import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* removeEvent({ id, callbacks }) {
  try {
    yield call(api.removeEvent, id);
    yield put(Actions.removeEventSuccess());
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.removeEventFail(error));
    if (typeof callbacks?.fail === 'function') {
      yield call(callbacks.fail);
    }
  }
}

function* watchRemoveEvent() {
  yield takeLatest(Actions.REMOVE_EVENT_REQUEST, removeEvent);
}

export default function* remove() {
  yield all([fork(watchRemoveEvent)]);
}

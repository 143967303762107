import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchAttendingEvents({ callbacks }) {
  try {
    const response = yield call(api.fetchAttendingEvents);
    const data = response?.data;

    yield put(Actions.fetchAttendingEventsSuccess(data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.fetchAttendingEventsFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchAttendingEvents() {
  yield takeLatest(
    Actions.FETCH_ATTENDING_EVENTS_REQUEST,
    fetchAttendingEvents
  );
}

export default function* attendList() {
  yield all([fork(watchFetchAttendingEvents)]);
}

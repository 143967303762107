import React from 'react';
import { Picture } from 'components/UI';
import {
  gfxFeatures,
  iconCheckmark,
  iconAppStore,
  iconPlayStore,
} from 'assets';

const Features = () => {
  const bulletList = (label) => (
    <li className="flex mb-6">
      <img alt="check" src={iconCheckmark.default} className="h-4 mt-1 mr-4" />
      <span>{label}</span>
    </li>
  );

  const appStoreUrl = 'https://apps.apple.com/us/app/gig-life-pro/id1640489665';
  const playStoreUrl =
    'https://play.google.com/store/apps/details?id=com.giglifepro&pli=1';

  // eslint-disable-next-line react/prop-types
  const AppIcon = ({ alt, src, onClick }) => (
    <button type="button" onClick={onClick}>
      <img alt={alt} src={src} className="h-12 mr-4" />
    </button>
  );

  return (
    <div className="container flex flex-col-reverse md:flex-row items-center my-24 mx-auto px-4">
      <div className="flex flex-col items-center lg:items-start lg:w-1/2">
        <h2 className="hidden md:block">Features At a Glance</h2>
        <p className="text-md text-dusty my-8 lg:mb-16 text-center lg:text-left">
          Stay updated, share insights and feel connected all in one app
        </p>
        <div className="flex justify-center">
          <ul className="list-outside px-6 sm:px-24 lg:pl-0">
            {bulletList(
              'Access information and tools on how the industry works globally'
            )}
            {bulletList(
              'Explore local market opportunities by learning about trends, platforms and services. '
            )}
            {bulletList(
              'Connect with partners globally who can help drive your growth locally.'
            )}
          </ul>
        </div>
      </div>
      <div className="flex-1">
        <h2 className="text-center mb-4 lg:hidden">Features At a Glance</h2>
        <Picture src={gfxFeatures} className="w-10/12 mx-auto" />
        <div className="flex justify-center mt-6">
          <AppIcon
            alt="app store"
            src={iconAppStore.default}
            onClick={() => window.open(appStoreUrl, '_blank')}
          />
          <AppIcon
            alt="play store"
            src={iconPlayStore.default}
            onClick={() => window.open(playStoreUrl, '_blank')}
          />
        </div>
      </div>
    </div>
  );
};

export default Features;

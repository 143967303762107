import Actions from 'actions';

const initialState = Object.freeze({});

function connect(state = initialState, action) {
  switch (action.type) {
    case Actions.CONNECT_SENDBIRD_REQUEST:
    case Actions.CONNECT_SENDBIRD_FAIL:
      return initialState;
    case Actions.CONNECT_SENDBIRD_SUCCESS:
      return {
        ...initialState,
        isConnected: true,
        error: null,
      };
    default:
      return state;
  }
}

export default connect;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchTimezones() {
  try {
    const response = yield call(api.fetchTimezones);
    yield put(Actions.fetchTimezonesSuccess(response.data));
  } catch (error) {
    yield put(Actions.fetchTimezonesFail(error));
  }
}

function* watchFetchTimezones() {
  yield takeLatest(Actions.FETCH_TIMEZONES_REQUEST, fetchTimezones);
}

export default function* list() {
  yield all([fork(watchFetchTimezones)]);
}

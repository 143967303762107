import { all, fork } from 'redux-saga/effects';

import customer from './customer';
import subscription from './subscription';
import promo from './promo';
import portalSession from './portalSession';
import creditCard from './creditCard';

export default function* stripe() {
  yield all([
    fork(customer),
    fork(subscription),
    fork(promo),
    fork(portalSession),
    fork(creditCard),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components/UI/Loading';
import { Card } from 'components/UI';
import isEmpty from 'lodash/isEmpty';
import CreditCardDetails from './CreditCardDetails';

const CreditCard = ({ isLoading, data, redirectStripePortal }) => {
  if (isEmpty(data)) return null;

  return (
    <div className="mt-8 mb-6">
      <h3 className="uppercase mb-4 font-semibold">My Credit/Debit Card</h3>
      <Card className="max-w-120 min-w-80">
        {isLoading ? (
          <div className="flex justify-center items-center py-4">
            <Spinner height="40" color="#47bad4" />
          </div>
        ) : (
          <CreditCardDetails
            data={data}
            redirectStripePortal={redirectStripePortal}
          />
        )}
      </Card>
    </div>
  );
};

CreditCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  redirectStripePortal: PropTypes.func.isRequired,
};

export default CreditCard;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isFuture, isToday, parseISO } from 'date-fns';
import {
  Badge,
  Card,
  Picture,
  Options,
  MemberTierBadge,
  Modal,
} from 'components/UI';
import { displayTimeFormat, displayDayDateFormat } from 'utils/datetime';
import {
  iconClock,
  gfxAvatar,
  iconAddToCalendarActive,
  iconDeclineCalendar,
  iconAcceptTick,
  iconRequestReschedule,
} from 'assets';
import { GUEST } from 'utils/constants';
import RescheduleModal from './RescheduleModal';

const OptionAddToCalendar = () => (
  <span>
    <Picture src={iconAddToCalendarActive} className="inline-block w-4 mr-2" />
    Add to calendar
  </span>
);

const OptionAcceptBooking = () => (
  <span>
    <Picture src={iconAcceptTick} className="inline-block w-4 mr-2" />
    Accept Booking
  </span>
);

const OptionDeclineBooking = () => (
  <span>
    <Picture src={iconDeclineCalendar} className="inline-block w-4 mr-2" />
    Decline Booking
  </span>
);

const OptionDeclineReschedule = () => (
  <span>
    <Picture src={iconDeclineCalendar} className="inline-block w-4 mr-2" />
    Decline Reschedule
  </span>
);

const OptionCancelBooking = () => (
  <span>
    <Picture src={iconDeclineCalendar} className="inline-block w-4 mr-2" />
    Cancel Booking
  </span>
);

const OptionAcceptReschedule = () => (
  <span>
    <Picture src={iconAcceptTick} className="inline-block w-4 mr-2" />
    Accept Reschedule
  </span>
);

const OptionReschedule = () => (
  <span>
    <Picture src={iconRequestReschedule} className="inline-block w-4 mr-2" />
    Request reschedule
  </span>
);

const bgBadgeStatusMap = {
  pending: 'bg-yellow-500',
  paid: 'bg-brilliantGreen',
  cancelled: 'bg-valencia',
  declined: 'bg-valencia',
  pending_reschedule: 'bg-yellow-500',
};

const BookingCard = ({
  booking,
  acceptBooking,
  declineBooking,
  cancelBooking,
  onClickCalendar,
  cancelServiceBookingMutate,
  declineRescheduleMutate,
  isConsultantTab,
}) => {
  const finalStatusMap = {
    pending: isConsultantTab ? 'Request' : 'Pending',
    pending_reschedule: isConsultantTab
      ? 'Reschedule request'
      : 'Pending Reshedule',
    paid: 'Completed',
  };

  const {
    id,
    status,
    start_at: startAt,
    end_at: endAt,
    customer_name: customerName,
    customer_tier: customerTier,
    customer_image_url: customerImg,
    booking_details: bookingDetails,
    upcoming_booking: upcomingBooking,
    consultant: { id: consultantServiceId, name, image_url: img, link, tier },
    cancel_before: cancelBefore,
  } = booking;
  const isGuestCustomer = !customerTier;
  const displayName = isConsultantTab ? customerName : name;
  const displayImg = isConsultantTab ? customerImg : img;
  const displayTier = isConsultantTab ? customerTier : tier;

  const bookingIsToday = status === 'paid' && isToday(parseISO(startAt));
  const allowCancel = isFuture(parseISO(cancelBefore));

  const finalStatus = finalStatusMap[status] || status;
  const badgeBg = bgBadgeStatusMap[status] || 'bg-brilliantGreen';
  const showUpcoming = upcomingBooking && ['Completed'].includes(finalStatus);

  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const closeRescheduleModal = () => setIsRescheduleModalOpen(false);
  const openRescheduleModal = () => {
    setIsRescheduleModalOpen(true);
  };

  const optionList = [
    upcomingBooking &&
      status !== 'cancelled' && {
        label: <OptionAddToCalendar />,
        key: 'click-calendar',
        onClick: onClickCalendar,
      },
    status === 'pending' &&
      upcomingBooking &&
      isConsultantTab && {
        label: <OptionAcceptBooking />,
        key: 'accept-booking',
        onClick: acceptBooking,
      },
    status === 'pending' &&
      upcomingBooking &&
      isConsultantTab && {
        label: <OptionDeclineBooking />,
        key: 'decline-booking',
        onClick: declineBooking,
      },
    status === 'paid' &&
      upcomingBooking &&
      isConsultantTab &&
      allowCancel && {
        label: <OptionCancelBooking />,
        key: 'cancel-booking',
        onClick: cancelServiceBookingMutate,
      },
    status === 'pending' &&
      !isConsultantTab && {
        label: <OptionCancelBooking />,
        key: 'cancel-booking',
        onClick: () => cancelBooking(id),
      },
    status === 'pending_reschedule' &&
      isConsultantTab && {
        label: <OptionAcceptReschedule />,
        key: 'accept-reschedule',
        onClick: acceptBooking,
      },
    status === 'paid' &&
      upcomingBooking &&
      allowCancel &&
      !isConsultantTab && {
        label: <OptionReschedule />,
        key: 'request-reschedule',
        onClick: openRescheduleModal,
      },
    status === 'pending_reschedule' &&
      upcomingBooking &&
      isConsultantTab && {
        label: <OptionDeclineReschedule />,
        key: 'decline-reschedule',
        onClick: declineRescheduleMutate,
      },
  ].filter((option) => !!option);

  const [isOpen, setIsOpen] = useState(false);

  const openDetails = () => setIsOpen(!isOpen);

  return (
    <>
      <Card className="w-full flex flex-col md:flex-row py-3 items-center justify-center mt-5">
        <div className="pt-4 w-full md:p-4 md:w-1/3 lg:w-1/4 xl:w-1/6 xl:p-0 flex flex-col justify-center items-center">
          {isGuestCustomer && isConsultantTab ? (
            <div className="w-24 h-24 flex justify-center items-center border-2 rounded-full">
              <MemberTierBadge memberTier={GUEST} className="text-sm" />
            </div>
          ) : (
            <>
              <Picture
                src={displayImg || gfxAvatar}
                className="w-24 h-24 rounded-full mx-auto object-cover -mb-1"
                fallbackImage={gfxAvatar.default}
              />
              <MemberTierBadge memberTier={displayTier} className="text-sm" />
            </>
          )}
        </div>
        <div className="w-full px-6 lg:px-4 pb-3 flex flex-col lg:flex-row">
          <section className="w-full lg:w-2/3 flex flex-col items-center justify-center">
            <article className="mt-3 text-md md:self-start font-bold lg:text-lg xl:text-2lg">
              {`${
                isConsultantTab ? 'Appointment' : 'Consultation'
              } with ${displayName}`}
            </article>
            <section className="mt-3 flex items-center md:self-start text-boulder text-sm">
              <div className="border-r border-boulder pr-2 w-auto lg:pr-2">
                {bookingIsToday ? (
                  <span>
                    <span className="font-bold text-mulled">Today</span>,{' '}
                    {displayDayDateFormat(startAt)}
                  </span>
                ) : (
                  <span>{displayDayDateFormat(startAt)}</span>
                )}
              </div>
              <div className="flex items-center w-auto justify-center md:justify-center ml-2">
                <Picture src={iconClock} className="w-4 mr-2" />
                {`${displayTimeFormat(startAt)} - ${displayTimeFormat(endAt)}`}
              </div>
            </section>
            <article className="hidden lg:block lg:self-start lg:mt-3 text-easter">
              <div
                className={classnames(
                  'text-boulder font-semibold truncate max-w-md mb-3',
                  {
                    hidden: !link || status !== 'paid',
                  }
                )}
              >
                Meeting link:{' '}
                <a
                  href={link}
                  rel="noreferrer"
                  target="_blank"
                  className="text-easter"
                >
                  {link}
                </a>
              </div>
              {!isOpen ? (
                <span
                  onClick={openDetails}
                  className="text-easter"
                  role="presentation"
                >
                  View Details
                </span>
              ) : (
                <span
                  role="presentation"
                  href="#"
                  onClick={openDetails}
                  className="text-dusty"
                >
                  Hide Details
                </span>
              )}
            </article>
            <article
              className={classnames({ hidden: !isOpen }, 'self-start mt-2')}
            >
              {bookingDetails}
            </article>
          </section>
          <section className="w-full lg:w-1/3 flex justify-between lg:justify-start xl:justify-end xl:pr-10 lg:pl-5 items-center">
            <div className="pt-6">
              {bookingIsToday ? (
                <Badge bg="bg-gradient-turqoise-violet">
                  <a href={link}>Join Meet</a>
                </Badge>
              ) : (
                <div>
                  {showUpcoming ? (
                    <Badge bg="bg-blue-500">UPCOMING</Badge>
                  ) : (
                    <Badge bg={badgeBg}>{finalStatus}</Badge>
                  )}
                </div>
              )}
            </div>
            <div className="pt-6">
              {optionList.length > 0 && <Options list={optionList} />}
            </div>
          </section>
        </div>
      </Card>
      <Modal
        isOpen={isRescheduleModalOpen}
        content={
          <RescheduleModal
            onCancel={() => setIsRescheduleModalOpen(false)}
            consultantServiceId={consultantServiceId}
            bookingId={id}
          />
        }
        onDismiss={closeRescheduleModal}
      >
        <div />
      </Modal>
    </>
  );
};

BookingCard.propTypes = {
  booking: PropTypes.object.isRequired,
  acceptBooking: PropTypes.func,
  declineBooking: PropTypes.func,
  onClickCalendar: PropTypes.func,
  cancelServiceBookingMutate: PropTypes.func,
  declineRescheduleMutate: PropTypes.func,
  isConsultantTab: PropTypes.bool,
  cancelBooking: PropTypes.func,
};

BookingCard.defaultProps = {
  acceptBooking: null,
  declineBooking: null,
  onClickCalendar: null,
  cancelServiceBookingMutate: null,
  declineRescheduleMutate: null,
  isConsultantTab: false,
  cancelBooking: null,
};

export default BookingCard;

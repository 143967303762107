import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';
import { cloneDeep, unset } from 'lodash';

function* createUser({ data, callbacks }) {
  const payload = cloneDeep(data);
  payload.users_experiences_attributes = [
    {
      company: data.company,
      company_url: data.company_url,
      position: data.position,
      year_start: data.year_start,
      year_end: data.year_end,
    },
  ];
  unset(payload, 'company');
  unset(payload, 'company_url');
  unset(payload, 'position');
  unset(payload, 'year_start');
  unset(payload, 'year_end');

  try {
    const response = yield call(api.createUser, payload);
    yield put(Actions.createUserSuccess(response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.createUserFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchCreateUser() {
  yield takeLatest(Actions.CREATE_USER_REQUEST, createUser);
}

export default function* create() {
  yield all([fork(watchCreateUser)]);
}

import * as connect from './connect';
import * as user from './user';
import * as users from './users';
import channel from './channel';
import message from './message';

export default {
  ...connect,
  ...channel,
  ...user,
  ...users,
  ...message,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const ViewAll = ({ path }) => {
  return (
    <Link
      className="flex items-center cursor-pointer text-sigmaBlue-dark sm:ml-6 text-base"
      to={path}
    >
      <div>View All</div>
      <DoubleArrowIcon className="sm:mx-2" fontSize="inherit" />
    </Link>
  );
};

ViewAll.propTypes = {
  path: PropTypes.string.isRequired,
};

export default ViewAll;

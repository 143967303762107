import { combineReducers } from 'redux';
import connect from './connect';
import channel from './channel';
import user from './user';
import users from './users';
import message from './message';

export default combineReducers({
  connect,
  channel,
  user,
  users,
  message,
});

const NAME = 'CONSULTATION_SERVICE/UPDATE_';

export const UPDATE_CONSULTATION_SERVICE_REQUEST = `${NAME}REQUEST`;
export const UPDATE_CONSULTATION_SERVICE_SUCCESS = `${NAME}SUCCESS`;
export const UPDATE_CONSULTATION_SERVICE_FAIL = `${NAME}FAIL`;

export const updateConsultationService = (params, callbacks) => ({
  type: UPDATE_CONSULTATION_SERVICE_REQUEST,
  params,
  callbacks,
});

export const updateConsultationServiceSuccess = (data) => ({
  type: UPDATE_CONSULTATION_SERVICE_SUCCESS,
  data,
});

export const updateConsultationServiceFail = (error) => ({
  type: UPDATE_CONSULTATION_SERVICE_FAIL,
  error,
});

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchDashboardInfo({ id, callbacks }) {
  try {
    const response = yield call(api.fetchDashboardInfo, id);
    yield put(Actions.fetchDashboardInfoSuccess(response?.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.fetchDashboardInfoFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchDashboard() {
  yield takeLatest(Actions.FETCH_DASHBOARD_INFO_REQUEST, fetchDashboardInfo);
}

export default function* list() {
  yield all([fork(watchFetchDashboard)]);
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'components/UI';
import { gfxNewsletter } from 'assets';
import { HashLink } from 'react-router-hash-link';
import { BackgroundSection } from '../../containers/LandingPage/common';

const NewsletterModal = ({ isOpen }) => {
  const [open, toggleModal] = useState(isOpen);

  useEffect(() => {
    toggleModal(isOpen);
  }, [isOpen]);

  return (
    <Modal
      isOpen={open}
      content={
        <div className="h-104 sm:h-140 w-10/12 sm:w-full m-auto rounded-lg overflow-hidden">
          <BackgroundSection
            backgroundImage={`url(${gfxNewsletter.default})`}
            className="relative bg-no-repeat bg-center h-full flex justify-center items-center rounded-lg overflow-hidden"
          >
            <div className="max-w-lg flex justify-center items-center flex-col text-center px-3 sm:p-0">
              <h3 className="text-2lg sm:text-xl font-semibold mb-2 sm:m-0">
                Subscribe to our newsletter
              </h3>
              <p>
                Stay updated with our latest networking opportunities, content,
                trends, insights and more!
              </p>
              <HashLink to="#subscribeNewsletter" smooth>
                <Button
                  className="h-12 mt-2 text-xs sm:text-base px-4 sm:px-10"
                  label="SUBSCRIBE"
                  isPill
                  onClick={() => toggleModal(false)}
                />
              </HashLink>
            </div>
          </BackgroundSection>
        </div>
      }
    >
      <div />
    </Modal>
  );
};

NewsletterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default NewsletterModal;

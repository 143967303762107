const NAME = 'EVENTS/FETCH_COUNT';

export const FETCH_COUNT_REQUEST = `${NAME}_REQUEST`;
export const FETCH_COUNT_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_COUNT_FAIL = `${NAME}_FAIL`;

export const fetchCount = () => ({
  type: FETCH_COUNT_REQUEST,
});

export const fetchCountSuccess = (data) => ({
  type: FETCH_COUNT_SUCCESS,
  data,
});

export const fetchCountFail = (error) => ({
  type: FETCH_COUNT_FAIL,
  error,
});

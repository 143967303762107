import { takeLatest, all, fork, put, call, select } from 'redux-saga/effects';
import Actions from 'actions';
import Selectors from 'selectors';
import api from 'api';
import { setCacheExp } from 'utils/dataCache';
import { paginationHelper } from 'utils/helper';

function* fetchVideos({ params, callbacks }) {
  try {
    let data;
    let exp = yield select(Selectors.getVideosCacheExp);

    if (!params && new Date().getTime() < exp) {
      data = yield select(Selectors.getVideos);
      yield put(Actions.fetchVideosSuccess(data, exp));
    } else {
      const response = yield call(api.fetchVideos, params);
      const pageMeta = paginationHelper(response?.headers);
      data = response.data;
      exp = !params ? setCacheExp(15) : null;
      yield put(Actions.fetchVideosSuccess(data, exp, pageMeta));
    }

    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, data);
    }
  } catch (error) {
    yield put(Actions.fetchVideosFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchVideos() {
  yield takeLatest(Actions.FETCH_VIDEOS_REQUEST, fetchVideos);
}

export default function* list() {
  yield all([fork(watchFetchVideos)]);
}

import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Picture, Modal } from 'components/UI';
import notify from 'utils/notification';
import { imgTableBenefits, allAccessRedeemed, premiumRedeemed } from 'assets';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import api from 'api';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AuthContext } from 'utils/context';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { isEmpty } from 'lodash';
import { ALL_ACCESS } from 'utils/constants';
import Info from '@material-ui/icons/Info';
import { liteDialog } from './dialogs';
import CreditCard from './CreditCard';
import MembershipInfo from './MembershipInfo';
import RenewalReminder from './RenewalReminder';
import Password from './Password';
import BlockedUsers from './BlockedUsers';
import {
  ALL_ACCESS_MEMBERSHIP,
  PREMIUM_MEMBERSHIP,
} from '../../utils/constants';

const Subscription = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isFreeTier, isLite, hasAccess } = useContext(AuthContext);
  useEffect(() => {
    dispatch(Actions.fetchProfile());
    dispatch(Actions.fetchUserCreditCard());
    dispatch(Actions.fetchSubscription());
    dispatch(Actions.fetchMemberTiers());
  }, [dispatch, location.key]);

  const { data: redeemablesData } = useQuery(
    ['redeemablesData', { memberships: true }],
    () => api.fetchUserRedeemables({ memberships: true })
  );

  const premiumMonthly = redeemablesData?.data.find(
    ({ redeemable_type: redeemableType }) =>
      redeemableType === PREMIUM_MEMBERSHIP
  );
  const allAccessMonthly = redeemablesData?.data.find(
    ({ redeemable_type: redeemableType }) =>
      redeemableType === ALL_ACCESS_MEMBERSHIP
  );

  const creditCard = useSelector(Selectors.creditCardDetails) || {};
  const subscription = useSelector(Selectors.getSubscription);
  const tiers = useSelector(Selectors.memberTiersList);
  const {
    next_price: nextPrice,
    pro_eligible: isProEligible,
    price,
    store,
  } = subscription;
  const isMobile = store !== 'stripe';
  const isLoading = useSelector(
    Selectors.createLoadingSelector([
      Actions.FETCH_MEMBER_TIERS_REQUEST,
      Actions.FETCH_USER_CREDIT_CARD_REQUEST,
      Actions.FETCH_SUBSCRIPTION_REQUEST,
    ])
  );
  const redirectStripePortal = () =>
    dispatch(
      Actions.createPortalSession({
        success: ({ url }) => {
          window.location = url;
          return null;
        },
        failure: () =>
          notify(
            'Change is unavailable at the moment. Please try again later.',
            null,
            'danger'
          ),
      })
    );

  const upgradeToPro = () =>
    dispatch(
      Actions.showDialog({
        ...liteDialog(price?.interval === 'year'),
        onAccept: () => {
          navigate('/subscription/update', {
            state: { tierToUpdate: ALL_ACCESS },
          });
          dispatch(Actions.hideDialog());
        },
      })
    );

  const { mutate: activateRewardMutate } = useMutation(
    (id) => api.activateMembershipReward(id),
    {
      onSuccess: () => {
        dispatch(Actions.fetchSubscription());
        queryClient.invalidateQueries('redeemablesData');
        notify('Successfully activated reward!');
      },
      onError: (e) => {
        notify(
          e?.response?.data?.error ||
            'Something went wrong, please try again later',
          null,
          'danger'
        );
      },
    }
  );

  const activateRedeemable = (id) =>
    dispatch(
      Actions.showDialog({
        title: 'ACTIVATE REWARD',
        content: `Are you sure you want to activate 1 month membership reward?`,
        subContent: `Your membership reward will take effect in your next billing cycle if any.`,
        acceptLabel: 'YES, ACTIVATE REWARD',
        dismissLabel: `CANCEL`,
        onAccept: () => {
          activateRewardMutate(id);
          dispatch(Actions.hideDialog());
        },
      })
    );

  const ProEligibility = () => (
    <Card className="max-w-lg p-4 sm:p-5 mt-4 sm:mt-8">
      <div className="text-md">{`Eligible for ${ALL_ACCESS} membership!`}</div>
      <div className="text-dusty mt-2">
        {`You are eligible for ${ALL_ACCESS} membership. Let's UPGRADE to reap more `}
        <Modal content={<Picture src={imgTableBenefits} />}>
          <span className="text-easter cursor-pointer">benefits</span>
        </Modal>
      </div>
      <div
        className="mt-3 text-easter flex justify-end cursor-pointer"
        onClick={upgradeToPro}
        role="presentation"
      >
        Upgrade Now
        <DoubleArrowIcon className="ml-2" />
      </div>
    </Card>
  );

  const MembershipRedeemables = ({ membershipRedeemable }) => {
    const {
      id,
      redeemable_type: redeemableType,
      premium_count: premiumCount,
      all_access_count: allAccessCount,
    } = membershipRedeemable;
    return (
      <Card className="w-11/12 p-4 sm:p-5 mt-4 sm:mt-8 flex relative">
        <Picture
          src={
            redeemableType === ALL_ACCESS_MEMBERSHIP
              ? allAccessRedeemed
              : premiumRedeemed
          }
          className="self-center mr-5 w-20 lg:w-30 rounded-full"
        />
        <div>
          <p className="text-md">
            Activate your
            <span className="font-bold"> 1 Month Free Membership</span>
          </p>
          <p className="text-dusty mt-2">
            Awesome! You are eligible to activate your 1 month FREE month
            reward. There is no expiry date to activate this reward.
          </p>
        </div>
        <button
          type="button"
          className="h-10 w-48 justify-center text-sm px-3 flex items-center text-easter border-easter border-2 rounded-full py-2 ml-3"
          onClick={() => activateRedeemable(id)}
        >
          ACTIVATE
        </button>
        <div className="absolute right-0 bottom-0 mb-4 text-white bg-easter rounded-l-full px-4 rounded-t h-8 flex items-center">
          x
          {redeemableType === ALL_ACCESS_MEMBERSHIP
            ? allAccessCount
            : premiumCount}
        </div>
      </Card>
    );
  };

  MembershipRedeemables.propTypes = {
    membershipRedeemable: PropTypes.object.isRequired,
  };

  return (
    <>
      {isMobile && (
        <div className="bg-lily w-full p-4 flex">
          <div>
            <Info className="mr-4 mt-2 text-easter" />
          </div>
          <div className="flex flex-col">
            <p className="text-md lg:text-lg">
              Manage subscriptions through the mobile app
            </p>
            <p className="text-sm mt-1 text-dusty lg:w-11/12">
              Due to Apple and Google privacy policy, any subscriptions made via
              the Gig Life Pro mobile app can only be managed through the same
              device
            </p>
            <Link className="flex mt-2 text-easter" to="/faq">
              <p>Learn More</p>
              <ChevronRight className="" />
            </Link>
          </div>
        </div>
      )}
      <div className="p-4 sm:p-8">
        <MembershipInfo
          isLoading={isLoading}
          subscription={subscription}
          tiers={tiers}
          isMobile={isMobile}
        />
        {hasAccess && isProEligible && isLite && !nextPrice && !isMobile && (
          <ProEligibility />
        )}
        {allAccessMonthly && (
          <MembershipRedeemables membershipRedeemable={allAccessMonthly} />
        )}
        {premiumMonthly && (
          <MembershipRedeemables membershipRedeemable={premiumMonthly} />
        )}
        <Password />
        <CreditCard
          isLoading={isLoading}
          data={creditCard}
          redirectStripePortal={redirectStripePortal}
        />
        {hasAccess && !isFreeTier && !isEmpty(subscription) && (
          <RenewalReminder checked={subscription.renewal_reminder} />
        )}
        <BlockedUsers />
      </div>
    </>
  );
};

export default Subscription;

const NAME = 'CONSULTATION_SERVICE/FETCH_';

export const FETCH_CONSULTATION_SERVICE_REQUEST = `${NAME}REQUEST`;
export const FETCH_CONSULTATION_SERVICE_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_CONSULTATION_SERVICE_FAIL = `${NAME}FAIL`;

export const fetchConsultationService = () => ({
  type: FETCH_CONSULTATION_SERVICE_REQUEST,
});

export const fetchConsultationServiceSuccess = (data) => ({
  type: FETCH_CONSULTATION_SERVICE_SUCCESS,
  data,
});

export const fetchConsultationServiceFail = (error) => ({
  type: FETCH_CONSULTATION_SERVICE_FAIL,
  error,
});

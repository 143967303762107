import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { Breadcrumbs, PageMeta, NavigationTab } from 'components/UI';
import { AuthContext } from 'utils/context';
import RedeemedPerks from './RedeemedPerks';
import CreatedPerks from './CreatedPerks';

const EXPLORE_PATH = '/members/perks/explore';
const TABS = Object.freeze([
  { name: 'Explore Perks', path: EXPLORE_PATH },
  { name: 'My Perks', path: '/members/perks/my-perks' },
]);

const MyPerks = () => {
  const { isFreeTier } = useContext(AuthContext);
  const dispatch = useDispatch();
  const redeemedPerks = useSelector(Selectors.getRedeemedPerks);
  const createdPerks = useSelector(Selectors.getCreatedPerks);
  const isLoadingRedeemed = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_REDEEMED_REQUEST])
  );
  const isLoadingCreated = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_CREATED_REQUEST])
  );

  useEffect(() => {
    dispatch(Actions.fetchRedeemedPerks());
    if (!isFreeTier) dispatch(Actions.fetchCreatedPerks());
  }, [dispatch, isFreeTier]);

  return (
    <>
      <PageMeta title="Perks" />
      <Breadcrumbs
        trails={[
          { label: 'Perks', path: EXPLORE_PATH, icon: 'perks' },
          'My Perks',
        ]}
      />
      <NavigationTab tabs={TABS} />
      <div className="lg:container mx-auto px-6 pt-30">
        {!isFreeTier && (
          <CreatedPerks perks={createdPerks} isLoading={isLoadingCreated} />
        )}
        <RedeemedPerks perks={redeemedPerks} isLoading={isLoadingRedeemed} />
      </div>
    </>
  );
};

export default MyPerks;

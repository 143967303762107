import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Selectors from 'selectors';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { ScrollToTop, Dialog, MemberTierBadge } from 'components/UI';
import { SidebarContext } from 'utils/context';
import {
  gfxAvatar,
  myProfileIcon,
  accountSettingsIcon,
  logOutIcon,
} from 'assets';
import { ALL_ACCESS, PREMIUM, FREE } from 'utils/constants';

import Sidebar from './common/Sidebar';
import { isProduction } from '../../utils/config';
import StagingBanner from '../../components/UI/StagingBanner';

const HeaderContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: calc(100% - 100px);
    ${({ sidebarExpanded }) => sidebarExpanded && 'width: calc(100% - 300px);'}
  }
`;

const Header = () => {
  const { sidebarExpanded } = useContext(SidebarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profilePage = '/profile';
  const accountSettingsPage = '/subscription';

  const user = useSelector(Selectors.getUser) || {};
  const { image_url: avatarUrl, member_tier: memberTier } = user;
  const userTierName = memberTier?.name;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const DropDownItem = ({ description, onClick, icon }) => {
    return (
      <button
        className="w-48 text-left text-dusty flex text-sm px-4 py-2 hover:bg-lily"
        type="button"
        onClick={onClick}
      >
        <div className="w-2/12 flex justify-center">
          <img src={icon.default} alt="icon" className="h-5" />
        </div>
        <p className="w-10/12 ml-3">{description}</p>
      </button>
    );
  };

  DropDownItem.propTypes = {
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.object.isRequired,
  };

  return (
    <HeaderContainer
      className="fixed flex flex-col sm:h-24 items-center px-10 z-40 bg-white border-b border-lily shadow-md"
      sidebarExpanded={sidebarExpanded}
    >
      <button
        className="flex flex-col items-center ml-auto my-auto"
        type="button"
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
      >
        <img
          src={avatarUrl || gfxAvatar.default}
          alt="icon"
          className={classnames('w-12 rounded-full border mt-1 -mb-1', {
            'border-violet': userTierName === ALL_ACCESS,
            'border-easter': userTierName === (PREMIUM || FREE),
            'border-none': !avatarUrl,
          })}
        />
        <MemberTierBadge memberTier={userTierName} className="text-xxs" />
      </button>
      <div
        className={classnames(
          'ml-auto shadow-xl overflow-visible mt-3 bg-white rounded-lg font-light',
          {
            hidden: !isDropDownOpen,
          }
        )}
      >
        <div className="flex-grow h-1  bg-violet rounded-t-lg" />
        <DropDownItem
          description="My Profile"
          onClick={() => navigate(profilePage)}
          icon={myProfileIcon}
        />
        <DropDownItem
          description="Account Settings"
          onClick={() => navigate(accountSettingsPage)}
          icon={accountSettingsIcon}
        />
        <DropDownItem
          description="Sign Out"
          onClick={() => dispatch(Actions.signOut())}
          icon={logOutIcon}
        />
      </div>
    </HeaderContainer>
  );
};

const MembersIndex = () => {
  const [sidebarExpanded, toggleSidebar] = useState(true);

  return (
    <SidebarContext.Provider value={{ sidebarExpanded, toggleSidebar }}>
      {!isProduction && <StagingBanner />}
      <div className="h-screen max-w-screen flex overflow-hidden">
        <Sidebar
          sidebarExpanded={sidebarExpanded}
          toggleSidebar={toggleSidebar}
        />
        <div
          id="scrollableContent"
          className="overflow-y-auto relative w-full flex-1"
        >
          <Header sidebarExpanded={sidebarExpanded} />
          <Dialog />
          <div className="mt-16 sm:mt-24 w-full">
            <ScrollToTop />
            <Outlet />
          </div>
        </div>
      </div>
    </SidebarContext.Provider>
  );
};

export default MembersIndex;

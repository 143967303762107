import { all, fork } from 'redux-saga/effects';
import list from './list';
import info from './info';
import attend from './attend';
import unattend from './unattend';
import attendList from './attendList';
import optIn from './optIn';
import optOut from './optOut';
import getCategories from './getCategories';
import getCount from './getCount';
import attendances from './attendances';
import add from './add';
import remove from './remove';
import addedList from './addedList';
import create from './create';
import createdList from './createdList';
import destroy from './destroy';
import update from './update';
import fetchCreated from './fetchCreated';

export default function* events() {
  yield all([
    fork(list),
    fork(info),
    fork(attend),
    fork(unattend),
    fork(attendList),
    fork(optIn),
    fork(optOut),
    fork(getCategories),
    fork(getCount),
    fork(attendances),
    fork(add),
    fork(remove),
    fork(addedList),
    fork(create),
    fork(createdList),
    fork(destroy),
    fork(update),
    fork(fetchCreated),
  ]);
}

import React from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'actions';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Options } from 'components/UI';
import { displaySubscriptionDateFormat } from 'utils/datetime';
import { urlHandler } from 'utils/helper';
import classnames from 'classnames';
import notify from 'utils/notification';
import { BackgroundSection } from '../../LandingPage/common';
import MemberAccessBadge from '../MemberAccessBadge';

const STATUS_MAP = Object.freeze({
  pending: 'PENDING APPROVAL',
  approved: 'PUBLISHED',
  declined: 'DECLINED',
});

const CreatedPerkTileCard = ({ perk }) => {
  const {
    id,
    name,
    summary,
    member_access: memberAccess,
    status,
    draft,
    image_url: img,
    end_date: expiry,
  } = perk;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updatePerkDraft = () => {
    const callbacks = {
      success: () =>
        notify(`Perk ${draft ? 'is published' : 'saved as draft'}`),
      fail: () => notify('Failed to update perk', null, 'danger'),
    };

    dispatch(Actions.updatePerk(id, { draft: !draft }, callbacks));
  };

  const deleteDialog = () => {
    const callbacks = {
      success: () => {
        dispatch(Actions.hideDialog());
        notify('Perk is deleted');
      },
      fail: () => {
        dispatch(Actions.hideDialog());
        notify('Failed to delete perk', null, 'danger');
      },
    };
    dispatch(
      Actions.showDialog({
        title: 'DELETE PERK',
        content: 'Are you sure you want to DELETE your created perk?',
        subContent: 'This will remove all information regarding this perk',
        acceptLabel: 'YES, DELETE PERK',
        dismissLabel: "NO, DON'T DELETE",
        onAccept: () => dispatch(Actions.deletePerk(id, callbacks)),
      })
    );
  };

  const optionList = [
    { label: 'Edit Perk', onClick: () => navigate(`${id}/update`) },
    {
      label: `${draft ? 'Publish' : 'Unpublish'} Perk`,
      onClick: updatePerkDraft,
    },
    {
      label: 'Delete Perk',
      onClick: deleteDialog,
      className: 'text-valencia',
    },
  ];

  return (
    <Card className="relative h-full">
      <Link to={id}>
        <BackgroundSection
          backgroundImage={`url(${urlHandler(img)})`}
          className="relative bg-no-repeat bg-cover bg-center h-56 rounded-t-lg"
        />
        <div className="m-4 absolute top-0 left-0">
          <MemberAccessBadge memberAccess={memberAccess} />
        </div>
      </Link>
      <Link to={id} className="p-4 pb-0 flex flex-col">
        <h4 className="font-lynstone">{name}</h4>
        <div className="text-dusty mb-3 overflow-y-scroll h-16">{summary}</div>
      </Link>
      <div className="p-4 pt-0">
        <div className="flex flex-row justify-between mt-auto text-xs items-center">
          <div className="text-dusty">
            {`Expires ${displaySubscriptionDateFormat(expiry)}`}
          </div>
          <div className="flex justify-end items-center">
            <div
              className={classnames({
                'text-green-500': status === 'approved',
                'text-yellow-500': !draft && status === 'pending',
                'text-red-600': status === 'declined',
                'text-dusty': draft,
              })}
            >
              {draft ? 'DRAFT' : STATUS_MAP[status]}
            </div>
            <Options list={optionList} />
          </div>
        </div>
      </div>
    </Card>
  );
};

CreatedPerkTileCard.propTypes = {
  perk: PropTypes.object.isRequired,
};

export default CreatedPerkTileCard;

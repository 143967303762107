import React, { useEffect, useState } from 'react';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import Actions from 'actions';
import { gfxNewsletterPlane } from 'assets';
import notify from 'utils/notification';
import { TextInput, SelectInput } from 'components/Form';
import { ErrorContainer, Button, Picture } from 'components/UI';
import { arrayToOptions } from 'utils/helper';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  company: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
});

const EmailSignUp = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const countries = useSelector(Selectors.countryOptions);
  const countryList = (countries && Object.values(countries)) || [];
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_COUNTRY_OPTIONS_REQUEST])
  );

  const details = success
    ? 'You have successfully subscribed to our newsletter!'
    : 'Stay up to date with our latest features, networking events, industry tips, tools, trends and more.';

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    country: '',
  };

  const onSubmit = (values) => {
    dispatch(
      Actions.newsLetterSignUp(
        { ...values, city: values.country },
        () => {
          setError();
          setSuccess(true);
          notify('Newsletter subscribed!');
        },
        (error) => {
          const msg =
            typeof error === 'string'
              ? error
              : 'Failed to subscribe newsletter, please try again later';
          setError(msg);
        }
      )
    );
  };

  useEffect(() => {
    dispatch(Actions.fetchCountryOptions());
  }, [dispatch]);

  return (
    <div className="bg-twilight w-full -mt-48 p-6 md:p-20">
      <div className="m-auto border-t-6 border-easter p-8 md:p-16 max-w-5xl relative bg-white rounded-lg shadow-card">
        {!success && (
          <div className="absolute top-0 right-0 -mr-12 mt-40 hidden md:block">
            <Picture src={gfxNewsletterPlane} />
          </div>
        )}

        <div className="text-center space-y-2">
          <h2>Subscribe to our newsletter</h2>
          <p>{details}</p>
        </div>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form
            className={classnames('mt-6 flex flex-col gap-2 max-w-lg m-auto', {
              hidden: success,
            })}
            id="subscribeNewsletter"
          >
            <Field
              name="first_name"
              component={TextInput}
              placeholder="First Name"
            />
            <Field
              name="last_name"
              component={TextInput}
              placeholder="Last Name"
            />
            <Field
              name="email"
              component={TextInput}
              type="email"
              placeholder="Email"
            />
            <Field name="company" component={TextInput} placeholder="Company" />
            <Field
              name="country"
              placeholder="Country"
              component={SelectInput}
              isLoading={isLoading}
              options={arrayToOptions(countryList)}
            />
            <Button
              className="h-12 text-base px-4 sm:px-10 w-full md:w-1/2 mt-4 m-auto"
              label="SUBSCRIBE"
              isPill
              type="submit"
            />
          </Form>
        </Formik>
        <ErrorContainer className="mt-10" error={error} />
      </div>
    </div>
  );
};

export default EmailSignUp;

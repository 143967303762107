import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from 'components/UI';
import { yourEvents, upcomingEvents, newMembers } from 'assets';
import { BackgroundSection } from '../LandingPage/common';

const CountCard = ({ name, image, count, link }) => {
  return (
    <Link to={link}>
      <Card className="h-full">
        <BackgroundSection
          backgroundImage={`url(${image})`}
          className="relative bg-no-repeat bg-cover bg-center h-48 rounded-lg flex"
        >
          <div className="justify-around text-white m-auto w-2/3">
            <div className="mt-3 text-md h-12">{name}</div>
            <div className="font-semibold text-3xl">{count}</div>
          </div>
        </BackgroundSection>
      </Card>
    </Link>
  );
};

CountCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
};

const SummaryCard = ({
  yourEventsCount,
  upcomingEventsCount,
  newMembersCount,
}) => {
  return (
    <div className="container mx-auto pt-8 px-4 sm:px-12">
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
        <CountCard
          name="Your Events"
          image={yourEvents.default}
          count={yourEventsCount}
          link="/members/events/my-events"
        />
        <CountCard
          name="Upcoming Events"
          image={upcomingEvents.default}
          count={upcomingEventsCount}
          link="/members/events/explore"
        />
        <CountCard
          name="New Members This Month"
          image={newMembers.default}
          count={newMembersCount}
          link="/network?new=true"
        />
      </div>
    </div>
  );
};

SummaryCard.propTypes = {
  yourEventsCount: PropTypes.number.isRequired,
  upcomingEventsCount: PropTypes.number.isRequired,
  newMembersCount: PropTypes.number.isRequired,
};

export default SummaryCard;

import React from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/UI';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import api from 'api';
import { Field, Form, Formik } from 'formik';
import { TextInput } from 'components/Form';
import notify from 'utils/notification';

const BlockUserForm = ({ blockedUser }) => {
  const { id } = blockedUser;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate: blockUserMutate } = useMutation(
    (formData) => api.blockUser(id, formData),
    {
      onSuccess: () => {
        notify('Successfully blocked user');
        dispatch(Actions.hideDialog());
        navigate('/network');
      },
      onError: (e) => {
        notify(
          e?.response?.data?.error || 'Error blocking user',
          null,
          'danger'
        );
      },
    }
  );
  return (
    <div className="flex flex-col gap-4 text-left text-base">
      <p className="text-md text-center">
        State a reason on why you are blocking this user (optional){' '}
      </p>
      <p className="text-boulder text-center">
        Providing a reason can help us create a healthy community for you.
      </p>
      <Formik initialValues={{ reason: '' }} onSubmit={blockUserMutate}>
        {({ errors }) => (
          <Form className="space-y-4">
            <Field
              label="Reason"
              placeholder="Reason"
              name="reason"
              type="text"
              component={TextInput}
            />
            <div className="flex flex-col justify-center items-center m-auto gap-4">
              <Button
                label="YES, BLOCK USER"
                className="w-full sm:w-3/4 h-12 px-4"
                type="submit"
                isPill
                theme="valencia"
                disabled={!isEmpty(errors)}
              />
              <Button
                label="NO, CANCEL"
                className="w-full sm:w-3/4 h-12 px-4"
                theme="plain"
                outline
                isPill
                onClick={() => dispatch(Actions.hideDialog())}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

BlockUserForm.propTypes = {
  blockedUser: PropTypes.object.isRequired,
};

export default BlockUserForm;

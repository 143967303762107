import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { Breadcrumbs, PageMeta } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import useQuery from 'hooks/useQuery';
import { AuthContext } from 'utils/context';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isEmpty } from 'lodash';
import UserCard from './UserCard';
import Filter from './Filter';

const Connections = () => {
  const queries = useQuery();
  const { authenticated } = useContext(AuthContext);
  const dispatch = useDispatch();
  const usersPageMeta = useSelector(Selectors.usersPageMeta);
  const allUsers = useSelector(Selectors.getPaginatedUsers);
  const { currentPage, totalCount, totalPages } = usersPageMeta;
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_USERS_REQUEST])
  );

  useEffect(() => {
    dispatch(Actions.fetchUsers(queries));
  }, [dispatch, queries]);

  return (
    <>
      <PageMeta title="Network" />
      <Breadcrumbs
        isMember={authenticated}
        trails={[{ label: 'Network', path: '/network', icon: null }]}
      />
      <div className="container mx-auto px-6 pt-20">
        <h3 className="uppercase font-semibold">Explore Members</h3>
        <Filter />
        {isLoading && isEmpty(allUsers) && (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        )}
        <InfiniteScroll
          dataLength={totalCount || 0}
          next={() =>
            dispatch(Actions.fetchUsers({ ...queries, page: currentPage + 1 }))
          }
          hasMore={currentPage < totalPages}
          scrollableTarget="scrollableContent"
          loader={
            <div className="flex pb-8">
              <Spinner />
            </div>
          }
        >
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 my-8 mx-3">
            {allUsers.map((user) => (
              <UserCard {...user} key={user.id} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default Connections;

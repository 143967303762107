import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'actions';
import { Field, Formik, Form } from 'formik';
import { TextInput } from 'components/Form';
import { Card, Button } from 'components/UI';
import { gfxPassword } from 'assets';
import * as Yup from 'yup';
import notify from 'utils/notification';

const PasswordSchema = Yup.object({
  password: Yup.string().required('Current password is required'),
  new_password: Yup.string()
    .required('Password is required')
    .min(8, 'Password is too short, should be at least 8 characters'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('new_password'), null], 'Password must match'),
});

const Password = () => {
  const dispatch = useDispatch();
  const [isToggled, setToggle] = useState();
  const updatePassword = ({ confirmPassword, ...passwords }) =>
    dispatch(
      Actions.updatePassword(passwords, {
        success: () => {
          notify('Password updated!');
          setToggle(false);
        },
        failure: (error) =>
          notify(error || 'Failed to update password', null, 'danger'),
      })
    );

  return (
    <div className="mt-8 mb-6">
      <h3 className="uppercase mb-4 font-semibold">PASSWORD</h3>
      <Card className="max-w-120 min-w-80 flex flex-col p-4 sm:p-5 justify-center">
        <div className="flex justify-around items-center">
          <img src={gfxPassword.default} alt="password-icon" className="h-12" />
          <p className="mx-4">Need to change your password?</p>
          <div
            className={`cursor-pointer ${
              isToggled ? 'text-dusty' : 'text-easter'
            }`}
            onClick={() => setToggle(!isToggled)}
            role="button"
            tabIndex="0"
          >
            {isToggled ? 'Cancel' : 'Change'}
          </div>
        </div>
        {isToggled && (
          <div className="mt-8 pt-8 border-t border-gray-300">
            <Formik
              initialValues={{
                password: '',
                new_password: '',
                confirmPassword: '',
              }}
              onSubmit={updatePassword}
              validationSchema={PasswordSchema}
            >
              <Form>
                <Field
                  name="password"
                  component={TextInput}
                  placeholder="Current password"
                  type="password"
                />
                <Field
                  name="new_password"
                  component={TextInput}
                  placeholder="New password"
                  type="password"
                />
                <Field
                  name="confirmPassword"
                  component={TextInput}
                  placeholder="Confirm new password"
                  type="password"
                />
                <Button
                  className="block mx-auto mt-6"
                  label="Update Password"
                  type="submit"
                />
              </Form>
            </Formik>
          </div>
        )}
      </Card>
    </div>
  );
};

export default Password;

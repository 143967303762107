const NAME = 'EVENTS/UNATTEND_EVENT';

export const UNATTEND_EVENT_REQUEST = `${NAME}_REQUEST`;
export const UNATTEND_EVENT_SUCCESS = `${NAME}_SUCCESS`;
export const UNATTEND_EVENT_FAIL = `${NAME}_FAIL`;

export const unattendEvent = (id, callbacks) => ({
  type: UNATTEND_EVENT_REQUEST,
  id,
  callbacks,
});

export const unattendEventSuccess = () => ({
  type: UNATTEND_EVENT_SUCCESS,
});

export const unattendEventFail = (error) => ({
  type: UNATTEND_EVENT_FAIL,
  error,
});

import Actions from 'actions';
import uniqBy from 'lodash/uniqBy';

const initialState = Object.freeze({ allData: [] });

function list(state = initialState, { type, data, page, error }) {
  switch (type) {
    case Actions.FETCH_EVENTS_SUCCESS:
      return {
        featuredEvent:
          state.featuredEvent ||
          data.find(({ featured }) => featured) ||
          data[0],
        data,
        page,
        allData:
          page?.currentPage === 1
            ? data
            : uniqBy([...state.allData, ...data], 'id'),
      };
    case Actions.FETCH_EVENTS_FAIL:
      return {
        ...state,
        error,
      };
    default:
      return state;
  }
}

export default list;

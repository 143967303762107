import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ShareIcon from '@material-ui/icons/Share';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';
import FadeIn from 'react-fade-in';
import styled from '@emotion/styled';
import { iconCopyLink } from 'assets';
import notify from 'utils/notification';
import Picture from './Picture';

const Button = styled.button`
  height: 43.6px;
  width: 43.6px;
`;

const SIZE = 45;
const transitionClass = 'transition duration-500 opacity-50 hover:opacity-100';

const SocialMediaShare = ({
  title,
  url,
  summary,
  className,
  horizontal,
  linkName,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isHidden, setHidden] = useState(true);
  const isBtnHidden = isHidden && !isOpen;
  const buttons = [
    <WhatsappShareButton
      title={title}
      url={url}
      className={transitionClass}
      key="whatsapp"
    >
      <WhatsappIcon round size={SIZE} />
    </WhatsappShareButton>,
    <FacebookShareButton
      quote={title}
      url={url}
      className={transitionClass}
      key="facebook"
    >
      <FacebookIcon round size={SIZE} />
    </FacebookShareButton>,
    <TwitterShareButton
      title={title}
      url={url}
      className={transitionClass}
      key="twitter"
    >
      <TwitterIcon round size={SIZE} />
    </TwitterShareButton>,
    <EmailShareButton
      subject={title}
      body={summary}
      url={url}
      className={transitionClass}
      key="email"
    >
      <EmailIcon round size={SIZE} />
    </EmailShareButton>,
    <Button
      className={`rounded-full cursor-pointer ${transitionClass}`}
      onClick={() => {
        navigator.clipboard.writeText(url);
        notify(`${linkName} link copied!`);
      }}
      type="button"
      key="copy"
    >
      <Picture src={iconCopyLink} />
    </Button>,
  ];

  return (
    <>
      <div className={`sticky top-1/2 mr-3 z-10 float-right ${className}`}>
        <div className="flex flex-col space-y-4 items-center sm:hidden">
          <FadeIn
            visible={isOpen}
            delay={25}
            className="flex flex-col space-y-4 items-center"
            onComplete={() => setHidden(!isOpen)}
            childClassName={isBtnHidden ? 'invisible' : ''}
          >
            {buttons}
          </FadeIn>
          <button
            className="h-12 w-12 rounded-full bg-easter bg-opacity-75 flex items-center justify-center cursor-pointer"
            onClick={() => setOpen(!isOpen)}
            type="button"
          >
            <ShareIcon className="text-white" />
          </button>
        </div>
        <div
          className={classnames(
            `hidden sm:flex space-x-4 items-center mb-4 mt-5`,
            {
              'flex-col space-y-4 mt-0': !horizontal,
            },
            className
          )}
        >
          <p className={classnames('hidden', { block: !horizontal })}>
            Share on
          </p>
          {buttons}
        </div>
      </div>
    </>
  );
};

SocialMediaShare.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  summary: PropTypes.string,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  linkName: PropTypes.string,
};

SocialMediaShare.defaultProps = {
  summary: '',
  className: '',
  horizontal: false,
  linkName: 'Article',
};

export default SocialMediaShare;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchMemberTier({ id, callbacks }) {
  try {
    const response = yield call(api.fetchMemberTier, id);
    yield put(Actions.fetchMemberTierSuccess(response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.fetchMemberTierFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchMemberTier() {
  yield takeLatest(Actions.FETCH_MEMBER_TIER_REQUEST, fetchMemberTier);
}

export default function* list() {
  yield all([fork(watchFetchMemberTier)]);
}

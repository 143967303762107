const NAME = 'EVENTS/ADD_EVENT_';

export const ADD_EVENT_REQUEST = `${NAME}REQUEST`;
export const ADD_EVENT_SUCCESS = `${NAME}SUCCESS`;
export const ADD_EVENT_FAIL = `${NAME}FAIL`;

export const addEvent = (id, callbacks) => ({
  type: ADD_EVENT_REQUEST,
  id,
  callbacks,
});

export const addEventSuccess = () => ({
  type: ADD_EVENT_SUCCESS,
});

export const addEventFail = () => ({
  type: ADD_EVENT_FAIL,
});

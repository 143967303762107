import Actions from 'actions';

const initialState = Object.freeze({});

function send(state = initialState, action) {
  switch (action.type) {
    case Actions.SEND_MESSAGE_REQUEST:
    case Actions.SEND_MESSAGE_SUCCESS:
    case Actions.SEND_MESSAGE_FAIL:
      return initialState;
    default:
      return state;
  }
}

export default send;

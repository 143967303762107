import Actions from 'actions';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import api from 'api';

function* updateSubscription({ data, callbacks }) {
  try {
    const response = yield call(api.updateSubscription, data);
    if (response) {
      yield put(Actions.updateSubscriptionSuccess());
      if (callbacks?.success) {
        yield call(callbacks.success);
      }
    } else {
      yield put(Actions.updateSubscriptionFail(response.error));
      if (callbacks?.failure) {
        yield call(callbacks.failure);
      }
    }
  } catch (error) {
    yield put(Actions.updateSubscriptionFail(error));
    if (callbacks?.failure) {
      yield call(callbacks.failure);
    }
  }
}

function* watchupdateSubscription() {
  yield takeLatest(Actions.UPDATE_SUBSCRIPTION_REQUEST, updateSubscription);
}

export default function* update() {
  yield all([fork(watchupdateSubscription)]);
}

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import useQuery from 'hooks/useQuery';
import isEmpty from 'lodash/isEmpty';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Breadcrumbs, Loading, PageMeta } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import { AuthContext } from 'utils/context';
import qs from 'query-string';
import Featured from './Featured';
import ArticleList from './List';

const Header = () => (
  <section className="flex flex-col justify-center items-center mt-10 mb-4 sm:my-10">
    <h2 className="font-semibold text-center">
      The Inside Info On All Things APAC
    </h2>
    <p className="text-dusty font-semibold text-center text-md lg:w-1/2">
      Read about the best people, places and platforms to reach and grow your
      audience across Asia Pacific.
    </p>
  </section>
);

const Articles = () => {
  const [, setSearchParams] = useSearchParams();
  const params = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();
  const { authenticated, hasAccess } = useContext(AuthContext);
  const memberPage = '/members/articles';
  const articlesPath = authenticated ? memberPage : '/articles';

  const [favouriteItems, setFavouriteItems] = useState([]);
  const [featured, setFeatured] = useState({});
  const allArticles = useSelector(Selectors.getPaginatedArticles);
  const articlePageMeta = useSelector(Selectors.articlePageMeta);
  const categoriesExpired = useSelector(
    Selectors.isArticleCategoriesCacheExpired
  );
  const allFavouritedArticles = useSelector(
    Selectors.getPaginatedFavouriteArticles
  );
  const articleFavouritePageMeta = useSelector(
    Selectors.articleFavouritePageMeta
  );
  const { currentPage = 1, totalCount = 0, totalPages = 0 } = articlePageMeta;
  const {
    currentPage: currentPageFav = 1,
    totalCount: totalCountFav = 0,
    totalPages: totalPagesFav = 0,
  } = articleFavouritePageMeta;
  const showFavourites = params?.favourite === 'true';
  const defaultArticles = useSelector(Selectors.defaultArticles);
  const featuredArticle =
    defaultArticles.filter(({ featured } = {}) => featured)[0] ||
    defaultArticles[0] ||
    allArticles[0];

  useEffect(() => {
    if (isEmpty(defaultArticles) && !isEmpty(params)) setSearchParams(params);
  }, [defaultArticles, params, setSearchParams]);

  useEffect(() => {
    if (authenticated && hasAccess && !currentPath.includes(memberPage)) {
      const query = isEmpty(params) ? '' : `?${qs.stringify(params)}`;
      navigate(memberPage + query);
    }
  }, [authenticated, navigate, hasAccess, currentPath, params]);

  useEffect(() => {
    if (showFavourites && authenticated) {
      dispatch(Actions.fetchFavouriteArticles({ ...params, favourite: true }));
    } else {
      dispatch(Actions.fetchArticles({ ...params, favourite: false }));
    }
  }, [params, dispatch, showFavourites, authenticated]);

  useEffect(() => {
    dispatch(Actions.fetchCities({ has_articles: true, simple: true }));
    dispatch(Actions.fetchCountries({ has_articles: true }));
  }, [dispatch]);

  useEffect(() => {
    if (categoriesExpired) dispatch(Actions.fetchArticleCategories());
  }, [dispatch, categoriesExpired]);

  useEffect(() => {
    setFavouriteItems(allFavouritedArticles);
  }, [allFavouritedArticles]);

  useEffect(() => {
    if (isEmpty(featured) && featuredArticle) setFeatured(featuredArticle);
  }, [featuredArticle, featured]);

  const fetchMoreArticles = () =>
    showFavourites
      ? dispatch(
          Actions.fetchFavouriteArticles({
            ...params,
            page: currentPageFav + 1,
          })
        )
      : dispatch(Actions.fetchArticles({ ...params, page: currentPage + 1 }));

  const updateFavourites = (articleId) => {
    if (showFavourites) {
      setFavouriteItems(
        favouriteItems.filter((article) => article.id !== articleId)
      );
    }
  };

  if (isEmpty(featured)) return <Loading />;

  return (
    <div>
      <PageMeta title="Articles" />
      {hasAccess && (
        <Breadcrumbs
          trails={[{ label: 'Articles', path: articlesPath, icon: 'articles' }]}
        />
      )}
      <Featured article={featured} articlesPath={articlesPath} />
      <Header />
      <InfiniteScroll
        dataLength={showFavourites ? totalCountFav : totalCount}
        next={fetchMoreArticles}
        hasMore={
          showFavourites
            ? currentPageFav < totalPagesFav
            : currentPage < totalPages
        }
        scrollableTarget="scrollableContent"
        loader={
          <div className="flex pb-8">{!showFavourites && <Spinner />}</div>
        }
      >
        <ArticleList
          articlesPath={articlesPath}
          articles={showFavourites ? favouriteItems : allArticles}
          updateFavourites={(id) => updateFavourites(id)}
        />
      </InfiniteScroll>
    </div>
  );
};

export default Articles;

import React from 'react';
import { useQuery } from 'react-query';
import Selectors from 'selectors';
import { useSelector } from 'react-redux';
import api from 'api';

import { Breadcrumbs, PageMeta, NavigationTab } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import PointsHero from './PointsHero';
import ReferralInfoCard from './ReferraInfoCard';
import RewardsActivity from './RewardsActivity';

const EXPLORE_PATH = '/members/rewards/explore';
const TABS = Object.freeze([
  { name: 'Explore Rewards', path: EXPLORE_PATH },
  { name: 'My Rewards', path: '/members/rewards/my-rewards' },
]);

const MyRewards = () => {
  const { data: userPointsData, isLoading: isLoadingUserPoints } = useQuery(
    'userPointsData',
    api.fetchUserPoints
  );
  const {
    current_points: currentPoints,
    lifetime_points: lifetimePoints,
    premium_referrals: premiumReferrals,
    all_access_referrals: allAccessReferrals,
  } = userPointsData?.data || {};
  const { referral_code: referralCode } = useSelector(Selectors.getUser);

  return (
    <>
      <PageMeta title="Rewards" />
      <Breadcrumbs
        trails={[{ label: 'Rewards', path: EXPLORE_PATH }, 'My Rewards']}
      />
      <NavigationTab tabs={TABS} />
      <div className="py-1 max-w-full container mx-auto">
        {isLoadingUserPoints ? (
          <div className="self-center">
            <Spinner />
          </div>
        ) : (
          <>
            <PointsHero
              currentPoints={currentPoints}
              lifetimePoints={lifetimePoints}
              isLoadingUserPoints={isLoadingUserPoints}
            />
            <ReferralInfoCard
              referralCode={referralCode}
              premiumReferrals={premiumReferrals}
              allAccessReferrals={allAccessReferrals}
              isLoadingUserPoints={isLoadingUserPoints}
            />
          </>
        )}
        <RewardsActivity />
      </div>
    </>
  );
};

export default MyRewards;

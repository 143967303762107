import React from 'react';
import Slider from 'react-slick';
import { Picture } from 'components/UI';
import {
  partnership1,
  partnership2,
  partnership3,
  partnership4,
  partnership5,
  partnership6,
  partnership7,
  partnership8,
} from 'assets';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CONTENT = [
  partnership3,
  partnership4,
  partnership8,
  partnership1,
  partnership2,
  partnership6,
  partnership7,
  partnership5,
];

const PastPartnerships = () => {
  const settings = {
    infinite: true,
    slidesToShow: 4,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.6,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto px-6">
      <h2 className="text-center mb-10">Past Partnerships</h2>
      <Slider {...settings}>
        {CONTENT.map((item) => (
          <Picture src={item} key={item.default} />
        ))}
      </Slider>
    </div>
  );
};

export default PastPartnerships;

import React from 'react';
import Selectors from 'selectors';
import { Field } from 'formik';
import SelectInput from 'components/Form/SelectInput';
import TextInput from 'components/Form/TextInput';
import { YEAR_OPTIONS } from 'utils/helper';
import SocialMediaLinkInput from 'components/Form/SocialMediaLinkInput';
import { useSelector } from 'react-redux';

const WorkDetails = () => {
  const memberTypes = useSelector(Selectors.memberTypesList);
  const memberOptions = memberTypes.map(({ id, name }) => ({
    label: name,
    value: name,
    id,
  }));

  return (
    <div className="flex flex-col mx-5 mb-8 md:mb-3">
      <div className="font-chap text-gray-800 mb-1 text-2lg">
        Become a Member
      </div>
      <div className="text-gray-500 mb-8 text-xs">
        Filling this in will improve your chances of being approved for
        membership
      </div>
      <div className="flex flex-col mb-5">
        <Field
          name="industry_since"
          className="w-full"
          placeholder="Started working in the music industry"
          component={SelectInput}
          listHeight={150}
          options={YEAR_OPTIONS}
        />
        <Field
          name="memberTypes"
          isMulti
          creatable
          className="w-full"
          placeholder="Select member type(s)"
          component={SelectInput}
          listHeight={150}
          options={memberOptions}
        />
      </div>
      <Field
        name="company"
        fieldClassName="mt-3 mb-5"
        component={TextInput}
        placeholder="Current Company/Organisation"
      />
      <Field name="position" component={TextInput} placeholder="Job Position" />
      <p className="text-xs text-dusty mt-3 mb-2">Years in the company</p>
      <div className="flex flex-col sm:flex-row justify-start">
        <Field
          name="year_start"
          component={SelectInput}
          listHeight={150}
          options={YEAR_OPTIONS}
          placeholder="Select Year"
        />
        <p className="mb-4 sm:mt-4 mx-auto sm:mx-8">To</p>
        <Field
          name="year_end"
          component={SelectInput}
          listHeight={150}
          options={[
            {
              label: 'Present',
              // allow to display dynamic year_end value, refer to MR 305
              value: ' ',
            },
            ...YEAR_OPTIONS,
          ]}
          placeholder="Select Year"
        />
      </div>
      <SocialMediaLinkInput
        className="w-full"
        name="company_url"
        placeholder="Enter your company website…"
      />
    </div>
  );
};

export default WorkDetails;

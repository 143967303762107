import Actions from 'actions';

export const initialState = Object.freeze({ data: [], exp: null, page: {} });

function list(state = initialState, { type, data, exp, page }) {
  switch (type) {
    case Actions.FETCH_VIDEOS_SUCCESS: {
      return {
        exp,
        data: page?.currentPage > 1 ? [...state.data, ...data] : data,
        page: page || state.page,
      };
    }
    case Actions.FETCH_VIDEOS_FAIL:
      return { ...state, exp: null };
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default list;

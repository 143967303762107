import Actions from 'actions';

export const initialState = Object.freeze([]);

function users(state = initialState, { type, data }) {
  switch (type) {
    case Actions.FETCH_SENDBIRD_USERS_SUCCESS:
      return data;
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default users;

import SendBird from 'sendbird';
import CONFIG from 'utils/config';

let sendbird;

const GROUP_CHANNEL_LIMIT = 99;

export const initSendBird = () => {
  sendbird = new SendBird({
    appId: CONFIG.SENDBIRD_APP_ID,
  });
};

export const connect = (userId) =>
  new Promise((resolve, reject) => {
    sendbird.connect(userId, (user, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(user);
    });
  });

export const createSbChannel = (userIds, groupName) =>
  new Promise((resolve, reject) => {
    const params = new sendbird.GroupChannelParams();
    params.isPublic = false;
    params.isEphemeral = false;
    params.isDistinct = userIds.length < 3;
    params.isSuper = userIds.length > GROUP_CHANNEL_LIMIT;
    params.addUserIds(userIds);
    params.customType = groupName;

    sendbird.GroupChannel.createChannel(params, (channel, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(channel);
    });
  });

export const listSbChannels = (userId) =>
  new Promise((resolve, reject) => {
    const filteredQuery = sendbird.GroupChannel.createMyGroupChannelListQuery();
    filteredQuery.userIdsIncludeFilter = [userId];
    filteredQuery.next((channels, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(channels);
    });
  });

export const updateSbUserProfile = (name, picture) =>
  new Promise((resolve, reject) => {
    sendbird.updateCurrentUserInfo(name, picture, (user, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(user);
    });
  });

export const listSbMessages = (channel) =>
  new Promise((resolve, reject) => {
    const prevMessageListQuery = channel.createPreviousMessageListQuery();
    prevMessageListQuery.limit = 50;
    prevMessageListQuery.load((messages, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(messages);
    });
  });

export const sendSbMessage = (channel, text) =>
  new Promise((resolve, reject) => {
    channel.sendUserMessage(text, (message, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(message);
    });
  });

export const sendSbFileMessage = (channel, file) => {
  const params = new sendbird.FileMessageParams();
  params.file = file;

  return new Promise((resolve, reject) => {
    channel.sendFileMessage(params, (message, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(message);
    });
  });
};

export const getSbTotalUnreadCount = () =>
  new Promise((resolve, reject) => {
    sendbird.getTotalUnreadMessageCount((count, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(count);
    });
  });

export const disconnectSb = () => {
  sendbird.disconnect();
};

export const leaveSbChannel = (channel) =>
  new Promise((resolve, reject) => {
    channel.leave((message, error) => {
      if (error) {
        return reject(error);
      }
      return resolve(message);
    });
  });

import Actions from 'actions';

const initialState = Object.freeze({});

function unread(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_TOTAL_UNREAD_COUNT_REQUEST:
    case Actions.GET_TOTAL_UNREAD_COUNT_FAIL:
      return initialState;
    case Actions.GET_TOTAL_UNREAD_COUNT_SUCCESS:
      return {
        ...initialState,
        data: action.data,
      };
    default:
      return state;
  }
}

export default unread;

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import EventCard from '../EventCard';
import EventsFilter from '../Filter';

const List = ({ events, eventsPath, featuredId }) => {
  const nonFeaturedEvents = events.filter(({ id }) => id !== featuredId);
  const secondFeatured =
    events.length > 3 &&
    (nonFeaturedEvents.find(({ featured }) => featured) ||
      nonFeaturedEvents[0]);
  const filteredEvents = secondFeatured
    ? events.filter(({ id }) => id !== secondFeatured.id)
    : events;
  const positionFeatured = events.length > 6 ? 5 : 2;

  return (
    <div className="lg:container mx-auto px-6 sm:mb-10 max-w-full">
      <EventsFilter />
      {!isEmpty(filteredEvents) ? (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {filteredEvents.map((item, index) => (
            <React.Fragment key={item.id}>
              <EventCard events={item} eventsPath={eventsPath} />
              {secondFeatured && index === positionFeatured && (
                <EventCard
                  events={secondFeatured}
                  key={secondFeatured.id}
                  eventsPath={eventsPath}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div className="p-10 text-center my-24">No events found</div>
      )}
    </div>
  );
};

List.propTypes = {
  events: PropTypes.array.isRequired,
  eventsPath: PropTypes.string,
  featuredId: PropTypes.string.isRequired,
};

List.defaultProps = {
  eventsPath: '/events',
};

export default List;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchArticleCategories() {
  try {
    const { data } = yield call(api.fetchArticleCategories);
    yield put(Actions.fetchArticleCategoriesSuccess(data));
  } catch (error) {
    yield put(Actions.fetchArticleCategoriesFail(error));
  }
}

function* watchFetchArticleCategories() {
  yield takeLatest(
    Actions.FETCH_ARTICLE_CATEGORIES_REQUEST,
    fetchArticleCategories
  );
}

export default function* categories() {
  yield all([fork(watchFetchArticleCategories)]);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Footer } from 'components/UI';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const FormFooter = ({ onCancel, onSubmit, isSubmitting, isUpdate }) => (
  <Footer>
    <div className="flex items-center w-full text-xs md:text-sm lg:text-base sm:px-12 justify-end">
      <button
        type="button"
        className="text-dusty hover:text-easter flex focus:outline-none"
        onClick={onCancel}
        disabled={isSubmitting}
      >
        CANCEL
      </button>
      <Button
        disabled={isSubmitting}
        type="button"
        isPill
        className="px-3 lg:px-8 ml-10"
        onClick={onSubmit}
        label={
          <div className="flex justify-center items-center">
            <p>{`${isUpdate ? 'UPDATE' : 'ADD'} CONSULTATION INFO`}</p>
            <DoubleArrowIcon fontSize="small" className="ml-1 lg:ml-3" />
          </div>
        }
      />
    </div>
  </Footer>
);

FormFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
};

export default FormFooter;

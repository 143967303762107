import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* createBooking({ data, callbacks }) {
  const { date, selectedConsultant, ...payload } = data;
  payload.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    const response = yield call(api.createBooking, payload);
    yield put(Actions.createBookingSuccess(response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.createBookingFail(error));
    if (typeof callbacks?.failure === 'function') {
      const err = error?.response?.data?.error;
      yield call(callbacks.failure, err ? err[0] : error);
    }
  }
}

function* watchCreateBooking() {
  yield takeLatest(Actions.CREATE_BOOKING_REQUEST, createBooking);
}

export default function* create() {
  yield all([fork(watchCreateBooking)]);
}

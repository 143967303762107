import { imgTableBenefits } from 'assets';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';

const YOUTUBE_IDS = {
  subscription: 'DSFADovsTTo',
  network: 'UAzWL_nK9KM',
  groupChat: 'vzYmU1KRzyk',
  consultation: 'ux0-DuAubk8',
  profile: 'uQ9TP6ZBpDQ',
  perks: 'ETI335ds2Xk',
  events: 'RPR-VM6eZbc',
};

const renderYoutubeVid = (youtubeId) =>
  `<iframe
      title="faq"
      height="400"
      width="750"
      src=https://www.youtube.com/embed/${youtubeId}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />`;

export const QUESTIONS = [
  {
    parent: 'about',
    children: [
      {
        id: 1,
        question: 'What is Gig Life Pro?',
        answer: `
          <div>
            Gig Life Pro is a Membership Platform for the music industry to learn more about how to do business in Asia Pacific and connect with other Industry to grow their business in these markets.
          </div>
          <br />
          <div>
            Gig Life Pro is also a consultancy helping festivals, conferences, brands and platforms connect with music industry professionals across the Asia Pacific.
          </div>
        `,
      },
      {
        id: 2,
        question: 'Why join the Gig Life Pro community?',
        answer:
          'Gig Life Pro is the best platform to learn more about the music business in Asia Pacific and to connect with the music industry globally. From bespoke online and offline networking events, access to a membership directory and useful content about each local music market, it is the one tool that music industry professionals need to grow their business in Asia Pacific and internationally.',
      },
      {
        id: 3,
        question: 'How do I know if I’m eligible to join Gig Life Pro?',
        answer: `
          Anyone at any level of working in the music industry or considering entering into the music industry can sign up for a <b>FREE</b> membership.
          <br /><br />
          For a <b>PREMIUM</b> membership you need to be actively working in the music industry or be an intern at music related business, be a Music Business Student, Musician (Producer, DJ, Songwriter).
          <br /><br />
          <b>ALL ACCESS</b> must be a C-level / Management / Owner for at least 6 years in one of these industries:
          <br/>
          <ul class="list-disc list-inside">
            <li>Labels (A&R, Marketing, Project Managers, etc)</li>
            <li>Managers</li>
            <li>Tour Managers</li>
            <li>Agents</li>
            <li>Promoters (Festivals, Touring Companies)</li>
            <li>Venues (Owners, Venue Bookers, Marketing Team)</li>
            <li>Distributors </li>
            <li>Legal Counsel</li>
            <li>Publishers</li>
            <li>Synch Agencies</li>
            <li>Music Supervisors</li>
            <li>PR/Marketing Agencies</li>
            <li>Digital Service Providers / Online Music Platforms (Data, Streaming, Social, Content Management, Ticketing, etc)</li>
            <li>Business Administration</li>
            <li>Accounting</li>
            <li>Studio Owners</li>
            <li>Equipment Providers (Production Companies, Backline, AV)</li>
            <li>Engineers</li>
            <li>Music Photographer  </li>
            <li>Creative Agencies</li>
            <li>Music videographer/video editor</li>
            <li>Graphic Designer</li>
            <li>Music content creator</li>
            <li>Music Visual Artist</li>
          </ul>
          `,
      },
      {
        id: 4,
        question: 'Who is Gig Life Pro NOT for?',
        answer: `
          <ul class="list-disc list-inside">
            <li>Government Agencies</li>
            <li>Brands</li>
            <li>Media</li>
            <li>People not related to the music industry at all</li>
          </ul>
          `,
      },
      {
        id: 5,
        question:
          'What is the difference between a FREE, PREMIUM and ALL ACCESS membership?',
        answer: `<img src=${imgTableBenefits.default} />`,
      },
      {
        id: 6,
        question: 'How much does PREMIUM and ALL ACCESS membership cost?',
        answer:
          'All Access is USD $50/mth or USD $500/ year ($100 savings) and Premium is USD $15/mth or USD $150/year ($30 savings)',
      },
      {
        id: 7,
        question: 'Can I join from any country or must I reside in APAC?',
        answer:
          'Gig Life Pro is open to members globally who are interested in doing more business in and connecting with the music industry from markets in the Asia Pacific.',
      },
    ],
  },
  {
    parent: 'membership',
    children: [
      {
        id: 1,
        question: 'I have submitted my application, now what?',
        answer:
          'We are committed to keeping this community for those looking to enter or professionals that work in the music industry only, we may take a few days to vet each application. You will receive an email 2-3 working days after your application has been submitted with the outcome.',
      },
      {
        id: 2,
        question: 'I was approved! Now what?',
        answer:
          'Once approved you will receive an email that will prompt you to “Activate Your Account”. This will take you back to the Gig Life Pro website where you can complete your profile by adding a picture of yourself and share more about yourself.',
      },
      {
        id: 3,
        question: `I applied for ${ALL_ACCESS}, but got approved for ${PREMIUM}. Why is that?`,
        answer: `After reviewing your application we felt like you did not meet our ${ALL_ACCESS} criteria, but do feel like you will get and add value at the ${PREMIUM} level until you do qualify for ${ALL_ACCESS}.`,
      },
      {
        id: 4,
        question:
          'I was not approved for any level of Gig Life Membership - why? Can I reapply?',
        answer:
          'After reviewing your application we felt that you are not a music industry professional. If you would like to appeal this decision, please email more information showing what you do in the music industry to our <b>Community Manager</b>: community@giglifepro.com',
      },
      {
        id: 5,
        question:
          'Change of Membership - Upgrade, Downgrade, Cancel, or Delete',
        answer: `
          <div class="space-y-5">
            <div>
              <b>Check Subscription:</b>
              <p>
                In your “<b>Account Settings</b>”, you can check when your subscription is due or set to renew, switch between annual and monthly plans as well as upgrade from a <b>FREE</b> or <b>PREMIUM</b> to <b>ALL ACCESS</b>, assuming you meet the requirements for <b>ALL ACCESS</b>.
              </p>
            </div>
            <div>
              <b>Upgrade Membership:</b>
              <div class="space-y-2">
                <p>Upgrading from tier to tier is automatic, you will not need to wait for the approval.</p>
                <p>
                  Please note from <b>FREE</b> or <b>PREMIUM</b> to an <b>ALL ACCESS</b> membership you will need to be eligible (Min 6 years working in the music industry in one of the above roles or be Management / C-Level / Owner).
                </p>
                <p>
                  Your upgrade will take effect immediately, and you can start enjoying the benefits of your <b>PREMIUM</b> or <b>ALL ACCESS</b> membership.
                </p>
                <p>
                  <b>PREMIUM to ALL ACCESS Annual membership</b> - your <b>prorated charges</b> for an annual membership will be processed at the time of your upgrade and change USD  $150 / USD $500 per year.
                </p>
              </div>
            </div>
            <div>
              <b>Downgrade Or Delete:</b>
              <div class="space-y-2">
                <div>
                  <p>You may downgrade or cancel your subscription anytime.</p>
                  <p class='italic'>
                    * Please note when downgrading your account the changes will be made from your next billing cycle.
                  </p>
                </div>
                <p>In your Dashboard menu, go to “<b>Account Settings</b>” to downgrade.</p>
                <p>
                  If you wish to pause your payments on a <b>PREMIUM</b> or <b>ALL ACCESS</b> membership you will be automatically moved down to the <b>FREE</b> tier membership with limited access until you re-activate your paid subscription. Please view the membership table <a href="https://giglifepro-assets.s3.ap-southeast-1.amazonaws.com/img_table_benefits.png" class="text-easter" target="_blank" rel="noreferrer">HERE</a>
                </p>
                <p>
                  To delete your subscription, you will need to downgrade to the <b>FREE</b> tier, once your downgrade has been processed at the end of your current billing cycle the option to <b>DELETE</b> your account will appear.
                </p>
              </div>
            </div>
          </div>
          `,
      },
      {
        id: 6,
        question: 'How do I pause or cancel my membership?',
        answer: `
        In your Dashboard menu, you can go to <b>“Account Settings”</b> where you can pause or cancel your membership at any time.
        <br /><br />
        You can check when your subscription is set to renew, switch between annual and monthly plans, as well as switch from <b>FREE to PREMIUM</b> or <b>PREMIUM to ALL ACCESS</b>, assuming you meet the requirements for <b>ALL ACCESS</b>. You can also Pause your subscription when you need to where your access to the platform will be limited to Account Settings and your Profile until you re-activate.
        `,
      },
      {
        id: 7,
        question: 'How do I change my credit card details?',
        answer: `Log into your account, within your membership dashboard scroll to the very bottom until <b>“Account Settings”</b> appears, click on <b>“Account Settings”</b>, your membership card will appear, click change under “My Credit / Debit Card”.`,
      },
      {
        id: 8,
        question: 'Can I pay via bank transfer?',
        answer:
          'We do accept bank transfers - please email finance@giglifepro.com for more information.',
      },
      {
        id: 9,
        question: 'I forgot my password, help!!',
        answer: `
          No problem!  When you go to login, click on <b>Forgot My Password</b>. You will receive an email with a password reset code that will allow you to reset your password on the platform.
          <br/ ><br/ >
          <b>Account Settings</b> is also where you can reset your password and decide if you want to receive email notifications before your subscription is charged.
          `,
      },
      {
        id: 10,
        question: 'How do I update my profile?',
        answer: `The left hand side of his dashboard has a menu that includes “Profile”. Here you will be able to update your profile and particulars, as well as update each section of your profile and add playlists.
          <br />
          <br />
          ${renderYoutubeVid(YOUTUBE_IDS.profile)}`,
      },
      {
        id: 11,
        question: 'How do I make my email visible to other members?',
        answer: `Log into your account on the membership dashboard click “Profile”. Edit your profile using the pencil icon on the top right and select your desired &apos;Email Visibility&apos; under your profile name. To confirm your status you can click on the symbol of the eye on your profile by your email address.`,
      },
      {
        id: 12,
        question:
          'What are the rules of engagement for the Gig Life Pro platform?',
        answer:
          'Gig Life Pro is a safe space for those starting their career in the music industry and professionals. We do not tolerate harassment or vulgarity of any kind and anybody caught behaving in this way will be promptly removed from the platform with no refunds offered.',
      },
      {
        id: 13,
        question: 'Blocking and reporting users or content',
        answer: `
        <u><b>How do I report inappropriate behaviour?</b></u>
        <br></br>
        <b>Website</b>
        <br></br>
        Go to the profile of the member you would like to report - click on the profile > click on the top right
        options button (three dots) and select <b>Block</b> user or <b>"Block and Report user"</b>
        <br></br>
        <b>Mobile App</b>
        <br></br>
        Go to network click on the user profile > click on more options on the top right,
        a pop-up would appear and select block user or block and report user.
        <br></br>
        * If you would like to flag the behaviour with the Gig Life Pro staff, you must select block and report
        <br></br>
        <b>How do I unblock a user?</b>
        <br></br>
        Go account settings > scroll down to blocked users and select the user you wish to unblock and select <b>Unblock</b>
        `,
      },
      {
        id: 14,
        question:
          'Does each member of our company have to sign up individually?',
        answer: `
        Gig Life Pro is based on individual membership.
        <br /><br />
        * We do offer discounted corporate memberships, please contact our <b>Partnership Manager: partnerships@giglifepro.com</b>

        `,
      },
      {
        id: 15,
        question: 'How do I cancel my membership?',
        answer:
          'In your Dashboard menu you can go to “<b>Settings</b>” where you can cancel your membership before 30 days of the next billing cycle.',
      },
      {
        id: 16,
        question: 'Is my data Protected?',
        answer: 'Refer to <u><a href="/terms">Terms of Use</a></u>',
      },
      {
        id: 17,
        question: 'Assistance and contacting a team member of Gig Life Pro',
        answer:
          '<b>For membership assistance please contact our Gig Life Pro Community Manager:</b> community@giglifepro.com',
      },
    ],
  },
  {
    parent: 'events',
    children: [
      {
        id: 1,
        question: 'Promote your own events & receive exclusive events invites',
        answer: `${renderYoutubeVid(YOUTUBE_IDS.events)}`,
      },
      {
        id: 2,
        question: 'How do I submit my own event listing?',
        answer: `
        You can upload your own events to promote festivals, online shows, gigs and webinars to other members and the general public. Your published event will be listed within the members portal and on the Gig Life Pro website.
        <br/><br/>
        <ul class="list-decimal list-inside">
          <li>Login in to your GLP pro account</li>
          <li>Click on <b>“Events”</b> in your member dashboard</li>
          <li>Click on <b>“My Events”</b> and > click on <b>“Create Event”</b>, fill in the form, add promotional code (optional to offer Gig Life Pro members discounts), ticketing link etc.</li>
          <li>
          Your event will need to be approved by the Gig Life team, once you&apos;ve filled in the form and added artwork, hit <b>“Create Event”</b> down the bottom right corner of the page the Gig Life Pro team will receive a notification to check and approve your event for publishing.
          </li>
        </ul>
          `,
      },
      {
        id: 3,
        question: 'Who will see my event listing?',
        answer:
          'Events listings are visible to members and the general public.',
      },
      {
        id: 4,
        question: 'How do I attend a Gig Life Pro event?',
        answer:
          'All Gig Life Pro events you are eligible to attend will be in the Events section.  You can go into the event and click <b>“Book Slot”</b>,  click <b>“Ok”</b> a calendar option will appear, add to your preferred calendar and a zoom link replaces the book slot for you to login on the day of the event.  If the event is offline, you will be sent an email with more information about how to access the event.',
      },
    ],
  },
  {
    parent: 'locations',
    children: [
      {
        id: 1,
        question:
          'How do I request for my content to be updated on a location page?',
        answer:
          'If you think we are missing a Location or want to include/have something update something on our Locations, please email: community@giglifepro.com',
      },
    ],
  },
  {
    parent: 'articles',
    children: [
      {
        id: 1,
        question:
          'How do I pitch for music industry related news to appear on Gig Life Pro?',
        answer:
          'If you have news you think that the music industry would be interested in learning more about, please email editorial@giglifepro.com',
      },
      {
        id: 2,
        question:
          'How do I pitch myself or my company for an interview on Gig Life Pro?',
        answer:
          'If you would like to pitch yourself or your company for feature coverage, please email editorial@giglifepro.com',
      },
    ],
  },
  {
    parent: 'network',
    children: [
      {
        id: 1,
        question: 'How to filter your network',
        answer: `${renderYoutubeVid(YOUTUBE_IDS.network)}`,
      },
      {
        id: 2,
        question: 'How do I connect with other members?',
        answer: `
        <ul class="list-decimal list-inside">
          <li>
          Log into your account, within your membership dashboard you will see <b>“Network”</b>, click on network, you can then begin searching by name or click “Show Filter” to begin searching by country, city or member type.
            <ul class="list-disc list-inside">
              <li>Click on individual members and click “Message” located on the right hand or profile page to commence a direct chat.</li>
            <ul>
          </li>
          <br/>
          <li>Log into your account, within your membership dashboard you will see <b>“Chat”</b>, click on chat, you can begin searching by name within the <b>“Search Name”</b> bar</li>
        </ul>`,
      },
      {
        id: 3,
        question: 'How do I start a group message?',
        answer: `
        Log into your account, within your membership dashboard you will see <b>“Chat”</b>, click on chat, > click on <b>“Groups”</b> > click on <b>“Create Group Chat”</b>
          <br />
          <br />
          ${renderYoutubeVid(YOUTUBE_IDS.groupChat)}
          `,
      },
    ],
  },
  {
    parent: 'consultation',
    children: [
      {
        id: 1,
        question: 'How to set up a consultation',
        answer: `
        One on one consulting with our industry experts is a great way to accelerate your plans to grow your business across Asia Pacific.
        <br /><br />
        Head to Consultation and start by selecting who you would like to speak to, based on your needs and their experience.
        <br /><br />
        Select from the available date and time options and be sure to include as much information about yourself and what you are looking for to help our consultants determine if and how they can add value.
        <br /><br />
        If you are not a Gig Life Pro Member you will be asked to enter your payment details to confirm the booking. Gig Life Pro Members will receive 50% off of an hourly consultation.
        <br /><br />
        Within 2 working days of your request, the consultant will email you if they are able to confirm the booking and may include a few additional questions to better assist you during the call.  Payment is charged upon confirmation of the booking by the consultant.
        <br /><br />
        You will also receive a calendar invite with a Zoom link to participate in the call.
        <br /><br />
        The consultation can be rescheduled by either party via email up to 48 hours prior to the scheduled meeting.  It can be cancelled via email no less than 72 hours or 3 working days prior to the meeting for a full refund.
        <br /><br />
        ${renderYoutubeVid(YOUTUBE_IDS.consultation)}
        `,
      },
    ],
  },
  {
    parent: 'perks',
    children: [
      {
        id: 1,
        question: 'What are Perks?',
        answer: `
          We have a members only perks section within your membership dashboard, here you will find
          Gig Life Pro members only offers discounts on industry related business, events, music etc.
          <br /><br />
          * Please note Perks are only visible to Gig Life Pro members.
          <br /><br />
          ${renderYoutubeVid(YOUTUBE_IDS.perks)}
          `,
      },
      {
        id: 2,
        question: 'How do I redeem Perks?',
        answer:
          'Under perks on your dashboard, you can click on individual perks to find the full information on how to redeem, this will either be via a Code or submission form or direct email, depending on each business.',
      },
      {
        id: 3,
        question: 'How do I upload my own company perks?',
        answer: `
        You can upload your own perk and promote your business, events or music directly to other members.
        <br/><br/>
        <ul class="list-decimal list-inside">
          <li>Login in to your GLP pro account</li>
          <li>Click on <b>“Perks”</b> in your member dashboard</li>
          <li>Click on <b>“My Perks”</b> and > click on <b>“Create Perk”</b>, fill in the form, add promotional code and redemption link, or email and press create link.</li>
          <li>
            Your perk will need to be approved by the Gig Life team.
            <br />
            Don't forget to check <b>“Notify Members”</b>, so that all our members will receive this offer.
            <br />
            Once you've filled in the form and added artwork, hit <b>“Create Perk”</b> down the bottom right corner of the page the Gig Life Pro team will receive a notification to check and approve your perk for publishing.
          </li>
        </ul>
        `,
      },
    ],
  },
  {
    parent: 'subscription and payments',
    children: [
      {
        id: 1,
        question: 'Check / Manage Subscription on the website',
        answer: `
        Do note that you may only use this method if you've subscribed through the website.
        <br></br>
        In your “Account Settings,” you can check when your subscription is due or set to renew,
        switch between annual and monthly plans as well as upgrade from a FREE or PREMIUM to ALL ACCESS,
        assuming you meet the requirements for ALL ACCESS.`,
      },
      {
        id: 2,
        question: 'Check / Manage Subscriptions on the mobile app',
        answer: `
        In your "<b>Account Settings</b>", you can check your current subscription status and when it is due or set to renew.
        <br></br>
        Click on "<b>Manage subscriptions</b>" > Click on "<b>Manage current subscriptions</b>".
        <br></br>
        For <b>iOS</b> users, this would take you to the App Store where you can manage your subscription. For <b>Android</b> users, you will be directed
        to the Play Store instead. From there, you can have the option to upgrade or downgrade your subscription accordingly.
        <br></br>
        <b>Cancelling subscriptions on the mobile app</b>
        <br></br>
        You may downgrade or cancel your subscription anytime. For any downgrades, *please note that <i>changes will on be made on your next billing cycle</i>
        <br></br>
        <b>How do I pause my subscriptions?</b>
        <br></br>
        We do not offer the option to pause your subscription directly. You will need to downgrade to the FREE tier.
        You can then upgrade to a PREMIUM or ALL ACCESS when you would like to resume your paid subscription. Please view the membership table HERE
        <br></br>
        <b>What will happen to my subscription if I switch between iOS and Android?</b>
        <br></br>
        You will need to cancel your active subscription and then subscribe for a new one on your new mobile device platform.
        `,
      },
      {
        id: 3,
        question: 'Payment options and methods for paid subscriptions',
        answer: `
        <b>Website</b>
        <br></br>
        Subscriptions via the website can only be paid using credit/debit cards. 
        You can edit these card details in your Account Settings under “My Credit/Debit Card”
        We do accept bank transfers if none of the above payment methods works for you.
        Please email finance@giglifepro.com for more information.
        <br></br>
        For website subscriptions, we offer both monthy and annual subscriptions.
        <br></br>
        <b>Mobile App (iOS and Android)</b>
        <br></br>
        Subscriptions via the mobile app depend on the payment methods offered by Apple or Google.
        <br></br>
        Due to platform limitations, we are not able to offer annual subscription plans via the mobile app. 
        Please subscribe via the website if you want to sign up for an annual subscription.
        <br></br>
        <b>Mobile App Pricing Changes</b>
        <br></br>
        Pricing on iOS is subject to change based on currency and local tax requirements. If you
        are billed through Apple, you will be prompted directly with an update and to accept the
        new price and/or currency change in order to continue your membership.
        <br></br>
        For any further support, you can contact Apple support at <a href="support.apple.com/billing" class="text-easter">https://support.apple.com/billing</a>.
        `,
      },
      {
        id: 4,
        question: 'Subscription Upgrades',
        answer: `
        Upgrading from tier to tier is automatic, you will not need to wait for the approval.
        <br></br>
        Please note, upgrades to an <b>ALL ACCESS</b> membership will need to be eligible
        (Min 6 years working in the music industry in one of the above roles or be Management / C-Level / Owner).
        <br></br>
        Your upgrade will take effect immediately, and you can start enjoying the benefits of your <b>PREMIUM</b> or <b>ALL ACCESS membership</b>.
        <br></br>
        <b>PREMIUM</b> to <b>ALL ACCESS Annual membership</b> - your prorated charges for an annual membership will be processed at the time of your upgrade
        and change USD  $150 / USD $500 per year.`,
      },
      {
        id: 5,
        question: 'Downgrade or Delete',
        answer: `
        You may downgrade or cancel your subscription anytime. 
        <br></br>
        * Please note when downgrading your account the changes will be made from your next billing cycle.
        In your Dashboard menu, go to “Account Settings” to downgrade.
        <br></br>
        If you wish to pause your payments on a PREMIUM or ALL ACCESS membership you will be automatically moved
        down to the FREE tier membership with limited access until you re-activate your paid subscription.  Please
        view the membership table <a href="https://giglifepro-assets.s3.ap-southeast-1.amazonaws.com/img_table_benefits.png" class="text-easter" target="_blank" rel="noreferrer">HERE</a>
        <br></br>
        <b>To delete your subscription</b>, you will need to downgrade to the FREE tier, once your downgrade has been processed
        at the end of your current billing cycle the option to DELETE your account will appear.
        `,
      },
      {
        id: 6,
        question: 'Managing credit card details',
        answer: `
        <b>Website</b>
        <br></br>
        Log into your account, within your membership dashboard scroll to the very bottom until <b>“Account Settings”</b> appears,
        click on <b>“Account Settings”</b>, and your membership card will appear, click change under <b>“My Credit / Debit Card</b>”. 
        <br></br>
        <b>iOS</b>
        <br></br>
        We do not store any payment information made through the App Store.
        Follow these steps to update your Apple billing information at <a href="https://support.apple.com/en-gb/HT201266" class="text-easter">https://support.apple.com/en-gb/HT201266</a>
        <br></br>
        <b>Android</b>
        <br></br>
        Log into your account, within your membership dashboard scroll to the very bottom until <b>“Account Settings”</b> appears,
        click on <b>“Account Settings”</b>, and your membership card will appear, click change under <b>“My Credit / Debit Card”</b>. 
        `,
      },
      {
        id: 7,
        question: 'Requesting refunds',
        answer: `
        <b>Website</b>
        <br></br>
        Please email one of our friendly staff at finance@giglifepro.com
        <br></br>
        <b>iOS</b>
        <br></br>
        We do not refund subscriptions ordered via the mobile app store. Please follow the steps here:
        <a href="https://support.apple.com/en-us/HT204084" class="text-easter" >https://support.apple.com/en-us/HT204084</a>
        <br></br>
        <b>Android</b>
        <br></br>
        Please email one of our friendly staff at finance@giglifepro.com.
        Alternatively, you may also request a refund directly from Google`,
      },
      {
        id: 8,
        question: 'Restoring mobile subscriptions',
        answer: `
        <b>iOS and Android</b>
        <br></br>
        To restore your subscription manually, you may go to <b>Account Settings</b> > <b>Manage Subscriptions</b>,
        then select <b>Restore Subscription</b>.Your active subscription tied to your Apple ID/Google Account would then be synced
        with your current Gig Life Pro account.
        <br></br>
        * Do note that if you start a new subscription on a new Gig Life Pro account with
        an existing Apple ID/Google Acount which has an active subscription,
        your active subscription will be transferred automatically to the new Gig Life Pro account.
        * No additional steps will be required.`,
      },
    ],
  },
];

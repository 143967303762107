import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from 'components/UI';
import { displaySubscriptionDateFormat } from 'utils/datetime';
import { urlHandler } from 'utils/helper';
import { BackgroundSection } from '../LandingPage/common';
import MemberAccessBadge from './MemberAccessBadge';

const PerkTileCard = ({ perk, path }) => {
  const {
    id,
    name,
    summary,
    member_access: memberAccess,
    image_url: img,
    end_date: expiry,
  } = perk;

  return (
    <Link to={path || id}>
      <Card className="relative h-full">
        <BackgroundSection
          backgroundImage={`url(${urlHandler(img)})`}
          className="relative bg-no-repeat bg-cover bg-center h-56 rounded-t-lg"
        />
        <div className="m-4 absolute top-0 left-0">
          <MemberAccessBadge memberAccess={memberAccess} />
        </div>
        <div className="p-4 flex flex-col">
          <h4 className="font-lynstone">{name}</h4>
          <div className="text-dusty mb-3 overflow-y-scroll h-16">
            {summary}
          </div>
          <div className="flex flex-row justify-end text-dusty text-xs mt-auto">
            {`Expires ${displaySubscriptionDateFormat(expiry)}`}
          </div>
        </div>
      </Card>
    </Link>
  );
};

PerkTileCard.propTypes = {
  perk: PropTypes.object.isRequired,
  path: PropTypes.string,
};

PerkTileCard.defaultProps = {
  path: null,
};

export default PerkTileCard;

import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { RadioButton } from 'components/UI';
import classnames from 'classnames';

const RadioButtonInput = ({
  form: { setFieldValue, touched, errors },
  field: { name, value },
  options,
  downsize,
}) => {
  const error = getIn(touched, name) && getIn(errors, name);

  return (
    <>
      <div
        className={classnames('flex flex-col lg:flex-row', {
          'lg:flex-col': downsize,
        })}
      >
        {options.map(({ label, value: optionValue }) => (
          <RadioButton
            onClick={() => setFieldValue(name, optionValue)}
            label={label}
            selected={value === optionValue}
            key={label}
            downsize
          />
        ))}
      </div>
      <div className="text-valencia h-4 mt-1 text-xs font-light">{error}</div>
    </>
  );
};

RadioButtonInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  downsize: PropTypes.bool,
};

RadioButtonInput.defaultProps = {
  downsize: false,
};

export default RadioButtonInput;

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/UI';
import { getIn } from 'formik';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

const SelectInput = (props) => {
  const {
    className,
    options,
    field: { name, onBlur, value: fieldValue },
    form: { errors, setFieldValue, touched },
    onChange,
    isMulti,
    placeholder,
    label,
    getOptionValue,
    showError,
    creatable,
  } = props;

  const error = getIn(touched, name) && getIn(errors, name);

  const value = () => {
    if (Array.isArray(fieldValue)) return fieldValue;
    if (!fieldValue) {
      if (isMulti) return [];
      return {
        name: placeholder,
        label: placeholder,
        id: undefined,
        value: undefined,
      };
    }

    return options?.find((option) => getOptionValue(option) === fieldValue);
  };

  const handleChange = (e) => {
    if (isMulti) {
      setFieldValue(name, isEmpty(e) ? [] : e);
    } else {
      setFieldValue(name, e.value);
    }
    onChange(e || '');
  };

  return (
    <div className={className}>
      <div
        className={classnames(
          'ml-3 text-easter text-xs mb-1',
          'transition-all duration-400 ease-in-out',
          { '-mb-4 opacity-0': isEmpty(value) }
        )}
      >
        {(value && label) || placeholder}
      </div>
      <Select
        {...props}
        isMulti={isMulti}
        value={value()}
        onBlur={onBlur}
        onChange={handleChange}
        creatable={creatable}
      />
      {showError && (
        <div className="flex min-h-5 items-end text-valencia text-xs font-light mt-2">
          {error}
        </div>
      )}
    </div>
  );
};

SelectInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  getOptionValue: PropTypes.func,
  showError: PropTypes.bool,
  creatable: PropTypes.bool,
};

SelectInput.defaultProps = {
  className: '',
  onChange: () => {},
  showError: true,
  isMulti: false,
  placeholder: null,
  label: null,
  getOptionValue: ({ value }) => value,
  creatable: false,
};

export default SelectInput;

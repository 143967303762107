import { all, fork } from 'redux-saga/effects';

import create from './create';
import update from './update';
import setupIntent from './setupIntent';
import downgrade from './downgrade';
import cancelUpdate from './cancelUpdate';

export default function* subscription() {
  yield all([
    fork(create),
    fork(update),
    fork(setupIntent),
    fork(downgrade),
    fork(cancelUpdate),
  ]);
}

import Actions from 'actions';

const initialState = Object.freeze({});

function fetch(state = initialState, { type, data }) {
  switch (type) {
    case Actions.FETCH_CONSULTATION_SERVICE_SUCCESS:
    case Actions.CREATE_CONSULTATION_SERVICE_SUCCESS:
    case Actions.UPDATE_CONSULTATION_SERVICE_SUCCESS:
      return data;
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default fetch;

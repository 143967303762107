import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CONFIG from 'utils/config';
import Actions from 'actions';
import Selectors from 'selectors';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { modalHeader, gfxBookingSent } from 'assets';
import { Card, Picture, ErrorContainer, Button } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import { displayConsultationDateTime } from 'utils/datetime';
import { AuthContext } from 'utils/context';
import isEmpty from 'lodash/isEmpty';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';
import { BackgroundSection } from '../../LandingPage/common';
import CreditCard from '../../Subscription/CreditCardDetails';
import AddToCalendar from '../../../components/UI/AddToCalendar';

const stripePromise = loadStripe(CONFIG.STRIPE_PUB_KEY);
const genericError = 'Something Went Wrong. Please try again';

const CARD_ELEMENT_STYLES = {
  style: {
    base: {
      color: '#718196',
      fontWeight: 600,
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0afbf',
      },
    },
    invalid: {
      color: '#fc8181',
      iconColor: '#fc8181',
    },
  },
};

const PaymentModal = ({ bookingData, closeModal, isSubscribed, handleTab }) => {
  const { isFreeTier } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [bookingSent, setBookingSent] = useState(false);
  const {
    selectedConsultant: { name, rate, discounted_rate: discountedRate },
    start_at: startAt,
    customer_email: email,
    customer_name: customerName,
  } = bookingData;
  const finalRate = discountedRate || rate;
  const creditCard = useSelector(Selectors.creditCardDetails) || {};
  const { start_at: start, end_at: end, consultant } = useSelector(
    Selectors.getConsultationBooking
  );
  const calendarProps = {
    start,
    end,
    title: `Consultation with ${consultant?.name}`,
  };

  useEffect(() => {
    dispatch(Actions.fetchUserCreditCard());
  }, [dispatch]);

  const hasPaymentMethod =
    !isEmpty(creditCard) || (isSubscribed && !isFreeTier);

  const redirectStripePortal = () =>
    dispatch(
      Actions.createPortalSession({
        success: ({ url }) => {
          window.location = url;
          return null;
        },
        failure: () =>
          notify(
            'Change is unavailable at the moment. Please try again later.',
            null,
            'danger'
          ),
      })
    );

  const CreditCardForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const disabledSubmitState = processing || error || disabled;
    const currentUser = useSelector(Selectors.getUser);
    const setupIntentData = useSelector(Selectors.getSetupIntent);
    const disabledExistingCard = isEmpty(creditCard) || processing || error;
    const isBtnDisabled = hasPaymentMethod
      ? disabledExistingCard
      : disabledSubmitState;
    const callbacks = {
      success: () => setBookingSent(true),
      failure: (err) => {
        setProcessing(false);
        setError(err);
      },
    };

    const createBooking = () =>
      dispatch(Actions.createBooking(bookingData, callbacks));

    const handleCardOnChange = (event) => {
      setDisabled(event.empty);
      setError(event.error ? event.error.message : '');
    };

    const saveCreditCard = () => {
      setProcessing(true);
      if (!stripe || !elements) return setProcessing(false);
      if (!isEmpty(setupIntentData))
        return handleStripeSetupIntent(setupIntentData);
      dispatch(
        Actions.setupIntent(
          { email: email || currentUser?.email },
          handleStripeSetupIntent
        )
      );
    };

    const handleStripeSetupIntent = ({ secret, customer }) =>
      stripe
        .confirmCardSetup(secret, {
          payment_method: { card: elements.getElement(CardElement) },
        })
        .then((result) => {
          if (result.error) {
            setError(result.error?.message || genericError);
            setProcessing(false);
          } else {
            dispatch(
              Actions.createBooking(
                {
                  ...bookingData,
                  stripe_customer_id: customer,
                  // eslint-disable-next-line camelcase
                  payment_method: result?.setupIntent?.payment_method,
                },
                callbacks
              )
            );
          }
        });

    return (
      <>
        <div className="w-full sm:max-w-120">
          <div
            className={classnames(
              'flex flex-col items-center justify-center w-full p-4 rounded',
              { 'bg-squeeze': !hasPaymentMethod }
            )}
          >
            <p className="text-gull text-sm">Pay with credit/debit card</p>
            {!hasPaymentMethod && (
              <CardElement
                options={CARD_ELEMENT_STYLES}
                onChange={handleCardOnChange}
                className="w-full bg-white px-4 py-3 my-2 rounded sm:h-full"
              />
            )}
            {hasPaymentMethod && (
              <Card className="max-w-120 min-w-80 my-3 text-left">
                {isEmpty(creditCard) ? (
                  <div className="flex justify-center items-center py-4">
                    <Spinner height="40" color="#47bad4" />
                  </div>
                ) : (
                  <CreditCard
                    data={creditCard}
                    redirectStripePortal={redirectStripePortal}
                  />
                )}
              </Card>
            )}
            <button
              className={classnames(
                'flex justify-center w-full rounded bg-easter text-white text-lg py-2',
                { 'bg-boulder opacity-100 cursor-not-allowed': isBtnDisabled }
              )}
              type="submit"
              disabled={isBtnDisabled}
              onClick={hasPaymentMethod ? createBooking : saveCreditCard}
            >
              {processing ? (
                <Spinner color="#fff" height={20} />
              ) : (
                `Pay USD ${finalRate}`
              )}
            </button>
          </div>
          <p className="text-xs self-start mt-4 text-dusty text-center">
            *By clicking &quot;Pay&quot;, you agree to being charged once your
            booking is approved.
          </p>
        </div>
        <ErrorContainer className="max-w-120 mx-auto mt-4" error={error} />
      </>
    );
  };

  const PaymentDetails = () => (
    <>
      <div className="mb-4 sm:mb-8 text-ebony text-xs sm:text-sm sm:max-w-100">
        {`Please enter your payment details. `}
        <span className="font-semibold">You will only be charged</span> once
        your booking is approved.
      </div>
      <div className="flex justify-center items-center flex-col w-full sm:w-3/5 text-sm sm:text-base mb-6 sm:mb-8 gap-3">
        <div className="mb-3 font-semibold self-start">
          {`Consultation Service (${name})`}
        </div>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="text-dusty">Schedule</div>
          <div>{displayConsultationDateTime(startAt)}</div>
        </div>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="text-dusty">Consultation Duration</div>
          <div>1 hour</div>
        </div>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="text-dusty">Hourly Rate</div>
          <div>{`USD ${Number(rate)} / hour`}</div>
        </div>
        {!!discountedRate && (
          <div className="flex flex-row justify-between items-center w-full">
            <div className="text-dusty">Member Discount (50%)</div>
            <div>{`- USD ${Number(rate) / 2} / hour`}</div>
          </div>
        )}
        <div className="py-3 flex flex-row justify-between items-center w-full border-gray-300 border-t">
          <div className="text-dusty font-bold">Total</div>
          <div className="text-easter font-bold text-lg">{`USD ${finalRate}`}</div>
        </div>
        {!discountedRate && (
          <p className="text-dusty text-xs self-start font-thin">
            {`*${ALL_ACCESS} & ${PREMIUM} members get 50% off`}
          </p>
        )}
      </div>
      <Elements stripe={stripePromise}>
        <CreditCardForm />
      </Elements>
    </>
  );

  const BookingSent = () => (
    <>
      <Picture
        src={gfxBookingSent}
        className="h-full w-2/5 mx-auto mb-2 sm:mb-4"
      />
      <div className="flex flex-col justify-center items-center">
        <div className="mb-4 font-semibold text-md sm:text-lg">
          Your booking has been sent for approval
        </div>
        <div className="mb-4">
          Hi <span className="font-semibold">{customerName}</span>, thank you
          for booking a consultation with us.
          <br /> Once your booking is approved, we&apos;ll send you a
          confirmation email.
        </div>
        <AddToCalendar {...calendarProps} />
        <Button
          label="OK, GOT IT"
          bold={false}
          uppercase={false}
          isPill
          className="mt-2 py-3 w-full max-w-xs"
          onClick={() => {
            closeModal();
            if (isSubscribed) handleTab('/members/consultation/bookings');
          }}
        />
      </div>
    </>
  );

  return (
    <div className="w-full px-2 md:p-0">
      <Card>
        <BackgroundSection
          backgroundImage={`url(${modalHeader.default})`}
          className={classnames(
            'relative bg-no-repeat bg-cover bg-center h-18 rounded-t-lg'
          )}
        >
          <h3 className="p-3 pt-4 md:p-4 md:pt-5 text-white font-hairline text-center">
            {bookingSent ? 'BOOKING REQUEST SENT' : 'PAYMENT DETAILS'}
          </h3>
        </BackgroundSection>
        <div className="p-4 sm:p-10 text-center flex flex-col justify-center items-center mb-4 sm:mb-8">
          {bookingSent ? <BookingSent /> : <PaymentDetails />}
        </div>
      </Card>
    </div>
  );
};

PaymentModal.propTypes = {
  bookingData: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleTab: PropTypes.func.isRequired,
  isSubscribed: PropTypes.bool,
};

PaymentModal.defaultProps = {
  isSubscribed: false,
};

export default PaymentModal;

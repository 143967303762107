import * as consultants from './consultants';
import * as slots from './slots';
import * as bookings from './bookings';
import * as create from './create';

export default {
  ...consultants,
  ...slots,
  ...bookings,
  ...create,
};

const NAME = 'ARTICLES';

export const FETCH_FAVOURITE_ARTICLES_REQUEST = `${NAME}/FETCH_FAVOURITE_ARTICLES_REQUEST`;
export const FETCH_FAVOURITE_ARTICLES_SUCCESS = `${NAME}/FETCH_FAVOURITE_ARTICLES_SUCCESS`;
export const FETCH_FAVOURITE_ARTICLES_FAIL = `${NAME}/FETCH_FAVOURITE_ARTICLES_FAIL`;

export const fetchFavouriteArticles = (params) => ({
  type: FETCH_FAVOURITE_ARTICLES_REQUEST,
  params,
});

export const fetchFavouriteArticlesSuccess = (data, page) => ({
  type: FETCH_FAVOURITE_ARTICLES_SUCCESS,
  data,
  page,
});

export const fetchFavouriteArticlesFail = (error) => ({
  type: FETCH_FAVOURITE_ARTICLES_FAIL,
  error,
});

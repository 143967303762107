import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Card } from 'components/UI';
import { useDispatch } from 'react-redux';
import Actions from 'actions';
import { ProfileContext } from 'utils/context';
import * as Yup from 'yup';
import notify from 'utils/notification';
import Personal from './Personal';
import Playlist from './Playlist';
import Services from './Services';
import Work from './Work';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  member_types: Yup.array().required('Required'),
});

const ViewProfile = ({ initialValues, readOnly }) => {
  const dispatch = useDispatch();
  const handleOnSubmit = (formValues) => {
    const {
      member_types: memberTypes,
      languages,
      rep_artists: repArtists,
      rep_events: repEvents,
      rep_venues: repVenues,
      users_services: userServices,
      users_experiences: userExperiences,
    } = formValues;

    const parsedFormValue = {
      ...formValues,
      member_types: memberTypes,
      languages: languages.map((item) => item.value),
      rep_artists: repArtists.split(', '),
      rep_events: repEvents.split(', '),
      rep_venues: repVenues.split(', '),
      users_services_attributes: userServices,
      users_experiences_attributes: userExperiences,
    };

    dispatch(
      Actions.updateUser(parsedFormValue, {
        success: () => notify('Profile updated'),
        failure: (e) => notify(e, null, 'danger'),
      })
    );
  };

  return (
    <div className="container mx-auto px-6 pt-4 mb-24">
      <ProfileContext.Provider value={{ isView: true, readOnly }}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          <Form>
            <Card className="mt-4">
              <Personal isView />
            </Card>
            <Card className="p-8 mt-8">
              <Work />
            </Card>
            <Card className="p-8 mt-8">
              <Services />
            </Card>
            <Card className="p-8 mt-8">
              <Playlist />
            </Card>
          </Form>
        </Formik>
      </ProfileContext.Provider>
    </div>
  );
};

ViewProfile.propTypes = {
  initialValues: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

ViewProfile.defaultProps = {
  readOnly: false,
};

export default ViewProfile;

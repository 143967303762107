const NAME = 'CITIES';

export const FETCH_CITIES_REQUEST = `${NAME}/FETCH_CITIES_REQUEST`;
export const FETCH_CITIES_SUCCESS = `${NAME}/FETCH_CITIES_SUCCESS`;
export const FETCH_CITIES_FAIL = `${NAME}/FETCH_CITIES_FAIL`;

export const fetchCities = (params) => ({
  type: FETCH_CITIES_REQUEST,
  params,
});

export const fetchCitiesSuccess = (data, page) => ({
  type: FETCH_CITIES_SUCCESS,
  data,
  page,
});

export const fetchCitiesFail = (error) => ({
  type: FETCH_CITIES_FAIL,
  error,
});

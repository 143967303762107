import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { iconMain } from 'assets';
import { Picture } from 'components/UI';
import { AuthContext } from 'utils/context';

const Divider = () => <ChevronRightIcon className="sm:mx-4" />;

const Breadcrumbs = ({ trails, color, publicRoute }) => {
  const navigate = useNavigate();
  const { isSubscribed } = useContext(AuthContext);

  const BreadcrumbTrails = () =>
    trails.map((trail, index) => {
      const isCurrent = trail === trails[trails.length - 1];
      const isObject = typeof trail === 'object';
      const label = isObject ? trail.label : trail;
      const handleClick = () => {
        if (isObject) navigate(trail.path);
      };

      return (
        <div
          className={classnames('uppercase flex items-center', {
            'cursor-pointer': !isCurrent,
            'text-sigmaBlue-dark': isCurrent && isSubscribed,
          })}
          key={label || `trail-${index}`}
          role="presentation"
          onClick={handleClick}
        >
          {isObject && trail.icon && (
            <span
              className={classnames(
                'text-sm md:text-md mr-4',
                `icon-${trail.icon}`
              )}
            />
          )}
          <p className="truncate text-sm md:text-md">{label}</p>
          {!isCurrent && <Divider />}
        </div>
      );
    });

  return (
    <div
      className={classnames({
        'w-full h-12 flex fixed z-30 border-t-2 shadow-card bg-white':
          isSubscribed && !publicRoute,
      })}
    >
      <div
        className={classnames(`flex items-center ${color} overflow-hidden`, {
          'ml-6': isSubscribed,
        })}
      >
        <Link
          to={isSubscribed ? '/dashboard' : '/'}
          className="flex items-center"
        >
          <Picture src={iconMain} alt="icon-main" />
          {!isEmpty(trails) && <Divider />}
        </Link>
        <BreadcrumbTrails />
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  trails: PropTypes.array,
  color: PropTypes.string,
  publicRoute: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
  trails: [],
  color: 'text-silver',
  publicRoute: false,
};

export default Breadcrumbs;

/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Picture, Button, MemberTierBadge, Options } from 'components/UI';
import { nameFormat } from 'utils/format';
import {
  iconFBActive,
  iconIGActive,
  iconLinkedInActive,
  gfxAvatar,
  bgProfile,
  iconChat,
  blockUserIcon,
} from 'assets';
import Edit from '@material-ui/icons/Edit';
import FormatQuote from '@material-ui/icons/FormatQuote';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { ProfileContext } from 'utils/context';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { ALL_ACCESS, PREMIUM, FREE } from 'utils/constants';
import { AuthContext } from '../../../utils/context';
import BlockUserForm from './BlockUserForm';

const ProfileHeaderContainer = styled.div`
  background-image: url(${bgProfile.default});
`;

const OptionBlockUser = () => (
  <span>
    <Picture src={blockUserIcon} className="inline-block w-4 mr-2" />
    Block user
  </span>
);

const PersonalInfo = ({ toggleForm }) => {
  const dispatch = useDispatch();
  const user = useSelector(Selectors.userData);
  const params = useParams();
  const navigate = useNavigate();
  const { readOnly } = useContext(ProfileContext);
  const currentUser = useSelector(Selectors.getUser);
  const { isFreeTier, isPro, tier } = useContext(AuthContext);
  const isCurrentUser = !readOnly || params?.id === currentUser?.id;
  const userTierName = isCurrentUser ? tier?.name : user?.member_tier?.name;
  const countryName = isCurrentUser
    ? currentUser?.country?.name
    : user?.country?.name;
  const cityName = isCurrentUser ? currentUser?.city?.name : user?.city?.name;

  const {
    values: {
      avatarUrl,
      linked_in: linkedIn,
      facebook,
      instagram,
      email,
      first_name: firstName,
      last_name: lastName,
      biography,
      member_types: memberTypes,
      languages,
      rep_artists: repArtist,
      rep_events: repEvents,
      rep_venues: repVenues,
      sendbirdId,
      email_visibility: emailVisibility,
    },
  } = useFormikContext();

  const socialLinks = (link, icon) => {
    const socialIcon = (
      <Picture
        className={classnames('rounded h-10 mr-2', { 'opacity-50': !link })}
        src={icon}
      />
    );
    if (link) {
      return (
        <a href={link} target="_blank" rel="noreferrer">
          {socialIcon}
        </a>
      );
    }
    return socialIcon;
  };

  const arrayText = (title, content) => (
    <div className="flex flex-col mt-8">
      <div className="text-sm text-boulder uppercase mb-2">{title}</div>
      <div
        className={classnames('flex', {
          'overflow-y-auto': title === 'Member Types',
        })}
      >
        {content}
      </div>
    </div>
  );

  const msgUser = () =>
    dispatch(
      Actions.listChannels(() => navigate('/chat', { state: { sendbirdId } }))
    );

  const blockUser = () =>
    dispatch(
      Actions.showDialog({
        title: 'Block User',
        content: <BlockUserForm blockedUser={user} />,
        hideButtons: true,
      })
    );

  const optionList = [
    {
      label: <OptionBlockUser />,
      key: 'click-block',
      onClick: blockUser,
    },
  ];

  const showChatButton =
    readOnly && currentUser.id !== params?.id && sendbirdId;

  const hasChatRestriction =
    (isFreeTier && !user?.member_tier?.is_free_tier) ||
    (!isPro && user?.member_tier?.name === ALL_ACCESS);

  return (
    <div>
      <ProfileHeaderContainer className="flex h-56 rounded-t">
        {!readOnly && (
          <Edit
            className="ml-auto mr-4 mt-4 text-white hover:text-dusty cursor-pointer"
            onClick={() => toggleForm(false)}
            role="presentation"
          />
        )}
      </ProfileHeaderContainer>
      <section className="flex flex-col lg:flex-row -mt-30 px-8">
        <div className="flex flex-col items-center">
          <Picture
            src={avatarUrl || gfxAvatar}
            fallbackImage={gfxAvatar.default}
            className={classnames(
              'w-48 h-48 rounded-full object-cover border shadow-lg -mb-2',
              {
                'border-violet': userTierName === ALL_ACCESS,
                'border-easter': userTierName === (PREMIUM || FREE),
                'border-none': !avatarUrl,
              }
            )}
          />
          <MemberTierBadge memberTier={userTierName} />
        </div>
        <div className="flex flex-col mt-4 lg:ml-4 flex-grow">
          <p
            className={classnames(
              'text-2lg lg:text-white lg:text-shadow font-semibold',
              { 'mt-6': !email }
            )}
          >
            {nameFormat({ firstName, lastName })}
          </p>
          <p className="text-md lg:text-white lg:text-shadow">
            {[cityName, countryName].join(', ')}
          </p>
          {email && (
            <div className="flex items-center">
              <p className="lg:text-white lg:text-shadow">{email}</p>
              {emailVisibility === 'email_hidden' && (
                <Tooltip
                  title={
                    <p className="text-xs">Your email is only visible to you</p>
                  }
                  placement="top"
                  arrow
                >
                  <VisibilityOffIcon className="ml-3 text-gray-400" />
                </Tooltip>
              )}
            </div>
          )}
          <div className="flex mt-2 lg:mt-8 flex-col sm:flex-row sm:items-center">
            <div className="flex flex-1 justify-between items-center">
              <div className="flex">
                {socialLinks(facebook, iconFBActive)}
                {socialLinks(instagram, iconIGActive)}
                {socialLinks(linkedIn, iconLinkedInActive)}
              </div>
              {!readOnly && isFreeTier && (
                <Tooltip
                  interactive
                  title={
                    <div className="text-xs">
                      Free tier has limited access to features on Gig Life Pro.
                      You can
                      <Link
                        to="/subscription"
                        className="font-semibold text-easter"
                      >
                        {` upgrade here`}
                      </Link>
                    </div>
                  }
                  placement="top"
                  arrow
                >
                  <Chip label="FREE TIER" />
                </Tooltip>
              )}
            </div>
            {!!showChatButton && (
              <Tooltip
                interactive
                title={
                  hasChatRestriction ? (
                    <div className="text-xs">
                      {`You're currently on ${currentUser.member_tier.name} tier with limited access.
                      Start chatting with members by`}
                      <Link
                        to="/subscription"
                        className="font-semibold text-easter"
                      >
                        {` upgrading here`}
                      </Link>
                    </div>
                  ) : (
                    ''
                  )
                }
                placement="top"
                arrow
              >
                <div className="flex justify-center items-center">
                  <Button
                    className="mt-3 w-60 sm:w-40 sm:mt-0 sm:ml-auto px-2"
                    isPill
                    label={
                      <div className="flex flex-row justify-center items-center font-normal">
                        Message
                        <Picture src={iconChat} className="ml-2 rounded-full" />
                      </div>
                    }
                    onClick={msgUser}
                    outline
                    disabled={hasChatRestriction}
                  />
                </div>
              </Tooltip>
            )}
            {!isCurrentUser && <Options list={optionList} />}
          </div>
        </div>
      </section>
      <section
        className={classnames('px-8 pb-8 lg:ml-48', {
          'lg:-mt-12': !biography,
        })}
      >
        {biography && (
          <div className="flex flex-col lg:ml-4 container lg:w-2/3">
            <FormatQuote className="-ml-4 lg:-ml-8" />
            {biography}
            <FormatQuote className="self-end" />
          </div>
        )}
        <div className="lg:ml-4">
          {arrayText(
            'Member Types',
            memberTypes.map(({ label }) => (
              <div
                className="bg-gradient-easter-bostonBlue px-2 py-1 mr-2 rounded text-white shadow"
                key={label}
              >
                {label}
              </div>
            ))
          )}
          {arrayText(
            'Languages Spoken',
            languages.map(({ label }) => label).join(', ')
          )}
          {arrayText('Artist Represented', repArtist)}
          {arrayText('Events Represented', repEvents)}
          {arrayText('Venue Represented', repVenues)}
        </div>
      </section>
    </div>
  );
};

PersonalInfo.propTypes = {
  toggleForm: PropTypes.func.isRequired,
};

MemberTierBadge.propTypes = {
  memberTier: PropTypes.string.isRequired,
};

export default PersonalInfo;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ALL_ACCESS, PREMIUM, FREE, GUEST } from 'utils/constants';
import Badge from './Badge';

const MemberTierBadge = ({ memberTier, className }) => {
  const bgMemberTierBadgeMap = {
    [ALL_ACCESS]: 'bg-violet-light',
    [PREMIUM]: 'bg-easter',
    [FREE]: 'bg-dusty',
    [GUEST]: 'bg-boulder',
  };
  const badgeBg = bgMemberTierBadgeMap[memberTier];
  return (
    <Badge className="rounded-full" bg={badgeBg}>
      <div className={classnames('capitalise', className)}>{memberTier}</div>
    </Badge>
  );
};

MemberTierBadge.propTypes = {
  memberTier: PropTypes.string.isRequired,
  className: PropTypes.string,
};

MemberTierBadge.defaultProps = {
  className: null,
};

export default MemberTierBadge;

import { all, fork } from 'redux-saga/effects';
import list from './list';
import categories from './categories';
import info from './info';

export default function* videos() {
  yield all([fork(list)]);
  yield all([fork(categories)]);
  yield all([fork(info)]);
}

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { format } from 'date-fns';
import { urlHandler } from 'utils/helper';
import { isFirefox } from 'utils/browser';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import { Badge, Card, Button, ViewAll } from 'components/UI';
import qs from 'query-string';
import styled from '@emotion/styled';
import { BackgroundSection, labelBg } from '../LandingPage/common';

const BadgeContainer = styled.div`
  padding-bottom: 9.5rem;
`;

const EventCard = ({ events }) => {
  const {
    id,
    city,
    name,
    start_date: date,
    image_url: imageUrl,
    event_category: eventCategory,
  } = events;

  const eventType = eventCategory?.name || 'Festival';
  const eventTypeUrl = `/events?${qs.stringify({ category: eventType })}`;

  return (
    <div className="w-full sm:w-9/12 relative z-0">
      <BadgeContainer className="absolute bottom-0 p-10 z-10">
        <Badge bg={labelBg(eventType)} md className="mb-2 z-10">
          <Link to={eventTypeUrl}>{eventType}</Link>
        </Badge>
      </BadgeContainer>
      <Link to={`/members/events/explore/${id}`}>
        <Card className="shadow-xl">
          <BackgroundSection
            backgroundImage={`url(${urlHandler(imageUrl)})`}
            className="relative bg-no-repeat bg-cover bg-center rounded-lg"
          >
            <div className="flex flex-col text-white text-shadow justify-between relative">
              <div className="self-end text-center md:mr-10 mr-5 mt-5 mb-16">
                <div className="text-6xl text-right">
                  {format(new Date(date), 'do')}
                </div>
                <div className="font-thin font-lynstone text-lg uppercase">
                  {format(new Date(date), 'MMMM yyyy')}
                </div>
              </div>
              <div className="flex flex-col m-10 pt-8">
                <div className="sm:text-xl text-lg font-chap truncate">
                  {name}
                </div>
                <div className="font-thin font-lynstone sm:text-lg text-md uppercase">
                  {city?.name || 'Global'}
                </div>
              </div>
            </div>
          </BackgroundSection>
        </Card>
      </Link>
    </div>
  );
};

EventCard.propTypes = {
  events: PropTypes.object.isRequired,
};

SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);

const UpcomingEvents = ({ events }) => {
  const width = window.innerWidth;
  const swiperProps = {
    effect: 'coverflow',
    coverflowEffect: {
      rotate: 0,
      stretch: width > 1024 ? 400 : 100,
      depth: 200,
      modifier: 2,
      slideShadows: false,
    },
    loop: true,
    loopedSlides: 3,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      1025: {
        slidesPerView: 'auto',
      },
    },
    onSlideChangeTransitionEnd: (swiper) => {
      swiper.loopFix();
    },
    autoplay: true,
    grabCursor: true,
    direction: 'horizontal',
    navigation: { clickable: true },
    speed: isFirefox ? 1000 : 300,
    slidesPerView: 1,
  };

  return (
    <div className="container mx-auto px-4 sm:px-12 py-16">
      <div className="grid sm:flex pb-3 item-center">
        <div className="font-semibold pb-2 sm:pb-0 text-lg">
          Upcoming Events
        </div>
        {!isEmpty(events) && <ViewAll path="/members/events/explore" />}
      </div>
      {isEmpty(events) ? (
        <Card className="mx-auto border-t-2 border-easter p-8 md:p-16 text-center">
          <div className="flex flex-no-wrap overflow-x-auto w-full">
            <div className="m-auto text-center">
              <h3 className="font-semibold">No upcoming events</h3>
              <p className="text-dusty">
                Please wait for the upcoming events...
              </p>
              <Button
                className="mt-8 max-w-80 mx-auto"
                label="VIEW ALL Upcoming Events"
                onClick={() => navigate('/members/events/explore')}
              />
            </div>
          </div>
        </Card>
      ) : (
        <>
          <Swiper {...swiperProps}>
            {events.map((item) => (
              <SwiperSlide key={`swipe-${item.id}`} className="cursor-pointer">
                <EventCard events={item} key={item.id} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
};

UpcomingEvents.propTypes = {
  events: PropTypes.array.isRequired,
};

export default UpcomingEvents;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchSendbirdUsers() {
  try {
    const { data } = yield call(api.fetchSendbirdUsers);
    yield put(Actions.fetchSendbirdUsersSuccess(data));
  } catch (error) {
    yield put(Actions.fetchSendbirdUsersFail(error));
  }
}

function* watchFetchSendbirdUsers() {
  yield takeLatest(Actions.FETCH_SENDBIRD_USERS_REQUEST, fetchSendbirdUsers);
}

export default function* users() {
  yield all([fork(watchFetchSendbirdUsers)]);
}

import React from 'react';
import PropTypes from 'prop-types';
import { bgAboutUs } from 'assets';
import styled from '@emotion/styled';
import { background } from 'styled-system';
import { BackgroundSection } from '.';

const PolygonBackground = styled.div`
  @media (min-width: 640px) {
    clip-path: polygon(0 75%, 100% 0%, 100% 100%, 0% 100%);
  }
  clip-path: polygon(0 50%, 100% 0%, 100% 100%, 0% 100%);
  ${background}
`;

const Filter = () => (
  <div className="bg-ebony bg-opacity-75 absolute top-0 bottom-0 right-0 left-0" />
);

const FeaturedLayout = (props) => {
  const { children, image, bgFilter, polygon, polygonBg } = props;
  return (
    <>
      <BackgroundSection
        backgroundImage={`url(${image})`}
        maxHeight="640px"
        minHeight="600px"
        backgroundPosition="50% 45%"
        className="relative h-screen bg-no-repeat bg-cover pt-20 px-4 mb-1 flex flex-col justify-end"
        {...props}
      >
        {bgFilter && <Filter />}
        {children}
      </BackgroundSection>
      {polygon && (
        <PolygonBackground
          className="bg-white -mt-24 h-24 sm:-mt-30 sm:h-30"
          background={polygonBg}
        />
      )}
    </>
  );
};

FeaturedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.any,
  bgFilter: PropTypes.bool,
  polygon: PropTypes.bool,
  polygonBg: PropTypes.string,
};

FeaturedLayout.defaultProps = {
  image: bgAboutUs.default,
  bgFilter: true,
  polygon: true,
  polygonBg: 'white',
};

export default FeaturedLayout;

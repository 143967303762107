const NAME = 'STRIPE/DOWNGRADE_SUBSCRIPTION_';

export const DOWNGRADE_SUBSCRIPTION_REQUEST = `${NAME}REQUEST`;
export const DOWNGRADE_SUBSCRIPTION_SUCCESS = `${NAME}SUCCESS`;
export const DOWNGRADE_SUBSCRIPTION_FAIL = `${NAME}FAIL`;

export const downgradeSubscription = (data, callbacks) => ({
  type: DOWNGRADE_SUBSCRIPTION_REQUEST,
  data,
  callbacks,
});

export const downgradeSubscriptionSuccess = (data) => ({
  type: DOWNGRADE_SUBSCRIPTION_SUCCESS,
  data,
});

export const downgradeSubscriptionFail = (error) => ({
  type: DOWNGRADE_SUBSCRIPTION_FAIL,
  error,
});

/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import Selectors from 'selectors';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TextInput, SocialMediaLinkInput, SelectInput } from 'components/Form';
import { Picture } from 'components/UI';
import { yearsTillNow, CURRENT_YEAR } from 'utils/datetime';
import { YEAR_OPTIONS } from 'utils/helper';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import { iconJobFallback } from 'assets';
import { ProfileContext } from 'utils/context';
import isEmpty from 'lodash/isEmpty';

const Work = () => {
  const { isView, readOnly } = useContext(ProfileContext);
  const {
    values: {
      users_experiences: workExperiences,
      industry_since: industrySince,
    },
    handleSubmit,
    setFieldValue,
  } = useFormikContext();
  const userProfile = useSelector(Selectors.userProfile);
  const currentUserWorkExp = userProfile.users_experiences || [];
  const workExp = readOnly ? workExperiences : currentUserWorkExp;
  const nonDestroyedWorkExp = workExperiences.filter(
    ({ id, _destroy }) => id && !_destroy
  );
  const [viewProfile, toggleForm] = useState(isView);
  const sortedExp = workExp.sort((exp1, exp2) => {
    const yearEnd1 = exp1.year_end || CURRENT_YEAR;
    const yearEnd2 = exp2.year_end || CURRENT_YEAR;
    if (yearEnd1 === yearEnd2) {
      return exp2.year_start - exp1.year_start;
    }
    return yearEnd2 - yearEnd1;
  });
  const companyUrl = (url) => {
    if (!url) return;
    return url.slice(0, 4) === 'http' ? url : `https://${url}`;
  };

  if (viewProfile)
    return (
      <div>
        <div className="flex justify-between">
          <h4 className="text-dusty text-lg font-semibold uppercase mb-4">
            Work Experience
          </h4>
          {!readOnly && (
            <Edit
              className="text-dusty cursor-pointer hover:text-easter"
              onClick={() => toggleForm(false)}
              role="presentation"
            />
          )}
        </div>
        {industrySince && (
          <p className="mb-4 text-md pb-2">
            <b className="font-semibold">
              {industrySince >= CURRENT_YEAR - 1
                ? 'First year'
                : `${yearsTillNow(industrySince)} years`}
            </b>
            {` in the music industry`}
          </p>
        )}
        <div>
          {sortedExp.map((item, index) => {
            if (isEmpty(item)) return;
            const yearEnd = item.year_end;

            return (
              <div className="mb-4 flex" key={index}>
                <Picture
                  src={iconJobFallback}
                  className="h-10 mr-4 shadow rounded-full"
                  disableParentClassName
                />
                <div>
                  <a
                    href={companyUrl(item.company_url)}
                    className="font-semibold text-md mb-2 text-sigmaBlue-dark"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.company}
                  </a>
                  <p>
                    {item.position} • {item.year_start}
                    {` - ${!yearEnd || yearEnd === ' ' ? 'Present' : yearEnd}`}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );

  return (
    <div>
      <div className="sm:flex justify-between mb-8 sm:mb-0">
        <h5 className="text-lg mb-4">Work Experience</h5>
        {isView && (
          <div
            className="flex text-dusty cursor-pointer hover:text-easter justify-end"
            onClick={() => {
              toggleForm(true);
              handleSubmit();
            }}
            role="presentation"
          >
            <Save />
            <div className="ml-2">Save Changes</div>
          </div>
        )}
      </div>
      <FieldArray
        name="users_experiences"
        render={({ push, remove }) => (
          <>
            {workExperiences.map(({ id, _destroy }, index) => (
              <div
                className={`flex flex-col border-alto border-b border-dashed my-8 pb-8 ${
                  _destroy && 'hidden'
                }`}
                key={index}
              >
                {(!id || nonDestroyedWorkExp.length > 1) && (
                  <button
                    className="inline-flex ml-auto text-valencia focus:outline-none hover:bg-pippin rounded-full"
                    type="button"
                    onClick={() => {
                      if (id)
                        return setFieldValue(
                          `users_experiences[${index}]._destroy`,
                          true
                        );
                      remove(index);
                    }}
                  >
                    <RemoveCircle className="mr-2" />
                    Remove
                  </button>
                )}
                <Field
                  name={`users_experiences[${index}].company`}
                  component={TextInput}
                  placeholder="Company/Organisation"
                />
                <Field
                  name={`users_experiences[${index}].position`}
                  component={TextInput}
                  placeholder="Job Position"
                />
                <p className="text-xs text-dusty mb-2">Years in the company</p>
                <div className="flex flex-col lg:flex-row justify-start">
                  <Field
                    name={`users_experiences[${index}].year_start`}
                    component={SelectInput}
                    listHeight={150}
                    options={YEAR_OPTIONS}
                    placeholder="Select Year"
                  />
                  <p className="mb-4 lg:mt-4 mx-auto lg:mx-8">To</p>
                  <Field
                    name={`users_experiences[${index}].year_end`}
                    component={SelectInput}
                    listHeight={150}
                    options={[
                      {
                        label: 'Present',
                        // allow to display dynamic year_end value, refer to MR 305
                        value: ' ',
                      },
                      ...YEAR_OPTIONS,
                    ]}
                    placeholder="Select Year"
                  />
                </div>
                <SocialMediaLinkInput
                  className="w-full"
                  name={`users_experiences[${index}].company_url`}
                  placeholder="Enter company website…"
                />
              </div>
            ))}
            <button
              className="text-easter flex items-center focus:outline-none hover:bg-alto rounded-full"
              type="button"
              onClick={() =>
                push({
                  company: '',
                  company_url: '',
                  position: '',
                  year_start: '',
                  year_end: '',
                })
              }
            >
              <AddCircle className="mr-2" />
              Add More
            </button>
          </>
        )}
      />
    </div>
  );
};

export default Work;

/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TextInput } from 'components/Form';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import { ProfileContext } from 'utils/context';

const Services = () => {
  const { isView, readOnly } = useContext(ProfileContext);
  const {
    values: { users_services: userServices },
    handleSubmit,
    setFieldValue,
  } = useFormikContext();
  const [viewProfile, toggleForm] = useState(isView);

  if (viewProfile)
    return (
      <div>
        <div className="flex justify-between">
          <h4 className="text-dusty text-lg font-semibold uppercase mb-4">
            My Services
          </h4>
          {!readOnly && (
            <Edit
              className="text-dusty cursor-pointer hover:text-easter"
              onClick={() => toggleForm(false)}
              role="presentation"
            />
          )}
        </div>
        <div>
          {userServices.map((item, index) => (
            <div className="mb-2" key={index}>
              <p className="font-semibold text-md">{item.name}</p>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div>
      <div className="sm:flex justify-between mb-8 sm:mb-0">
        <h5 className="text-lg mb-4">My Services</h5>
        {isView && (
          <div
            className="flex text-dusty cursor-pointer hover:text-easter justify-end"
            onClick={() => {
              toggleForm(true);
              handleSubmit();
            }}
            role="presentation"
          >
            <Save />
            <div className="ml-2">Save Changes</div>
          </div>
        )}
      </div>
      <FieldArray
        name="users_services"
        render={({ push, remove }) => (
          <>
            {userServices.map(({ id, _destroy }, index) => (
              <div
                className={`flex flex-col border-alto border-b border-dashed mb-8 pb-8 ${
                  _destroy && 'hidden'
                }`}
                key={index}
              >
                <button
                  className="inline-flex ml-auto text-valencia focus:outline-none hover:bg-pippin rounded-full"
                  type="button"
                  onClick={() => {
                    if (!id) return remove(index);
                    setFieldValue(`users_services[${index}]._destroy`, true);
                  }}
                >
                  <RemoveCircle className="mr-2" />
                  Remove
                </button>
                <Field
                  name={`users_services[${index}].name`}
                  component={TextInput}
                  label="Service name"
                  placeholder="Service name (eg. Record music)"
                />
                <Field
                  name={`users_services[${index}].description`}
                  component={TextInput}
                  label="Description"
                  placeholder="Description (eg. Record and customize your audio experience)"
                />
              </div>
            ))}
            <button
              className="text-easter flex items-center focus:outline-none hover:bg-alto rounded-full"
              type="button"
              onClick={() =>
                push({
                  name: '',
                  description: '',
                })
              }
            >
              <AddCircle className="mr-2" />
              Add More
            </button>
          </>
        )}
      />
    </div>
  );
};

export default Services;

import * as signIn from './signIn';
import * as signOut from './signOut';
import * as activateAccount from './activateAccount';
import * as createPassword from './createPassword';
import * as forgotPassword from './forgotPassword';
import * as resetPassword from './resetPassword';
import * as validateResetPasswordCode from './validateResetPasswordCode';
import * as validateEmail from './validateEmail';
import * as updatePassword from './updatePassword';

export default {
  ...signIn,
  ...signOut,
  ...activateAccount,
  ...createPassword,
  ...forgotPassword,
  ...resetPassword,
  ...validateResetPasswordCode,
  ...validateEmail,
  ...updatePassword,
};

import { combineReducers } from 'redux';
import create from './create';
import list from './list';
import unread from './unread';

export default combineReducers({
  create,
  list,
  unread,
});

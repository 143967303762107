import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { AuthContext } from 'utils/context';
import { Button } from 'components/UI';
import BookingList from './BookingList';
import CustomerBookings from './CustomerBookings';

const Bookings = () => {
  const dispatch = useDispatch();
  const { isFreeTier } = useContext(AuthContext);
  const [isConsultantTab, setIsConsultantTab] = useState(!isFreeTier);
  const consultationBookings = useSelector(Selectors.getConsultationBookings);
  const isConsultationBookingsLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_BOOKINGS_REQUEST])
  );
  const customerBookings = useSelector(
    Selectors.getConsultationServiceBookings
  );
  const isCustomerBookingsLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_SERVICE_BOOKINGS_REQUEST])
  );
  const cancelBooking = (id) => dispatch(Actions.cancelBooking(id));

  useEffect(() => {
    dispatch(Actions.fetchBookings());
  }, [dispatch]);

  useEffect(() => {
    if (!isFreeTier) {
      dispatch(Actions.fetchConsultationService());
      dispatch(Actions.fetchServiceBookings());
    }
  }, [dispatch, isFreeTier]);

  const setIsConsultantTabTrue = () => setIsConsultantTab(true);
  const setIsConsultantTabFalse = () => setIsConsultantTab(false);

  return (
    <>
      {!isFreeTier && (
        <div className="pt-30 px-3 flex items-center space-x-3">
          <Button
            label="As a customer"
            className="text-xxs"
            outline
            theme={isConsultantTab ? 'plain' : 'easter'}
            onClick={setIsConsultantTabFalse}
          />
          <Button
            label="As a consultant"
            className="text-xxs"
            onClick={setIsConsultantTabTrue}
            outline
            theme={isConsultantTab ? 'easter' : 'plain'}
          />
        </div>
      )}
      <div
        className={classnames({ 'pt-20': isFreeTier, '-mt-5': !isFreeTier })}
      >
        {!isConsultantTab ? (
          <BookingList
            bookings={consultationBookings}
            isLoading={isConsultationBookingsLoading}
            cancelBooking={cancelBooking}
          />
        ) : (
          <div className="mt-10 sm:px-6 mb-10">
            <h3 className="font-semibold mb-4 mx-4 sm:mx-0">
              UPCOMING CUSTOMER APPOINTMENTS
            </h3>
            <CustomerBookings
              bookings={customerBookings}
              isLoading={isCustomerBookingsLoading}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Bookings;

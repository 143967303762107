import React from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/UI';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import api from 'api';
import { Field, Form, Formik } from 'formik';
import { RadioButtonInput } from 'components/Form';
import notify from 'utils/notification';

const FlagForm = ({ eventId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate: flagEventMutate } = useMutation(
    (formData) => api.flagEvent(eventId, formData),
    {
      onSuccess: () => {
        notify('Successfully reported event');
        dispatch(Actions.hideDialog());
        navigate('/members/events/explore');
      },
      onError: (e) => {
        notify(
          e?.response?.data?.error || 'Error reporting event',
          null,
          'danger'
        );
      },
    }
  );

  const reasonsList = [
    { label: 'Spam', value: 'Spam' },
    { label: `Impersonation`, value: 'Impersonation' },
    { label: `Violence`, value: 'Violence' },
    { label: 'Harassment', value: 'Harassment' },
    { label: `Adult Content`, value: 'Adult Content' },
    { label: `Defamation`, value: 'Defamation' },
    { label: `Others`, value: 'Others' },
  ];

  return (
    <div className="flex flex-col gap-2 text-left text-base">
      <p className="text-md text-center">
        Help state the reason for reporting this event.
      </p>
      <p className="text-boulder text-center mb-3">
        Providing a reason can help us create a healthy community for you.
      </p>
      <Formik
        enableReinitialize
        initialValues={{ reason: '' }}
        onSubmit={flagEventMutate}
      >
        {({ errors }) => (
          <Form className="space-y-4">
            <Field
              name="reason"
              component={RadioButtonInput}
              options={reasonsList}
              downsize
            />
            <div className="flex flex-col justify-center items-center gap-3">
              <Button
                label="YES, REPORT EVENT"
                className="w-full sm:w-3/4 h-12 px-4"
                type="submit"
                isPill
                theme="valencia"
                disabled={!isEmpty(errors)}
              />
              <Button
                label="NO, CANCEL"
                className="w-full sm:w-3/4 h-12 px-4"
                theme="plain"
                outline
                isPill
                onClick={() => dispatch(Actions.hideDialog())}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

FlagForm.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default FlagForm;

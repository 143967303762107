import React from 'react';
import PropTypes from 'prop-types';
import { Picture } from 'components/UI';
import { gfxAvatar } from 'assets';
import { Link } from 'react-router-dom';

const Speakers = ({ data }) => (
  <div className="text-boulder mt-5">
    <p>SPEAKERS</p>
    <div className="flex sm:items-center mt-3 flex-col sm:flex-row">
      {data.map(({ name, img, id }) => {
        const Content = () => (
          <div className="flex items-center mb-3 mr-5">
            <div className="rounded-full h-8 w-8 shadow-lg overflow-hidden mr-2">
              <Picture
                src={img || gfxAvatar}
                className="object-cover w-full h-full"
                fallbackImage={gfxAvatar.default}
              />
            </div>
            {name}
          </div>
        );

        return id ? (
          <Link to={`/network/${id}`} key={name}>
            <Content />
          </Link>
        ) : (
          <div key={name}>
            <Content />
          </div>
        );
      })}
    </div>
  </div>
);

Speakers.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Speakers;

import React, { useState, useEffect } from 'react';
import Actions from 'actions';
import { Formik, Field } from 'formik';
import TextInput from 'components/Form/TextInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ErrorContainer } from 'components/UI';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import AuthLayout from './common/AuthLayout';

const PasswordSchema = Yup.object({
  newPassword: Yup.string().required('Password is required'),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Password must match'
  ),
});

const ResetPassword = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    if (isEmpty(state)) navigate('/forgot-password');
  }, [state, navigate]);

  if (isEmpty(state)) return null;

  const { email, code, user } = state;

  return (
    <AuthLayout>
      <div className="flex flex-col my-8">
        <h5 className="text-2lg">Create New Password</h5>
        <p className="text-sm text-dusty mb-16 max-w-120 mt-4">
          {`Hi ${user}, please enter a new password to continue access`}
          <br />
          to your Gig Life Pro account
        </p>
        <Formik
          initialValues={{ newPassword: '', confirmNewPassword: '' }}
          onSubmit={({ newPassword }) => {
            const params = { email, password: newPassword, code };

            dispatch(
              Actions.resetPassword(params, {
                success: () => {
                  dispatch(
                    Actions.signIn(
                      { email, password: newPassword },
                      {
                        success: () => navigate('/dashboard'),
                      }
                    )
                  );
                },
                failure: ({ data: { error } }) =>
                  setError(error || 'Something Went Wrong'),
              })
            );
          }}
          validationSchema={PasswordSchema}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="newPassword"
                component={TextInput}
                placeholder="Password"
                type="password"
              />
              <Field
                name="confirmNewPassword"
                component={TextInput}
                placeholder="Confirm Password"
                type="password"
              />
              <ErrorContainer error={error} />
              <div className="mt-12">
                <Button
                  className="block mx-auto"
                  label="Confirm New Password"
                  type="submit"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;

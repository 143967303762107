import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* favouriteArticleInfo({ id, callback }) {
  try {
    const response = yield call(api.favouriteArticleInfo, id);
    yield put(Actions.favouriteArticleInfoSuccess(response?.data));
    if (typeof callback === 'function') {
      yield call(callback);
    }
  } catch (error) {
    yield put(Actions.favouriteArticleInfoFail(error));
  }
}

function* watchFavouriteArticlesInfo() {
  yield takeLatest(
    Actions.FAVOURITE_ARTICLE_INFO_REQUEST,
    favouriteArticleInfo
  );
}

export default function* favourite() {
  yield all([fork(watchFavouriteArticlesInfo)]);
}

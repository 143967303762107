import Actions from 'actions';

const initialState = Object.freeze({
  dialog: {},
  isVisible: false,
});

function dialog(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_DIALOG:
      return {
        dialog: action.dialog,
        isVisible: true,
      };
    case Actions.HIDE_DIALOG:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
}

export default dialog;

import Actions from 'actions';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import api from 'api';

function* fetchUserCreditCard({ callbacks }) {
  try {
    const response = yield call(api.fetchUserCreditCard);
    yield put(Actions.fetchUserCreditCardSuccess(response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, data);
    }
  } catch (error) {
    yield put(Actions.fetchUserCreditCardFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, response.error);
    }
  }
}

function* watchFetchUserCreditCard() {
  yield takeLatest(Actions.FETCH_USER_CREDIT_CARD_REQUEST, fetchUserCreditCard);
}

export default function* fetchCreditCard() {
  yield all([fork(watchFetchUserCreditCard)]);
}

import React from 'react';
import classnames from 'classnames';
import { Button } from 'components/UI';
import { bgApply } from 'assets';
import { useNavigate } from 'react-router-dom';
import { BackgroundSection } from '.';
import EmailSignUp from './EmailSignUp';

const Apply = () => {
  const navigate = useNavigate();
  return (
    <>
      <BackgroundSection
        backgroundImage={`url(${bgApply.default})`}
        height="750px"
        className={classnames(
          'h-screen bg-no-repeat bg-cover xl:bg-full bg-center',
          'flex flex-col items-center text-center pt-20'
        )}
      >
        <h2 className="text-white mt-32 sm:mt-48 mb-4">
          Become a Gig Life Pro Member
        </h2>
        <p className="text-white mb-16 text-md">Join our community</p>
        <div>
          <Button
            label="Apply to be a member"
            onClick={() => navigate('/signup')}
            className="py-4"
          />
        </div>
      </BackgroundSection>
      <EmailSignUp />
    </>
  );
};

export default Apply;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useMutation, useQueryClient } from 'react-query';
import { nameFormat } from 'utils/format';
import api from 'api';
import { modalHeader, gfxAvatar, blockUserIcon } from 'assets';
import { Card, Picture } from 'components/UI';
import { latestWorkExp } from 'utils/helper';
import notify from 'utils/notification';
import { BackgroundSection } from '../LandingPage/common';

const BlockedUsersList = ({ blockedUsers, closeModal }) => {
  const queryClient = useQueryClient();
  const isSingleBlocked = blockedUsers?.data.length === 1;
  const { mutate: unblockUserMutate } = useMutation(
    (id) => api.unblockUser(id),
    {
      onSuccess: () => {
        closeModal();
        queryClient.invalidateQueries('blockedUsers');
        notify('Successfully unblocked user');
      },
      onError: (e) =>
        notify(
          e?.response?.data?.error ||
            'Something went wrong, please try again later',
          null,
          'danger'
        ),
    }
  );

  return (
    <div className="w-full px-2 md:p-0">
      <Card>
        <BackgroundSection
          backgroundImage={`url(${modalHeader.default})`}
          className={classnames(
            'relative bg-no-repeat bg-cover bg-center h-18 rounded-t-lg'
          )}>
          <h3 className="p-3 pt-4 md:p-4 md:pt-5 text-white font-hairline">
            BLOCKED USERS
          </h3>
        </BackgroundSection>
        {blockedUsers ? (
          <>
            <p className="p-4 ml-2 md:ml-4 pb-0 text-dusty">
              {`${blockedUsers.data.length} ${
                blockedUsers.data.length === 1 ? 'member is' : 'members are'
              } blocked`}
            </p>
            <div className="flex-1 p-4 pt-1 overflow-y-auto flex flex-col max-h-140">
              {blockedUsers.data.map(
                ({
                  image_url: img,
                  id,
                  first_name: firstName,
                  last_name: lastName,
                  country,
                  users_experiences: workExperience,
                }) => (
                  <div
                    className="my-2 hover:shadow-card rounded-lg w-full py-2 items-center flex"
                    key={id}>
                    <div to="" className="w-full flex flex-row items-center">
                      <div className="rounded-full h-10 w-10 md:h-12 md:w-12 ml-1 md:ml-3 shadow overflow-hidden mr-3 md:mr-5 flex-shrink-0">
                        <Picture
                          src={img || gfxAvatar}
                          className="object-cover w-10 md:w-12 h-full"
                        />
                      </div>
                      <div className="flex flex-col text-ebony">
                        <p className="font-bold md:text-md">
                          {nameFormat({ firstName, lastName })}
                        </p>
                        <p className="text-sm">
                          {`${latestWorkExp(workExperience).company} • ${
                            country?.name
                          }`}
                        </p>
                      </div>
                    </div>
                    <button
                      className="w-60 mr-2 p-1 cursor-pointer overflow-hidden flex justify-center items-center"
                      type="button"
                      onClick={() => unblockUserMutate(id)}>
                      <Picture
                        src={blockUserIcon}
                        className="w-8 sm:mr-4 h-full"
                      />
                      <p>Unblock user</p>
                    </button>
                  </div>
                )
              )}
            </div>
          </>
        ) : (
          <p className="p-4 ml-2 md:ml-4 pb-0 text-dusty">
            No blocked users found.
          </p>
        )}
      </Card>
    </div>
  );
};

BlockedUsersList.propTypes = {
  blockedUsers: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default BlockedUsersList;

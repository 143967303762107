/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getIn } from 'formik';
import CheckMark from '@material-ui/icons/Check';

const SingleCheckboxInput = (props) => {
  const {
    form: { setFieldValue, touched, errors },
    field: { name, value },
    label,
    className,
    onChange,
    hideError,
    nonBooleanValue,
  } = props;
  const error = getIn(touched, name) && getIn(errors, name);
  const checked = value;

  const handleChange = () => {
    if (nonBooleanValue) {
      setFieldValue(name, value ? '' : nonBooleanValue);
    } else {
      setFieldValue(name, !value);
    }

    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <div className={className}>
      <div
        className="inline-flex mr-auto"
        onClick={handleChange}
        role="presentation"
      >
        <div
          className={classnames(
            'flex h-5 w-5 items-center justify-center',
            'text-easter border-gray-600 border rounded',
            'cursor-pointer',
            { 'border-valencia': error }
          )}
        >
          {checked && <CheckMark />}
        </div>
        <label
          className="font-medium text-gray-600 text-sm block mb-2 ml-2 cursor-pointer"
          htmlFor={name}
        >
          {label}
        </label>
      </div>
      {!hideError && (
        <div className="text-valencia h-4 mt-1 text-xs font-light">{error}</div>
      )}
    </div>
  );
};

SingleCheckboxInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  hideError: PropTypes.bool,
  nonBooleanValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SingleCheckboxInput.defaultProps = {
  className: null,
  onChange: null,
  nonBooleanValue: null,
  hideError: false,
};

export default SingleCheckboxInput;

import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Badge, Button, Card, Picture } from 'components/UI';
import {
  iconLocation,
  iconClock,
  iconDate,
  iconStar,
  iconStarActive,
} from 'assets';
import Actions from 'actions';
import { AuthContext } from 'utils/context';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { displayDateFormat } from 'utils/datetime';
import { useNavigate, Link } from 'react-router-dom';
import qs from 'query-string';
import { startCase } from 'lodash';
import FeaturedLayout from '../LandingPage/common/FeaturedLayout';

export const DetailsCard = ({ article, className, hideFavouriteBtn }) => {
  const {
    id,
    publish_at: postedTime,
    read_time: timeToRead,
    current_user_favourited: favourited,
    location,
  } = article;
  const dispatch = useDispatch();
  const { isSubscribed } = useContext(AuthContext);
  const [isFavourited, setFavourited] = useState(favourited || false);
  const handleFavourite = (e) => {
    e.preventDefault();
    const callback = () => setFavourited(!isFavourited);
    return isFavourited
      ? dispatch(Actions.unfavouriteArticleInfo(id, callback))
      : dispatch(Actions.favouriteArticleInfo(id, callback));
  };
  const showFavouriteBtn = isSubscribed && !hideFavouriteBtn;

  const layout = (title, image, onClick = null) => (
    <div
      className={classnames(
        'flex-1 flex justify-center items-center h-10 px-4 py-2',
        'border-alto first:border-none last:border-none',
        'md:border-r sm:border-l text-center',
        { 'cursor-pointer': onClick }
      )}
      onClick={onClick}
      role="presentation"
    >
      <Picture src={image} className="w-5" />
      <span className="text-dusty ml-2">{title}</span>
    </div>
  );

  return (
    <Card
      className={classnames(
        className,
        'absolute left-0 right-0 bottom-0 z-10 grid sm:flex',
        'py-4 w-2/3 mx-auto',
        'bg-white rounded flex flex-wrap items-center justify-center',
        { 'lg:w-1/2': !showFavouriteBtn }
      )}
    >
      {layout(startCase(location || 'Global'), iconLocation)}
      {layout(displayDateFormat(postedTime), iconDate)}
      {layout(`${timeToRead || '0'} min read`, iconClock)}
      {showFavouriteBtn &&
        layout(
          'Favourite',
          isFavourited ? iconStarActive : iconStar,
          handleFavourite
        )}
    </Card>
  );
};

DetailsCard.propTypes = {
  article: PropTypes.object.isRequired,
  className: PropTypes.string,
  hideFavouriteBtn: PropTypes.bool,
};

DetailsCard.defaultProps = {
  className: '-mb-2 md:mb-8',
  hideFavouriteBtn: false,
};

const Featured = ({ article, articlesPath }) => {
  const navigate = useNavigate();

  const {
    id,
    image_url: image,
    article_category: category,
    title,
    summary: subtitle,
  } = article;

  const badgeLabel = category?.name;
  const badgeLabelUrl = `/articles?${qs.stringify({ category: badgeLabel })}`;

  return (
    <FeaturedLayout image={image}>
      <div className="flex items-center justify-center relative mb-40 sm:m-auto">
        <div className="flex flex-col max-w-6xl">
          {badgeLabel && (
            <Link to={badgeLabelUrl}>
              <Badge md bg="bg-gradient-violet-cyan">
                {badgeLabel}
              </Badge>
            </Link>
          )}
          <h1 className="text-white font-semibold lg:leading-none my-4 sm:my-6 lg:text-6xl text-xl leading-tight md:text-2xl">
            {title}
          </h1>
          <p className="text-white sm:text-lg max-w-6xl">{subtitle}</p>
          <div className="my-3 sm:my-6">
            <Button
              label="Read Article"
              onClick={() => navigate(`${articlesPath}/${id}`)}
              bold={false}
              uppercase={false}
              theme="easter"
              className="bg-opacity-50 flex flex-row-reverse"
              icon={<ChevronRightIcon className="ml-2" />}
            />
          </div>
        </div>
      </div>
      <DetailsCard article={article} hideFavouriteBtn />
    </FeaturedLayout>
  );
};

Featured.propTypes = {
  article: PropTypes.object.isRequired,
  articlesPath: PropTypes.string,
};

Featured.defaultProps = {
  articlesPath: '/articles',
};

export default Featured;

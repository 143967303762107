const NAME = 'USER/DELETE_USER_IMAGE';

export const DELETE_USER_IMAGE_REQUEST = `${NAME}_REQUEST`;
export const DELETE_USER_IMAGE_SUCCESS = `${NAME}_SUCCESS`;
export const DELETE_USER_IMAGE_FAIL = `${NAME}_FAIL`;

export const deleteUserImage = (callbacks) => ({
  type: DELETE_USER_IMAGE_REQUEST,
  callbacks,
});

export const deleteUserImageSuccess = () => ({
  type: DELETE_USER_IMAGE_SUCCESS,
});

export const deleteUserImageFail = (error) => ({
  type: DELETE_USER_IMAGE_FAIL,
  error,
});

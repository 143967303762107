/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  RadioButtonUnchecked,
  RadioButtonChecked,
  AddCircle,
  RemoveCircle,
} from '@material-ui/icons';
import { DateInput, SelectInput } from 'components/Form';
import { Field, FieldArray, useFormikContext } from 'formik';
import { HOURS_OPTIONS, DAY_OPTIONS } from 'utils/datetime';

const DEFAULT_VALUES = { date: '', startHour: '', endHour: '' };

const SlotsField = ({ isRecurringSlots, setRecurringSlot }) => {
  const {
    values: { slots, recurringSlots },
    errors,
    setFieldValue,
  } = useFormikContext();

  const SlotButton = ({ title, description, selected, isRecurring }) => (
    <div onClick={() => setRecurringSlot(isRecurring)} role="presentation">
      <div
        className={`flex flex-1 mr-4 mb-4 p-3 items-center cursor-pointer shadow-card rounded-lg h-full ${
          selected ? 'bg-pureWhite' : 'border-gray-200 text-dusty bg-gray-100'
        }`}
      >
        {selected ? (
          <RadioButtonChecked className="text-easter" />
        ) : (
          <RadioButtonUnchecked />
        )}
        <div className="ml-3 lg:ml-5">
          <div className="text-md">{title}</div>
          <div className="text-dusty text-sm">{description}</div>
        </div>
      </div>
    </div>
  );

  SlotButton.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    isRecurring: PropTypes.bool.isRequired,
  };

  const SlotsFieldArray = ({ slots, name, isRecurring }) => (
    <FieldArray
      name={name}
      render={({ push, remove }) => (
        <div className="mb-6">
          {slots.map(({ id, destroy }, index) => (
            <div
              className={`bg-lily rounded-lg p-3 flex flex-col lg:flex-row lg:justify-start lg:px-4 justify-center items-center mb-3 lg:mb-2 ${
                destroy && 'hidden'
              }`}
              key={index}
            >
              <div className="w-full lg:w-auto">
                <p className="text-dusty mb-2">
                  {isRecurring ? 'Day' : 'Date'}
                </p>
                <Field
                  name={`${name}[${index}].date`}
                  component={isRecurring ? SelectInput : DateInput}
                  placeholder={`Select ${isRecurring ? 'Day' : 'Date'}`}
                  options={DAY_OPTIONS}
                  minDate={new Date()}
                />
              </div>
              <div className="w-full lg:w-auto lg:mx-4">
                <p className="text-dusty mb-2">Start Time</p>
                <Field
                  name={`${name}[${index}].startHour`}
                  component={SelectInput}
                  placeholder="Start Time"
                  options={HOURS_OPTIONS.slice(0, -1)}
                  isDisabled={!slots[index].date}
                  onChange={() => setFieldValue(`${name}[${index}].endHour`)}
                />
              </div>
              <div className="w-full lg:w-auto">
                <p className="text-dusty mb-2">End Time</p>
                <Field
                  name={`${name}[${index}].endHour`}
                  component={SelectInput}
                  placeholder="End Time"
                  options={HOURS_OPTIONS.filter((_, i) => {
                    const start = HOURS_OPTIONS.find(
                      ({ value }) => slots[index].startHour === value
                    );
                    return i > HOURS_OPTIONS.indexOf(start);
                  })}
                  isDisabled={!slots[index].startHour}
                />
              </div>
              {!!slots.length && (
                <button
                  className="inline-flex ml-auto text-valencia focus:outline-none hover:bg-pippin rounded-full"
                  type="button"
                  onClick={() => {
                    if (!id) return remove(index);
                    setFieldValue(`${name}[${index}].destroy`, true);
                  }}
                >
                  <RemoveCircle className="mr-2 lg:ml-2" />
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            className="text-easter flex items-center focus:outline-none hover:bg-alto rounded-full"
            type="button"
            onClick={() => push({ ...DEFAULT_VALUES, recurring: isRecurring })}
          >
            <AddCircle className="mr-2" />
            Add Slot
          </button>
        </div>
      )}
    />
  );

  SlotsFieldArray.propTypes = {
    slots: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    isRecurring: PropTypes.bool.isRequired,
  };

  return (
    <div className="mb-2">
      <div className="text-md font-chap mb-1">Consultation Slots</div>
      <p className="text-dusty mb-3">Pick your consultation slots</p>
      <div className="w-full flex flex-col lg:flex-row mb-3 lg:mb-5">
        <SlotButton
          title="Individual consultation slots"
          description="Add and update your available slots manually"
          selected={!isRecurringSlots}
          isRecurring={false}
        />
        <SlotButton
          title="Recurring consultation slots"
          description="Select the day and time you are available"
          selected={isRecurringSlots}
          isRecurring
        />
      </div>
      <div className="mb-3">
        <p>{`${isRecurringSlots ? 'RECURRING' : 'AVAILABLE'} SLOTS`}</p>
        {errors.slots === 'Required' && (
          <div className="text-valencia h-4 mt-1 text-xs font-light">
            At least one type of consultation slot is required
          </div>
        )}
      </div>
      {isRecurringSlots ? (
        <SlotsFieldArray
          name="recurringSlots"
          slots={recurringSlots}
          isRecurring
        />
      ) : (
        <SlotsFieldArray name="slots" slots={slots} isRecurring={false} />
      )}
    </div>
  );
};

export default SlotsField;

SlotsField.propTypes = {
  isRecurringSlots: PropTypes.bool.isRequired,
  setRecurringSlot: PropTypes.func.isRequired,
};

import React from 'react';
import { useQuery } from 'react-query';
import api from 'api';
import BookingCard from './BookingCard';

const CompletedBookings = () => {
  const { data: completedBookings } = useQuery('completedBookings', () =>
    api.fetchCompletedBookings()
  );

  const completedBookingsDataLength = completedBookings?.data?.length || 0;

  return (
    completedBookingsDataLength > 0 && (
      <div>
        <h3 className="font-semibold sm:pb-0 mt-10">PAST CONSULTATIONS</h3>
        {completedBookings?.data?.map((data) => (
          <div
            className="flex-grow-0 flex-shrink-0 my-4 mx-4 sm:mx-0"
            key={data.id}
          >
            <BookingCard booking={data} key={data.id} />
          </div>
        ))}
      </div>
    )
  );
};

export default CompletedBookings;

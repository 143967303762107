import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { SidebarContext } from 'utils/context';
import { INFO_ROUTES, PRIVATE_ROUTES } from 'utils/routes';

import Selectors from 'selectors';
import Actions from 'actions';
import { Picture } from 'components/UI';
import { pngLogoWhite } from 'assets';
import styled from '@emotion/styled';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import BurgerMenu from '@material-ui/icons/Menu';

const SidebarContainer = styled.nav`
  width: 100px;
  ${({ sidebarExpanded }) =>
    sidebarExpanded &&
    `
    width: 0px;
    @media (min-width: 768px) {
      width: 300px;
    }
  `}
  li {
    &:hover,
    &.active {
      background: linear-gradient(90deg, #1e1a30 50%, #59e0ff 150%);
    }
    &.active {
      box-shadow: 4px 0 #47bad4;
    }
  }
`;

const MAX_UNREAD_COUNT_NOTI = 99;

const Sidebar = () => {
  const { sidebarExpanded, toggleSidebar } = useContext(SidebarContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const unreadCount = useSelector(Selectors.getUnreadCount);
  const dashboard = useSelector(Selectors.dashboardInfo);
  const [bookingsCount, setBookingsCount] = useState(0);

  useEffect(() => {
    dispatch(Actions.fetchDashboardInfo());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(dashboard)) {
      const { upcoming_bookings_count: upcomingBookingsCount } = dashboard;
      setBookingsCount(upcomingBookingsCount);
    }
  }, [dashboard]);

  return (
    <SidebarContainer
      className="relative transition-all duration-500 ease-in-out"
      sidebarExpanded={sidebarExpanded}
    >
      <div className="bg-ebony text-white h-full">
        {sidebarExpanded ? (
          <span className="block h-12 text-2xl text-white ml-4 pt-6 icon-expand_logo" />
        ) : (
          <Picture src={pngLogoWhite} className="h-12 ml-4" />
        )}
        <ul className="mt-20 h-full overflow-y-scroll">
          {PRIVATE_ROUTES.map((menu) => (
            <Link to={menu.defaultPath || menu.path} key={menu.label}>
              <li
                className={classnames(
                  'h-16 uppercase flex items-center',
                  'cursor-pointer md:pl-8',
                  {
                    'pl-8': !sidebarExpanded,
                    active: location.pathname.includes(menu.path),
                  }
                )}
              >
                <span
                  className={classnames(
                    'text-lg mr-4 icomoon-icon',
                    `icon-${menu.icon}`,
                    {
                      sm_active:
                        location.pathname.includes(menu.path) &&
                        sidebarExpanded,
                      active:
                        location.pathname.includes(menu.path) &&
                        !sidebarExpanded,
                    }
                  )}
                />
                {sidebarExpanded && menu.label}
                {sidebarExpanded &&
                  menu.path === '/chat' &&
                  unreadCount > 0 && (
                    <div className="flex items-center justify-center ml-auto mr-8 text-xs h-6 w-6 rounded-full bg-valencia invisible md:visible">
                      {unreadCount < MAX_UNREAD_COUNT_NOTI
                        ? unreadCount
                        : '99+'}
                    </div>
                  )}
                {sidebarExpanded &&
                  menu.path === '/members/consultation' &&
                  bookingsCount > 0 && (
                    <div className="flex items-center justify-center ml-auto mr-8 text-xs h-6 w-6 rounded-full bg-valencia invisible md:visible">
                      {bookingsCount < MAX_UNREAD_COUNT_NOTI
                        ? bookingsCount
                        : '99+'}
                    </div>
                  )}
              </li>
            </Link>
          ))}
          <div className="h-2/5" />
        </ul>
      </div>
      <button
        className="absolute top-0 right-0 z-50 bg-ebony text-white rounded-r-lg focus:outline-none h-10 w-10 -mr-6 mt-6"
        onClick={() => toggleSidebar(!sidebarExpanded)}
        type="button"
      >
        <div className="hidden sm:block">
          {sidebarExpanded ? (
            <NavigateBefore fontSize="large" />
          ) : (
            <BurgerMenu fontSize="large" />
          )}
        </div>
        <div className="sm:hidden">
          {sidebarExpanded ? (
            <BurgerMenu fontSize="large" />
          ) : (
            <NavigateBefore fontSize="large" />
          )}
        </div>
      </button>
      {sidebarExpanded && (
        <div className="absolute bottom-0 invisible md:visible bg-ebony text-sm">
          <div className="mx-4 border-gray-700 border-t-2 rounded" />
          <div className="flex flex-wrap px-5 py-2">
            {INFO_ROUTES.map(({ path, label }) => (
              <Link
                to={path}
                key={label}
                className="text-silver mr-4 my-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                {label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import { Breadcrumbs } from 'components/UI';
import { AuthContext } from 'utils/context';
import useQuery from 'hooks/useQuery';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import Tabs from './Tabs';
import Landing from './Landing';
import Bookings from './Bookings';
import Service from './Service';

const Consultation = () => {
  const dispatch = useDispatch();
  const params = useQuery();
  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();
  const { authenticated, hasAccess, isSubscribed } = useContext(AuthContext);
  const memberPage = '/members/consultation';
  const [consultantIndex, setConsultantIndex] = useState(0);
  const [activePage, setActivePage] = useState(currentPath);
  const consultants = useSelector(Selectors.getConsultants);
  const isBookingsScreen = activePage === `${memberPage}/bookings`;
  const isMyServiceScreen = activePage === `${memberPage}/my-service`;

  useEffect(() => {
    if (authenticated && hasAccess && !currentPath.includes(memberPage)) {
      const query = isEmpty(params) ? '' : `?${qs.stringify(params)}`;
      navigate(`${memberPage}/service${query}`, { replace: true });
    }
  }, [authenticated, navigate, hasAccess, currentPath]);

  useEffect(() => {
    if (currentPath !== activePage) setActivePage(currentPath);
  }, [currentPath, activePage]);

  useEffect(() => {
    dispatch(Actions.fetchConsultants());
  }, [dispatch]);

  useEffect(() => {
    let indexOfConsultant;
    if (!isEmpty(params) && consultants.length > 0) {
      indexOfConsultant = consultants.findIndex((i) => i.slug === params.name);
      if (indexOfConsultant !== -1) {
        setConsultantIndex(indexOfConsultant);
      }
    }
  }, [params, consultantIndex, consultants]);

  const handleTab = (tab) => {
    navigate(tab, { replace: true });
    setActivePage(tab);
  };

  const breadCrumbTrails = () => {
    const activeTrail = () => {
      if (isBookingsScreen) return 'Booking';
      if (isMyServiceScreen) return 'My Service';
      return 'Service';
    };

    return [
      {
        label: 'CONSULTATION',
        path: currentPath,
        icon: 'consultation',
      },
      activeTrail(),
    ];
  };

  const Content = () => {
    if (authenticated) {
      if (isBookingsScreen) return <Bookings />;
      if (isMyServiceScreen) return <Service />;
    }

    return (
      <Landing
        consultants={consultants}
        isSubscribed={isSubscribed}
        handleTab={handleTab}
        consultantIndex={consultantIndex}
      />
    );
  };

  return (
    <>
      {hasAccess && (
        <>
          <Breadcrumbs trails={breadCrumbTrails()} />
          <Tabs currentActive={activePage} handleTab={handleTab} />
        </>
      )}
      <Content />
    </>
  );
};

export default Consultation;

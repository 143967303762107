import Actions from 'actions';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import api from 'api';

function* setupIntent({ data, callback }) {
  try {
    const response = yield call(api.setupIntent, data);
    yield put(Actions.setupIntentSuccess(response.data));
    if (typeof callback === 'function') {
      yield call(callback, response.data);
    }
  } catch (error) {
    yield put(Actions.setupIntentFail(error));
  }
}

function* watchSetupIntent() {
  yield takeLatest(Actions.SETUP_INTENT_REQUEST, setupIntent);
}

export default function* setup() {
  yield all([fork(watchSetupIntent)]);
}

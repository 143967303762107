import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ children, className }) => {
  return (
    <div className={`${className} bg-white shadow-card rounded-lg`}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  className: null,
};

export default Card;

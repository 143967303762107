import Actions from 'actions';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import api from 'api';

function* cancelUpdateSubscription({ callbacks }) {
  try {
    const response = yield call(api.cancelUpdateSubscription);
    yield put(Actions.cancelUpdateSubscriptionSuccess(response?.data));
    if (callbacks?.success) {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.cancelUpdateSubscriptionFail(error));
    const errorMsg = error?.response?.data?.error;
    if (callbacks?.failure) {
      yield call(callbacks.failure, errorMsg && errorMsg[0]);
    }
  }
}

function* watchCancelUpdateSubscription() {
  yield takeLatest(
    Actions.CANCEL_UPDATE_SUBSCRIPTION_REQUEST,
    cancelUpdateSubscription
  );
}

export default function* cancelUpdate() {
  yield all([fork(watchCancelUpdateSubscription)]);
}

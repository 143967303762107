const NAME = 'AUTH/CREATE_PASSWORD';

export const CREATE_PASSWORD_REQUEST = `${NAME}_REQUEST`;
export const CREATE_PASSWORD_SUCCESS = `${NAME}_SUCCESS`;
export const CREATE_PASSWORD_FAIL = `${NAME}_FAIL`;

export const createPassword = (data, callbacks) => ({
  type: CREATE_PASSWORD_REQUEST,
  data,
  callbacks,
});

export const createPasswordSuccess = () => ({
  type: CREATE_PASSWORD_SUCCESS,
});

export const createPasswordFail = (error) => ({
  type: CREATE_PASSWORD_FAIL,
  error,
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckMark from '@material-ui/icons/Check';
import classnames from 'classnames';

const Checkbox = ({ onChange, label, error, name, className, value }) => {
  const [checked, setChecked] = useState(value || false);

  const handleChange = () => {
    setChecked(!checked);
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <div
      className={classnames('inline-flex', className)}
      onClick={handleChange}
      role="presentation"
    >
      <div
        className={classnames(
          'flex h-5 w-5 items-center justify-center',
          'text-easter border-gray-600 border rounded',
          'cursor-pointer',
          { 'border-valencia': error }
        )}
      >
        {checked && <CheckMark />}
      </div>
      <label
        className="font-medium text-gray-600 text-sm block ml-2 cursor-pointer"
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.bool,
};

Checkbox.defaultProps = {
  onChange: () => {},
  label: null,
  error: null,
  name: null,
  className: '',
  value: false,
};

export default Checkbox;

import * as list from './list';
import * as info from './info';
import * as favouriteList from './favouriteList';
import * as favourite from './favourite';
import * as unfavourite from './unfavourite';

export default {
  ...list,
  ...info,
  ...favouriteList,
  ...favourite,
  ...unfavourite,
};

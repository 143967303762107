import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { useDropzone } from 'react-dropzone';
import WallpaperIcon from '@material-ui/icons/Wallpaper';

const ImageInput = (props) => {
  const {
    form: { touched, errors, setFieldValue },
    field: { name },
    initialImgUrl,
    attachmentComponent,
    onAttach,
    onError,
  } = props;
  const formError = getIn(touched, name) && getIn(errors, name);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);
  const [uploadedBlob, setUploadedBlob] = useState();
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setUploadedBlob(file);
      const objectURL = URL.createObjectURL(file);
      setPreview(objectURL);
      setError(null);
      setFieldValue(name, file);
    },
    [name, setFieldValue]
  );
  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDrag: false,
    multiple: false,
    maxSize: 2000000,
  });
  const rejectError = fileRejections[0];

  const dropZoneComponent = () => {
    if (attachmentComponent) return attachmentComponent;

    return (
      <div className="sm:h-64 h-56 bg-gray-100 border-dashed border-2 border-alto text-dusty flex justify-center items-center rounded-lg cursor-pointer my-3 relative hover:border-easter duration-1000 transition">
        {preview ? (
          <img src={preview} alt="uploaded" className="h-full" />
        ) : (
          <div className="text-center">
            <div className="flex justify-center mb-2">
              <WallpaperIcon style={{ fontSize: 60 }} />
            </div>
            <div className="">Select or drop image (JPEG or PNG)</div>
            <div className="text-xs italic">
              *Recommended dimension: 1200 x 600, max size: 2MB
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (rejectError) {
      setError(rejectError.errors[0].message);
      if (onError) onError();
    }
  }, [rejectError, onError]);

  useEffect(() => setPreview(initialImgUrl), [initialImgUrl]);

  useEffect(() => {
    if (onAttach) onAttach(uploadedBlob);
  }, [onAttach, uploadedBlob]);

  return (
    <div className="w-full sm:w-1/2">
      <div {...getRootProps()} onClick={open} role="presentation">
        <input {...getInputProps()} />
        {dropZoneComponent()}
      </div>
      {!attachmentComponent && (
        <div className="text-valencia h-4 mt-2 text-xs font-light">
          {error || formError}
        </div>
      )}
    </div>
  );
};

ImageInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  initialImgUrl: PropTypes.string,
  attachmentComponent: PropTypes.node,
  onAttach: PropTypes.func,
  onError: PropTypes.func,
};

ImageInput.defaultProps = {
  initialImgUrl: null,
  attachmentComponent: null,
  onAttach: null,
  onError: null,
};

export default ImageInput;

import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/UI/Card';
import ReactHtmlParser from 'react-html-parser';

const LanguageAndGenreCard = ({ languages, genres }) => {
  return (
    <Card className="bg-white rounded flex flex-wrap justify-center py-4 lg:py-8 sm:w-2/3 w-5/6 lg:w-1/2 mx-auto -mt-20 text-center max-w-2xl">
      <div className="w-1/2 border-gray-400 border-r">
        <div className="uppercase text-gray-600 mb-2">Language(s)</div>
        <div className="inline-flex flex-col text-left mb-1 px-2 sm:px-8">
          {ReactHtmlParser(languages || 'N/A')}
        </div>
      </div>
      <div className="w-1/2">
        <div className="uppercase text-gray-600">Top genres</div>
        <div className="inline-flex flex-col text-left mb-1 pl-2 sm:px-8 text-sm lg:text-base">
          {ReactHtmlParser(genres || 'N/A')}
        </div>
      </div>
    </Card>
  );
};

LanguageAndGenreCard.propTypes = {
  languages: PropTypes.string.isRequired,
  genres: PropTypes.string.isRequired,
};

export default LanguageAndGenreCard;

import ReactGA from 'react-ga4';
import CONFIG, { isProduction } from 'utils/config';

export const initGA = () => {
  ReactGA.initialize(CONFIG.ANALYTICS);
};

export const gaEvents = {
  conversion: (tier) => {
    if (!isProduction) return;

    ReactGA.event({
      action: `${tier.toLowerCase()}`,
      category: 'Conversions',
    });
  },
};

export const storeUTMParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');
  const utmContent = urlParams.get('utm_content');
  const utmTerm = urlParams.get('utm_term');

  if (utmSource || utmMedium || utmCampaign || utmContent || utmTerm) {
    const utmData = {
      source: utmSource,
      medium: utmMedium,
      campaign: utmCampaign,
      content: utmContent,
      term: utmTerm,
    };

    sessionStorage.setItem('sourceInfo', JSON.stringify(utmData));
  }
};

export const getUTMParameters = () => {
  const utmData = sessionStorage.getItem('sourceInfo');
  if (!utmData) return null;

  return JSON.parse(utmData);
};

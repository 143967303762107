import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* createPassword({ data, callbacks }) {
  try {
    const response = yield call(api.createPassword, data);
    yield put(Actions.createPasswordSuccess(response.data));
    if (typeof callbacks.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.createPasswordFail(error));
    if (typeof callbacks.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchCreatePassword() {
  yield takeLatest(Actions.CREATE_PASSWORD_REQUEST, createPassword);
}

export default function* create() {
  yield all([fork(watchCreatePassword)]);
}

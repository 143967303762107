import React, { useState } from 'react';
import Actions from 'actions';
import Selectors from 'selectors';
import { Loading } from 'components/UI';
import { useSelector } from 'react-redux';
import AuthLayout from '../common/AuthLayout';
import RequestForgotPasswordForm from './Form/RequestForgotPassword';
import ValidateResetPasswordCodeForm from './Form/ValidateResetPasswordCode';

const ForgotPassword = () => {
  const [formStep, setStep] = useState(0);
  const [email, setEmail] = useState(undefined);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([
      Actions.FORGOT_PASSWORD_REQUEST,
      Actions.VALIDATE_RESET_PASSWORD_CODE_REQUEST,
    ])
  );

  const renderStepForm = () => {
    switch (formStep) {
      case 0:
        return (
          <RequestForgotPasswordForm
            nextStep={() => setStep(formStep + 1)}
            setEmail={setEmail}
          />
        );
      case 1:
        return (
          <ValidateResetPasswordCodeForm
            previousStep={() => setStep(formStep - 1)}
            email={email}
          />
        );
      default:
        return <RequestForgotPasswordForm />;
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <AuthLayout>{renderStepForm()}</AuthLayout>
    </>
  );
};

export default ForgotPassword;

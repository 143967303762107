import { combineReducers } from 'redux';
import list from './list';
import categories from './categories';
import info from './info';

export default combineReducers({
  list,
  categories,
  info,
});

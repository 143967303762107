import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { displayTime } from 'utils/datetime';
import AddSlot from './AddSlot';

const PreviewSlot = ({ slotLabel, slotValue, service, recurring }) => {
  const [editSlotIsOpen, setEditSlotIsOpen] = useState(false);
  const openEditSlot = () => {
    setEditSlotIsOpen(!editSlotIsOpen);
  };
  const closeSlotForms = () => {
    setEditSlotIsOpen(false);
  };
  const [slot] = slotValue;
  const date = new Date(slot.start_at);
  const startHour = `${date.getHours()}`;
  const endHour = `${new Date(slot.end_at).getHours()}`;
  const currentSlot = { date, startHour, endHour };

  return (
    <div className="mb-5 bg-gray-100 p-3 rounded-lg" key={slotLabel}>
      <p className="mb-1">
        {recurring ? `Every ${slotLabel}` : `${slotLabel}`}
      </p>
      <ul className="list-disc mx-4 flex justify-between">
        {slotValue.map(({ start_at: start, end_at: end }) => (
          <li key={`${start}${end}`} className="font-thin">
            {`${displayTime(start)} to ${displayTime(end)}`}
          </li>
        ))}
        <button
          className={classNames('text-sm hover:text-easter', {
            'text-boulder': editSlotIsOpen,
          })}
          type="button"
          onClick={openEditSlot}
        >
          {editSlotIsOpen ? 'CANCEL' : 'EDIT'}
        </button>
      </ul>
      <AddSlot
        addRecurringSlot={recurring}
        service={service}
        closeSlotForms={closeSlotForms}
        editSlotIsOpen={editSlotIsOpen}
        currentSlot={currentSlot}
        currentSlotId={slot.id}
      />
    </div>
  );
};

PreviewSlot.propTypes = {
  slotLabel: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
  slotValue: PropTypes.string.isRequired,
  recurring: PropTypes.bool,
};

PreviewSlot.defaultProps = {
  recurring: false,
};

export default PreviewSlot;

import { takeLatest, all, fork, put, call, take } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* attendEvent({ id, data, callbacks }) {
  try {
    const response = yield call(api.attendEvent, { id, data });
    yield put(Actions.fetchEventInfo(id));
    yield take([
      Actions.FETCH_EVENT_INFO_SUCCESS,
      Actions.FETCH_EVENT_INFO_FAIL,
    ]);
    yield put(Actions.attendEventSuccess(response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks?.success, response.data);
    }
  } catch (error) {
    yield put(Actions.attendEventFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchAttendEvent() {
  yield takeLatest(Actions.ATTEND_EVENT_REQUEST, attendEvent);
}

export default function* attend() {
  yield all([fork(watchAttendEvent)]);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title, ogDesc, ogImg }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={ogDesc} />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:height" content="200" />
      <meta property="og:image" content={ogImg} />
    </Helmet>
  );
};

PageMeta.propTypes = {
  title: PropTypes.string,
  ogDesc: PropTypes.string,
  ogImg: PropTypes.any,
};

PageMeta.defaultProps = {
  title: 'Gig Life Pro',
  ogImg:
    'https://giglifepro-assets.s3-ap-southeast-1.amazonaws.com/glp_preview.png',
  ogDesc:
    'Connect and collaborate with the best Music Industry Professionals and grow your business across Asia Pacific.',
};

export default PageMeta;

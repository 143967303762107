import Actions from 'actions';
import uniqBy from 'lodash/uniqBy';

const initialState = Object.freeze({});

function list(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_USERS_REQUEST:
      return { ...initialState, allData: state.allData || [] };
    case Actions.FETCH_USERS_SUCCESS:
      return {
        ...initialState,
        data: action.data,
        page: action.page,
        allData:
          action.page?.currentPage === 1
            ? action.data
            : uniqBy([...state.allData, ...action.data], 'id'),
      };
    case Actions.FETCH_USERS_FAIL:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
}

export default list;

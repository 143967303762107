import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/UI';

const SuccessScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col max-w-120">
      <h1 className="font-chap text-white text-center text-xl md:mt-24">
        Welcome to Gig Life Pro
      </h1>
      <div className="text-white text-center text-sm mt-4">
        Let’s complete your profile so other members get to know you better and
        increase your connections
      </div>
      <Button
        className="mt-8 md:mt-24 mb-6 max-w-80 mx-auto"
        label="Complete my profile"
        onClick={() => navigate('/profile')}
      />
      <div className="flex">
        <button
          className="text-dusty hover:opacity-75 focus:outline-none mx-auto"
          type="button"
          onClick={() => navigate('/dashboard')}
        >
          Skip for now
        </button>
      </div>
    </div>
  );
};

export default SuccessScreen;

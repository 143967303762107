const NAME = 'SENDGRID/SEND_ENQUIRY_EMAIL';

export const SEND_ENQUIRY_EMAIL_REQUEST = `${NAME}_REQUEST`;
export const SEND_ENQUIRY_EMAIL_SUCCESS = `${NAME}_SUCCESS`;
export const SEND_ENQUIRY_EMAIL_FAIL = `${NAME}_FAIL`;

export const sendEnquiryEmail = (data, callbacks) => ({
  type: SEND_ENQUIRY_EMAIL_REQUEST,
  data,
  callbacks,
});

export const sendEnquiryEmailSuccess = () => ({
  type: SEND_ENQUIRY_EMAIL_SUCCESS,
});

export const sendEnquiryEmailFail = (error) => ({
  type: SEND_ENQUIRY_EMAIL_FAIL,
  error,
});

import { takeLatest, all, fork, put, call, select } from 'redux-saga/effects';
import Actions from 'actions';
import Selectors from 'selectors';
import { connect } from 'utils/sendbird';
import { nameFormat } from 'utils/format';

function* connectSendbird() {
  if (process.env.NODE_ENV === 'development') return;

  const {
    first_name: firstName,
    last_name: lastName,
    image_url: imageUrl,
    sendbird_id: sendbirdId,
  } = yield select(Selectors.getUser);

  try {
    if (!sendbirdId) {
      yield put(Actions.connectSendbirdFail());
      return;
    }
    const response = yield call(connect, sendbirdId);

    if (response) {
      yield put(Actions.connectSendbirdSuccess());
      yield put(Actions.getTotalUnreadCount());
      yield put(
        Actions.updateSendbirdUser(
          nameFormat({ firstName, lastName }),
          imageUrl
        )
      );
    }
  } catch (error) {
    yield put(Actions.connectSendbirdFail(error));
  }
}

function* watchConnectSendbird() {
  yield takeLatest(Actions.CONNECT_SENDBIRD_REQUEST, connectSendbird);
}

export default function* list() {
  yield all([fork(watchConnectSendbird)]);
}

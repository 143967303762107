import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Button } from 'components/UI';
import TextInput from 'components/Form/TextInput';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { KeyboardArrowDown } from '@material-ui/icons';
import { plusOneIcon } from 'assets';
import { ALL_ACCESS } from 'utils/constants';

const GuestFormSchema = Yup.object().shape({
  email: Yup.string().required('Field is required').email('Invalid email'),
  full_name: Yup.string().required('Field is required'),
  company: Yup.string().required('Field is required'),
});

const ConfirmBookingModal = ({
  eventName,
  handleConfirmBooking,
  tier,
  id,
  openSlot,
  guestsAllowed,
}) => {
  const dispatch = useDispatch();
  const [isGuestFormOpen, setIsGuestFormOpen] = useState(false);
  const triggerGuestForm = () => setIsGuestFormOpen(!isGuestFormOpen);
  return (
    <>
      <p>{`Please confirm your booking for "${eventName}" event`}</p>
      <div className="text-left">
        <Formik
          initialValues={{
            email: '',
            full_name: '',
            company: '',
          }}
          onSubmit={(values) => handleConfirmBooking(id, values)}
          validationSchema={isGuestFormOpen && GuestFormSchema}
        >
          {({ handleSubmit }) => (
            <>
              <div
                className={classnames(
                  'w-full border rounded-lg p-5 mt-5 flex flex-col',
                  {
                    hidden:
                      tier.name !== ALL_ACCESS ||
                      openSlot < 2 ||
                      !guestsAllowed,
                  }
                )}
              >
                <div className="flex">
                  <div className="self-start mr-5">
                    <img
                      src={plusOneIcon.default}
                      alt="plus-one-icon"
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="font-semibold text-md text-left self-start">
                      Include a plus one ?
                    </p>
                    <p className="font-light text-sm text-dusty self-start text-left w-11/12 mt-1">
                      You can bring a guest to this event. This is a special
                      perk available only to all access members
                    </p>
                  </div>
                  {!isGuestFormOpen ? (
                    <ChevronRightIcon
                      className="mr-1"
                      fontSize="large"
                      onClick={triggerGuestForm}
                      role="presentation"
                    />
                  ) : (
                    <KeyboardArrowDown
                      className="mr-1 text-easter"
                      fontSize="large"
                      onClick={triggerGuestForm}
                      role="presentation"
                    />
                  )}
                </div>
                <div
                  className={classnames(
                    'border-t mt-3 pt-3 flex flex-col space-y-3',
                    {
                      hidden: !isGuestFormOpen,
                    }
                  )}
                >
                  <p className="self-start ml-2 text-left mt-3">
                    Enter details of your plus-one
                  </p>
                  <Form className="flex flex-col mt-5 space-y-4 text-xs sm:text-sm">
                    <Field
                      label="Email"
                      placeholder="Email"
                      name="email"
                      type="email"
                      component={TextInput}
                    />
                    <Field
                      label="Full Name"
                      placeholder="Full Name"
                      name="full_name"
                      type="text"
                      component={TextInput}
                    />
                    <Field
                      label="Company"
                      placeholder="Company : Enter N/A if not applicable"
                      name="company"
                      type="text"
                      component={TextInput}
                    />
                  </Form>
                  <div className="flex items-center">
                    <p className="text-dusty text-xs">*</p>
                    <p className="text-xs text-dusty ml-1">
                      Close this form if you wish to not bring a guest.
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-dusty self-start text-xs">*</p>
                    <p className="text-xs text-dusty ml-1">
                      You can also fill up these details later on your event
                      page.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-4 mt-5">
                <Button
                  label="BOOK SLOT"
                  className="w-full sm:w-3/4 h-12 px-4 text-base"
                  onClick={handleSubmit}
                  isPill
                />
                <Button
                  label="NO, DON'T BOOK"
                  className="w-full sm:w-3/4 h-12 px-4 text-base"
                  theme="plain"
                  outline
                  isPill
                  onClick={() => dispatch(Actions.hideDialog())}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

ConfirmBookingModal.propTypes = {
  handleConfirmBooking: PropTypes.func.isRequired,
  eventName: PropTypes.string.isRequired,
  tier: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  openSlot: PropTypes.number.isRequired,
  guestsAllowed: PropTypes.bool.isRequired,
};

export default ConfirmBookingModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ClickAwayListener } from '@material-ui/core';
import { Card } from 'components/UI';
import classnames from 'classnames';

const Options = ({ list }) => {
  const [isOpen, setOpen] = useState();

  return (
    <div className="relative z-10">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <button
            type="button"
            className="focus:outline-none"
            onClick={() => setOpen(!isOpen)}
          >
            <MoreVertIcon className="text-dusty ml-1 sm:ml-2" />
          </button>
          <Card
            className={classnames(
              'border-t-4 border-easter flex justify-between flex-col absolute right-0 items-start w-60 overflow-hidden',
              { hidden: !isOpen }
            )}
          >
            {list.map(({ label, onClick, className, key }) => (
              <div
                onClick={() => {
                  onClick();
                  setOpen(false);
                }}
                key={key || label}
                role="presentation"
                className={`py-2 hover:bg-sigmaBlue px-4 w-full text-left text-dusty hover:bg-opacity-25 hover:text-easter cursor-pointer ${className}`}
              >
                {label}
              </div>
            ))}
          </Card>
        </div>
      </ClickAwayListener>
    </div>
  );
};

Options.propTypes = {
  list: PropTypes.array.isRequired,
};

export default Options;

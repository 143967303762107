import React from 'react';
import PropTypes from 'prop-types';
import { SocialMediaLinkInput, SingleCheckboxInput } from 'components/Form';
import { Picture, Button, Modal } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import { iconPromo, imgTableBenefits } from 'assets';
import { Field } from 'formik';
import Pricing from './Pricing';

const Activate = ({
  tierInfo,
  isLoading,
  selectedPlan,
  setPlan,
  isLoadingPromoCode,
  navigateBack,
  liteOnly,
  referralCode,
}) => {
  if (isLoading || Object.keys(tierInfo).length === 0) return <Spinner />;

  const { name, prices } = tierInfo;
  const priceMonthly = Number(
    tierInfo?.prices?.find((item) => item.interval === 'month')?.amount
  );
  const priceAnnually = Number(
    tierInfo?.prices?.find((item) => item.interval === 'year')?.amount
  );

  return (
    <>
      <div className="flex flex-col lg:px-16 items-center">
        <h5 className="text-2lg text-center text-rock">
          {navigateBack
            ? `Apply to be a ${name} Member!`
            : `Awesome! You are now a ${name} Member!`}
        </h5>
        <p className="text-dusty text-center mb-4 max-w-120">
          {navigateBack
            ? 'Choose your membership payment frequency'
            : 'In order to activate your account, you are required to make payment for the membership fee.'}
        </p>
        <div className="flex flex-col sm:flex-row w-full items-center justify-around">
          {prices?.map((price) => (
            <Pricing
              key={price.id}
              {...price}
              setPlan={() => setPlan(price.id)}
              isSelected={selectedPlan === price.id}
              isAnnual={price.interval === 'year'}
              amount={Number(price.amount)}
              savings={priceMonthly * 12 - priceAnnually}
            />
          ))}
        </div>
        {liteOnly && (
          <div className="text-rock mt-6 text-center">
            {`What are the `}
            <Modal content={<Picture src={imgTableBenefits} />}>
              <span className="text-easter cursor-pointer">
                perks &amp; benefits?
              </span>
            </Modal>
          </div>
        )}
        <div className="w-11/12 mt-4">
          <SocialMediaLinkInput
            className="w-full"
            name="promoCode"
            img={iconPromo.default}
            placeholder="Enter promo code…"
          />
        </div>
        {referralCode && (
          <div className="mb-4 p-3 border-dotted border-easter border-2 bg-lily">
            <p>Referral code applied {referralCode}</p>
          </div>
        )}
        <Field
          name="agree"
          component={SingleCheckboxInput}
          label={
            <div className="max-w-sm">
              By ticking this box you confirm that you agree to our{' '}
              <a
                className="font-semibold text-easter"
                href="/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              . You can edit payment duration at any time and cancel membership
              30 days before your next billing cycle.
            </div>
          }
        />
        <div className="mt-4">
          <Button
            className="block mx-auto"
            label="PROCEED TO PAYMENT"
            type="submit"
            isLoading={isLoadingPromoCode}
            loadInButton
          />
          {navigateBack && (
            <button
              className="uppercase mx-auto mt-6 text-xs text-dusty hover:text-easter flex focus:outline-none"
              type="button"
              onClick={navigateBack}
            >
              Return to previous screen
            </button>
          )}
        </div>
      </div>
    </>
  );
};

Activate.propTypes = {
  selectedPlan: PropTypes.number,
  setPlan: PropTypes.func.isRequired,
  navigateBack: PropTypes.func,
  tierInfo: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingPromoCode: PropTypes.bool.isRequired,
  liteOnly: PropTypes.bool.isRequired,
  referralCode: PropTypes.string,
};

Activate.defaultProps = {
  selectedPlan: null,
  navigateBack: null,
  referralCode: null,
};

export default Activate;

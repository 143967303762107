/* eslint-disable camelcase */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextArea from 'react-textarea-autosize';
import { AuthContext } from 'utils/context';
import classnames from 'classnames';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { ImageInput } from 'components/Form';
import { Formik, Form, Field } from 'formik';
import notify from 'utils/notification';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';

const ChatInput = ({ onSubmit, recipient, hasAttachment }) => {
  const [text, setText] = useState('');
  const [attachment, setAttachment] = useState();
  const [preview, setPreview] = useState();
  const { isPro: userIsPro } = useContext(AuthContext);
  const recipientIsPro = recipient?.member_tier?.name === ALL_ACCESS;
  const sendingBlocked = !userIsPro && recipientIsPro;

  const handleSendMessage = () => {
    const msg = attachment || text;
    if (!msg) return;
    if (!sendingBlocked) {
      const callback = () => {
        setText('');
        setAttachment(null);
      };
      onSubmit(msg, callback);
    }
    setAttachment(null);
    setText('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    setPreview(attachment && URL.createObjectURL(attachment));
  }, [attachment]);

  return (
    <div className="flex sticky bottom-0 bg-concrete w-full p-2">
      {preview ? (
        <div className="flex-1 rounded-lg min-h-10 py-2 px-4 focus:outline-none resize-none bg-white">
          <div className="relative inline-block">
            <img src={preview} alt="uploaded" className="max-h-80 rounded-lg" />
            <div
              className={classnames(
                'absolute top-0 right-0 -mt-1 -mr-1',
                'flex justify-center items-center',
                'h-6 w-6 bg-valencia rounded-full cursor-pointer',
                'text-sm text-white font-bold leading-loose'
              )}
              onClick={() => setAttachment(null)}
              role="presentation"
            >
              X
            </div>
          </div>
        </div>
      ) : (
        <TextArea
          maxRows="10"
          className="flex-1 rounded-lg min-h-10 py-2 px-4 focus:outline-none resize-none"
          onChange={(event) => setText(event.target.value)}
          onKeyDown={handleKeyDown}
          value={text}
          placeholder={
            sendingBlocked
              ? `${PREMIUM} members can't message ${ALL_ACCESS} members`
              : 'Type a message…'
          }
          disabled={sendingBlocked}
        />
      )}
      {hasAttachment && (
        <Formik initialValues={{ image: null }}>
          <Form className="m-auto">
            <Field
              name="image"
              component={ImageInput}
              onAttach={setAttachment}
              onError={() =>
                notify('Image size should be smaller than 2MB', null, 'danger')
              }
              attachmentComponent={
                <AttachFileIcon className="cursor-pointer hover:text-easter" />
              }
            />
          </Form>
        </Formik>
      )}
      <button
        type="button"
        className={classnames('focus:outline-none mx-2', {
          'cursor-not-allowed': sendingBlocked,
          'hover:text-easter': !sendingBlocked,
        })}
        onClick={handleSendMessage}
        disabled={sendingBlocked}
      >
        Send
      </button>
    </div>
  );
};

ChatInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  recipient: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasAttachment: PropTypes.bool,
};

ChatInput.defaultProps = {
  hasAttachment: false,
  recipient: null,
};

export default ChatInput;

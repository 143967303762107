import React, { useContext, useEffect, useRef, useState } from 'react';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import api from 'api';
import { Link, useNavigate } from 'react-router-dom';
import linkifyHtml from 'linkify-html';

import styled from '@emotion/styled';
import classnames from 'classnames';
import { Picture, Button, Options, Modal } from 'components/UI';
import { gfxAvatar, gfxEmptyChat, gfxGroupChat } from 'assets';
import { isToday, format } from 'date-fns';
import startCase from 'lodash/startCase';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { AuthContext } from 'utils/context';
import { Tooltip } from '@material-ui/core';
import CreateForm from './CreateForm';
import ChatInput from './ChatInput';

const ChatBubbleContainer = styled.div`
  max-width: 80%;
`;

const MessagesList = ({ messages, inviter, recipients, groupName }) => {
  const ref = useRef(null);
  const { sendbird_id: id } = useSelector(Selectors.getUser);
  useEffect(() => {
    if (messages.length > 0) {
      ref.current.scrollIntoView(false);
    }
  }, [messages]);
  const sampleRecipient =
    inviter &&
    recipients.find(({ nickname }) => nickname !== inviter.nickname).nickname;

  return (
    <div className="flex-1 p-4 overflow-y-auto flex flex-col">
      {inviter && inviter.userId !== id && (
        <div className="italic text-dusty text-center w-full text-sm">
          {`${inviter.nickname} invited ${sampleRecipient} and ${
            recipients.length - 1
          } more to "${groupName}"`}
        </div>
      )}
      {messages.map(
        ({
          text,
          position,
          id,
          createdAt,
          fromAdmin,
          avatar,
          nickname,
          plainUrl,
        }) => (
          <ChatBubbleContainer
            key={id}
            className={classnames('flex flex-col m-2', {
              'mr-auto': position === 'left' && !fromAdmin,
              'ml-auto': position === 'right',
              'mx-auto bg-lily rounded-full px-4': fromAdmin,
            })}
          >
            {position === 'left' && !fromAdmin && (
              <div className="text-xs font-light text-silver mr-auto ml-12">
                {nickname}
              </div>
            )}
            {fromAdmin ? (
              <span className="text-gull text-center text-xs">{text}</span>
            ) : (
              <div
                className={classnames('flex items-center', {
                  'flex-row-reverse': position === 'right',
                })}
              >
                <Picture
                  src={avatar || gfxAvatar}
                  className="h-8 w-8 rounded-full object-cover flex-shrink-0 self-end shadow-xs"
                  fallbackImage={gfxAvatar.default}
                />
                {plainUrl ? (
                  <img
                    src={plainUrl}
                    className="max-h-104 mx-2 rounded-md"
                    alt="uploaded"
                  />
                ) : (
                  <div
                    className={classnames(
                      'p-2 rounded word-break whitespace-pre-wrap mx-2',
                      {
                        'bg-twilight': position === 'left',
                        'bg-boston text-white': position === 'right',
                      }
                    )}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(text, { target: '_blank' }),
                    }}
                  />
                )}
              </div>
            )}
            <div
              className={classnames('text-xs font-light text-silver', {
                'mr-auto ml-12': position === 'left' && !fromAdmin,
                'ml-auto mr-12': position === 'right',
                'text-center': fromAdmin,
              })}
            >
              {fromAdmin && 'By Admin '}
              {format(createdAt, isToday(createdAt) ? 'kk:mm' : 'EEE kk:mm')}
            </div>
          </ChatBubbleContainer>
        )
      )}
      <div ref={ref} />
    </div>
  );
};

MessagesList.propTypes = {
  messages: PropTypes.array.isRequired,
  recipients: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  groupName: PropTypes.string.isRequired,
  inviter: PropTypes.object,
};

MessagesList.defaultProps = {
  inviter: null,
};

const ChannelInfo = ({ navigate, channel, showChatList, leaveChannel }) => {
  const isGroup = channel?.isGroup;
  const directRecipient = isGroup ? {} : channel?.recipients;
  const {
    connectionStatus,
    nickname,
    plainProfileUrl,
    userId,
  } = directRecipient;
  const groupActiveCount = isGroup
    ? channel?.recipients?.filter(
        ({ connectionStatus }) => connectionStatus === 'online'
      ).length
    : null;
  const isOnline = isGroup ? groupActiveCount : connectionStatus === 'online';

  const { data: userProfileData } = useQuery(
    ['userProfile'],
    () => api.fetchUserProfileBySendbirdId(userId),
    {
      enabled: !isGroup,
    }
  );
  const recipientId = userProfileData?.data?.id;

  return (
    <div className="h-20 shadow flex items-center px-2">
      <button
        className="hover:text-easter focus:outline-none md:hidden mr-4"
        onClick={showChatList}
        type="button"
      >
        <ChevronLeft />
        Back
      </button>
      {isGroup ? (
        <>
          <div className="h-12 w-12 rounded-full object-cover mr-4 bg-teal-100 flex justify-center items-center shadow">
            <Picture
              src={gfxGroupChat}
              className="h-8 w-8 object-cover"
              fallbackImage={gfxGroupChat.default}
            />
          </div>
          <div className="flex flex-col">
            <div>{channel?.name}</div>
            <div className="text-dusty text-xs">{`${channel.memberCount} Members`}</div>
          </div>
          <span
            className={classnames('mr-2 h-2 w-2 rounded-full ml-auto', {
              'bg-brilliantGreen': isOnline,
              'bg-dusty': !isOnline,
            })}
          />
          <div className="mr-5">
            {isOnline ? `${groupActiveCount} Online` : 'Offline'}
          </div>
          <Options
            list={[
              {
                label: 'Leave Group',
                className: 'text-valencia',
                onClick: () => leaveChannel(channel.channel),
              },
            ]}
          />
        </>
      ) : (
        <div
          className="flex items-center focus:outline-none cursor-pointer"
          onClick={() => {
            navigate(`/network/${recipientId}`);
          }}
          role="presentation"
        >
          <Picture
            src={plainProfileUrl || gfxAvatar}
            className="h-12 w-12 rounded-full object-cover mr-4"
            fallbackImage={gfxAvatar.default}
          />
          {nickname}
          <span
            className={classnames('ml-8 mr-2 h-2 w-2 rounded-full', {
              'bg-brilliantGreen': isOnline,
              'bg-dusty': !isOnline,
            })}
          />
          {startCase(connectionStatus)}
        </div>
      )}
    </div>
  );
};

ChannelInfo.propTypes = {
  navigate: PropTypes.func.isRequired,
  channel: PropTypes.object.isRequired,
  showChatList: PropTypes.func.isRequired,
  leaveChannel: PropTypes.func.isRequired,
};

const ChatBoxContainer = styled.div`
  max-height: calc(100vh - 6rem);
`;

const ChatBox = (props) => {
  const { channel, isOpen, recipient, selectChat } = props;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [isGroup, setIsGroup] = useState(false);
  const navigate = useNavigate();

  const currentUser = useSelector(Selectors.getUser);
  const { isFreeTier } = useContext(AuthContext);
  const messages =
    useSelector(Selectors.getMessages(channel?.channel?.url)) || [];

  useEffect(() => {
    if (recipient) {
      setOpenModal(true);
    }
  }, [recipient]);

  const EmptyMessagesList = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center">
        <Picture className="h-16" src={gfxEmptyChat} />
        <p className="text-lg font-semibold mt-8 mb-2">
          It’s nice to chat with someone
        </p>
        <p className="text-sm w-4/5 font-light text-boulder mb-8">
          Let’s start a conversation with someone
        </p>
        <div className="flex flex-col justify-between items-center w-64">
          <Button
            className="w-full"
            label="Direct Message"
            onClick={() => {
              setIsGroup(false);
              setOpenModal(true);
            }}
          />
          <p className="text-dusty my-6">Or</p>
          {isFreeTier ? (
            <Tooltip
              interactive
              title={
                <div className="text-xs">
                  {`You're currently on ${currentUser.member_tier.name} tier with limited access. Start chat groups with members by`}
                  <Link
                    to="/subscription"
                    className="font-semibold text-easter"
                  >
                    {` upgrading here`}
                  </Link>
                </div>
              }
              placement="top"
              arrow
            >
              <div className="w-full">
                <Button
                  className="w-full"
                  label="Create Chat Group"
                  outline
                  disabled
                />
              </div>
            </Tooltip>
          ) : (
            <>
              <Button
                className="w-full"
                label="Create Chat Group"
                outline
                onClick={() => {
                  setOpenModal(true);
                  setIsGroup(true);
                }}
              />
              ,
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <ChatBoxContainer
      className={classnames(
        'h-screen flex-1 transition-all ease-in-out duration-400 relative',
        { '-mr-100vw md:mr-0': !isOpen }
      )}
    >
      {!channel ? (
        <EmptyMessagesList />
      ) : (
        <div className="flex flex-col h-full">
          <ChannelInfo {...props} navigate={navigate} />
          <MessagesList
            messages={messages}
            inviter={channel.isGroup ? channel.inviter : null}
            recipients={channel.recipients}
            groupName={channel.name}
          />
          <ChatInput
            hasAttachment
            onSubmit={(message, callback) =>
              dispatch(
                Actions.sendMessage(
                  channel.channel,
                  message,
                  !channel.isGroup && channel.recipients.userId,
                  { success: callback }
                )
              )
            }
          />
        </div>
      )}
      <Modal
        isOpen={openModal}
        content={(setOpen) => (
          <div className="mx-auto rounded">
            <CreateForm
              recipient={recipient}
              selectChat={selectChat}
              isGroup={isGroup}
              closeModal={() => {
                setOpen(false);
                setOpenModal(false);
              }}
            />
          </div>
        )}
        onDismiss={() => setOpenModal(false)}
      >
        <div />
      </Modal>
    </ChatBoxContainer>
  );
};

ChatBox.propTypes = {
  channel: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  showChatList: PropTypes.func.isRequired,
  openChatModal: PropTypes.func.isRequired,
  selectChat: PropTypes.func.isRequired,
  recipient: PropTypes.string,
};

ChatBox.defaultProps = {
  channel: null,
  recipient: null,
};

export default ChatBox;

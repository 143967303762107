const NAME = 'USER/FETCH_USER';

export const FETCH_USER_REQUEST = `${NAME}_REQUEST`;
export const FETCH_USER_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_USER_FAIL = `${NAME}_FAIL`;

export const fetchUser = (id, callbacks) => ({
  type: FETCH_USER_REQUEST,
  id,
  callbacks,
});

export const fetchUserSuccess = (data) => ({
  type: FETCH_USER_SUCCESS,
  data,
});

export const fetchUserFail = (error) => ({
  type: FETCH_USER_FAIL,
  error,
});

import { createContext, useContext } from 'react';

export const SidebarContext = createContext(null);
export const AuthContext = createContext({
  authenticated: false,
  hasAccess: false,
  isPro: false,
  isSubscribed: false,
  isFreeTier: false,
  isLite: false,
  linkedInTag: null,
});
export const ProfileContext = createContext({
  isView: false,
  readOnly: false,
});

export const useAuth = () => useContext(AuthContext);

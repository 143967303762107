import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* resetPassword({ data, callbacks }) {
  try {
    const response = yield call(api.resetPassword, data);
    yield put(Actions.resetPasswordSuccess(response.data));
    if (typeof callbacks.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.resetPasswordFail(error));
    if (typeof callbacks.failure === 'function') {
      yield call(callbacks.failure, error?.response);
    }
  }
}

function* watchresetPassword() {
  yield takeLatest(Actions.RESET_PASSWORD_REQUEST, resetPassword);
}

export default function* reset() {
  yield all([fork(watchresetPassword)]);
}

const NAME = 'STRIPE/CANCEL_UPDATE_SUBSCRIPTION_';

export const CANCEL_UPDATE_SUBSCRIPTION_REQUEST = `${NAME}REQUEST`;
export const CANCEL_UPDATE_SUBSCRIPTION_SUCCESS = `${NAME}SUCCESS`;
export const CANCEL_UPDATE_SUBSCRIPTION_FAIL = `${NAME}FAIL`;

export const cancelUpdateSubscription = (callbacks) => ({
  type: CANCEL_UPDATE_SUBSCRIPTION_REQUEST,
  callbacks,
});

export const cancelUpdateSubscriptionSuccess = (data) => ({
  type: CANCEL_UPDATE_SUBSCRIPTION_SUCCESS,
  data,
});

export const cancelUpdateSubscriptionFail = (error) => ({
  type: CANCEL_UPDATE_SUBSCRIPTION_FAIL,
  error,
});

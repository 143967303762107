const NAME = 'EVENTS/FETCH_CREATED_LIST_';

export const FETCH_CREATED_LIST_REQUEST = `${NAME}REQUEST`;
export const FETCH_CREATED_LIST_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_CREATED_LIST_FAIL = `${NAME}FAIL`;

export const fetchCreatedEvents = () => ({
  type: FETCH_CREATED_LIST_REQUEST,
});

export const fetchCreatedEventsSuccess = (data) => ({
  type: FETCH_CREATED_LIST_SUCCESS,
  data,
});

export const fetchCreatedEventsFail = (error) => ({
  type: FETCH_CREATED_LIST_FAIL,
  error,
});

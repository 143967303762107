import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { gfxEmptyCreatedPerks } from 'assets';
import { Spinner } from 'components/UI/Loading';
import { Card, Picture, Button } from 'components/UI';
import CreatedPerkCard from './CreatedPerkCard';
import CreatedPerkTileCard from './CreatedPerkTileCard';

const CreatedPerks = ({ perks, isLoading }) => {
  const navigate = useNavigate();
  const navigateToForm = () => navigate('create');
  const isEmptyPerks = isEmpty(perks);
  const Content = () => {
    if (isEmptyPerks) {
      if (isLoading)
        return (
          <div className="flex my-20">
            <Spinner />
          </div>
        );

      return (
        <Card className="p-10 flex flex-no-wrap overflow-x-auto">
          <div className="m-auto text-center">
            <Picture src={gfxEmptyCreatedPerks} className="py-8 w-1/3 m-auto" />
            <h3 className="font-semibold font-lynstone">
              You have not created any perks
            </h3>
            <p className="text-dusty">Perks you created will appear here</p>
            <Button
              className="mt-5 max-w-80 mx-auto"
              label="CREATE PERK"
              onClick={navigateToForm}
            />
          </div>
        </Card>
      );
    }

    return (
      <>
        <div className="hidden sm:grid gap-8 mb-10">
          {perks.map((perk) => (
            <CreatedPerkCard perk={perk} key={perk.id} />
          ))}
        </div>
        <div className="grid sm:hidden gap-8 mb-10">
          {perks.map((perk) => (
            <CreatedPerkTileCard perk={perk} key={perk.id} />
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="mb-10">
      <div className="flex items-center justify-between mb-6">
        <div className="font-semibold text-lg font-chap">CREATED PERKS</div>
        {!isEmptyPerks && (
          <Button
            type="button"
            isPill
            className="px-2 sm:px-8"
            onClick={navigateToForm}
            label="CREATE PERK"
          />
        )}
      </div>
      <Content />
    </div>
  );
};

CreatedPerks.propTypes = {
  perks: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CreatedPerks;

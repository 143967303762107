import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* deletePerk({ id, callbacks }) {
  try {
    yield call(api.deletePerk, id);
    yield put(Actions.deletePerkSuccess(id));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.deletePerkFail(error));
    if (typeof callbacks?.fail === 'function') {
      yield call(callbacks.fail);
    }
  }
}

function* watchDeletePerk() {
  yield takeLatest(Actions.DELETE_PERK_REQUEST, deletePerk);
}

export default function* destroy() {
  yield all([fork(watchDeletePerk)]);
}

const NAME = 'SUBSCRIPTION/TOGGLE_REMINDER_';

export const TOGGLE_REMINDER_REQUEST = `${NAME}REQUEST`;
export const TOGGLE_REMINDER_SUCCESS = `${NAME}SUCCESS`;
export const TOGGLE_REMINDER_FAIL = `${NAME}FAIL`;

export const toggleReminder = () => ({
  type: TOGGLE_REMINDER_REQUEST,
});

export const toggleReminderSuccess = () => ({
  type: TOGGLE_REMINDER_SUCCESS,
});

export const toggleReminderFail = (error) => ({
  type: TOGGLE_REMINDER_FAIL,
  error,
});

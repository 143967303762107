import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import { leaveSbChannel } from 'utils/sendbird';

function* leaveChannel({ channel, callbacks }) {
  try {
    yield call(leaveSbChannel, channel);
    yield put(Actions.hideDialog());
    yield put(Actions.leaveChannelSuccess(channel.url));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure);
    }
    yield put(Actions.leaveChannelFail(error));
  }
}

function* watchLeaveChannel() {
  yield takeLatest(Actions.LEAVE_CHANNEL_REQUEST, leaveChannel);
}

export default function* leave() {
  yield all([fork(watchLeaveChannel)]);
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { nameFormat } from 'utils/format';
import { modalHeader, gfxAvatar, iconChat, iconChatPassive } from 'assets';
import { Card, Picture } from 'components/UI';
import { latestWorkExp } from 'utils/helper';
import { BackgroundSection } from '../LandingPage/common';

const RsvpList = ({ eventId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [focused, setFocused] = useState(null);
  const users = useSelector(Selectors.getAttendances(eventId));
  const currentUser = useSelector(Selectors.getUser);
  const isSingleRsvp = users.length === 1;

  const msgUser = (sendbirdId) =>
    dispatch(
      Actions.listChannels(() => navigate('/chat', { state: { sendbirdId } }))
    );

  useEffect(() => {
    if (eventId) {
      dispatch(Actions.fetchAttendances(eventId));
    }
  }, [dispatch, eventId]);

  return (
    <div className="w-full px-2 md:p-0">
      <Card>
        <BackgroundSection
          backgroundImage={`url(${modalHeader.default})`}
          className={classnames(
            'relative bg-no-repeat bg-cover bg-center h-18 rounded-t-lg'
          )}
        >
          <h3 className="p-3 pt-4 md:p-4 md:pt-5 text-white font-hairline">
            MEMBERS ATTENDING
          </h3>
        </BackgroundSection>
        <div className="p-4 ml-2 md:ml-4 pb-0 text-dusty">
          {`${users.length} ${
            isSingleRsvp ? 'member is' : 'members are'
          } attending`}
        </div>
        <div className="flex-1 p-4 pt-1 overflow-y-auto flex flex-col max-h-140">
          {users.map(
            (
              {
                image_url: img,
                id,
                first_name: firstName,
                last_name: lastName,
                country,
                users_experiences: workExperience,
                sendbird_id: sendbirdId,
              },
              idx
            ) => (
              <div
                className="my-2 hover:shadow-card rounded-lg w-full py-2 items-center flex"
                key={id}
                onMouseEnter={() => setFocused(idx)}
                onMouseLeave={() => setFocused(null)}
              >
                <Link
                  to={`/network/${id}`}
                  className="w-full flex flex-row items-center"
                >
                  <div className="rounded-full h-10 w-10 md:h-12 md:w-12 ml-1 md:ml-3 shadow overflow-hidden mr-3 md:mr-5 flex-shrink-0">
                    <Picture
                      src={img || gfxAvatar}
                      className="object-cover w-10 md:w-12 h-full"
                    />
                  </div>
                  <div className="flex flex-col text-ebony">
                    <div className="font-bold md:text-md">
                      {nameFormat({ firstName, lastName })}
                    </div>
                    <div className="text-sm">
                      {`${latestWorkExp(workExperience).company} • ${
                        country?.name
                      }`}
                    </div>
                  </div>
                </Link>
                {sendbirdId && sendbirdId !== currentUser.sendbird_id && (
                  <div
                    className="rounded-full mr-2 cursor-pointer overflow-hidden flex justify-center items-center"
                    onClick={() => msgUser(sendbirdId)}
                    role="presentation"
                  >
                    <Picture
                      src={focused === idx ? iconChat : iconChatPassive}
                      className="w-8 sm:w-10 sm:mr-4 h-full"
                    />
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </Card>
    </div>
  );
};

RsvpList.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default RsvpList;

import { all, fork } from 'redux-saga/effects';
import list from './list';
import info from './info';
import redeem from './redeem';
import redeemed from './redeemed';
import create from './create';
import created from './created';
import destroy from './destroy';
import update from './update';

export default function* perks() {
  yield all([fork(list)]);
  yield all([fork(info)]);
  yield all([fork(redeem)]);
  yield all([fork(redeemed)]);
  yield all([fork(create)]);
  yield all([fork(created)]);
  yield all([fork(destroy)]);
  yield all([fork(update)]);
}

import React, { useState } from 'react';
import useQuery from 'hooks/useQuery';
import classnames from 'classnames';
import { Accordion } from 'components/UI';
import FeaturedLayout from '../common/FeaturedLayout';
import { QUESTIONS } from './content';

const CATEGORY = Object.freeze(['General', 'Consultation', 'Video Content']);

const Terms = () => {
  const params = useQuery();
  const [category, setCategory] = useState(params.type || 'General');

  const CategoryList = () => (
    <div className="w-full lg:w-1/4">
      <p className="text-dusty text-center lg:text-left">Category</p>
      <div className="flex lg:flex-col flex-wrap lg:flex-no-wrap justify-evenly lg:justify-start mb-8 lg:mb-0">
        {CATEGORY.map((item) => (
          <div
            className={classnames(
              'cursor-pointer text-md font-semibold mt-6 mr-4',
              { 'text-easter': item === category }
            )}
            onClick={() => setCategory(item)}
            role="presentation"
            key={item}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );

  const currentContent = QUESTIONS.find(({ parent }) => parent === category);

  return (
    <>
      <FeaturedLayout>
        <div className="flex flex-col text-center text-white relative m-auto">
          <h1 className="text-3xl font-semibold">Terms of Use</h1>
        </div>
      </FeaturedLayout>
      <div className="min-h-screen mx-auto px-4 container flex flex-col lg:flex-row mt-12">
        <CategoryList />
        <div className="flex-1">
          {currentContent.description && (
            <p className="mb-6 ml-2 font-semibold">
              {currentContent.description}
            </p>
          )}
          <Accordion
            content={currentContent.children}
            parentKey="question"
            childKey="answer"
          />
          {currentContent.date && (
            <p className="my-5 ml-2">{currentContent.date}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Terms;

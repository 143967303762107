const NAME = 'USER/FETCH_PROFILE';

export const FETCH_PROFILE_REQUEST = `${NAME}_REQUEST`;
export const FETCH_PROFILE_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_PROFILE_FAIL = `${NAME}_FAIL`;

export const fetchProfile = (callbacks) => ({
  type: FETCH_PROFILE_REQUEST,
  callbacks,
});

export const fetchProfileSuccess = (data) => ({
  type: FETCH_PROFILE_SUCCESS,
  data,
});

export const fetchProfileFail = (error) => ({
  type: FETCH_PROFILE_FAIL,
  error,
});

import { takeLatest, all, fork, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* signOut() {
  yield call(api.signOut);
}

function* watchSignOut() {
  yield takeLatest(Actions.SIGN_OUT, signOut);
}

export default function* auth() {
  yield all([fork(watchSignOut)]);
}

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from 'classnames';
import { gfxAvatar } from 'assets';
import { Card, Button, Picture } from 'components/UI';
import { nameFormat } from 'utils/format';
import { useNavigate } from 'react-router-dom';
import { latestWorkExp } from 'utils/helper';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';
import Flag from 'react-country-flag';

const UserCard = ({
  id: userId,
  first_name: firstName,
  last_name: lastName,
  member_tier: { name: tierName, is_free_tier: isFreeTier },
  city: { name: cityName },
  country: { code, name: countryName },
  image_url: avatarUrl,
  users_experiences: workExperiences,
}) => {
  const navigate = useNavigate();
  const currentWorkExp = latestWorkExp(workExperiences);
  const companyUrl = () => {
    const url = currentWorkExp.company_url;
    if (url) return url.slice(0, 4) === 'http' ? url : `https://${url}`;
  };

  return (
    <Card className="overflow-hidden rounded-lg relative h-104">
      <div className="text-center px-2 pt-4 h-9/12">
        <div className="flex items-center justify-between px-3">
          <Tooltip title={countryName} placement="top" arrow>
            <div className="flex shadow">
              <Flag
                style={{
                  fontSize: '1.5rem',
                  marginTop: '-0.2rem',
                  marginBottom: '-0.2rem',
                }}
                svg
                countryCode={code}
              />
            </div>
          </Tooltip>
        </div>
        <Picture
          src={avatarUrl || gfxAvatar}
          className="w-32 h-32 rounded-full mx-auto object-cover"
          fallbackImage={gfxAvatar.default}
        />
        <div className="font-bold text-lg mt-6 truncate">
          {nameFormat({ firstName, lastName })}
        </div>
        <div className="flex flex-col items-center mt-3">
          <a
            href={companyUrl()}
            className="text-easter font-semibold truncate w-4/5"
            target="_blank"
            rel="noreferrer"
          >
            {currentWorkExp.company}
          </a>
          <div className="truncate w-4/5">{currentWorkExp.position}</div>
          <div className="text-boulder truncate w-4/5">{cityName}</div>
        </div>
        <div>
          {/* TODO: Add Link to button instead of using navigate */}
          <Button
            label="View Profile"
            className="mx-auto text-sm my-4 self-end"
            outline
            onClick={() => {
              navigate(`/network/${userId}`);
            }}
          />
        </div>
      </div>
      <div
        className={classnames(
          'flex flex-col h-10 text-white w-full absolute bottom-0',
          {
            'bg-violet-light': tierName === ALL_ACCESS,
            'bg-easter': tierName === PREMIUM,
          }
        )}
      >
        <div className="h-1" />
        <div className="border-t-2 border-white w-full h-full uppercase flex justify-center items-center font-light tracking-wider">
          {!isFreeTier && tierName}
        </div>
      </div>
    </Card>
  );
};

UserCard.propTypes = {
  id: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  member_tier: PropTypes.object.isRequired,
  city: PropTypes.object.isRequired,
  country: PropTypes.object.isRequired,
  users_experiences: PropTypes.array.isRequired,
  image_url: PropTypes.string,
};

UserCard.defaultProps = {
  image_url: null,
};

export default UserCard;

import { combineReducers } from 'redux';
import list from './list';
import info from './info';
import favouriteList from './favouriteList';
import favourite from './favourite';
import unfavourite from './unfavourite';
import categories from './categories';

export default combineReducers({
  list,
  info,
  favouriteList,
  favourite,
  unfavourite,
  categories,
});

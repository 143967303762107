import * as fetch from './fetch';
import * as create from './create';
import * as update from './update';
import * as approve from './approve';
import * as decline from './decline';
import * as bookings from './bookings';

export default {
  ...fetch,
  ...create,
  ...update,
  ...approve,
  ...decline,
  ...bookings,
};

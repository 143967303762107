import Actions from 'actions';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import api from 'api';

function* downgradeSubscription({ data, callbacks }) {
  try {
    yield call(api.downgradeSubscription, data);
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
    yield put(Actions.downgradeSubscriptionSuccess());
  } catch (error) {
    yield put(Actions.downgradeSubscriptionFail(error));
    if (callbacks?.failure) {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchDowngradeSubscription() {
  yield takeLatest(
    Actions.DOWNGRADE_SUBSCRIPTION_REQUEST,
    downgradeSubscription
  );
}

export default function* downgrade() {
  yield all([fork(watchDowngradeSubscription)]);
}

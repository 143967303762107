import { store } from 'react-notifications-component';

export default function notify(
  message,
  title,
  type = 'success',
  duration = 3000
) {
  return store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      showIcon: true,
      duration,
      onScreen: true,
    },
  });
}

import { combineReducers } from 'redux';
import list from './list';
import optionsList from './optionsList';
import citiesOfCountry from './citiesOfCountry';

export default combineReducers({
  list,
  optionsList,
  citiesOfCountry,
});

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* validateEmail({ email, callbacks }) {
  try {
    yield call(api.validateEmail, { email });
    yield put(Actions.validateEmailSuccess());
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.validateEmailFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error?.response?.data?.error);
    }
  }
}

function* watchValidateEmail() {
  yield takeLatest(Actions.VALIDATE_EMAIL_REQUEST, validateEmail);
}

export default function* emailValidation() {
  yield all([fork(watchValidateEmail)]);
}

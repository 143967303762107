const NAME = 'AUTH/UPDATE_PASSWORD_';

export const UPDATE_PASSWORD_REQUEST = `${NAME}REQUEST`;
export const UPDATE_PASSWORD_SUCCESS = `${NAME}SUCCESS`;
export const UPDATE_PASSWORD_FAIL = `${NAME}FAIL`;

export const updatePassword = (data, callbacks = {}) => ({
  type: UPDATE_PASSWORD_REQUEST,
  data,
  callbacks,
});

export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
});

export const updatePasswordFail = (error) => ({
  type: UPDATE_PASSWORD_FAIL,
  error,
});

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import { Breadcrumbs, PageMeta, Loading } from 'components/UI';
import { gfxVideoIcon } from 'assets';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { displaySubscriptionDateFormat } from 'utils/datetime';
import ReactHtmlParser from 'react-html-parser';
import Player from './Player';
import VideoCard from './VideoCard';
import Speakers from './Speakers';
import Tags from './Tags';

const View = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: paramId } = useParams();
  const {
    id,
    name,
    views,
    category,
    tags,
    speakers,
    description,
    img_url: img,
    published_at: published,
  } = useSelector(Selectors.getVideo);
  const videos = useSelector(Selectors.getVideos);
  const isNewId = paramId !== id;

  useEffect(() => {
    if (isNewId) {
      dispatch(
        Actions.fetchVideo(paramId, {
          failure: () => navigate('/members/videos'),
        })
      );
    }
  }, [dispatch, isNewId, paramId, navigate]);

  useEffect(() => {
    if (!videos.length) dispatch(Actions.fetchVideos());
  }, [dispatch, videos.length]);

  return (
    <div className="h-screen">
      <PageMeta title={name} ogImg={img} />
      <Breadcrumbs
        trails={[
          { label: 'Videos', path: '/members/videos', icon: 'videos' },
          name,
        ]}
      />
      {id ? (
        <>
          <Player id={paramId} />
          <div className="container mx-auto py-8 px-6">
            <div className="flex text-easter uppercase items-center">
              <img
                src={gfxVideoIcon.default}
                alt="video_icon"
                className="mr-3 h-8"
              />
              {category}
            </div>
            <p className="my-3 text-lg sm:text-xl font-semibold">{name}</p>
            <div className="flex text-dusty mb-6">
              <span className="flex border-r border-dusty pr-4 items-center">
                <VisibilityIcon className="mr-2 text-silver" />
                {`${views.toLocaleString()} views`}
              </span>
              <span className="flex items-center pl-4">
                {`${displaySubscriptionDateFormat(published)}`}
              </span>
            </div>
            <div className="sm:text-md text-boulder">
              {ReactHtmlParser(description)}
            </div>
            {!!speakers?.length && <Speakers data={speakers} />}
            {!!tags?.length && <Tags data={tags} />}
          </div>
          <div className="px-5 pb-8 mt-6">
            <div className="w-full border-t border-alto" />
          </div>
          <div className="px-6 container mx-auto flex flex-col">
            <h3 className="mb-4 font-semibold">OTHER VIDEOS</h3>
            {videos.map((v) => {
              if (v.id === id) return null;

              return <VideoCard video={v} key={v.id} row />;
            })}
          </div>
        </>
      ) : (
        <Loading
          className="bg-white h-full flex justify-center items-center"
          opaque={false}
        />
      )}
    </div>
  );
};

export default View;

const NAME = 'CONSULTATION_SERVICE/DECLINE_BOOKING_';

export const DECLINE_BOOKING_REQUEST = `${NAME}REQUEST`;
export const DECLINE_BOOKING_SUCCESS = `${NAME}SUCCESS`;
export const DECLINE_BOOKING_FAIL = `${NAME}FAIL`;

export const declineBooking = (id, data, callbacks) => ({
  type: DECLINE_BOOKING_REQUEST,
  id,
  data,
  callbacks,
});

export const declineBookingSuccess = (data) => ({
  type: DECLINE_BOOKING_SUCCESS,
  data,
});

export const declineBookingFail = () => ({
  type: DECLINE_BOOKING_FAIL,
});

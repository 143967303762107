// Logo
export const pngLogoWhite = require('../img/landing/logo_giglifepro_white.png');
export const logoPreview = require('../img/ui/glp_preview.png');
export const glpBlue = require('../img/landing/glp_blue.png');

// Icons
export const iconCheckmark = require('../img/landing/icon_checkmark.png');
export const iconCheckmarkWhite = require('../img/landing/icon_checkmark_white.png');
export const iconLinkCheckmark = require('../img/landing/icon_link_checkmark.png');
export const iconFB = require('../img/landing/icon_facebook_passive.png');
export const iconYT = require('../img/landing/icon_youtube_passive.png');
export const iconFBActive = require('../img/landing/icon_facebook_active.png');
export const iconFBLink = require('../img/landing/icon_facebook_link.png');
export const iconIG = require('../img/landing/icon_instagram_passive.png');
export const iconIGActive = require('../img/landing/icon_instagram_active.png');
export const iconIGLink = require('../img/landing/icon_instagram_link.png');
export const iconLinkedIn = require('../img/landing/icon_linkedin_passive.png');
export const iconLinkedInActive = require('../img/landing/icon_linkedin_active.png');
export const iconLinkedInLink = require('../img/landing/icon_linked_in_link.png');
export const iconWebsiteLink = require('../img/landing/icon_website_link.png');
export const iconRightArrow = require('../img/landing/icon_right_arrow.png');
export const iconLeftArrow = require('../img/landing/icon_left_arrow.png');
export const iconNext = require('../img/landing/icon_next.png');
export const iconBack = require('../img/landing/icon_back.png');
export const iconSelectDropDown = require('../img/ui/dropdown_passive.png');
export const iconSelectDropDownActive = require('../img/ui/dropdown_active.png');
export const iconClock = require('../img/landing/icon_clock.png');
export const iconClockTeal = require('../img/landing/icon_clock_teal.png');
export const iconDate = require('../img/landing/icon_date.png');
export const iconLocation = require('../img/landing/icon_location.png');
export const iconStar = require('../img/landing/icon_star_passive.png');
export const iconStarActive = require('../img/landing/icon_star_active.png');
export const iconMain = require('../img/landing/icon_main.svg');
export const iconRegistrationSuccess = require('../img/landing/icon_registration_success.png');
export const iconPro = require('../img/landing/icon_pro.png');
export const iconLite = require('../img/landing/icon_lite.png');
export const iconPromo = require('../img/ui/promo_code.png');
export const iconTierSelected = require('../img/ui/selected_icon.png');
export const iconCardFallback = require('../img/card_networks/card_fallback.png');
export const iconSpotify = require('../img/ui/spotify_icon.png');
export const iconSpotifyLink = require('../img/ui/spotify_field_icon.png');
export const iconYoutube = require('../img/ui/youtube_icon.png');
export const iconYoutubeLink = require('../img/ui/youtube_field_icon.png');
export const iconJobFallback = require('../img/ui/job_fallback_icon.png');
export const iconCaution = require('../img/landing/icon_caution.png');
export const iconSuccess = require('../img/landing/icon_success.png');
export const iconLock = require('../img/landing/icon_lock.png');
export const iconAppleCalendar = require('../img/ui/apple_calendar.png');
export const iconGoogleCalendar = require('../img/ui/google_calendar.png');
export const iconOutlookCalendar = require('../img/ui/outlook_calendar.png');
export const iconDownload = require('../img/ui/download.png');
export const iconChat = require('../img/ui/chat_icon.svg');
export const iconChatPassive = require('../img/ui/chat_passive_icon.svg');
export const iconAddEvent = require('../img/ui/add_event.png');
export const iconAddedEvent = require('../img/ui/added_event.png');
export const iconAddEventGrey = require('../img/ui/add_event_grey.png');
export const iconEllipsis = require('../img/landing/ellipsis.svg');
export const iconAddToCalendarActive = require('../img/ui/add_to_calendar_active.png');
export const iconDeclineCalendar = require('../img/ui/decline_calendar.png');
export const iconShareSocial = require('../img/ui/share_social.png');
export const iconAcceptTick = require('../img/ui/accept_tick.png');
export const iconCopyLink = require('../img/ui/copy_link_icon.png');
export const plusOneIcon = require('../img/ui/plus-one-icon-graphic.png');
export const myProfileIcon = require('../img/ui/my-profile-icon.png');
export const accountSettingsIcon = require('../img/ui/account-settings-icon.png');
export const logOutIcon = require('../img/ui/log-out-icon.png');
export const iconRequestReschedule = require('../img/ui/reschedule-icon.png');
export const blockedUsersIcon = require('../img/ui/blocked-users-icon.png');
export const blockUserIcon = require('../img/ui/block-user-icon.png');
export const reportEventIcon = require('../img/ui/report-event-icon.png');
export const referralIcon = require('../img/ui/referral-icon.png');
export const rewardsHistoryIcon = require('../img/ui/rewards-history-icon.png');
export const allAccessReferredIcon = require('../img/ui/all-access-referred.png');
export const premiumReferredIcon = require('../img/ui/premium-referred.png');
export const othersSuccessfullRewardIcon = require('../img/ui/others-successfull.png');
export const allAccessRedeemed = require('../img/ui/all-access-redeemed.png');
export const premiumRedeemed = require('../img/ui/premium-redeemed.png');
export const redeemedIcon = require('../img/ui/redeemed-icon.png');
export const rewardsRedeemed = require('../img/ui/rewards-redeemed.png');
export const earnPointsIcon = require('../img/ui/earn-points-icon.png');
export const joinedEventRewardIcon = require('../img/ui/joined-event-reward.png');
export const loginStreakRewardIcon = require('../img/ui/signed-in-7-days-reward.png');
export const iconVenue = require('../img/ui/venue_icon.png');
export const iconAppStore = require('../img/landing/app_store.png');
export const iconPlayStore = require('../img/landing/google_play.png');
// Image
export const bgHero = require('../img/landing/bg_hero.png');
export const bgAboutUs = require('../img/landing/about_us.png');
export const bgCommunity = require('../img/landing/bg_community.png');
export const bgApply = require('../img/landing/bg_apply.png');
export const bgLocations = require('../img/landing/bg_locations.png');
export const bgVisa = require('../img/landing/bg_visa.png');
export const bgAuth = require('../img/landing/bg_auth.png');
export const bgAuthContainer = require('../img/landing/bg_auth_container.png');
export const bgMembership = require('../img/landing/bg_membership_active.svg');
export const bgMembershipPassive = require('../img/landing/bg_membership_passive.svg');
export const bgGroup = require('../img/landing/bg_group.png');
export const bgKeyMedia = require('../img/landing/bg_key_media.jpeg');
export const bgMarketing = require('../img/landing/bg_marketing.png');
export const bgConference = require('../img/landing/bg_conference.png');
export const bgFestival = require('../img/landing/bg_festival.png');
export const bgAbout = require('../img/landing/bg_about.jpg');
export const bgConsultation = require('../img/landing/bg_consultation.png');
export const bgLabels = require('../img/landing/bg_labels.png');
export const bgDistribution = require('../img/landing/bg_distribution.png');
export const bgStudios = require('../img/landing/bg_studios.png');
export const bgMembershipBanner = require('../img/landing/membership-banner-img.png');
export const bgMembers = require('../img/landing/members-bg-img.png');
// These three images need to be changed later
export const bgDigitalPlatforms = require('../img/landing/bg_digital_platforms.jpeg');
export const bgSocialMedia = require('../img/landing/bg_social_media.jpeg');
export const bgDspPlaylist = require('../img/landing/bg_music_promoters.png');
export const bgMusicPromoters = require('../img/landing/bg_dsp_playlist.png');
export const bgPublishing = require('../img/landing/bg_publishing.png');
export const bgRecordStores = require('../img/landing/bg_record_stores.png');
export const bgSyncCompanies = require('../img/landing/bg_sync_companies.png');
export const bgProfile = require('../img/ui/profile_header.png');
export const bgMemberCardPro = require('../img/ui/membership_card_pro.png');
export const bgMemberCardLite = require('../img/ui/membership_card_lite.png');
export const bgMemberCardFree = require('../img/ui/membership_card_free.png');
export const imgRewardsHero = require('../img/landing/rewards-hero-img.png');
export const bgRewards = require('../img/landing/rewards-artwork.png');
export const myPointsHero = require('../img/landing/my-points-hero.png');
export const allAccessRewardsCard = require('../img/ui/all-access-rewards-card.png');
export const premiumRewardsCard = require('../img/ui/premium-rewards-card.png');

// Past partnerships landing page slider
export const partnership1 = require('../img/landing/partnership1.png');
export const partnership2 = require('../img/landing/partnership2.png');
export const partnership3 = require('../img/landing/partnership3.png');
export const partnership4 = require('../img/landing/partnership4.png');
export const partnership5 = require('../img/landing/partnership5.png');
export const partnership6 = require('../img/landing/partnership6.png');
export const partnership7 = require('../img/landing/partnership7.png');
export const partnership8 = require('../img/landing/partnership8.png');

// Images
export const imgTableBenefits = require('../img/landing/img_table_benefits.png');
export const imgAboutPriya = require('../img/landing/img_priya.png');
export const imgAboutSarah = require('../img/landing/img_sarah.png');
export const imgReviewAyudita = require('../img/landing/img_ayudita.jpeg');
export const imgReviewJeff = require('../img/landing/img_jeff.jpg');
export const imgReviewStephen = require('../img/landing/img_stephen.jpg');
export const imgPlaceholder = require('../img/landing/img_placeholder.png');
export const imgAboutHien = require('../img/landing/img_hien.jpg');
export const imgAboutEzra = require('../img/landing/img_ezra.jpeg');
export const imgAboutChristine = require('../img/landing/img_christine.jpg');
export const imgAboutShaf = require('../img/landing/img_shaf.jpg');
export const imgAboutTyler = require('../img/landing/img_tyler.jpg');
export const imgAboutAnn = require('../img/landing/img_ann.jpg');
export const imgAboutAhikaa = require('../img/landing/img_ahikaa.png');
export const imgAboutEd = require('../img/landing/img_ed.jpg');
export const imgAboutMai = require('../img/landing/img_maivo.jpg');
export const imgReviewJustin = require('../img/landing/img_justin.jpg');
export const imgReviewTej = require('../img/landing/img_tej.jpg');
export const imgReviewNjTaylor = require('../img/landing/img_njtaylor.jpg');
export const imgReviewClaire = require('../img/landing/img_claire.png');
export const imgReviewSarun = require('../img/landing/img_sarun.png');

//
export const favouriteArticlesEmpty = require('../img/landing/favourite_articles_empty.png');
export const createdEventsEmpty = require('../img/landing/events_empty.png');
export const joinedEventsEmpty = require('../img/landing/joined_events_empty.png');
export const yourEvents = require('../img/landing/your_events.png');
export const upcomingEvents = require('../img/landing/upcoming_events.png');
export const newMembers = require('../img/landing/new_members.png');
export const modalHeader = require('../img/landing/modal_header.png');

// Graphic
export const gfxApac = require('../img/landing/gfx_apac_insights.png');
export const gfxEvents = require('../img/landing/gfx_events_across_apac.png');
export const gfxMixers = require('../img/landing/gfx_industry_mixers.png');
export const gfxPerks = require('../img/landing/gfx_industry_perks.png');
export const gfxFeatures = require('../img/landing/gfx_features_at_a_glance.png');
export const gfxSubscription = require('../img/ui/subscription_border.png');
export const gfxViewSubscription = require('../img/ui/view_subscription_border.png');
export const gfxAvatar = require('../img/ui/glp_avatar.png');
export const gfxDirectMsg = require('../img/ui/direct_message.png');
export const gfxEmptyChat = require('../img/ui/empty_chat.png');
export const gfxGroupChat = require('../img/ui/group_chat.png');
export const gfxBookingSent = require('../img/ui/booking_sent.png');
export const gfxVideosEmpty = require('../img/ui/videos_empty.png');
export const gfxVideoIcon = require('../img/ui/video_icon.png');
export const gfxConsultationApply = require('../img/ui/consultation_apply.png');
export const gfxConsultationApproval = require('../img/ui/consultation_approval.png');
export const gfxConsultationFollowUp = require('../img/ui/consultation_follow_up.png');
export const gfxConsultationZoom = require('../img/ui/consultation_zoom.png');
export const gfxNewsletter = require('../img/landing/gfx_newsletter.png');
export const gfxNotification = require('../img/ui/subscription_notification.png');
export const gfxEmptyPerks = require('../img/ui/empty_perks.png');
export const gfxEmptyCreatedPerks = require('../img/ui/empty_created_perks.png');
export const gfxPassword = require('../img/ui/password.png');
export const gfxConsultationInfo = require('../img/ui/consultation_info.png');
export const gfxNewsletterPlane = require('../img/ui/newsletter-plane.png');

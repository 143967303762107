import Actions from 'actions';

const initialState = Object.freeze({});

function forgotPassword(state = initialState, action) {
  switch (action.type) {
    case Actions.FORGOT_PASSWORD_REQUEST:
      return initialState;
    case Actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...initialState,
        data: action.data,
      };
    case Actions.FORGOT_PASSWORD_FAIL:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
}

export default forgotPassword;

import { combineReducers } from 'redux';
import getTier from './getTier';
import getTiers from './getTiers';
import getTypes from './getTypes';

export default combineReducers({
  getTier,
  getTiers,
  getTypes,
});

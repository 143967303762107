/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { SocialMediaLinkInput } from 'components/Form';
import { Picture } from 'components/UI';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import {
  iconSpotify,
  iconSpotifyLink,
  iconYoutube,
  iconYoutubeLink,
} from 'assets';
import { ProfileContext } from 'utils/context';

const SPOTIFY_CATEGORIES = [
  'track',
  'album',
  'artist',
  'playlist',
  'show',
  'episode',
];

const Playlist = () => {
  const { isView, readOnly } = useContext(ProfileContext);
  const {
    values: { spotify_playlists: spotify, youtube_playlists: youtube },
    handleSubmit,
  } = useFormikContext();
  const [viewProfile, toggleForm] = useState(isView);
  const spotifyLinks = spotify
    .map((link) => link.split('https://open.spotify.com/')[1])
    .filter((link) => link && SPOTIFY_CATEGORIES.includes(link.split('/')[0]));

  if (viewProfile)
    return (
      <div>
        <div className="flex justify-between">
          <h4 className="text-dusty text-lg font-semibold uppercase mb-4">
            My Playlist
          </h4>
          {!readOnly && (
            <Edit
              className="text-dusty cursor-pointer hover:text-easter"
              onClick={() => toggleForm(false)}
              role="presentation"
            />
          )}
        </div>
        <div>
          <h5 className="font-lynstone mb-4">
            <Picture
              src={iconSpotify}
              className="inline-block h-10 mr-2 shadow rounded-full"
              disableParentClassName
            />
            Spotify Playlist
          </h5>
          <div className="border-b pb-4 mb-4">
            {spotifyLinks.map((link) => (
              <div className="mb-2" key={link}>
                <iframe
                  title={link}
                  src={`https://open.spotify.com/embed/${link}`}
                  width="300"
                  height="380"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                />
              </div>
            ))}
          </div>
          <h5 className="font-lynstone mb-4">
            <Picture
              src={iconYoutube}
              className="inline-block h-10 mr-2 shadow rounded-full"
              disableParentClassName
            />
            YouTube Playlist
          </h5>
          <div className="mb-4">
            {youtube.map((item, index) => {
              const getYoutubeId = () => {
                if (item.startsWith('https://www.youtube.com/')) {
                  if (item.includes('list=')) {
                    const playlist = item.split('list=')[1];
                    return `videoseries?list=${playlist}`;
                  }
                  if (item.includes('watch?v='))
                    return item.split('watch?v=')[1];
                }
                return null;
              };
              const youtubeId = getYoutubeId();

              return (
                <div className="mb-2" key={index}>
                  {youtubeId && (
                    <iframe
                      title={item}
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${youtubeId}`}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className="sm:flex justify-between mb-8 sm:mb-0">
        <h5 className="text-lg mb-4">My Playlist</h5>
        {isView && (
          <div
            className="flex text-dusty cursor-pointer hover:text-easter justify-end"
            onClick={() => {
              toggleForm(true);
              handleSubmit();
            }}
            role="presentation"
          >
            <Save />
            <div className="ml-2">Save Changes</div>
          </div>
        )}
      </div>
      <div className="flex flex-col border-alto border-b border-dashed mb-8 pb-8">
        <h5 className="font-lynstone mb-8">Spotify Playlist</h5>
        <span className="text-xs text-silver break-words">
          eg:
          https://open.spotify.com/playlist/37i9dQZF1DWWMOmoXKqHTD?si=CwSkFLx7RXuO8pFjRqpKaA
        </span>
        <FieldArray
          name="spotify_playlists"
          render={({ push, remove }) => (
            <>
              {spotify.map((_, index) => (
                <div className="flex" key={index}>
                  <SocialMediaLinkInput
                    className="flex-1"
                    name={`spotify_playlists.${index}`}
                    placeholder="Paste your Spotify playlist URL here…"
                    img={iconSpotifyLink.default}
                  />
                  {spotify.length > 1 && (
                    <button
                      className="ml-4 text-valencia focus:outline-none hover:bg-pippin rounded-full h-full mt-4 p-1"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <RemoveCircle />
                    </button>
                  )}
                </div>
              ))}
              <button
                className="text-easter flex items-center focus:outline-none hover:bg-alto rounded-full"
                type="button"
                onClick={() => push('')}
              >
                <AddCircle className="mr-2" />
                Add More
              </button>
            </>
          )}
        />
      </div>
      <div className="flex flex-col">
        <h5 className="font-lynstone mb-8">YouTube Playlist</h5>
        <span className="text-xs text-silver break-words">
          eg:
          https://www.youtube.com/playlist?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG
        </span>
        <FieldArray
          name="youtube_playlists"
          render={({ push, remove }) => (
            <>
              {youtube.map((_, index) => (
                <div className="flex" key={index}>
                  <SocialMediaLinkInput
                    className="flex-1"
                    name={`youtube_playlists.${index}`}
                    placeholder="Paste your Youtube playlist URL here…"
                    img={iconYoutubeLink.default}
                  />
                  {youtube.length > 1 && (
                    <button
                      className="ml-4 text-valencia focus:outline-none hover:bg-pippin rounded-full h-full mt-4 p-1"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <RemoveCircle />
                    </button>
                  )}
                </div>
              ))}
              <button
                className="text-easter flex items-center focus:outline-none hover:bg-alto rounded-full"
                type="button"
                onClick={() => push('')}
              >
                <AddCircle className="mr-2" />
                Add More
              </button>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default Playlist;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Selectors from 'selectors';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';
import api from 'api';
import Actions from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import CONFIG from 'utils/config';

import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Spinner } from 'components/UI/Loading';
import { Card, ErrorContainer } from 'components/UI';
import CreditCard from '../Subscription/CreditCardDetails';

const CARD_ELEMENT_STYLES = {
  style: {
    base: {
      color: '#718196',
      fontWeight: 600,
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0afbf',
      },
    },
    invalid: {
      color: '#fc8181',
      iconColor: '#fc8181',
    },
  },
};

const BookingPaymentModal = ({
  eventId,
  eventName,
  eventPrice,
  handleConfirmBooking,
}) => {
  const dispatch = useDispatch();
  const stripePromise = loadStripe(CONFIG.STRIPE_PUB_KEY);
  const creditCard = useSelector(Selectors.creditCardDetails) || {};
  const [clientSecret, setClientSecret] = useState('');

  const { data: paymentIntentData } = useQuery('eventPaymentIntent', () =>
    api.eventPaymentIntent(eventId)
  );

  useEffect(() => {
    dispatch(Actions.fetchUserCreditCard());
    setClientSecret(paymentIntentData?.data?.secret);
  }, [dispatch, paymentIntentData?.data?.secret]);

  const hasPaymentMethod = !isEmpty(creditCard);
  const redirectStripePortal = () =>
    dispatch(
      Actions.createPortalSession({
        success: ({ url }) => {
          window.location = url;
          return null;
        },
        failure: () =>
          notify(
            'Change is unavailable at the moment. Please try again later.',
            null,
            'danger'
          ),
      })
    );

  const CreditCardForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const disabledSubmitState = processing || error || disabled;
    const currentUser = useSelector(Selectors.getUser);
    const setupIntentData = useSelector(Selectors.getSetupIntent);
    const disabledExistingCard = isEmpty(creditCard) || processing || error;
    const isBtnDisabled = hasPaymentMethod
      ? disabledExistingCard
      : disabledSubmitState;

    const payAndCreateBooking = async () => {
      setProcessing(true);
      if (!stripe || !elements) return setProcessing(false);

      try {
        const paymentIntentResult = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: creditCard.id,
          }
        );

        if (paymentIntentResult.error) {
          setError(paymentIntentResult.error?.message);
          setProcessing(false);
          return;
        }

        handleConfirmBooking(eventId, {
          payment_intent_id: paymentIntentResult?.paymentIntent?.id,
        });
      } catch (error) {
        setError(error);
        setProcessing(false);
      }
    };

    const handleCardOnChange = (event) => {
      setDisabled(event.empty);
      setError(event.error ? event.error.message : '');
    };

    const setupCardAndBook = async ({ secret }) => {
      try {
        const setupIntentResult = await stripe.confirmCardSetup(secret, {
          payment_method: { card: elements.getElement(CardElement) },
        });

        if (setupIntentResult.error) {
          setError(setupIntentResult.error?.message);
          setProcessing(false);
          return;
        }

        const paymentIntentResult = await stripe.confirmCardPayment(
          clientSecret,
          {
            // eslint-disable-next-line camelcase
            payment_method: setupIntentResult?.setupIntent?.payment_method,
          }
        );

        if (paymentIntentResult.error) {
          setError(paymentIntentResult.error?.message);
          setProcessing(false);
          return;
        }

        handleConfirmBooking(eventId, {
          payment_intent_id: paymentIntentResult?.paymentIntent?.id,
        });
      } catch (error) {
        setError(error);
        setProcessing(false);
      }
    };

    // If setup intent is empty, create a new setup intent to save the new card, and complete payment.
    const saveCreditCard = () => {
      setProcessing(true);
      if (!stripe || !elements) return setProcessing(false);
      if (!isEmpty(setupIntentData)) return setupCardAndBook(setupIntentData);
      dispatch(
        Actions.setupIntent(
          { email: email || currentUser?.email },
          setupCardAndBook
        )
      );
    };

    return (
      <>
        <div
          className={classnames(
            'flex flex-col items-center justify-center w-full rounded',
            { 'bg-squeeze': !hasPaymentMethod }
          )}
        >
          <p className="text-gull text-sm">Pay with credit/debit card</p>
          {!hasPaymentMethod && (
            <CardElement
              options={CARD_ELEMENT_STYLES}
              onChange={handleCardOnChange}
              className="w-full bg-white px-4 py-3 my-2 rounded sm:h-full"
            />
          )}
          {hasPaymentMethod && (
            <Card className="my-3 text-left">
              {isEmpty(creditCard) ? (
                <div className="flex justify-center items-center py-4">
                  <Spinner height="40" color="#47bad4" />
                </div>
              ) : (
                <CreditCard
                  isModal
                  data={creditCard}
                  redirectStripePortal={redirectStripePortal}
                />
              )}
            </Card>
          )}
          <button
            className={classnames(
              'flex justify-center w-full rounded bg-easter text-white text-lg py-2',
              { 'bg-boulder opacity-100 cursor-not-allowed': isBtnDisabled }
            )}
            type="submit"
            disabled={isBtnDisabled}
            onClick={hasPaymentMethod ? payAndCreateBooking : saveCreditCard}
          >
            {processing ? (
              <Spinner color="#fff" height={20} />
            ) : (
              `Pay USD ${eventPrice}`
            )}
          </button>
          <button
            className={classnames(
              'flex justify-center w-full rounded text-lg py-2 text-white mt-2 bg-boulder'
            )}
            type="button"
            onClick={() => dispatch(Actions.hideDialog())}
          >
            CANCEL
          </button>
        </div>
        <p className="text-xs self-start mt-4 text-dusty text-center">
          *By clicking &quot;Pay&quot;, you agree to our Terms of Service and
          Privacy Policy
        </p>

        <p className="text-xs self-start mt-4 text-dusty">
          Slots bought are non-refundable within 72 hours of the event.
        </p>
        <ErrorContainer className="max-w-120 mx-auto mt-4" error={error} />
      </>
    );
  };

  return (
    <Elements stripe={stripePromise} options={clientSecret}>
      <div className="flex flex-col justify-center items-center gap-5 -mt-10 px-4">
        <p>Confirm your payment details before proceeding with your purchase</p>
        <div className="flex flex-row justify-between items-center w-full mt-5">
          <p className="text-dusty text-left text-sm">{eventName}</p>
          <p className="text-base">{`USD ${Number(eventPrice)} / slot`}</p>
        </div>
        <div className="py-3 flex flex-row justify-between items-center w-full border-gray-300 border-t">
          <div className="text-dusty">Total</div>
          <div className="text-easter text-lg">{`USD ${eventPrice}`}</div>
        </div>
      </div>
      <CreditCardForm />
    </Elements>
  );
};

BookingPaymentModal.propTypes = {
  eventPrice: PropTypes.number.isRequired,
  eventName: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  handleConfirmBooking: PropTypes.func.isRequired,
};

export default BookingPaymentModal;

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* updatePerk({ id, data: payload, callbacks }) {
  try {
    const { image, ...rest } = payload;
    const data = typeof image === 'string' ? rest : payload;
    const response = yield call(api.updatePerk, { id, data });
    yield put(Actions.updatePerkSuccess(id, response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.updatePerkFail(error));
    if (typeof callbacks?.fail === 'function') {
      const err = error?.response?.data?.error;
      yield call(
        callbacks.fail,
        err ? err[0] : 'Something went wrong, please try again later'
      );
    }
  }
}

function* watchUpdatePerk() {
  yield takeLatest(Actions.UPDATE_PERK_REQUEST, updatePerk);
}

export default function* update() {
  yield all([fork(watchUpdatePerk)]);
}

export const NAME = 'SENDBIRD/UPDATE_SENDBIRD_USER';

export const UPDATE_SENDBIRD_USER_REQUEST = `${NAME}_REQUEST`;
export const UPDATE_SENDBIRD_USER_SUCCESS = `${NAME}_SUCCESS`;
export const UPDATE_SENDBIRD_USER_FAIL = `${NAME}_FAIL`;

export const updateSendbirdUser = (name, picture) => ({
  type: UPDATE_SENDBIRD_USER_REQUEST,
  name,
  picture,
});

export const updateSendbirdUserSuccess = () => ({
  type: UPDATE_SENDBIRD_USER_SUCCESS,
});

export const updateSendbirdUserFail = (error) => ({
  type: UPDATE_SENDBIRD_USER_FAIL,
  error,
});

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Spinner } from 'components/UI/Loading';
import AddedEventCard from './AddedEventCard';

const AddedList = ({ events, eventsPath, handleRemoveEvent, isLoading }) => {
  if (!isLoading && isEmpty(events)) return null;

  return (
    <div className="lg:container mx-auto sm:px-6 px-2 my-20 max-w-full">
      <div className="font-semibold pb-2 sm:pb-0 text-lg">SAVED EVENTS</div>
      {isLoading && isEmpty(events) && (
        <div className="flex my-20">
          <Spinner />
        </div>
      )}
      {!isEmpty(events) && (
        <>
          {events.map((item) => (
            <div className="flex-grow-0 flex-shrink-0 my-4" key={item.id}>
              <AddedEventCard
                eventInfo={item}
                key={item.id}
                eventsPath={eventsPath}
                handleRemoveEvent={handleRemoveEvent}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

AddedList.propTypes = {
  events: PropTypes.array.isRequired,
  eventsPath: PropTypes.string,
  handleRemoveEvent: PropTypes.func,
  isLoading: PropTypes.bool,
};

AddedList.defaultProps = {
  eventsPath: '',
  handleRemoveEvent: null,
  isLoading: false,
};

export default AddedList;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Card, Picture } from 'components/UI';
import pluralize from 'pluralize';
import { earnPointsIcon } from '../../../../public/assets';

const EarnPointsDropdown = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const openAccordion = () => setIsOpen(!isOpen);

  const OnePointActivities = [
    { label: 'Login everyday for 7 days' },
    {
      label: 'Attend a Real Talk',
      action: () => navigate('/members/events/explore'),
    },
    {
      label: 'Attend an Online Happy HOUR',
      action: () => navigate('/members/events/explore'),
    },
  ];

  const TwoPointActivities = [
    {
      label: 'Attend an off-line Mixer / Dinner',
      action: () => navigate('/members/events/explore'),
    },
    {
      label: 'Refer a premium member',
      action: () => navigate('/members/rewards/my-rewards'),
    },
    { label: 'Participate in GLP surveys' },
  ];

  const FourPointActivities = [{ label: 'Attend GLP Explores' }];

  const SixPointActivities = [
    {
      label: 'Refer an All Access member',
      action: () => navigate('/members/rewards/my-rewards'),
    },
    { label: 'Login everyday for 30 days' },
  ];
  // eslint-disable-next-line react/prop-types
  const PointsHeader = ({ points, background }) => {
    return (
      <div className="flex items-center">
        <h3 className="text-md md:text-2lg">
          {points} {pluralize('points', points)}
        </h3>
        <div className={classnames('h-1 flex-grow ml-2', background)} />
      </div>
    );
  };

  const ActivityListItem = ({ activityList, background }) => {
    return (
      <div
        className={classnames('flex flex-col gap-5 rounded-lg p-3', background)}
      >
        {activityList?.map(({ label, action }) => (
          <div className="flex justify-between items-center w-full">
            <p>{label}</p>
            <ChevronRightIcon fontSize="medium" onClick={action} />
          </div>
        ))}
      </div>
    );
  };

  ActivityListItem.propTypes = {
    activityList: PropTypes.object.isRequired,
    background: PropTypes.string.isRequired,
  };

  return (
    <Card className="mx-5 lg:mx-10 my-5 p-4">
      <article className="flex justify-between items-center">
        <div className="flex flex-col">
          <div className="flex items-center gap-3">
            <Picture src={earnPointsIcon} className="w-10 h-10 mb-3" />
            <h3>How to earn points?</h3>
          </div>
          <p className="text-xs lg:text-sm">
            Find out how you can earn and accumulate points by completing these
            simple activities
          </p>
        </div>
        {!isOpen ? (
          <ChevronRightIcon
            className="ml-1"
            fontSize="large"
            onClick={openAccordion}
            role="presentation"
          />
        ) : (
          <KeyboardArrowDown
            className="ml-1 text-easter"
            fontSize="large"
            onClick={openAccordion}
            role="presentation"
          />
        )}
      </article>

      <article
        className={classnames({
          hidden: !isOpen,
        })}
      >
        <section className="mt-5 flex flex-col gap-3">
          <PointsHeader points={1} background="bg-yellow-200" />
          <ActivityListItem
            activityList={OnePointActivities}
            background="bg-yellow-200"
          />
        </section>
        <section className="mt-5 flex flex-col gap-3">
          <PointsHeader points={2} background="bg-blue-200" />
          <ActivityListItem
            activityList={TwoPointActivities}
            background="bg-blue-200"
          />
        </section>
        <section className="mt-5 flex flex-col gap-3">
          <PointsHeader points={4} background="bg-purple-200" />
          <ActivityListItem
            activityList={FourPointActivities}
            background="bg-purple-200"
          />
        </section>
        <section className="mt-5 flex flex-col gap-3">
          <PointsHeader points={6} background="bg-purple-300" />
          <ActivityListItem
            activityList={SixPointActivities}
            background="bg-purple-300"
          />
        </section>
      </article>
    </Card>
  );
};

export default EarnPointsDropdown;

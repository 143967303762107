import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { gfxEmptyPerks } from 'assets';
import { Spinner } from 'components/UI/Loading';
import { Card, Picture } from 'components/UI';
import PerkCard from './PerkCard';
import PerkTileCard from '../PerkTileCard';

const RedeemedPerks = ({ perks, isLoading }) => {
  const isEmptyPerks = isEmpty(perks);
  const Content = () => {
    if (isEmptyPerks) {
      if (isLoading)
        return (
          <div className="flex my-20">
            <Spinner />
          </div>
        );

      return (
        <Card className="p-10 flex flex-no-wrap overflow-x-auto">
          <div className="m-auto text-center">
            <Picture src={gfxEmptyPerks} className="py-8 w-1/3 m-auto" />
            <h3 className="font-semibold font-lynstone">
              You have not redeemed any perks
            </h3>
            <p className="text-dusty">Perks redeemed will appear here</p>
          </div>
        </Card>
      );
    }

    return (
      <>
        <div className="hidden sm:grid gap-8 mb-10">
          {perks.map((perk) => (
            <PerkCard perk={perk} key={perk.id} />
          ))}
        </div>
        <div className="grid sm:hidden gap-8 mb-10">
          {perks.map((perk) => (
            <PerkTileCard perk={perk} key={perk.id} />
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="mb-10">
      <div className="font-semibold pb-2 sm:pb-6 text-lg font-chap">
        REDEEMED PERKS
      </div>
      <Content />
    </div>
  );
};

RedeemedPerks.propTypes = {
  perks: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RedeemedPerks;

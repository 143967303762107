const NAME = 'EVENTS/FETCH_ATTENDANCES_';

export const FETCH_ATTENDANCES_REQUEST = `${NAME}REQUEST`;
export const FETCH_ATTENDANCES_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_ATTENDANCES_FAIL = `${NAME}FAIL`;

export const fetchAttendances = (id) => ({
  type: FETCH_ATTENDANCES_REQUEST,
  id,
});

export const fetchAttendancesSuccess = (id, data) => ({
  type: FETCH_ATTENDANCES_SUCCESS,
  id,
  data,
});

export const fetchAttendancesFail = () => ({
  type: FETCH_ATTENDANCES_FAIL,
});

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/UI/Card';
import {
  bgDigitalPlatforms,
  bgSocialMedia,
  bgKeyMedia,
  bgMarketing,
  bgConference,
  bgFestival,
  bgLabels,
  bgDistribution,
  bgStudios,
  bgDspPlaylist,
  bgMusicPromoters,
  bgPublishing,
  bgRecordStores,
  bgSyncCompanies,
} from 'assets';
import { chunk, camelCase, pickBy } from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import styled from '@emotion/styled';
import { AuthContext } from 'utils/context';
import { BackgroundSection, LockOverlay } from '../LandingPage/common';

const linearGradient = `linear-gradient(0deg, rgba(48, 45, 68, 1) 0%, rgba(48, 45, 68, 0.7357317927170868) 70%, rgba(0, 212, 255, 0) 100%)`;

const StyledCard = styled(Card)`
  width: 100%;
  @media (min-width: 768px) {
    width: calc(50% - 30px);
  }
  @media (min-width: 1024px) {
    width: calc(34% - 32px);
  }
`;

const allAccessTierGuides = [
  'DSP playlists',
  'Key Media',
  'Social Media',
  'Promoters',
];

const backgrounds = (name) =>
  ({
    'Digital Streaming Platforms': bgDigitalPlatforms,
    'Social Media': bgSocialMedia,
    'Key Media': bgKeyMedia,
    'PR & Marketing Agencies': bgMarketing,
    'Conferences / Showcase': bgConference,
    Festivals: bgFestival,
    Labels: bgLabels,
    Distribution: bgDistribution,
    'Recording Studios': bgStudios,
    'DSP Playlists': bgDspPlaylist,
    Promoters: bgMusicPromoters,
    'Publishing Companies': bgPublishing,
    'Record Stores': bgRecordStores,
    'Sync Companies': bgSyncCompanies,
  }[name] || bgSocialMedia);

const AudienceReach = ({ audienceReach, showLockOverlay }) => {
  const { isPro } = useContext(AuthContext);
  const filteredGuides = pickBy(audienceReach, (value, key) => {
    const hasContent = !!ReactHtmlParser(value)[0]?.props?.children?.length;
    return hasContent && (isPro || !allAccessTierGuides.includes(key));
  });
  const showAudienceReach = Object.keys(filteredGuides).length > 0;
  const guides = isPro
    ? filteredGuides
    : {
        ...filteredGuides,
        ...pickBy(audienceReach, (_, key) => allAccessTierGuides.includes(key)),
      };

  const Paywall = () => {
    return (
      <>
        <div className="space-y-4 md:hidden">
          {chunk(Object.entries(audienceReach), 3).map((group) => (
            <div className="grid gap-4 relative" key={group[0][0]}>
              {group.map(([name, value]) => (
                <Card key={camelCase(name)}>
                  <BackgroundSection
                    background={`${linearGradient} , url(${
                      backgrounds(name).default
                    }) center center / cover no-repeat`}
                    className="relative bg-no-repeat bg-cover bg-center h-56 rounded-t-lg flex flex-col justify-end p-8"
                  >
                    <div className="text-white text-xl leading-tight">
                      {name}
                    </div>
                  </BackgroundSection>
                  <div className="inline-flex flex-col text-left p-8 text-lg text-gray-800 h-48 overflow-y-hidden">
                    <LockOverlay
                      showLockOverlay
                      isPaidContent
                      className="text-base bottom-auto -ml-8 h-40 z-10 rounded-b-lg"
                    />
                    {ReactHtmlParser(value)}
                  </div>
                </Card>
              ))}
            </div>
          ))}
        </div>
        <div className="hidden md:block space-y-4 lg:space-y-8">
          {chunk(Object.entries(audienceReach), 3).map((group) => {
            const classname =
              group.length < 3 ? 'flex justify-center' : 'grid grid-cols-3';
            const CardComponent = group.length < 3 ? StyledCard : Card;

            return (
              <div
                className={`${classname} gap-4 lg:gap-8 relative`}
                key={group[0][0]}
              >
                <LockOverlay
                  showLockOverlay
                  isPaidContent
                  className="rounded-b-lg z-10 h-56 "
                />
                {group.map(([name, value]) => (
                  <CardComponent key={camelCase(name)}>
                    <BackgroundSection
                      background={`${linearGradient} , url(${
                        backgrounds(name).default
                      }) center center / cover no-repeat`}
                      className="relative bg-no-repeat bg-cover bg-center h-56 rounded-t-lg flex flex-col justify-end p-8"
                    >
                      <div className="text-white text-xl leading-tight">
                        {name}
                      </div>
                    </BackgroundSection>
                    <div className="inline-flex flex-col text-left p-8 text-lg text-gray-800 h-64 overflow-y-hidden">
                      {ReactHtmlParser(value)}
                    </div>
                  </CardComponent>
                ))}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      {showAudienceReach && (
        <div className="container px-6 mx-auto mt-20">
          <h2 className="font-semibold mb-4">Reach Your Audience</h2>
          {showLockOverlay && <Paywall />}
          {!showLockOverlay && (
            <div className="flex flex-col justify-center lg:justify-start gap-4 sm:flex-row sm:flex-wrap sm:gap-8">
              {Object.entries(guides).map(([name, value]) => (
                <StyledCard key={camelCase(name)}>
                  <BackgroundSection
                    background={`${linearGradient} , url(${
                      backgrounds(name).default
                    }) center center / cover no-repeat`}
                    className="relative bg-no-repeat bg-cover bg-center h-56 rounded-t-lg flex flex-col justify-end p-8"
                  >
                    <div className="text-white text-xl leading-tight">
                      {name}
                    </div>
                  </BackgroundSection>
                  <div className="inline-flex flex-col text-left text-md text-gray-800 min-h-56 relative w-full">
                    {!isPro && allAccessTierGuides.includes(name) && (
                      <LockOverlay
                        showLockOverlay
                        isAllAccessContent
                        className="text-base h-full w-full z-10 rounded-b-lg"
                      />
                    )}
                    <div className="p-8">{ReactHtmlParser(value)}</div>
                  </div>
                </StyledCard>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

AudienceReach.propTypes = {
  audienceReach: PropTypes.object.isRequired,
  showLockOverlay: PropTypes.bool.isRequired,
};

export default AudienceReach;

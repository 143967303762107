import Actions from 'actions';

const initialState = Object.freeze({});

function attendances(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_ATTENDANCES_SUCCESS:
      return { ...state, [action.id]: action.data };
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default attendances;

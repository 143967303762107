import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { Button } from 'components/UI';
import { iconSuccess } from 'assets';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';

const TierInfoCard = ({
  content,
  memberTier,
  monthlyPrice,
  annualPrice,
  benefits,
  isAllAccessEligible,
  showNextScreen,
}) => {
  const navigate = useNavigate();
  const { memberTier: memberTierQuery } = useQuery();
  const showRecommended = isAllAccessEligible
    ? memberTier === ALL_ACCESS
    : memberTier === PREMIUM;

  return (
    <>
      <div className="rounded-lg flex flex-col bg-white md:w-4/12">
        <div className="p-6 flex flex-col justify-between h-104">
          <div>
            {showRecommended && (
              <div className="bg-yellow-500 text-xs rounded-full py-2 text-center w-1/2 md:w-8/12 mx-auto -mt-10">
                Recommended
              </div>
            )}
            <h4 className="font-semibold text-2lg py-5">{memberTier}</h4>
            {content && (
              <ul className="list-outside list-disc space-y-2 ml-3 text-easter text-2lg">
                {content.map((text) => (
                  <li key={text}>
                    <p className="text-black text-sm">{text}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <div className="flex flex-col items-end">
              {annualPrice ? (
                <>
                  <div className="flex mt-3">
                    <span>USD</span>
                    <span className="text-xl leading-none font-medium">
                      {monthlyPrice}
                    </span>
                    <span className="text-sm self-end">/mo</span>
                  </div>
                  <div className="text-xs mt-2 mr-4">{`USD ${annualPrice}/yr`}</div>
                </>
              ) : (
                <>
                  <div className="flex mt-6">
                    <span className="text-2lg leading-none font-medium">
                      FREE
                    </span>
                  </div>
                  <div className="text-xs mt-2 self-end">Forever</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex items-center h-10">
          <div className="flex-grow border-t border-gray-400" />
          <Button
            label={`Apply for ${memberTier}`}
            className="mx-auto w-11/12 text-xs lg:text-sm my-4"
            onClick={() => {
              if (memberTierQuery !== memberTier) {
                navigate({
                  pathname: '/signup',
                  search: `?memberTier=${memberTier}`,
                });
              } else {
                showNextScreen();
              }
            }}
          />
          <div className="flex-grow border-t border-gray-400" />
        </div>
        <div className="text-sm px-4 py-6">
          <p>Benefits:</p>
          <ul className="space-y-4 mt-3 font-light">
            {benefits &&
              benefits.map((text) => (
                <div className="flex space-x-3" key={text}>
                  <img
                    alt="check"
                    src={iconSuccess.default}
                    className="h-3 lg:h-4 mt-1"
                  />
                  <li>{text}</li>
                </div>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

TierInfoCard.propTypes = {
  content: PropTypes.array.isRequired,
  benefits: PropTypes.array.isRequired,
  memberTier: PropTypes.string.isRequired,
  isAllAccessEligible: PropTypes.bool,
  monthlyPrice: PropTypes.number,
  annualPrice: PropTypes.number,
  showNextScreen: PropTypes.func,
};

TierInfoCard.defaultProps = {
  monthlyPrice: 0,
  annualPrice: 0,
  isAllAccessEligible: false,
  showNextScreen: () => {},
};

export default TierInfoCard;

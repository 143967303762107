import Actions from 'actions';

export const initialState = Object.freeze([]);

function redeemed(state = initialState, { type, data }) {
  switch (type) {
    case Actions.FETCH_REDEEMED_SUCCESS:
      return data;
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default redeemed;

import { combineReducers } from 'redux';
import signIn from './signIn';
import activateAccount from './activateAccount';
import createPassword from './createPassword';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import validateResetPasswordCode from './validateResetPasswordCode';

export default combineReducers({
  signIn,
  activateAccount,
  createPassword,
  forgotPassword,
  resetPassword,
  validateResetPasswordCode,
});

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { groupBy } from 'lodash';
import { gfxConsultationInfo } from 'assets';
import { Loading, Card, Button, Picture, Options } from 'components/UI';
import { displayConsultationDateFormat, displayWeekday } from 'utils/datetime';
import { AddCircle } from '@material-ui/icons';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';
import { AuthContext } from 'utils/context';
import ServiceForm from './ServiceForm';
import ShareLink from './ShareLink';
import AddSlot from './AddSlot';
import PreviewSlot from './PreviewSlot';

const AUDIENCE_TYPES = {
  all_members: 'ALL MEMBERS',
  pro_only: `${ALL_ACCESS} MEMBERS ONLY`,
  lite_only: `${PREMIUM} MEMBERS ONLY`,
};

const Service = () => {
  const [isFormView, showFormView] = useState();
  const [addRecurringSlot, setAddRecurringSlot] = useState(false);
  const { isFreeTier } = useContext(AuthContext);
  const toggleFormView = () => showFormView(!isFormView);
  const dispatch = useDispatch();
  const service = useSelector(Selectors.getConsultationServiceData);
  const { biography, link, audience, currency, rate, slots = [] } =
    service || {};
  const individualSlots = slots.filter(({ recurring }) => !recurring);
  const recurringSlots = slots.filter(({ recurring }) => recurring);
  const formattedIndividualSlots = groupBy(
    individualSlots,
    ({ start_at: start }) => displayConsultationDateFormat(start)
  );
  const formattedRecurringSlots = groupBy(
    recurringSlots,
    ({ start_at: start }) => displayWeekday(start)
  );
  const isLoading = useSelector(
    Selectors.createLoadingSelector([
      Actions.FETCH_CONSULTATION_SERVICE_REQUEST,
    ])
  );

  useEffect(() => {
    if (isFreeTier) window.location.replace('/members/consultation/service');
  }, [isFreeTier]);

  useEffect(() => {
    if (!isFreeTier) {
      dispatch(Actions.fetchConsultationService());
      dispatch(Actions.fetchServiceBookings());
    }
  }, [dispatch, isFreeTier]);

  if (isLoading)
    return (
      <Loading
        className="bg-white h-screen flex justify-center items-center"
        opaque={false}
      />
    );

  const Content = () => {
    const title = (text) => <div className="text-dusty pb-2">{text}</div>;
    const [addRecurringSlotIsOpen, setAddRecurringSlotIsOpen] = useState(false);
    const openRecurringSlot = () => {
      setAddRecurringSlot(true);
      setAddRecurringSlotIsOpen(!addRecurringSlotIsOpen);
    };
    const [addSlotIsOpen, setAddSlotIsOpen] = useState(false);
    const openAddSlot = () => {
      setAddRecurringSlot(false);
      setAddSlotIsOpen(!addSlotIsOpen);
    };
    const closeSlotForms = () => {
      setAddRecurringSlotIsOpen(false);
      setAddSlotIsOpen(false);
    };

    if (service)
      return (
        <Card className="p-4 sm:p-8 flex flex-col md:flex-row">
          <Picture
            src={gfxConsultationInfo}
            className="w-10 mr-8 hidden md:block"
          />
          <div className="flex flex-col flex-1">
            <div className="mb-6">
              {title('Description')}
              <p className="whitespace-pre-line">{biography}</p>
            </div>
            <div className="mb-6">
              {title('Online Consultation URL')}
              {link ? (
                <a
                  href={link}
                  rel="noreferrer"
                  target="_blank"
                  className="text-easter"
                >
                  {link}
                </a>
              ) : (
                <>-</>
              )}
            </div>
            <div className="mb-6">
              {title('Available To')}
              <p className="uppercase">{AUDIENCE_TYPES[audience]}</p>
            </div>
          </div>
          <div className="flex flex-col flex-1 md:pl-6 md:ml-6 md:border-l border-gray-300">
            <div className="mb-6">
              {title('Available Slots')}
              <div className="mb-3">
                {individualSlots.length > 0 &&
                  Object.entries(formattedIndividualSlots).map(([k, v]) => (
                    <PreviewSlot slotLabel={k} slotValue={v} />
                  ))}
              </div>
              <AddSlot
                addSlotIsOpen={addSlotIsOpen || false}
                service={service}
                addRecurringSlot={addRecurringSlot}
                closeSlotForms={closeSlotForms}
              />
              <button
                className="text-easter flex items-center focus:outline-none hover:bg-alto rounded-full"
                type="button"
                onClick={openAddSlot}
              >
                <AddCircle className="mr-2" />
                Add Slot
              </button>
            </div>
            <div className="mb-6">
              {title('Recurring Booking Slots')}
              <div className="mb-3">
                {recurringSlots.length > 0 &&
                  Object.entries(formattedRecurringSlots).map(([k, v]) => (
                    <PreviewSlot slotLabel={k} slotValue={v} recurring />
                  ))}
              </div>
              <AddSlot
                addRecurringSlotIsOpen={addRecurringSlotIsOpen || false}
                service={service}
                addRecurringSlot={addRecurringSlot}
                closeSlotForms={closeSlotForms}
              />
              <button
                className="text-easter flex items-center focus:outline-none hover:bg-alto rounded-full"
                type="button"
                onClick={openRecurringSlot}
              >
                <AddCircle className="mr-2" />
                Add Slot
              </button>
            </div>
            <div className="mb-6">
              {title('Consultation Rate')}
              <p className="text-xl text-easter">
                {`${currency} ${Math.trunc(rate)}/hour`}
              </p>
            </div>
          </div>
        </Card>
      );

    return (
      <Card className="p-6 sm:p-10 text-center">
        <div className="m-auto text-center lg:w-2/3">
          <Picture src={gfxConsultationInfo} className="py-8 w-24 m-auto" />
          <h3 className="font-semibold font-lynstone text-md md:text-lg">
            You have not created any consultation info for your consultation
            service
          </h3>
          <p className="text-dusty mt-2">
            Add information about your consultation service including available
            slots to begin offering consultation services to other members.
          </p>
          <Button
            isPill
            className="mt-8 w-full md:w-64 max-w-80 mx-auto"
            label="ADD CONSULTATION INFO"
            onClick={toggleFormView}
          />
        </div>
      </Card>
    );
  };

  return (
    <div className="lg:container mx-auto px-3 sm:px-6 pt-30 pb-10">
      {isFormView ? (
        <ServiceForm toggleFormView={toggleFormView} service={service} />
      ) : (
        <>
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold">CONSULTATION INFO</h3>
            {service && (
              <Options
                list={[
                  { label: 'Edit Consultation Info', onClick: toggleFormView },
                ]}
              />
            )}
          </div>
          <Content />
          {service && <ShareLink />}
        </>
      )}
    </div>
  );
};

export default Service;

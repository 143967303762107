import styled from '@emotion/styled';
import { background, layout } from 'styled-system';

export const BackgroundSection = styled.section`
  ${background}
  ${layout}
`;

export const labelBg = (label) => {
  let bg;

  switch (label) {
    case 'News':
    case 'Concert':
    case 'Sarah Guppy':
      bg = 'bg-gradient-turqoise-violet';
      break;
    case 'Press Play':
    case 'Member Happy Hour':
      bg = 'bg-gradient-violet';
      break;
    case 'Reach Your Audience':
    case 'Festival':
      bg = 'bg-gradient-tory-cyan';
      break;
    case 'Pro Spotlight':
    case 'Event Spotlight':
    case 'Member Webinar':
    case 'Conference':
    case 'Priya Dewan':
    default:
      bg = 'bg-gradient-violet-cyan';
      break;
  }

  return bg;
};

export { default as LockOverlay } from './LockOverlay';

import { combineReducers } from 'redux';
import list from './list';
import info from './info';
import attend from './attend';
import unattend from './unattend';
import attendList from './attendList';
import optIn from './optIn';
import optOut from './optOut';
import getCategories from './getCategories';
import getCount from './getCount';
import attendances from './attendances';
import addedList from './addedList';
import createdList from './createdList';
import fetchCreated from './fetchCreated';

export default combineReducers({
  list,
  info,
  attend,
  unattend,
  attendList,
  optIn,
  optOut,
  getCategories,
  getCount,
  attendances,
  addedList,
  createdList,
  fetchCreated,
});

import persist from './persist';
import common from './common';
import cities from './cities';
import articles from './articles';
import countries from './countries';
import sendbird from './sendbird';
import dashboard from './dashboard';
import * as membership from './membership';
import * as events from './events';
import * as user from './user';
import stripe from './stripe';
import subscription from './subscription';
import ui from './ui';
import consultation from './consultation';
import timezones from './timezones';
import * as videos from './videos';
import * as perks from './perks';
import * as consultationService from './consultationService';

export default {
  ...persist,
  ...common,
  ...events,
  ...cities,
  ...articles,
  ...countries,
  ...sendbird,
  ...membership,
  ...stripe,
  ...user,
  ...dashboard,
  ...subscription,
  ...ui,
  ...consultation,
  ...timezones,
  ...videos,
  ...perks,
  ...consultationService,
};

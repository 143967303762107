import { combineReducers } from 'redux';
import create from './create';
import show from './show';
import update from './update';
import uploadUserImage from './uploadUserImage';
import deleteUserImage from './deleteUserImage';
import list from './list';
import profile from './profile';

export default combineReducers({
  create,
  show,
  update,
  uploadUserImage,
  deleteUserImage,
  list,
  profile,
});

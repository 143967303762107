import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { createdEventsEmpty } from 'assets';
import { Card, Picture, Button } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import CreatedEventCard from './CreatedEventCard';

const CreatedList = ({ events, eventsPath, isLoading, showForm }) => {
  const Content = () => {
    if (isLoading && isEmpty(events)) {
      return (
        <div className="flex my-20">
          <Spinner />
        </div>
      );
    }

    if (!isLoading && isEmpty(events)) {
      return (
        <Card className="mx-auto border-t-2 p-10 text-center mt-5">
          <div className="flex flex-no-wrap overflow-x-auto w-full">
            <div className="m-auto text-center">
              <Picture src={createdEventsEmpty} className="py-8 w-2/5 m-auto" />
              <h3 className="font-semibold font-lynstone">
                You have not created any events
              </h3>
              <p className="text-dusty">Your created events will appear here</p>
              <Button
                className="mt-8 max-w-80 mx-auto"
                label="CREATE EVENT"
                onClick={showForm}
              />
            </div>
          </div>
        </Card>
      );
    }

    return (
      <>
        {events.map((item) => (
          <div className="my-4" key={item.id}>
            <CreatedEventCard
              eventInfo={item}
              eventsPath={eventsPath}
              showForm={showForm}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="lg:container mx-auto pt-20 sm:pt-10 sm:px-6 px-2 max-w-full">
      <div className="flex items-center justify-between pb-2 sm:pb-0">
        <div className="font-semibold pb-2 sm:pb-0 text-lg font-chap">
          CREATED EVENTS
        </div>
        {!!events.length && (
          <Button
            type="button"
            isPill
            className="px-2 sm:px-8"
            onClick={showForm}
            label="CREATE EVENT"
          />
        )}
      </div>
      <Content />
    </div>
  );
};

CreatedList.propTypes = {
  events: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showForm: PropTypes.func.isRequired,
  eventsPath: PropTypes.string.isRequired,
};

export default CreatedList;

import React, { useEffect, useContext } from 'react';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Loading } from 'components/UI';
import { AuthContext } from 'utils/context';
import { useNavigate } from 'react-router-dom';
import UpcomingEvents from '../Events/Upcoming';
import SummaryCard from './SummaryCard';
import RelatedArticles from '../Articles/Related';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasAccess } = useContext(AuthContext);
  const dashboard = useSelector(Selectors.dashboardInfo);
  const allArticles = useSelector(Selectors.getPaginatedArticles);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_DASHBOARD_INFO_REQUEST])
  );

  useEffect(() => {
    dispatch(Actions.fetchArticles());
  }, [dispatch]);

  useEffect(() => {
    if (!hasAccess) navigate('/subscription');
  }, [navigate, hasAccess]);

  useEffect(() => {
    dispatch(Actions.fetchDashboardInfo());
  }, [dispatch]);

  if (isLoading && isEmpty(dashboard)) return <Loading isLoading={isLoading} />;
  if (!isLoading && isEmpty(dashboard)) {
    return (
      <div className="m-auto">
        <Loading isLoading />
      </div>
    );
  }

  const {
    rsvp_events_count: yourEventsCount,
    upcoming_events_count: upcomingEventsCount,
    new_member_count: newMembersCount,
    upcoming_events: upcomingEvents,
  } = dashboard;

  return (
    <>
      <SummaryCard
        yourEventsCount={yourEventsCount}
        upcomingEventsCount={upcomingEventsCount}
        newMembersCount={newMembersCount}
      />
      <UpcomingEvents events={upcomingEvents} />
      <RelatedArticles articles={allArticles} fromDashboardPage />
    </>
  );
};

export default Dashboard;

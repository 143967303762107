export const NAME = 'SENDBIRD';

export const LIST_MESSAGES_REQUEST = `${NAME}/LIST_MESSAGES_REQUEST`;
export const LIST_MESSAGES_SUCCESS = `${NAME}/LIST_MESSAGES_SUCCESS`;
export const LIST_MESSAGES_FAIL = `${NAME}/LIST_MESSAGES_FAIL`;
export const ON_MESSAGE_RECEIVED = `${NAME}/ON_MESSAGE_RECEIVED`;

export const listMessages = (channel) => ({
  type: LIST_MESSAGES_REQUEST,
  channel,
});

export const listMessagesSuccess = (id, data) => ({
  type: LIST_MESSAGES_SUCCESS,
  id,
  data,
});

export const listMessagesFail = (error) => ({
  type: LIST_MESSAGES_FAIL,
  error,
});

export const onMessageReceived = (message) => ({
  type: ON_MESSAGE_RECEIVED,
  message,
});

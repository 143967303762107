import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, ImageFilter } from 'components/UI';
import { displaySubscriptionDateFormat } from 'utils/datetime';
import { urlHandler } from 'utils/helper';
import { BackgroundSection } from '../../LandingPage/common';
import MemberAccessBadge from '../MemberAccessBadge';

const PerkCard = ({ perk, featured }) => {
  const {
    id,
    name,
    summary,
    member_access: memberAccess,
    image_url: img,
    end_date: expiry,
  } = perk;

  if (featured) {
    return (
      <Link className="col-span-2" to={id}>
        <Card className="w-full shadow-xl">
          <BackgroundSection
            backgroundImage={`url(${urlHandler(img)})`}
            className="relative bg-no-repeat bg-cover bg-center h-140 rounded-lg"
          >
            <ImageFilter className="rounded-lg" />
            <div className="flex flex-col text-white justify-end h-full relative">
              <div className="flex flex-col m-10">
                <MemberAccessBadge memberAccess={memberAccess} md />
                <div className="lg:text-6xl text-xl font-chap">{name}</div>
                <div className="text-lg">{summary}</div>
                <div className="mt-5">
                  {`Expires ${displaySubscriptionDateFormat(expiry)}`}
                </div>
              </div>
            </div>
          </BackgroundSection>
        </Card>
      </Link>
    );
  }

  return (
    <Link to={id}>
      <Card className="w-full flex overflow-hidden h-48">
        <div className="w-2/3 relative">
          <BackgroundSection
            backgroundImage={`url(${urlHandler(img)})`}
            className="bg-no-repeat bg-cover bg-center h-full relative"
          />
          <div className="m-4 absolute top-0 left-0">
            <MemberAccessBadge memberAccess={memberAccess} />
          </div>
        </div>
        <div className="w-full p-4 pl-6 flex flex-col">
          <div className="text-lg font-semibold mb-2 break-normal">{name}</div>
          <div className="text-dusty overflow-y-scroll">{summary}</div>
          <div className="flex flex-row justify-end text-dusty text-xs mt-auto">
            {`Expires ${displaySubscriptionDateFormat(expiry)}`}
          </div>
        </div>
      </Card>
    </Link>
  );
};

PerkCard.propTypes = {
  perk: PropTypes.object.isRequired,
  featured: PropTypes.bool.isRequired,
};

export default PerkCard;

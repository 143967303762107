import React from 'react';
import { Button, Select } from 'components/UI';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from 'components/Form/TextInput';
import { iconIGLink } from 'assets';
import SocialMediaLinkInput from 'components/Form/SocialMediaLinkInput';
import notify from 'utils/notification';

const Index = () => {
  const fruitsList = [
    'Apple',
    'Mango',
    'Peach',
    'Banana',
    'Orange',
    'Grapes',
    'Watermelon',
    'Tomato',
  ];
  const array = fruitsList.map((item, index) => ({
    label: item,
    value: index,
  }));

  const formSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email'),
    secondaryEmail: Yup.string().email('Invalid email'),
    InstagramLink: Yup.string().url('Invalid Url'),
  });

  return (
    <div className="container mx-auto">
      <h2>Notification Test</h2>
      <Button
        label="Test Notification"
        onClick={() => notify('Notify Message', 'Title')}
        type="button"
      />
      <h1>H1 Header</h1>
      <h2>H2 Header</h2>
      <h3>H3 Header</h3>
      <h4>H4 Header</h4>
      <h5>H5 Header</h5>
      <h6>H6 Header</h6>
      <div className="font-chap">Chap</div>
      <div className="font-lynstone">Lynstone</div>
      <Button label="Sign In" onClick={() => console.log('test')} />
      <Button
        outline
        transparent
        label="Sign In"
        onClick={() => console.log('test')}
      />
      <h2 className="mt-10">Select Component</h2>
      <Select
        className="w-32 mt-18 ml-10"
        onChange={(e) => console.log(e)}
        options={array}
        defaultValue={array[0]}
      />
      <h2 className="mt-10">Multi-Select Component</h2>
      <Select
        className=" max-w-100 mt-18 ml-10"
        onChange={(e) => console.log(e)}
        isMulti
        options={array}
        defaultValue={array[0]}
      />
      <Formik
        validationSchema={formSchema}
        initialValues={{
          email: '',
          secondaryEmail: '',
        }}
      >
        {({ errors, values }) => (
          <form className="mt-10 mr-20">
            <h2 className="mb-5">Text Input Basic</h2>
            <Field
              className="max-w-80"
              name="email"
              basic
              component={TextInput}
              placeholder="Email"
            />
            <h2 className="mt-8 mb-5">Text Input Styled</h2>
            <Field
              className="max-w-80"
              name="secondaryEmail"
              component={TextInput}
              placeholder="Email"
            />
            <h2 className="mb-5">Text Input Basic</h2>
            <Field
              className="max-w-80"
              name="email"
              basic
              component={TextInput}
              placeholder="Email"
            />
            <h2 className="mt-8 mb-5">Text Input Styled</h2>
            <Field
              className="max-w-80"
              name="secondaryEmail"
              component={TextInput}
              placeholder="Email"
            />
            <h2 className="mt-8 mb-5">Social Media link Input</h2>
            <SocialMediaLinkInput
              className="max-w-80"
              name="InstagramLink"
              img={iconIGLink.default}
              isValid={!errors?.InstagramLink && values?.InstagramLink}
              placeholder="Paste Instagram link here…"
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Index;

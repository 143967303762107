import * as create from './create';
import * as update from './update';
import * as setupIntent from './setupIntent';
import * as downgrade from './downgrade';
import * as cancelUpdate from './cancelUpdate';

export default {
  ...create,
  ...update,
  ...setupIntent,
  ...downgrade,
  ...cancelUpdate,
};

const NAME = 'COUNTRIES/FETCH_COUNTRY_OPTIONS';

export const FETCH_COUNTRY_OPTIONS_REQUEST = `${NAME}_REQUEST`;
export const FETCH_COUNTRY_OPTIONS_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_COUNTRY_OPTIONS_FAIL = `${NAME}_FAIL`;

export const fetchCountryOptions = () => ({
  type: FETCH_COUNTRY_OPTIONS_REQUEST,
});

export const fetchCountryOptionsSuccess = (data) => ({
  type: FETCH_COUNTRY_OPTIONS_SUCCESS,
  data,
});

export const fetchCountryOptionsFail = (error) => ({
  type: FETCH_COUNTRY_OPTIONS_FAIL,
  error,
});

import Actions from 'actions';

const initialState = Object.freeze({});

function sendEnquiryEmail(state = initialState, action) {
  switch (action.type) {
    case Actions.SEND_ENQUIRY_EMAIL_REQUEST:
      return initialState;
    case Actions.SEND_ENQUIRY_EMAIL_SUCCESS:
      return {
        ...initialState,
        data: action.data,
      };
    case Actions.SEND_ENQUIRY_EMAIL_FAIL:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
}

export default sendEnquiryEmail;

import Actions from 'actions';

export const initialState = Object.freeze([]);

function created(state = initialState, { type, data, id }) {
  switch (type) {
    case Actions.FETCH_CREATED_SUCCESS:
      return data;
    case Actions.UPDATE_PERK_SUCCESS: {
      const newState = [...state];
      const index = newState.findIndex((perk) => perk.id === id);
      newState[index] = data;
      return newState;
    }
    case Actions.DELETE_PERK_SUCCESS:
      return state.filter((perk) => perk.id !== id);
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default created;

import React from 'react';
import { Outlet } from 'react-router-dom';
import { ScrollToTop } from 'components/UI';
import Navbar from './common/Navbar';
import Footer from './common/Footer';

const index = () => (
  <div className="flex flex-col min-h-screen">
    <ScrollToTop />
    <Navbar />
    <Outlet />
    <Footer />
  </div>
);

export default index;

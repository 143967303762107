const NAME = 'USER';

export const CREATE_USER_REQUEST = `${NAME}/CREATE_USER_REQUEST`;
export const CREATE_USER_SUCCESS = `${NAME}/CREATE_USER_SUCCESS`;
export const CREATE_USER_FAIL = `${NAME}/CREATE_USER_FAIL`;

export const createUser = (data, callbacks) => ({
  type: CREATE_USER_REQUEST,
  data,
  callbacks,
});

export const createUserSuccess = () => ({
  type: CREATE_USER_SUCCESS,
});

export const createUserFail = (error) => ({
  type: CREATE_USER_FAIL,
  error,
});

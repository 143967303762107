import React from 'react';
import PropTypes from 'prop-types';
import { Picture } from 'components/UI';
import {
  gfxAvatar,
  premiumReferredIcon,
  allAccessReferredIcon,
  othersSuccessfullRewardIcon,
  joinedEventRewardIcon,
  loginStreakRewardIcon,
} from 'assets';
import { displayDayDateFormat } from '../../../utils/datetime';

const RewardActivityCard = ({ rewardActivity }) => {
  const {
    created_at: createdAt,
    referred_user: referredUser,
    points,
    reward,
  } = rewardActivity;
  const { first_name: firstName, last_name: lastName, image_url: userImg } =
    referredUser || {};

  const userFullName = `${firstName} ${lastName}` || {};
  const { reward_type: rewardType, member_tier: rewardMemberTier } =
    reward || {};

  const referralReward = rewardType === 'referral';

  const rewardImgMap = {
    referral: userImg,
    attendance: joinedEventRewardIcon,
    login_streak: loginStreakRewardIcon,
  };

  const iconMap = {
    'All Access': allAccessReferredIcon,
    Premium: premiumReferredIcon,
    Free: othersSuccessfullRewardIcon,
  };

  const rewardDescriptionMap = {
    attendance: 'attended an event',
    login_streak: 'logged in for 7 days straight',
  };

  const rewardIcon = iconMap[rewardMemberTier];

  return (
    <>
      <section className="w-full xl:w-11/12 rounded-lg border p-3 md:px-3 lg:px-5 mt-5 flex items-center">
        <div className="flex flex-col">
          <Picture
            src={rewardImgMap[rewardType] || gfxAvatar.default}
            className="self-center mr-5 w-20 lg:w-20 rounded-full"
          />
          <Picture
            src={rewardIcon || othersSuccessfullRewardIcon}
            className="mr-5 w-5 rounded-full self-end -mt-3"
          />
        </div>
        <article className="flex flex-col md:flex-row md:justify-between w-full">
          <section className="flex flex-col">
            {referralReward ? (
              <p className="text-xs lg:text-md font-light">
                <span className="font-semibold">{userFullName}</span>{' '}
                successfully referred as{' '}
                <span className="font-semibold text-violet">
                  {reward.member_tier}{' '}
                </span>
                member
              </p>
            ) : (
              <p>
                <span className="font-bold">You </span>
                {rewardDescriptionMap[rewardType] || 'were rewarded points'}
              </p>
            )}

            <p className="text-xxs lg:text-sm text-dusty font-light">
              {displayDayDateFormat(createdAt)}
            </p>
          </section>
          <p className="text-xs md:text-md lg:text-lg self-start md:self-center font-semibold text-brilliantGreen ">
            + {points} Points
          </p>
        </article>
      </section>
    </>
  );
};

RewardActivityCard.propTypes = {
  rewardActivity: PropTypes.object,
};

RewardActivityCard.defaultProps = {
  rewardActivity: {},
};

export default RewardActivityCard;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Error = ({ error, className }) => {
  if (error)
    return (
      <div
        className={classnames(
          'bg-pippin font-light p-4 text-valencia',
          'border-2 border-dashed border-valencia ',
          className
        )}
      >
        {error}
      </div>
    );
  return null;
};

Error.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
};

Error.defaultProps = {
  error: null,
  className: 'mt-4',
};

export default Error;

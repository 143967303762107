const NAME = 'MEMBER/FETCH_TIER';

export const FETCH_MEMBER_TIER_REQUEST = `${NAME}_REQUEST`;
export const FETCH_MEMBER_TIER_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_MEMBER_TIER_FAIL = `${NAME}_FAIL`;

export const fetchMemberTier = (id) => ({
  type: FETCH_MEMBER_TIER_REQUEST,
  id,
});

export const fetchMemberTierSuccess = (data) => ({
  type: FETCH_MEMBER_TIER_SUCCESS,
  data,
});

export const fetchMemberTierFail = (error) => ({
  type: FETCH_MEMBER_TIER_FAIL,
  error,
});

import { takeLatest, all, fork, put, call, select } from 'redux-saga/effects';
import Actions from 'actions';
import { listSbMessages } from 'utils/sendbird';
import Selectors from 'selectors';

function* listMessages({ channel }) {
  try {
    const { sendbird_id: sendbirdId } = yield select(Selectors.getUser);
    const messages = yield call(listSbMessages, channel);

    if (messages) {
      const serialized = messages.map((message) => {
        // eslint-disable-next-line no-underscore-dangle
        const sender = message._sender ? message._sender : { userId: 'admin' };

        const { messageId, message: text, createdAt, plainUrl } = message;
        const { userId: senderId, plainProfileUrl, nickname } = sender;

        return {
          id: messageId,
          position: senderId === sendbirdId ? 'right' : 'left',
          type: 'text',
          text,
          createdAt,
          fromAdmin: senderId === 'admin',
          avatar: plainProfileUrl,
          nickname,
          plainUrl,
        };
      });
      // eslint-disable-next-line no-underscore-dangle
      yield put(Actions.listMessagesSuccess(channel.url, serialized));
    }
  } catch (error) {
    yield put(Actions.listMessagesFail(error));
  }
}

// function* onMessageReceived({ message }) {
//   // const serialized = serializeMessage(message);
//   yield put(Actions.listMessagesSuccess(message));
// }

function* watchListMessages() {
  yield takeLatest(Actions.LIST_MESSAGES_REQUEST, listMessages);
}

// function* watchOnMessageReceived() {
//   yield takeLatest(Actions.ON_MESSAGE_RECEIVED, onMessageReceived);
// }

export default function* list() {
  yield all([fork(watchListMessages)]);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Footer } from 'components/UI';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const FormFooter = ({
  onCancel,
  onSubmit,
  onSubmitDraft,
  isUpdate,
  isSubmitting,
}) => (
  <Footer>
    <div className="flex items-center w-full text-xs sm:text-base sm:px-12 justify-around">
      <button
        type="button"
        className="text-dusty hover:text-easter flex focus:outline-none sm:mr-auto"
        onClick={onCancel}
        disabled={isSubmitting}
      >
        CANCEL
      </button>
      <button
        type="button"
        className="text-dusty hover:text-easter flex focus:outline-none"
        onClick={onSubmitDraft}
        disabled={isSubmitting}
      >
        SAVE AS DRAFT
      </button>
      <Button
        disabled={isSubmitting}
        type="button"
        isPill
        className="px-2 sm:px-8 sm:ml-10"
        onClick={onSubmit}
        label={
          <div className="flex justify-center items-center">
            <p>{`${isUpdate ? 'UPDATE' : 'CREATE'} PERK`}</p>
            <DoubleArrowIcon fontSize="small" className="ml-1 sm:ml-3" />
          </div>
        }
      />
    </div>
  </Footer>
);

FormFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitDraft: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormFooter;

import api from './helper';

export default {
  // Events
  fetchEvents: (params) => api.get('/events', params),
  fetchEventInfo: (id) => api.get(`/events/${id}`),
  fetchCountries: (params) => api.get('/countries', params),
  fetchCountryOptions: () => api.get('/countries/options'),
  fetchCitiesOfCountry: (data) => api.get(`/countries/${data}/city_options`),
  fetchCities: (params) => api.get('/cities', params),
  fetchCityInfo: (id) => api.get(`/cities/${id}`),
  fetchCategories: (params) => api.get('/event_categories', params),
  fetchCount: () => api.get('/landing_page/content_count'),
  addEvent: (id) => api.post(`/events/${id}/users_event`),
  removeEvent: (id) => api.delete(`/events/${id}/users_event`),
  fetchTimezones: () => api.get('/time_zones'),
  createEvent: (data) => api.multipartPost('/users/events', { data }),
  fetchCreatedEvents: () => api.get('/users/events'),
  deleteEvent: (id) => api.delete(`/users/events/${id}`),
  updateEvent: ({ id, data }) =>
    api.multipartPut(`/users/events/${id}`, { data }),
  fetchCreatedEvent: (id) => api.get(`/users/events/${id}`),
  flagEvent: (id, data) => api.post(`/events/${id}/flag`, data),
  eventPaymentIntent: (id) => api.get(`/events/${id}/payment_intent`),

  // RSVP Events
  fetchAttendingEvents: () => api.get('/events/attending'),
  attendEvent: ({ id, data }) => api.post(`/events/${id}/attendance`, data),
  unattendEvent: (id) => api.delete(`/events/${id}/attendance`),
  optInEvent: (id) => api.post(`/events/${id}/opt_in`),
  optOutEvent: (id) => api.post(`/events/${id}/opt_out`),
  fetchAttendances: (id) => api.get(`/events/${id}/attendances`),
  addGuest: (id, data) => api.post(`/events/${id}/guests`, data),
  removeGuest: (id) => api.delete(`/events/${id}/guests`),
  updateGuest: (id, data) => api.put(`/events/${id}/guests`, data),

  // Articles
  fetchArticleCategories: () => api.get('/article_categories'),
  fetchArticles: (params) => api.get('/articles', params),
  fetchArticleInfo: (id) => api.get(`/articles/${id}`),
  favouriteArticleInfo: (id) => api.post(`/articles/${id}/favourite`),
  unfavouriteArticleInfo: (id) => api.post(`/articles/${id}/unfavourite`),
  newsLetterSignUp: (data) => api.post('/newsletter', data),

  // Videos
  fetchVideo: (id) => api.get(`/videos/${id}`),
  fetchVideos: (params) => api.get('/videos', params),
  fetchVideoCategories: () => api.get('/video_categories'),

  // Perks
  fetchPerks: (params) => api.get('/perks', params),
  fetchPerk: (id) => api.get(`/perks/${id}`),
  redeemPerk: (id) => api.post(`/perks/${id}/redeem`),
  redeemedPerks: () => api.get(`/perks/redemptions`),
  createPerk: (data) => api.multipartPost('/perks', { data }),
  createdPerks: () => api.get('/perks/created'),
  deletePerk: (id) => api.delete(`/perks/${id}`),
  updatePerk: ({ id, data }) => api.multipartPut(`/perks/${id}`, { data }),

  // Rewards && Redeemables
  fetchRewardsActivity: (params) => api.get(`/users_points`, params),
  fetchUserPoints: () => api.get('/users_points/status'),
  fetchRedeemables: (params) => api.get('/redeemables', params),
  fetchUserRedeemables: (params) => api.get('/users_redeemables', params),
  redeemReward: (id, data) => api.post(`/redeemables/${id}/redeem`, data),

  // User
  createUser: (data) => api.post('/users', data),
  fetchMemberTypes: (params) => api.get('/member_types', params),
  fetchMemberTiers: () => api.get('/member_tiers'),
  fetchMemberTier: (id) => api.get(`/member_tiers/${id}`),
  fetchUserProfile: () => api.get('/profiles/show'),
  fetchUserProfileBySendbirdId: (id) => api.get(`/sendbird/${id}`),
  updateUserProfile: (data) => api.patch('/profiles/update', data),
  uploadUserImage: (data) =>
    api.multipartPost('/profiles/upload_image', { data, fileType: 'image' }),
  deleteUserImage: () => api.delete('/profiles/remove_image'),
  fetchUsers: (params) => api.get('/users', params),
  fetchUser: (id) => api.get(`/users/${id}`),
  terminateAccount: (data) => api.post('/profiles/terminate', data),
  blockUser: (id, data) => api.post(`/users/${id}/block_user`, data),
  unblockUser: (id) => api.post(`/users/${id}/unblock_user`),
  fetchBlockedUsers: () => api.get('/users/blocked_users'),

  // Auth
  validateEmail: (data) => api.post('/registration/validate_email', data),
  createPassword: (data) => api.post('/registration/complete', data),
  forgotPassword: (data) => api.post('/passwords', data),
  resetPassword: (data) => api.put('/passwords/reset', data),
  updatePassword: (data) => api.put('/passwords', data),
  validateResetPasswordCode: (data) => api.post('/passwords/validate', data),
  signIn: (data) => api.post('/authentication', data),
  signOut: () => api.delete('/authentication'),

  // Stripe
  setupIntent: (data) => api.post('/subscription/setup_intent', data),
  getStripeCustomer: () => api.get('/stripe_customer'),
  createSubscription: (data) => api.post('/subscription', data),
  downgradeSubscription: (data) => api.post('/subscription/downgrade', data),
  fetchSubscription: () => api.get('/subscription'),
  updateSubscription: (data) => api.put('/subscription', data),
  cancelUpdateSubscription: () => api.post('/subscription/cancel_update'),
  validatePromoCode: (data) => api.post(`/promo_code/validate`, data),
  createPortalSession: () => api.post('/stripe_portal_sessions', {}),
  fetchUserCreditCard: () => api.get('/credit_card'),
  toggleReminder: () => api.post('/subscription/toggle_reminder'),

  // Chat
  fetchSendbirdUsers: () => api.get('/sendbird/users'),
  sendChatEmailNotification: (data) => api.post(`/chat_notifications`, data),

  // Consultation
  fetchConsultants: () => api.get('/consultants'),
  fetchConsultationSlots: (id, params) =>
    api.get(`/consultants/${id}/slots`, params),
  createBooking: (data) => api.post('/consultation_bookings', data),
  fetchBookings: () => api.get('/consultation_bookings'),
  cancelBooking: (id) => api.post(`/consultation_bookings/${id}/cancel`),
  rescheduleBooking: (id, data) =>
    api.post(`/consultation_bookings/${id}/reschedule`, data),
  fetchCompletedBookings: () =>
    api.get('/consultation_bookings/completed_bookings'),

  // Consultation Service
  fetchConsultationService: () => api.get('/consultation_service'),
  createConsultationService: (params) =>
    api.post('/consultation_service', params),
  updateConsultationService: (params) =>
    api.put('/consultation_service', params),
  fetchServiceBookings: (params) =>
    api.get('/consultation_service/bookings', params),
  approveBooking: (id) =>
    api.post(`/consultation_service/bookings/${id}/approve`),
  cancelConsultationServiceBooking: (id) =>
    api.post(`/consultation_service/bookings/${id}/cancel`),
  declineBooking: ({ id, data }) =>
    api.post(`/consultation_service/bookings/${id}/decline`, data),
  fetchCompletedServices: () =>
    api.get('/consultation_service/bookings/completed_services'),

  // Dashboard
  fetchDashboardInfo: () => api.get('/dashboard'),

  // Others
  sendEnquiryEmail: (data) => api.post('/contact_us', data),

  // Common
  fetchLanguages: () => api.get('/languages'),
};

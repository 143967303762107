import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';
import { paginationHelper } from 'utils/helper';

function* fetchCities({ params, callbacks }) {
  try {
    const response = yield call(api.fetchCities, params);
    const data = response?.data;
    const pagination = paginationHelper(response?.headers);

    yield put(Actions.fetchCitiesSuccess(data, pagination));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.fetchCitiesFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchCities() {
  yield takeLatest(Actions.FETCH_CITIES_REQUEST, fetchCities);
}

export default function* list() {
  yield all([fork(watchFetchCities)]);
}

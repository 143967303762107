import { all, fork } from 'redux-saga/effects';
import consultants from './consultants';
import slots from './slots';
import create from './create';
import bookings from './bookings';
import cancel from './cancel';

export default function* consultation() {
  yield all([
    fork(consultants),
    fork(slots),
    fork(create),
    fork(bookings),
    fork(cancel),
  ]);
}

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* newsLetterSignUp({ data, successCallback, failureCallback }) {
  try {
    const response = yield call(api.newsLetterSignUp, data);
    const json = response?.data;
    if (json?.status === 201) {
      yield put(Actions.newsLetterSignUpSuccess(json));
      if (typeof successCallback === 'function') {
        yield call(successCallback, json);
      }
    } else {
      yield put(Actions.newsLetterSignUpFail(response));
      if (typeof failureCallback === 'function') {
        yield call(failureCallback, json?.message);
      }
    }
  } catch (error) {
    yield put(Actions.newsLetterSignUpFail(error));
    if (typeof failureCallback === 'function') {
      yield call(
        failureCallback,
        'Failed to subscribe newsletter, please try again later'
      );
    }
  }
}

function* watchNewsLetterSignUp() {
  yield takeLatest(Actions.NEWSLETTER_SIGNUP_REQUEST, newsLetterSignUp);
}

export default function* create() {
  yield all([fork(watchNewsLetterSignUp)]);
}

import React, { useState, useEffect } from 'react';
import useQuery from 'hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import PropTypes from 'prop-types';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CONFIG from 'utils/config';
import { Formik, Form } from 'formik';
import { ErrorContainer } from 'components/UI';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import notify from 'utils/notification';
import Activation from './Activation';
import AuthLayout from '../common/AuthLayout';
import Review from './Review';
import SuccessScreen from './SuccessScreen';

const steps = ['Choose Payment', 'Review Payment'];
const genericError = 'Something Went Wrong. Please try again';

const ActivationSchema = [
  Yup.object().shape({
    promo: Yup.string(),
    agree: Yup.bool().oneOf([true], 'Must agree to Terms and Conditions'),
  }),
  Yup.object().shape({
    permission: Yup.bool().oneOf([true], 'Must authorise payment'),
  }),
];

const ActivationForm = ({
  selectedTier,
  createUser,
  navigateBack,
  email,
  liteOnly,
  referralCode,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    tier,
    email: user,
    inviteCode,
    revised,
    completePayment,
  } = useQuery();
  const isDowngraded = revised === 'true';
  const isCompletePayment = completePayment === 'true';
  const userEmail = isCompletePayment ? user : email;
  const profilePath = '/profile';

  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const currentValidationSchema = ActivationSchema[activeStep];

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [selectedPlan, setPlan] = useState(null);
  const [validPromo, setValidPromo] = useState(null);

  const tierInfo = useSelector(Selectors.memberTier);
  const setupIntentData = useSelector(Selectors.getSetupIntent);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_MEMBER_TIER_REQUEST])
  );
  const isLoadingPromoCode = useSelector(
    Selectors.createLoadingSelector([Actions.VALIDATE_PROMO_CODE_REQUEST])
  );

  useEffect(() => {
    dispatch(Actions.fetchMemberTier(selectedTier || tier));
  }, [dispatch, selectedTier, tier]);

  useEffect(() => {
    if (!isEmpty(tierInfo)) setPlan(tierInfo?.prices[0]?.id);
  }, [tierInfo]);

  const downgradeSubscription = () => {
    setProcessing(true);
    dispatch(
      Actions.downgradeSubscription(
        {
          price_id: selectedPlan,
          promo_id: validPromo?.id,
          email: user,
        },
        {
          success: () =>
            navigate(`/create-password?email=${user}&inviteCode=${inviteCode}`),
          failure: (error) => {
            setError(error?.error?.message || genericError);
            setProcessing(false);
          },
        }
      )
    );
  };

  const updateSubscription = (paymentMethod) => {
    setProcessing(true);
    dispatch(
      Actions.updateSubscription(
        {
          price_id: selectedPlan,
          promo_id: validPromo?.id,
          email: userEmail,
          payment_method: paymentMethod,
        },
        {
          success: () => {
            notify(`Payment complete! Please proceed to login`);
            navigate(
              `/login?completedPayment=true&redirectPath=${profilePath}`
            );
          },
          failure: (error) => {
            setError(error?.error?.message || genericError);
            setProcessing(false);
          },
        }
      )
    );
  };

  const handleStripeSetupIntent = ({ secret, customer }) =>
    stripe
      .confirmCardSetup(secret, {
        payment_method: { card: elements.getElement(CardElement) },
      })
      .then((result) => {
        if (result.error) {
          setError(result.error?.message || genericError);
          setProcessing(false);
        } else {
          // eslint-disable-next-line camelcase
          const paymentMethod = result?.setupIntent?.payment_method;
          if (isCompletePayment) return updateSubscription(paymentMethod);

          createUser({
            price_id: selectedPlan,
            promo_id: validPromo?.id,
            email,
            stripe_customer_id: customer,
            payment_method: paymentMethod,
          });
        }
      });

  const saveCreditCard = () => {
    setProcessing(true);
    if (!stripe || !elements) return setProcessing(false);
    if (!isEmpty(setupIntentData))
      return handleStripeSetupIntent(setupIntentData);
    dispatch(
      Actions.setupIntent({ email: userEmail }, handleStripeSetupIntent)
    );
  };

  function handleOnSubmit({ promoCode }, actions) {
    function nextStep() {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
    setError(null);

    if (isLastStep)
      return isDowngraded ? downgradeSubscription() : saveCreditCard();

    if (activeStep === 0) {
      if (promoCode !== '') {
        dispatch(
          Actions.validatePromoCode(
            { code: promoCode, price_id: selectedPlan },
            {
              success: (data) => {
                setValidPromo(data);
                nextStep();
              },
              failure: (error) => {
                if (error?.response?.status === 404) {
                  setError('Invalid promo code');
                } else setError(error?.response?.data?.error || error?.message);
              },
            }
          )
        );
      } else {
        nextStep();
        setValidPromo({});
      }
    }
  }

  function renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Activation
            tierInfo={tierInfo}
            setPlan={setPlan}
            selectedPlan={selectedPlan}
            isLoading={isLoading}
            isLoadingPromoCode={isLoadingPromoCode}
            navigateBack={navigateBack}
            liteOnly={liteOnly || isDowngraded}
            referralCode={referralCode}
          />
        );
      case 1:
        return (
          <Review
            promoDetails={validPromo?.coupon}
            back={() => setActiveStep(activeStep - 1)}
            setError={setError}
            error={error}
            processing={processing}
            pricePlan={tierInfo?.prices?.find(
              (price) => price.id === selectedPlan
            )}
            tier={tierInfo}
            isDowngraded={isDowngraded}
            isCompletePayment={isCompletePayment}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  if (activeStep === steps.length)
    return (
      <AuthLayout showBg disableScroll>
        <SuccessScreen />
      </AuthLayout>
    );

  return (
    <AuthLayout disableScroll>
      <Formik
        initialValues={{ promoCode: '', agree: false, permission: false }}
        validationSchema={currentValidationSchema}
        onSubmit={handleOnSubmit}
      >
        <Form>
          {renderStepContent(activeStep)}
          <ErrorContainer className="max-w-120 mx-auto mt-4" error={error} />
        </Form>
      </Formik>
    </AuthLayout>
  );
};

ActivationForm.propTypes = {
  selectedTier: PropTypes.number,
  createUser: PropTypes.func,
  navigateBack: PropTypes.func,
  email: PropTypes.string,
  liteOnly: PropTypes.bool,
  referralCode: PropTypes.string,
};

ActivationForm.defaultProps = {
  createUser: null,
  navigateBack: null,
  selectedTier: null,
  email: null,
  liteOnly: false,
  referralCode: null,
};

const stripePromise = loadStripe(CONFIG.STRIPE_PUB_KEY);

const Activate = (props) => (
  <Elements stripe={stripePromise}>
    <ActivationForm {...props} />
  </Elements>
);

export default Activate;

import { takeLatest, all, fork, put, call, select } from 'redux-saga/effects';
import Actions from 'actions';
import Selectors from 'selectors';
import api from 'api';
import { isEmpty } from 'lodash';
import { setCacheExp } from 'utils/dataCache';
import { paginationHelper } from 'utils/helper';

function* fetchPerks({ params }) {
  try {
    let exp = yield select(Selectors.getPerksCacheExp);

    if (isEmpty(params) && new Date().getTime() < exp) {
      const data = yield select(Selectors.getPerks);
      yield put(Actions.fetchPerksSuccess(data, exp));
    } else {
      const { data, headers } = yield call(api.fetchPerks, params);
      const pageMeta = paginationHelper(headers);
      exp = isEmpty(params) ? setCacheExp(15) : null;
      yield put(Actions.fetchPerksSuccess(data, exp, pageMeta));
    }
  } catch (error) {
    yield put(Actions.fetchPerksFail(error));
  }
}

function* watchFetchPerks() {
  yield takeLatest(Actions.FETCH_PERKS_REQUEST, fetchPerks);
}

export default function* list() {
  yield all([fork(watchFetchPerks)]);
}

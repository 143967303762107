const NAME = 'CONSULTATION/CREATE_BOOKING_';

export const CREATE_BOOKING_REQUEST = `${NAME}REQUEST`;
export const CREATE_BOOKING_SUCCESS = `${NAME}SUCCESS`;
export const CREATE_BOOKING_FAIL = `${NAME}FAIL`;

export const createBooking = (data, callbacks) => ({
  type: CREATE_BOOKING_REQUEST,
  data,
  callbacks,
});

export const createBookingSuccess = (data) => ({
  type: CREATE_BOOKING_SUCCESS,
  data,
});

export const createBookingFail = (error) => ({
  type: CREATE_BOOKING_FAIL,
  error,
});

import React from 'react';
import styled from '@emotion/styled';

const TextsContainer = styled.article`
  h5 {
    font-size: 24px;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  p,
  strong {
    margin-bottom: 1rem;
  }
  strong {
    display: block;
    font-size: 18px;
  }
  ol {
    margin-left: 2rem;
    margin-bottom: 1rem;
    list-style-type: lower-roman;
    ol {
      margin-left: 4rem;
      list-style-type: lower-alpha;
    }
  }
`;

export const QUESTIONS = [
  {
    parent: 'General',
    date: 'These Terms are last updated on 15 June 2023.',
    children: [
      {
        id: 1,
        question: 'The Contractual Relationship',
        answer: () => (
          <TextsContainer>
            <p>
              These Terms of Use (“<b>Terms</b>”) govern the access and/or use
              by you, from within any country, all content, products and/or
              services (the “<b>Services</b>&quot;), made available on our
              Website <a href="/">www.giglifepro.com</a> (the “<b>Website</b>”),
              and/or mobile applications which we may operate from time to time,
              which includes the GLP application (“GLP App”), whether downloaded
              through Apple’s App Store or Google LLC’s (“Google”) Google Play
              Store (collectively, “Application Stores”), whether as a guest or
              registered user or a service provider. The Website and the mobile
              applications shall hereafter be referred to individually as
              “Platform” and collectively as “Platforms”. The access and usage
              of the Services and the the Platforms have been made available by
              and through Gig Life Pro Pte Ltd (“<b>GLP</b>
              ”), a private limited liability company established under the laws
              of Republic of Singapore.
            </p>
            <strong>
              Kindly carefully read the Terms herein before proceeding to access
              and/or use the Services and/or any of the Platforms.
            </strong>
            <p>
              Your access and use of the Services and/or the Platforms
              constitute your agreement to be bound by the Terms, which stands
              as a contractual relationship between you and GLP.
              <br />
              <br />
              If you do not agree to these Terms, you should not access or use
              the Services and/or the Platforms. The Terms herein do hereby
              expressly supersede any prior agreements or arrangements with you.
              GLP reserves all rights to immediately terminate these Terms
              and/or your access and/or usage of any of the Services and/or the
              Platforms, including those available to you as being a guest or a
              registered user or a service provider of the Services and/or the
              Platforms, or generally cease its offering or deny access to the
              Services and/or the Platforms at any time for any reason, with due
              notice provided.
              <br />
              <br />
              GLP reserves the right to offer you, at any time, such
              supplemental terms as may be necessary and/or apply to certain
              Services and/or the Platforms, and such supplemental terms will be
              disclosed to you in connection with the applicable Services and/or
              the Platforms. Supplemental terms are an addition to, and shall be
              deemed a part of, the Terms for the purposes of the applicable
              Services. Supplemental terms shall prevail over these Terms in the
              event of a conflict with respect to the applicable Services and/or
              the Platforms.
              <br />
              <br />
              GLP reserves all rights to amend the Terms related to the Services
              and/or the Platforms from time to time, with such due notice
              provided. Such amended Terms will be effective upon the posting of
              the updated Terms. Your continued access and/or use of the
              Services and/or the Platforms, after such posting occurs,
              constitutes your consent to be by bound the updated Terms as
              amended.
              <br />
              <br />
              Our collection and use of personal information or personal data,
              in connection with the Services and/or the Platforms, is as
              provided in the Privacy Policy Notice which can be accessed at{' '}
              <a href="/privacy">www.giglifepro.com/privacy</a> (“
              <b>Privacy Policy Notice</b>”).
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 2,
        question: 'The Services and The Platforms',
        answer: () => (
          <TextsContainer>
            <strong>Services</strong>
            <p>
              The <b>Services</b> involve an ever-expanding business model of
              connecting music industry professionals and hosting mixers and
              events (e.g. representatives of labels, agents, promoters,
              publishers, public relation &amp; marketing, platform or venue
              providers, and etc.) (hereafter referred to as “
              <b>Industry Professionals</b>”) on the Platforms or at music
              festivals across Asia and providing consulting services. The
              facilitation of the Services shall be conducted through the
              Platforms.
              <br />
              <br />
              The Services varies according to each member’s membership tier
              (e.g. either a “Free” member, “Premium” member or “All Access”
              member) and include, amongst others, the following:
            </p>
            <ol className="list-inside" type="i">
              <li>online platform to set up user profiles;</li>
              <li>consulting for industry professionals</li>
              <li>creating fun city guides of activities and F&amp;B;</li>
              <li>invitations to key events; and</li>
              <li>
                work with Creative Agencies to source music/artists for synch
                licensing and brand activations.
              </li>
            </ol>
            <strong>Platform</strong>
            <p>
              The Platform is used to facilitate access to the Services.
              <br />
              <br />
              All data and information contained about and/or related to the
              Services on the Platform shall not give rise to any commitment
              and/or obligation which is not specifically stipulated in the
              Terms herein.
              <br />
              <br />
              Any materials posted about the Services through any of the
              Platforms are at no material time to be construed or considered as
              advice on which reliance should be placed.
            </p>
            <strong>License</strong>
            <p>
              Subject to your compliance with the Terms, GLP grants you a
              limited, non-exclusive, non-sublicensable, revocable,
              non-transferrable license to:
            </p>
            <ol className="list-inside" type="i">
              <li>
                access and use the Services and the Platforms on your personal
                device solely in connection with your use of the Services and/or
                the Platforms; and
              </li>
              <li>
                access and use any content, information and related materials
                that may be made available through the Services and/or the
                Platforms, in each case solely for your personal, non-commercial
                use, subject at all material times to permissions granted by GLP
                and/or its licensors. Any rights not expressly granted herein
                are expressly reserved by GLP and/or and of its affiliated or
                related licensors.
              </li>
            </ol>
            <strong>Restrictions or Prohibited Conduct</strong>
            <p>
              You agree that you will comply with the Terms herein and you will
              not:
            </p>
            <ol className="list-inside">
              <li>
                use the Services and/or the Platforms for any purpose(s) which
                may:
                <ol>
                  <li>
                    breach any law, statute, rule, regulation or by-law of the
                    Republic of Singapore or any other applicable jurisdiction;
                  </li>
                  <li>be fraudulent, criminal or unlawful in any way;</li>
                  <li>be inaccurate or out-of-date;</li>
                  <li>
                    create, upload, transmit, distribute, or store any content
                    that is inaccurate, unlawful, infringing, obscene, indecent,
                    pornographic, vulgar, profane, racist, sexist,
                    discriminatory, offensive, derogatory, harmful, harassing,
                    threatening, embarrassing, malicious, abusive, defamatory,
                    untrue or otherwise objectionable;
                  </li>
                  <li>
                    intentionally, knowingly or unknowingly impersonate any
                    other person, entity or body; falsely claim an affiliation
                    with, or misrepresent a relationship with, any person,
                    entity or body; forge another person’s digital signature;
                    misrepresent the source, identity, or content of information
                    transmitted via any of the Platforms; or perform any other
                    similar fraudulent activity;
                  </li>
                  <li>
                    be contrary to any specific rule or requirement that is
                    stipulated on the Services and/or the Platforms (whether it
                    be in relation to a particular part or in general);
                  </li>
                  <li>
                    send junk mail or spam to other users or members of the
                    Platforms, including without limitation unsolicited
                    advertising, promotional materials, or other solicitation
                    material; bulk mailing of commercial advertising, chain
                    mail, informational announcements, charity requests,
                    petitions for signatures, or any of the preceding things
                    related to promotional giveaways (such as raffles and
                    contests); and other similar activities;
                  </li>
                  <li>
                    harvest or collect email addresses or other contact and/or
                    personal information of other users or members of the
                    Platform without their consent;
                  </li>
                  <li>
                    defame, harass, abuse, threaten, or defraud other users or
                    members of the Platform; and
                  </li>
                  <li>
                    involve your use, delivery or transmission of any viruses,
                    unsolicited emails, ‘trojan horses’ or any computer
                    programming routines that are intended and/or for the
                    purpose of damaging, detrimentally interfering with,
                    surreptitiously intercepting, or expropriate any system,
                    data, personal information or any user’s enjoyment of the
                    Services and/or the Platforms.
                  </li>
                </ol>
              </li>
              <li>
                reverse engineer, decompile, disassemble, or otherwise attempt
                to discover the source code of the Platforms or any part
                thereof; remove any copyright, trademark and/or any other IP
                Rights (as defined below), or other proprietary notices from any
                portion of the Services and/or any of the Platforms;
              </li>
              <li>
                reproduce, modify, adapt, translate, prepare derivative works
                based upon, distribute, license, lease, sell, resell, transfer,
                publicly display, publicly perform, transmit, stream, broadcast,
                or otherwise exploit any of the Services and/or any of the
                Platforms except as expressly permitted by GLP;
              </li>
              <li>
                delete, remove, circumvent, disable, damage, or otherwise
                interfere with (a) security-related features of the Platforms,
                (b) features that prevent or restrict use or copying of any
                content accessible through the Platforms, (c) features that
                enforce limitations on the use of the Platforms, or (d) the
                copyright or other proprietary rights notices on the Platforms;
              </li>
              <li>
                link to, mirror or frame any portion of the Services and/or any
                of the Platforms, without express permission by GLP;
              </li>
              <li>
                attempt to indicate in any manner, without our prior written
                permission, that you have a relationship with GLP or that GLP
                have endorsed you or any products or services for any purpose;
              </li>
              <li>
                use or attempt to use another user’s account without
                authorization from that user and GLP;
              </li>
              <li>
                relay email from a third party’s mail servers without the
                permission of that third party; manipulate identifiers in order
                to disguise the origin of user content transmitted through the
                Platforms;
              </li>
              <li>
                cause to be launched or launch any programs or scripts for the
                purpose of indexing, surveying or otherwise data mining any
                portion of the Services and/or the Platforms or unduly burdening
                or hindering the operation and/or functionality of any aspect of
                the Services and/or the Website;
              </li>
              <li>
                attempt to circumvent any content filtering techniques we
                employ, or attempt to gain unauthorised access to or impair any
                aspect of the Services and/or the Platforms and/or its related
                systems or networks that you are not authorized to access;
                and/or
              </li>
              <li>
                interfere with or disrupt the Services and/or the Platforms or
                servers or networks connected to the Platforms, or disobey any
                requirements, procedures, policies, or regulations of networks
                connected to the Platforms; use the Platforms in any manner that
                could interfere with, disrupt, negatively affect, or inhibit
                other users from fully enjoying the Services and/or the
                Platforms, or that could damage, disable, overburden, or impair
                the functioning of the Services and/or the Platforms in any
                manner.
              </li>
            </ol>
            <p>
              In addition, you agree that the entire risk arising out of your
              use of the Services remains solely with you, to the full extent
              permitted under the laws of Republic of Singapore.
            </p>
            <strong>Access to the Services and the Platforms</strong>
            <strong>Access</strong>
            <p>
              GLP shall not be responsible and/or liable if the Services and/or
              any of the Platforms is unavailable for any time or for any
              period.
              <br />
              <br />
              On occasion, GLP may need to carry out routine repairs,
              maintenance or introduce new facilities and functions to the
              Services and/or the Platforms. You shall be notified of such
              occasions and are requested to bear with any downtime until the
              completion of such routine activities.
              <br />
              <br />
              At any material time, GLP reserves all rights to make changes
              and/or updates as it deems fit without requiring any further
              consent from and/or notice to you.
            </p>
            <strong>
              Customer Support, Information, Questions and Complaints
            </strong>
            <p>If you:</p>
            <ol className="list-outside">
              <li>
                wish to complain about any content or information, including
                feedback related to the Services and/or the Platforms which are
                submitted, uploaded, published or otherwise made available
                through the Services and/or the Platforms or the Services
                itself;
              </li>
              <li>
                have any questions concerning the Services, Platforms or these
                Terms; or
              </li>
              <li>have any other issue you wish to raise with us,</li>
            </ol>
            <p>please contact us at the following contact information:</p>
            <div>
              <p className="font-bold">
                Name: <span className="font-normal"> Gig Life Pro Pte Ltd</span>
              </p>
              <p className="font-bold">
                Address:{' '}
                <span className="font-normal">
                  1090 Lower Delta Rd, #04-16,
                  <br />
                  Tiong Bahru Industrial Estate,
                  <br />
                  Singapore 169201
                </span>
              </p>
              <p className="font-bold">
                Telephone number:
                <span className="font-normal"> +65 63 758003</span>
              </p>
              <p className="font-bold">
                Email-address:
                <span className="font-normal"> general@giglifepro.com</span>
              </p>
            </div>
            <strong>Third Party Services and Content</strong>
            <p>
              The Services and/or any of the Platforms may be made available or
              accessed in connection with third party services and content
              (including advertising) that GLP does not control. Accordingly,
              you acknowledge that different terms of use and privacy policies
              may apply to your access and/or use of such third party services
              and content. Unless expressly agreed or stated otherwise, GLP does
              not endorse nor control such third party services and content and
              in no event shall GLP be responsible or liable for any products or
              services of such third parties.
            </p>
            <p>
              If you have downloaded the Services from Apple Inc.’s (``Apple``)
              App Store or if you are accessing and using the Services on any
              device utilising Apple’s iOS operating system or iOS device, you
              acknowledge that you have read, understood, and agree to the
              following notice regarding Apple:
            </p>
            <ol className="list-outside">
              <li>
                these Terms are solely between you and GLP only, not with Apple,
                and Apple is not responsible for the Services or content
                thereof;
              </li>
              <br />
              <li>
                the license granted to you hereunder is limited to a personal,
                limited, non-exclusive, non-transferable right to use the
                Services on the Apple device(s) authorized by Apple that you own
                or control for personal, non-commercial use and your use of the
                Services must comply with Apple’s Media Services Terms and
                Conditions (“Apple T&Cs”), irrespective of any access or use of
                the Services and/or the Platforms by other accounts associated
                with your own via:
                <ol>
                  <br />
                  <li>
                    our corporate membership feature that allows for volume
                    purchasing and distribution within an organisation; or
                  </li>
                  <br />
                  <li>
                    any access sharing feature between multiple users, e.g.
                    Apple Inc`s Family Sharing feature or any similar
                    functionality provided by Apple;
                  </li>
                </ol>
              </li>
              <br />
              <li>
                Apple has no obligation whatsoever to furnish any maintenance
                and support services with respect to the Services, and GLP shall
                be solely responsible for providing any maintenance and support
                services with respect to the Services;
              </li>
              <br />
              <li>
                in the event of any failure of the Service to conform to any
                applicable warranty, you may notify Apple and Apple will refund
                the applicable purchase price for the Services to you; and, to
                the maximum extent permitted by applicable law, Apple has no
                other warranty obligation whatsoever with respect to the
                Services and any other claims, losses, liabilities, damages,
                costs or expenses attributable to any failure to conform to any
                warranty will be solely governed by these Terms;
              </li>
              <br />
              <li>
                Apple is not responsible for addressing any claims by you or any
                third party relating to the Services or your possession, access
                or use of the Services, including:
                <ol>
                  <br />
                  <li>product liability claims;</li>
                  <br />
                  <li>
                    any claim that the Services fail to conform to any
                    applicable legal or regulatory requirement;{' '}
                  </li>
                  <br />
                  <li>
                    claims arising under consumer protection, privacy or similar
                    legislation; and
                  </li>
                  <br />
                  <li>
                    claims with respect to intellectual property infringement.
                  </li>
                </ol>
              </li>
              <br />
              <li>
                in the event of any third party claims that the Services or your
                possession and use of the Services infringes such third party’s
                intellectual property rights, Apple is not responsible for the
                investigation, defense, settlement, and discharge of any
                third-party claim that the Services or your possession and use
                of the Services infringe that third party`s intellectual
                property rights, and GLP, not Apple, will be solely responsible
                for the investigation, defense, settlement and discharge of any
                such intellectual property infringement claim to the extent
                required by these Terms;
              </li>
              <br />
              <li>
                notwithstanding any other representations and warranties made by
                you under these Terms, you further represent and warrant that:
                <ol>
                  <br />
                  <li>
                    you are not located in a country that is subject to a United
                    States of America (“U.S”) Government embargo, or that is
                    designated by the U.S. Government as a “terrorist
                    supporting” country; and
                  </li>
                  <br />
                  <li>
                    you are not listed on any U.S. Government list of prohibited
                    or restricted parties;
                  </li>
                </ol>
              </li>
              <br />
              <li>
                you agree to comply with any and all applicable third-party
                terms, when using the Services; and
              </li>
              <br />
              <li>
                Apple, and Apple`s subsidiaries, are third-party beneficiaries
                of these Terms, and upon your acceptance of these Terms, Apple
                will have the right (and will be deemed to have accepted the
                right) to enforce these Terms against you as a third-party
                beneficiary of these Terms.
              </li>
            </ol>
            <strong>Ownership</strong>
            <p>
              The Services and/or the Platforms and all rights therein are and
              shall remain the property of GLP and/or its licensors. Neither the
              Terms nor your use of the Services and/or any of the Platforms
              convey or grant you any rights:
            </p>
            <ol className="list-inside" type="i">
              <li>
                in or related to the Services and/or the Platforms except for
                the limited license granted above; or
              </li>
              <li>
                to use or reference in any manner{' '}
                <a href="/">www.giglifepro.com</a> and/or GLP’s company names,
                logos, products and service names, trademarks or service marks
                of those, or those of GLP’s licensors.
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 3,
        question: 'Your Access and Use of the Services and the Platforms',
        answer: () => (
          <TextsContainer>
            <strong>Registration of Member Accounts</strong>
            <p>
              To access and/or use most aspects of the Services and/or the
              Platform, you would need to become a registered member by
              subscribing to our mailing list and maintain an active profile
              account (the “<b>Account</b>”). Application for account
              registration requires you to submit:
            </p>
            <ol className="list-inside" type="i">
              <li>
                certain personal information such as your name, email address,
                telephone number, date of birth, location, interests, as well as
                any other information which may be necessary; and
              </li>
              <li>
                bank account details for at least one valid debit or credit card
                for processing of the Charges (as defined below).
              </li>
            </ol>
            <p>
              Upon approval of your registration of an Account with the
              Platform, you are required to create a username and a password.
              The password must remain confidential. The only occasion on which
              you shall be asked for your password is part of the login process
              into any of the Platform. Should there be any unauthorised access
              or usage of your password or the Account or your email address, or
              any other such breach of security known to you, you shall
              immediately notify GLP of such incident by emailing our Data
              Protection Officer at dpo@giglifepro.com.
            </p>
            <p>When you create an Account, you represent and warrant that:</p>
            <ol>
              <li>
                if you are an individual, you are at least 18 years of age;
              </li>
              <li>
                if you are representing a company, organisation or any other
                legal entity (“Entity”), you have authority to bind the Entity
                to these Terms;
              </li>
              <li>
                you are capable of entering into and performing legally binding
                contracts under any applicable law; and
              </li>
              <li>
                all information which you provide is accurate, up to date,
                truthful and complete.
              </li>
            </ol>
            <p>
              If you are under 18 years of age, you may only use the Services
              with the consent of and under the supervision of your parent or
              legal guardian who shall be responsible for all your activities.
              <br />
              <br />
              You also agree to maintain accurate, complete and up-to-date
              information in your Account. Your failure to maintain accurate,
              complete, and up-to-date Account information, including having an
              invalid or expired payment method on file, may result in your
              inability to access and use the Services and/or the Platforms or
              GLP’s termination of the Terms with you.
              <br />
              <br />
              You are responsible for all activities and transactions under your
              Account, and you agree to maintain the security and secrecy of
              your Account, username and password at all times.
              <br />
              <br />
              By submitting your personal information to register an Account,
              you acknowledge your agreement and acceptance of the Privacy
              Policy and thereby expressly consent to authorising us to process
              your personal data for such purposes as set out in the{' '}
              <a href="/privacy">Privacy Policy Notice</a>.
              <br />
              <br />
              All information relating to the Services will be available,
              accessible and retrievable upon logging in to your Account. You
              will also have the opportunity to manage and save contact details
              and address information.
            </p>
            <strong>Approval of Members Accounts</strong>
            <p>
              During registration and upon accessing your Account, there shall
              be an option to sign up and receive, and to opt out of receiving,
              information emails (such as newsletter, information on offers, and
              the like). There is always the option to unsubscribe from such
              information emails at any time by opting out in the Account
              settings.
            </p>
            <strong>Refusal of Registration of Members Accounts</strong>
            <p>
              GLP shall be entitled to refuse or not approve any application for
              registration of an Account if (a) such applicant has not met the
              minimum criteria stipulated for the type of Account applied for
              and/or (b) GLP deems that such applicant is ineligible or
              unsuitable to be registered as a Member. Notwithstanding the
              foregoing, GLP reserves all rights to refuse or not approve any
              application without assigning any reasons whatsoever.
            </p>
            <strong>Closure of Members Accounts</strong>
            <p>
              GLP reserves all rights to close your Account and cease permitted
              access and/or usage of the Platforms and/or the Services if any
              disruptive activity on the Platforms or related and/or connected
              to the Services is detected, including but not limited to:
            </p>
            <ol>
              <li>
                wrongful or fraudulent registration of an Account using
                another’s email address or mobile number, without their consent
                or permission; and/or
              </li>
              <li>
                willful misconduct and/or breach of any term contained herein.
              </li>
            </ol>
            <p>
              In the event you choose to cancel or terminate your Account in
              accordance to Clause 5 hereinbelow, your access to your Account
              shall be discontinued, disabled or denied at the end of the
              prescribed notice period.
            </p>
            <strong>Guest Access</strong>
            <p>
              Your access to the Services and/or the Platforms as a guest shall
              be limited, insofar as you are able to browse and peruse the
              Services available and glimpse into the functionality of the
              Platforms, but having no permission rights to utilise the
              Services, without first signing up as a registered member and
              maintaining an Account.
              <br />
              <br />
              Notwithstanding your limited access to the Services and/or
              Platforms, GLP shall hold you responsible and/or liable to adhere
              to all the applicable terms and conditions herein contained in
              your browsing and perusing of the Services and glimpse into the
              functionality of the Platforms, and any breach thereof shall hold
              you responsible and/or liable in the same manner and category as
              if you were a registered member maintaining an account.
            </p>
            <strong>Free Trials</strong>
            <p>
              Provided that you qualify under the eligibility conditions listed
              below, we offer a trial period for you to become a registered
              member with an Account (“<b>Free Trial</b>”), which entitles you
              to access the Services on the Platforms as a registered member
              without charge for an initial three (3) month trial period (“
              <b>Free Trial Period</b>”) from the moment that your Account is
              approved by GLP with the Free Trial activated.
            </p>
            <p>GLP reserves the right to</p>
            <ol>
              <li>
                reject or refuse your eligibility for a Free Trial without
                assigning any reasons whatsoever; and
              </li>
              <li>
                modify or earlier terminate a Free Trial at any time without
                assigning any reasons whatsoever.
              </li>
            </ol>
            <p>
              GLP shall not be obligated to accept any further attempts by you
              to take up the Free Trial.
            </p>
            <p>
              <b>
                After the Free Trial Period, you will automatically be charged
                and the then-current monthly price for the Account and the
                payment method which you provided in the setting up of the
                Account will automatically be charged that amount unless you
                cancel your account subscription and/or membership at least
                thirty (30) days prior to the end of the Free Trial Period.
              </b>
            </p>
            <p>
              <b>
                To cancel, you must log into your Account and follow the prompts
                on the Subscription page of your Account.
              </b>
            </p>
            <p>
              In order to be eligible for this Free Trial, you must satisfy the
              following conditions (“<b>Eligible User</b>”):
            </p>
            <ol>
              <li>
                you must be a new user who is trying to register a new Account;
              </li>
              <li>
                you must enter a valid promotional code for the Free Trial in
                the relevant section where you are prompted to enter the
                promotional code; and
              </li>
              <li>
                you must provide valid and current payment details during the
                registration of your Account.
              </li>
            </ol>
            <p>
              Eligible Users may only redeem the Free Trial once and may not
              redeem the Free Trial again after the Free Trial Period has
              expired.
            </p>

            <strong>Redemption of Events and Perks</strong>
            <p>
              GLP may at its absolute discretion, from time to time offer
              certain events and/or perks for redemption by its members on the
              Platform and may assign different privileges according to the
              membership subscription tiers for certain events and/or perks. For
              instance, an “All Access” member may not be required to pay
              additional fees to redeem an event but the “Premium” member and
              “Free” member may be required to pay additional fees to redeem the
              same event.
            </p>

            <p>
              GLP may, at its absolute discretion, impose eligibility
              requirements for purposes of redemption of any event and/or perk,
              which includes but is not limited to imposing a 3- month
              membership requirement of a certain membership subscription tier
              (which requirement may be subject to change from time to time at
              the absolute discretion of GLP), before such member is eligible to
              redeem the event and/or perk.
            </p>

            <p>
              Should you not meet the requirements imposed for purposes of
              redemption of the event and/or perk, a push notification will
              appear informing you of the same and in certain circumstances, you
              may be offered the option to pay a fee or make an upgrade in
              membership tier on an annual basis to redeem the particular event
              and/or perk.
            </p>

            <p>
              Should a member downgrade their membership tier at any point
              before the redeemed event and/or perk is due to take place, such
              redemption will automatically be cancelled and GLP shall be held
              harmless and not be made liable for any costs and/or damages that
              you may suffer as a result of such cancellation.
            </p>

            <p>
              All events and/or perks offered on the Platform shall be subject
              to availability, on a first come first serve basis. All event
              spots and/or perks redeemed cannot be (i) purchased, sold,
              transferred or redeemed for cash by the member or (ii) redeemed
              for use by another person which is not the member making the
              redemption (unless otherwise provided by GLP).
            </p>

            <strong>User Provided Content</strong>
            <p>
              GLP , in its sole discretion, may permit you to submit, upload,
              publish or otherwise make available through the Services and/or
              the Platforms content or information, including feedback related
              to the Services and/or the Platforms, any User Content. By
              submitting such user content, you grant GLP a worldwide,
              perpetual, irrevocable, transferrable, royalty-free license with
              the right to assign or sublicense, to use, copy, modify, create
              derivate works of, distribute, publicly display, publicly perform,
              and otherwise use in any manner in all formats and distribution
              channels now known or hereafter devised, without further notice to
              or consent from you, and without the requirement of payment to you
              or any other person or entity.
              <br />
              <br />
              All user content submitted through the Services and/or any of the
              Platforms shall be in accordance and shall fall subject to the
              Terms herein contained and, in particular, the “Restrictions of
              Prohibited Conduct” terms above.
              <br />
              <br />
              Any disputes with regard to infringement of any solely /
              exclusively owned IP Rights of GLP shall be settled by way of the
              dispute resolution mechanism as set out in the Terms herein below.
              <br />
              <br />
              In the event of any dispute related to the infringement of any IP
              Rights owned by GLP, any suspected, purported or alleged
              infringer, shall have his / her membership account suspended or
              frozen henceforth, without any further notice to the infringer,
              until such a time that the dispute can be resolved by way of
              amicable resolution and/or through the dispute resolution process
              as set out at the Terms herein below.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 4,
        question: 'Warranties and Guarantees',
        answer: () => (
          <TextsContainer>
            <p>GLP makes the following warranties and guarantees:</p>
            <ol className="list-inside" type="i">
              <li>
                to make best efforts and endeavours to ensure that the Services
                and/or the Platforms are error-free, containing accurate,
                complete and up-to-date information and/or data to facilitate
                your ease of use of the same;
              </li>
              <li>
                to make best efforts and endeavours to make the Services and/or
                the Platforms available 24 hours a day, 7 days a week;
              </li>
              <li>
                upon conducting any routine repairs, maintenance of upgrades,
                that it shall make best efforts and endeavours to resume regular
                or normal activity of the Services and/or the Platforms at the
                soonest time possible.
              </li>
            </ol>
            <p>
              GLP <span className="underline">does not</span> warrant and/or
              guarantee and/or waive disclaimers as follows:
            </p>
            <ol className="list-inside" type="i">
              <li>
                the Terms herein contained are intended to be construed as a
                warranty and/or a guarantee of uninterrupted, timely or
                error-free access to the Services and/or the Platforms;
              </li>
              <li>
                that the Platforms will be compatible with all hardware and
                software which you may use;
              </li>
              <li>
                to take any ownership of responsibility and/or liability for any
                viruses, unsolicited emails, ‘trojan horses’ or any computer
                programming routines that are intended and/or for the purpose of
                damaging, detrimentally interfering with, surreptitiously
                intercepting, or expropriate any system, data or personal
                information, which may directly or indirectly affect any
                computer equipment, software, data or other property as a result
                of your access and/or use of the Services and/or Platforms;
              </li>
              <li>
                to take any ownership of responsibility and/or liability for the
                actions of third parties;
              </li>
              <li>
                to take any ownership of responsibility and/or liability arising
                from any reliance placed on materials made available about
                and/or on the Services and/or the Platforms by any guest,
                registered member, professional or client, or by any other
                person who may be informed of the contents of said materials;
              </li>
              <li>
                representations, warranties or guarantees made regarding the
                reliability, timeliness, quality, suitability or availability of
                the Services and/or the Platforms, or that the Services and/or
                Platforms will be uninterrupted or error-free.
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 5,
        question: 'Payment and Updating of Membership Subscription',
        answer: () => (
          <TextsContainer>
            <p>
              By accepting the Terms herein, you accept that any engagement
              and/or use of the Services, as a Client or otherwise, may result
              in subscription fees or charges (as indicated on the Platforms) to
              you for the Services you receive from GLP upon your Account being
              approved on the Website or through the GLP App (the “Charges”).
            </p>
            <br />
            <p>
              All payment processing services for the Charges (“Payment
              Services”):
            </p>
            <br />
            <ol className="list-outside">
              <li>
                through the Website will be undertaken and facilitated by Stripe
                Payments Singapore Pte. Ltd. or its related entities responsible
                for the payment processing services (“Stripe”), and subject to
                or governed by their respective Stripe Additional Terms (as
                defined below); and
              </li>
              <br />
              <li>
                through the GLP App, will be undertaken and facilitated by
                either Apple or Google (depending on where the GLP App is
                downloaded from) or their related entities or any other
                third-party payment processors responsible for payment
                processing services on the Application Stores, and subject to or
                governed by their respective Additional Terms (as defined
                below).
              </li>
            </ol>
            <p>
              Payment of the Charges shall, for all intents and purposes, be
              considered the same as payment directly by you, as a client or
              otherwise, to GLP. The Charges will be inclusive of applicable
              taxes where required by Republic of Singapore law, such as Goods
              and Services Tax (“<b>GST</b>”). Charges paid by you are final and
              non-refundable, unless otherwise determined at the sole discretion
              of GLP, upon their joint or several agreements accordingly.
            </p>
            <br />
            <p>
              The payment structure and the applicable Charge(s) for each
              transaction of the Services purchased from GLP is intended to be
              full compensation for the Services provided. You hereby understand
              and agree that, while you are free to provide additional payment
              as a gratuity to any service provider providing you with the
              Service, you are under no obligation to do so. Gratuities are
              voluntary and exist as payment beyond the control and knowledge of
              GLP, who’s rights are hereby expressly reserved.
            </p>
            <br />
            <p>
              GLP, reserves the right to establish, remove and/or revise Charges
              for any or all services or goods obtained through the use of the
              Services at any time in its sole discretion. Further, you
              acknowledge and agree that Charges applicable may increase (or
              reduce) according to the commercial considerations of GLP.
            </p>
            <strong>Payment Service Provider</strong>
            <p>
              In order to use the Payment Services, you must provide GLP with
              accurate and complete information required for the Payment
              Services, including bank account information for at least one
              valid debit or credit card through the Website or the GLP App. You
              acknowledge and agree that GLP may use Stripe (or its related
              entities) for payment through the Website or Apple or Google (or
              their related entities) or any other third-party payment
              processors responsible for payment processing services on the
              relevant Application Stores for payment through the GLP App, to
              process payments and to manage the account information you have
              provided to uson the Website or the GLP App. You may add, delete,
              or edit the payment method / account information you have provided
              from time to time directly through the Website or the GLP App.
            </p>
            <p>
              In order to use the Payment Services, you agree to comply with the
              terms and conditions of:
            </p>
            <ol>
              <li>
                Stripe (available at:{' '}
                <a
                  href="https://stripe.com/en-sg"
                  target="_blank"
                  rel="noreferrer"
                  className="text-easter"
                >
                  www.stripe.com/en-sg
                </a>
                ), if payment is made through the Website, including but not
                limited to the terms and conditions set out in:
                <ol>
                  <br />
                  <li>
                    Stripe Connected Account Agreement (available at{' '}
                    <a
                      href="https://stripe.com/en-sg/checkout/legal"
                      target="_blank"
                      rel="noreferrer"
                      className="text-easter"
                    >
                      https://stripe.com/en-sg/connect-account/legal
                    </a>
                    );
                  </li>
                  <br />
                  <li>
                    Stripe Checkout (available at{' '}
                    <a
                      href="https://stripe.com/en-sg/connect-account/legal"
                      target="_blank"
                      rel="noreferrer"
                      className="text-easter"
                    >
                      https://stripe.com/en-sg/connect-account/legal
                    </a>
                    ); and
                  </li>
                  <br />
                  <li>
                    Stripe Customer Portal (available at{' '}
                    <a
                      href="https://stripe.com/en-sg/customer-portal/legal"
                      target="_blank"
                      rel="noreferrer"
                      className="text-easter"
                    >
                      https://stripe.com/en-sg/customer-portal/legal
                    </a>
                    );
                  </li>
                </ol>
                <p>
                  which may be modified or updated from time to time by Stripe
                  (hereafter referred to as “<b>Additional Terms</b>”) and
                </p>
              </li>
              <br />
              <li>
                Apple or Google (or their related entities) or any other
                third-party payment processors responsible for payment
                processing services on the relevant Application Stores,
                depending on where the GLP App is downloaded from, which is
                available on the respective relevant Application Stores or
                third-party payment processor’s website (“Additional Terms”).
              </li>
            </ol>
            <p>
              By using a particular payment method, you are agreeing to the
              terms of service of Stripe or Apple or Google or any third-party
              payment processor (as relevant) and your financial institution. If
              any, You will bear all fees that may be charged by Stripe, Apple,
              Google or third-party payment processor as relevant (if any)
              and/or your financial institution (if any) for the selected
              payment method.
            </p>
            <p>
              By providing the account information for a credit or debit card,
              you represent and warrant, that: (i) you are legally authorised to
              provide such information to us; (ii) you are legally authorised to
              perform payments from the account(s) you have provided to us; and
              (iii) such action does not violate the terms and conditions
              applicable to your use of such account(s) or applicable law. When
              you authorise a payment using any such account(s) via the Website
              or GLP App, you represent and warrant that there are sufficient
              funds or credit available to complete a payment using such
              account(s).
            </p>
            <p>
              You agree to authorise GLP and/or its related corporations to
              provide or give instructions on your behalf to Stripe, Apple,
              Google or third-party payment processor for processing the Payment
              Services on your behalf including, but not limited to making,
              accepting and/or refunding any payments, in accordance with the
              Terms.
            </p>
            <strong>Updating of membership</strong>
            <p>
              You may upgrade or downgrade your membership subscription tier at
              any time.
            </p>
            <ol className="list-outside">
              <li>
                All Access Member
                <p>
                  <br /> If you are an “All Access” member, you may downgrade to
                  either a “Premium” member or a “Free Member”.{' '}
                </p>
              </li>
              <li>
                Premium Member
                <p>
                  <br /> If you are a “Premium” member, you may upgrade to an
                  “All Access” member (subject always to GLP’s discretion and
                  you meeting the criteria to become a “Premium” member) or
                  downgrade to a “Free” member.
                </p>
              </li>
              <li>
                Free Member
                <p>
                  <br /> Provided that you meet all of the criteria to become a
                  “Premium” or “All Access” member (and subject always to GLP’s
                  discretion), you may upgrade from a “Free” member to either a
                  “Premium” member or “All Access” member.
                </p>
              </li>
            </ol>
            <p>
              You agree that all subscription fees applicable to your membership
              tier post-upgrade or post-downgrade as stipulated on the Platforms
              and any other applicable Charges will be charged to your Account.
            </p>
            <p>
              Any upgrades in membership shall take effect immediately and your
              Charges or prorated Charges (as applicable), whether on a monthly
              or yearly basis will be processed at the time of your upgrade.
            </p>
            <p>
              Any downgrades of memberships shall take effect upon the end of
              your billing period or the Free Trial Period (whichever is
              applicable), and you shall continue to have access to the Services
              relevant to your membership (pre-downgrade) up until the end of
              such billing period or Free Trial Period. For the avoidance of
              doubt, your subscription shall renew automatically based on the
              previous billing period selected should you fail to downgrade your
              account subscription at least thirty (30) days before the end of
              the billing period or Free Trial Period.
            </p>
            <strong>Deletion of Account</strong>
            <p>
              You may only delete your account as a “Free” member. As such, you
              agree and acknowledge that if you are an “All Access” member or
              “Premium” member, you shall be required to downgrade your
              membership tier to the “Free” member tier before having the option
              to delete your account.
            </p>
            <strong>
              Deletion of Bank Account Details for application(s) refused or not
              approved by GLP
            </strong>
            <p>
              In the event that GLP refuses or choose to not approve any
              application for registration of an Account on the Website, GLP
              shall instruct Stripe to take all reasonable measures to cease
              retention of the bank account details submitted during the
              application for registration, and such measures will be taken in
              accordance with Stripe’s Privacy Policy (available at{' '}
              <a
                href="https://stripe.com/en-sg/privacy"
                target="_blank"
                rel="noreferrer"
              >
                https://stripe.com/en-sg/privacy
              </a>
              ) and/or the <a href="/privacy">Privacy Policy Notice</a>.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 6,
        question: 'Limitation of Liability and Indemnity',
        answer: () => (
          <TextsContainer>
            <strong>Limitation of Liability</strong>
            <p>
              GLP shall not be liable for any direct or indirect, incidental,
              general, special or consequential damages, including lost profits,
              lost data, personal injury or property damage related to, in
              connection with, or otherwise resulting from any use of the
              Services, even if the possibility of such damages have already
              been advised. GLP shall not be liable for any damages, liability
              or losses arising out of:
            </p>
            <ol className="list-inside" type="i">
              <li>
                your use or reliance on the Services or your inability to access
                or use the Services; or
              </li>
              <li>
                even if GLP has been advised of the possibility of such damages;
                or
              </li>
              <li>
                delay or failure in performance resulting from causes beyond the
                control of GLP.
              </li>
            </ol>
            <p>
              Our total liability, if any, to any party concerned, including but
              not limited to you, any client, Service Provider and/or any other
              third party, shall in no circumstances exceed, in aggregate, a sum
              equal to the greater of:
            </p>
            <ol className="list-inside" type="i">
              <li>SGD500.00; and</li>
              <li>
                110% of any aggregate amount paid by you to us in the three (3)
                months preceding any cause of action arising.
              </li>
            </ol>
            <strong>Indemnity</strong>
            <p>
              You agree to indemnify and hold GLP and its officers, directors,
              employees and agents harmless from any and all claims, demands,
              losses, liabilities and expenses (including legal costs and fees)
              arising out or in connection with:
            </p>

            <ol className="list-inside" type="i">
              <li>your use of the Services;</li>
              <li>
                your breach or violation of any of the Terms herein contained;
              </li>
              <li>GLP&#39;s use of your User Content; or</li>
              <li>your violation of the rights of any third party.</li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 7,
        question: 'Intellectual Property',
        answer: () => (
          <TextsContainer>
            <strong>IP Rights</strong>
            <p>
              For the purpose of the Terms herein and/or the Privacy Policy
              Notice, IP Rights shall include a non-exhaustive list of, amongst
              others, copyrights, trademarks, patents, designs, methods,
              know-hows and processes (the “<b>IP Rights</b>”).
              <br />
              <br />
              GLP and/or its licensors are the sole owners of all IP Rights
              (whether jointly or severally) contained and/or related to and/or
              connected with the Website and all content and material contained
              therein, with the exception of User Content. All works, content
              and materials on the Website are protected by international
              copyright laws and treaties. All such rights are hereby expressly
              and impliedly reserved.
              <br />
              <br />
              No content or material contained on the Website is to be used for
              any commercial purpose and/or public circulation without the
              express permission of GLP and/or its licensors.
              <br />
              <br />
              Should there be infringement of any of the rights owned by GLP
              and/or its’ licensors, all action shall be taken to the full
              extent permitted by law to seek redress and relief for such
              infringement, including but not limited to an immediate cease
              placed on the Accounts of Clients.
              <br />
              <br />
              Any disputes with regard to infringement of any solely /
              exclusively owned IP Rights of GLP shall be settled by way of the
              dispute resolution mechanism as set out in the Terms herein below.
              <br />
              <br />
              In the event of any dispute related to the infringement of any IP
              Rights owned by GLP, any suspected, purported or alleged
              infringer, whether it be client, Service Provider or otherwise,
              shall have his / her Account suspended or frozen henceforth,
              without any further notice to the infringer, until such a time
              that the dispute can be resolved by way of amicable resolution
              and/or through the dispute resolution process as set out in the
              Terms herein below.
            </p>
            <strong>Trademarked Brand Names</strong>
            <p>
              The “GLP” and “Gig Life Pro” names and logos and all related
              names, design marks and slogans are the trademarks of GLP or its
              licensors.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 8,
        question: 'Governing Law and Jurisdiction',
        answer: () => (
          <TextsContainer>
            <p>
              The Terms and your use of the Website and/or the Services (an all
              non-contractual relationships arising out of or connected to them)
              shall be governed and construed in accordance with the prevailing
              laws of Republic of Singapore.
              <br />
              <br />
              Any disputes which may arise out of or which may be related to or
              in connection with the Services and/or the Website and/or the
              Terms shall fall under the exclusive jurisdiction of the courts of
              Republic of Singapore, for the settlement of such disputes.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 9,
        question: 'Other provisions',
        answer: () => (
          <TextsContainer>
            <strong>Notice</strong>
            <p>
              GLP may give notice, by means of general notice, on the Services
              and/or any of the Platforms and/or the Terms herein contained,
              electronic mail to your email address as registered in the Account
              or by written communication sent to your address as contained in
              the registered Account.
            </p>
            <strong>Severability</strong>
            <p>
              If at any material time, during the subsistence of the Terms,
              there are changes to laws, regulations, by-laws or otherwise,
              which may render any of the Terms illegal, invalid or otherwise
              unenforceable in any state or country in which these terms are
              intended to be effective, then to the extent and within the
              jurisdiction which that term is illegal, invalid or unenforceable,
              it shall be severed and deleted and the remaining Terms shall
              survive, remain in force and effect and continue to be binding on
              all parties herein concerned.
            </p>
          </TextsContainer>
        ),
      },
    ],
  },
  {
    parent: 'Consultation',
    date:
      'These Terms of Use of Consultation Services are last updated on 15 June 2023.',
    description: `Please read the terms of use set out herein (“Terms of Use of Consultation Services”) carefully before proceeding to access and/or use the Consultation Services (as defined below). If you do not agree to the Terms of Use of Consultation Services, you should not access or use the Consultation Services (as defined below).`,
    children: [
      {
        id: 1,
        question: 'Terms of Use of Consultation Services',
        answer: () => (
          <TextsContainer>
            <p>
              By (a) accessing and / or using the Consultation Services (as
              defined below) and Consultation Booking Service (as defined below)
              at https://giglifepro.com/consultation or the relevant section of
              the GLP APP (as defined below)(“
              <b>Consultation Booking Page</b>”) and/or (b) clicking the button
              “Confirm Booking” when making a booking on the Consultation
              Booking Page, you hereby agree to and accept the terms of use set
              out herein (“<b>Terms of Use of Consultation Services</b>”) and of
              the terms of use of our website www.giglifepro.com (
              <b>“Website</b>”) and/or mobile applications which we may operate
              from time to time, which includes the GLP Application (“
              <b>GLP APP</b>”), whether downloaded through Apple’s App Store or
              Google LLC’s (“<b>Google</b>”) Google Play Store (collectively, “
              <b>Application Store</b>”) at https://giglifepro.com/terms (“
              <b>General Terms of Use</b>”), which shall be deemed to be
              incorporated into this Terms of Use of Consultation Services.
            </p>
            <p>
              The Website and the mobile application shall hereafter be referred
              to individually as “<b>Platform</b>” and collectively as “
              <b>Platform</b>”. The access and usage of the Consultation Booking
              Page and the Platforms have been made available by and through Gig
              Life Pro Pte Ltd, a private limited liability company established
              under the laws of Republic of Singapore (collectively, “<b>our</b>
              ”, “<b>us”</b> or “<b>we”</b>).
            </p>
            <p>
              In the event of any conflict between any of the Terms of Use of
              Consultation Services and the General Terms of Use, such
              conflicting Terms of Use of Consultation Services shall prevail.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 2,
        question: 'Consultation Booking Service',
        answer: () => (
          <TextsContainer>
            <p>
              The consultation booking service offered on the Consultation
              Booking Page enables you to reserve and schedule Consultation
              Meetings (as defined below) (“<b>Consultation Booking Service</b>
              ”) offered by our consultants listed in the Consultation Booking
              Page (individually, a “<b>Consultant</b>” and collectively “
              <b>Consultants</b>”).
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 3,
        question: 'Making a Booking',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                You may book a consultation slot as a guest on the Website or as
                a registered member of any of the Platforms. When you are
                booking a consultation slot, you must provide accurate and
                complete information of the individual receiving the
                Consultation Services, including but not limited to:
                <ol>
                  <li>
                    certain personal information such as full name, e-mail
                    address (“<b>E-mail Address</b>”), link to website and link
                    to social media account as well as any other information as
                    required to complete your booking (“
                    <b>Personal Information</b>”); and
                  </li>
                  <li>
                    details of at least one valid debit or credit card for
                    processing of the Charges (as defined below).
                  </li>
                </ol>
              </li>
              <li>
                Upon approval of your booking request by us, you would be
                charged for the Charges and an e-mail confirming your booking (“
                <b>Confirmation E-mail</b>”) would be sent to the E-mail
                Address.
              </li>
              <br />
              <li>
                You agree that we are entitled, at our discretion, to reject or
                refuse your consultation booking request without assigning any
                reasons whatsoever.
              </li>
              <br />
              <li>
                By submitting the Personal Information to make a booking, you
                acknowledge your agreement and acceptance of the privacy policy
                set out at https://giglifepro.com/privacy (“
                <b>Privacy Policy Notice</b>”) and thereby expressly consent to
                authorising us to process the personal data collected for such
                purposes as set out in the Privacy Policy Notice.
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 4,
        question: 'Consultation Services Provided by Our Consultants',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                The services which will be provided by the Consultant to you
                shall be:
                <ol>
                  <li>
                    to give professional advice and / or recommendations based
                    on your responses to the questions which will be sent to
                    your E-mail Address prior to your Consultation Meeting (as
                    defined below); and
                  </li>
                  <li>
                    up to a maximum of sixty (60) minutes conference call via
                    the agreed mode of communication with you to go through the
                    Consultant’s professional advice and/or recommendations
                    (individually a <b>“Consultation Meeting”</b> and
                    collectively,
                    <b>“Consultation Meetings”</b>). For the avoidance of doubt,
                    should the Consultation Meeting end earlier than sixty (60)
                    minutes, the Consultation Meeting shall be deemed complete,
                    and you shall not be entitled to use the unutilised duration
                    out of the sixty (60) minutes at any other time (including
                    any subsequent Consultation Meetings) (“Unutilised Time”).
                    You further agree that any subsequent Consultation Meetings
                    will need to be booked through the Website or GLP App and
                    the necessary payments made, as stipulated in the “Making a
                    Booking” terms above and that you shall not be entitled to
                    any discounts or set-off as a result of the Unutilised Time,
                  </li>
                  which may vary, as the Consultant deems fit and according to
                  the circumstances of the Consultation Meeting (collectively
                  referred to as “<b>Consultation Services</b>”).
                </ol>
              </li>
              <li>
                For the avoidance of doubt, the term “<b>Services</b>” in the
                General Terms of Use includes the Consultation Services and the
                Consultation Booking Service.
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 5,
        question: 'Independent Contractor',
        answer: () => (
          <TextsContainer>
            <p>
              With respect to the Consultation Services, it is hereby agreed
              that nothing in this Terms of Use of Consultation Services
              creates, implies or evidences the relationship of employer and
              employee, or principal and agent or to constitute a partnership,
              joint venture between us and our Consultants and you.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 6,
        question: 'Pricing and Payment',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                <p>
                  The hourly rates of our Consultants shall be as listed on the
                  Consultation Booking Page.
                </p>
                <p>
                  The total consultation fees per Consultation Meeting is
                  calculated based on the hourly rates of the Consultants (“
                  <b>Consultation Fees</b>”).
                </p>
              </li>
              <li>
                <p>
                  By accepting the Terms of Use of Consultation Services, you
                  accept that you will be charged the Consultation Fees, any
                  such as the Goods and Services Tax and any other fees that may
                  be charged, as stipulated in the “Payment Service Provider”
                  section below (&quot;Charges&quot;). All payment processing
                  services for the Charges (“ <b>Payment Services</b>”)
                </p>
                <ol>
                  <li>
                    through the Website will be undertaken and facilitated by
                    Stripe Payments Singapore Pte. Ltd. or its related entitles
                    responsible for the payment processing services (
                    <b>“Stripe”</b>), and subject to or governed by their
                    respective Stripe Additional Terms (as defined below); and
                  </li>
                  <li>
                    through the GLP App, will be undertaken and facilitated by
                    either Apple or Google (depending on where the GLP App is
                    downloaded from) or theirits related entities or any
                    third-party payment processors responsible for the payment
                    processing services on the Applications Stores, and subject
                    to or governed accordingly by their respective Additional
                    Terms (as defined below).
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  Charges paid by you are final and non-refundable, unless
                  otherwise determined at our sole discretion.
                </p>
              </li>
              <li>
                We reserve the right to establish, remove and/or revise the
                Charges for any or all services or goods obtained through the
                use of the Consultation Services at any time at our sole
                discretion. Further, you acknowledge and agree that Charges
                applicable may increase (or reduce) according to our commercial
                considerations.
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 7,
        question: 'Payment Service Provider',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                <p>
                  In order to use the Payment Services, you must provide us with
                  accurate and complete information required for the Payment
                  Services, including bank account information for at least one
                  valid debit or credit card through the Website or the GLP App.
                  You acknowledge and agree that we may use Stripe (or its
                  related entities) for payment through the Website or Apple or
                  Google (or their related entities) or any other third-party
                  payment processors responsible for payment processing services
                  on the relevant Application Stores for payment through the GLP
                  App, to process payments and to manage the account information
                  you have provided on the Website or the GLP App.
                </p>
              </li>
              <li>
                <p>
                  In order to use the Payment Services, you agree to comply with
                  the terms and conditions of Stripe (available at:
                  https://stripe.com/en-sg), including but not limited to the
                  terms and conditions set out in:
                </p>
                <ol>
                  <li>
                    Stripe Connected Account Agreement (available at
                    https://stripe.com/en-sg/connect-account/legal);{' '}
                  </li>
                  <li>
                    Stripe Checkout (available at
                    https://stripe.com/en-sg/checkout/legal); and
                  </li>
                  <li>
                    Stripe Customer Portal (available at
                    https://stripe.com/en-sg/customer-portal/legal);
                  </li>
                </ol>
                <p>
                  which may be modified or updated from time to time by Stripe
                  (“<b>Additional Terms</b>”); and
                </p>
              </li>
              <li>
                <p>
                  Apple or Google (or their related entities) or any other
                  third-party payment processors responsible for payment
                  processing services on the relevant Application Stores,
                  depending on where the GLP App is downloaded from, which is
                  available on the respective relevant Application Stores or
                  third- party payment processor’s website (“Additional Terms”).
                </p>
              </li>
              <li>
                <p>
                  By using a particular payment method, you are agreeing to the
                  terms of service of Stripe or Apple or Google or any
                  third-party payment processor (as relevant) and your financial
                  institution. If any, yYou will bear all fees that may be
                  charged by Stripe, Apple, Google or third-party payment
                  processor (as relevant) and/or your financial institution (if
                  any) for the selected payment method.
                </p>
              </li>
              <li>
                <p>
                  By providing the account information for a credit or debit
                  card, you represent and warrant, that: (i) you are legally
                  authorised to provide such information to us; (ii) you are
                  legally authorised to perform payments from the account(s) you
                  have provided to us; and (iii) such action does not violate
                  the terms and conditions applicable to your use of such
                  account(s) or applicable law. When you authorise a payment
                  using any such account(s) via the Website, you represent and
                  warrant that there are sufficient funds or credit available to
                  complete a payment using such account(s).
                </p>
              </li>
              <li>
                You agree to authorise us and/or our related corporations to
                provide or give instructions on your behalf to Stripe for
                processing the Payment Services on your behalf including, but
                not limited to making, accepting and/or refunding any payments,
                in accordance with the Terms.
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 8,
        question: 'Obligations and Warranties',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                <p>Our Obligations and Warranties</p>
                <p>
                  We warrant and represent to you that we and the Consultants
                  will comply with the provisions of all applicable federal,
                  state and/or local laws, ordinances, regulations and codes (as
                  adopted or amended from time to time) applicable to the
                  Consultant’s performance of the Consultation Services under
                  this Terms of Use of Consultation Services.
                </p>
              </li>
              <li>
                <p>Your Obligations and Warranties</p>
                <p>You warrant and represent to us that:</p>
                <ol>
                  <li>
                    you are an individual of at least eighteen (18) years of
                    age;
                  </li>
                  <li>
                    you are capable of entering into and performing legally
                    binding contracts under any applicable law; and
                  </li>
                  <li>
                    all information which you provide is accurate, up to date,
                    truthful and complete.
                  </li>
                </ol>
              </li>
            </ol>
            For the avoidance of doubt, the Clause on “Warranties and
            Guarantees” in the General Terms of Use applies to this Terms of Use
            of Consultation Services.
          </TextsContainer>
        ),
      },
      {
        id: 9,
        question: 'Rescheduling or Cancellation of Consultation',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                <p>The date and time of the Consultation Meeting:</p>
                <ol>
                  <li>
                    may be rescheduled by either us, via e-mail sent to your
                    E-mail Address or you, by contacting us at
                    community@giglifepro.com, no less than 48 hours prior to the
                    Consultation Meeting; and
                  </li>
                  <li>
                    may be cancelled by either us, via e-mail sent to your
                    E-mail Address or you, contacting us at
                    community@giglifepro.com, no less than 72 hours prior to the
                    Consultation.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  In the event the Consultation Meeting is cancelled by either
                  us or you pursuant to the terms in this section, you could
                  request for a refund by contacting us at
                  finance@giglifepro.com <b>within three (3) business days</b>{' '}
                  from the date of cancellation. If you fail to request for a
                  refund within three (3) business days, GLP shall be entitled
                  to refuse processing the request for a refund.
                </p>
              </li>
              <li>
                <p>
                  Any refund payment will be returned to the original payment
                  method.
                </p>
              </li>
              <li>
                <p>
                  In the event that you commit a breach of any of the terms
                  contained in this Terms of Use of Consultation Services, we
                  may cancel your Consultation Meeting and you will not be
                  entitled to any refund arising from such cancellation.
                </p>
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 10,
        question: 'Limitation of Liability and Indemnity',
        answer: () => (
          <TextsContainer>
            <p>
              The Consultation Booking Page, the Consultation Booking Service
              and the Consultation Services are provided “as-is” and we disclaim
              any and all representations and warranties, whether express or
              implied, including implied warranties of title, merchantability,
              fitness for a particular purpose or non-infringement. We cannot
              guarantee and do not promise any specific results from use of the
              Consultation Booking Page, the Consultation Booking Service and
              the Consultation Services.
            </p>
            <p>
              We do not represent or warrant that the content of the
              Consultation Booking Page is accurate, complete, reliable, current
              or error-free or that it is free of viruses or other harmful
              components. Without limiting the foregoing, you understand and
              agree that if you download or otherwise obtain content, material
              or data from the Consultation Booking Page or through the
              Consultation Services, you do so at your own discretion and risk.
              In no event will we or our directors, officers, advisors,
              employees, contractors, affiliates or agents be liable to you or
              any third person for any indirect, consequential, exemplary,
              incidental or punitive damages, including for any lost profits or
              lost data arising from your use of the Consultation Booking Page,
              the Consultation Booking Service or the Consultation Services.
            </p>
            <p>
              For the avoidance of doubt, we shall not be liable for any direct,
              indirect, punitive, incidental, special, consequential damages or
              any damages whatsoever, including, without limitation, liability
              arising out of or in any way connected with or otherwise arising
              out of the Consultation Booking Page, the Consultation Booking
              Service or the Consultation Services, whether based on contract,
              tort, negligence, strict liability or otherwise, Under no
              circumstances will we be responsible for any loss or damage or
              personal injury or death resulting from anyone’s use of the
              Consultation Booking Page, the Consultation Booking Service or the
              Consultation Services or any interaction between users of the
              Consultation Booking Page, whether online or offline.
            </p>
            <p>
              For the avoidance of doubt, the Clause on “Limitation of Liability
              and Indemnity” in the General Terms of Use applies to this Terms
              of Use of Consultation Services.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 11,
        question: 'Ownership of Content',
        answer: () => (
          <TextsContainer>
            <p>
              You agree that we own all intellectual property rights, including
              copyrights, patents, trademarks and trade secrets (as applicable)
              in respect of, in connection with and arising out of:
            </p>
            <ol>
              <li>
                the Consultation Services, which includes but is not limited to
                any materials and / or advice provided in the provision of the
                Consultation Services and any footage of any Consultation
                Meeting whether recorded by you or us; and
              </li>
              <li>
                the Consultation Booking Page, the Consultation Booking Service
                and this Terms of Use of Consultation Services, which includes
                but is not limited to any content, name and logo displayed on
                the same.
              </li>
            </ol>
            For the avoidance of doubt, the Clause on “Intellectual Property” in
            the General Terms of Use applies to this Terms of Use of
            Consultation Services.
          </TextsContainer>
        ),
      },
      {
        id: 12,
        question: 'Notice',
        answer: () => (
          <TextsContainer>
            <p>We may give notice, by means of:</p>
            <ol>
              <li>
                general notice in the Consultation Booking Page and/or any of
                the Platforms and/or the Terms of Use of Consultation Services
                and/or the General Terms of Use; or
              </li>
              <li>electronic mail to the E-mail Address.</li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 13,
        question: 'Force Majeure',
        answer: () => (
          <TextsContainer>
            <p>
              Neither us nor you shall be considered to be in breach of or in
              default under this Terms of Use of Consultation Services on
              account of, and shall not be liable to the other for, any delay or
              failure to perform the obligations hereunder by reason of fire,
              earthquake, flood, explosion, strike, riot, lockdown, war,
              terrorism, change of ruling government or change of key political
              party person or similar event beyond that party’s reasonable
              control (each a “<b>Force Majeure Event</b>”); provided that the
              affected party shall, as soon as practicable notify in writing to
              the other of the occurrence of a Force Majeure Event.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 14,
        question: 'Severability',
        answer: () => (
          <TextsContainer>
            <p>
              If at any material time, during the subsistence of the Terms of
              Use of Consultation Services, there are changes to laws,
              regulations, by-laws or otherwise, which may render any of the
              Terms of Use of Consultation Services illegal, invalid or
              otherwise unenforceable in any state or country in which these
              terms are intended to be effective, then to the extent and within
              the jurisdiction which such term(s) is illegal, invalid or
              unenforceable, it shall be severed and deleted and the remaining
              Terms of Use of Consultation Services shall survive, remain in
              force and effect and continue to be binding on all parties herein
              concerned.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 15,
        question: 'Governing Law and Jurisdiction',
        answer: () => (
          <TextsContainer>
            <p>
              The Terms of Use of Consultation Services (and all non-contractual
              relationships arising out of or connected to them) shall be
              governed and construed in accordance with the prevailing laws of
              Republic of Singapore.
            </p>
            <p>
              Any disputes which may arise out of or which may be related to or
              in connection with the Terms of Use of Consultation Services shall
              fall under the exclusive jurisdiction of the courts of Republic of
              Singapore, for the settlement of such disputes.
            </p>
          </TextsContainer>
        ),
      },
    ],
  },
  {
    parent: 'Video Content',
    date:
      'These Terms of Use of Video Content are last updated on 15 June 2023.',
    description: `Please read the terms of use set out herein (“Terms of Use of Video Content”) carefully before proceeding to access and / or use the  Video Content (as defined below). If you do not agree to the Terms of Use of Video Content, you should not access the Video Content.
`,
    children: [
      {
        id: 1,
        question: 'Terms of Use of Video Content',
        answer: () => (
          <TextsContainer>
            <p>
              By accessing and/or using the Video Content (as defined below),
              you hereby agree and accept the Terms of Use of Video Content and
              the terms of use of our website www.giglifepro.com (“
              <b>Website</b>”) at https://giglifepro.com/terms and/or mobile
              applications which we may operate from time to time, which
              includes the GLP application (<b>“GLP App“</b>), whether
              downloaded through the Apple`s App Store or Google LLC`s (
              <b>”Google”</b>) Google Play Store (collectively,{' '}
              <b>”Application Stores”</b>) at (“
              <b>General Terms of Use</b>”), which shall be deemed to be
              incorporated into this Terms of Use of Video Content. The Website
              and the mobile applications shall hereafter be referred to
              individually as “Platform” and collectively as “Platforms”. The
              access and usage of the Platforms have been made available by and
              through Gig Life Pro Pte Ltd, a private limited liability company
              established under the laws the of Republic of Singapore
              (collectively, “<b>our</b>”, “<b>us</b>” or “<b>we</b>”).
            </p>
            <p>
              In the event of any conflict between the Terms of Use of Video
              Content and the General Terms of Use, such conflicting Terms of
              Use of Video Content shall prevail.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 2,
        question: 'Use and Access of Video Content',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                <p>
                  We will curate and make available certain type of videos
                  relating to topics of interest in the media and entertainment
                  industry (e.g. ‘Real Talk’, ‘Educate Yourself’ and ‘Get to
                  Know’) on the Platforms (“<b>Video Content</b>”). The Video
                  Content will be embedded on the Platform and hosted on the
                  Youtube platform (at www.youtube.com) or any other third-party
                  video hosting platform (“
                  <b>Third-Party Platform</b>”) which we may in our absolute
                  discretion determine.
                </p>
              </li>
              <li>
                For the avoidance of doubt, the term “<b>Services</b>” in the
                General Terms of Use includes the Video Content.
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 3,
        question: 'Third-Party Platform and Websites',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                <p>
                  You agree and acknowledge that the Video Content, may be
                  hosted on and (ii) the Platform and/or Video Content may
                  provide links or other access to, other sites and resources
                  (as applicable) on the Internet which includes but is not
                  limited to the Third-Party Platform. We are not responsible
                  for and do not endorse such sites and resources. You further
                  acknowledge and agree that we will not be responsible or
                  liable, directly or indirectly, for any damage or loss caused
                  or alleged to be caused by or in connection with use of or
                  reliance on any content, events, goods or services available
                  on or through any such site or resource. Any dealings you have
                  with third parties found while using the Platforms, Website
                  and the Video Content are between you and the third party, and
                  you agree that we are not liable for any loss or claim that
                  you may have against any such third party.
                </p>
              </li>
              <li>
                <p>
                  <p>
                    By accessing and/or using the Video Content, you acknowledge
                    that in addition to our privacy policy set out at
                    https://giglifepro.com/privacy (“
                    <b>GLP Privacy Policy Notice</b>”), you have read, agreed
                    and accepted:
                  </p>
                  <ol>
                    <li>
                      <p>
                        the Terms of Service of Youtube set out at
                        https://www.youtube.com/static?template=terms or any
                        other Third-Party Platform, if used to host the Video
                        Content (“<b>Third-Party Terms of Use</b>”); and
                      </p>
                    </li>
                    <li>
                      <p>
                        the privacy policy of Youtube set out at
                        https://policies.google.com/privacy or any other
                        Third-Party Platform, if used to host the Video Content
                        (“<b>Third-Party Privacy Policy Notice</b>”), and
                        thereby expressly consent to authorising us and the
                        Third-Party Platform to process the personal data
                        collected for such purposes as set out in the GLP
                        Privacy Policy Notice and any Third-Party Privacy Policy
                        Notice.
                      </p>
                    </li>
                  </ol>
                </p>
              </li>
              <li>
                <p>
                  <b>
                    If you do not agree to the GLP Privacy Policy Notice and /
                    or the Third-Party Privacy Policy Notice and / or
                    Third-Party Terms of Use, you should not access the Video
                    Content.
                  </b>
                </p>
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 4,
        question: 'Nature of Relationship',
        answer: () => (
          <TextsContainer>
            <p>
              It is hereby agreed that nothing in this Terms of Use of Video
              Content creates, implies or evidences the relationship of employer
              and employee, principal and agent or to constitute a partnership
              or joint venture between us and you.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 5,
        question: 'Obligations and Warranties',
        answer: () => (
          <TextsContainer>
            <p>You warrant and represent to us that:</p>
            <ol>
              <li>
                <p>
                  you are an individual of at least eighteen (18) years of age;
                  and
                </p>
              </li>
              <li>
                <p>
                  you are capable of entering into and performing legally
                  binding contracts under any applicable law.
                </p>
              </li>
            </ol>
            <p>
              If you are under 18 years of age, you may only access and use the
              Platform, and the Video Content with the consent of and under the
              supervision of your parent or legal guardian who shall be
              responsible for all your activities.
            </p>
            <p>
              For the avoidance of doubt, the Clause on “Warranties and
              Guarantees” in the General Terms of Use applies to this Terms of
              Use of Video Content.
            </p>
          </TextsContainer>
        ),
      },
      {
        id: 6,
        question: 'Termination',
        answer: () => (
          <TextsContainer>
            You agree that we reserve the right to suspend, terminate or remove
            your access to the Platforms and Video Content with or without
            notice to you.
          </TextsContainer>
        ),
      },
      {
        id: 7,
        question: 'Limitation of Liability and Indemnity',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                <p>Limitation of Liability</p>
                <p>
                  The Platfomrs and Video Content are provided on “as-is basis”
                  and we disclaim any and all representations and warranties,
                  whether express or implied, including implied warranties of
                  title, merchantability and fitness for a particular purpose or
                  non-infringement. We cannot guarantee and do not promise any
                  specific results from use of the Platforms and Video Content.
                </p>
                <p>
                  We do not represent or warrant that the Platforms, the Video
                  Content and/or the Third-Party Platform is accurate, complete,
                  reliable, current or error-free or that it is free of viruses
                  or other harmful components. Without limiting the foregoing,
                  you understand and agree that if you download or otherwise
                  obtain content, material or data from the Platforms, the Video
                  Content and/or the Third-Party Platform, you do so at your own
                  discretion and risk. In no event will we or our directors,
                  officers, advisors, employees, contractors, affiliates or
                  agents be liable to you or any third person for any indirect,
                  consequential, exemplary, incidental or punitive damages,
                  including for any lost profits or lost data arising from your
                  use of the Platforms, the Video Content and/or the Third-Party
                  Platform.
                </p>
                <p>
                  For the avoidance of doubt, we shall not be liable for any
                  direct, indirect, punitive, incidental, special, consequential
                  damages or any damages whatsoever, including, without
                  limitation, liability arising out of or in any way connected
                  with or otherwise arising out of the the Platforms, the Video
                  Content and/or the Third-Party Platform, whether based on
                  contract, tort, negligence, strict liability or otherwise.
                  Under no circumstances will we be responsible for any loss or
                  damage or personal injury or death resulting from anyone’s use
                  of the Platforms, the Video Content and/or the Third-Party
                  Platform or any interaction between users of the Video
                  Content, whether online or offline.
                </p>
              </li>
              <li>
                <p>Indemnity</p>
                <p>
                  You agree to indemnify us and keep our officers, directors,
                  employees and agents harmless from any and all claims,
                  demands, losses, liabilities and expenses (including legal
                  costs and fees) arising out or in connection with your use of
                  the Platforms, the Video Content and/or the Third-Party
                  Platform.
                </p>
              </li>
            </ol>
            For the avoidance of doubt, the Clause on “Limitation of Liability
            and Indemnity” in the General Terms of Use applies to this Terms of
            Use of Video Content.
          </TextsContainer>
        ),
      },
      {
        id: 8,
        question: 'Ownership and Grant of License of Video Content',
        answer: () => (
          <TextsContainer>
            <ol>
              <li>
                <p>
                  You agree that we own all intellectual property rights,
                  including copyrights, patents, trademarks and trade secrets
                  (as applicable) in respect of, in connection with and arising
                  out of the Platforms, Video Content and this Terms of Use of
                  Video Content, which includes but is not limited to any
                  content, name and logo displayed on the same.
                </p>
              </li>
              <li>
                <p>
                  The Video Content is solely for your personal and
                  non-commercial use. We grant you a{' '}
                  <b>
                    limited, non-exclusive, non-sublicensable, non-transferable
                    and revocable
                  </b>{' '}
                  license to access the Video Content in accordance with Clause
                  2 only for the purpose of viewing the Video Content. You may
                  not download, record or replicate the Video Content in any way
                  and except for the foregoing limited license, no right, title
                  or interest shall be transferred to you. You agree not to use
                  the Video Content for public performances. We may revoke your
                  license at any time in our sole discretion without giving you
                  any reason whatsoever.
                </p>
              </li>
              <li>
                For the avoidance of doubt, the Clause on “License” and
                “Intellectual Property” in the General Terms of Use applies to
                this Terms of Use of Video Content.
              </li>
            </ol>
          </TextsContainer>
        ),
      },
      {
        id: 9,
        question: 'Notice',
        answer: () => (
          <TextsContainer>
            We may give notice, by means of general notice in any of the
            Platforms , the Terms of Use of Video Content and/or the General
            Terms of Use.
          </TextsContainer>
        ),
      },
      {
        id: 10,
        question: 'Force Majeure',
        answer: () => (
          <TextsContainer>
            Neither us nor you shall be considered to be in breach of or in
            default under this Terms of Use of Video Content on account of, and
            shall not be liable to the other for, any delay or failure to
            perform the obligations hereunder by reason of fire, earthquake,
            flood, explosion, strike, riot, lockdown, war, terrorism, change of
            ruling government or change of key political party person or similar
            event beyond that party’s reasonable control (each a “
            <b>Force Majeure Event</b>”); provided that the affected party
            shall, as soon as practicable notify in writing to the other of the
            occurrence of a Force Majeure Event.
          </TextsContainer>
        ),
      },
      {
        id: 11,
        question: 'Severability',
        answer: () => (
          <TextsContainer>
            If at any material time, during the subsistence of the Terms of Use
            of Video Content, there are changes to laws, regulations, by-laws or
            otherwise, which may render any of the Terms of Use of Video Content
            illegal, invalid or otherwise unenforceable in any state or country
            in which these terms are intended to be effective, then to the
            extent and within the jurisdiction which such term(s) is illegal,
            invalid or unenforceable, it shall be severed and deleted and the
            remaining Terms of Use of Video Content shall survive, remain in
            force and effect and continue to be binding on all parties herein
            concerned.
          </TextsContainer>
        ),
      },
      {
        id: 12,
        question: 'Governing Law and Jurisdiction',
        answer: () => (
          <TextsContainer>
            <p>
              The Terms of Use of Video Content (and all non-contractual
              relationships arising out of or connected to them) shall be
              governed and construed in accordance with the prevailing laws of
              Republic of Singapore.
            </p>
            Any disputes which may arise out of or which may be related to or in
            connection with the Terms of Use of Video Content shall fall under
            the exclusive jurisdiction of the courts of Republic of Singapore,
            for the settlement of such disputes.
          </TextsContainer>
        ),
      },
    ],
  },
];

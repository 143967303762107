import Actions from 'actions';

const initialState = Object.freeze([]);

function list(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_TIMEZONES_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default list;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Card, Picture } from 'components/UI';
import styled from '@emotion/styled';
import {
  imgAboutPriya,
  imgAboutSarah,
  imgAboutEzra,
  imgAboutShaf,
} from 'assets';
import classnames from 'classnames';
import { isProduction } from 'utils/config';
import FeaturedLayout from './common/FeaturedLayout';
import Apply from './common/Apply';
import PastPartnerships from './Home/PastPartnerships';
import {
  imgAboutAnn,
  imgAboutEd,
  imgAboutTyler,
  imgAboutChristine,
  imgAboutAhikaa,
} from '../../../public/assets';
import { generalEmails, partnershipServices } from '../../utils/constants';

const SCREENS = Object.freeze(['Services', 'Our Team']);

const TEAM_BG_HEIGHT = 1500;

const TeamBackground = styled.div`
  height: ${TEAM_BG_HEIGHT}px;
  clip-path: polygon(0 6%, 100% 0%, 100% 94%, 0% 100%);
`;

const TeamSectionContainer = styled.section`
  margin-top: -${TEAM_BG_HEIGHT + 75}px;
`;

const TeamCardContainer = styled(Card)`
  max-width: 800px;
`;

const TeamCardPicture = styled(Picture)`
  max-height: 360px;
`;

const VIDEO_URL = isProduction
  ? 'https://www.youtube.com/embed/qhz00ZACNQ8?autoplay=1&loop=1&playlist=qhz00ZACNQ8&mute=1'
  : 'https://www.youtube.com/embed/qhz00ZACNQ8';

const Services = () => (
  <>
    <div className="container mx-auto px-6 mb-12 mt-4 md:mt-12">
      <Card className="max-w-5xl mx-auto border-t-6 border-easter p-8 md:p-16 mt-8 flex flex-col items-center">
        <h2 className="font-semibold text-center">Why Gig Life Pro?</h2>
        <div className="space-y-8 mt-8">
          <p className="text-center">
            Every market works differently with Interconnected networks but
            unique cultures and distinct structures. Gig Life Pro can assist you
            in meeting the right partners and preparing you for working with new
            cultures uncovering new revenue streams and building a stronger
            footprint for your business or music globally.
          </p>
        </div>
        <div className="w-2/3 my-8 relative rounded overflow-hidden">
          <iframe
            title="landingVideo"
            height="400"
            src={VIDEO_URL}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-full"
          />
        </div>
      </Card>
      <h2 className="font-semibold mt-12 text-center">Partnership Services</h2>
      <div className="max-w-5xl mx-auto text-center">
        {partnershipServices.map((service) => (
          <div key={service.heading} className="">
            <h5 className="font-2lg mt-8 mb-4">{service.heading}</h5>
            <ul className="list-inside list-disc space-y-2 ml-3 text-easter text-md">
              {service.content.map((content) => (
                <li key={content}>
                  <span className="text-black">{content}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <p className="text-center mt-12">
          For services enquires please contact{' '}
          <a
            className="text-easter underline"
            href="mailto:partnerships@giglifepro.com"
          >
            partnerships@giglifepro.com
          </a>
        </p>
      </div>
    </div>
    <PastPartnerships />
  </>
);

const TeamCard = ({ image, name, position, country, about, reverse }) => (
  <div
    className={classnames('flex flex-col-reverse items-center mt-16', {
      'md:flex-row-reverse': reverse,
      'md:flex-row': !reverse,
    })}
  >
    <TeamCardContainer
      className={classnames('border-easter py-12 px-8 -mt-8 md:mt-0', {
        'border-r-6 md:pr-12 md:pl-32': reverse,
        'border-l-6 md:pr-32 md:pl-12': !reverse,
      })}
    >
      <h5 className="text-2lg text-easter">{name}</h5>
      <strong className="block text-md font-semibold">{position}</strong>
      <strong className="block mb-4 font-normal text-boulder">{country}</strong>
      <p>{about}</p>
    </TeamCardContainer>
    <TeamCardPicture
      src={image}
      className={classnames('max-w-xs relative rounded', {
        'md:-mr-12': reverse,
        'md:-ml-12': !reverse,
      })}
    />
  </div>
);

TeamCard.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

TeamCard.defaultProps = {
  reverse: false,
};

const TeamSection = () => (
  <TeamSectionContainer className="mb-20 relative">
    <div className="flex flex-col items-center container mx-auto px-6">
      <TeamCard
        image={imgAboutPriya}
        name="Priya Dewan"
        country="Singapore"
        position="Founder and CEO"
        about="Priya Dewan launched Gig Life Pro to empower today’s music industry creatives and professionals with
        the information and networking opportunities that helped her build a 20+ year globally successful career
        in the music industry. Dewan’s first major success was at the start of her career running Warp Records
        N. America in New York where she helped Grizzly Bear’s Vecketamist debut at #8 on Billboard’s Top 200
        and received the honor of “30 Under 30 Power Players”. She then returned to Singapore to launch
        Feedback Asia, the first direct to management booking Asia for Asia, based in Asia, representing artists
        like CHVRCHES, Flying Lotus, Simian Mobile Disco, Bicep and Beach House. Most recently Dewan was
        VP of International Marketing APAC and MD of Southeast Asia and South Korea for The Orchard, where
        she received Billboard’s “International Power Player” twice for her role in signing and overseeing global
        campaigns for artists like BTS, TWICE, Stray Kids, ITZY, DPR, Eric Nam, CL, G.E.M., Son Tung MTP-E,
        James Reid, Shigga Shay. Priya has facilitated or spoken on conference panels at All That Matters
        (Singapore), SXSW Sydney, MU:CON (Seoul), EMC (Syd), GMAs (Taipei), Sound of the Xity (Beijing),
        Primavera Pro (Barcelona), CMJ (New York), SXSW (Austin)."
        reverse
      />
      <TeamCard
        image={imgAboutSarah}
        name="Sarah Guppy"
        country="Australia"
        position="COO (Chief Operation Officer)"
        about="Sarah Guppy is a publicist and marketing professional. Sarah’s global perspective has gained from years
        of working both in Australia and the UK. Working in the London office This Much Talent led to setting up
        and becoming the Owner of their base in Naarm/Melbourne. Sarah has experience in producer,
        songwriter and artist management, marketing, publicity, radio servicing, and venue and event music
        programming. Sarah has worked as the booker and publicist for venues Revolver, Shadow Electric and
        Japanese venue Horse Bazaar, and has curated her own events Label of Love (record label series) and
        Labour of Love (music artist exhibition series) the live acts for Melbourne Fashion Week and VAMFF
        (formally L’Oréal Fashion Festival) as well as Mountain Goat’s annual Cans and Bands event alongside
        The Social Crew.
        Sarah has been a speaker on panels at industry conferences and events including BIGSOUND
        (Brisbane), Face The Music (Melb), Melbourne International Jazz Festival, Stonnington Jazz Festival,
        APRA &amp; Music Victoria Workshops (Melb), SONIK (Manila) Indie Week (Canada), Mondo NYC (USA)
        Vietnam Music Week, Indie-Con (Adelaide, Australia), Taiwan Beats (Taiwan), Ministry of Culture of
        Mongolia, and has participated as a mentor on programs for The Push, Live Music Professionals, MIME
        (Music Industry Mentoring Edge) and the AIR Women In Music Mentor Program.
        "
      />
      <TeamCard
        image={imgAboutEd}
        name="Edward Tan"
        country="Singapore"
        position="Director of Growth Marketing"
        about="With over a decade of experience in integrated media, content, and digital marketing, Edward Tan
          specializes in orchestrating comprehensive 360-degree marketing campaigns for renowned brands such
          as CapitaLand, MM2 Entertainment, Cathay Group, MediaCorp, Esplanade, and TVB Hong Kong. In his
          multifaceted role, Ed leverages his in-depth expertise across SEO, PPC, social media, email marketing,
          and analytics to consistently deliver exceptional results.
          
          As a strategic and creative thinker, he notably led the Subway Singapore team to secure the Best Use of
    Mobile Advertising at the Singapore Media Award in 2019. Beyond the professional realm, Ed finds
  enjoyment in watching Disney movies and grooving to his eclectic playlist, featuring a fusion of English,
  Chinese, Thai, Vietnamese, and Italian music. Currently serving as the Director of Growth Marketing at
  Gig Life Pro, Ed continues to bring his wealth of experience to drive success in the marketing arena.
          "
        reverse
      />
      <TeamCard
        image={imgAboutChristine}
        name="Christine Su"
        country="Taiwan"
        position="Event Manager"
        about="Christine Su comes to Gig Life Pro with a wealth of experience and most recently was the GMA
        (Golden Melody Awards & Festival) international project manager as well as the conference curator 
        of the GMA festival.  The event is an awards ceremony and a business-to-business music festival in 
        Taipei, Taiwan every June. Commended as the Grammys in the Mandarin music world, GMA has been organized
        by the Ministry of Culture, Taiwan since 1990. Golden Melody Festival, since 2014, is a three-day B2B
        oriented event annually. It gathers professionals from industries around the world and supports business 
        opportunities and engagements in music and other industries. Experiencing many major events and with years
        of experience in music export and import to Taiwan for government projects, Christine continues working on
        international relationships with both existing and potential partners to promote Taiwanese artists and music
        to the world."
      />
      <TeamCard
        image={imgAboutEzra}
        name="Ezra Nicolau"
        country="Phillipines"
        position="Content Creator"
        about="Ezra Nicolau is a multimedia arts graduate who majored in digital animation based in the Philippines. She
        originally started as a graphic artist, and then, as a motion graphic artist in a marketing consulting agency.
        As her heart desires to leave an impact on the next generation, she was led to a foundation as a creative
        producer creating weekly videos and content for children. She is now growing a creative studio called
        Ennie Day Project with services of graphic design, video editing, and motion design while being the
        creative lead at Gig Life Pro."
        reverse
      />
      <TeamCard
        image={imgAboutShaf}
        name="Nurshafiqa Mohd Noor"
        country="Singapore"
        position="Assistant Community Manager"
        about="Currently based in Singapore, Nurshafiqa (Shaf) is an Assistant Community Manager at Gig Life Pro.
        Finding her passion in music and the arts, Shaf began her venture into the industry as a music writer,
        
        participating in the 2015 Baybeats Budding Writers programme under the mentorship of The Straits
        Times music correspondent Eddino Abdul Hadi. She then freelanced as a live sound technician from
        2015 to 2021 for productions such as the annual Singapore National Day Parade, St. Jerome&#39;s Laneway
        Festival, ZoukOut Singapore, Ultra Singapore, and many more. Wanting to challenge herself with new
        territories, Shaf dabbled in music PR as an Account Coordinator at The LINCH Agency, assisted in venue
        management at the historical Pasir Panjang Power Station currently occupied by 24OWLS and did some
        artist liaison work for Eventim Live Asia."
      />
      <TeamCard
        image={imgAboutTyler}
        name="Tyler Jenke"
        country="Australia"
        position="Content Manager"
        about="Originally from Adelaide, Tyler Jenke is a Melbourne-based journalist with more than a decade of
        experience in writing and publishing. Having originally created an exhaustive database dedicated to triple
        j’s Hottest 100 countdown, the past eight years have seen Tyler working as a prolific writer across
        numerous websites, with three years spent as the Editor of Rolling Stone Australia. Tyler also lectures on
        the topic of professional writing and spends any available free time as a musician; performing with the
        rock band Dental Plan and with solo project Cheap Domestic. Outside of the world of the written word,
        you can often find Tyler researching obscure music facts, collecting copious amounts of vinyl, tapes, and
        CDs, or simply situated in the front row of a live concert."
        reverse
      />
      <TeamCard
        image={imgAboutAnn}
        name="Ann Marie Mohan"
        country="Singapore"
        position="Finance Manager"
        about="Ann-Marie oversees all areas of finance and works closely with the Chief Financial Officer to develop the
        annual operating budget for review and approval by the board of directors by providing strategic analysis
        for the senior management to determine the company’s profitability and special reports required by
        Shareholders."
      />
      <TeamCard
        image={imgAboutAhikaa}
        name="Ahikaa Arora"
        country="Singapore"
        position="Community Manager"
        about="Ahikaa, a Berklee College of Music scholarship graduate, has been driven by a lifelong passion for music. Holding a degree in Music Business, she has been building a diverse career, including A&R experiences at el3ment, Position Music, and APG in Los Angeles. Her professional journey also encompasses contributions to Berklee's A&R group and an independent label, where she scouted artists, organized pitching sessions with major labels such as RCA Records and Atlantic Records, and facilitated a notable event featuring Sophia Eris (Lizzo's DJ) to enhance Berklee artist visibility. As a singer-songwriter, Ahikaa takes pleasure in self-expression, with her debut single receiving airplay on Kiss 92FM in Singapore. Her commitment to the music community extends beyond her personal career; she has volunteered at the AXEAN Festival in Singapore, taught voice lessons to nurture aspiring singers, and is now channeling her passion into Gig Life Pro. At Gig Life Pro, Ahikaa is dedicated to serving the community that has been integral to her journey, connecting with industry peers, and actively shaping her path through hands-on involvement. Guided by her belief in following one’s intuition, being proactive, and fostering collaboration, she remains deeply engaged in this business. Her journey reflects a desire to reach her highest potential, striving to grow through every opportunity."
        reverse
      />
      <h2 className="font-semibold mt-12 text-center">General Enquiries</h2>
      <Card className="w-4/6 relative mt-16 border-easter border-t-6">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                DEPARTMENT
              </th>
              <th scope="col" className="px-6 py-3">
                EMAIL
              </th>
            </tr>
          </thead>
          <tbody>
            {generalEmails?.map(({ department, email }) => (
              <tr key={department} className="bg-white border-b ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {department}
                </th>
                <td className="px-6 py-4 text-gray-900">{email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  </TeamSectionContainer>
);

const Header = () => (
  <FeaturedLayout bgFilter={false} polygon={false}>
    <div className="container sm:px-4 mx-auto z-0">
      <Breadcrumbs
        trails={[{ label: 'About Us', path: '/about' }]}
        publicRoute
      />
    </div>
    <div className="flex flex-col text-center text-white relative m-auto">
      <h1 className="text-3xl font-semibold">About Us</h1>
      <p className="text-lg">
        A little background about our services and the founders of the community
      </p>
    </div>
  </FeaturedLayout>
);

const About = () => {
  const [activeScreen, setActiveScreen] = useState(SCREENS[0]);

  const Tabs = () => {
    return (
      <div className="w-screen border-b border-gray-300 flex justify-center">
        {SCREENS.map((screen) => (
          <div
            className={classnames('py-2 px-4 sm:px-5 md:px-8 ', {
              'text-dusty cursor-pointer': screen !== activeScreen,
              'border-b-4 border-easter text-easter font-semibold':
                screen === activeScreen,
            })}
            key={screen}
            onClick={() => setActiveScreen(screen)}
            role="presentation"
          >
            {screen}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Header />
      <Tabs />
      {activeScreen === SCREENS[0] && <Services />}
      {activeScreen === SCREENS[1] && (
        <>
          <TeamBackground className="bg-lily mt-20" />
          <TeamSection />
        </>
      )}
      <Apply />
    </div>
  );
};

About.propTypes = {};

export default About;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import { Card } from 'components/UI';
import { gfxNotification } from 'assets';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const StyledSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#47bad4',
    },
    '&$checked + $track': {
      backgroundColor: '#47bad4',
    },
  },
  checked: {},
  track: {},
})(Switch);

const RenewalReminder = ({ checked }) => {
  const dispatch = useDispatch();
  const [isToggled, setToggle] = useState(checked);
  const toggleReminder = () => {
    setToggle(!isToggled);
    dispatch(Actions.toggleReminder());
  };

  useEffect(() => {
    setToggle(checked);
  }, [checked]);

  return (
    <div className="mt-8 mb-6">
      <h3 className="uppercase mb-4 font-semibold">SUBSCRIPTION REMINDER</h3>
      <Card className="max-w-120 min-w-80 flex p-4 sm:p-5 justify-around items-center">
        <img
          src={gfxNotification.default}
          alt="notification-icon"
          className="h-12"
        />
        <p className="mx-4">
          Get <b>notified</b> 1 week before your subscription renews
        </p>
        <StyledSwitch checked={isToggled} onChange={toggleReminder} />
      </Card>
    </div>
  );
};

RenewalReminder.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default RenewalReminder;

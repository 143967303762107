const NAME = 'CONSULTATION_SERVICE/CREATE_';

export const CREATE_CONSULTATION_SERVICE_REQUEST = `${NAME}REQUEST`;
export const CREATE_CONSULTATION_SERVICE_SUCCESS = `${NAME}SUCCESS`;
export const CREATE_CONSULTATION_SERVICE_FAIL = `${NAME}FAIL`;

export const createConsultationService = (params, callbacks) => ({
  type: CREATE_CONSULTATION_SERVICE_REQUEST,
  params,
  callbacks,
});

export const createConsultationServiceSuccess = (data) => ({
  type: CREATE_CONSULTATION_SERVICE_SUCCESS,
  data,
});

export const createConsultationServiceFail = (error) => ({
  type: CREATE_CONSULTATION_SERVICE_FAIL,
  error,
});

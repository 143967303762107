const NAME = 'SENDBIRD/LEAVE_CHANNEL_';

export const LEAVE_CHANNEL_REQUEST = `${NAME}REQUEST`;
export const LEAVE_CHANNEL_SUCCESS = `${NAME}SUCCESS`;
export const LEAVE_CHANNEL_FAIL = `${NAME}FAIL`;

export const leaveChannel = (channel, callbacks) => ({
  type: LEAVE_CHANNEL_REQUEST,
  channel,
  callbacks,
});

export const leaveChannelSuccess = (channelUrl) => ({
  type: LEAVE_CHANNEL_SUCCESS,
  channelUrl,
});

export const leaveChannelFail = () => ({
  type: LEAVE_CHANNEL_FAIL,
});

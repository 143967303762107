import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Loading, PageMeta } from 'components/UI';
import App from 'containers/App';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import configureStore from './store/configureStore';

import './styles/app.css';
import 'react-notifications-component/dist/theme.css';

const queryClient = new QueryClient();
const { persistor, store } = configureStore();
const root = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <ReactNotification />
      <Router>
        <PageMeta />
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </CookiesProvider>
      </Router>
    </PersistGate>
  </Provider>,
  root
);

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchCountryOptions(callbacks) {
  try {
    const response = yield call(api.fetchCountryOptions);
    yield put(Actions.fetchCountryOptionsSuccess(response?.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks?.success, response?.data);
    }
  } catch (error) {
    yield put(Actions.fetchCountryOptionsFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks?.failure, error);
    }
  }
}

function* watchfetchCountryOptions() {
  yield takeLatest(Actions.FETCH_COUNTRY_OPTIONS_REQUEST, fetchCountryOptions);
}

export default function* list() {
  yield all([fork(watchfetchCountryOptions)]);
}

const NAME = 'EVENTS';

export const featuredEvent = (store) => store[NAME].list.featuredEvent;
export const eventInfo = (store) => store[NAME].info.data || {};
export const eventList = (store) => store[NAME].list.data || [];
export const addedEvents = (store) => store[NAME].addedList || [];
export const getPaginatedEvents = (store) => store[NAME].list.allData || [];
export const eventAttendInfo = (store) => store[NAME].attend.data || {};
export const eventUnattendInfo = (store) => store[NAME].unattend.data || {};
export const eventAttendList = (store) => store[NAME].attendList.data || [];
export const eventOptInInfo = (store) => store[NAME].optIn.data || {};
export const eventOptOutInfo = (store) => store[NAME].optOut.data || {};
export const eventPageMeta = (store) => store[NAME].list.page || {};
export const eventCategories = (store) => store[NAME].getCategories;
export const getContentCount = (store) => store[NAME].getCount.data || {};
export const getAttendances = (id) => (store) =>
  store[NAME].attendances[id] || [];
export const createdEvents = (store) => store[NAME].createdList;
export const fetchCreatedEvent = (store) => store[NAME].fetchCreated;
export const isCountCacheExpired = (store) =>
  new Date().getTime() > store[NAME].getCount.exp;

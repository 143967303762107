import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'components/UI';
import classnames from 'classnames';
import { AuthContext } from 'utils/context';
import { useDispatch } from 'react-redux';
import Actions from 'actions';
import { pngLogoWhite } from 'assets';
import StagingBanner from '../../../components/UI/StagingBanner';
import { isProduction } from '../../../utils/config';

const MD_SCREEN = 768;

const Navbar = () => {
  const [menuIsOpen, setMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isSubscribed } = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.pageYOffset);
    const handleResize = () =>
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (dimensions.width >= MD_SCREEN) setMenuOpen(false);
  }, [dimensions]);

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  const Logo = () => (
    <div className="flex-shrink-0">
      <Link to="/">
        <img src={pngLogoWhite.default} alt="logo" className="h-12" />
      </Link>
    </div>
  );

  const MobileMenu = () => (
    <div className="ml-auto flex lg:hidden">
      <button
        type="button"
        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
        onClick={() => setMenuOpen(!menuIsOpen)}
      >
        <svg
          className={classnames({ hidden: menuIsOpen }, 'h-6 w-6')}
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        <svg
          className={classnames({ hidden: !menuIsOpen }, 'h-6 w-6')}
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );

  const MenuItems = () => {
    const menuItem = (menu, path) => {
      const menuPath = `/${path || menu.toLowerCase()}`;
      const isCurrentPath = pathname === menuPath;

      return (
        <Link
          to={menuPath}
          className={classnames({
            'mr-4 text-offwhite px-3 py-2': !menuIsOpen,
            'p-4 border-b border-gray-100 text-white': menuIsOpen,
            'border-b-2 text-easter font-semibold border-easter': isCurrentPath,
          })}
        >
          {menu}
        </Link>
      );
    };

    return (
      <div className="flex flex-col lg:flex-row sm:flex-grow sm:justify-around xl:mr-16">
        {menuItem('About Us', 'about')}
        {menuItem('Membership')}
        {menuItem('Events')}
        {menuItem('Locations')}
        {menuItem('Articles')}
        {menuItem('Consultation')}
      </div>
    );
  };

  const ActionButtons = () => {
    if (isSubscribed)
      return (
        <Button
          label="Sign Out"
          outline
          transparent
          onClick={() => dispatch(Actions.signOut())}
          className="ml-auto self-center"
        />
      );

    return (
      <>
        <Button
          outline
          transparent
          label="Login"
          onClick={() => navigate('/login')}
          className="mr-4 px-10 md:px-4 lg:px-10"
          isPill
        />
        <Button
          label="Apply"
          onClick={() => navigate('/signup')}
          className="mr-4 xl:mr-0 px-10 md:px-4 lg:px-10"
          isPill
        />
      </>
    );
  };

  return (
    <nav
      className={classnames('fixed w-full transition-all duration-500 z-30', {
        'bg-ebony': scrollPosition > 60 || menuIsOpen,
      })}
    >
      {!isProduction && <StagingBanner />}
      <div className="container mx-auto px-4 sm:px-6 xl:px-0">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center sm:flex-grow">
            <Logo />
            <div className="hidden lg:flex items-center justify-between w-full">
              <div className="ml-10 flex items-baseline sm:flex-grow">
                <MenuItems />
              </div>
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-col xl:flex-row lg:justify-between lg:space-y-2 xl:space-y-0 lg:pt-10 xl:pt-0">
            <ActionButtons />
          </div>
          <MobileMenu />
        </div>
      </div>
      <div className={classnames({ hidden: !menuIsOpen }, 'lg:hidden')}>
        <div className="flex flex-col mx-4">
          <MenuItems />
          <div className="flex justify-evenly my-4">
            <ActionButtons />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

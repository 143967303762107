import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* deleteUserImage({ callbacks }) {
  try {
    const response = yield call(api.deleteUserImage);
    yield put(Actions.deleteUserImageSuccess());
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks?.success, response.data);
    }
  } catch (error) {
    yield put(Actions.deleteUserImageFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks?.failure, error);
    }
  }
}

function* watchDeleteUserImage() {
  yield takeLatest(Actions.DELETE_USER_IMAGE_REQUEST, deleteUserImage);
}

export default function* deleteImage() {
  yield all([fork(watchDeleteUserImage)]);
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import api from 'api';
import { useMutation } from 'react-query';
import { Card, Button } from 'components/UI';
import { modalHeader } from 'assets';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import notify from 'utils/notification';
import { BackgroundSection } from '../../LandingPage/common';

const RedeemModal = ({
  currentPoints,
  redeemableImg,
  closeRedeemModal,
  isMembership = true,
  redeemable,
}) => {
  const { title, subtitle, points, id } = redeemable;
  const [redeemCount, setRedeemCount] = useState(1);
  const addCount = () => setRedeemCount((redeemCount) => redeemCount + 1);
  const substractCount = () => {
    if (redeemCount > 1) setRedeemCount((redeemCount) => redeemCount - 1);
  };

  const { mutate: redeemMutate } = useMutation(
    () =>
      api.redeemReward(id, {
        redemption_count: redeemCount,
      }),
    {
      onSuccess: () => {
        notify('Successfully redeemed points');
        closeRedeemModal();
      },
      onError: (e) => {
        notify(
          e?.response?.data?.error?.[0] || 'Error redeeming points',
          null,
          'danger'
        );
      },
    }
  );

  const pointsBalance = currentPoints - redeemCount * points;
  return (
    <div className="w-full sm:w-3/4 mx-auto px-4  sm:mt-10">
      <Card>
        <BackgroundSection
          backgroundImage={`url(${modalHeader.default})`}
          className={classnames(
            ' bg-no-repeat bg-cover bg-center h-18 rounded-t-lg'
          )}
        >
          <h3 className="p-3 pt-4 md:p-4 md:pt-5 text-white font-thin text-center">
            REDEEM REWARDS
          </h3>
        </BackgroundSection>
        <BackgroundSection
          backgroundImage={`url(${redeemableImg})`}
          className={classnames(
            'bg-no-repeat bg-cover bg-center h-48 flex flex-col items-center justify-center text-white'
          )}
        >
          <p className="text-2xl">{currentPoints}</p>
          <p className="text-sm">Availabe Points</p>
        </BackgroundSection>
        <Card className="flex w-11/12 mx-auto p-4 shadow-lg -mt-8 justify-between">
          <section className="w-3/4">
            <p className="text-sm sm:text-md">{title}</p>
            <p className="text-xs sm:text-sm mt-2 w-3/4 text-boulder">
              {subtitle}
            </p>
          </section>
          <section>
            <p className="text-xl font-semibold">{points}</p>
            <p className="text-xs text-right">Points</p>
          </section>
        </Card>
        <div className="p-5 sm:p-8 sm:text-lg">
          <p>I would like to redeem for....</p>
          <div className="flex justify-between items-center mt-3">
            <section className="flex items-center">
              <RemoveCircle
                className="mr-2 text-easter"
                onClick={substractCount}
              />
              <p>{redeemCount}</p>
              <AddCircle className="ml-2 text-easter" onClick={addCount} />
              <span className="ml-2">{isMembership ? 'months' : 'events'}</span>
            </section>
            <section>
              <p className="font-semibold text-right">{points * redeemCount}</p>
              <p className="text-boulder font-light text-xxs mt-1 text-right">
                {pointsBalance < 0
                  ? 'Not enough points to redeem'
                  : `${pointsBalance} remaining`}
              </p>
            </section>
          </div>
        </div>
        <div className="p-5 sm:p-10 mb-5 sm:mb-8 text-center border-t">
          <p className="text-xs text-boulder sm:text-sm">
            We will deduct your points after you succesfully redeemed the reward
          </p>
          <Button
            className="mt-2 lg:mt-5 text-xs lg:text-base py-3 px-20"
            label="REDEEM"
            type="submit"
            onClick={redeemMutate}
            disabled={currentPoints < points * redeemCount}
          />
          <Button
            className="mt-2 lg:mt-5 text-xs lg:text-base py-3 px-20"
            label="CANCEL"
            type="button"
            outline
            isPill
            onClick={closeRedeemModal}
          />
        </div>
      </Card>
    </div>
  );
};

RedeemModal.propTypes = {
  currentPoints: PropTypes.number.isRequired,
  redeemableImg: PropTypes.string.isRequired,
  closeRedeemModal: PropTypes.func.isRequired,
  redeemable: PropTypes.object.isRequired,
  isMembership: PropTypes.bool.isRequired,
};

export default RedeemModal;

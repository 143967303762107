import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import qs from 'query-string';
import classnames from 'classnames';

// TODO: Finalize design with designer. This component was extracted from https://tailwindui.com/components/application-ui/navigation/pagination

const Pagination = ({ pageMeta }) => {
  const { pathname } = useLocation();
  const { currentPage, pageItems, totalCount, totalPages } = pageMeta;
  const linkTo = (pageQuery, pageQueryModifier = 0) => ({
    pathname,
    search: `?${qs.stringify({
      page: pageQuery + pageQueryModifier,
    })}`,
  });

  const PageInfo = () => (
    <p className="text-sm leading-5 text-gray-700">
      Showing
      <span className="font-medium mx-1">
        {(currentPage - 1) * pageItems + 1}
      </span>
      to
      <span className="font-medium mx-1">
        {currentPage * pageItems > totalCount
          ? totalCount
          : currentPage * pageItems}
      </span>
      of
      <span className="font-medium mx-1">{totalCount}</span>
    </p>
  );

  return (
    <div className="container mx-auto px-4 py-3 flex items-center justify-between">
      <div className="flex flex-wrap items-center justify-center sm:flex-1 sm:items-center sm:justify-between">
        <PageInfo />
        <div>
          <nav className="relative z-0 inline-flex items-center">
            <Link
              to={linkTo(currentPage, -1)}
              className={classnames(
                'relative inline-flex items-center px-2 py-2',
                'border border-gray-300 bg-white text-sm leading-5 font-medium',
                'hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150',
                'rounded-l-md text-gray-500',
                { hidden: currentPage === 1 }
              )}
              aria-label="Previous"
            >
              Prev
            </Link>
            <p className="text-sm leading-5 text-gray-700 mx-4">
              Page
              <span className="font-medium mx-1">{currentPage}</span>
              of
              <span className="font-medium mx-1">{totalPages}</span>
            </p>
            <Link
              to={linkTo(currentPage, +1)}
              className={classnames(
                'relative inline-flex items-center px-2 py-2',
                'border border-gray-300 bg-white text-sm leading-5 font-medium',
                'hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150',
                '-ml-px rounded-r-md  text-gray-500',
                { hidden: currentPage === totalPages }
              )}
              aria-label="Next"
            >
              Next
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pageMeta: PropTypes.object.isRequired,
};

export default Pagination;

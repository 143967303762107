const NAME = 'EVENTS';

export const FETCH_EVENTS_REQUEST = `${NAME}/FETCH_EVENTS_REQUEST`;
export const FETCH_EVENTS_SUCCESS = `${NAME}/FETCH_EVENTS_SUCCESS`;
export const FETCH_EVENTS_FAIL = `${NAME}/FETCH_EVENTS_FAIL`;

export const fetchEvents = (params, callbacks) => ({
  type: FETCH_EVENTS_REQUEST,
  params,
  callbacks,
});

export const fetchEventsSuccess = (data, page) => ({
  type: FETCH_EVENTS_SUCCESS,
  data,
  page,
});

export const fetchEventsFail = (error) => ({
  type: FETCH_EVENTS_FAIL,
  error,
});

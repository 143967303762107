import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Selectors from 'selectors';
import Actions from 'actions';
import { Breadcrumbs, Loading, NavigationTab } from 'components/UI';
import styled from '@emotion/styled';
import { width } from 'styled-system';
import { BackgroundSection } from '../LandingPage/common';
import PerkViewCard from './PerkViewCard';
import PerkTileCard from './PerkTileCard';

const CardContainer = styled.div`
  ${width}
`;

const EXPLORE_PATH = '/members/perks/explore';
const MY_PERKS_PATH = '/members/perks/my-perks';
const TABS = Object.freeze([
  { name: 'Explore Perks', path: EXPLORE_PATH },
  { name: 'My Perks', path: MY_PERKS_PATH },
]);

const View = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: paramId } = useParams();
  const perk = useSelector(Selectors.getPerk) || {};
  const perks = useSelector(Selectors.getPerks);
  const { pathname } = useLocation();
  const { id, name } = perk;
  const isNewId = paramId !== id;
  const callbacks = { failure: () => navigate(EXPLORE_PATH) };
  const isExplore = pathname.includes(EXPLORE_PATH);
  const breadcrumbTrail = [
    { label: 'Perks', path: EXPLORE_PATH, icon: 'perks' },
    {
      label: isExplore ? 'Explore Perks' : 'My Perks',
      path: isExplore ? EXPLORE_PATH : MY_PERKS_PATH,
    },
    name,
  ];
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_PERK_REQUEST])
  );
  const hasMultiplePerks = perks.length > 1;
  const filteredPerks = perks.filter(({ id }) => id !== paramId);

  useEffect(() => {
    if (isNewId && !isLoading) dispatch(Actions.fetchPerk(paramId, callbacks));
  }, [dispatch, isNewId, paramId, callbacks, isLoading]);

  useEffect(() => {
    if (!hasMultiplePerks) dispatch(Actions.fetchPerks());
  }, [dispatch, hasMultiplePerks]);

  return (
    <>
      <BackgroundSection backgroundSize="100% 400px">
        <>
          <div className="pb-12">
            <Breadcrumbs trails={breadcrumbTrail} />
            <NavigationTab tabs={TABS} />
          </div>
          <div className="container mx-auto px-6 pt-8">
            {isNewId ? (
              <Loading
                className="bg-white h-screen flex justify-center items-center"
                opaque={false}
              />
            ) : (
              <PerkViewCard perk={perk} />
            )}
          </div>
        </>
      </BackgroundSection>
      {!!filteredPerks.length && (
        <div className="container mx-auto p-6">
          <h3 className="font-semibold">Other Perks</h3>
          <div className="flex flex-no-wrap overflow-x-auto w-full p-3">
            {filteredPerks.map((item) => (
              <CardContainer
                width="350px"
                className="flex-grow-0 flex-shrink-0 mr-10 my-4"
                key={item.id}
              >
                <PerkTileCard perk={item} path={`${EXPLORE_PATH}/${item.id}`} />
              </CardContainer>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default View;

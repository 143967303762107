const NAME = 'CONSULTATION/CANCEL_BOOKING_';

export const CANCEL_BOOKING_REQUEST = `${NAME}REQUEST`;
export const CANCEL_BOOKING_SUCCESS = `${NAME}SUCCESS`;
export const CANCEL_BOOKING_FAIL = `${NAME}FAIL`;

export const cancelBooking = (id) => ({
  type: CANCEL_BOOKING_REQUEST,
  id,
});

export const cancelBookingSuccess = (data) => ({
  type: CANCEL_BOOKING_SUCCESS,
  data,
});

export const cancelBookingFail = () => ({
  type: CANCEL_BOOKING_FAIL,
});

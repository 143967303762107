const NAME = 'STRIPE/VALIDATE_PROMO_CODE';

export const VALIDATE_PROMO_CODE_REQUEST = `${NAME}_REQUEST`;
export const VALIDATE_PROMO_CODE_SUCCESS = `${NAME}_SUCCESS`;
export const VALIDATE_PROMO_CODE_FAIL = `${NAME}_FAIL`;

export const validatePromoCode = (data, callbacks) => ({
  type: VALIDATE_PROMO_CODE_REQUEST,
  data,
  callbacks,
});

export const validatePromoCodeSuccess = (data) => ({
  type: VALIDATE_PROMO_CODE_SUCCESS,
  data,
});

export const validatePromoCodeFail = (error) => ({
  type: VALIDATE_PROMO_CODE_FAIL,
  error,
});

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const StyledLabel = styled.label`
  font-size: 11px;
`;

const FieldContainer = ({
  name,
  children,
  error,
  label,
  value,
  basic,
  fieldClassName,
  placeholder,
}) => (
  <div className={classnames('', fieldClassName)}>
    {basic ? (
      <label
        className="block font-bold mb-2 text-gray-600 text-sm"
        htmlFor={name}
      >
        {label}
      </label>
    ) : (
      <StyledLabel
        className={classnames('block ml-3 text-easter text-xs -mb-4', {
          invisible: !value,
        })}
        htmlFor={name}
      >
        {(value && label) || placeholder}
      </StyledLabel>
    )}
    {children}
    <div className="text-valencia h-4 mt-1 text-xs ml-3 font-light">
      {error}
    </div>
  </div>
);

FieldContainer.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  fieldClassName: PropTypes.string,
  placeholder: PropTypes.string,
  basic: PropTypes.bool.isRequired,
};

FieldContainer.defaultProps = {
  error: null,
  value: null,
  fieldClassName: null,
  placeholder: null,
};

export default FieldContainer;

const NAME = 'EVENTS/UPDATE_PERK_';

export const UPDATE_PERK_REQUEST = `${NAME}REQUEST`;
export const UPDATE_PERK_SUCCESS = `${NAME}SUCCESS`;
export const UPDATE_PERK_FAIL = `${NAME}FAIL`;

export const updatePerk = (id, data, callbacks) => ({
  type: UPDATE_PERK_REQUEST,
  id,
  data,
  callbacks,
});

export const updatePerkSuccess = (id, data) => ({
  type: UPDATE_PERK_SUCCESS,
  id,
  data,
});

export const updatePerkFail = (error) => ({
  type: UPDATE_PERK_FAIL,
  error,
});

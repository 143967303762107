import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { urlHandler } from 'utils/helper';
import { useAuth } from 'utils/context';
import { BackgroundSection } from '../LandingPage/common';

const TopMembers = ({ members }) => {
  const navigate = useNavigate();
  const { isSubscribed } = useAuth();

  if (isEmpty(members)) return null;

  const navigateToUser = (id) =>
    navigate(isSubscribed ? `/network/${id}` : '/signup');

  return (
    <div className="mt-20 mx-auto flex flex-col container px-6">
      <div className="font-chap text-xl">Top Members</div>
      <div className="flex mt-4 overflow-x-auto px-4">
        {members.map(
          ({
            id,
            first_name: firstName,
            last_name: lastName,
            image_url: imageUrl,
          }) => (
            <div
              key={id}
              onClick={() => navigateToUser(id)}
              className="flex flex-col justify-center items-center mb-4 mr-8 sm:mr-12 cursor-pointer"
              role="presentation"
            >
              <BackgroundSection
                className="bg-no-repeat bg-cover bg-center rounded-full w-20 h-20 sm:w-30 sm:h-30"
                backgroundImage={`url(${urlHandler(imageUrl)})`}
              />
              <div className="text-sm sm:text-md font-bold mt-3 mb-2 text-center whitespace-no-wrap">{`${firstName} ${lastName}`}</div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

TopMembers.propTypes = {
  members: PropTypes.array.isRequired,
};

export default TopMembers;

const NAME = 'EVENTS/FETCH_ATTENDING_EVENTS';

export const FETCH_ATTENDING_EVENTS_REQUEST = `${NAME}_REQUEST`;
export const FETCH_ATTENDING_EVENTS_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_ATTENDING_EVENTS_FAIL = `${NAME}_FAIL`;

export const fetchAttendingEvents = () => ({
  type: FETCH_ATTENDING_EVENTS_REQUEST,
});

export const fetchAttendingEventsSuccess = (data, page) => ({
  type: FETCH_ATTENDING_EVENTS_SUCCESS,
  data,
  page,
});

export const fetchAttendingEventsFail = (error) => ({
  type: FETCH_ATTENDING_EVENTS_FAIL,
  error,
});

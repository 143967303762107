import { all, fork } from 'redux-saga/effects';
import fetch from './fetch';
import create from './create';
import update from './update';
import approve from './approve';
import decline from './decline';
import bookings from './bookings';

export default function* consultationService() {
  yield all([fork(fetch)]);
  yield all([fork(create)]);
  yield all([fork(update)]);
  yield all([fork(approve)]);
  yield all([fork(decline)]);
  yield all([fork(bookings)]);
}

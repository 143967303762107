const NAME = 'STRIPE';

export const UPDATE_SUBSCRIPTION_REQUEST = `${NAME}/UPDATE_SUBSCRIPTION_REQUEST`;
export const UPDATE_SUBSCRIPTION_SUCCESS = `${NAME}/UPDATE_SUBSCRIPTION_SUCCESS`;
export const UPDATE_SUBSCRIPTION_FAIL = `${NAME}/UPDATE_SUBSCRIPTION_FAIL`;

export const updateSubscription = (data, callbacks) => ({
  type: UPDATE_SUBSCRIPTION_REQUEST,
  data,
  callbacks,
});

export const updateSubscriptionSuccess = () => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
});

export const updateSubscriptionFail = (error) => ({
  type: UPDATE_SUBSCRIPTION_FAIL,
  error,
});

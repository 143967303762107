import Actions from 'actions';

const initialState = Object.freeze({});

function slots(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_CREATED_EVENT_SUCCESS:
      return action.data;
    case Actions.SIGN_OUT:
    case Actions.CLEAR_FETCH_CREATED:
      return initialState;
    default:
      return state;
  }
}

export default slots;

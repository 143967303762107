export const NAME = 'SENDBIRD/CREATE_CHANNEL';

export const CREATE_CHANNEL_REQUEST = `${NAME}_REQUEST`;
export const CREATE_CHANNEL_SUCCESS = `${NAME}_SUCCESS`;
export const CREATE_CHANNEL_FAIL = `${NAME}_FAIL`;
export const CLEAR_CREATED_CHANNEL = `${NAME}_CLEAR`;

export const createChannel = (recipients, message, groupName, callbacks) => ({
  type: CREATE_CHANNEL_REQUEST,
  recipients,
  message,
  groupName,
  callbacks,
});

export const createChannelSuccess = (data) => ({
  type: CREATE_CHANNEL_SUCCESS,
  data,
});

export const createChannelFail = (error) => ({
  type: CREATE_CHANNEL_FAIL,
  error,
});

export const clearCreatedChannel = () => ({
  type: CLEAR_CREATED_CHANNEL,
});

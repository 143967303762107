const NAME = 'ARTICLES/FETCH_ARTICLE_CATEGORIES_';

export const FETCH_ARTICLE_CATEGORIES_REQUEST = `${NAME}REQUEST`;
export const FETCH_ARTICLE_CATEGORIES_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_ARTICLE_CATEGORIES_FAIL = `${NAME}FAIL`;

export const fetchArticleCategories = () => ({
  type: FETCH_ARTICLE_CATEGORIES_REQUEST,
});

export const fetchArticleCategoriesSuccess = (data) => ({
  type: FETCH_ARTICLE_CATEGORIES_SUCCESS,
  data,
});

export const fetchArticleCategoriesFail = () => ({
  type: FETCH_ARTICLE_CATEGORIES_FAIL,
});

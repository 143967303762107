import customer from './customer';
import subscription from './subscription';
import * as promo from './promo';
import portalSession from './portalSession';
import creditCard from './creditCard';

export default {
  ...customer,
  ...portalSession,
  ...subscription,
  ...promo,
  ...creditCard,
};

/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from 'components/UI/Loading';

const Button = (props) => {
  const {
    onClick,
    label,
    type,
    disabled,
    theme,
    outline,
    transparent,
    className,
    bold,
    uppercase,
    icon,
    isLoading,
    loadInButton,
    isPill,
  } = props;
  let style;

  switch (theme) {
    case 'violet-cyan':
      style = {
        borderColor: 'border-violet-cyan',
        bgColor: 'bg-gradient-violet-cyan',
        textColor: 'text-easter',
      };
      break;
    case 'violet':
      style = {
        borderColor: 'border-violet-valencia',
        bgColor: 'bg-gradient-violet-valencia',
        textColor: 'text-easter',
      };
      break;
    case 'easter':
      style = {
        borderColor: 'border-easter',
        bgColor: 'bg-easter',
        textColor: 'text-easter',
      };
      break;
    case 'plain':
      style = {
        borderColor: 'border-gray-400',
        bgColor: 'bg-transparent',
        textColor: 'text-gray-400',
      };
      break;
    case 'valencia':
      style = {
        borderColor: 'border-valencia',
        bgColor: 'bg-valencia',
        textColor: 'text-white',
      };
      break;
    default:
      style = {
        borderColor: 'border-easter',
        bgColor: 'bg-gradient-turqoise-violet',
        textColor: 'text-easter',
      };
      break;
  }

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const { bgColor, borderColor, textColor } = style;

  const isDisabled = disabled || isLoading;

  if (isLoading && !loadInButton) {
    return (
      <div className="flex">
        <Spinner />
      </div>
    );
  }
  return (
    <button
      disabled={isDisabled}
      className={classnames(
        { 'px-10': !isPill },
        'py-2 rounded-full focus:outline-none',
        'hover:opacity-75 transition-opacity duration-400 ease-in-out',
        className,
        borderColor,
        {
          'bg-gray-300 cursor-not-allowed': isDisabled,
          [`${textColor} border-2`]: outline && !isDisabled,
          [`${bgColor} text-white`]: !outline && !isDisabled,
        },
        transparent ? 'bg-transparent shadow-button' : 'bg-white'
      )}
      type={type}
      onClick={handleOnClick}
    >
      {isLoading ? (
        <Spinner height={20} color="#fff" />
      ) : (
        <>
          {icon}
          <span className={classnames({ 'font-semibold': bold, uppercase })}>
            {label}
          </span>
        </>
      )}
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  theme: PropTypes.oneOf([
    'default',
    'easter',
    'plain',
    'violet',
    'violet-cyan',
    'valencia',
  ]),
  outline: PropTypes.bool,
  transparent: PropTypes.bool,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadInButton: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.object,
  isPill: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  uppercase: true,
  bold: true,
  label: '',
  onClick: () => null,
  type: 'button',
  theme: 'default',
  outline: false,
  transparent: false,
  isLoading: false,
  loadInButton: false,
  className: null,
  icon: null,
  isPill: false,
};

export default Button;

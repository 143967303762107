const NAME = 'CONSULTATION/FETCH_BOOKINGS_';

export const FETCH_BOOKINGS_REQUEST = `${NAME}REQUEST`;
export const FETCH_BOOKINGS_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_BOOKINGS_FAIL = `${NAME}FAIL`;

export const fetchBookings = () => ({
  type: FETCH_BOOKINGS_REQUEST,
});

export const fetchBookingsSuccess = (data) => ({
  type: FETCH_BOOKINGS_SUCCESS,
  data,
});

export const fetchBookingsFail = () => ({
  type: FETCH_BOOKINGS_FAIL,
});

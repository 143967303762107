import React from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import Selectors from 'selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import TextInput from 'components/Form/TextInput';
import classnames from 'classnames';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import SelectInput from 'components/Form/SelectInput';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';
import { FREE, PREMIUM, ALL_ACCESS } from 'utils/constants';

const toOptions = (array) =>
  array.map((item) => ({
    label: startCase(item),
    value: item,
  }));

const BasicDetails = ({ memberTier, getPrice, showPrevScreen }) => {
  const dispatch = useDispatch();
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const countries = useSelector(Selectors.countryOptions);
  const countryList = (countries && Object.values(countries)) || [];
  const cities = useSelector(Selectors.citiesOfCountry) || [];

  const isLoadingCountryList = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_COUNTRY_OPTIONS_REQUEST])
  );
  const isLoadingCityList = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_CITIES_OF_COUNTRY_REQUEST])
  );

  const monthlyPrice = getPrice(memberTier, 'month');
  const annualPrice = getPrice(memberTier, 'year');
  return (
    <div className={classnames('flex flex-col pb-10 md:pb-0 px-16')}>
      <div className="font-chap text-gray-800 mb-1 text-2lg">
        Become a Member
      </div>
      <div className="text-dusty mb-3 text-xs">
        Join us and be part of the Gig Life Pro community!
      </div>
      <div className="border border-gray rounded-lg p-5 mb-4 text-center">
        <p>
          You have selected the{' '}
          <span
            className={classnames('font-semibold', {
              'text-violet': memberTier.name === ALL_ACCESS,
              'text-easter': memberTier.name === PREMIUM,
              'text-dusty': memberTier.name === FREE,
            })}
          >
            {memberTier.name}
          </span>{' '}
          membership tier
        </p>
        {!memberTier.is_free_tier && (
          <>
            <p className="text-lg font-semibold mt-3">
              USD {monthlyPrice}/month or USD {annualPrice}/year
            </p>
            <p className="text-dusty text-xs mt-3">
              * Payment will only be made after approval
            </p>
          </>
        )}
        <div className="flex-grow border-t border-gray-400 mt-3" />
        <p className="text-xs text-dusty mt-5">
          Choose a different{' '}
          <button
            className="text-easter"
            onClick={showPrevScreen}
            type="button"
          >
            membership tier
          </button>
        </p>
      </div>
      <div className="flex mb-5 lg:mb-0 flex-wrap lg:flex-no-wrap">
        <div className="mr-0 mb-5 lg:mr-8 lg:mb-0 lg:w-auto w-full">
          <Field
            name="first_name"
            component={TextInput}
            placeholder="First Name"
          />
        </div>
        <div className="lg:w-auto w-full">
          <Field
            name="last_name"
            component={TextInput}
            placeholder="Last Name"
          />
        </div>
      </div>
      <div className="relative">
        <Field name="email" component={TextInput} placeholder="Email" />
        <Tooltip
          title={
            <p className="text-xs">
              Your email will be hidden to everyone by default. You can change
              this later on your profile.
            </p>
          }
          placement="right"
          className="absolute right-0 top-0 mt-2"
        >
          <InfoIcon className="text-dusty hover:text-easter" />
        </Tooltip>
        <Field
          name="password"
          component={TextInput}
          placeholder="Password"
          type="password"
        />
        <Field
          name="confirmPassword"
          component={TextInput}
          placeholder="Confirm Password"
          type="password"
        />
      </div>
      <div className="flex flex-col lg:flex-row my-8 justify-between">
        <Field
          name="country"
          placeholder="Countries"
          component={SelectInput}
          isLoading={isLoadingCountryList}
          onChange={({ value }) => {
            dispatch(Actions.fetchCitiesOfCountry(value));
            setFieldValue('city', '');
            setFieldTouched('city', false);
          }}
          options={toOptions(countryList)}
        />
        <Field
          name="city"
          placeholder="Cities"
          component={SelectInput}
          isLoading={isLoadingCityList}
          isDisabled={isEmpty(cities)}
          options={toOptions(cities)}
        />
      </div>
    </div>
  );
};

BasicDetails.propTypes = {
  memberTier: PropTypes.object.isRequired,
  getPrice: PropTypes.func.isRequired,
  showPrevScreen: PropTypes.func.isRequired,
};
export default BasicDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'components/UI';
import {
  ALL_ACCESS,
  PREMIUM,
  PREMIUM_ALL_ACCESS,
  ALL_ACCESS_ONLY,
} from 'utils/constants';

export const memberAccessMap = {
  [PREMIUM_ALL_ACCESS]: `${ALL_ACCESS} & ${PREMIUM}`,
  [ALL_ACCESS_ONLY]: ALL_ACCESS,
};

const MemberAccessBadge = ({ memberAccess }) => {
  if ([PREMIUM_ALL_ACCESS, ALL_ACCESS_ONLY].includes(memberAccess)) {
    return (
      <Badge
        className="rounded-full overflow-hidden"
        bg="bg-gradient-turqoise-violet-light"
      >
        <div className="capitalize">{memberAccessMap[memberAccess]}</div>
      </Badge>
    );
  }

  return null;
};

MemberAccessBadge.propTypes = {
  memberAccess: PropTypes.string.isRequired,
};

export default MemberAccessBadge;

import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import { gfxAvatar } from 'assets';
import { Picture, Button, ErrorContainer, Loading } from 'components/UI';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const pixelRatio = window.devicePixelRatio || 1;

const AvatarInput = (props) => {
  const { fieldClassname } = props;
  const {
    values: { avatarUrl },
  } = useFormikContext();
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 55,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const dispatch = useDispatch();
  const isLoading = useSelector(
    Selectors.createLoadingSelector([
      Actions.UPLOAD_USER_IMAGE_REQUEST,
      Actions.DELETE_USER_IMAGE_REQUEST,
    ])
  );
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (avatarUrl) setPreview(avatarUrl);
  }, [avatarUrl]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) return;

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const resizeCanvas = (canvas, newWidth, newHeight) => {
    const tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext('2d');
    ctx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      newWidth,
      newHeight
    );

    return tmpCanvas;
  };

  const uploadAvatar = () => {
    const { current } = previewCanvasRef;
    if (!crop || !current) return;
    setError(null);
    const canvas = resizeCanvas(current, crop.width, crop.height);

    canvas.toBlob((blob) => {
      const file = new File([blob], Date.now());
      dispatch(
        Actions.uploadUserImage(file, {
          success: () => {
            setShowCrop(false);
            setPreview(URL.createObjectURL(file));
          },
          failure: (error) =>
            setError(error?.response?.data?.error || 'Something Went Wrong'),
        })
      );
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    const objectURL = URL.createObjectURL(acceptedFiles[0]);
    setPreview(objectURL);
    setError(null);
    setShowCrop(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: 'image/*',
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    multiple: false,
  });

  const RemoveButton = () => {
    previewCanvasRef.current = null;

    const removeFile = () => {
      if (showCrop) {
        setShowCrop(false);
        return setPreview(null);
      }

      setShowCrop(false);
      setPreview(null);
      dispatch(
        Actions.deleteUserImage({
          failure: (error) =>
            setError(error?.response?.data?.error || 'Something Went Wrong'),
        })
      );
    };

    return (
      <div
        className={classnames(
          'absolute top-0 right-0',
          'flex justify-center items-center',
          'h-4 w-4 bg-valencia rounded-full cursor-pointer',
          'text-xs text-white font-bold leading-loose'
        )}
        onClick={removeFile}
        role="presentation"
      >
        x
      </div>
    );
  };

  const DropContent = () => {
    const avatarStyle = 'rounded-full w-full h-full object-cover';

    if (isDragActive) return 'Drop the files here ...';
    if (isEmpty(preview))
      return (
        <Picture
          src={gfxAvatar}
          className={avatarStyle}
          disableParentClassName
        />
      );

    return (
      <>
        <RemoveButton />
        {showCrop ? (
          <canvas ref={previewCanvasRef} className={avatarStyle} />
        ) : (
          <img src={preview} alt="avatar" className={avatarStyle} />
        )}
      </>
    );
  };

  return (
    <div className={classnames('flex flex-col items-center', fieldClassname)}>
      {showCrop && (
        <ReactCrop
          src={preview}
          crop={crop}
          onChange={setCrop}
          ruleOfThirds
          onImageLoaded={onLoad}
          onComplete={setCompletedCrop}
          setUploadSuccess
          circularCrop
          className="mb-4"
        />
      )}
      <div onClick={open} {...getRootProps()} role="presentation">
        <input {...getInputProps()} />
        <div className="flex relative p-1 rounded-full shadow-card h-48 w-48">
          {isLoading && (
            <Loading className="absolute top-0 right-0 rounded-full h-full w-full" />
          )}
          <DropContent />
        </div>
      </div>
      <Button
        label={showCrop ? 'Confirm' : 'Upload Photo'}
        className="mx-auto text-sm my-8"
        outline
        onClick={showCrop ? uploadAvatar : open}
      />
      <ErrorContainer error={error} className="mb-8 -mt-4" />
    </div>
  );
};

AvatarInput.propTypes = {
  name: PropTypes.string.isRequired,
  fieldClassname: PropTypes.string,
  label: PropTypes.string,
};

AvatarInput.defaultProps = {
  fieldClassname: null,
  label: null,
};

export default AvatarInput;

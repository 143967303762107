import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';
import classnames from 'classnames';
import { Accordion } from 'components/UI';
import FeaturedLayout from '../common/FeaturedLayout';
import { QUESTIONS } from './content';

const CATEGORY = [
  { label: 'About Gig Life Pro', value: 'about' },
  'membership',
  { label: 'Subscription and Payments', value: 'subscription and payments' },
  'events',
  'locations',
  'articles',
  'network',
  'consultation',
  'perks',
];

const Faq = () => {
  const [category, setCategory] = useState('about');

  const FaqSidebar = () => {
    const handleSetCategory = (item) => () => {
      if (typeof item === 'object') setCategory(item.value);
      else setCategory(item);
    };

    return (
      <div className="w-full lg:w-1/4 ">
        <p className="text-dusty text-center lg:text-left">Category</p>
        <div className="flex lg:flex-col flex-wrap lg:flex-no-wrap justify-evenly lg:justify-start mb-8 lg:mb-0">
          {CATEGORY.map((item) => (
            <div
              className={classnames(
                'cursor-pointer text-md font-semibold mt-6 mr-4',
                {
                  'text-easter': item === category || item.value === category,
                }
              )}
              onClick={handleSetCategory(item)}
              role="presentation"
              key={item}
            >
              {typeof item === 'object' ? item.label : capitalize(item)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const currentContent = QUESTIONS.find(({ parent }) => parent === category);

  return (
    <div>
      <FeaturedLayout>
        <div className="flex flex-col text-center text-white relative m-auto">
          <h1 className="text-3xl font-semibold">FAQ</h1>
          <p className="text-lg">Frequently Asked Questions</p>
        </div>
      </FeaturedLayout>
      <div className="min-h-screen mx-auto px-4 container flex flex-col lg:flex-row mt-12">
        <FaqSidebar />
        <div className="flex-1">
          <Accordion
            content={currentContent.children}
            parentKey="question"
            childKey="answer"
          />
        </div>
      </div>
    </div>
  );
};

export default Faq;

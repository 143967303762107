import Actions from 'actions';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import api from 'api';

function* createPortalSession({ callbacks }) {
  try {
    const response = yield call(api.createPortalSession);
    yield put(Actions.createPortalSessionSuccess(response.data));
    if (typeof callbacks.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.createPortalSessionFail(error));
    if (typeof callbacks.failure === 'function') {
      yield call(callbacks.failure);
    }
  }
}

function* watchCreatePortalSession() {
  yield takeLatest(Actions.CREATE_PORTAL_SESSION_REQUEST, createPortalSession);
}

export default function* create() {
  yield all([fork(watchCreatePortalSession)]);
}

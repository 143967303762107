import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import { Breadcrumbs, Loading } from 'components/UI';
import { urlHandler } from 'utils/helper';
import { AuthContext } from 'utils/context';
import ApplySection from '../LandingPage/common/Apply';
import { BackgroundSection } from '../LandingPage/common';
import LanguageAndGenreCard from './LanguageAndGenreCard';
import AudienceReach from './AudienceReach';
import RelatedSection from '../Articles/Related';
import TopMembersSection from './TopMembersSection';
import UpcomingEvents from '../Events/Related';
import Venues from './Venues';
import VisaDetails from './VisaDetails';

const linearGradient = `linear-gradient(
  0deg,
  rgba(48, 45, 68, 0.1) 50%,
  rgba(48, 45, 68, 1) 100%,
  rgba(0, 212, 255, 0) 100%
)`;

const View = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: locationId } = useParams();
  const { pathname: currentPath } = useLocation();
  const { authenticated, hasAccess, isFreeTier } = useContext(AuthContext);
  const memberPage = '/members/locations';
  const locationsPath = authenticated ? memberPage : '/locations';
  const cityInfo = useSelector(Selectors.cityInfo);
  const showLockOverlay = !authenticated || isFreeTier;

  useEffect(() => {
    if (authenticated && hasAccess && !currentPath.includes(memberPage)) {
      navigate(`${memberPage}/${locationId}`);
    }
  }, [authenticated, navigate, hasAccess, locationId]);

  useEffect(() => {
    dispatch(Actions.fetchCityInfo(locationId));
  }, [dispatch, locationId]);

  if (!cityInfo?.id) return <Loading />;

  const {
    name,
    country,
    languages,
    image_url: imageUrl,
    top_genres: topGenres,
    visa_consulate: visaConsulate,
    visa_document: visaDocuments,
    visa_timeline: visaTimeline,
    visa_type: visaType,
    visa_details: visaDetails,
    venues,
    users,
    articles,
    events,
    marketing,
    conferences,
    festivals,
    labels,
    distribution,
    key_media: keyMedia,
    digital_streaming_platforms: digitalMedia,
    social_media: socialMedia,
    recording_studios: recordingStudios,
    sync_companies: syncCompanies,
    dsp_playlists: dspPlaylists,
    publishing_companies: publishingCompanies,
    promoters,
    record_stores: recordStores,
  } = cityInfo;

  const breadcrumbsTrail = [
    { label: 'Locations', path: locationsPath, icon: 'locations' },
    name,
  ];

  const audienceReach = {
    'Conferences / Showcase': conferences,
    'Digital Streaming Platforms': digitalMedia,
    Distribution: distribution,
    'DSP Playlists': dspPlaylists,
    Festivals: festivals,
    'Key Media': keyMedia,
    Labels: labels,
    Promoters: promoters,
    'PR & Marketing Agencies': marketing,
    'Publishing Companies': publishingCompanies,
    'Record Stores': recordStores,
    'Recording Studios': recordingStudios,
    'Social Media': socialMedia,
    'Sync Companies': syncCompanies,
  };

  const visa = {
    visaType,
    visaTimeline,
    visaDocuments,
    visaConsulate,
    visaDetails,
  };

  return (
    <div className="flex flex-col">
      {authenticated && <Breadcrumbs trails={breadcrumbsTrail} />}
      <BackgroundSection
        className="pt-16 pb-30 bg-no-repeat bg-cover bg-center bg-ebony bg-opacity-25"
        backgroundImage={`${linearGradient}, url(${urlHandler(imageUrl)})`}
      >
        <div className="container mx-auto px-6 pt-4">
          {!authenticated && (
            <Breadcrumbs trails={breadcrumbsTrail} color="text-white" />
          )}
          <div className="flex flex-col text-white text-center mt-16">
            <h1 className="sm:text-6xl text-4xl">{name}</h1>
            <div className="text-lg">{`Country: ${country.name}`}</div>
          </div>
        </div>
      </BackgroundSection>
      <LanguageAndGenreCard languages={languages} genres={topGenres} />
      <Venues venues={venues} showLockOverlay={showLockOverlay} />
      <TopMembersSection members={users} />
      <AudienceReach
        audienceReach={audienceReach}
        showLockOverlay={showLockOverlay}
      />
      <UpcomingEvents
        events={events}
        eventsPath={authenticated ? '/members/events/explore' : '/events'}
        fromLocationPage
      />
      <VisaDetails visaInfo={visa} showLockOverlay={showLockOverlay} />
      <RelatedSection
        articles={articles}
        articlesPath={authenticated ? '/members/articles/' : '/articles'}
        fromLocationPage
      />
      {!authenticated && <ApplySection />}
    </div>
  );
};

export default View;

import React from 'react';
import PropTypes from 'prop-types';

import { myPointsHero } from 'assets';
import { Spinner } from 'components/UI/Loading';

import { BackgroundSection } from '../../LandingPage/common';

const PointsHero = ({ currentPoints, lifetimePoints, isLoadingUserPoints }) => {
  if (isLoadingUserPoints) {
    return (
      <div className="flex my-20">
        <Spinner />
      </div>
    );
  }

  return (
    <BackgroundSection
      backgroundImage={`url(${myPointsHero.default})`}
      backgroundPosition="50% 0%"
      minHeight="230px"
      className="h-100 mt-24 pt-10 mb-1 bg-no-repeat bg-cover flex flex-col justify-start xl:h-140 xl:pt-20"
    >
      <section className="flex flex-col items-center">
        <p className="text-m xl:text-lg">Current Points</p>
        <p className="text-2xl font-semibold rounded-lg px-16 mt-1 bg-easter text-white xl:text-3xl">
          {currentPoints}
        </p>
        <p className="text-sm font-light text-easter bg-lily h-fit mt-2 p-2 rounded-lg xl:text-md">
          Lifetime points: {lifetimePoints}
        </p>
      </section>
    </BackgroundSection>
  );
};

PointsHero.propTypes = {
  currentPoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lifetimePoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoadingUserPoints: PropTypes.bool.isRequired,
};

PointsHero.defaultProps = {
  currentPoints: '-',
  lifetimePoints: '-',
};

export default PointsHero;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* declineBooking({ id, data, callbacks }) {
  try {
    yield call(api.declineBooking, { id, data });
    yield put(Actions.declineBookingSuccess());
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.declineBookingFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure);
    }
  }
}

function* watchDeclineBooking() {
  yield takeLatest(Actions.DECLINE_BOOKING_REQUEST, declineBooking);
}

export default function* decline() {
  yield all([fork(watchDeclineBooking)]);
}

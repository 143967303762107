const NAME = 'CONSULTATION/FETCH_SLOTS_';

export const FETCH_SLOTS_REQUEST = `${NAME}REQUEST`;
export const FETCH_SLOTS_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_SLOTS_FAIL = `${NAME}FAIL`;

export const fetchConsultationSlots = (id, date) => ({
  type: FETCH_SLOTS_REQUEST,
  id,
  date,
});

export const fetchConsultationSlotsSuccess = (data) => ({
  type: FETCH_SLOTS_SUCCESS,
  data,
});

export const fetchConsultationSlotsFail = () => ({
  type: FETCH_SLOTS_FAIL,
});

const SUBSCRIPTION = 'SUBSCRIPTION/FETCH_SUBSCRIPTION_';

export const FETCH_SUBSCRIPTION_REQUEST = `${SUBSCRIPTION}REQUEST`;
export const FETCH_SUBSCRIPTION_SUCCESS = `${SUBSCRIPTION}SUCCESS`;
export const FETCH_SUBSCRIPTION_FAIL = `${SUBSCRIPTION}FAIL`;

export const fetchSubscription = () => ({
  type: FETCH_SUBSCRIPTION_REQUEST,
});

export const fetchSubscriptionSuccess = (data) => ({
  type: FETCH_SUBSCRIPTION_SUCCESS,
  data,
});

export const fetchSubscriptionFail = () => ({
  type: FETCH_SUBSCRIPTION_FAIL,
});

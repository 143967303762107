import React, { useState } from 'react';
import { Picture, Accordion } from 'components/UI';
import styled from '@emotion/styled';
import {
  gfxConsultationApply,
  gfxConsultationApproval,
  gfxConsultationFollowUp,
  gfxConsultationZoom,
} from 'assets';
import { INSTRUCTIONS } from './instructions';

const IMAGES = {
  0: gfxConsultationApply,
  1: gfxConsultationApproval,
  2: gfxConsultationZoom,
  3: gfxConsultationFollowUp,
};

const BG_HEIGHT = 850;

const Background = styled.div`
  height: ${BG_HEIGHT}px;
  clip-path: polygon(0 0%, 100% 0%, 100% 88%, 0% 100%);
`;

const SectionContainer = styled.section`
  margin-top: -${BG_HEIGHT}px;
`;

const HowItWorks = () => {
  const [activePanel, setPanel] = useState(0);

  return (
    <>
      <Background className="bg-lily" />
      <SectionContainer className="mb-12 sm:mb-24 relative">
        <h2 className="text-center mb-4 sm:mb-8 font-semibold">How It Works</h2>
        <div className="container m-auto px-4 text-center sm:w-3/4">
          All eyes are on the Asia Pacific as one of the fastest growing music
          markets globally. Navigating its diverse cultures, languages, and
          platforms can be tricky. We help you succeed with on the ground
          experts from across the region. Whether you need general music career
          guidance or country-specific support, our consultants offer the
          expertise to fit your needs.
        </div>
        <div className="mx-auto px-4 container flex mt-6 sm:mt-12">
          <div className="sm:flex-1 sm:pr-10">
            <Accordion
              content={INSTRUCTIONS}
              parentKey="question"
              childKey="answer"
              onChange={setPanel}
              currentPanel={activePanel}
            />
            <div className="text-dusty">
              *Please also read the
              <a
                className="text-easter"
                href="/terms?type=Consultation"
                target="_blank"
                rel="noreferrer"
              >
                {' Terms & Conditions here'}
              </a>
            </div>
          </div>
          <div className="flex-1 hidden sm:flex justify-center items-center flex-">
            <Picture src={IMAGES[activePanel]} className="w-full" />
          </div>
        </div>
      </SectionContainer>
    </>
  );
};

export default HowItWorks;

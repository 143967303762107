const NAME = 'PERK/FETCH_REDEEMED_';

export const FETCH_REDEEMED_REQUEST = `${NAME}REQUEST`;
export const FETCH_REDEEMED_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_REDEEMED_FAIL = `${NAME}FAIL`;

export const fetchRedeemedPerks = () => ({
  type: FETCH_REDEEMED_REQUEST,
});

export const fetchRedeemedPerksSuccess = (data) => ({
  type: FETCH_REDEEMED_SUCCESS,
  data,
});

export const fetchRedeemedPerksFail = (error) => ({
  type: FETCH_REDEEMED_FAIL,
  error,
});

import React from 'react';
import { useSelector } from 'react-redux';
import Selectors from 'selectors';
import { Card, Picture, Button } from 'components/UI';
import { iconShareSocial } from 'assets';
import notify from 'utils/notification';

const ShareLink = () => {
  const currentUser = useSelector(Selectors.getUser);
  const link = `${window.location.origin}/consultation?name=${currentUser.id}`;

  const copyLink = () => {
    navigator.clipboard.writeText(link).then(
      () => notify('Link copied!'),
      () =>
        notify(
          'Failed to copy link. Please copy the link manually.',
          null,
          'danger'
        )
    );
  };

  return (
    <Card className="p-4 md:p-10 mt-5 flex flex-col md:flex-row">
      <Picture className="w-20 mr-4 hidden md:block" src={iconShareSocial} />
      <div className="max-w-full">
        <h3 className="font-lynstone mb-3">Share Consultation Link</h3>
        <p className="mb-5">
          Share your personalised consultation link. Just copy and paste the
          link to any social media posts to share.
        </p>
        <div className="px-4 py-2 mb-5 flex justify-between items-center border-2 border-dashed bg-white">
          <span className="mr-3 text-dusty overflow-x-scroll whitespace-no-wrap">
            {link}
          </span>
          <Button
            label="copy"
            className="py-1 hidden md:block"
            onClick={copyLink}
          />
        </div>
        <Button
          label="copy"
          className="block md:hidden py-1"
          onClick={copyLink}
        />
      </div>
    </Card>
  );
};

export default ShareLink;

const NAME = 'STRIPE/FETCH_USER_CREDIT_CARD';

export const FETCH_USER_CREDIT_CARD_REQUEST = `${NAME}_REQUEST`;
export const FETCH_USER_CREDIT_CARD_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_USER_CREDIT_CARD_FAIL = `${NAME}_FAIL`;

export const fetchUserCreditCard = (callbacks) => ({
  type: FETCH_USER_CREDIT_CARD_REQUEST,
  callbacks,
});

export const fetchUserCreditCardSuccess = (data) => ({
  type: FETCH_USER_CREDIT_CARD_SUCCESS,
  data,
});

export const fetchUserCreditCardFail = (error) => ({
  type: FETCH_USER_CREDIT_CARD_FAIL,
  error,
});

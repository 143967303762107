export const INSTRUCTIONS = [
  {
    id: 1,
    question: 'Step 1 : Apply for a consultation slot',
    answer:
      "When booking your consultation time slot, tell us what's on your mind. Briefly describe the topic(s) you'd like to discuss with our experts.",
  },
  {
    id: 2,
    question: 'Step 2 : Application approval',
    answer:
      'Once approved you will receive a confirmation email, followed by a direct email from Priya / Sarah to gather more information to prepare for the consultation',
  },
  {
    id: 3,
    question: 'Step 3 : Consultation on Zoom',
    answer:
      'The consultation will be done on ZOOM with a link provided by Gig Life Pro',
  },
  {
    id: 4,
    question: 'Step 4 : Receive follow ups',
    answer:
      'Within a week after the consultation, Priya / Sarah will send you a follow up email with notes, as well as connect you via email to a minimum of 3 music industry professionals based on your needs',
  },
];

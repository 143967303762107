import Actions from 'actions';

function info(state = null, { type, data }) {
  switch (type) {
    case Actions.FETCH_PERK_SUCCESS:
    case Actions.REDEEM_PERK_SUCCESS:
    case Actions.UPDATE_PERK_SUCCESS:
      return data;
    case Actions.FETCH_PERK_REQUEST:
    case Actions.SIGN_OUT:
      return null;
    default:
      return state;
  }
}

export default info;

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { gfxVideosEmpty } from 'assets';
import VideoCard from './VideoCard';

const EmptyList = () => (
  <div className="h-full flex justify-center items-center flex-col my-20">
    <img src={gfxVideosEmpty.default} alt="videos_empty" />
    <p className="mt-4 text-center text-md">
      Sorry we couldn&apos;t find the video that you&apos;re looking for…
    </p>
  </div>
);

const List = ({ videos, row }) => {
  if (!videos.length) return <EmptyList />;

  return (
    <div
      className={classnames({
        'grid sm:grid-cols-2 lg:grid-cols-3 gap-10 mb-12': !row,
        'flex flex-col': row,
      })}
    >
      {videos.map((v) => (
        <VideoCard video={v} key={v.id} row={row} />
      ))}
    </div>
  );
};

List.propTypes = {
  videos: PropTypes.array.isRequired,
  row: PropTypes.bool.isRequired,
};

export default List;

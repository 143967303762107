import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import PropTypes from 'prop-types';
import Selectors from 'selectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'components/UI/Loading';
import { Loading } from 'components/UI';
import Featured from './Featured';
import EventList from './List';

const Events = ({ events, eventsPath, featured }) => {
  const dispatch = useDispatch();
  const eventPageMeta = useSelector(Selectors.eventPageMeta);
  const { currentPage = 1, totalPages } = eventPageMeta;
  const fetchMoreEvents = () =>
    dispatch(Actions.fetchEvents({ page: currentPage + 1 }));
  if (!featured) return <Loading />;

  return (
    <div>
      <Featured event={featured} eventsPath={eventsPath} />
      <InfiniteScroll
        dataLength={events?.length || 0}
        next={fetchMoreEvents}
        hasMore={currentPage < totalPages}
        scrollableTarget="scrollableContent"
        loader={
          <div className="flex pb-8">
            <Spinner />
          </div>
        }
      >
        <EventList
          events={events}
          eventsPath={eventsPath}
          featuredId={featured.id}
        />
      </InfiniteScroll>
    </div>
  );
};

Events.propTypes = {
  events: PropTypes.array.isRequired,
  eventsPath: PropTypes.string.isRequired,
  featured: PropTypes.object,
};

Events.defaultProps = {
  featured: null,
};

export default Events;

import Actions from 'actions';

const initialState = Object.freeze([]);

function serviceBookings(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_SERVICE_BOOKINGS_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default serviceBookings;

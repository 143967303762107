const NAME = 'STRIPE/SETUP_INTENT_';

export const SETUP_INTENT_REQUEST = `${NAME}REQUEST`;
export const SETUP_INTENT_SUCCESS = `${NAME}SUCCESS`;
export const SETUP_INTENT_FAIL = `${NAME}FAIL`;

export const setupIntent = (data, callback) => ({
  type: SETUP_INTENT_REQUEST,
  data,
  callback,
});

export const setupIntentSuccess = (data) => ({
  type: SETUP_INTENT_SUCCESS,
  data,
});

export const setupIntentFail = (error) => ({
  type: SETUP_INTENT_FAIL,
  error,
});

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchLanguages({ callbacks }) {
  try {
    const response = yield call(api.fetchLanguages);
    yield put(Actions.fetchLanguagesSuccess(response?.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.fetchLanguagesFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchLanguages() {
  yield takeLatest(Actions.FETCH_LANGUAGES_REQUEST, fetchLanguages);
}

export default function* languages() {
  yield all([fork(watchFetchLanguages)]);
}

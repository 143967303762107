import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { experienceOptions } from 'utils/constants';

const ExperienceFilter = ({ className, handleTabChange, activeTab }) => {
  const ExperienceTabs = () => {
    return (
      <>
        {experienceOptions.map((experience) => (
          <button
            className={classnames(
              'rounded-full w-10/12 py-2 md:py-3 text-black',
              {
                'text-white bg-gradient-turqoise-violet':
                  activeTab === experience,
              }
            )}
            key={experience}
            type="button"
            onClick={() => handleTabChange(experience)}
          >
            {experience}
          </button>
        ))}
      </>
    );
  };

  return (
    <div
      className={classnames(
        'lg:w-9/12 xl:w-7/12 px-4 -mb-10 sm:mx-auto flex flex-col items-center justify-center text-center pt-10 space-y-4 z-20 overflow-visible',
        className
      )}
    >
      <h2 className="text-xl font-light">Our Membership Tiers</h2>
      <h3 className="font-light">
        What best describes your role and experience in the music industry?
      </h3>
      <p className="text-sm">
        We will recommend the membership tiers that best suit your experience
      </p>
      <div className="flex flex-col items-center md:flex-row md:rounded-full bg-white rounded-lg w-full py-3 space-y-2 md:space-y-0 md:py-1 md:px-2 text-xs lg:text-sm border-gray border ">
        <ExperienceTabs />
      </div>
    </div>
  );
};

ExperienceFilter.propTypes = {
  className: PropTypes.string,
  handleTabChange: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};

ExperienceFilter.defaultProps = {
  className: null,
};

export default ExperienceFilter;

const NAME = 'EVENTS/DELETE_EVENT_';

export const DELETE_EVENT_REQUEST = `${NAME}REQUEST`;
export const DELETE_EVENT_SUCCESS = `${NAME}SUCCESS`;
export const DELETE_EVENT_FAIL = `${NAME}FAIL`;

export const deleteEvent = (id, callbacks) => ({
  type: DELETE_EVENT_REQUEST,
  id,
  callbacks,
});

export const deleteEventSuccess = (id) => ({
  type: DELETE_EVENT_SUCCESS,
  id,
});

export const deleteEventFail = (error) => ({
  type: DELETE_EVENT_FAIL,
  error,
});

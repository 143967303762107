import axios from 'axios';
import CONFIG from 'utils/config';
import Actions from 'actions';
import { getStore } from 'store';

const axiosClient = axios.create({
  baseURL: CONFIG.SERVER_URL,
  withCredentials: true,
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const unauthorizedError = error?.response?.status === 401;
    const isLoginPath = window.location.pathname === '/login';

    if (!isLoginPath && unauthorizedError) {
      const { dispatch } = getStore();
      dispatch(Actions.clearUser());
    }
    return Promise.reject(error);
  }
);

const commonParams = (method, endpoint) => ({
  method,
  url: `/api${endpoint}`,
  withCredentials: true,
});

const request = (method, endpoint, params = {}) => {
  if (method === 'get') {
    return axiosClient({
      params,
      ...commonParams(method, endpoint),
    });
  }
  return axiosClient({
    data: params,
    ...commonParams(method, endpoint),
  });
};

const multipartRequest = (
  method,
  endpoint,
  { params, data, responseType, fileType }
) => {
  const formData = new FormData();
  if (fileType) {
    formData.append(fileType, data);
  } else {
    Object.entries(data).forEach(([k, v]) => {
      formData.append(k, v);
    });
  }

  return axiosClient({
    params,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    responseType,
    ...commonParams(method, endpoint),
  });
};

const api = {
  get(endpoint, params) {
    return request('get', endpoint, params);
  },
  post(endpoint, params) {
    return request('post', endpoint, params);
  },
  put(endpoint, params) {
    return request('put', endpoint, params);
  },
  patch(endpoint, params) {
    return request('patch', endpoint, params);
  },
  delete(endpoint, params) {
    return request('delete', endpoint, params);
  },
  multipartPost(endpoint, params = {}) {
    return multipartRequest('post', endpoint, params);
  },
  multipartPut(endpoint, params = {}) {
    return multipartRequest('put', endpoint, params);
  },
};

export default api;

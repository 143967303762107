import Actions from 'actions';

const initialState = Object.freeze({});

function info(state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_EVENT_SUCCESS:
    case Actions.SIGN_OUT:
    case Actions.SIGN_IN_SUCCESS:
      return {
        ...initialState,
      };
    case Actions.FETCH_EVENT_INFO_SUCCESS:
      return {
        ...initialState,
        data: action.data,
      };
    case Actions.FETCH_EVENT_INFO_FAIL:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
}

export default info;

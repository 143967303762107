import Actions from 'actions';
import { setCacheExp } from 'utils/dataCache';

const initialState = Object.freeze({ exp: null });

function getCount(state = initialState, { type, data }) {
  switch (type) {
    case Actions.FETCH_COUNT_SUCCESS:
      return { data, exp: setCacheExp(10) };
    default:
      return state;
  }
}

export default getCount;

const NAME = 'CONSULTATION/FETCH_CONSULTANTS_';

export const FETCH_CONSULTANTS_REQUEST = `${NAME}REQUEST`;
export const FETCH_CONSULTANTS_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_CONSULTANTS_FAIL = `${NAME}FAIL`;

export const fetchConsultants = (callbacks) => ({
  type: FETCH_CONSULTANTS_REQUEST,
  callbacks,
});

export const fetchConsultantsSuccess = (data) => ({
  type: FETCH_CONSULTANTS_SUCCESS,
  data,
});

export const fetchConsultantsFail = () => ({
  type: FETCH_CONSULTANTS_FAIL,
});

import React from 'react';
import classnames from 'classnames';
import { Button, Card } from 'components/UI';
import { bgCommunity } from 'assets';
import { useNavigate } from 'react-router-dom';
import { isProduction } from 'utils/config';
import { BackgroundSection } from '../common';

const VIDEO_URL = isProduction
  ? 'https://www.youtube.com/embed/qEjep6_vZYk?autoplay=1&loop=1&playlist=qEjep6_vZYk&mute=1'
  : 'https://www.youtube.com/embed/qEjep6_vZYk';

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-24 md:mt-40">
      <BackgroundSection
        backgroundImage={`url(${bgCommunity.default})`}
        maxHeight="640px"
        className="h-screen bg-no-repeat bg-cover xl:bg-full bg-center pt-20 px-4 sm:mb-10 md:-mb-16"
      >
        <div className="flex justify-center max-w-6xl mx-auto -mt-32 md:-mt-40 px-4 md:px-8">
          <div className="grid lg:grid-cols-5 items-center">
            <div className="lg:col-span-3 mb-8 sm:m-0 relative rounded overflow-hidden">
              <iframe
                title="landingVideo"
                height="400"
                src={VIDEO_URL}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full mx-auto lg:m-0"
              />
            </div>
            <Card
              className={classnames(
                'flex flex-col items-center justify-center text-center',
                'py-4 sm:py-12 px-4 sm:px-4 lg:py-8 sm:mx-12 sm:-mt-8 lg:mx-0 lg:my-12 lg:col-span-2',
                'rounded lg:rounded-l-none lg:rounded-tr'
              )}
            >
              <h4>WHAT IS GIG LIFE PRO?</h4>
              <p className="text-center sm:my-8">
                Gig Life Pro is a networking platform designed to support all
                global music industry professionals and creatives in developing
                their connections.
              </p>
              <p className="text-center sm:mb-8">
                We are dedicated to supporting members through curating industry
                panels, workshops, mixers, intimate dinners, after-parties,
                trade missions and online networking events to exchange highly
                specialised knowledge of a rapidly expanding industry.
              </p>
              <div className="mt-4 lg:mt-0">
                <Button
                  label="Apply to be a member"
                  onClick={() => navigate('/signup')}
                  className="py-2 lg:py-4"
                />
              </div>
            </Card>
          </div>
        </div>
      </BackgroundSection>
    </div>
  );
};

export default HeroSection;

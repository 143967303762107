import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import classnames from 'classnames';
import Selectors from 'selectors';
import { Breadcrumbs, PageMeta, Button, Loading, Card } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import { Field, Formik, Form } from 'formik';
import { TextInput, SelectInput } from 'components/Form';
import startCase from 'lodash/startCase';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppsIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/List';
import { AuthContext } from 'utils/context';
import Player from './Player';
import List from './List';

const INITIAL_VALUES = { search: '', video_category_id: null, sort: null };
const toOptions = (array) =>
  array.map((item) => ({
    label: startCase(item.name || item),
    value: item.id || item,
  }));
const SORT_OPTIONS = [
  { label: 'Latest', value: null },
  { label: 'Oldest', value: 'published_at' },
  { label: 'Most Views', value: 'views DESC' },
];

const Videos = () => {
  const [id, setId] = useState();
  const { isFreeTier } = useContext(AuthContext);
  const [rowView, setRowView] = useState(false);
  const [params, setParams] = useState({});
  const dispatch = useDispatch();
  const videos = useSelector(Selectors.getVideos);
  const { currentPage, totalPages } = useSelector(Selectors.videosPageMeta);
  const categories = useSelector(Selectors.getVideoCategories);
  const categoriesExpired = useSelector(Selectors.videoCategoriesCacheExpired);
  const setFeaturedId = useCallback(
    (videos) => {
      const featuredId = isFreeTier
        ? videos.find((video) => !video.paid_content)?.id
        : videos[0]?.id;
      setId(featuredId || null);
    },
    [isFreeTier]
  );
  const fetchVideos = (values) => {
    dispatch(
      Actions.fetchVideos(values, {
        success: (data) => {
          if (!id && isFreeTier) setFeaturedId(data);
          setParams(values);
        },
      })
    );
  };
  const loadMore = () => {
    dispatch(
      Actions.fetchVideos(
        { ...params, page: currentPage + 1 },
        {
          success: (data) => {
            if (!id && isFreeTier) setFeaturedId(data);
          },
        }
      )
    );
  };

  const ListViewToggle = () => {
    return (
      <div
        onClick={() => setRowView(!rowView)}
        className="ml-auto mb-2 cursor-pointer"
        role="presentation"
      >
        <Card className="flex bg-white overflow-hidden">
          <div
            className={classnames('p-3', {
              'bg-easter text-concrete': !rowView,
              'text-easter': rowView,
            })}
          >
            <AppsIcon />
          </div>
          <div
            className={classnames('p-3', {
              'bg-easter text-concrete': rowView,
              'text-easter': !rowView,
            })}
          >
            <ListIcon />
          </div>
        </Card>
      </div>
    );
  };

  useEffect(() => {
    dispatch(
      Actions.fetchVideos(null, {
        success: (data) => setFeaturedId(data),
      })
    );
  }, [dispatch, isFreeTier, setFeaturedId]);

  useEffect(() => {
    if (categoriesExpired) dispatch(Actions.fetchVideoCategories());
  }, [dispatch, categoriesExpired]);

  return (
    <div className="h-screen">
      <PageMeta title="Videos" />
      <Breadcrumbs
        trails={[{ label: 'Videos', path: '/members/videos', icon: 'videos' }]}
      />
      {id !== undefined || videos.length > 0 ? (
        <>
          {id ? <Player id={id} /> : <div className="pt-12" />}
          <InfiniteScroll
            dataLength={videos.length}
            next={loadMore}
            hasMore={totalPages > currentPage}
            scrollableTarget="scrollableContent"
            loader={
              <div className="flex pb-8">
                <Spinner />
              </div>
            }
          >
            <div className="container mx-auto py-8 px-6 h-1/2">
              <Formik onSubmit={fetchVideos} initialValues={INITIAL_VALUES}>
                {({ handleSubmit }) => (
                  <Form className="flex items-center mb-8 flex-col xl:flex-row sm:gap-4">
                    <div className="flex">
                      <Field
                        name="search"
                        className="h-12 rounded-r-none rounded-l-full pl-5 sm:pl-8 mr-32 sm:m-0 sm:min-w-80"
                        component={TextInput}
                        basic
                        placeholder="Search by name or tags"
                        onChange={(v) => !v && handleSubmit()}
                      />
                      <Button
                        className="h-12 rounded-l-none rounded-r-full mt-2 text-xs sm:text-base px-4 sm:px-10"
                        label="Search"
                        type="submit"
                        isPill
                      />
                    </div>
                    <div className="flex items-center w-full flex-col md:flex-row sm:gap-4">
                      <div className="flex flex-col sm:flex-row sm:gap-4 w-full justify-center md:justify-start">
                        <Field
                          name="video_category_id"
                          placeholder="All Categories"
                          component={SelectInput}
                          onChange={handleSubmit}
                          options={[
                            { label: 'All Categories', value: null },
                            ...toOptions(categories),
                          ]}
                          className="mb-2"
                          showError={false}
                        />
                        <Field
                          name="sort"
                          placeholder="Latest"
                          component={SelectInput}
                          onChange={handleSubmit}
                          options={SORT_OPTIONS}
                          className="mb-2"
                          showError={false}
                        />
                      </div>
                      <ListViewToggle />
                    </div>
                  </Form>
                )}
              </Formik>
              <List videos={videos} row={rowView} />
            </div>
          </InfiniteScroll>
        </>
      ) : (
        <Loading
          className="bg-white h-full flex justify-center items-center"
          opaque={false}
        />
      )}
    </div>
  );
};

export default Videos;

const NAME = 'VIDEO/FETCH_VIDEOS_';

export const FETCH_VIDEOS_REQUEST = `${NAME}REQUEST`;
export const FETCH_VIDEOS_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_VIDEOS_FAIL = `${NAME}FAIL`;

export const fetchVideos = (params, callbacks) => ({
  type: FETCH_VIDEOS_REQUEST,
  params,
  callbacks,
});

export const fetchVideosSuccess = (data, exp, page) => ({
  type: FETCH_VIDEOS_SUCCESS,
  data,
  exp,
  page,
});

export const fetchVideosFail = (error) => ({
  type: FETCH_VIDEOS_FAIL,
  error,
});

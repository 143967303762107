import React, { useState } from 'react';
import classnames from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FiberManualRecord, KeyboardArrowDown } from '@material-ui/icons';

const ReferralInfoSteps = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openAccordion = () => setIsOpen(!isOpen);

  return (
    <>
      <section className="mt-10 p-4 border-2 rounded-lg self-start lg:w-1/2">
        <article className="flex justify-between items-center">
          <h3 className="text-md">How does it work?</h3>
          {!isOpen ? (
            <ChevronRightIcon
              className="mr-1"
              fontSize="large"
              onClick={openAccordion}
              role="presentation"
            />
          ) : (
            <KeyboardArrowDown
              className="mr-1 text-easter"
              fontSize="large"
              onClick={openAccordion}
              role="presentation"
            />
          )}
        </article>

        <article
          className={classnames({
            hidden: !isOpen,
          })}
        >
          <section className="sm:text-md mt-5 mr-10 ">
            <p className="text-xl text-easter font-light">01</p>
            Copy and share your referral code to your friends.
          </section>
          <section className="sm:text-md mt-2">
            <p className="text-xl text-easter font-light">02</p>
            Ask your friend to sign up with your referral code!
          </section>
          <section className="sm:text-md mt-2">
            <p className="text-xl text-easter font-light">03</p>
            Once your friend has sucessfully registered as a Premium or All
            Access member, we willl credit points for you to redeem your free
            subscription with us!
          </section>
        </article>
      </section>
    </>
  );
};

const RedeemInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openAccordion = () => setIsOpen(!isOpen);

  return (
    <>
      <section className="mt-5 py-4 border-2 rounded-lg self-start lg:w-1/2 lg:mt-10">
        <div className="flex pl-4 justify-between items-center">
          <h3 className="text-md">What Can I redeem?</h3>
          {!isOpen ? (
            <ChevronRightIcon
              className="mr-1"
              fontSize="large"
              onClick={openAccordion}
              role="presentation"
            />
          ) : (
            <KeyboardArrowDown
              className="mr-1 text-easter"
              fontSize="large"
              onClick={openAccordion}
              role="presentation"
            />
          )}
        </div>

        <article
          className={classnames({
            hidden: !isOpen,
          })}
        >
          <section className="text-md mt-5 px-6 mr-10">
            <p className="text-md text-easter font-semibold">3 points</p>1 month
            FREE <span className="font-bold">Premium</span> subscription
          </section>
          <section className="text-md px-6 mt-10">
            <p className="text-md text-easter font-semibold">6 points</p>1 month
            FREE <span className="font-bold">All Acceess</span> subscription
          </section>
          <section className="mt-10 bg-lily px-6 py-10">
            <p className="text-md font-semibold">
              Get points from sucessful referrals
            </p>
            <article className="space-y-5 mt-10">
              <ol className="text-md">
                <FiberManualRecord
                  className="text-easter mr-2"
                  fontSize="small"
                />
                1 succesful Premium referral ={' '}
                <span className="font-semibold">2 Points</span>
              </ol>
              <ol className="text-md">
                <FiberManualRecord
                  className="text-easter mr-2"
                  fontSize="small"
                />
                1 succesful All Access referral ={' '}
                <span className="font-semibold">6 Points</span>
              </ol>
            </article>
          </section>
        </article>
      </section>
    </>
  );
};

const HowAndWhatInfo = () => {
  return (
    <>
      <ReferralInfoSteps />
      <RedeemInfo />
    </>
  );
};

export default HowAndWhatInfo;

import Actions from 'actions';

const initialState = Object.freeze({});

function create(state = initialState, action) {
  switch (action.type) {
    case Actions.CREATE_CHANNEL_REQUEST:
    case Actions.CREATE_CHANNEL_FAIL:
      return initialState;
    case Actions.CREATE_CHANNEL_SUCCESS:
      return {
        ...initialState,
        data: action.data,
      };
    case Actions.CLEAR_CREATED_CHANNEL:
    default:
      return state;
  }
}

export default create;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';
import { paginationHelper } from 'utils/helper';
import { isEmpty } from 'lodash';

function* fetchArticles({ params = {}, callbacks }) {
  try {
    const response = yield call(api.fetchArticles, params);
    const { data, headers } = response;
    const pagination = paginationHelper(headers);
    const { favourite, page, ...filterParams } = params;
    if (data.length === 0) {
      // re-fetch articles without params to get featured article
      const response = yield call(api.fetchArticles, { page: 1 });
      const { data } = response;
      const featured = data.filter(({ feature }) => feature)[0] || data[0];
      // for setting featured article
      yield put(Actions.fetchArticlesSuccess([featured], pagination, true));
      // for clearing result data
      yield put(Actions.fetchArticlesSuccess([], pagination, false));
    } else {
      yield put(
        Actions.fetchArticlesSuccess(data, pagination, isEmpty(filterParams))
      );
    }

    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.fetchArticlesFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchArticles() {
  yield takeLatest(Actions.FETCH_ARTICLES_REQUEST, fetchArticles);
}

export default function* list() {
  yield all([fork(watchFetchArticles)]);
}

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const EventTab = ({ currentActive, handleTab }) => {
  const layout = (title, path) => {
    const isActivePage = currentActive === path;

    return (
      <div className="sm:px-6 w-1/2 sm:w-auto text-center">
        <div
          className={classnames(
            'flex px-4 pb-2 justify-center sm:justify-start',
            { 'border-b-4 border-sigmaBlue': isActivePage }
          )}
        >
          <div className="cursor-pointer text-dusty">
            <div
              role="button"
              tabIndex="0"
              className="flex items-center focus:outline-none"
              onClick={() => handleTab(path)}
            >
              <div className={classnames({ 'text-sigmaBlue': isActivePage })}>
                {title}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex fixed z-10 shadow-card bg-white h-24 items-end">
      {layout('Explore Events', '/members/events/explore')}
      {layout('My Events', '/members/events/my-events')}
    </div>
  );
};

EventTab.propTypes = {
  currentActive: PropTypes.string.isRequired,
  handleTab: PropTypes.func.isRequired,
};

export default EventTab;

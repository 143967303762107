/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import { Formik, Form, Field } from 'formik';
import {
  TextInput,
  SelectInput,
  ImageInput,
  RadioButtonInput,
  DateRangeInput,
  SocialMediaLinkInput,
  SingleCheckboxInput,
} from 'components/Form';
import { Card, ErrorContainer } from 'components/UI';
import * as Yup from 'yup';
import { isEmpty, pickBy } from 'lodash';
import startCase from 'lodash/startCase';
import notify from 'utils/notification';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';
import {
  ALL_ACCESS,
  PREMIUM,
  PREMIUM_ALL_ACCESS,
  ALL_ACCESS_ONLY,
  ALL_MEMBERS,
} from 'utils/constants';
import { AuthContext } from 'utils/context';
import FormFooter from './FormFooter';

const RADIO_OPTIONS = [
  { label: 'All members', value: ALL_MEMBERS },
  {
    label: `${ALL_ACCESS} & ${PREMIUM} members`,
    value: PREMIUM_ALL_ACCESS,
  },
  { label: `${ALL_ACCESS} members only`, value: ALL_ACCESS_ONLY },
];

const defaultValues = {
  name: '',
  summary: '',
  description: '',
  promo_code: '',
  redemption_limit: '',
  redemption_link: '',
  member_access: '',
  start_date: null,
  end_date: null,
  city: '',
  country: '',
  image: null,
  notify_members: false,
};

const promoCodeErrorMsg = 'Either Promo Code or Redemption Link is required';
const ValidationSchema = Yup.object().shape(
  {
    name: Yup.string().required('Required'),
    summary: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    redemption_link: Yup.string()
      .url('Link must be a valid Url')
      .when('promo_code', (v, schema) =>
        v ? schema : schema.required(promoCodeErrorMsg)
      ),
    start_date: Yup.string().nullable().required('Required'),
    end_date: Yup.string().nullable().required('Required'),
    image: Yup.mixed().required('Required'),
    member_access: Yup.string().required('Required'),
    promo_code: Yup.string().when('redemption_link', (v, schema) =>
      v ? schema : schema.required(promoCodeErrorMsg)
    ),
    redemption_limit: Yup.number().typeError('Value must be a number'),
    country: Yup.string().nullable(),
    city: Yup.string().nullable(),
    notify_members: Yup.bool(),
  },
  ['promo_code', 'redemption_link']
);

const toOptions = (array) =>
  array.map((item) => ({
    label: startCase(item.name || item),
    value: item.id || item,
  }));

const PerkForm = ({ perk }) => {
  const { isPro } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const countries = useSelector(Selectors.countryOptions);
  const cities = useSelector(Selectors.citiesOfCountry);
  const emptyCountries = isEmpty(countries);
  const formattedPerkValues = perk && {
    ...perk,
    redemption_link: perk.redemption_link || '',
    redemption_limit: perk.redemption_limit ? `${perk.redemption_limit}` : '',
    description: perk.description_text,
    image: perk.image_url,
    start_date: new Date(perk.start_date),
    end_date: new Date(perk.end_date),
  };
  const initialValues = formattedPerkValues || defaultValues;
  const { id, image, country } = initialValues;
  const isLoadingCountryList = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_COUNTRY_OPTIONS_REQUEST])
  );
  const isLoadingCityList = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_CITIES_OF_COUNTRY_REQUEST])
  );
  const submitPerk = (values) => {
    const filtered = pickBy(values, (v) => v !== null);
    return perk ? updatePerk(filtered) : createPerk(filtered);
  };
  const radioOptions = isPro
    ? RADIO_OPTIONS
    : RADIO_OPTIONS.filter(({ value }) => value !== ALL_ACCESS_ONLY);

  const createPerk = (values) => {
    const { draft } = values;
    const callbacks = {
      success: ({ id }) => {
        dispatch(Actions.hideDialog());
        notify(
          draft ? 'Perk saved as draft' : 'Perk created, pending admin approval'
        );
        navigate(`/members/perks/my-perks/${id}`);
      },
      fail: (error) => {
        setError(error);
        dispatch(Actions.hideDialog());
      },
    };

    if (draft) return dispatch(Actions.createPerk(values, callbacks));

    dispatch(
      Actions.showDialog({
        title: 'CREATE AND NOTIFY ADMIN',
        content:
          'Creating a perk requires admin approval, it will be published once approved.',
        acceptLabel: 'YES, CREATE PERK',
        dismissLabel: "NO, DON'T CREATE",
        onAccept: () => dispatch(Actions.createPerk(values, callbacks)),
      })
    );
  };

  const updatePerk = (values) => {
    const callbacks = {
      success: () => {
        notify('Perk is updated');
        navigate(`/members/perks/my-perks/${id}`);
      },
      fail: setError,
    };

    dispatch(Actions.updatePerk(id, values, callbacks));
  };

  const isSubmitting = useSelector(
    Selectors.createLoadingSelector([
      Actions.CREATE_PERK_REQUEST,
      Actions.UPDATE_PERK_REQUEST,
    ])
  );

  useEffect(() => {
    if (emptyCountries) dispatch(Actions.fetchCountryOptions());
  }, [dispatch, emptyCountries]);

  useEffect(() => {
    if (country) dispatch(Actions.fetchCitiesOfCountry(country));
  }, [dispatch, country]);

  return (
    <div className="lg:container mx-auto pt-30 sm:px-6 px-2 max-w-full mb-30">
      <Card className="mx-auto border-t-2 p-4 sm:p-10 mt-5">
        <div className="mb-4">
          <div className="text-2lg font-chap">
            {`${perk ? 'Update' : 'Create'} Perk`}
          </div>
        </div>
        <Formik
          onSubmit={submitPerk}
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          enableReinitialize
        >
          {({ setFieldValue, setFieldTouched, values, handleSubmit }) => (
            <Form>
              <Field
                name="name"
                component={TextInput}
                placeholder="Perk Title (e.g. 50% Off Summerfest Event tickets)"
              />
              <Field
                name="summary"
                component={TextInput}
                placeholder="Short summary of this perk"
              />
              <Field
                name="description"
                component={TextInput}
                placeholder="Description"
                type="textarea"
                maxLength={2000}
                className="h-30"
              />
              <div className="relative sm:max-w-lg">
                <Field
                  name="promo_code"
                  component={TextInput}
                  placeholder="Promo Code (e.g. PROMOCODE123)"
                  className="w-full my-2"
                />
                <Tooltip
                  title="This will be redeemed by members"
                  placement="right"
                  className="absolute right-0 top-0 mt-2"
                >
                  <InfoIcon className="text-dusty hover:text-easter" />
                </Tooltip>
              </div>
              <div className="relative sm:max-w-lg">
                <Field
                  name="redemption_limit"
                  component={TextInput}
                  placeholder="Redemption limit"
                  className="w-full my-2"
                />
                <Tooltip
                  title="Promo code will be hidden once it is fully redeemed"
                  placement="right"
                  className="absolute right-0 top-0 mt-2"
                >
                  <InfoIcon className="text-dusty hover:text-easter" />
                </Tooltip>
              </div>
              <p className="text-dusty">Redemption Link</p>
              <SocialMediaLinkInput
                className="w-full"
                name="redemption_link"
                placeholder="Paste URL for members to redeem the promo code"
              />
              <p className="text-dusty">Membership Eligiblity</p>
              <div className="my-2">
                <Field
                  name="member_access"
                  component={RadioButtonInput}
                  options={radioOptions}
                />
              </div>
              <p className="text-dusty">Perk Validity Period</p>
              <div className="flex my-2">
                <Field
                  name="start_date"
                  component={DateRangeInput}
                  onChange={(dates) => {
                    setFieldValue('start_date', dates[0]);
                    setFieldValue('end_date', dates[1]);
                    setFieldTouched('start_date', false);
                  }}
                  containerClassName="my-2"
                  initialValues={[values.start_date, values.end_date]}
                />
              </div>
              <p className="text-dusty">Location (optional)</p>
              <div className="flex flex-col lg:flex-row mt-2 mb-4">
                <Field
                  name="country"
                  placeholder="Country"
                  component={SelectInput}
                  isLoading={isLoadingCountryList}
                  onChange={({ value }) => {
                    dispatch(Actions.fetchCitiesOfCountry(value));
                    setFieldValue('city', '');
                    setFieldTouched('city', false);
                  }}
                  options={toOptions(Object.values(countries))}
                  className="mr-4"
                />
                <Field
                  name="city"
                  placeholder="City"
                  component={SelectInput}
                  isLoading={isLoadingCityList}
                  isDisabled={isEmpty(cities) || !values.country}
                  options={toOptions(cities)}
                />
              </div>
              <Field
                name="image"
                component={ImageInput}
                initialImgUrl={image}
              />
              {!initialValues.notify_members && (
                <Field
                  name="notify_members"
                  component={SingleCheckboxInput}
                  className="mt-4"
                  label="Notify members of this new perk through email"
                />
              )}
              <FormFooter
                isSubmitting={isSubmitting}
                onCancel={() => navigate(`/members/perks/my-perks`)}
                onSubmit={() => {
                  values.draft = false;
                  handleSubmit();
                }}
                onSubmitDraft={() => {
                  values.draft = true;
                  handleSubmit();
                }}
                isUpdate={!!perk}
              />
              <ErrorContainer error={error} className="mt-4" />
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

PerkForm.propTypes = {
  perk: PropTypes.object,
};

PerkForm.defaultProps = {
  perk: null,
};

export default PerkForm;

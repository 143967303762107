const NAME = 'TIMEZONE/FETCH_TIMEZONES_';

export const FETCH_TIMEZONES_REQUEST = `${NAME}REQUEST`;
export const FETCH_TIMEZONES_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_TIMEZONES_FAIL = `${NAME}FAIL`;

export const fetchTimezones = () => ({
  type: FETCH_TIMEZONES_REQUEST,
});

export const fetchTimezonesSuccess = (data) => ({
  type: FETCH_TIMEZONES_SUCCESS,
  data,
});

export const fetchTimezonesFail = () => ({
  type: FETCH_TIMEZONES_FAIL,
});

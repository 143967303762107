import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/UI';

const CityFilterInput = ({ cities, onChange, defaultValue, className }) => {
  return (
    <Select
      key={defaultValue}
      className={className}
      onChange={onChange}
      options={[{ label: 'All Cities', value: null }, ...cities]}
      placeholder="All Cities"
      value={cities.find(({ value }) => value === defaultValue)}
    />
  );
};

CityFilterInput.propTypes = {
  cities: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
};

CityFilterInput.defaultProps = {
  defaultValue: null,
  className: '',
};

export default CityFilterInput;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import useQuery from 'hooks/useQuery';
import Featured from './Featured';
import Consultants from './Consultants';
import BookingForm from './BookingForm';
import HowItWorks from './HowItWorks';

const Landing = ({ consultants, isSubscribed, handleTab, consultantIndex }) => {
  const params = useQuery();
  const [selectedConsultantId, setConsultantId] = useState(null);

  const scrollToForm = (consultantId) => {
    setConsultantId(consultantId);
    const element = document.getElementById('booking-form');
    element.scrollIntoView({
      behavior: 'smooth',
      block: isSubscribed ? 'end' : 'center',
    });
  };

  const scrollToConsultant = () => {
    const element = document.getElementById('consultant');
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useEffect(() => {
    if (!isEmpty(params) && consultants.length > 0) {
      scrollToConsultant();
    }
  }, [params]);

  const linkToForm = isSubscribed
    ? '/members/consultation/service#booking-form'
    : '/consultation#booking-form';

  return (
    <div>
      <Featured scroll={scrollToForm} linkToForm={linkToForm} />
      <HowItWorks />
      {!!consultants.length && (
        <>
          <Consultants
            consultants={consultants}
            scroll={scrollToForm}
            consultantIndex={consultantIndex}
          />
          <BookingForm
            consultants={consultants}
            selectedConsultantId={selectedConsultantId}
            isSubscribed={isSubscribed}
            handleTab={handleTab}
          />
        </>
      )}
    </div>
  );
};

Landing.propTypes = {
  consultants: PropTypes.array.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  handleTab: PropTypes.func.isRequired,
  consultantIndex: PropTypes.number.isRequired,
};

export default Landing;

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/UI';
import ChevronRight from '@material-ui/icons/ChevronRight';
import upperCase from 'lodash/upperCase';
import { isEmpty } from 'lodash';
import { urlHandler } from 'utils/helper';
import { BackgroundSection, LockOverlay } from '../LandingPage/common';

const VenueColumn = ({ venues, category }) => {
  const venueList = venues.filter((venue) => venue.category === category);

  // using lodahs's uppercase sine im getting snake case from BE.
  return (
    <div className="overflow-x-hidden sm:overflow-visible">
      <div className="text-dusty font-semibold text-md py-2">
        {upperCase(category)}
      </div>
      <div className="flex flex-col overflow-y-auto max-h-140 p-1 pr-3">
        {venueList.map((item) => {
          const { id, name, address, link, image_url: imageUrl } = item;
          return (
            <a
              key={id}
              target="_blank"
              rel="noopener noreferrer"
              className="mb-5"
              href={link}
            >
              <Card className="flex items-center">
                <BackgroundSection
                  className="w-40 h-30 bg-no-repeat bg-cover bg-center rounded-l-lg"
                  backgroundImage={`url(${urlHandler(imageUrl)})`}
                />
                <div className="flex flex-col justify-center w-1/2 ml-3 sm:ml-5">
                  <div className="font-semibold">{name}</div>
                  <div className="text-gray-700 text-sm mt-1 break-words">
                    {address}
                  </div>
                </div>
                <div className="hidden sm:block">
                  <ChevronRight
                    style={{ fontSize: 50 }}
                    className="m-auto text-gray-400"
                  />
                </div>
              </Card>
            </a>
          );
        })}
      </div>
    </div>
  );
};

VenueColumn.propTypes = {
  venues: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
};

const Venues = ({ venues, showLockOverlay }) => {
  if (isEmpty(venues)) return null;
  // Its a bad practice to hard filter json on FE, but i was requested to.
  const allCategories = venues.map((item) => item.category);
  const venueCategories = [...new Set(allCategories)]; // To get unique categories

  return (
    <div className="flex flex-col container mx-auto mt-20 px-6">
      <div className="font-chap text-xl">Venues</div>
      <div className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-10 xs:gap-5 mt-2 relative">
        <LockOverlay
          showLockOverlay={showLockOverlay}
          className="rounded-b-lg z-10 h-full"
          isPaidContent
        />
        {venueCategories.map((item) => {
          return <VenueColumn venues={venues} key={item} category={item} />;
        })}
      </div>
    </div>
  );
};

Venues.propTypes = {
  venues: PropTypes.array.isRequired,
  showLockOverlay: PropTypes.bool.isRequired,
};

export default Venues;

import * as create from './create';
import * as update from './update';
import * as show from './show';
import * as uploadUserImage from './uploadUserImage';
import * as deleteUserImage from './deleteUserImage';
import * as list from './list';
import * as profile from './profile';

export default {
  ...create,
  ...update,
  ...show,
  ...uploadUserImage,
  ...deleteUserImage,
  ...list,
  ...profile,
};

const NAME = 'STRIPE';

export const CREATE_SUBSCRIPTION_REQUEST = `${NAME}/CREATE_SUBSCRIPTION_REQUEST`;
export const CREATE_SUBSCRIPTION_SUCCESS = `${NAME}/CREATE_SUBSCRIPTION_SUCCESS`;
export const CREATE_SUBSCRIPTION_FAIL = `${NAME}/CREATE_SUBSCRIPTION_FAIL`;

export const createSubscription = (data) => ({
  type: CREATE_SUBSCRIPTION_REQUEST,
  data,
});

export const createSubscriptionSuccess = (data) => ({
  type: CREATE_SUBSCRIPTION_SUCCESS,
  data,
});

export const createSubscriptionFail = (error) => ({
  type: CREATE_SUBSCRIPTION_FAIL,
  error,
});

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchUser({ id, callbacks }) {
  try {
    const response = yield call(api.fetchUser, id);
    yield put(Actions.fetchUserSuccess(response?.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks?.success, response?.data);
    }
  } catch (error) {
    yield put(Actions.fetchUserFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks?.failure, error);
    }
  }
}

function* watchFetchUser() {
  yield takeLatest(Actions.FETCH_USER_REQUEST, fetchUser);
}

export default function* show() {
  yield all([fork(watchFetchUser)]);
}

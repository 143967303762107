import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DatePicker from 'react-date-picker';
import { displayWeekday } from 'utils/datetime';

const DateInput = (props) => {
  const {
    form: { touched, errors, setFieldValue },
    field: { name, value },
    minDate,
    placeholder,
  } = props;
  const error = getIn(touched, name) && getIn(errors, name);
  const [isOpen, setIsOpen] = useState();
  const handleChange = (date) => setFieldValue(name, date);

  return (
    <>
      <div className="px-4 py-3 text-boulder bg-pureWhite w-full md:w-64 shadow-card rounded-md h-12">
        <div
          onClick={() => setIsOpen(true)}
          className="flex w-full items-center cursor-pointer"
          role="presentation"
        >
          <DateRangeIcon className="text-easter mr-2" />
          <p className="mr-1">{value && displayWeekday(value)}</p>
          {value || isOpen ? (
            <DatePicker
              format="dMMMy"
              onChange={handleChange}
              value={value}
              calendarClassName="border-0"
              clearIcon={null}
              calendarIcon={null}
              isOpen={isOpen}
              dayPlaceholder="day "
              monthPlaceholder="month"
              yearPlaceholder=" year"
              minDate={minDate}
              className="w-full"
            />
          ) : (
            <>{placeholder}</>
          )}
        </div>
      </div>
      <div className="text-valencia h-4 mt-2 text-xs font-light">{error}</div>
    </>
  );
};

DateInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  minDate: PropTypes.object,
};

DateInput.defaultProps = {
  minDate: null,
};

export default DateInput;

import React from 'react';
import { bgLocations } from 'assets';
import { Field, Formik, Form } from 'formik';
import { useSearchParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import TextInput from 'components/Form/TextInput';
import { debounce } from 'lodash';
import FeaturedLayout from '../LandingPage/common/FeaturedLayout';

const Header = () => {
  const { search } = useQuery();
  const [, setSearchParams] = useSearchParams();

  const updateSearch = debounce(({ search: value }) => {
    setSearchParams(value === '' ? {} : { search: value });
  }, 500);

  return (
    <FeaturedLayout image={bgLocations.default}>
      <div className="flex items-center m-auto relative md:mt-32">
        <div className="flex flex-col text-white text-center mb-10 sm:mb-0">
          <div className="text-2xl sm:text-6xl font-chap">
            Global Market Directory
          </div>
          <div className="text-lg font-lynstone flex-wrap">
            A dynamic directory of information, platforms and services <br /> to
            help grow your business in new markets.
          </div>
          <Formik
            onSubmit={updateSearch}
            initialValues={{ search: search || '' }}
          >
            {({ handleSubmit }) => (
              <Form className="lg:w-8/12 self-center mt-6">
                <Field
                  name="search"
                  onChange={handleSubmit}
                  className="h-12 rounded-full pl-5 sm:pl-8 sm:pr-32"
                  component={TextInput}
                  basic
                  value={search || ''}
                  placeholder="Search a city or country. . ."
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </FeaturedLayout>
  );
};

export default Header;

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* uploadUserImage({ data, callbacks }) {
  try {
    const response = yield call(api.uploadUserImage, data);
    yield put(Actions.uploadUserImageSuccess());
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks?.success, response.data);
    }
  } catch (error) {
    yield put(Actions.uploadUserImageFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks?.failure, error);
    }
  }
}

function* watchUploadUserImage() {
  yield takeLatest(Actions.UPLOAD_USER_IMAGE_REQUEST, uploadUserImage);
}

export default function* upload() {
  yield all([fork(watchUploadUserImage)]);
}

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const Spinner = (props) => (
  <Loader color="#695cb6" className="m-auto" {...props} />
);

const Loading = ({ className, isLoading, opaque }) =>
  isLoading && (
    <div
      className={classnames(`bg-black flex z-50 ${className}`, {
        'opacity-50': opaque,
      })}
    >
      <Spinner />
    </div>
  );

Loading.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  opaque: PropTypes.bool,
};

Loading.defaultProps = {
  className: 'fixed sm:absolute h-screen w-full',
  isLoading: true,
  opaque: true,
};

export default Loading;

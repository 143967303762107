const NAME = 'EVENTS/OPT_IN_EVENT';

export const OPT_IN_EVENT_REQUEST = `${NAME}_REQUEST`;
export const OPT_IN_EVENT_SUCCESS = `${NAME}_SUCCESS`;
export const OPT_IN_EVENT_FAIL = `${NAME}_FAIL`;

export const optInEvent = (id, callbacks) => ({
  type: OPT_IN_EVENT_REQUEST,
  id,
  callbacks,
});

export const optInEventSuccess = () => ({
  type: OPT_IN_EVENT_SUCCESS,
});

export const optInEventFail = (error) => ({
  type: OPT_IN_EVENT_FAIL,
  error,
});

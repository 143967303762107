const NAME = 'CONSULTATION_SERVICE/FETCH_SERVICE_BOOKINGS_';

export const FETCH_SERVICE_BOOKINGS_REQUEST = `${NAME}REQUEST`;
export const FETCH_SERVICE_BOOKINGS_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_SERVICE_BOOKINGS_FAIL = `${NAME}FAIL`;

export const fetchServiceBookings = () => ({
  type: FETCH_SERVICE_BOOKINGS_REQUEST,
});

export const fetchServiceBookingsSuccess = (data) => ({
  type: FETCH_SERVICE_BOOKINGS_SUCCESS,
  data,
});

export const fetchServiceBookingsFail = () => ({
  type: FETCH_SERVICE_BOOKINGS_FAIL,
});

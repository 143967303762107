import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* cancelBooking({ id }) {
  try {
    yield call(api.cancelBooking, id);
    yield put(Actions.cancelBookingSuccess());
    yield put(Actions.fetchBookings());
  } catch (error) {
    yield put(Actions.cancelBookingFail(error));
  }
}

function* watchCancelBooking() {
  yield takeLatest(Actions.CANCEL_BOOKING_REQUEST, cancelBooking);
}

export default function* cancel() {
  yield all([fork(watchCancelBooking)]);
}

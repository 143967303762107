export const NAME = 'SENDBIRD';

export const LIST_CHANNELS_REQUEST = `${NAME}/LIST_CHANNELS_REQUEST`;
export const LIST_CHANNELS_SUCCESS = `${NAME}/LIST_CHANNELS_SUCCESS`;
export const LIST_CHANNELS_FAIL = `${NAME}/LIST_CHANNELS_FAIL`;

export const listChannels = (callback) => ({
  type: LIST_CHANNELS_REQUEST,
  callback,
});

export const listChannelsSuccess = (data) => ({
  type: LIST_CHANNELS_SUCCESS,
  data,
});

export const listChannelsFail = (error) => ({
  type: LIST_CHANNELS_FAIL,
  error,
});

import Actions from 'actions';

const initialState = Object.freeze({});

function setupIntent(state = initialState, action) {
  switch (action.type) {
    case Actions.SETUP_INTENT_SUCCESS:
      return action.data;
    case Actions.SIGN_OUT:
    case Actions.CREATE_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}

export default setupIntent;

const NAME = 'COUNTRIES/FETCH_COUNTRIES';

export const FETCH_COUNTRIES_REQUEST = `${NAME}_REQUEST`;
export const FETCH_COUNTRIES_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_COUNTRIES_FAIL = `${NAME}_FAIL`;

export const fetchCountries = (params) => ({
  type: FETCH_COUNTRIES_REQUEST,
  params,
});

export const fetchCountriesSuccess = (data) => ({
  type: FETCH_COUNTRIES_SUCCESS,
  data,
});

export const fetchCountriesFail = (error) => ({
  type: FETCH_COUNTRIES_FAIL,
  error,
});

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* deleteEvent({ id, callbacks }) {
  try {
    yield call(api.deleteEvent, id);
    yield put(Actions.deleteEventSuccess(id));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.deleteEventFail(error));
    if (typeof callbacks?.failure === 'function') {
      const err = error?.response?.data?.error;
      yield call(callbacks.failure, err ? err[0] : error);
    }
  }
}

function* watchDeleteEvent() {
  yield takeLatest(Actions.DELETE_EVENT_REQUEST, deleteEvent);
}

export default function* destroy() {
  yield all([fork(watchDeleteEvent)]);
}

import React from 'react';
import PropTypes from 'prop-types';

const ImageFilter = ({ className }) => {
  return (
    <div
      className={`bg-ebony bg-opacity-75 absolute top-0 bottom-0 right-0 left-0 ${className}`}
    />
  );
};

ImageFilter.propTypes = {
  className: PropTypes.string,
};

ImageFilter.defaultProps = {
  className: '',
};

export default ImageFilter;

const NAME = 'SENDBIRD/FETCH_SENDBIRD_USERS_';

export const FETCH_SENDBIRD_USERS_REQUEST = `${NAME}REQUEST`;
export const FETCH_SENDBIRD_USERS_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_SENDBIRD_USERS_FAIL = `${NAME}FAIL`;

export const fetchSendbirdUsers = () => ({
  type: FETCH_SENDBIRD_USERS_REQUEST,
});

export const fetchSendbirdUsersSuccess = (data) => ({
  type: FETCH_SENDBIRD_USERS_SUCCESS,
  data,
});

export const fetchSendbirdUsersFail = (error) => ({
  type: FETCH_SENDBIRD_USERS_FAIL,
  error,
});

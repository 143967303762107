import React, { useState, useEffect } from 'react';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { plusOneIcon } from 'assets';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import api from 'api';
import { Button } from 'components/UI';
import TextInput from 'components/Form/TextInput';
import notify from 'utils/notification';

const DEFAULT_VALUES = { email: '', full_name: '', company: '' };

const GuestFormSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
  full_name: Yup.string().required('Full name is required'),
  company: Yup.string().required('Company is required'),
});

const GuestInfoForm = ({ guestInfo, id: eventId, allAccessSlots }) => {
  const dispatch = useDispatch();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const guestSlot = allAccessSlots?.[0].slots;
  const [isGuestSlotOpen, setIsGuestSlotOpen] = useState(guestSlot > 0);
  const [guestDetails, setGuestDetails] = useState(guestInfo);
  const { email, full_name: fullName, company } = guestDetails;
  const isUpdate = !!fullName;
  const initialValues = isUpdate ? guestDetails : DEFAULT_VALUES;
  const updateEventInfo = () =>
    setTimeout(() => dispatch(Actions.fetchEventInfo(eventId)), 300);

  useEffect(() => {
    setIsGuestSlotOpen(guestSlot > 0);
  }, [dispatch, guestSlot]);

  useEffect(() => {
    setGuestDetails(guestInfo);
  }, [dispatch, guestInfo]);

  const { mutate: addGuestMutate } = useMutation(
    (formData) => api.addGuest(eventId, formData),
    {
      onSuccess: () => {
        notify('Successfully added guest');
        setIsFormOpen(false);
        updateEventInfo();
      },
      onError: (e) => {
        notify(
          e?.response?.data?.error || 'Error adding guest',
          null,
          'danger'
        );
      },
    }
  );

  const { mutate: updateGuestMutate } = useMutation(
    (formData) => api.updateGuest(eventId, formData),
    {
      onSuccess: () => {
        notify('Successfully updated guest');
        setIsFormOpen(false);
        updateEventInfo();
      },
      onError: (e) =>
        notify(
          e?.response?.data?.error ||
            'Something went wrong, please try again later',
          null,
          'danger'
        ),
    }
  );

  const { mutate: removeGuestMutate } = useMutation(
    (eventId) => api.removeGuest(eventId),
    {
      onSuccess: () => {
        notify('Successfully removed guest');
        setIsFormOpen(false);
        updateEventInfo();
      },
      onError: (e) =>
        notify(
          e?.response?.data?.error ||
            'Something went wrong, please try again later',
          null,
          'danger'
        ),
    }
  );

  return (
    <>
      <div className="flex flex-col mb-10 pl-5 pr-3 max-w-xs">
        <div className="flex justify-between">
          <p className="text-gray-500 text-sm uppercase">MY PLUS ONE</p>
          <button
            onClick={toggleForm}
            className={classnames('text-easter text-sm uppercase', {
              'text-dusty': isFormOpen,
              hidden: !isUpdate && !isFormOpen,
            })}
            type="button"
          >
            {isFormOpen ? 'CANCEL' : 'EDIT'}
          </button>
        </div>
        <div
          className={classnames('flex mt-5', {
            hidden: isFormOpen === true,
          })}
        >
          <div className="w-2/12 mt-1">
            <img
              src={plusOneIcon.default}
              alt="plus-one-icon"
              className="w-6/12"
            />
          </div>
          <div className="flex flex-col space-y-1 -ml-3">
            <p className="text-semibold text-sm">
              {fullName || 'No plus-one added'}
            </p>
            <p className="text-xs text-dusty">
              {email || 'You can bring a plus one to this event'}
            </p>
            <button
              className={classnames(
                'text-easter self-start text-xs hover:cursor',
                { hidden: isUpdate }
              )}
              type="button"
              onClick={toggleForm}
              disabled={!isGuestSlotOpen}
            >
              {isGuestSlotOpen
                ? 'Add a plus one'
                : 'Currently no slots available'}
            </button>
            <p className="text-semibold text-xs">{company}</p>
          </div>
        </div>
        <div
          className={classnames('text-left border rounded-lg p-5 mt-5', {
            hidden: isFormOpen === false,
          })}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={isUpdate ? updateGuestMutate : addGuestMutate}
            enableReinitialize
            validationSchema={GuestFormSchema}
          >
            {({ values }) => (
              <>
                <Form className="flex flex-col space-y-4 text-xs">
                  <Field
                    label="Name"
                    placeholder="Name"
                    name="full_name"
                    type="text"
                    component={TextInput}
                    isRequired
                  />
                  <Field
                    label="Email"
                    placeholder="Email"
                    name="email"
                    type="email"
                    component={TextInput}
                    isRequired
                  />
                  <Field
                    label="Company"
                    placeholder="Company Enter N/A if not applicable"
                    name="company"
                    type="text"
                    component={TextInput}
                    isRequired
                  />
                  <div className="flex justify-between items-center gap-3 mt-5">
                    {isUpdate && (
                      <button
                        type="button"
                        className="text-xs md:text-sm w-1/2 text-valencia hover:cursor"
                        onClick={() => {
                          removeGuestMutate(eventId);
                        }}
                      >
                        REMOVE
                      </button>
                    )}
                    <Button
                      label={isUpdate ? 'UPDATE' : 'ADD GUEST'}
                      type="submit"
                      className="text-xs justify-center w-full flex items-center h-8 md:h-10 md:text-sm"
                      disabled={!values}
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

GuestInfoForm.propTypes = {
  guestInfo: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  allAccessSlots: PropTypes.object.isRequired,
};

export default GuestInfoForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import { CodeInput } from 'components/Form';
import { iconBack } from 'assets';
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { nameFormat } from 'utils/format';

const ValidateResetPasswordCodeForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCodeValid, setCodeValidity] = useState(true);
  const [error, setError] = useState(null);

  const { email, previousStep } = props;
  return (
    <div className="md:px-8">
      <h5 className="mt-8">Enter Reset Password Code</h5>
      <p className="text-dusty mt-2 mb-8 text-sm font-light">
        {`Hi, please check your email that we sent to ${email} and`}
        <br />
        enter the ‘Reset Code’ below to reset your password
      </p>
      <Formik
        onSubmit={({ code }) => {
          dispatch(
            Actions.validateResetPasswordCode(
              { email, code },
              {
                success: ({ first_name: firstName, last_name: lastName }) => {
                  navigate('/reset-password', {
                    state: {
                      code,
                      email,
                      user: nameFormat({ firstName, lastName }),
                    },
                  });
                },
                failure: () => {
                  setCodeValidity(false);
                  setError(
                    'Incorrect code, please enter a valid code to proceed'
                  );
                },
              }
            )
          );
        }}
        initialValues={{
          code: '',
        }}
      >
        {({ handleSubmit }) => (
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <Field
              className="mx-auto"
              name="code"
              component={CodeInput}
              type="number"
              isValid={isCodeValid}
            />
            {error && (
              <div className="text-center text-valencia mt-2">{error}</div>
            )}
            <div className="flex justify-between mt-32 mb-8">
              <button
                type="button"
                className="uppercase text-dusty flex focus:outline-none"
                onClick={previousStep}
              >
                <img
                  alt="check"
                  src={iconBack.default}
                  className="h-4 mt-1 mr-4"
                />
                Back
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

ValidateResetPasswordCodeForm.propTypes = {
  previousStep: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default ValidateResetPasswordCodeForm;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* toggleReminder() {
  try {
    yield call(api.toggleReminder);
    yield put(Actions.toggleReminderSuccess());
  } catch (error) {
    yield put(Actions.toggleReminderFail(error));
  }
}

function* watchToggleReminder() {
  yield takeLatest(Actions.TOGGLE_REMINDER_REQUEST, toggleReminder);
}

export default function* toggle() {
  yield all([fork(watchToggleReminder)]);
}

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatDistanceStrict, parseJSON } from 'date-fns';
import { gfxVideoIcon } from 'assets';
import { Badge, Card, Button } from 'components/UI';
import { urlHandler } from 'utils/helper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { displaySubscriptionDateFormat } from 'utils/datetime';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';
import { AuthContext } from 'utils/context';
import { BackgroundSection } from '../LandingPage/common';

const VideoCard = ({ video, row }) => {
  const { isFreeTier } = useContext(AuthContext);
  const {
    id,
    name,
    views,
    duration,
    category,
    img_url: img,
    published_at: published,
    paid_content: isPaidContent,
  } = video;
  const link = `/members/videos/${id}`;
  const hasPaywall = isFreeTier && isPaidContent;

  const UpgradeButton = () => {
    return (
      <Link to="/subscription">
        <Button
          label="upgrade membership"
          className="hover:opacity-100 px-6"
          isPill
        />
      </Link>
    );
  };

  if (row)
    return (
      <div className="relative w-full mb-8 shadow-card rounded-lg overflow-hidden">
        {hasPaywall && (
          <div className="absolute h-full w-full bg-white z-10 flex bg-opacity-75 flex-col justify-center items-center gap-4">
            <p className="font-chap text-2lg text-center">{`Exclusive for ${PREMIUM} & ${ALL_ACCESS} only`}</p>
            <UpgradeButton />
          </div>
        )}
        <Link to={link}>
          <div className="w-full sm:flex">
            <div className="w-full sm:w-1/2 lg:w-1/3">
              <BackgroundSection
                backgroundImage={`url(${urlHandler(img)})`}
                className="bg-no-repeat bg-cover bg-center h-full relative"
              >
                <Badge
                  className="absolute left-0 bottom-0 m-3 hidden sm:block"
                  bg="opacity-75 bg-martini"
                >
                  {duration}
                </Badge>
              </BackgroundSection>
            </div>
            <div className="w-full p-6 pt-3 flex flex-col justify-center truncate">
              <div className="flex text-easter uppercase items-center">
                <img
                  src={gfxVideoIcon.default}
                  alt="video_icon"
                  className="mr-3 h-16"
                />
                {category}
              </div>
              <div className="w-full">
                <div className="mt-4 text-lg sm:text-2lg font-bold truncate">
                  {name}
                </div>
                <div className="flex text-dusty mt-2">
                  <span className="flex border-r border-dusty pr-4 items-center">
                    <VisibilityIcon className="mr-2 text-silver" />
                    {`${views.toLocaleString()} views`}
                  </span>
                  <span className="flex items-center pl-4">
                    {`${displaySubscriptionDateFormat(published)}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );

  return (
    <div className="relative">
      {hasPaywall && (
        <div className="absolute h-full w-full bg-white z-10 flex p-6 bg-opacity-75 flex-col rounded-md">
          <div className="flex justify-center items-center flex-1 flex-col gap-4">
            <p className="font-chap text-2lg text-center">{`Exclusive for ${PREMIUM} & ${ALL_ACCESS} only`}</p>
            <UpgradeButton />
          </div>
          <div className="flex-1" />
        </div>
      )}
      <Link to={link}>
        <Card className="h-full">
          <BackgroundSection
            backgroundImage={`url(${urlHandler(img)})`}
            className="relative bg-no-repeat bg-cover bg-center h-56 rounded-t-md"
          >
            <Badge
              className="absolute left-0 bottom-0 m-3"
              bg="opacity-75 bg-martini"
            >
              {duration}
            </Badge>
          </BackgroundSection>
          <div className="p-4 sm:px-6">
            <div className="flex text-easter mb-3 uppercase relative">
              {category}
              <img
                src={gfxVideoIcon.default}
                className="absolute right-0 -mt-8"
                alt="video_icon"
              />
            </div>
            <h3 className="break-normal overflow-y-scroll h-16 font-lynstone">
              {name}
            </h3>
            <div className="flex text-boulder mt-3">
              <span className="flex border-r border-dusty pr-2 items-center flex-1">
                <VisibilityIcon className="mr-2 text-silver" />
                {`${views.toLocaleString()} views`}
              </span>
              <span className="flex items-center flex-1 pl-4">
                {`${formatDistanceStrict(
                  parseJSON(published),
                  new Date()
                )} ago`}
              </span>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};

VideoCard.propTypes = {
  video: PropTypes.object.isRequired,
  row: PropTypes.bool,
};

VideoCard.defaultProps = {
  row: false,
};

export default VideoCard;

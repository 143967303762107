import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchCreatedPerks() {
  try {
    const { data } = yield call(api.createdPerks);
    yield put(Actions.fetchCreatedPerksSuccess(data));
  } catch (error) {
    yield put(Actions.fetchCreatedPerksFail(error));
  }
}

function* watchFetchCreatedPerks() {
  yield takeLatest(Actions.FETCH_CREATED_REQUEST, fetchCreatedPerks);
}

export default function* created() {
  yield all([fork(watchFetchCreatedPerks)]);
}

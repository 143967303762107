import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Badge, Button } from 'components/UI';
import { displayDateFormat } from 'utils/datetime';
import qs from 'query-string';
import FeaturedLayout from '../../LandingPage/common/FeaturedLayout';
import MemberAccessBadge from '../MemberAccessBadge';

const Featured = ({ event, eventsPath }) => {
  const {
    id,
    city,
    start_at: startAt,
    image_url: imageUrl,
    name,
    event_category: eventCategory,
    time_zone: timeZone,
    member_access: memberAccess,
  } = event;
  const eventType = eventCategory?.name || 'Festival';
  const eventTypeUrl = `${eventsPath}?${qs.stringify({ category: eventType })}`;
  const locationAndTime = `${city?.name || 'Global'} • ${displayDateFormat(
    startAt,
    timeZone
  )}`;

  return (
    <FeaturedLayout image={imageUrl}>
      <div className="flex relative my-auto px-6">
        <div className="flex flex-col">
          <div className="flex flex-col items-start gap-4 sm:flex-row sm:gap-10 sm:items-center">
            <Link to={eventTypeUrl}>
              <Badge md bg="bg-gradient-violet-cyan">
                {eventType}
              </Badge>
            </Link>
            {!!memberAccess && (
              <MemberAccessBadge memberAccess={memberAccess} />
            )}
          </div>
          <h1 className="text-white font-semibold leading-none my-6 text-4xl max-w-6xl">
            {name}
          </h1>
          <p className="text-white text-lg max-w-lg">{locationAndTime}</p>
          <div className="mt-6">
            <Link to={`${eventsPath}/${id}`}>
              <Button
                label="EVENT DETAILS"
                bold={false}
                uppercase={false}
                theme="easter"
                className="bg-opacity-50 flex flex-row-reverse"
                icon={<ChevronRightIcon className="ml-2" />}
              />
            </Link>
          </div>
        </div>
      </div>
    </FeaturedLayout>
  );
};

Featured.propTypes = {
  event: PropTypes.object.isRequired,
  eventsPath: PropTypes.string,
};

Featured.defaultProps = {
  eventsPath: '/events',
};

export default Featured;

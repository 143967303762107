const NAME = 'EVENTS';

export const FETCH_EVENT_INFO_REQUEST = `${NAME}/FETCH_EVENT_INFO_REQUEST`;
export const FETCH_EVENT_INFO_SUCCESS = `${NAME}/FETCH_EVENT_INFO_SUCCESS`;
export const FETCH_EVENT_INFO_FAIL = `${NAME}/FETCH_EVENT_INFO_FAIL`;

export const fetchEventInfo = (id, callbacks) => ({
  type: FETCH_EVENT_INFO_REQUEST,
  id,
  callbacks,
});

export const fetchEventInfoSuccess = (data) => ({
  type: FETCH_EVENT_INFO_SUCCESS,
  data,
});

export const fetchEventInfoFail = (error) => ({
  type: FETCH_EVENT_INFO_FAIL,
  error,
});

const NAME = 'PERK/DELETE_PERK_';

export const DELETE_PERK_REQUEST = `${NAME}REQUEST`;
export const DELETE_PERK_SUCCESS = `${NAME}SUCCESS`;
export const DELETE_PERK_FAIL = `${NAME}FAIL`;

export const deletePerk = (id, callbacks) => ({
  type: DELETE_PERK_REQUEST,
  id,
  callbacks,
});

export const deletePerkSuccess = (id) => ({
  type: DELETE_PERK_SUCCESS,
  id,
});

export const deletePerkFail = (error) => ({
  type: DELETE_PERK_FAIL,
  error,
});

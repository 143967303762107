const PERSIST = 'PERSIST';

export const SET_USER = `${PERSIST}/SET_USER`;
export const CLEAR_USER = `${PERSIST}/CLEAR_USER`;

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});

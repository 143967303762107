import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Picture } from 'components/UI';
import socialLinks from 'utils/socialLinks';
import { iconFB, iconIG, iconLinkedIn, iconYT } from 'assets';

const FooterItem = ({ label, url }) => {
  return (
    <Link
      to={url} // TODO: Add link
      className={classnames(
        'py-2 md:py-0 md:px-8 md:first:pl-0',
        'md:border-r border-gray-800 last:border-none',
        'text-dusty hover:text-boston text-sm'
      )}
    >
      {label}
    </Link>
  );
};
FooterItem.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const Footer = () => {
  const socialItems = (imgSrc, link, className) => (
    <a
      href={link}
      className={`h-6 ${className}`}
      target="_blank"
      rel="noreferrer"
    >
      <Picture src={imgSrc} className="h-6" />
    </a>
  );

  const { facebook, instagram, linkedin, youtube } = socialLinks;

  return (
    <div className="mt-auto">
      <div className="bg-lily">
        <div className="flex flex-col lg:flex-row justify-between items-center container mx-auto px-6 py-10 lg:py-0 xl:px-5 lg:h-12">
          <div className="flex flex-col md:flex-row flex-wrap items-center">
            <FooterItem label="FAQ" url="/faq" />
            <FooterItem label="Contact Us" url="/contact" />
            <FooterItem label="Privacy Policy" url="/privacy" />
            <FooterItem label="Terms of Use" url="/terms" />
          </div>
          <div className="flex mt-6 lg:mt-0">
            <Link to="/" className="h-6 mr-6">
              <span className="h-6 text-2lg text-dusty mr-4 icon-expand-logo" />
            </Link>
            {socialItems(iconFB, facebook)}
            {socialItems(iconIG, instagram, 'mx-2')}
            {socialItems(iconLinkedIn, linkedin)}
            {socialItems(iconYT, youtube, 'mx-2')}
          </div>
        </div>
      </div>
      <div className="h-12 text-center flex items-center justify-center text-sm bg-twilight">
        {`Gig Life Pro Copyright © ${new Date().getUTCFullYear()}`}
      </div>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;

const NAME = 'EVENTS/UPDATE_EVENT_';

export const UPDATE_EVENT_REQUEST = `${NAME}REQUEST`;
export const UPDATE_EVENT_SUCCESS = `${NAME}SUCCESS`;
export const UPDATE_EVENT_FAIL = `${NAME}FAIL`;

export const updateEvent = (id, data, callbacks) => ({
  type: UPDATE_EVENT_REQUEST,
  id,
  data,
  callbacks,
});

export const updateEventSuccess = (id, data) => ({
  type: UPDATE_EVENT_SUCCESS,
  id,
  data,
});

export const updateEventFail = (error) => ({
  type: UPDATE_EVENT_FAIL,
  error,
});

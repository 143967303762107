const NAME = 'VIDEO/FETCH_VIDEO_CATEGORIES_';

export const FETCH_VIDEO_CATEGORIES_REQUEST = `${NAME}REQUEST`;
export const FETCH_VIDEO_CATEGORIES_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_VIDEO_CATEGORIES_FAIL = `${NAME}FAIL`;

export const fetchVideoCategories = () => ({
  type: FETCH_VIDEO_CATEGORIES_REQUEST,
});

export const fetchVideoCategoriesSuccess = (data) => ({
  type: FETCH_VIDEO_CATEGORIES_SUCCESS,
  data,
});

export const fetchVideoCategoriesFail = (error) => ({
  type: FETCH_VIDEO_CATEGORIES_FAIL,
  error,
});

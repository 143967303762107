import React from 'react';

// Landing Page
import Events from 'containers/Events';
import Event from 'containers/Events/View';
import Articles from 'containers/Articles';
import Article from 'containers/Articles/View';
import Locations from 'containers/Locations';
import Location from 'containers/Locations/View';
import Home from 'containers/LandingPage/Home';
import Faq from 'containers/LandingPage/Faq';
import Terms from 'containers/LandingPage/Terms';
import Contact from 'containers/LandingPage/Contact';
import About from 'containers/LandingPage/About';
import Privacy from 'containers/LandingPage/Privacy';
import Consultation from 'containers/Consultation';
import Membership from 'containers/Membership';

// Auth
import Login from 'containers/Auth/Login';
import Registration from 'containers/Auth/Registration';
import CreatePassword from 'containers/Auth/CreatePassword';
import ResetPassword from 'containers/Auth/ResetPassword';
import ForgotPassword from 'containers/Auth/ForgotPassword';
import Activate from 'containers/Auth/Activate';

// Member's page
import Dashboard from 'containers/Dashboard';
import Profile from 'containers/Profile';
import Subscription from 'containers/Subscription';
import SubscriptionUpdate from 'containers/Subscription/Update';
import Connections from 'containers/Connections';
import UserProfile from 'containers/Connections/UserProfile';
import Videos from 'containers/Videos';
import Video from 'containers/Videos/View';
import Perks from 'containers/Perks/Explore';
import MyPerks from 'containers/Perks/MyPerks';
import Perk from 'containers/Perks/View';
import PerkForm from 'containers/Perks/MyPerks/PerkForm/index';
import Explore from 'containers/Rewards/Explore';
import Chat from 'containers/Chat';
import MyRewards from '../containers/Rewards/MyRewards';

export const PUBLIC_ROUTES = [
  {
    path: '/',
    component: <Home />,
  },
  {
    path: '/events',
    children: [
      { path: '/', component: <Events /> },
      { path: '/:id', component: <Event /> },
    ],
  },
  {
    path: '/articles',
    children: [
      { path: '/', component: <Articles /> },
      { path: '/:id', component: <Article /> },
    ],
  },
  {
    path: '/locations',
    children: [
      { path: '/', component: <Locations /> },
      { path: '/:id', component: <Location /> },
    ],
  },
  {
    path: '/signup',
    children: [
      { path: '/', component: <Registration /> },
      { path: '/:referral_code', component: <Registration /> },
    ],
  },
  { path: '/faq', component: <Faq /> },
  { path: '/terms', component: <Terms /> },
  { path: '/contact', component: <Contact /> },
  { path: '/about', component: <About /> },
  { path: '/privacy', component: <Privacy /> },
  { path: '/create-password', component: <CreatePassword /> },
  { path: '/reset-password', component: <ResetPassword /> },
  { path: '/forgot-password', component: <ForgotPassword /> },
  { path: '/activate', component: <Activate /> },
  { path: '/login', component: <Login /> },
  { path: '/consultation', component: <Consultation /> },
  { path: '/membership', component: <Membership /> },
];

export const PRIVATE_ROUTES = [
  {
    path: '/dashboard',
    label: 'Dashboard',
    icon: 'dashboard',
    component: <Dashboard />,
  },
  {
    path: '/profile',
    label: 'Profile',
    icon: 'my_profile',
    component: <Profile />,
    availableInUnpaid: true,
  },
  {
    path: '/network',
    label: 'Network',
    icon: 'network',
    children: [
      { path: '/', component: <Connections /> },
      { path: '/:id', component: <UserProfile /> },
    ],
  },
  {
    path: '/chat',
    label: 'Chat',
    icon: 'chat',
    component: <Chat />,
  },
  {
    path: '/members/locations',
    label: 'Global Directory',
    icon: 'locations',
    children: [
      { path: '/', component: <Locations /> },
      { path: '/:id', component: <Location /> },
    ],
  },
  {
    path: '/members/events',
    defaultPath: '/members/events/explore',
    label: 'Events',
    icon: 'events',
    children: [
      { path: '/:type', component: <Events /> },
      { path: '/:type/:id', component: <Event /> },
    ],
  },
  {
    path: '/members/articles',
    label: 'Articles',
    icon: 'articles',
    children: [
      { path: '/', component: <Articles /> },
      { path: '/:id', component: <Article /> },
    ],
  },
  {
    path: '/members/consultation',
    defaultPath: '/members/consultation/service',
    label: 'Consultation',
    icon: 'consultation',
    component: <Consultation />,
    children: [{ path: '/:type', component: <Consultation /> }],
  },
  {
    path: '/members/perks',
    defaultPath: '/members/perks/explore',
    label: 'Perks',
    icon: 'perks',
    children: [
      { path: '/explore', component: <Perks /> },
      { path: '/my-perks', component: <MyPerks /> },
      { path: '/my-perks/create', component: <PerkForm /> },
      { path: '/my-perks/:id/update', component: <PerkForm /> },
      { path: '/:type/:id', component: <Perk /> },
    ],
  },
  {
    path: '/members/videos',
    label: 'Videos',
    icon: 'videos',
    children: [
      { path: '/', component: <Videos /> },
      { path: '/:id', component: <Video /> },
    ],
  },
  {
    path: '/members/rewards',
    defaultPath: '/members/rewards/explore',
    label: 'Rewards',
    icon: 'rewards',
    children: [
      { path: '/explore', component: <Explore /> },
      { path: '/my-rewards', component: <MyRewards /> },
    ],
    hideMenu: true,
  },
  {
    path: '/subscription',
    label: 'Account Settings',
    icon: 'subscription',
    children: [
      { path: '/', component: <Subscription /> },
      { path: '/update', component: <SubscriptionUpdate /> },
    ],
    availableInUnpaid: true,
  },
].filter(({ hideMenu }) => !hideMenu);

export const INFO_ROUTES = [
  {
    path: '/about',
    label: 'About Us',
  },
  {
    path: '/faq',
    label: 'FAQ',
  },
  {
    path: '/contact',
    label: 'Contact Us',
  },
  {
    path: '/privacy',
    label: 'Privacy',
  },
  {
    path: '/terms',
    label: 'Terms',
  },
];

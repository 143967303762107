const NAME = 'PERKS/CREATE_PERK_';

export const CREATE_PERK_REQUEST = `${NAME}REQUEST`;
export const CREATE_PERK_SUCCESS = `${NAME}SUCCESS`;
export const CREATE_PERK_FAIL = `${NAME}FAIL`;

export const createPerk = (params, callbacks) => ({
  type: CREATE_PERK_REQUEST,
  params,
  callbacks,
});

export const createPerkSuccess = (data) => ({
  type: CREATE_PERK_SUCCESS,
  data,
});

export const createPerkFail = (error) => ({
  type: CREATE_PERK_FAIL,
  error,
});

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Picture, Modal } from 'components/UI';
import { gfxAvatar, gfxDirectMsg, gfxGroupChat } from 'assets';
import { formatDistanceToNow, isYesterday, isToday, format } from 'date-fns';
import Add from '@material-ui/icons/Add';
import { AuthContext } from 'utils/context';
import CreateForm from './CreateForm';

const ChannelsContainer = styled.div`
  max-height: calc(100vh - 6rem);
  @media (min-width: 768px) {
    width: 300px;
  }
  @media (min-width: 1024px) {
    width: 350px;
  }
`;

const ChatTypeBtn = styled.button`
  width: 50%;
  border-color: #47bad4;
  margin: 0 1.25rem;
  ${({ hasBorder }) =>
    hasBorder && `border-bottom-width: 3px; padding-top: 3px;`}
`;

const Channel = (channelProps) => {
  const {
    channel,
    avatar,
    title,
    date,
    subtitle,
    unread,
    handleClick,
    isDirect,
  } = channelProps;

  const fromNow = (timestamp) => {
    if (isToday(timestamp)) {
      return `${formatDistanceToNow(timestamp)} ago`;
    }
    if (isYesterday(timestamp)) {
      return 'Yesterday';
    }
    return format(timestamp, 'd/MM/yyyy');
  };

  return (
    <div
      className="flex items-center min-h-24 hover:bg-mulled px-4 cursor-pointer"
      key={channel.url}
      onClick={handleClick}
      role="presentation"
    >
      {isDirect ? (
        <Picture
          src={avatar || gfxAvatar}
          className="h-10 w-10 rounded-full object-cover mr-2"
          fallbackImage={gfxAvatar.default}
        />
      ) : (
        <div className="h-10 w-10 rounded-full object-cover mr-2 bg-teal-100 flex justify-center items-center">
          <Picture src={gfxGroupChat} className="h-6 w-6 object-cover" />
        </div>
      )}
      <div className="flex-1 flex flex-col text-white truncate">
        <div className="flex items-center">
          <div className="flex-1 font-semibold truncate">{title}</div>
          <i className="text-easter font-light text-xs ml-6">
            {fromNow(new Date(date))}
          </i>
        </div>
        <div className="flex items-center mt-2">
          <p className="flex-1 text-sm truncate">{subtitle}</p>
          {unread > 0 && (
            <span className="h-6 w-6 ml-6 bg-easter text-white rounded-full text-sm flex items-center justify-center">
              {unread}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const Channels = ({ channels, isOpen, selectChat, openedChannel }) => {
  const { isFreeTier } = useContext(AuthContext);
  const [searchValue, setSearch] = useState('');
  const [isDirect, setDirect] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const directChannels = channels.filter(({ isGroup }) => !isGroup);
  const groupChannels = channels.filter(({ isGroup }) => isGroup);
  const unreadGroup =
    groupChannels.reduce((acc, { unread }) => acc + unread, 0) || null;
  const unreadDirect =
    directChannels.reduce((acc, { unread }) => acc + unread, 0) || null;
  const selectedChannels = isDirect ? directChannels : groupChannels;
  const filteredChannels = isEmpty(searchValue)
    ? selectedChannels
    : selectedChannels.filter(({ title }) =>
        title.toLowerCase().includes(searchValue.toLowerCase())
      );

  const EmptyChatList = () => {
    return (
      <div className="flex flex-col items-center justify-center text-white text-center self-center my-auto">
        <Picture
          className="h-16"
          src={isDirect ? gfxDirectMsg : gfxGroupChat}
        />
        <p className="text-md font-semibold mt-8 mb-2">
          {isDirect ? 'Direct messages empty' : 'You have no chat groups'}
        </p>
        <p className="text-sm w-4/5">
          {isDirect
            ? 'Direct messages with your connections will appear here...'
            : 'Your chat groups will appear here...'}
        </p>
      </div>
    );
  };

  return (
    <ChannelsContainer
      className={classnames(
        'bg-martini h-screen w-screen transition-all ease-in-out duration-400 flex flex-col',
        { '-ml-100vw md:ml-0': !isOpen }
      )}
    >
      <div className="flex bg-martini border-b border-black items-center px-2 min-h-16 h-16 sticky top-0">
        <div className="flex flex-grow justify-around text-easter h-full">
          <ChatTypeBtn
            type="button"
            hasBorder={isDirect}
            onClick={() => setDirect(true)}
          >
            <div className="flex flex-row justify-center">
              <p>Direct</p>
              {unreadDirect && (
                <div className="h-6 w-6 bg-valencia text-white rounded-full text-sm flex items-center justify-center ml-3">
                  {unreadDirect}
                </div>
              )}
            </div>
          </ChatTypeBtn>
          <ChatTypeBtn
            type="button"
            hasBorder={!isDirect}
            onClick={() => setDirect(false)}
          >
            <div className="flex flex-row justify-center">
              <p>Groups</p>
              {unreadGroup && (
                <div className="h-6 w-6 bg-valencia text-white rounded-full text-sm flex items-center justify-center ml-3">
                  {unreadGroup}
                </div>
              )}
            </div>
          </ChatTypeBtn>
        </div>
        <div
          className={classnames(
            'flex items-center justify-center h-8 w-8',
            'cursor-pointer text-md rounded-full',
            'bg-easter text-white ml-4',
            { 'md:hidden': !openedChannel, hidden: !isDirect && isFreeTier }
          )}
        >
          <button type="button" onClick={() => setOpenModal(true)}>
            <Add fontSize="inherit" />
          </button>
        </div>
      </div>
      <div className="flex bg-martini items-center px-2 min-h-16 sticky top-0">
        <input
          className="flex-1 rounded-full min-h-10 py-2 px-4 focus:outline-none bg-ebony text-twilight"
          placeholder="Search Name"
          onChange={(event) => setSearch(event.target.value)}
          value={searchValue}
        />
      </div>
      {isEmpty(filteredChannels) ? (
        <EmptyChatList />
      ) : (
        <div className="overflow-y-auto flex flex-col">
          {filteredChannels.map((channel) => (
            <Channel
              {...channel}
              key={channel.channel.url}
              handleClick={() => selectChat(channel)}
              isDirect={isDirect}
            />
          ))}
        </div>
      )}
      <Modal
        isOpen={openModal}
        content={(setOpen) => (
          <div className="mx-auto rounded">
            <CreateForm
              selectChat={selectChat}
              isGroup={!isDirect}
              closeModal={() => {
                setOpen(false);
                setOpenModal(false);
              }}
            />
          </div>
        )}
        onDismiss={() => setOpenModal(false)}
      >
        <div />
      </Modal>
    </ChannelsContainer>
  );
};

Channels.propTypes = {
  channels: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectChat: PropTypes.func.isRequired,
  openedChannel: PropTypes.bool.isRequired,
  openChatModal: PropTypes.func,
};

Channels.defaultProps = {
  openChatModal: null,
};

export default Channels;

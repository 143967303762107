import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Spinner } from 'components/UI/Loading';
import { Card, Picture, Modal } from 'components/UI';
import AddToCalendar from 'components/UI/AddToCalendar';
import { createdEventsEmpty } from 'assets';
import BookingCard from './BookingCard';
import CompletedBookings from './CompletedBookings';

const BookingList = ({ bookings, cancelBooking, isLoading }) => {
  const [modalStatus, showModal] = useState(false);
  const closeModal = () => showModal(false);

  const [currentBooking, setBooking] = useState({});

  const setCalendarBooking = (booking) => {
    setBooking(booking);
    showModal(true);
  };

  const EmptyList = () => (
    <Card className="border-t-2 p-10 text-center mt-5">
      <div className="m-auto text-center">
        <Picture
          src={createdEventsEmpty}
          className="py-8 w-2/5 sm:w-1/6 m-auto"
        />
        <h3 className="font-semibold">No upcoming consultation bookings</h3>
        <p className="text-dusty">
          Your consultation bookings will appear here
        </p>
      </div>
    </Card>
  );

  const Content = () => {
    if (isEmpty(bookings)) {
      if (isLoading)
        return (
          <div className="flex my-20">
            <Spinner />
          </div>
        );

      return <EmptyList />;
    }

    return bookings.map((item) => (
      <div
        className="flex-grow-0 flex-shrink-0 my-4 mx-4 sm:mx-0"
        key={item.id}
      >
        <BookingCard
          booking={item}
          key={item.id}
          cancelBooking={cancelBooking}
          onClickCalendar={() => setCalendarBooking(item)}
        />
      </div>
    ));
  };

  return (
    <div className="sm:px-6 mx-auto mt-10 max-w-full">
      <h3 className="font-semibold sm:pb-0 mx-4 sm:mx-0">
        UPCOMING CONSULTATIONS
      </h3>
      <Content />
      <CompletedBookings />
      <Modal
        isOpen={modalStatus}
        content={
          <div className="w-full p-4 rounded bg-white">
            <AddToCalendar
              start={currentBooking.start_at || ''}
              end={currentBooking.end_at || ''}
              title={`Consultation with ${currentBooking.consultant?.name}`}
            />
          </div>
        }
        onDismiss={closeModal}
      >
        <div />
      </Modal>
    </div>
  );
};

BookingList.propTypes = {
  bookings: PropTypes.array.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default BookingList;

import React, { useState } from 'react';
import { Card, Modal } from 'components/UI';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { useQuery } from 'react-query';
import api from 'api';
import { blockedUsersIcon } from 'assets';
import BlockedUsersList from './BlockedUsersList';

const BlockedUsers = () => {
  const { data } = useQuery('blockedUsers', () => api.fetchBlockedUsers());
  const [isListOpen, setIsListOpen] = useState(false);

  return (
    <div className="mt-8 mb-6">
      <h3 className="uppercase mb-4 font-semibold">BLOCKED USERS</h3>
      <Card className="max-w-120 min-w-80 flex p-4 sm:p-5 justify-center">
        <div className="flex justify-around items-center">
          <img
            src={blockedUsersIcon.default}
            alt="blocked-users-icon"
            className="h-12"
          />
          <p className="mx-4">View blocked users</p>
        </div>
        <Modal
          isOpen={isListOpen}
          content={
            <BlockedUsersList
              blockedUsers={data}
              closeModal={() => setIsListOpen(false)}
            />
          }
          onChildClick={() => setIsListOpen(!isListOpen)}
        >
          <button
            type="button"
            className="flex items-center cursor-pointer text-sigmaBlue-dark ml-auto text-base"
          >
            <div>View All</div>
            <DoubleArrowIcon className="sm:mx-2" fontSize="inherit" />
          </button>
        </Modal>
      </Card>
    </div>
  );
};

export default BlockedUsers;

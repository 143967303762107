import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* updateUser({ data, callbacks = {} }) {
  try {
    const response = yield call(api.updateUserProfile, data);
    yield put(Actions.updateUserSuccess(response.data));
    if (typeof callbacks.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.updateUserFail(error));
    if (typeof callbacks.failure === 'function') {
      const err = error?.response?.data?.error;
      const msg = Array.isArray(err) ? err.join(', ') : 'Failed to update';
      yield call(callbacks.failure, msg);
    }
  }
}

function* watchUpdateUser() {
  yield takeLatest(Actions.UPDATE_USER_REQUEST, updateUser);
}

export default function* update() {
  yield all([fork(watchUpdateUser)]);
}

import React from 'react';
import styled from '@emotion/styled';
import { Breadcrumbs } from 'components/UI';
import FeaturedLayout from './common/FeaturedLayout';
import Apply from './common/Apply';

const TextsContainer = styled.article`
  h5 {
    font-size: 24px;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  p,
  strong {
    margin-bottom: 1rem;
  }
  strong {
    display: block;
    font-size: 18px;
  }
  ol {
    margin-left: 2rem;
    margin-bottom: 1rem;
    list-style-type: decimal;
  }
`;

const Privacy = () => {
  return (
    <div>
      <FeaturedLayout>
        <div className="container sm:px-4 mx-auto z-0">
          <Breadcrumbs
            trails={[{ label: 'Privacy', path: '/privacy' }]}
            publicRoute
          />
        </div>
        <div className="flex flex-col text-center text-white relative m-auto">
          <h1 className="text-3xl font-semibold">Privacy Policy</h1>
        </div>
      </FeaturedLayout>
      <TextsContainer className="container px-4 mx-auto pt-8">
        <h5>PRIVACY POLICY </h5>
        <p>
          The Personal Data Protection Act 2012 of Singapore and its
          regulation(s) (“<b>PDPA</b>”), which regulates the processing of
          personal data in commercial transactions, applies to all companies
          under and/or related to Gig Life Pro Pte Ltd (“<b>GLP</b>”), a private
          limited liability company established under the laws of Republic of
          Singapore (collectively, “our”, “us” or “we”). This Privacy Policy (“
          <b>Policy</b>”) governs the collection, use and disclosure of personal
          data submitted to GLP and explains how we collect and handle the
          personal date of individuals and comply with the requirements of the
          PDPA. For the purpose of this Policy, the terms “personal data” shall
          have the same meaning as prescribed in the PDPA.
        </p>
        <strong>Terms of Use</strong>
        <p>
          This Policy is meant to be read together with the Terms of Use at
          www.giglifepro.com/terms, of which cross-refers to this Privacy Police
          Notice.
        </p>
        <strong>Modifications</strong>
        <p>
          Take note that we reserve the right to modify or update this Policy
          from time to time and therefore, we encourage you to periodically
          review the latest version of this Policy.
        </p>
        <strong>Data Protection Officer</strong>
        <p>
          If you have any concerns, comments or further queries regarding this
          Policy, please contact our community manager, at
          community@giglifepro.com. You may also contact our DPO for any of the
          following reasons:
        </p>
        <ol className="list-inside">
          <li>you wish to access your own personal data;</li>
          <li>you wish to correct or amend your own personal data;</li>
          <li>
            you wish to withdraw consent in respect of our collection, use or
            disclosure of your personal data; and
          </li>
          <li>for any other reason listed below.</li>
        </ol>
        <h5>COLLECTION OF PERSONAL DATA</h5>
        <strong>Information you provide to us:</strong>
        <ol className="list-inside">
          <li>
            This Policy serves to inform you that we will obtain personal data
            about you when you visit us / our website and mobile applications
            which we may operate from time to time (collectively, “Platform”)
            and subscribe to our mailing list. By submitting your information to
            us, such as your name, e-mail address, telephone number, location
            and other data you provide voluntarily, you hereby give your consent
            to the processing of your personal data.
          </li>
          <li>
            You hereby warrant and represent to us that (a) personal data which
            you disclose to us is accurate and complete; and (b) where you
            volunteer personal data of another person to us, that you are
            authorized by such other person to disclose such personal data to
            us, and that such personal data is accurate and complete.
          </li>
          <li>
            The Platform and our related services are not intended for those
            under 18, and we do not knowingly collect information from those
            under the age of 18. As such, we collect your date of birth to
            ascertain your age. Those under 18 should not submit any personal
            information without the permission of their parents or guardians. By
            using the Platform and our related services, you are representing
            that you are at least 18.
          </li>
        </ol>
        <strong>
          Information we collect through cookies and similar technologies:
        </strong>
        <ol className="list-inside">
          <li>
            In operating this website, we may monitor the use of our website
            through the use a technology called &quot;<b>cookies</b>.&quot; A
            cookie is a piece of information that the computer that hosts our
            website gives to your computer when you access a website. For
            example, we may monitor the number of times you visit our website or
            which pages you go to. This information helps us to build a profile
            of our users. Some of this data will be aggregated or statistical,
            which means that we will not be able to identify you individually.
          </li>
          <li>
            We may collect and process information about your computer hardware
            and software when you use our website. The information can include:
            your IP address, browser type, domain names, access times and
            referring website addresses. This information is used by GLP for the
            operation of the Services (which are defined in our Terms of Use),
            to maintain quality of the Services, and to provide general
            statistics regarding use of the GLP website.
          </li>
          <li>
            Please note that our Platform uses{' '}
            <b>
              Google Analytics, Amazon Web Services (AWS) Pinpoint and/or Amazon
              Datalake Analytics
            </b>
            . GLP does not have control over third party analytics software and
            therefore, you are advised to refer to{' '}
            <a className="word-break">
              http://www.google.com/policies/privacy/partners
            </a>{' '}
            to find out more about how Google uses data when you use our and how
            you may control the information sent to Google.
          </li>
          <li>
            For EU residents, we will not collect personal data that reveals any
            of the following about you: racial or ethnic origin, political
            opinions, religious or philosophical beliefs, trade union
            membership, genetic data, biometric data, data concerning health or
            sex life, or sexual orientation
          </li>
        </ol>
        <h5>USE OF PERSONAL DATA</h5>
        <p>
          Your personal data is being or is to be collected and further
          processed for:
        </p>
        <ol className="list-inside">
          <li>
            communicating with you to send email updates, promotions and
            invitations;
          </li>
          <li>providing the Services to you;</li>
          <li>responding to your requests, inquiries and/or complaints;</li>
          <li>data mining and to gather customer feedback;</li>
          <li>market surveys and trend analysis;</li>
          <li>update you on changes to the Platform and Services;</li>
          <li>
            providing you with information on our Services and our related
            corporations and business partners unless you have otherwise
            indicated that you do not wish for us to process your personal data
            for such purpose;
          </li>
          <li>complying with any legal requirements; and</li>
          <li>such other purposes directly related to the foregoing.</li>
        </ol>
        <p>
          (collectively, the “<b>Purposes</b>”).
          <br />
          <br />
          If you wish to unsubscribe from our mailing lists or elect not to
          receive further marketing information from us, you may contact our
          DPO, or if you have been contacted by us via an email, you may use the
          unsubscribe function in that email to notify us. Such requests will be
          processed within thirty (30) days.
        </p>
        <h5>DISCLOSURE OF PERSONAL DATA</h5>
        <ol className="list-inside">
          <li>
            We do not release your personal data to third parties, except when
            for the situations listed below or unless we specifically inform you
            and give you an opportunity to opt out of our sharing your personal
            information.
          </li>
          <li>We may share your personal information with:</li>
          <li>
            Authorised service providers. Our authorised service providers are
            companies that perform certain services on our behalf. These
            services may include fulfilling orders, processing credit card
            payments, delivering packages, providing customer service and
            marketing assistance, performing business and sales analyses,
            supporting the Platform functionality, and supporting contests,
            surveys, data intermediaries or trusted partners specifically to
            assist with GLP’s activities or perform statistical analysis and
            other features we offer. These service providers may have access to
            your clients&apos; or your personal data to the extent necessary to
            perform their functions, but we do not allow them to share or use
            any of your personal data for any other purpose. Any such third
            parties whom we engage will be bound contractually to keep all
            information confidential and are prohibited from using your personal
            data except to provide these services to GLP.
          </li>
          <li>
            Affiliates. We may share information we collect, including personal
            data, with GLP-owned or affiliated businesses. Sharing information
            with our affiliates enables us to provide you with information about
            a variety of products and services that might interest you. All GLP
            companies comply with applicable privacy and security laws and, at a
            minimum, in any commercial email they send to you, will give you the
            opportunity to choose not to receive such email messages in the
            future.
          </li>
          <li>
            Business partners. We may share your personal data with the
            merchants offering the products, services, promotions and contests.
            We won&apos;t share your personal data with these businesses unless
            you choose to participate in their offer or programme. When you
            choose to engage in a particular offer or programme, you authorise
            us to share your email address and other information with the
            relevant merchant.
          </li>
          <li>
            When required by law. We also may disclose your personal data in
            response to a subpoena or similar investigative demand, a court
            order, or a request for cooperation from a law enforcement or other
            government agency; to establish or exercise our legal rights; to
            defend against legal claims; or as otherwise required by the law. In
            such cases, we may raise or waive any legal objection or right
            available to us.
          </li>
          <li>
            GLP may, from time to time, contact you on behalf of external
            business partners and/or affiliates about a particular event, mixer
            or offering that may be of interest to you. In those circumstances,
            your personal data will not be transferred to the third party
            without your explicit consent.{' '}
          </li>
        </ol>
        <h5>ACCESS TO AND CORRECTION OF PERSONAL DATA</h5>
        <ol className="list-inside">
          <li>
            You have the right to request access to and to request correction of
            your personal data. We will provide you with access to your own
            personal data or other appropriate information on your personal
            data, within thirty (30) days upon receiving request from you, in
            accordance with the requirements of the PDPA.
          </li>
          <li>
            We will correct an error or omission in your personal data that is
            in our possession or control, within thirty (30) days upon receiving
            request from you, in accordance with the requirements of the PDPA.
          </li>
          <li>
            Subject to provisions of the Act:
            <ol className="list-inside">
              <li>
                we may charge for a request for access. In the event a fee is to
                be charged, we will inform you of the amount beforehand and
                respond to your request after payment is received; and
              </li>
              <li>
                we may refuse to comply with a data access request or a data
                correction request and shall, by notice in writing, inform you
                of our refusal and the reasons of our refusal.
              </li>
            </ol>
          </li>
        </ol>
        <h5>WITHDRAWAL OF CONSENT</h5>
        <p>
          You have the right to withdraw any consent given or deemed to have
          been given in respect of our collection, use or disclosure of your
          personal data and you may give us notice of your intention to do so by
          contacting our DPO, who will inform you of the likely consequences of
          withdrawing your consent. We will cease (and cause any of our data
          intermediaries and agents to cease) collecting, using or disclosing
          the personal data unless it is required or authorised under applicable
          laws.
        </p>
        <h5>ACCURACY OF PERSONAL DATA</h5>
        <p>
          We will make a reasonable effort to ensure that personal data
          collected by us or on our behalf is accurate and complete.
        </p>
        <h5>SECURITY OF PERSONAL DATA</h5>
        <ol className="list-inside">
          <li>
            We strive to implement generally accepted standards of technology
            and operational security to try to prevent unauthorised access,
            collection, use, disclosure, copying, modification, disposal or
            similar risks. Although we will do our best to protect your personal
            data, we cannot guarantee the security of your data transmitted to
            our Platform or that hackers or unauthorised personnel will not gain
            access to your personal information despite our efforts. You should
            note that in using the Platform and/or the App and our related
            services, this information will travel through third party
            infrastructures which are not under our control. Hence, we cannot be
            held responsible for unauthorised or unintended use, access or
            disclosure that is beyond our control and you acknowledge that any
            transmission of data to our Platform is done at your own risk.
          </li>
          <li>
            All GLP personnel follow a strict internal security policy. Only
            authorised GLP personnel are provided access to personally
            identifiable information and these personnel have agreed to ensure
            confidentiality of this information.{' '}
          </li>
        </ol>
        <h5>RETENTION OF PERSONAL DATA</h5>
        <p>
          We will cease to retain personal data, as soon as it is reasonable to
          assume that the purpose for collection of such personal data is no
          longer being served by such retention, and such retention is no longer
          necessary for legal or business purposes.
        </p>
        <h5>TRANSFER OF PERSONAL DATA</h5>
        <p>
          We do not transfer your personal data outside Singapore at this point.
          However, your personal data may be transferred, stored and/or
          processed in a country or territory outside Singapore in the future,
          and you consent to any such transfer, storage and/or processing of
          your personal data outside Singapore. We will however ensure that any
          party to whom we transfer your personal data outside Singapore
          provides to such personal data a standard of protection at least
          comparable to the protection under the PDPA.{' '}
        </p>
        <strong>Disclaimer</strong>
        <ol className="list-inside">
          <li>
            This Privacy Policy only applies to our GLP Platform. Our Platform
            may contain certain links to other websites which are maintained by
            third parties. When visiting other third party websites, please
            ensure that you read their own privacy policies before disclosing
            your personal data to them. GLP makes no representations as to the
            accuracy or any other aspect of information contained in other third
            party websites.
          </li>
          <li>
            In no event will GLP, its related partnerships or corporations, or
            the partners, agents or employees thereof be liable to you or anyone
            else for any consequential, special, punitive, indirect, incidental
            or similar damages, or for any loss of reputation or goodwill,
            whether based in contract, tort (including negligence), equity,
            strict liability, statute or otherwise, even if advised of the
            possibility of such damages, as a result of unauthorised or
            unintended use, access or disclosure of your personal data.
          </li>
        </ol>
      </TextsContainer>
      <Apply />
    </div>
  );
};

export default Privacy;

import React from 'react';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from 'components/UI';
import { bgConsultation } from 'assets';
import { HashLink } from 'react-router-hash-link';
import FeaturedLayout from '../../LandingPage/common/FeaturedLayout';

const Featured = ({ scroll, linkToForm }) => {
  return (
    <FeaturedLayout
      image={bgConsultation.default}
      bgFilter={false}
      polygonBg="#e9fbff"
    >
      <div className="flex items-center justify-center relative m-auto">
        <div className="flex flex-col">
          <h1 className="text-white font-semibold leading-none my-6 sm:text-6xl text-4xl max-w-6xl">
            Need Expert Advice?
          </h1>
          <p className="text-white text-lg max-w-lg">
            Book a slot with our featured consultants today.
          </p>
          <div className="mt-6">
            <HashLink to={linkToForm} smooth scroll={scroll}>
              <Button
                label="BOOK A SLOT"
                bold={false}
                uppercase={false}
                theme="easter"
                className="bg-opacity-75 flex flex-row-reverse"
                icon={<ChevronRightIcon className="ml-2" />}
              />
            </HashLink>
          </div>
        </div>
      </div>
    </FeaturedLayout>
  );
};

Featured.propTypes = {
  scroll: PropTypes.func.isRequired,
  linkToForm: PropTypes.string.isRequired,
};

export default Featured;

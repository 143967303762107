import { takeLatest, all, fork, put, call, select } from 'redux-saga/effects';
import Actions from 'actions';
import Selectors from 'selectors';
import { createSbChannel } from 'utils/sendbird';

function* createChannel({ recipients, message, groupName, callbacks }) {
  const isGroup = Array.isArray(recipients);
  const recipientIds = isGroup
    ? recipients.map(({ sendbird_id: id }) => id)
    : [recipients.sendbird_id];
  const { sendbird_id: sendbirdId } = yield select(Selectors.getUser);

  try {
    const allIds = [...recipientIds, sendbirdId];
    const channel = yield call(createSbChannel, allIds, groupName);

    if (channel) {
      yield put(Actions.createChannelSuccess(channel));
      yield put(
        Actions.sendMessage(
          channel,
          message,
          !isGroup && recipients.sendbird_id
        )
      );
      const filteredRecipients = channel.members.filter(
        ({ userId }) => userId !== sendbirdId
      );
      const recipient = channel.members.find(
        ({ userId }) => userId !== sendbirdId
      );
      if (typeof callbacks?.success === 'function') {
        yield call(callbacks.success, {
          // eslint-disable-next-line no-underscore-dangle
          id: channel._iid,
          avatar: isGroup ? null : recipient.profileUrl,
          title: isGroup ? channel.customType : recipient.nickname,
          subtitle: (channel.lastMessage && channel.lastMessage.message) || '',
          date:
            (channel.lastMessage && channel.lastMessage.createdAt) ||
            new Date(),
          unread: channel.unreadMessageCount,
          channel,
          recipients: isGroup ? filteredRecipients : recipient,
          memberCount: channel.memberCount,
          inviter: channel.inviter,
          name: channel.customType,
          isGroup,
        });
      }
    }
  } catch (error) {
    yield put(Actions.createChannelFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchCreateChannel() {
  yield takeLatest(Actions.CREATE_CHANNEL_REQUEST, createChannel);
}

export default function* list() {
  yield all([fork(watchCreateChannel)]);
}

import React from 'react';
import { Picture, Modal } from 'components/UI';
import { imgTableBenefits } from 'assets';
import Info from '@material-ui/icons/Info';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';

const MemberDifference = () => {
  return (
    <Modal content={<Picture src={imgTableBenefits} />}>
      <button
        className="flex items-center p-4 text-white mt-5 rounded-lg bg-gradient-turqoise-violet w-full h-16"
        type="button"
      >
        <Info className="mr-4" />
        <div>{`${ALL_ACCESS} and ${PREMIUM} differences`}</div>
        <ChevronRight className="ml-auto" />
      </button>
    </Modal>
  );
};

export default MemberDifference;

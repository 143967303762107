const NAME = 'USER/UPDATE_USER';

export const UPDATE_USER_REQUEST = `${NAME}_REQUEST`;
export const UPDATE_USER_SUCCESS = `${NAME}_SUCCESS`;
export const UPDATE_USER_FAIL = `${NAME}_FAIL`;

export const updateUser = (data, callbacks) => ({
  type: UPDATE_USER_REQUEST,
  data,
  callbacks,
});

export const updateUserSuccess = (data) => ({
  type: UPDATE_USER_SUCCESS,
  data,
});

export const updateUserFail = (error) => ({
  type: UPDATE_USER_FAIL,
  error,
});

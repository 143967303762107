export const NAME = 'SENDBIRD';

export const GET_TOTAL_UNREAD_COUNT_REQUEST = `${NAME}/GET_TOTAL_UNREAD_COUNT_REQUEST`;
export const GET_TOTAL_UNREAD_COUNT_SUCCESS = `${NAME}/GET_TOTAL_UNREAD_COUNT_SUCCESS`;
export const GET_TOTAL_UNREAD_COUNT_FAIL = `${NAME}/GET_TOTAL_UNREAD_COUNT_FAIL`;

export const getTotalUnreadCount = () => ({
  type: GET_TOTAL_UNREAD_COUNT_REQUEST,
});

export const getTotalUnreadCountSuccess = (data) => ({
  type: GET_TOTAL_UNREAD_COUNT_SUCCESS,
  data,
});

export const getTotalUnreadCountFail = (error) => ({
  type: GET_TOTAL_UNREAD_COUNT_FAIL,
  error,
});

import Actions from 'actions';

const initialState = Object.freeze({});

function user(state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_SENDBIRD_USER_REQUEST:
    case Actions.UPDATE_SENDBIRD_USER_SUCCESS:
    case Actions.UPDATE_SENDBIRD_USER_FAIL:
      return initialState;
    default:
      return state;
  }
}

export default user;

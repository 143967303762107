const NAME = 'MEMBER/FETCH_TYPES';

export const FETCH_MEMBER_TYPES_REQUEST = `${NAME}_REQUEST`;
export const FETCH_MEMBER_TYPES_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_MEMBER_TYPES_FAIL = `${NAME}_FAIL`;

export const fetchMemberTypes = (params) => ({
  type: FETCH_MEMBER_TYPES_REQUEST,
  params,
});

export const fetchMemberTypesSuccess = (data) => ({
  type: FETCH_MEMBER_TYPES_SUCCESS,
  data,
});

export const fetchMemberTypesFail = (error) => ({
  type: FETCH_MEMBER_TYPES_FAIL,
  error,
});

import { all, fork } from 'redux-saga/effects';
import signIn from './signIn';
import signOut from './signOut';
import activateAccount from './activateAccount';
import createPassword from './createPassword';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import validateResetPasswordCode from './validateResetPasswordCode';
import validateEmail from './validateEmail';
import updatePassword from './updatePassword';

export default function* auth() {
  yield all([
    fork(signIn),
    fork(signOut),
    fork(activateAccount),
    fork(createPassword),
    fork(forgotPassword),
    fork(resetPassword),
    fork(validateResetPasswordCode),
    fork(validateEmail),
    fork(updatePassword),
  ]);
}

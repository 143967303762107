import React from 'react';
import { bgMembershipBanner } from 'assets';
import Tiers from './Tiers';
import { BackgroundSection } from '../LandingPage/common';
import EmailSignUp from '../LandingPage/common/EmailSignUp';

const Header = () => {
  return (
    <>
      <BackgroundSection
        backgroundImage={`url(${bgMembershipBanner.default})`}
        maxHeight="640px"
        minHeight="600px"
        backgroundPosition="45%"
        className="relative h-screen bg-no-repeat bg-cover pt-20 px-4 mb-1 flex flex-col justify-end"
      >
        <div className="flex flex-col text-center text-white relative m-auto mt-24 sm:mt-32">
          <h1 className="text-2xl md:text-6xl font-semibold">Membership</h1>
          <p className="text-lg text-wrap sm:w-3/4 self-center">
            Choose your perfect fit! We offer three membership tiers (FREE,
            PREMIUM, ALL ACCESS) to suit your eligibility and budget, available
            as monthly or annual subscriptions.
          </p>
        </div>
      </BackgroundSection>
      <div className="lg:w-9/12 xl:w-7/12 px-4 sm:mx-auto sm:pb-5">
        <div className="space-y-4 text-center">
          <h5 className="text-2lg -mt-10 pt-10 pb-5 lg:pt-12">
            Gig Life Pro is the best platform for music industry professionals
            wanting to grow their business across Asia Pacific.
          </h5>
          <p className="w-11/12 mx-auto">
            Our vibrant community connects a diverse range of individuals
            working in the Music Industry including creatives behind the scenes
            from Creators, Photographers, and Videographers to Music Business
            Students, Musicians, Producers, DJs, and Songwriters.
          </p>
          <p className="w-11/12 mx-auto">
            With FREE, PREMIUM and ALL ACCESS Membership, we invite all levels
            of industry to sign up to gain valuable industry insights, forge
            meaningful connections, and thrive within our supportive community.
          </p>
          <p className="w-11/12 mx-auto">
            Our membership community comes from Singapore, Australia, the United
            States, Malaysia, the Philippines, Thailand, South Korea, Japan,
            India and the United Kingdom and work for businesses like Def Jam
            Asia, kumu, Monstercat, Ditto, Chartmetric, BMG, The Orchard,
            Spinnup, Bandzoogle and more.
          </p>
          <p className="w-11/12 mx-auto">
            * We also offer discounted Custom Packages for group subscribers,
            please contact{' '}
            <a
              className="text-easter underline"
              href="mailto:community@giglifepro.com"
            >
              community@giglifepro.com
            </a>{' '}
            for more information.
          </p>
        </div>
      </div>
    </>
  );
};

const Membership = () => {
  return (
    <div className="w-screen flex flex-col">
      <Header />
      <Tiers />
      <EmailSignUp />
    </div>
  );
};

export default Membership;

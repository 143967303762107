import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { Card } from 'components/UI';
import { Formik, Form, Field } from 'formik';
import notify from 'utils/notification';
import {
  TextInput,
  SelectInput,
  RadioButtonInput,
  SocialMediaLinkInput,
} from 'components/Form';
import * as Yup from 'yup';
import { add, startOfDay } from 'date-fns';
import { isEmpty } from 'lodash';
import { CURRENT_TIMEZONE, DAY_OPTIONS, displayWeekday } from 'utils/datetime';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';
import FormFooter from './FormFooter';
import SlotsField from './SlotsField';

const getRateOption = (rate) => ({
  label: `$${rate} normal price / $${rate / 2} member price`,
  value: rate * 100,
});

const DEFAULT_RATES = [150, 200, 300, 600, 800];

const AUDIENCE_OPTIONS = [
  { label: 'All members', value: 'all_members' },
  { label: `${ALL_ACCESS} members only`, value: 'pro_only' },
  { label: `${PREMIUM} members only`, value: 'lite_only' },
];

const validationSchema = Yup.object().shape(
  {
    biography: Yup.string().required('Required'),
    link: Yup.string().required('Required'),
    link_password: Yup.string(),
    rate_cents: Yup.number().required('Required'),
    audience: Yup.string().required('Required'),
    slots: Yup.array()
      .of(
        Yup.object().shape({
          date: Yup.date().required('Required'),
          startHour: Yup.string().required('Required'),
          endHour: Yup.string().nullable().required('Required'),
        })
      )
      .when('recurringSlots', (v, schema) =>
        isEmpty(v) ? schema.required('Required') : schema
      ),
    recurringSlots: Yup.array()
      .of(
        Yup.object().shape({
          date: Yup.string().required('Required'),
          startHour: Yup.string().required('Required'),
          endHour: Yup.string().nullable().required('Required'),
        })
      )
      .when('slots', (v, schema) =>
        isEmpty(v) ? schema.required('Required') : schema
      ),
  },
  ['slots', 'recurringSlots']
);

const DEFAULT_VALUES = {
  biography: '',
  link: '',
  link_password: '',
  rate_cents: '',
  audience: 'all_members',
  slots: [],
  recurringSlots: [],
};

const ServiceForm = ({ toggleFormView, service }) => {
  const [isRecurringSlots, setRecurringSlot] = useState(false);
  const dispatch = useDispatch();
  const formattedSlots = service?.slots.reduce(
    (currentResult, slot) => {
      let date = new Date(slot.start_at);
      const startHour = `${date.getHours()}`;
      const endHour = `${new Date(slot.end_at).getHours()}`;
      if (!slot.recurring)
        return {
          ...currentResult,
          slots: [
            ...currentResult.slots,
            { ...slot, date, startHour, endHour },
          ],
        };

      date = DAY_OPTIONS.find(
        ({ label }) => label === displayWeekday(slot.start_at)
      ).value;
      const formatted = { ...slot, date, startHour, endHour };

      return {
        ...currentResult,
        recurringSlots: [...currentResult.recurringSlots, formatted],
      };
    },
    { slots: [], recurringSlots: [] }
  );
  const currentValues = { ...service, ...formattedSlots };
  const isUpdate = !!service;
  const initialValues = isUpdate ? currentValues : DEFAULT_VALUES;
  const isSubmitting = useSelector(
    Selectors.createLoadingSelector([
      Actions.CREATE_CONSULTATION_SERVICE_REQUEST,
    ])
  );
  const submitForm = ({ slots, recurringSlots, ...params }) => {
    const allSlots = [...slots, ...recurringSlots].map((slot) => {
      const date = startOfDay(slot.date);
      const startDateTime = add(date, { hours: slot.startHour });
      const endDateTime = add(date, { hours: slot.endHour });

      return {
        start_at: startDateTime,
        end_at: endDateTime,
        timezone: CURRENT_TIMEZONE,
        recurring: slot.recurring,
        id: slot.id,
        _destroy: slot.destroy,
      };
    });
    const data = { ...params, consultation_slots_attributes: allSlots };
    const callbacks = {
      success: () => {
        toggleFormView();
        notify('Consultation info updated');
      },
      fail: (e) => notify(e, null, 'danger'),
    };

    return dispatch(
      isUpdate
        ? Actions.updateConsultationService(data, callbacks)
        : Actions.createConsultationService(data, callbacks)
    );
  };
  const finalRateOptions = () => {
    // to be able to accept custom rates created from admin dashboard
    const options = DEFAULT_RATES.map(getRateOption);
    if (isUpdate && !DEFAULT_RATES.includes(service.rate_cents / 100))
      return [...options, getRateOption(service.rate_cents / 100)];
    return options;
  };

  return (
    <Card className="p-4 md:p-10 md:mt-5 mb-20">
      <div className="text-2lg font-chap mb-4">Consultation Info</div>
      <Formik
        onSubmit={submitForm}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <Form>
            <div className="text-md font-chap mb-1">Consultation Details</div>
            <p className="text-dusty mb-3">
              Add a short description about the consultation service you&apos;re
              offering to other members.
            </p>
            <div className="mb-2">
              <Field
                name="biography"
                component={TextInput}
                placeholder="Biography of your consultation service"
                type="textarea"
                maxLength={2000}
                className="h-30"
              />
            </div>
            <div className="mb-2">
              <p className="text-dusty">Online Consultation URL</p>
              <SocialMediaLinkInput
                className="w-full md:w-3/4"
                name="link"
                placeholder="Paste URL for members to attend consultation"
              />
            </div>
            <div className="sm:max-w-sm mb-2">
              <p className="text-dusty">Online link password (Optional)</p>
              <Field
                name="link_password"
                component={TextInput}
                placeholder="Online link password"
                className="w-full my-2"
              />
            </div>
            <div className="mb-2 sm:max-w-xl">
              <div className="text-md font-chap mb-1">
                Consultation Rate (USD per hour)
              </div>
              <p className="text-dusty mb-1">
                {`${ALL_ACCESS} & ${PREMIUM} members will enjoy 50% off the normal price`}
              </p>
              <Field
                name="rate_cents"
                placeholder="Rate per hour"
                component={SelectInput}
                options={finalRateOptions()}
                className="mr-4 mt-1"
              />
            </div>
            <SlotsField
              isRecurringSlots={isRecurringSlots}
              setRecurringSlot={setRecurringSlot}
            />
            <div className="mb-2">
              <div className="text-md font-chap mb-2">
                Who can apply for your consultation service?
              </div>
              <Field
                name="audience"
                component={RadioButtonInput}
                options={AUDIENCE_OPTIONS}
              />
            </div>
            <FormFooter
              onSubmit={handleSubmit}
              onCancel={toggleFormView}
              isSubmitting={isSubmitting}
              isUpdate={isUpdate}
            />
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default ServiceForm;

ServiceForm.propTypes = {
  toggleFormView: PropTypes.func.isRequired,
  service: PropTypes.object,
};

ServiceForm.defaultProps = {
  service: null,
};

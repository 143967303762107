import React from 'react';
import PropTypes from 'prop-types';
import { Picture } from 'components/UI';
import classnames from 'classnames';
import { gfxSubscription } from 'assets';

const Pricing = ({ amount, setPlan, isSelected, isAnnual, savings }) => (
  <div
    className={classnames(
      'flex flex-col items-center border-2 rounded-lg mt-4 w-48',
      {
        'border-easter border-4 shadow-2xl': isSelected,
        'border-alto cursor-pointer': !isSelected,
      }
    )}
    onClick={setPlan}
    role="presentation"
  >
    <h5 className="text-2lg px-10 mt-4 uppercase text-ebony">
      {isAnnual ? 'Annually' : 'Monthly'}
    </h5>
    <Picture src={gfxSubscription} className="h-10" />
    <div className="flex">
      <span className="text-easter font-semibold">USD</span>
      <span className="text-2xl text-easter leading-none font-semibold">
        {amount}
      </span>
      <span className="text-sm self-end text-ebony">
        {isAnnual ? '/yr' : '/mo'}
      </span>
    </div>
    <div className="text-dusty text-sm h-4 mt-2 mb-4">
      {isAnnual && `Save USD ${savings}!`}
    </div>
  </div>
);

Pricing.propTypes = {
  amount: PropTypes.number.isRequired,
  setPlan: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
  isAnnual: PropTypes.bool.isRequired,
  savings: PropTypes.number,
};

Pricing.defaultProps = {
  savings: 0,
  setPlan: null,
};

export default Pricing;

/* eslint-disable camelcase */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Button, Card, ErrorContainer, Footer } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import { currencyFormat } from 'utils/format';
import isEmpty from 'lodash/isEmpty';
import { iconPromo, iconLock } from 'assets';
import { SocialMediaLinkInput } from 'components/Form';
import notify from 'utils/notification';
import { ALL_ACCESS } from 'utils/constants';
import { AuthContext } from 'utils/context';
import Pricing from '../Auth/Activate/Pricing';
import CreditCardDetails from './CreditCardDetails';
import StripeCreditCardForm from './StripeCreditCardForm';

const Update = () => {
  const formikRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [error, setError] = useState();
  const [promo, setPromo] = useState();
  const [newPrice, setNewPrice] = useState();
  const { isFreeTier, isLite } = useContext(AuthContext);
  const tiers = useSelector(Selectors.memberTiersList);
  const creditCard = useSelector(Selectors.creditCardDetails) || {};
  const currentUser = useSelector(Selectors.getUser);
  const { price: currentPrice = {} } = useSelector(Selectors.getSubscription);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([
      Actions.FETCH_USER_CREDIT_CARD_REQUEST,
      Actions.FETCH_SUBSCRIPTION_REQUEST,
      Actions.FETCH_MEMBER_TIERS_REQUEST,
    ])
  );
  const isSubmitting = useSelector(
    Selectors.createLoadingSelector([Actions.UPDATE_SUBSCRIPTION_REQUEST])
  );

  const tierToUpdate = state?.tierToUpdate;
  const newTier =
    tierToUpdate && tiers.find(({ name }) => name === tierToUpdate);
  const currentTier = tiers.find(({ id }) => id === currentUser.member_tier.id);
  const memberTier = newTier || currentTier;
  const memberTierPrices = memberTier?.prices || [];
  const isLiteToPro = isLite && tierToUpdate === ALL_ACCESS;
  const memberTierPriceOptions = (() => {
    if (tierToUpdate) {
      if (isLiteToPro) {
        return memberTierPrices.filter(
          ({ interval }) => interval === currentPrice.interval
        );
      }

      return memberTierPrices;
    }
    return memberTierPrices.filter(({ id }) => id !== currentPrice.id);
  })();

  const title = tierToUpdate
    ? `Switch to ${tierToUpdate.toUpperCase()} Membership`
    : 'Switch Billing Plan';

  const amountOff = promo?.coupon?.amount_off;
  const percentOff = promo?.coupon?.percent_off;
  const newPriceAmount = Number(newPrice?.amount || 0);
  const discount = amountOff / 100 || newPriceAmount * (percentOff / 100) || 0;
  const finalPrice = currencyFormat(newPriceAmount - discount);

  const redirectStripePortal = () =>
    dispatch(
      Actions.createPortalSession({
        success: ({ url }) => {
          window.location = url;
          return null;
        },
        failure: () =>
          notify(
            'Change is unavailable at the moment. Please try again later.',
            null,
            'danger'
          ),
      })
    );

  const submitPromo = ({ promoCode }) => {
    setError(null);
    if (!newPrice || isEmpty(promoCode)) return;

    dispatch(
      Actions.validatePromoCode(
        { code: promoCode, price_id: newPrice.id },
        {
          success: setPromo,
          failure: (error) => {
            setPromo(null);
            if (error?.response?.status === 404) {
              setError('Invalid promo code');
            } else setError(error?.response?.data?.error || error?.message);
          },
        }
      )
    );
  };

  const NewPricings = () => {
    if (!memberTierPriceOptions.length) return null;

    const priceMonthly = Number(
      memberTierPrices.find(({ interval }) => interval === 'month')?.amount
    );
    const priceAnnually = Number(
      memberTierPrices.find(({ interval }) => interval === 'year')?.amount
    );
    const annualSavings = priceMonthly * 12 - priceAnnually;

    return (
      <div className="flex flex-col sm:flex-row items-center justify-around">
        {memberTierPriceOptions.map((price) => {
          const isAnnual = price.interval === 'year';
          return (
            <Pricing
              key={price.id}
              setPlan={() => setNewPrice(price)}
              isSelected={newPrice?.id === price.id}
              isAnnual={isAnnual}
              amount={Number(price.amount)}
              savings={isAnnual ? annualSavings : 0}
            />
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    dispatch(Actions.fetchMemberTiers());
    dispatch(Actions.fetchUserCreditCard());
    dispatch(Actions.fetchSubscription());
  }, [dispatch]);

  useEffect(() => {
    if (!newPrice && memberTierPriceOptions.length === 1) {
      setNewPrice(memberTierPriceOptions[0]);
    }
  }, [newPrice, memberTierPriceOptions]);

  useEffect(() => {
    setPromo();
    if (formikRef.current) formikRef.current.resetForm();
  }, [newPrice]);

  useEffect(() => {
    if (tiers.length > 0 && !memberTierPriceOptions.length) {
      navigate('/subscription');
    }
  }, [tiers, memberTierPriceOptions, navigate]);

  return (
    <div className="p-4 sm:p-8 w-full">
      <h3 className="uppercase">My Subscription</h3>
      <Card className="mt-4 max-w-6xl flex mb-20">
        {isLoading ? (
          <div className="flex justify-center items-center py-4 min-h-64 w-full">
            <Spinner className="h-20" color="#47bad4" />
          </div>
        ) : (
          <div className="py-8 flex flex-col sm:flex-row w-full">
            <div className="flex flex-col sm:w-1/2 px-6 sm:px-10 mb-4 sm:m-0">
              <h3 className="font-semibold">{title}</h3>
              {!isFreeTier && (
                <p className="text-dusty mb-4 mt-2">
                  {isLiteToPro && currentPrice?.interval === 'year' ? (
                    <span>
                      Your <b>prorated charges</b> will be processed at the time
                      of your upgrade
                    </span>
                  ) : (
                    'Your membership fee will be deducted after your current billing cycle ends'
                  )}
                </p>
              )}
              <NewPricings />
              <Formik
                initialValues={{ promoCode: '' }}
                onSubmit={submitPromo}
                innerRef={formikRef}
              >
                <Form>
                  <div className="flex items-center justify-between mt-6">
                    <SocialMediaLinkInput
                      className="w-full mr-4"
                      name="promoCode"
                      img={iconPromo.default}
                      placeholder="Enter promo code…"
                    />
                    <button
                      className="flex items-center text-easter pb-2 focus:outline-none"
                      type="submit"
                    >
                      Verify
                    </button>
                  </div>
                  <ErrorContainer
                    className="max-w-120 mx-auto mt-4"
                    error={error}
                  />
                </Form>
              </Formik>
            </div>
            <div className="flex flex-col sm:w-1/2 pt-6 sm:pt-0 border-t sm:border-t-0 sm:border-l px-6 sm:px-10">
              <h3 className="font-semibold">Summary</h3>
              <div className="flex flex-col mb-6 h-full justify-end sm:w-3/4 mt-8">
                {newPrice && (
                  <h5 className="mb-4 font-lynstone">
                    {`Membership Fee (${
                      newPrice?.interval === 'year' ? 'Annual' : 'Monthly'
                    })`}
                  </h5>
                )}
                <div className="flex justify-between mb-3">
                  <p className="text-boulder">Amount</p>
                  <p className="text-rock">{currencyFormat(newPriceAmount)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-boulder">Discount</p>
                  <p className="text-rock">
                    {`- `}
                    {!percentOff || isEmpty(promo)
                      ? currencyFormat(discount)
                      : `${percentOff}% (${currencyFormat(discount)})`}
                  </p>
                </div>
                <div className="flex justify-between items-center border-t-2 border-alto pt-2 mt-3">
                  <p className="text-boulder">Total</p>
                  <p className="font-semibold text-2lg text-easter">
                    {finalPrice}
                  </p>
                </div>
              </div>
              {isEmpty(creditCard) ? (
                <StripeCreditCardForm
                  amount={newPriceAmount - discount}
                  promoId={promo?.id}
                  priceId={newPrice?.id}
                />
              ) : (
                <div className="pt-2 border-t-4">
                  <CreditCardDetails
                    data={creditCard}
                    redirectStripePortal={redirectStripePortal}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Card>
      <Footer>
        <div className="flex items-center w-full">
          <button
            type="button"
            className="uppercase text-dusty hover:text-easter flex focus:outline-none ml-auto mr-4"
            onClick={() => navigate('/subscription')}
          >
            Cancel
          </button>
          {!isEmpty(creditCard) && (
            <Button
              type="button"
              isPill
              className="px-4 sm:px-10"
              disabled={isSubmitting || !newPriceAmount || !!error}
              onClick={() =>
                dispatch(
                  Actions.updateSubscription(
                    { price_id: newPrice.id, promo_id: promo?.id },
                    {
                      success: () => {
                        notify('Successfully updated subscription!');
                        navigate('/subscription');
                      },
                      failure: () =>
                        notify(
                          'Failed to update subscription, please try again later',
                          null,
                          'danger'
                        ),
                    }
                  )
                )
              }
              label={
                <div className="flex">
                  {`Pay ${finalPrice}`}
                  <img
                    alt="check"
                    src={iconLock.default}
                    className="h-5 ml-5"
                  />
                </div>
              }
            />
          )}
        </div>
      </Footer>
    </div>
  );
};

export default Update;

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Picture, Button } from 'components/UI';
import {
  allAccessRedeemed,
  premiumRedeemed,
  redeemedIcon,
  rewardsRedeemed,
} from 'assets';
import { ALL_ACCESS_MEMBERSHIP, PREMIUM_MEMBERSHIP } from 'utils/constants';
import classnames from 'classnames';
import { displayDayDateFormat } from '../../../utils/datetime';

const RedeemableActivityCard = ({ redeemableActvity }) => {
  const navigate = useNavigate();

  const {
    created_at: createdAt,
    points,
    title,
    redeemable_type: redeemableType,
    status,
  } = redeemableActvity;
  const membershipType =
    redeemableType === PREMIUM_MEMBERSHIP || ALL_ACCESS_MEMBERSHIP;
  const path = membershipType ? '/subscription' : '/events';

  const redeemableIcon =
    redeemableType === PREMIUM_MEMBERSHIP ? premiumRedeemed : allAccessRedeemed;

  return (
    <>
      <section className="w-full xl:w-11/12 rounded-lg border p-3 lg:py-5 md:px-3 lg:px-5 mt-5 flex items-center">
        <div className="flex flex-col">
          <Picture
            src={membershipType ? redeemableIcon : rewardsRedeemed}
            className="self-center mr-5 w-20 lg:w-20 rounded-full"
          />
          <Picture
            src={redeemedIcon}
            className="mr-5 w-5 rounded-full self-end -mt-3"
          />
        </div>
        <article className="flex flex-col md:flex-row md:justify-between w-full">
          <section className="flex flex-col">
            <p>{title} sucessfully redeemed</p>

            <p className="text-xxs lg:text-sm text-dusty font-light">
              {displayDayDateFormat(createdAt)}
            </p>
          </section>
          <section className="flex flex-col">
            {status === 'inactive' ? (
              <Button
                className="h-10 w-3/5 md:w-full md:self-end text-xxs text-center justify-center lg:w-34 flex items-center mt-2 md:text-xs"
                label="USE NOW"
                outline
                onClick={() => navigate(path)}
              />
            ) : (
              <p
                className={classnames(' capitalize self-end mr-2', {
                  'text-brilliantGreen': status === 'active',
                  'text-dusty': status === 'used',
                })}
              >
                {status === 'used' ? 'Used' : status}
              </p>
            )}
            <p className="text-xs lg:text-base md:ml-auto mr-2 mt-2 md:self-center font-semibold text-valencia opacity-75 ">
              - {points} Points
            </p>
          </section>
        </article>
      </section>
    </>
  );
};

RedeemableActivityCard.propTypes = {
  redeemableActvity: PropTypes.object,
};

RedeemableActivityCard.defaultProps = {
  redeemableActvity: {},
};

export default RedeemableActivityCard;

import React from 'react';
import PropTypes from 'prop-types';
import { ccExpiry } from 'utils/format';
import styled from '@emotion/styled';
import { iconCardFallback } from 'assets';
import classNames from 'classnames';

const StyledCcLogo = styled.img`
  height: 35px;
  width: auto;
`;

const CreditCardDetails = ({ data, redirectStripePortal, isModal }) => {
  const paymentMethodType = data?.type;
  const { brand, exp_month: expMonth, exp_year: expYear, last4 } =
    data?.card || {};

  const creditCardBrandLogoSrc = () => {
    let brandLogo;
    try {
      // eslint-disable-next-line import/no-dynamic-require
      brandLogo = require(`../../../public/img/card_networks/${brand}.png`);
    } catch {
      brandLogo = iconCardFallback;
    }
    return brandLogo.default;
  };

  return (
    <div className="flex items-center p-4">
      {paymentMethodType === 'card' && (
        <>
          <StyledCcLogo src={creditCardBrandLogoSrc()} alt={`${brand} logo`} />
          <div className="mx-6">
            <h3
              className={classNames('font-lynstone', {
                'text-base': isModal,
              })}
            >
              XXXX XXXX XXXX {last4}
            </h3>
            <span
              className={classNames('font-lynstone text-boulder', {
                'text-sm': isModal,
              })}
            >
              {ccExpiry({ expMonth, expYear })}
            </span>
          </div>
        </>
      )}
      {paymentMethodType === 'link' && (
        <div className="flex items-center">
          <div className="mx-6">
            <h3
              className={classNames('font-lynstone', {
                'text-base': isModal,
              })}
            >
              Link
            </h3>
            <span
              className={classNames('font-lynstone text-boulder', {
                'text-sm': isModal,
              })}
            >
              {data?.link?.email}
            </span>
          </div>
        </div>
      )}
      <button
        type="button"
        className={classNames(
          'text-easter flex focus:outline-none ml-auto mr-2',
          {
            'text-sm': isModal,
          }
        )}
        onClick={redirectStripePortal}
      >
        Change
      </button>
    </div>
  );
};

CreditCardDetails.propTypes = {
  data: PropTypes.object.isRequired,
  redirectStripePortal: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
};

CreditCardDetails.defaultProps = {
  isModal: false,
};

export default CreditCardDetails;

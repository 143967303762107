import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import { updateSbUserProfile } from 'utils/sendbird';

function* updateSendbirdUser({ name, picture }) {
  try {
    const response = yield call(updateSbUserProfile, name, picture);
    if (response) {
      yield put(Actions.updateSendbirdUserSuccess());
    }
  } catch (error) {
    yield put(Actions.updateSendbirdUserFail(error));
  }
}

function* watchUpdateSendbirdUser() {
  yield takeLatest(Actions.UPDATE_SENDBIRD_USER_REQUEST, updateSendbirdUser);
}

export default function* update() {
  yield all([fork(watchUpdateSendbirdUser)]);
}

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchBookings({ params }) {
  try {
    const response = yield call(api.fetchServiceBookings, params);
    yield put(Actions.fetchServiceBookingsSuccess(response.data));
  } catch (error) {
    yield put(Actions.fetchServiceBookingsFail(error));
  }
}

function* watchFetchBookings() {
  yield takeLatest(Actions.FETCH_SERVICE_BOOKINGS_REQUEST, fetchBookings);
}

export default function* bookings() {
  yield all([fork(watchFetchBookings)]);
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Close from '@material-ui/icons/Close';
import { iconSuccess, iconCaution, modalHeader } from 'assets';
import { Spinner } from 'components/UI/Loading';
import { Button, ErrorContainer, Card, Picture } from 'components/UI';
import { BackgroundSection } from '../../containers/LandingPage/common';
import AddToCalendar from './AddToCalendar';

const Dialog = () => {
  const dispatch = useDispatch();
  const { dialog, isVisible } = useSelector(Selectors.getDialog);
  const [isLoading, setLoading] = useState(false);
  const {
    start_at: start,
    end_at: end,
    webinar_link: link,
    name,
  } = useSelector(Selectors.eventInfo);

  useEffect(() => {
    if (isLoading && !isVisible) {
      setLoading(false);
    }
  }, [isVisible, isLoading]);

  const calendarProps = {
    start,
    end,
    link,
    title: name,
    showDescription: true,
  };

  if (isEmpty(dialog)) return null;

  const {
    title = 'Are you sure?',
    onAccept,
    onDismiss,
    content,
    subContent,
    isSuccess,
    dismissLabel = 'Cancel',
    acceptLabel = 'OK',
    className = 'bg-black-cover',
    lightDismiss = false,
    error,
    showAddToCalendar,
    acceptBtnTheme,
    hideButtons = false,
  } = dialog;

  const hideDialog = () => dispatch(Actions.hideDialog());

  const onAcceptClicked = () => {
    setLoading(true);
    if (onAccept) onAccept();
    else hideDialog();
  };

  const onDismissClicked = () => {
    setLoading(false);
    if (onDismiss) {
      onDismiss();
    }
    hideDialog();
  };

  return (
    <div
      className={classnames(
        className,
        'flex top-0 bottom-0 right-0 left-0 modal fixed w-screen py-20 overflow-auto z-40',
        'duration-500 transition-all ease-in',
        { 'flex opacity-100': isVisible },
        { 'hidden opacity-0': !isVisible }
      )}
      role="presentation"
    >
      <div
        onClick={lightDismiss ? onDismissClicked : undefined}
        role="presentation"
        className="absolute top-0 right-0 bottom-0 left-0"
        style={{ zIndex: -1 }}
      />
      <div className="max-w-2xl flex flex-col m-auto transition-slow w-full transition-slow">
        <div className="px-2">
          <Card className="sm:w-3/4 mx-auto">
            <BackgroundSection
              backgroundImage={`url(${modalHeader.default})`}
              className={classnames(
                'relative bg-no-repeat bg-cover bg-center h-18 rounded-t-lg',
                { 'mb-4': !isSuccess }
              )}
            >
              {isSuccess ? (
                <div className="p-5">
                  <Picture
                    src={title === 'Caution' ? iconCaution : iconSuccess}
                    className="flex mx-auto rounded-full w-20 px-auto -mb-12"
                    disableParentClassName
                  />
                </div>
              ) : (
                <div className="flex p-5 justify-center uppercase text-2lg text-white font-semibold">
                  {title}
                </div>
              )}
            </BackgroundSection>
            {error && (
              <div className="px-12 -mb-8 text-center">
                <ErrorContainer error={error} />
              </div>
            )}
            <div className="grid gap-5 px-4 sm:px-8 py-12 place-items-center">
              <div
                className={classnames(
                  'text-md text-center',
                  { 'font-semibold': isSuccess },
                  { 'pb-4 px-4': isSuccess }
                )}
              >
                {content}
              </div>
              {subContent && (
                <p className="text-center text-boulder">{subContent}</p>
              )}
              {showAddToCalendar && name && (
                <AddToCalendar {...calendarProps} />
              )}
              <>
                {!hideButtons && (
                  <>
                    {isLoading &&
                    !isSuccess &&
                    title !== 'Caution' &&
                    !error ? (
                      <Spinner height="40" color="#47bad4" />
                    ) : (
                      <>
                        <Button
                          label={acceptLabel}
                          className="w-full sm:w-3/4 h-12 px-4"
                          onClick={
                            isSuccess ? onDismissClicked : onAcceptClicked
                          }
                          isPill
                          theme={acceptBtnTheme}
                        />
                        {((isSuccess && title === 'Caution') || !isSuccess) && (
                          <Button
                            label={dismissLabel}
                            className="w-full sm:w-3/4 h-12 px-4"
                            theme="plain"
                            outline
                            isPill
                            onClick={onDismissClicked}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </Card>
        </div>
      </div>
      <div className="absolute cursor-pointer mr-3 text-gray-500 right-0 top-0 text-xl">
        <Close
          onClick={onDismissClicked}
          role="presentation"
          fontSize="inherit"
        />
      </div>
    </div>
  );
};

export default Dialog;

const NAME = 'ARTICLES';

export const UNFAVOURITE_ARTICLE_INFO_REQUEST = `${NAME}/UNFAVOURITE_ARTICLE_INFO_REQUEST`;
export const UNFAVOURITE_ARTICLE_INFO_SUCCESS = `${NAME}/UNFAVOURITE_ARTICLE_INFO_SUCCESS`;
export const UNFAVOURITE_ARTICLE_INFO_FAIL = `${NAME}/UNFAVOURITE_ARTICLE_INFO_FAIL`;

export const unfavouriteArticleInfo = (id, callback) => ({
  type: UNFAVOURITE_ARTICLE_INFO_REQUEST,
  id,
  callback,
});

export const unfavouriteArticleInfoSuccess = () => ({
  type: UNFAVOURITE_ARTICLE_INFO_SUCCESS,
});

export const unfavouriteArticleInfoFail = (error) => ({
  type: UNFAVOURITE_ARTICLE_INFO_FAIL,
  error,
});

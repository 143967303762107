import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from '@emotion/styled';
import { width } from 'styled-system';
import { joinedEventsEmpty } from 'assets';
import { Spinner } from 'components/UI/Loading';
import { Card, Button, Picture } from 'components/UI';
import EventAttendedCard from './EventAttendedCard';

const CardContainer = styled.div`
  ${width}
`;

const AttendingList = ({
  events,
  eventsPath,
  handleTab,
  handleCancelBooking,
  isLoading,
}) => {
  return (
    <div className="lg:container mx-auto sm:px-6 px-2 my-20 max-w-full">
      <div className="font-semibold pb-2 sm:pb-0 text-lg font-chap">
        ATTENDING EVENTS
      </div>
      {isLoading && isEmpty(events) && (
        <div className="flex my-20">
          <Spinner />
        </div>
      )}
      {!isLoading && isEmpty(events) ? (
        <Card className="mx-auto border-t-2 p-10 text-center mt-5">
          <div className="flex flex-no-wrap overflow-x-auto w-full">
            <div className="m-auto text-center">
              <Picture src={joinedEventsEmpty} className="py-8 w-2/5 m-auto" />
              <h3 className="font-semibold font-lynstone">
                You have not attended any events
              </h3>
              <p className="text-dusty">
                Events you attend to will appear here
              </p>
              <Button
                className="mt-8 max-w-80 mx-auto"
                label="VIEW ALL UPCOMING EVENTS"
                onClick={() => handleTab('/members/events/explore')}
              />
            </div>
          </div>
        </Card>
      ) : (
        <>
          {events.map((item) => (
            <CardContainer
              className="flex-grow-0 flex-shrink-0 my-4"
              key={item.id}
            >
              <EventAttendedCard
                eventInfo={item}
                key={item.id}
                eventsPath={eventsPath}
                handleCancelBooking={handleCancelBooking}
              />
            </CardContainer>
          ))}
        </>
      )}
    </div>
  );
};

AttendingList.propTypes = {
  events: PropTypes.array.isRequired,
  handleTab: PropTypes.func.isRequired,
  eventsPath: PropTypes.string,
  handleCancelBooking: PropTypes.func,
  isLoading: PropTypes.bool,
};

AttendingList.defaultProps = {
  eventsPath: '',
  handleCancelBooking: null,
  isLoading: false,
};

export default AttendingList;

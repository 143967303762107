import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* optInEvent({ id, callbacks }) {
  try {
    const response = yield call(api.optInEvent, id);
    yield put(Actions.optInEventSuccess(response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks?.success, response.data);
    }
  } catch (error) {
    yield put(Actions.optInEventFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchOptInEvent() {
  yield takeLatest(Actions.OPT_IN_EVENT_REQUEST, optInEvent);
}

export default function* optIn() {
  yield all([fork(watchOptInEvent)]);
}

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchAddedEvents() {
  try {
    const response = yield call(api.fetchEvents, { saved: true });
    yield put(Actions.fetchAddedEventsSuccess(response?.data));
  } catch (error) {
    yield put(Actions.fetchAddedEventsFail());
  }
}

function* watchFetchAddedEvents() {
  yield takeLatest(Actions.FETCH_ADDED_EVENTS_REQUEST, fetchAddedEvents);
}

export default function* list() {
  yield all([fork(watchFetchAddedEvents)]);
}

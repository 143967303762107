import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import styled from '@emotion/styled';
import { background, layout } from 'styled-system';
import { bgMembers } from 'assets';
import {
  ALL_ACCESS,
  membershipCriteria,
  membershipBenefits,
  experienceOptions,
} from 'utils/constants';
import TierInfoCard from './TierInfoCard';
import ExperienceFilter from './ExperienceFilter';

const SecondBackground = styled.div`
  clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
  ${background}
  ${layout}
`;

const Filter = styled.div`
  z-index: 9;
  ${background}
`;

const Tiers = () => {
  const [isAllAccessEligible, setIsAllAccessEligible] = useState(true);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(
    experienceOptions[experienceOptions.length - 1]
  );
  const handleTabChange = (experience) => {
    setActiveTab(experience);
    setIsAllAccessEligible(
      experience === experienceOptions[experienceOptions.length - 1]
    );
  };
  const memberTiers = useSelector(Selectors.memberTiersList);

  const getPrice = (tier, interval) =>
    Number(tier?.prices?.find((price) => price.interval === interval)?.amount);
  const getMinPrice = (prices) =>
    prices.length ? Math.min(...prices.map(({ amount }) => amount)) : 0;

  const sortedTiers = memberTiers
    .filter(({ name }) => isAllAccessEligible || name !== ALL_ACCESS)
    .sort(
      (tier1, tier2) => getMinPrice(tier1.prices) - getMinPrice(tier2.prices)
    );

  useEffect(() => {
    dispatch(Actions.fetchMemberTiers());
  }, [dispatch]);

  return (
    <>
      <ExperienceFilter
        handleTabChange={handleTabChange}
        activeTab={activeTab}
      />
      <SecondBackground
        minHeight="600px"
        backgroundImage={`url(${bgMembers.default})`}
        backgroundPosition="50%"
        className="relative bg-no-repeat bg-cover mt-4 mb-40"
      >
        <Filter
          background="linear-gradient(180deg, rgba(48,45,68,0) -50%, #302D44 95%, #302D44 0%);"
          className="bg-opacity-75 absolute top-0 bottom-0 right-0 left-0 rounded"
        />
        <div className="flex flex-col justify-center max-w-6xl mx-auto my-12 px-4 relative z-10">
          <div className="mt-5 flex flex-col md:flex-row gap-6 space-y-3 md:space-y-0 lg:px-3 justify-center">
            {sortedTiers.map((tier) => (
              <TierInfoCard
                key={tier.id}
                memberTier={tier?.name}
                content={membershipCriteria[tier?.name]}
                benefits={membershipBenefits[tier?.name]}
                monthlyPrice={getPrice(tier, 'month')}
                annualPrice={getPrice(tier, 'year')}
                isAllAccessEligible={isAllAccessEligible}
              />
            ))}
          </div>
        </div>
      </SecondBackground>
    </>
  );
};

export default Tiers;

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* updateEvent({ id, data: payload, callbacks }) {
  try {
    const { image, ...rest } = payload;
    const data = typeof image === 'string' ? rest : payload;
    const response = yield call(api.updateEvent, { id, data });
    yield put(Actions.updateEventSuccess(id, response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.updateEventFail(error));
    if (typeof callbacks?.failure === 'function') {
      const err = error?.response?.data?.error;
      yield call(
        callbacks.failure,
        err ? err[0] : 'Something went wrong, please try again later'
      );
    }
  }
}

function* watchUpdateEvent() {
  yield takeLatest(Actions.UPDATE_EVENT_REQUEST, updateEvent);
}

export default function* update() {
  yield all([fork(watchUpdateEvent)]);
}

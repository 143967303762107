import auth from './auth';
import persist from './persist';
import events from './events';
import cities from './cities';
import user from './user';
import articles from './articles';
import newsLetterSignUp from './newsLetterSignUp';
import countries from './countries';
import membership from './membership';
import sendbird from './sendbird';
import sendgrid from './sendgrid';
import stripe from './stripe';
import common from './common';
import dashboard from './dashboard';
import subscription from './subscription';
import ui from './ui';
import consultation from './consultation';
import timezones from './timezones';
import videos from './videos';
import perks from './perks';
import consultationService from './consultationService';

export default {
  ...auth,
  ...persist,
  ...events,
  ...cities,
  ...user,
  ...articles,
  ...newsLetterSignUp,
  ...countries,
  ...sendbird,
  ...sendgrid,
  ...stripe,
  ...membership,
  ...common,
  ...dashboard,
  ...subscription,
  ...ui,
  ...consultation,
  ...timezones,
  ...videos,
  ...perks,
  ...consultationService,
};

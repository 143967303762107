import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Field, getIn } from 'formik';
import { iconLinkCheckmark, iconWebsiteLink } from 'assets';
import styled from '@emotion/styled';
import classnames from 'classnames';
import TextInput from './TextInput';

const StyledImg = styled.img`
  position: absolute;
  padding-bottom: 11px;
  padding-right: 9px;
  height: 24px;
`;

const SocialMediaLinkInput = ({ img, name, placeholder, className }) => {
  const { errors, values } = useFormikContext();
  const isValid = !getIn(errors, name) && getIn(values, name);

  return (
    <div className={classnames('flex justify-end', className)}>
      <img
        src={img}
        alt=""
        className="h-12 rounded-r-none rounded-l-full mt-2 focus:outline-none shadow"
      />
      <Field
        name={name}
        fieldClassName="flex-grow"
        className="h-12 border-none outline-none focus:outline-none rounded-r-full"
        basic
        component={TextInput}
        placeholder={placeholder}
      />
      <StyledImg
        src={iconLinkCheckmark.default}
        alt=""
        className={classnames('h-10 self-center', { invisible: !isValid })}
      />
    </div>
  );
};

SocialMediaLinkInput.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SocialMediaLinkInput.defaultProps = {
  className: null,
  img: iconWebsiteLink.default,
};

export default SocialMediaLinkInput;

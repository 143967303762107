import React from 'react';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import { Button } from 'components/UI';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-query';
import api from 'api';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Form';
import notify from 'utils/notification';

const PasswordSchema = Yup.object({
  password: Yup.string().required('Password is required'),
});

const TerminateForm = () => {
  const dispatch = useDispatch();
  const { mutate } = useMutation(api.terminateAccount, {
    onSuccess: () => {
      setTimeout(() => dispatch(Actions.clearUser()), 4000);
      notify('Account successfully terminated');
      dispatch(Actions.hideDialog());
    },
    onError: (e) =>
      notify(
        e?.response?.data?.error ||
          'Something went wrong, please try again later',
        null,
        'danger'
      ),
  });

  return (
    <div className="flex flex-col gap-4 text-left text-base">
      <p className="text-md text-center">
        Please enter your password to continue
      </p>
      <p className="text-boulder text-center">
        We need to make sure that this account that you want to permanently
        delete belongs to you.
      </p>
      <Formik
        initialValues={{ password: '' }}
        onSubmit={mutate}
        validationSchema={PasswordSchema}
      >
        {({ errors, values }) => (
          <Form className="space-y-4">
            <Field
              label="Password"
              placeholder="Password"
              name="password"
              type="password"
              component={TextInput}
            />
            <div className="flex flex-col justify-center items-center m-auto gap-4">
              <Button
                label="YES, DELETE ACCOUNT"
                className="w-full sm:w-3/4 h-12 px-4"
                type="submit"
                isPill
                theme="valencia"
                disabled={!isEmpty(errors) || !values.password}
              />
              <Button
                label="NO, DON'T DELETE"
                className="w-full sm:w-3/4 h-12 px-4"
                theme="plain"
                outline
                isPill
                onClick={() => dispatch(Actions.hideDialog())}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TerminateForm;

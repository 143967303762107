import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { Select } from 'components/UI';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

const Filter = () => {
  const params = useQuery();
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();

  const { country_id: countryId } = params;
  const hasParams = Object.values(params).some((obj) => obj !== '');
  const [hasFetchedOptions, setFetchedOptions] = useState(false);

  useEffect(() => {
    if (!hasFetchedOptions) {
      dispatch(Actions.fetchCountries({ has_cities: true }));
      setFetchedOptions(true);
    }
  }, [dispatch, hasFetchedOptions]);
  const countries = useSelector(Selectors.countries);

  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_COUNTRIES_REQUEST])
  );

  const updateFilter = (query) => {
    const queryParams = { ...params, ...query };
    const result = Object.entries(queryParams).reduce((result, [k, v]) => {
      if (v) result[k] = v;
      return result;
    }, {});

    setSearchParams(result);
  };

  const countryOptions = [
    { label: 'All Countries', value: null },
    ...countries.map(({ name, id }) => ({
      label: name,
      value: id,
    })),
  ];

  return (
    <div
      className={classnames(
        'container mx-auto px-6 flex justify-between md:flex-row transition-all duration-400 ease-linear'
      )}
    >
      <Select
        options={countryOptions}
        defaultValue={countryOptions.find(
          ({ value }) => value === parseInt(countryId, 10)
        )}
        onChange={({ value }) => updateFilter({ country_id: value })}
        placeholder="All Countries"
        isLoading={isLoading}
        className="sm:w-56"
        key={countryId}
      />
      {hasParams && (
        <button
          className="border mb-5 border-silver rounded shadow text-silver focus:outline-none px-4 h-10 mr-2"
          onClick={() => {
            updateFilter({ country_id: '', search: '' });
          }}
          type="button"
        >
          Clear Filter
        </button>
      )}
    </div>
  );
};

export default Filter;

import {
  takeLatest,
  all,
  fork,
  put,
  call,
  select,
  take,
} from 'redux-saga/effects';
import Actions from 'actions';
import Selectors from 'selectors';
import { listSbChannels, initSendBird } from 'utils/sendbird';

function* listChannels({ callback }) {
  try {
    const { sendbird_id: sendbirdId } = yield select(Selectors.getUser);
    const isSbConnected = yield select(Selectors.isSbConnected);

    if (!isSbConnected) {
      yield call(initSendBird);
      yield put(Actions.connectSendbird());
      yield take([
        Actions.CONNECT_SENDBIRD_SUCCESS,
        Actions.CONNECT_SENDBIRD_FAIL,
      ]);
    }

    const createdChannel = yield select(Selectors.getCreatedChannel);
    const channels = yield call(listSbChannels, sendbirdId);

    // if channel is newly created, move the created channel to the front of the channel's queue
    if (createdChannel) {
      const createdChannelIndex = channels.findIndex(
        // check unique url
        (channel) => channel.url === createdChannel.url
      );
      if (createdChannelIndex > -1) {
        createdChannel.lastMessage.createdAt = new Date();
        channels.splice(createdChannelIndex, 1);
      }
      channels.splice(0, 0, createdChannel);
    }

    if (channels) {
      yield put(Actions.getTotalUnreadCount());
      const filtered = channels.filter(
        (channel) => channel.joinedMemberCount > 1
      );
      const serialized = filtered.map((channel) => {
        const isGroup = channel.memberCount > 2;
        const filteredRecipients = channel.members.filter(
          ({ userId }) => userId !== sendbirdId
        );
        const recipient = channel.members.find(
          ({ userId }) => userId !== sendbirdId
        );
        return {
          // eslint-disable-next-line no-underscore-dangle
          id: channel._iid,
          avatar: isGroup ? null : recipient.profileUrl,
          title: isGroup ? channel.customType : recipient.nickname,
          subtitle: (channel.lastMessage && channel.lastMessage.message) || '',
          date:
            (channel.lastMessage && channel.lastMessage.createdAt) ||
            new Date(),
          unread: channel.unreadMessageCount,
          channel,
          recipients: isGroup ? filteredRecipients : recipient,
          isGroup,
          memberCount: channel.memberCount,
          inviter: channel.inviter,
          name: channel.customType,
        };
      });
      yield put(Actions.clearCreatedChannel());
      yield put(Actions.listChannelsSuccess(serialized));
    }
    if (typeof callback === 'function') {
      yield call(callback);
    }
  } catch (error) {
    yield put(Actions.listChannelsFail(error));
  }
}

function* watchListChannels() {
  yield takeLatest(Actions.LIST_CHANNELS_REQUEST, listChannels);
}

export default function* list() {
  yield all([fork(watchListChannels)]);
}

import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import Actions from 'actions';
import PropTypes from 'prop-types';
import Selectors from 'selectors';
import classnames from 'classnames';
import { intersection } from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import {
  Breadcrumbs,
  Button,
  Badge,
  Card,
  PageMeta,
  Loading,
  SocialMediaShare,
} from 'components/UI';
import { urlHandler } from 'utils/helper';
import { AuthContext } from 'utils/context';
import qs from 'query-string';
import CONFIG from 'utils/config';
import ApplySection from '../LandingPage/common/Apply';
import { BackgroundSection, labelBg } from '../LandingPage/common';
import { DetailsCard } from './Featured';
import RelatedSection from './Related';

const ArticleCard = ({ article }) => {
  const {
    image_url: imageUrl,
    article_category: articleCategory,
    title,
    content,
    member_only: memberOnly,
  } = article;
  const { authenticated } = useContext(AuthContext);
  const label = articleCategory?.name;
  const labelUrl = `/articles?${qs.stringify({ category: label })}`;
  const showLockOverlay = memberOnly && !authenticated;

  const LockOverlay = () => {
    if (showLockOverlay)
      return (
        <div
          className={classnames(
            'flex flex-col items-center justify-center text-center h-48 p-8',
            'absolute bottom-0 w-full bg-gradient-white-hide'
          )}
        >
          <h5>Become a member to view more…</h5>
          <div className="mt-6">
            <Button
              label="Apply to be a member"
              onClick={() => navigate('/signup')}
            />
          </div>
        </div>
      );
    return null;
  };
  const socialMediaUrl = `${CONFIG.WEB_URL}/articles/${article.id}`;

  return (
    <Card className="h-full relative mt-8">
      <SocialMediaShare
        title={title}
        url={socialMediaUrl}
        summary={article.summary}
        className="sm:hidden"
      />
      <BackgroundSection
        backgroundImage={`url(${urlHandler(imageUrl)})`}
        className="relative bg-no-repeat rounded-t-lg bg-cover bg-center"
        height="30rem"
      >
        <div className="relative">
          <div className="flex justify-between">
            {label && (
              <Link to={labelUrl}>
                <Badge bg={labelBg(label)} className="ml-4 mt-4">
                  {label}
                </Badge>
              </Link>
            )}
          </div>
        </div>
        <DetailsCard className="-mb-10" article={article} />
      </BackgroundSection>
      <div className="pt-10 px-2 sm:px-8">
        <SocialMediaShare
          title={title}
          url={socialMediaUrl}
          summary={article.summary}
          className="hidden sm:block"
        />
        <h1 className="text-center max-w-xl mt-10 mx-auto">{title}</h1>
        <div
          className={classnames(
            'p-2 sm:p-10 max-w-3xl mx-auto text-md leading-loose',
            {
              'max-h-screen overflow-y-hidden': showLockOverlay,
            }
          )}
        >
          {ReactHtmlParser(content, {
            transform: (node, idx) =>
              node?.data?.includes('iframe') && (
                <div className="flex justify-center items-center" key={idx}>
                  {ReactHtmlParser(node.data)}
                </div>
              ),
          })}
        </div>
      </div>
      <LockOverlay />
    </Card>
  );
};

ArticleCard.propTypes = {
  article: PropTypes.object.isRequired,
};

const View = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: articleId } = useParams();
  const { pathname: currentPath } = useLocation();
  const { authenticated, hasAccess } = useContext(AuthContext);
  const memberPage = '/members/articles';
  const articlesPath = authenticated ? memberPage : '/articles';

  const article = useSelector(Selectors.articleInfo);
  const allArticles = useSelector(Selectors.getPaginatedArticles);
  const articles = allArticles.slice(-12).filter(({ id }) => id !== articleId);
  const storedId = article?.id;
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_ARTICLE_INFO_REQUEST])
  );
  const callbacks = {
    success: () => !articles.length && dispatch(Actions.fetchArticles()),
    failure: () => navigate('/articles'),
  };

  useEffect(() => {
    if (authenticated && hasAccess && !currentPath.includes(memberPage)) {
      navigate(`${memberPage}/${articleId}`);
    }
  }, [authenticated, navigate, hasAccess, articleId, currentPath]);

  useEffect(() => {
    if (!isLoading && articleId !== storedId) {
      dispatch(Actions.fetchArticleInfo(articleId, callbacks));
    }
  }, [articleId, dispatch, storedId, isLoading, callbacks]);

  if (articleId !== storedId) return <Loading />;

  const {
    title,
    article_category: articleCategory,
    keywords: articleKeywords,
    image_url: imageUrl,
    summary,
  } = article;

  const relatedArticles = articles
    .sort(({ article_category: category }) =>
      category?.id === articleCategory?.id ? -1 : 1
    )
    .sort(({ keywords }) =>
      intersection(articleKeywords, keywords).length ? -1 : 1
    );

  return (
    <>
      <PageMeta title={title} ogImg={imageUrl} ogDesc={summary} />
      <div>
        <BackgroundSection
          backgroundSize="100% 400px"
          className={classnames({
            'pt-20 bg-gradient-ebony bg-no-repeat': !authenticated,
          })}
        >
          <div
            className={classnames({
              'container mx-auto px-2 sm:px-6 pt-4': !authenticated,
            })}
          >
            <div className={classnames({ 'pb-12': authenticated })}>
              <Breadcrumbs
                trails={[
                  {
                    label: 'Articles',
                    path: articlesPath,
                    icon: 'articles',
                  },
                  title,
                ]}
              />
            </div>
            <div
              className={classnames({
                'container mx-auto px-6 pt-4': authenticated,
              })}
            >
              <ArticleCard article={article} />
            </div>
          </div>
        </BackgroundSection>
        <RelatedSection
          articles={relatedArticles}
          articlesPath={articlesPath}
        />
        {!authenticated && <ApplySection />}
      </div>
    </>
  );
};

export default View;

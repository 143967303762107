import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchArticleInfo({ id, callbacks }) {
  try {
    const response = yield call(api.fetchArticleInfo, id);
    yield put(Actions.fetchArticleInfoSuccess(response?.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.fetchArticleInfoFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchArticlesInfo() {
  yield takeLatest(Actions.FETCH_ARTICLE_INFO_REQUEST, fetchArticleInfo);
}

export default function* info() {
  yield all([fork(watchFetchArticlesInfo)]);
}

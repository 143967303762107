import React, { useState, useEffect } from 'react';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Formik, Field } from 'formik';
import TextInput from 'components/Form/TextInput';
import classnames from 'classnames';
import { Spinner } from 'components/UI/Loading';

const CARD_ELEMENT_STYLES = {
  style: {
    base: {
      color: '#718196',
      fontWeight: 600,
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0afbf',
      },
    },
    invalid: {
      color: '#fc8181',
      iconColor: '#fc8181',
    },
  },
};

const SAMPLE_CUSTOMER_DATA = {
  id: 'cus_HAwLCi6nxPYcsl',
  object: 'customer',
  address: null,
  balance: 0,
  created: 1588007418,
  currency: 'usd',
  default_source: null,
  delinquent: false,
  description: 'My First Test Customer',
  discount: null,
  email: null,
  invoice_prefix: '2D409C0',
  invoice_settings: {
    custom_fields: null,
    default_payment_method: null,
    footer: null,
  },
  livemode: false,
  metadata: {},
  name: null,
  next_invoice_sequence: 1,
  phone: null,
  preferred_locales: [],
  shipping: null,
  sources: {
    object: 'list',
    data: [],
    has_more: false,
    url: '/v1/customers/cus_HAwLCi6nxPYcsl/sources',
  },
  subscriptions: {
    object: 'list',
    data: [],
    has_more: false,
    url: '/v1/customers/cus_HAwLCi6nxPYcsl/subscriptions',
  },
  tax_exempt: 'none',
  tax_ids: {
    object: 'list',
    data: [],
    has_more: false,
    url: '/v1/customers/cus_HAwLCi6nxPYcsl/tax_ids',
  },
};

const StripeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [customer, setCustomer] = useState({});
  const disabledSubmitState = processing || disabled || succeeded;

  useEffect(() => {
    // Create Stripe Customer as soon as the page loads to get customer data
    // Only for first-time user/setup
    dispatch(Actions.getStripeCustomer((data) => setCustomer(data.customer)));
    setCustomer(SAMPLE_CUSTOMER_DATA);
  }, [dispatch]);

  const handleChange = (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : ''); // TODO: refer to stripe change handler
  };

  const handleOnSubmit = async ({ priceId }) => {
    setProcessing(true);
    if (!stripe || !elements) return;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error('[createPaymentMethod error]', error);
      setError(error.message);
      setProcessing(false);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      const { id: paymentMethodId } = paymentMethod;
      const { id: customerId } = customer;
      dispatch(
        Actions.createSubscription(
          {
            customerId,
            paymentMethodId,
            priceId,
          },
          {
            success: (response) => {
              console.log('[createSubscription success]', response);
              setSucceeded(true);
            },
            failure: (error) =>
              console.error('[createSubscription error]', error),
          }
        )
      );
      setProcessing(false);
    }
  };

  return (
    <div className="m-auto w-2/3 shadow-card">
      <Formik
        initialValues={{ priceId: '' }}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <form>
            <Field
              name="priceId"
              component={TextInput}
              placeholder="Subscription Price Id"
            />
            <CardElement
              options={CARD_ELEMENT_STYLES}
              onChange={handleChange}
              className="px-4"
            />
            <button
              disabled={disabledSubmitState}
              type="button"
              onClick={() => handleSubmit()}
              className={classnames(
                'bg-violet text-white text-center w-full mt-4 py-2 rounded-b h-10 hover:opacity-75',
                {
                  'bg-boulder opacity-100': disabledSubmitState,
                }
              )}
            >
              <span className="flex justify-center">
                {processing ? <Spinner color="#fff" height={20} /> : 'Pay'}
              </span>
            </button>
            {succeeded && (
              <b
                className="card-error text-green-300 text-center block"
                role="alert"
              >
                Success
              </b>
            )}
            {error && (
              <div className="card-error text-red-400" role="alert">
                {error}
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default StripeForm;

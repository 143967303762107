export const NAME = 'SENDBIRD/CONNECT_SENDBIRD';

export const CONNECT_SENDBIRD_REQUEST = `${NAME}_REQUEST`;
export const CONNECT_SENDBIRD_SUCCESS = `${NAME}_SUCCESS`;
export const CONNECT_SENDBIRD_FAIL = `${NAME}_FAIL`;

export const connectSendbird = () => ({
  type: CONNECT_SENDBIRD_REQUEST,
});

export const connectSendbirdSuccess = () => ({
  type: CONNECT_SENDBIRD_SUCCESS,
});

export const connectSendbirdFail = (error) => ({
  type: CONNECT_SENDBIRD_FAIL,
  error,
});

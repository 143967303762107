import { combineReducers } from 'redux';
import list from './list';
import info from './info';
import redeemed from './redeemed';
import created from './created';

export default combineReducers({
  list,
  info,
  redeemed,
  created,
});

const NAME = 'CONSULTATION_SERVICE/APPROVE_BOOKING_';

export const APPROVE_BOOKING_REQUEST = `${NAME}REQUEST`;
export const APPROVE_BOOKING_SUCCESS = `${NAME}SUCCESS`;
export const APPROVE_BOOKING_FAIL = `${NAME}FAIL`;

export const approveBooking = (id, callbacks) => ({
  type: APPROVE_BOOKING_REQUEST,
  id,
  callbacks,
});

export const approveBookingSuccess = (data) => ({
  type: APPROVE_BOOKING_SUCCESS,
  data,
});

export const approveBookingFail = () => ({
  type: APPROVE_BOOKING_FAIL,
});

const NAME = 'ARTICLES';

export const FAVOURITE_ARTICLE_INFO_REQUEST = `${NAME}/FAVOURITE_ARTICLE_INFO_REQUEST`;
export const FAVOURITE_ARTICLE_INFO_SUCCESS = `${NAME}/FAVOURITE_ARTICLE_INFO_SUCCESS`;
export const FAVOURITE_ARTICLE_INFO_FAIL = `${NAME}/FAVOURITE_ARTICLE_INFO_FAIL`;

export const favouriteArticleInfo = (id, callback) => ({
  type: FAVOURITE_ARTICLE_INFO_REQUEST,
  id,
  callback,
});

export const favouriteArticleInfoSuccess = () => ({
  type: FAVOURITE_ARTICLE_INFO_SUCCESS,
});

export const favouriteArticleInfoFail = (error) => ({
  type: FAVOURITE_ARTICLE_INFO_FAIL,
  error,
});

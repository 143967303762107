const NAME = 'EVENTS/FETCH_CREATED_EVENT_';

export const FETCH_CREATED_EVENT_REQUEST = `${NAME}REQUEST`;
export const FETCH_CREATED_EVENT_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_CREATED_EVENT_FAIL = `${NAME}FAIL`;
export const CLEAR_FETCH_CREATED = `EVENTS/CLEAR_FETCH_CREATED`;

export const fetchCreatedEvent = (id, callbacks) => ({
  type: FETCH_CREATED_EVENT_REQUEST,
  id,
  callbacks,
});

export const fetchCreatedEventSuccess = (data) => ({
  type: FETCH_CREATED_EVENT_SUCCESS,
  data,
});

export const fetchCreatedEventFail = (error) => ({
  type: FETCH_CREATED_EVENT_FAIL,
  error,
});

export const clearFetchCreated = () => ({
  type: CLEAR_FETCH_CREATED,
});

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Footer } from 'components/UI';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const FormFooter = ({
  hideForm,
  submitEvent,
  submitDraft,
  isUpdate,
  isSubmitting,
}) => {
  return (
    <Footer>
      <div className="flex items-center w-full text-xs sm:text-base sm:px-12 justify-around">
        <button
          type="button"
          className="text-dusty hover:text-easter flex focus:outline-none sm:mr-auto"
          onClick={hideForm}
        >
          CANCEL
        </button>
        <button
          type="button"
          className="text-dusty hover:text-easter flex focus:outline-none"
          onClick={submitDraft}
        >
          SAVE AS DRAFT
        </button>
        <Button
          disabled={isSubmitting}
          type="button"
          isPill
          className="px-2 sm:px-8 sm:ml-10"
          onClick={submitEvent}
          label={
            <div className="flex justify-center items-center">
              <p>{`${isUpdate ? 'UPDATE' : 'CREATE'} EVENT`}</p>
              <DoubleArrowIcon fontSize="small" className="ml-1 sm:ml-3" />
            </div>
          }
        />
      </div>
    </Footer>
  );
};

FormFooter.propTypes = {
  hideForm: PropTypes.func.isRequired,
  submitEvent: PropTypes.func.isRequired,
  submitDraft: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormFooter;

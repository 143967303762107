const NAME = 'USER/FETCH_USERS';

export const FETCH_USERS_REQUEST = `${NAME}_REQUEST`;
export const FETCH_USERS_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_USERS_FAIL = `${NAME}_FAIL`;

export const fetchUsers = (params, callbacks) => ({
  type: FETCH_USERS_REQUEST,
  params,
  callbacks,
});

export const fetchUsersSuccess = (data, page) => ({
  type: FETCH_USERS_SUCCESS,
  data,
  page,
});

export const fetchUsersFail = (error) => ({
  type: FETCH_USERS_FAIL,
  error,
});

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* signIn({ credentials, callbacks }) {
  try {
    const response = yield call(api.signIn, credentials);
    yield put(Actions.signInSuccess(response.data));
    if (typeof callbacks.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    const errorResponse = error?.response || error;
    yield put(Actions.signInFail(error));
    if (typeof callbacks.failure === 'function') {
      yield call(callbacks.failure, errorResponse);
    }
  }
}

function* watchSignIn() {
  yield takeLatest(Actions.SIGN_IN_REQUEST, signIn);
}

export default function* auth() {
  yield all([fork(watchSignIn)]);
}

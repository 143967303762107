import React, { useState } from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

const Player = ({ id }) => {
  const [isPlaying, setPlaying] = useState(false);

  return (
    <div className="pt-12 w-full sm:h-17/20 relative">
      <div className="absolute right-0 left-0 pt-12 sm:p-10 cursor-not-allowed z-50 bg-transparent" />
      {!isPlaying && (
        <div className="absolute bottom-0 left-0 pt-16 px-24 cursor-not-allowed z-50 bg-transparent" />
      )}
      <YouTube
        containerClassName="w-full h-full"
        videoId={id}
        className="mx-auto w-full h-full sm:mx-0"
        opts={{ playerVars: { modestbranding: 1 } }}
        onPlay={() => setPlaying(true)}
      />
    </div>
  );
};

Player.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Player;

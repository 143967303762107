import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getIn } from 'formik';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import styled from '@emotion/styled';

const Container = styled.div`
  box-shadow: 0 5px 15px 0 rgba(14, 92, 164, 0.3);
  background-color: white;
`;

const TimeRangeInput = (props) => {
  const {
    form: { touched, errors, setFieldTouched },
    field: { name },
    onChange,
    className,
    initialValues,
  } = props;
  const error = getIn(touched, name) && getIn(errors, name);
  const [times, setTimes] = useState(initialValues);
  const hasInitialValues = initialValues[0] && initialValues[1];
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (times) => {
    setTimes(times);

    if (onChange) {
      onChange(times);
    }
  };

  useEffect(() => setTimes(initialValues), [initialValues]);

  return (
    <div className="flex flex-col">
      <Container
        className={classnames(
          'px-4 py-3 rounded-md items-center inline-flex text-boulder',
          className
        )}
      >
        <ScheduleIcon className="text-easter mr-3" />
        {hasInitialValues || isOpen ? (
          <TimeRangePicker
            value={times}
            onChange={handleChange}
            clearIcon={null}
            clockIcon={null}
            disableClock
          />
        ) : (
          <div
            className="cursor-pointer"
            role="presentation"
            onClick={() => {
              setIsOpen(true);
              setFieldTouched(name);
            }}
          >
            Start Time - End Time
          </div>
        )}
      </Container>
      <div className="text-valencia h-4 mt-2 text-xs font-light">{error}</div>
    </div>
  );
};

TimeRangeInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  initialValues: PropTypes.array.isRequired,
};

TimeRangeInput.defaultProps = {
  onChange: null,
  className: null,
};

export default TimeRangeInput;

import React from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'actions';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Badge, Card, Picture, Options } from 'components/UI';
import { displayTimeFormat, displayDayDateFormat } from 'utils/datetime';
import { iconClockTeal } from 'assets';
import { Link } from 'react-router-dom';
import { urlHandler } from 'utils/helper';
import notify from 'utils/notification';
import { BackgroundSection, labelBg } from '../../LandingPage/common';

const STATUS_MAP = Object.freeze({
  pending: 'PENDING APPROVAL',
  approved: 'PUBLISHED',
  declined: 'DECLINED',
});

const CreatedEventCard = ({ eventInfo, eventsPath, showForm }) => {
  const dispatch = useDispatch();
  const {
    id,
    city,
    name,
    image_url: imageUrl,
    start_at: startAt,
    end_at: endAt,
    event_category: { name: category },
    time_zone: timeZone,
    status,
    draft,
  } = eventInfo;
  const updateEventDraft = () => {
    const callbacks = {
      success: () =>
        notify(`Event ${draft ? 'is published' : 'saved as draft'}`),
      failure: () =>
        notify('Failed to update event, try again later', null, 'danger'),
    };

    dispatch(Actions.updateEvent(id, { draft: !draft }, callbacks));
  };

  const deleteDialog = () => {
    const callbacks = {
      success: () => {
        dispatch(Actions.hideDialog());
        notify('Event is deleted');
      },
      failure: () => {
        dispatch(Actions.hideDialog());
        notify('Failed to delete event, try again later', null, 'danger');
      },
    };
    dispatch(
      Actions.showDialog({
        title: 'DELETE EVENT',
        content: 'Are you sure you want to DELETE your created event?',
        subContent: 'This will remove all information regarding this event',
        acceptLabel: 'YES, DELETE EVENT',
        dismissLabel: "NO, DON'T DELETE",
        onAccept: () => dispatch(Actions.deleteEvent(id, callbacks)),
      })
    );
  };
  const optionList = [
    { label: 'Edit Event', onClick: () => showForm(id) },
    {
      label: `${draft ? 'Publish' : 'Unpublish'} Event`,
      onClick: updateEventDraft,
    },
    {
      label: 'Delete Event',
      onClick: deleteDialog,
      className: 'text-valencia',
    },
  ];

  return (
    <div className="w-full min-w-80 mr-4 mb-10 sm:mr-8">
      <Card className="w-full sm:flex">
        <Link className="w-full sm:w-1/2 lg:w-1/3" to={`${eventsPath}/${id}`}>
          <BackgroundSection
            backgroundImage={`url(${urlHandler(imageUrl)})`}
            className="bg-no-repeat bg-cover bg-center h-full rounded-l-lg"
          />
        </Link>
        <div className="w-full px-6 pb-6 sm:grid lg:flex">
          <Link to={`${eventsPath}/${id}`} className="w-full lg:w-2/3">
            <Badge bg={labelBg(category)} className="pb-2 pt-6">
              {category}
            </Badge>
            <div className="mt-2 text-2lg font-bold truncate max-w-lg">
              {name}
            </div>
            <p className="text-boulder font-semibold">
              {city?.name || 'Global'}
            </p>
            <div className="mt-5 flex text-boulder text-sm">
              <span className="border-r border-easter pr-4 text-easter w-1/2 lg:w-auto">
                {displayDayDateFormat(startAt, timeZone)}
              </span>
              <span className="flex items-center flex-1 pl-4 text-easter">
                <Picture src={iconClockTeal} className="w-4 mr-2 text-easter" />
                {`${displayTimeFormat(startAt)} - ${displayTimeFormat(endAt)}`}
              </span>
            </div>
          </Link>
          <div className="grid w-full lg:w-1/3">
            <div className="flex items-center justify-end h-10 sm:h-12 pt-6">
              <div
                className={classnames('ml-3 text-sm sm:text-base', {
                  'text-green-500': status === 'approved',
                  'text-yellow-500': !draft && status === 'pending',
                  'text-red-600': status === 'declined',
                  'text-dusty': draft,
                })}
              >
                {draft ? 'DRAFT' : STATUS_MAP[status]}
              </div>
              <Options list={optionList} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

CreatedEventCard.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  showForm: PropTypes.func.isRequired,
  eventsPath: PropTypes.string.isRequired,
};

export default CreatedEventCard;

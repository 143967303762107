import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { Breadcrumbs, PageMeta, NavigationTab, Loading } from 'components/UI';
import { AuthContext } from 'utils/context';
import Form from './Form';

const EXPLORE_PATH = '/members/perks/explore';
const TABS = Object.freeze([
  { name: 'Explore Perks', path: EXPLORE_PATH },
  { name: 'My Perks', path: '/members/perks/my-perks' },
]);

const PerkForm = () => {
  const navigate = useNavigate();
  const { isFreeTier } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const perk = useSelector(Selectors.getPerk);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_PERK_REQUEST])
  );

  useEffect(() => {
    if (id) dispatch(Actions.fetchPerk(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isFreeTier) navigate('/members/perks/explore', { replace: true });
  }, [isFreeTier, navigate]);

  return (
    <>
      <PageMeta title={id ? 'Update Perk' : 'Create New Perk'} />
      <Breadcrumbs
        trails={[
          { label: 'Perks', path: EXPLORE_PATH, icon: 'perks' },
          'My Perks',
        ]}
      />
      <NavigationTab tabs={TABS} />
      {isLoading ? (
        <Loading
          className="bg-white h-screen flex justify-center items-center"
          opaque={false}
        />
      ) : (
        <Form perk={id && perk} />
      )}
    </>
  );
};

export default PerkForm;

import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, Picture } from 'components/UI';
import { displayTimeFormat, displayDayDateFormat } from 'utils/datetime';
import { iconClockTeal } from 'assets';
import { Link } from 'react-router-dom';
import { urlHandler } from 'utils/helper';
import { BackgroundSection, labelBg } from '../../LandingPage/common';
import EventModal from '../EventModal';

const EventAttendedCard = ({ eventInfo, eventsPath, handleCancelBooking }) => {
  const {
    id,
    city,
    name,
    image_url: imageUrl,
    start_at: startAt,
    end_at: endAt,
    event_category: eventCategory,
    time_zone: timeZone,
    attendances_count: attendancesCount,
    attendees_preview: attendees,
    webinar_link: webinarLink,
  } = eventInfo;
  const eventType = eventCategory?.name || 'Festival';
  const attendeesPreview = attendees?.slice(0, 3);
  const remainingAttendanceSize = attendancesCount - attendeesPreview?.length;

  return (
    <div className="w-full min-w-80 mr-4 mb-10 sm:mr-8">
      <Card className="w-full sm:flex">
        <Link className="w-full sm:w-1/2 lg:w-1/3" to={`${eventsPath}/${id}`}>
          <BackgroundSection
            backgroundImage={`url(${urlHandler(imageUrl)})`}
            className="bg-no-repeat bg-cover bg-center h-full rounded-l-lg"
          />
        </Link>
        <div className="w-full px-6 pb-6 sm:grid lg:flex">
          <div className="w-full lg:w-2/3">
            <Link to={`${eventsPath}/${id}`}>
              <Badge bg={labelBg(eventType)} className="pb-2 pt-6">
                {eventType}
              </Badge>
              <div className="mt-2 text-2lg font-bold truncate max-w-lg">
                {name}
              </div>
            </Link>
            {webinarLink ? (
              <p className="text-boulder font-semibold truncate max-w-md">
                {`Webinar `}
                <span>
                  <a
                    href={webinarLink}
                    rel="noreferrer"
                    target="_blank"
                    className="text-easter"
                  >
                    {webinarLink}
                  </a>
                </span>
              </p>
            ) : (
              <p className="text-boulder font-semibold">
                {city?.name || 'Global'}
              </p>
            )}
            <Link
              to={`${eventsPath}/${id}`}
              className="mt-5 flex text-boulder text-sm"
            >
              <span className="border-r border-easter pr-4 text-easter w-1/2 lg:w-auto">
                {displayDayDateFormat(startAt, timeZone)}
              </span>
              <span className="flex items-center flex-1 pl-4 text-easter">
                <Picture src={iconClockTeal} className="w-4 mr-2 text-easter" />
                {`${displayTimeFormat(startAt)} - ${displayTimeFormat(endAt)}`}
              </span>
            </Link>
          </div>
          <div className="grid w-full lg:w-1/3">
            {eventInfo && (
              <EventModal
                eventInfo={eventInfo}
                updateAttending={handleCancelBooking}
                attending
                cancelOnly
              />
            )}
            <div className="flex flex-wrap ml-auto mt-auto justify-center">
              {attendeesPreview?.map(({ id, image_url: img }) => (
                <Link
                  key={id}
                  className="rounded-full h-10 w-10 mx-1 shadow-lg overflow-hidden cursor-pointer"
                  to={`/network/${id}`}
                  role="presentation"
                >
                  <Picture src={img} className="object-cover w-full h-full" />
                </Link>
              ))}
              {remainingAttendanceSize > 0 && (
                <div className="w-12 h-10 px-1">
                  <div className="flex shadow rounded-full object-cover max-w-full h-full align-middle border-none bg-sigmaBlue-dark">
                    <div className="m-auto text-white text-sm">
                      {attendancesCount > 999
                        ? '>999'
                        : `+${remainingAttendanceSize}`}
                      &nbsp;
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

EventAttendedCard.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  eventsPath: PropTypes.string,
  handleCancelBooking: PropTypes.func,
};

EventAttendedCard.defaultProps = {
  eventsPath: '/events',
  handleCancelBooking: null,
};

export default EventAttendedCard;

import React, { useEffect } from 'react';
import Actions from 'actions';
import Selectors from 'selectors';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Picture } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import { bgHero, glpBlue } from 'assets';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { BackgroundSection } from '../common';

const StatsCard = () => {
  const dispatch = useDispatch();
  const contentCount = useSelector(Selectors.getContentCount);
  const isCountCacheExpired = useSelector(Selectors.isCountCacheExpired);
  const {
    articles_count: articlesCount,
    events_count: eventsCount,
    users_count: usersCount,
  } = contentCount;

  useEffect(() => {
    if (isCountCacheExpired) dispatch(Actions.fetchCount());
  }, [dispatch, isCountCacheExpired]);

  const layout = (title, count, path) => (
    <Link
      to={path}
      className="flex flex-col items-center m-5 leading-none pb-4 md:pb-0 flex-1"
    >
      <span className="text-dusty font-semibold">{title}</span>
      <span className="text-easter text-xl sm:text-2xl font-semibold mt-3">
        {Intl.NumberFormat('en-US').format(count)}
      </span>
    </Link>
  );

  return (
    <div className="flex justify-center -mt-12 md:-mt-32 mx-2 sm:mx-4">
      <Card className="flex max-w-lg w-full">
        {isEmpty(contentCount) ? (
          <Spinner className="my-5 mx-auto" />
        ) : (
          <div className="flex-1 flex justify-around items-center">
            {layout('Members', usersCount, '/membership')}
            <div className="border-r border-alto h-17/20" />
            {layout('Events', eventsCount, '/events')}
            <div className="border-r border-alto h-17/20" />
            {layout('Articles', articlesCount, '/articles')}
          </div>
        )}
      </Card>
    </div>
  );
};

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <BackgroundSection
        backgroundImage={`url(${bgHero.default})`}
        maxHeight="640px"
        className="h-screen bg-no-repeat bg-cover bg-bottom pt-20 px-4"
      >
        <Picture src={glpBlue} className="mt-4 mx-auto w-32 sm:w-40" />
        <div className="text-center text-white text-shadow leading-snug text-xl sm:text:2xl font-bold font-chap mt-4">
          <div>Explore. Engage. Empower.</div>
        </div>
        <p className="mt-4 text-white text-shadow text-center mx-auto max-w-xl">
          Connect and collaborate with the best music industry professionals and
          grow your business globally.
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center mt-8">
          <div>
            <Button
              outline
              transparent
              label="Login"
              onClick={() => navigate('/login')}
              className="sm:mr-4"
            />
          </div>
          <div className="mt-4 md:mt-0">
            <Button
              label="Apply to be a member"
              onClick={() => navigate('/signup')}
            />
          </div>
        </div>
      </BackgroundSection>
      <StatsCard />
    </>
  );
};

export default HeroSection;

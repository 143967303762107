import React from 'react';
import PropTypes from 'prop-types';
import { google, outlook, ics } from 'calendar-link';
import {
  iconAppleCalendar,
  iconGoogleCalendar,
  iconOutlookCalendar,
  iconDownload,
} from 'assets';
import { Button } from 'components/UI';

const AddToCalendar = ({
  start,
  end,
  link,
  title,
  showDescription,
  guestEmail,
}) => {
  const description = `For more details, go here: ${window.location.href}`;
  const calendarDescription = () => {
    if (showDescription) {
      return link ? `Link to event: ${link}\n\n${description}` : description;
    }
    return null;
  };

  const calendarEvent = {
    title,
    start,
    end,
    description: calendarDescription(),
    guests: [guestEmail],
  };
  const links = [
    {
      name: 'Google Calendar',
      link: google(calendarEvent),
      logo: iconGoogleCalendar,
    },
    {
      name: 'Apple Calendar',
      link: ics(calendarEvent),
      logo: iconAppleCalendar,
    },
    {
      name: 'Outlook Calendar',
      link: outlook(calendarEvent),
      logo: iconOutlookCalendar,
    },
    { name: 'Download File', link: ics(calendarEvent), logo: iconDownload },
  ];

  return (
    <div className="flex justify-center items-center flex-col">
      <div className="my-3">Add to calendar</div>
      <div className="w-full flex flex-col items-center justify-around">
        {links.map(({ name, link, logo }) => (
          <a href={link} target="_blank" rel="noreferrer" key={name}>
            <Button
              label={
                <div className="flex text-ebony capitalize font-normal items-center">
                  <img
                    alt="check"
                    src={logo.default}
                    className="h-6 mr-4 ml-6"
                  />
                  {name}
                </div>
              }
              className="w-64 h-12 px-4 sm:px-6 shadow-lg border border-gray-100 mb-4"
              theme="plain"
              outline
              isPill
            />
          </a>
        ))}
      </div>
    </div>
  );
};

AddToCalendar.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  showDescription: PropTypes.bool,
  guestEmail: PropTypes.string,
};

AddToCalendar.defaultProps = {
  link: null,
  showDescription: false,
  guestEmail: null,
};

export default AddToCalendar;

const NAME = 'UI';

export const SHOW_DIALOG = `${NAME}/SHOW_DIALOG`;
export const HIDE_DIALOG = `${NAME}/HIDE_DIALOG`;

export const showDialog = (dialog) => ({
  type: SHOW_DIALOG,
  dialog,
});

export const hideDialog = () => ({
  type: HIDE_DIALOG,
});

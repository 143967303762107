import { all, fork } from 'redux-saga/effects';
import create from './create';
import update from './update';
import show from './show';
import uploadUserImage from './uploadUserImage';
import deleteUserImage from './deleteUserImage';
import list from './list';
import profile from './profile';

export default function* user() {
  yield all([
    fork(create),
    fork(update),
    fork(show),
    fork(uploadUserImage),
    fork(deleteUserImage),
    fork(list),
    fork(profile),
  ]);
}

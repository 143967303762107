import React from 'react';
import PropTypes from 'prop-types';
import { Card, Picture, Button } from 'components/UI';
import styled from '@emotion/styled';
import { gfxAvatar, iconLinkedInActive } from 'assets';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';

const StyledPicture = styled(Picture)`
  max-height: 500px;
  @media (max-width: 768px) {
    max-height: 250px;
  }
`;

const ConsultantCard = ({
  consultant: {
    id,
    biography,
    country,
    currency,
    rate,
    name,
    image_url: img,
    discounted_rate: discountedRate,
    linked_in: linkedIn,
  },
  scroll,
}) => (
  <div className="flex flex-col items-center md:flex-row justify-center py-4 md:py-8 px-2 md:px-0 mx-2">
    <StyledPicture
      src={img || gfxAvatar.default}
      fallbackImage={gfxAvatar.default}
      className="max-w-xs z-10 rounded md:-mr-12"
    />
    <Card className="border-lily py-10 px-4 md:px-8 -mt-8 md:mt-0 border-r-6 md:pl-32 h-full w-full flex flex-col max-w-200">
      <div className="flex flex-col md:flex-row md:justify-between items-start">
        <div className="text-center md:text-left m-auto md:m-0">
          <p className="text-easter font-semibold font-chap text-2lg">{name}</p>
          <p className="mb-2 text-boulder text-sm md:mb-3">{country}</p>
          {linkedIn && (
            <a href={linkedIn} target="_blank" rel="noreferrer">
              <Picture
                src={iconLinkedInActive}
                className="h-8 m-auto mb-2 md:m-0"
              />
            </a>
          )}
        </div>
        <div className="text-center md:text-right my-2 mx-auto md:m-0">
          <p className="text-sm text-mulled">CONSULTATION RATE</p>
          <p className="text-2lg md:text-xl text-easter">
            {`${currency} ${Number(discountedRate || rate)}/hour`}
          </p>
          {discountedRate ? (
            <p className="text-dusty line-through text-sm">
              {`${currency} ${Number(rate)}/hour`}
            </p>
          ) : (
            <p className="text-sm text-dusty">{`*${ALL_ACCESS} & ${PREMIUM} members get 50% discount!`}</p>
          )}
        </div>
      </div>
      <p className="mt-5 text-mulled leading-relaxed whitespace-pre-line">
        {biography}
      </p>
      <div className="w-48 md:w-64 mx-auto md:ml-auto md:mx-0 mt-6">
        <Button
          label="BOOK A SLOT"
          className="w-full h-12 px-4 sm:px-6"
          isPill
          onClick={() => scroll(id)}
          theme="violet-cyan"
        />
      </div>
    </Card>
  </div>
);

ConsultantCard.propTypes = {
  consultant: PropTypes.object.isRequired,
  scroll: PropTypes.func.isRequired,
};

export default ConsultantCard;

import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Selectors from 'selectors';
import Actions from 'actions';
import classnames from 'classnames';
import { Breadcrumbs, Loading } from 'components/UI';
import { AuthContext } from 'utils/context';
import ApplySection from '../LandingPage/common/Apply';
import { BackgroundSection } from '../LandingPage/common';
import EventTab from './EventTab';
import RelatedEvents from './Related';
import EventViewCard from './EventViewCard';

const View = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const { pathname: currentPath } = useLocation();
  const { authenticated, hasAccess, isSubscribed } = useContext(AuthContext);
  const memberPage = '/members/events';
  const isExplorePage = currentPath.includes(`${memberPage}/explore`);
  const activePage = isExplorePage
    ? `${memberPage}/explore`
    : `${memberPage}/my-events`;
  const eventsPath = authenticated ? activePage : '/events';
  const event = useSelector(Selectors.eventInfo);
  const allEvents = useSelector(Selectors.getPaginatedEvents);
  const events = allEvents.slice(-12).filter(({ id }) => id !== eventId);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_EVENT_INFO_REQUEST])
  );
  const { pro_eligible: isUserProEligible } = useSelector(
    Selectors.getSubscription
  );

  const storedId = event?.id;
  const callbacks = {
    success: () => !events.length && dispatch(Actions.fetchEvents()),
    failure: () => navigate('/events'),
  };

  useEffect(() => {
    if (authenticated && hasAccess && !currentPath.includes(memberPage)) {
      navigate(`${activePage}/${eventId}`);
    }
  }, [authenticated, navigate, hasAccess, eventId]);

  useEffect(() => {
    if (!isLoading && eventId !== storedId) {
      dispatch(Actions.fetchEventInfo(eventId, callbacks));
    }
  }, [dispatch, eventId, callbacks, storedId, isLoading]);

  useEffect(() => {
    if (authenticated) dispatch(Actions.fetchSubscription());
  }, [dispatch]);

  if (eventId !== storedId) return <Loading />;

  const { name, event_category: eventCategory, city: eventCity } = event;

  const relatedEvents = events
    .sort(({ event_category: category }) =>
      category?.id === eventCategory?.id ? -1 : 1
    )
    .sort(({ city }) => (city?.id === eventCity?.id ? -1 : 1));

  const trailsLabel = isExplorePage ? 'Explore Events' : 'My Events';
  const breadcrumbTrail = isSubscribed
    ? [
        { label: 'Events', path: eventsPath, icon: 'events' },
        { label: trailsLabel, path: activePage },
        name,
      ]
    : [{ label: 'Events', path: eventsPath, icon: 'events' }, name];

  return (
    <>
      <BackgroundSection
        backgroundSize="100% 400px"
        className={classnames({
          'pt-20 bg-gradient-ebony bg-no-repeat': !isSubscribed,
        })}
      >
        <div
          className={classnames({
            'container mx-auto px-6 pt-4': !isSubscribed,
          })}
        >
          <div className={classnames({ 'pb-12': isSubscribed })}>
            <Breadcrumbs trails={breadcrumbTrail} />
            {isSubscribed && (
              <EventTab
                currentActive={activePage}
                handleTab={(path) => navigate(path)}
              />
            )}
          </div>
          <div
            className={classnames({
              'container mx-auto px-6 pt-8': isSubscribed,
            })}
          >
            <EventViewCard
              eventInfo={event}
              isLoading={isLoading}
              isUserProEligible={isUserProEligible}
            />
          </div>
        </div>
      </BackgroundSection>
      <RelatedEvents events={relatedEvents} eventsPath={eventsPath} />
      {!isSubscribed && <ApplySection />}
    </>
  );
};

export default View;

import Actions from 'actions';
import { setCacheExp } from 'utils/dataCache';

export const initialState = Object.freeze({ data: [], exp: null });

function categories(state = initialState, { type, data }) {
  switch (type) {
    case Actions.FETCH_VIDEO_CATEGORIES_SUCCESS:
      return { data, exp: setCacheExp(30) };
    case Actions.FETCH_VIDEO_CATEGORIES_FAIL:
      return { ...state, exp: null };
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default categories;

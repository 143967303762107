import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import useQuery from 'hooks/useQuery';
import { Breadcrumbs } from 'components/UI';
import { AuthContext } from 'utils/context';
import { isEmpty } from 'lodash';
import qs from 'query-string';
import EventTab from './EventTab';
import ExploreEvents from './Explore';
import MyEvents from './MyEvents';

const Events = () => {
  const [, setSearchParams] = useSearchParams();
  const params = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();
  const { authenticated, hasAccess } = useContext(AuthContext);
  const memberPage = '/members/events';
  const eventsPath = authenticated ? `${memberPage}/explore` : '/events';
  const [activePage, setActivePage] = useState(currentPath);
  const events = useSelector(Selectors.getPaginatedEvents);
  const featured = useSelector(Selectors.featuredEvent);

  useEffect(() => {
    if (authenticated && hasAccess && !currentPath.includes(memberPage)) {
      const query = isEmpty(params) ? '' : `?${qs.stringify(params)}`;
      navigate(`${memberPage}/explore${query}`);
    }
  }, [authenticated, navigate, hasAccess, currentPath, params]);

  useEffect(() => {
    if (!featured && !isEmpty(params)) setSearchParams(params);
  }, [featured, params, setSearchParams]);

  useEffect(() => {
    dispatch(Actions.fetchEvents(params));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(Actions.fetchCities({ has_events: true, simple: true }));
    dispatch(Actions.fetchCountries());
    dispatch(Actions.fetchCategories({ has_events: true }));
  }, [dispatch]);

  const handleTab = (tab) => {
    navigate(tab, { replace: true });
    setActivePage(tab);
  };

  useEffect(() => {
    if (activePage !== currentPath) {
      setActivePage(currentPath);
    }
  }, [currentPath, activePage]);

  return (
    <>
      {hasAccess && (
        <>
          <Breadcrumbs
            trails={[
              { label: 'Events', path: currentPath, icon: 'events' },
              activePage === `${memberPage}/explore`
                ? 'Explore Events'
                : 'My Events',
            ]}
          />
          <EventTab currentActive={activePage} handleTab={handleTab} />
        </>
      )}
      {authenticated && activePage === `${memberPage}/my-events` ? (
        <MyEvents eventsPath={eventsPath} handleTab={handleTab} />
      ) : (
        <ExploreEvents
          events={events}
          eventsPath={eventsPath}
          featured={featured}
        />
      )}
    </>
  );
};

export default Events;

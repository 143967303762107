const NAME = 'EVENTS/CREATE_EVENT_';

export const CREATE_EVENT_REQUEST = `${NAME}REQUEST`;
export const CREATE_EVENT_SUCCESS = `${NAME}SUCCESS`;
export const CREATE_EVENT_FAIL = `${NAME}FAIL`;

export const createEvent = (data, callbacks) => ({
  type: CREATE_EVENT_REQUEST,
  data,
  callbacks,
});

export const createEventSuccess = (data) => ({
  type: CREATE_EVENT_SUCCESS,
  data,
});

export const createEventFail = (error) => ({
  type: CREATE_EVENT_FAIL,
  error,
});

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* validateResetPasswordCode({ data, callbacks }) {
  try {
    const response = yield call(api.validateResetPasswordCode, data);
    yield put(Actions.validateResetPasswordCodeSuccess(response.data));
    if (typeof callbacks.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.validateResetPasswordCodeFail(error));
    if (typeof callbacks.failure === 'function') {
      yield call(callbacks.failure, error?.response);
    }
  }
}

function* watchvalidateResetPasswordCode() {
  yield takeLatest(
    Actions.VALIDATE_RESET_PASSWORD_CODE_REQUEST,
    validateResetPasswordCode
  );
}

export default function* validateCode() {
  yield all([fork(watchvalidateResetPasswordCode)]);
}

import React, { useContext, useState } from 'react';
import { ProfileContext } from 'utils/context';

import PersonalInfo from './Info';
import PersonalForm from './Form';

const Personal = () => {
  const { isView, readOnly } = useContext(ProfileContext);
  const [viewProfile, toggleForm] = useState(isView);

  if (viewProfile || readOnly) return <PersonalInfo toggleForm={toggleForm} />;
  return <PersonalForm isView={isView} toggleForm={toggleForm} />;
};

export default Personal;

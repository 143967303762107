const NAME = 'EVENTS/FETCH_CATEGORIES';

export const FETCH_CATEGORIES_REQUEST = `${NAME}_REQUEST`;
export const FETCH_CATEGORIES_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_CATEGORIES_FAIL = `${NAME}_FAIL`;

export const fetchCategories = (params) => ({
  type: FETCH_CATEGORIES_REQUEST,
  params,
});

export const fetchCategoriesSuccess = (data) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  data,
});

export const fetchCategoriesFail = (error) => ({
  type: FETCH_CATEGORIES_FAIL,
  error,
});

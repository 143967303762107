import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';
import { paginationHelper } from 'utils/helper';

function* fetchUsers({ params, callbacks }) {
  try {
    const response = yield call(api.fetchUsers, params);
    const pagination = paginationHelper(response?.headers);

    yield put(Actions.fetchUsersSuccess(response?.data, pagination));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks?.success, response?.data);
    }
  } catch (error) {
    yield put(Actions.fetchUsersFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks?.failure, error);
    }
  }
}

function* watchfetchUsers() {
  yield takeLatest(Actions.FETCH_USERS_REQUEST, fetchUsers);
}

export default function* list() {
  yield all([fork(watchfetchUsers)]);
}

/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'actions';
import Selectors from 'selectors';
import { Formik, Form, Field } from 'formik';
import {
  TextInput,
  SelectInput,
  ImageInput,
  RadioButtonInput,
  DateRangeInput,
  TimeRangeInput,
  SocialMediaLinkInput,
} from 'components/Form';
import { Card, ErrorContainer } from 'components/UI';
import * as Yup from 'yup';
import { isEmpty, pickBy } from 'lodash';
import startCase from 'lodash/startCase';
import notify from 'utils/notification';
import { Spinner } from 'components/UI/Loading';
import FormFooter from './FormFooter';

const RADIO_OPTIONS = [
  { label: 'Online', value: true },
  { label: 'Offline', value: false },
];

const defaultValues = {
  name: '',
  description: '',
  online: false,
  country: '',
  city: '',
  link: '',
  start_date: null,
  end_date: null,
  start_at: null,
  end_at: null,
  time_zone: '',
  event_category_id: null,
  image: null,
};

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  country: Yup.string()
    .nullable()
    .when('online', {
      is: false,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
  city: Yup.string()
    .nullable()
    .when('online', {
      is: false,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
  link: Yup.string().required('Required').url('Link must be a valid Url'),
  start_date: Yup.string().nullable().required('Required'),
  end_date: Yup.string().nullable().required('Required'),
  start_at: Yup.string().nullable().required('Required'),
  end_at: Yup.string().nullable().required('Required'),
  time_zone: Yup.string().required('Required'),
  event_category_id: Yup.number().nullable().required('Required'),
  image: Yup.mixed().required('Required'),
});

const toOptions = (array) =>
  array.map((item) => ({
    label: startCase(item.name || item),
    value: item.id || item,
  }));

const EventForm = ({ hideForm, updateId }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const event = useSelector(Selectors.fetchCreatedEvent);
  const initialValues = updateId ? event : defaultValues;
  const countries = useSelector(Selectors.countryOptions);
  const cities = useSelector(Selectors.citiesOfCountry);
  const timezones = useSelector(Selectors.getTimezones);
  const categories = useSelector(Selectors.eventCategories);
  const emptyCountries = isEmpty(countries);
  const emptyTimezones = !timezones.length;
  const { country, id, image } = initialValues;
  const isSubmitting = useSelector(
    Selectors.createLoadingSelector([
      Actions.CREATE_EVENT_REQUEST,
      Actions.UPDATE_EVENT_REQUEST,
    ])
  );
  const isLoadingCountryList = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_COUNTRY_OPTIONS_REQUEST])
  );
  const isLoadingCityList = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_CITIES_OF_COUNTRY_REQUEST])
  );
  const isLoadingTimezones = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_TIMEZONES_REQUEST])
  );
  const isLoadingEvent = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_CREATED_EVENT_REQUEST])
  );

  const createEvent = (values) => {
    const { draft } = values;
    const successMsg = draft
      ? 'Event saved as draft'
      : 'Event created, pending admin approval';
    const callbacks = {
      success: () => {
        dispatch(Actions.hideDialog());
        hideForm();
        notify(successMsg);
      },
      failure: (error) => {
        setError(error);
        dispatch(Actions.hideDialog());
      },
    };

    if (draft) return dispatch(Actions.createEvent(values, callbacks));

    dispatch(
      Actions.showDialog({
        title: 'CREATE AND NOTIFY ADMIN',
        content:
          'Creating an event requires admin approval, it will be published once approved.',
        acceptLabel: 'YES, CREATE EVENT',
        dismissLabel: "NO, DON'T CREATE",
        onAccept: () => dispatch(Actions.createEvent(values, callbacks)),
      })
    );
  };

  const updateEvent = (values) => {
    const callbacks = {
      success: () => {
        hideForm();
        notify('Event is updated');
        dispatch(Actions.clearFetchCreated());
      },
      failure: setError,
    };

    dispatch(Actions.updateEvent(updateId, values, callbacks));
  };

  const submitEvent = (values) => {
    const filtered = pickBy(values, (v) => v !== null);
    return updateId ? updateEvent(filtered) : createEvent(filtered);
  };

  useEffect(() => {
    if (emptyCountries) dispatch(Actions.fetchCountryOptions());
  }, [dispatch, emptyCountries]);

  useEffect(() => {
    if (emptyTimezones) dispatch(Actions.fetchTimezones());
  }, [dispatch, emptyTimezones]);

  useEffect(() => {
    dispatch(Actions.fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (updateId && updateId !== id)
      dispatch(Actions.fetchCreatedEvent(updateId));
  }, [dispatch, id, updateId]);

  useEffect(() => {
    if (country) dispatch(Actions.fetchCitiesOfCountry(country));
  }, [dispatch, country]);

  useEffect(() => {
    if (error) {
      const scrollDiv = document.getElementById('scrollableContent');
      scrollDiv.scroll({ top: scrollDiv.scrollHeight, behavior: 'smooth' });
    }
  }, [error]);

  if (isLoadingEvent)
    return (
      <div className="w-full justify-center items-center flex absolute top-0 bottom-0">
        <Spinner />
      </div>
    );

  return (
    <div className="lg:container mx-auto pt-16 sm:pt-10 sm:px-6 px-2 max-w-full mb-30">
      <Card className="mx-auto border-t-2 p-4 sm:p-10 mt-5">
        <div className="mb-4">
          <div className="text-2lg font-chap">
            {`${updateId ? 'Update' : 'Create'} Event`}
          </div>
          {!updateId && (
            <p className="text-dusty">
              Create an event and link them to an external event website
              (Eventbrite, Facebook Events, 10Times etc.)
            </p>
          )}
        </div>
        <div className="text-lg font-chap mb-3">Event Details</div>
        <Formik
          onSubmit={submitEvent}
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          enableReinitialize
        >
          {({ setFieldValue, setFieldTouched, values, handleSubmit }) => (
            <Form>
              <Field
                name="name"
                component={TextInput}
                placeholder="Event Name"
              />
              <Field
                name="description"
                component={TextInput}
                placeholder="Description"
                type="textarea"
                maxLength={2000}
                className="h-30"
              />
              <Field
                name="image"
                component={ImageInput}
                initialImgUrl={image}
              />
              <div className="text-lg font-chap mb-3 mt-5">Event Location</div>
              <Field
                name="online"
                component={RadioButtonInput}
                options={RADIO_OPTIONS}
              />
              {!values.online && (
                <div className="flex flex-col lg:flex-row mt-2 mb-4">
                  <Field
                    name="country"
                    placeholder="Country"
                    component={SelectInput}
                    isLoading={isLoadingCountryList}
                    onChange={({ value }) => {
                      dispatch(Actions.fetchCitiesOfCountry(value));
                      setFieldValue('city', '');
                      setFieldTouched('city', false);
                    }}
                    options={toOptions(Object.values(countries))}
                    className="mr-4"
                  />
                  <Field
                    name="city"
                    placeholder="City"
                    component={SelectInput}
                    isLoading={isLoadingCityList}
                    isDisabled={isEmpty(cities) || !values.country}
                    options={toOptions(cities)}
                  />
                </div>
              )}
              <p className="text-dusty">Website</p>
              <SocialMediaLinkInput
                className="w-full"
                name="link"
                placeholder="Paste website URL for members to purchase ticket (eg. www.eventbrite.com)"
              />
              <div className="text-lg font-chap mb-3 mt-5">Event Schedule</div>
              <p className="text-dusty">Date</p>
              <div className="flex my-2">
                <Field
                  name="start_date"
                  component={DateRangeInput}
                  onChange={(dates) => {
                    setFieldValue('start_date', dates[0]);
                    setFieldValue('end_date', dates[1]);
                    setFieldTouched('start_date', false);
                  }}
                  containerClassName="my-2"
                  initialValues={[values.start_date, values.end_date].map(
                    (val) => val && new Date(val)
                  )}
                />
              </div>
              <p className="text-dusty">Time</p>
              <div className="flex my-2">
                <Field
                  name="start_at"
                  component={TimeRangeInput}
                  onChange={(times) => {
                    setFieldValue('start_at', times[0]);
                    setFieldValue('end_at', times[1]);
                  }}
                  containerClassName="my-2"
                  initialValues={[values.start_at, values.end_at]}
                />
              </div>
              <p className="text-dusty">Time Zone</p>
              <div className="flex my-2">
                <Field
                  name="time_zone"
                  placeholder="Select Time Zone"
                  component={SelectInput}
                  isLoading={isLoadingTimezones}
                  options={toOptions(timezones)}
                  className="mr-4 w-full sm:max-w-100"
                />
              </div>
              <div className="text-lg font-chap mb-3 mt-5">Others</div>
              <p className="text-dusty">Event Category</p>
              <div className="flex my-2">
                <Field
                  name="event_category_id"
                  placeholder="Choose category"
                  component={SelectInput}
                  isLoading={isLoadingCountryList}
                  options={toOptions(categories)}
                  className="mr-4 w-full sm:max-w-100"
                />
              </div>
              <FormFooter
                isSubmitting={isSubmitting}
                hideForm={hideForm}
                submitEvent={() => {
                  values.draft = false;
                  handleSubmit();
                }}
                submitDraft={() => {
                  values.draft = true;
                  handleSubmit();
                }}
                isUpdate={!!updateId}
              />
              <ErrorContainer error={error} className="mt-4" />
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

EventForm.propTypes = {
  hideForm: PropTypes.func.isRequired,
  updateId: PropTypes.string,
};

EventForm.defaultProps = {
  updateId: null,
};

export default EventForm;

import Actions from 'actions';

export const initialState = Object.freeze({
  data: [],
  exp: null,
  page: {},
  featured: null,
});

function list(state = initialState, { type, data, exp, page }) {
  switch (type) {
    case Actions.FETCH_PERKS_SUCCESS:
      return {
        exp,
        data: page?.currentPage > 1 ? [...state.data, ...data] : data,
        page: page || state.page,
        featuredPerk: state.featuredPerk || data[0],
      };
    case Actions.FETCH_PERKS_FAIL:
      return { ...state, exp: null };
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default list;

import React from 'react';
import { useSelector } from 'react-redux';
import Selectors from 'selectors';
import { useSearchParams } from 'react-router-dom';
import { Field, Formik, Form } from 'formik';
import TextInput from 'components/Form/TextInput';
import { debounce } from 'lodash';

import useQuery from 'hooks/useQuery';
import Checkbox from 'components/UI/Checkbox';
import { CityFilterInput, Select } from 'components/UI';

const Filter = () => {
  const params = useQuery();
  const hasParams = Object.values(params).some((obj) => obj !== '');
  const { category, city, online, search, country } = params;
  const [, setSearchParams] = useSearchParams();
  const cities = useSelector(Selectors.cities);
  const categories = useSelector(Selectors.eventCategories);
  const cityOptions = cities.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  const countries = useSelector(Selectors.countries).map((item) => ({
    label: item.name,
    value: item.name,
  }));
  const categoryOptions = [
    { label: 'All Categories', value: null },
    ...categories.map(({ name, id }) => ({
      label: name,
      value: id,
    })),
  ];

  const setQueryParams = (query) => {
    const queryParams = { ...params, ...query };
    const result = {};
    Object.entries(queryParams).forEach(([k, v] = arr) => {
      if (v) result[k] = v;
    });
    setSearchParams(result);
  };

  return (
    <div className="my-8 justify-center items-center xl:justify-between lg:flex">
      <div className="flex flex-col sm:flex-row">
        <Formik
          initialValues={{ category, city, online, search, country }}
          onSubmit={setQueryParams}
        >
          {({ submitForm, setFieldValue }) => (
            <Form
              className="flex flex-col w-full sm:flex-row sm:flex-wrap"
              onChange={debounce(submitForm, 500)}
            >
              <Field
                name="search"
                className="h-12 rounded-full pl-5 sm:pl-8 sm:pr-32 -mt-1"
                component={TextInput}
                basic
                placeholder="Search an event. . ."
              />
              <Select
                onChange={({ value }) => {
                  setFieldValue('category', value);
                  submitForm();
                }}
                options={categoryOptions}
                placeholder="All Categories"
                className="sm:w-56 sm:ml-3"
                defaultValue={categoryOptions.find(
                  ({ value }) => value === parseInt(category, 10)
                )}
                key={category}
              />
              <CityFilterInput
                className="mx-0 sm:mx-4 my-4 sm:my-0 sm:w-56"
                cities={cityOptions}
                onChange={({ value }) => {
                  setFieldValue('city', value);
                  submitForm();
                }}
                defaultValue={city}
              />

              <Select
                key={country}
                className="mb-4 sm:mr-4 sm:w-56"
                onChange={({ value }) => {
                  setFieldValue('country', value);
                  submitForm();
                }}
                options={[
                  { label: 'All Countries', value: null },
                  ...countries,
                ]}
                placeholder="All Countries"
                value={countries.find(({ value }) => value === country)}
              />
              <Checkbox
                label="Online"
                className="mt-2 sm:my-4"
                onChange={() => {
                  setFieldValue('online', !online);
                  submitForm();
                }}
                value={online}
                key={online}
              />
              {hasParams && (
                <button
                  className="mr-4 border border-silver rounded shadow text-silver h-12 focus:outline-none px-4 w-full sm:w-32 sm:mb-0"
                  onClick={() => {
                    setSearchParams();
                    setFieldValue('category', null);
                    setFieldValue('city', null);
                    setFieldValue('country', null);
                  }}
                  type="button"
                >
                  Clear Filter
                </button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Filter;

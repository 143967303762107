import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import api from 'api';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Card, Picture } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import { rewardsHistoryIcon } from 'assets';
import RewardActivityCard from './RewardActivtyCard';
import RedeemableActivityCard from './RedeemableActivityCard';

const RewardsActivity = () => {
  const [isPointsTab, setIsPointsTab] = useState(true);
  const changeTab = () => {
    setIsPointsTab(!isPointsTab);
  };

  // Rewards Activity Query
  const fetchRewardsActivity = ({ pageParam = 1 }) =>
    api.fetchRewardsActivity({ page: pageParam });
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    'rewardsActivityData',
    fetchRewardsActivity,
    {
      getNextPageParam: (lastPage) => {
        const currentPage = Number(lastPage?.headers?.['current-page'] || 0);
        const nextPage = currentPage + 1;
        const totalPages = Number(lastPage?.headers?.['total-pages'] || 0);
        if (nextPage <= totalPages) return nextPage;
      },
    }
  );
  const rewardsActivityData = data?.pages.map(({ data }) => data).flat() || [];
  const rewardsActivityDataLength = rewardsActivityData?.length || 0;

  // Redeemables Activity Query
  const fetchUserRedeemables = ({ pageParam = 1 }) =>
    api.fetchUserRedeemables({ page: pageParam });
  const {
    data: userRedeemabalesQueryData,
    fetchNextPage: fetchMoreUsersRedeemables,
    hasNextPage: hasMoreUsersRedeemables,
  } = useInfiniteQuery('userRedeemabalesData', fetchUserRedeemables, {
    getNextPageParam: (lastPage) => {
      const currentPage = Number(lastPage?.headers?.['current-page'] || 0);
      const nextPage = currentPage + 1;
      const totalPages = Number(lastPage?.headers?.['total-pages'] || 0);
      if (nextPage <= totalPages) return nextPage;
    },
  });
  const usersRedeemablesData =
    userRedeemabalesQueryData?.pages.map(({ data }) => data).flat() || [];
  const usersRedeemablesDataLength = usersRedeemablesData?.length || 0;

  return (
    <>
      <section className="flex-col mb-10 px-3 lg:px-10 xl:px-16">
        <h3 className="font-semibold text-md">My Rewards Activity</h3>
        <section className="flex items-start mt-3 space-x-2 overflow-x-auto">
          <Button
            label="Points Received"
            className="text-xs sm:text-sm mb-3 h-10 flex items-center justify-center"
            outline
            theme={isPointsTab ? 'easter' : 'plain'}
            role="button"
            onClick={changeTab}
          />
          <Button
            label="Rewards Redeemed"
            className="text-xs sm:text-sm mb-3 h-10 flex items-center justify-center border-gray"
            outline
            theme={isPointsTab ? 'plain' : 'easter'}
            role="button"
            onClick={changeTab}
          />
        </section>
        {isPointsTab ? (
          <>
            {rewardsActivityData.length === 0 ? (
              <Card className="flex flex-col items-center self-center w-full xl:w-11/12 xl:self-start px-4 py-8 xl:py-20 space-y-2 justify-center">
                <Picture
                  src={rewardsHistoryIcon}
                  className="self-center w-20 xl:w-30"
                />
                <p className="text-xs  lg:text-sm font-semibold text-center xl:text-md">
                  You currently have no rewards activity
                </p>
                <p className="text-dusty text-xxs lg:text-xs text-center xl:text-sm">
                  History of all points activity will be shown here.
                </p>
              </Card>
            ) : (
              <InfiniteScroll
                dataLength={rewardsActivityDataLength}
                next={fetchNextPage}
                hasMore={hasNextPage}
                scrollableTarget="scrollableContent"
                loader={
                  <div className="flex pb-8 self-center">
                    <Spinner />
                  </div>
                }
              >
                {rewardsActivityData?.map((data) => (
                  <RewardActivityCard key={data.id} rewardActivity={data} />
                ))}
              </InfiniteScroll>
            )}
          </>
        ) : (
          <>
            {usersRedeemablesData.length === 0 ? (
              <Card className="flex flex-col items-center self-center w-full xl:w-11/12 xl:self-start px-4 py-8 xl:py-20 space-y-2 justify-center">
                <Picture
                  src={rewardsHistoryIcon}
                  className="self-center w-20 xl:w-30"
                />
                <p className="text-xs  lg:text-sm font-semibold text-center xl:text-md">
                  You currently have yet to redeem any rewards. Head to Explore
                  Rewards
                </p>
                <p className="text-dusty text-xxs lg:text-xs text-center xl:text-sm">
                  History of all points redemptions will be shown here.
                </p>
              </Card>
            ) : (
              <InfiniteScroll
                dataLength={usersRedeemablesDataLength}
                next={fetchMoreUsersRedeemables}
                hasMore={hasMoreUsersRedeemables}
                scrollableTarget="scrollableContent"
                loader={
                  <div className="flex pb-8 self-center">
                    <Spinner />
                  </div>
                }
              >
                {usersRedeemablesData?.map((data) => (
                  <RedeemableActivityCard
                    key={data.id}
                    redeemableActvity={data}
                  />
                ))}
              </InfiniteScroll>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default RewardsActivity;

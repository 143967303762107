import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import { getSbTotalUnreadCount } from 'utils/sendbird';

function* getTotalUnreadCount() {
  try {
    const count = yield call(getSbTotalUnreadCount);
    yield put(Actions.getTotalUnreadCountSuccess(count));
  } catch (error) {
    yield put(Actions.getTotalUnreadCountFail(error));
  }
}

function* watchGetTotalUnreadCount() {
  yield takeLatest(Actions.GET_TOTAL_UNREAD_COUNT_REQUEST, getTotalUnreadCount);
}

export default function* unread() {
  yield all([fork(watchGetTotalUnreadCount)]);
}

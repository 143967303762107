import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchSubscription() {
  try {
    const response = yield call(api.fetchSubscription);
    yield put(Actions.fetchSubscriptionSuccess(response?.data));
  } catch (error) {
    yield put(Actions.fetchSubscriptionFail(error));
  }
}

function* watchFetchSubscription() {
  yield takeLatest(Actions.FETCH_SUBSCRIPTION_REQUEST, fetchSubscription);
}

export default function* fetch() {
  yield all([fork(watchFetchSubscription)]);
}

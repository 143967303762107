import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import Selectors from 'selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextInput, SelectInput } from 'components/Form';
import classnames from 'classnames';
import { Card, Button, Modal } from 'components/UI';
import {
  displayConsultationDateFormat,
  displayTimeFormat,
} from 'utils/datetime';
import * as Yup from 'yup';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';
import { Link } from 'react-router-dom';
import PaymentModal from './PaymentModal';

const ValidationSchema = Yup.object().shape(
  {
    consultation_service_id: Yup.number().required('Required'),
    date: Yup.string().required('Required'),
    customer_name: Yup.string().required('Required'),
    customer_email: Yup.string().email('Invalid email').required('Required'),
    customer_description: Yup.string().required('Required'),
    customer_website: Yup.string().when('customer_social_media', {
      is: undefined,
      then: Yup.string().required('Either Website or Social Media is required'),
      otherwise: Yup.string(),
    }),
    customer_social_media: Yup.string().when('customer_website', {
      is: undefined,
      then: Yup.string().required('Either Website or Social Media is required'),
      otherwise: Yup.string(),
    }),
    booking_details: Yup.string().required('Required'),
  },
  ['customer_website', 'customer_social_media']
);

const MemberValidationSchema = Yup.object().shape({
  consultation_service_id: Yup.number().required('Required'),
  date: Yup.string().required('Required'),
  booking_details: Yup.string().required('Required'),
});

const BookingForm = ({
  consultants,
  isSubscribed,
  handleTab,
  selectedConsultantId,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [startAt, setStartAt] = useState(null);
  const [startAtError, setStartAtError] = useState(false);
  const [modalStatus, showModal] = useState(false);
  const [selectedConsultant, selectConsultant] = useState(null);
  const [bookingData, setBookingData] = useState({});
  const slots = useSelector(Selectors.getConsultationSlots);
  const isLoadingSlots = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_SLOTS_REQUEST])
  );
  const dates = [
    ...new Set(slots.map((slot) => displayConsultationDateFormat(slot))),
  ];
  const dateOptions = dates.map((item) => ({
    label: item,
    value: item,
  }));
  const validationSchema = isSubscribed
    ? MemberValidationSchema
    : ValidationSchema;
  // eslint-disable-next-line camelcase
  const discountedRate = selectedConsultant?.discounted_rate;
  const baseRate = selectedConsultant?.rate;
  const consultantOptions = consultants.map(
    ({ rate, name, id, discounted_rate: discountedRate }) => ({
      label: `${name} (USD ${Number(discountedRate || rate)}/hour)`,
      value: id,
    })
  );

  useEffect(() => {
    if (selectedConsultantId) {
      dispatch(Actions.fetchConsultationSlots(selectedConsultantId));
      selectConsultant(
        consultants.find(({ id }) => id === selectedConsultantId)
      );
    }
  }, [dispatch, consultants, selectedConsultantId]);

  const closeModal = () => showModal(false);

  const StartAtOptions = () => {
    const slotBtn = (slot) => (
      <div
        className={classnames(
          'text-sm sm:text-base mr-4 rounded-full p-2 w-30 flex justify-center items-center mb-4',
          {
            'bg-gray-200 text-gray-500 cursor-pointer': startAt !== slot,
            'bg-easter text-white': startAt === slot,
          }
        )}
        onClick={() => setStartAt(slot)}
        role="presentation"
        key={slot}
      >
        {displayTimeFormat(slot)}
      </div>
    );

    return (
      <div className="mb-4">
        <div className="flex flex-row items-center flex-wrap">
          {slots
            .filter((slot) => displayConsultationDateFormat(slot) === date)
            .map((slot) => slotBtn(slot))}
        </div>
        {!startAt && startAtError && (
          <div className="text-valencia h-4 mt-1 text-xs ml-3 font-light">
            Required
          </div>
        )}
      </div>
    );
  };

  const confirmBooking = (values) => {
    if (!startAt) return setStartAtError(true);
    setBookingData({ ...values, start_at: startAt, selectedConsultant });
    showModal(true);
  };

  return (
    <>
      <h2 className="text-center font-semibold">
        Let&apos;s Book A Consultation Slot
      </h2>
      <div className="text-center mb-4 sm:mb-8">
        Choose the most suitable time for you and book a slot with us!
      </div>
      <div className="container mx-auto px-6 mb-24 max-w-screen-lg">
        <Card className="border-t-8 border-easter">
          <Formik
            onSubmit={confirmBooking}
            initialValues={{
              consultation_service_id: selectedConsultantId || '',
              date: '',
              customer_name: '',
              customer_email: '',
              customer_description: '',
              customer_website: '',
              customer_social_media: '',
              booking_details: '',
            }}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({ setFieldValue, setFieldTouched, values, handleSubmit }) => (
              <Form>
                <div className="p-5 sm:p-8">
                  <h5 className="text-lg mb-4">Booking Details</h5>
                  <div className="mb-2 text-sm text-dusty">
                    I would like consultation by...
                  </div>
                  <Field
                    name="consultation_service_id"
                    component={SelectInput}
                    placeholder="Select Consultant"
                    options={consultantOptions}
                    className="w-full sm:max-w-120 mb-2"
                    onChange={({ value }) => {
                      setDate();
                      setStartAt();
                      dispatch(Actions.fetchConsultationSlots(value));
                      setFieldValue('date', '');
                      setFieldTouched('date', false);
                      selectConsultant(
                        consultants.find(({ id }) => id === value)
                      );
                    }}
                  />
                  {values.consultation_service_id &&
                    (dateOptions.length ? (
                      <>
                        <div className="mb-2 text-sm text-dusty">
                          Choose available date
                        </div>
                        <Field
                          name="date"
                          component={SelectInput}
                          placeholder="Choose Date"
                          options={dateOptions}
                          className="w-full sm:max-w-120 mb-2"
                          onChange={({ value }) => {
                            setDate(value);
                            setStartAt();
                            setFieldValue('start_at', '');
                            setFieldTouched('start_at', false);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {!isLoadingSlots && (
                          <div className="mb-6 text-red-600">
                            All dates are fully booked, please choose another
                            consultant to proceed
                          </div>
                        )}
                      </>
                    ))}
                  {date && (
                    <>
                      <div className="mb-2 text-sm text-dusty">
                        Choose start time (Slots below are shown in your local
                        time zone)
                      </div>
                      <StartAtOptions />
                    </>
                  )}
                  <h5 id="booking-form" className="text-lg my-4">
                    Your Details
                  </h5>
                  {!isSubscribed && (
                    <>
                      <Field
                        name="customer_name"
                        component={TextInput}
                        placeholder="Name"
                      />
                      <Field
                        name="customer_email"
                        component={TextInput}
                        placeholder="Email"
                      />
                      <Field
                        name="customer_website"
                        component={TextInput}
                        placeholder="Website"
                      />
                      <Field
                        name="customer_social_media"
                        component={TextInput}
                        placeholder="Social Media"
                      />
                      <Field
                        name="customer_description"
                        component={TextInput}
                        placeholder="Tell us more about yourself..."
                        type="textarea"
                        showRemaining
                        maxLength={200}
                      />
                    </>
                  )}
                  <Field
                    name="booking_details"
                    component={TextInput}
                    placeholder="How can we help you?  Please share what you would like consultation on and include any relevant links that can help us prepare. "
                    type="textarea"
                    showRemaining
                    maxLength={500}
                  />
                </div>
                {selectedConsultant && (
                  <>
                    <div className="px-8 py-6 flex flex-row justify-between items-center bg-lily">
                      <div className="sm:pl-2 text-mulled text-sm sm:text-base">
                        {`Consultation Fee: ${
                          discountedRate ? '(50% Discount)' : ''
                        }`}
                        {!discountedRate && (
                          <Tooltip
                            arrow
                            placement="top"
                            interactive
                            title={
                              <p className="font-lynstone text-sm m-2 leading-4">
                                {`Apply to be a ${ALL_ACCESS}/${PREMIUM} member and get 50% OFF on all
                              consultation fees. `}
                                <Link
                                  to="/signup"
                                  className="font-semibold text-easter"
                                >
                                  Apply now
                                </Link>
                              </p>
                            }
                          >
                            <InfoIcon className="text-dusty hover:text-easter" />
                          </Tooltip>
                        )}
                      </div>
                      {discountedRate ? (
                        <div className="flex flex-col justify-center items-center">
                          <div className="sm:text-lg">{`USD ${discountedRate}`}</div>
                          <div className="text-dusty text-sm line-through">
                            {`USD ${baseRate}`}
                          </div>
                        </div>
                      ) : (
                        <div className="text-dusty sm:text-lg">
                          {`USD ${baseRate}`}
                        </div>
                      )}
                    </div>
                    <div className="p-8 flex flex-col justify-center items-center">
                      <div className="text-dusty text-xs sm:text-sm text-center w-full sm:w-3/4 mb-6">
                        *Your booking request is subject to our approval. By
                        clicking “CONFIRM BOOKING”, you acknowledge that you
                        agree to our
                        <a
                          className="font-semibold text-easter"
                          href="/terms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {` Terms & Conditions`}
                        </a>
                      </div>
                      <Modal
                        isOpen={modalStatus}
                        content={
                          <PaymentModal
                            bookingData={bookingData}
                            closeModal={closeModal}
                            isSubscribed={isSubscribed}
                            handleTab={handleTab}
                          />
                        }
                        onDismiss={closeModal}
                        onChildClick={handleSubmit}
                      >
                        <Button
                          label="CONFIRM BOOKING"
                          bold={false}
                          uppercase={false}
                          isPill
                          className="mb-2 sm:mb-4 py-3 w-full sm:max-w-xs"
                        />
                      </Modal>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
};

BookingForm.propTypes = {
  consultants: PropTypes.array.isRequired,
  isSubscribed: PropTypes.bool,
  handleTab: PropTypes.func.isRequired,
  selectedConsultantId: PropTypes.number,
};

BookingForm.defaultProps = {
  isSubscribed: false,
  selectedConsultantId: null,
};

export default BookingForm;

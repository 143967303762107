const config = {
  production: {
    SERVER_URL: 'https://api.giglifepro.com',
    SENDBIRD_APP_ID: '0B5BB8B3-FCE5-4D17-8816-44D33A6A0C59',
    STRIPE_PUB_KEY: 'pk_live_SoWq5YyP7r7HjBKrJAv5nmNp00xQpkWaCM',
    ANALYTICS: 'G-YW0R7VXFZZ',
    FB_PIXEL: '2941364236136088',
    LINKEDIN_TAG_ID: '3409676',
    LINKEDIN_FREE_TIER: '7875212',
    WEB_URL: 'https://giglifepro.com',
  },
  staging: {
    SERVER_URL: 'https://staging-api.giglifepro.com',
    SENDBIRD_APP_ID: '5E948E42-42F6-42DF-B5C3-9A9807F894D7',
    STRIPE_PUB_KEY: 'pk_test_NR64diXFxosquGmbFV3gJbpY00XKlQW0Pp',
    WEB_URL: 'https://staging.giglifepro.com',
  },
  development: {
    SERVER_URL: 'https://dev-api.giglifepro.com/',
    SENDBIRD_APP_ID: '5E948E42-42F6-42DF-B5C3-9A9807F894D7',
    STRIPE_PUB_KEY:
      'pk_test_51Q2kkYKywYe83pSEoKHDGtLuOMc8Ctaqaly8uIbVqy7SuxLJvnjzlTTsML7LVmyhWKiXOzFCEPIvcRH5KakFEVSv009GSKQsIZ',
    ANALYTICS: 'G-H4SBXJLJT7',
    WEB_URL: 'https://dev.giglifepro.com',
  },
};

const { NODE_ENV } = process.env;

export const isProduction = NODE_ENV === 'production';
export const isDevelop = NODE_ENV === 'development';

export default config[NODE_ENV];

const NAME = 'ARTICLES';

export const FETCH_ARTICLE_INFO_REQUEST = `${NAME}/FETCH_ARTICLE_INFO_REQUEST`;
export const FETCH_ARTICLE_INFO_SUCCESS = `${NAME}/FETCH_ARTICLE_INFO_SUCCESS`;
export const FETCH_ARTICLE_INFO_FAIL = `${NAME}/FETCH_ARTICLE_INFO_FAIL`;

export const fetchArticleInfo = (id, callbacks) => ({
  type: FETCH_ARTICLE_INFO_REQUEST,
  id,
  callbacks,
});

export const fetchArticleInfoSuccess = (data) => ({
  type: FETCH_ARTICLE_INFO_SUCCESS,
  data,
});

export const fetchArticleInfoFail = (error) => ({
  type: FETCH_ARTICLE_INFO_FAIL,
  error,
});

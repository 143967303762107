import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  membershipCriteria,
  membershipBenefits,
  experienceOptions,
} from 'utils/constants';
import { bgAuth } from 'assets';
import { BackgroundSection } from '../../LandingPage/common';
import TierInfoCard from '../../Membership/TierInfoCard';
import ExperienceFilter from '../../Membership/ExperienceFilter';

const ChooseTiers = ({
  memberTiers = [],
  setLiteOnly,
  liteOnly = false,
  getPrice,
  getMinPrice,
  showNextScreen,
}) => {
  const sortedTiers = memberTiers.sort((tier1, tier2) => {
    return getMinPrice(tier1.prices) - getMinPrice(tier2.prices);
  });

  const [activeTab, setActiveTab] = useState(
    experienceOptions[experienceOptions.length - 1]
  );
  const handleTabChange = (experience) => {
    setActiveTab(experience);
    setLiteOnly(experience !== experienceOptions[experienceOptions.length - 1]);
  };

  return (
    <>
      <BackgroundSection
        backgroundImage={`url(${bgAuth.default})`}
        className="relative flex flex-col bg-no-repeat bg-cover pt-24 pb-20 px-2"
      >
        <ExperienceFilter
          handleTabChange={handleTabChange}
          activeTab={activeTab}
          className="text-white"
        />
        <div className="flex flex-col justify-center max-w-6xl mx-auto mt-16 px-4 relative z-10">
          <div className="mt-5 flex flex-col md:flex-row gap-6 space-y-3 md:space-y-0 lg:px-3 justify-center">
            {sortedTiers.map((tier) => (
              <TierInfoCard
                key={tier.id}
                memberTier={tier?.name}
                content={membershipCriteria[tier?.name]}
                benefits={membershipBenefits[tier?.name]}
                monthlyPrice={getPrice(tier, 'month')}
                annualPrice={getPrice(tier, 'year')}
                isAllAccessEligible={!liteOnly}
                showNextScreen={showNextScreen}
              />
            ))}
          </div>
        </div>
      </BackgroundSection>
    </>
  );
};

ChooseTiers.propTypes = {
  memberTiers: PropTypes.array.isRequired,
  setLiteOnly: PropTypes.func.isRequired,
  liteOnly: PropTypes.bool.isRequired,
  getPrice: PropTypes.func.isRequired,
  getMinPrice: PropTypes.func.isRequired,
  showNextScreen: PropTypes.func.isRequired,
};

export default ChooseTiers;

const NAME = 'AUTH/VALIDATE_EMAIL_';

export const VALIDATE_EMAIL_REQUEST = `${NAME}REQUEST`;
export const VALIDATE_EMAIL_SUCCESS = `${NAME}SUCCESS`;
export const VALIDATE_EMAIL_FAIL = `${NAME}FAIL`;

export const validateEmail = (email, callbacks) => ({
  type: VALIDATE_EMAIL_REQUEST,
  email,
  callbacks,
});

export const validateEmailSuccess = () => ({
  type: VALIDATE_EMAIL_SUCCESS,
});

export const validateEmailFail = (error) => ({
  type: VALIDATE_EMAIL_FAIL,
  error,
});

import Actions from 'actions';

const initialState = Object.freeze([]);

function slots(state = initialState, action) {
  switch (action.type) {
    case Actions.CREATE_EVENT_SUCCESS:
      return [...state, action.data];
    case Actions.UPDATE_EVENT_SUCCESS: {
      const newState = [...state];
      const index = newState.findIndex(({ id }) => id === action.id);
      newState[index] = action.data;
      return newState;
    }
    case Actions.FETCH_CREATED_LIST_SUCCESS:
      return action.data;
    case Actions.DELETE_EVENT_SUCCESS:
      return state.filter(({ id }) => id !== action.id);
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default slots;

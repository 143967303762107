const NAME = 'DASHBOARD/FETCH_DASHBOARD_INFO';

export const FETCH_DASHBOARD_INFO_REQUEST = `${NAME}_REQUEST`;
export const FETCH_DASHBOARD_INFO_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_DASHBOARD_INFO_FAIL = `${NAME}_FAIL`;

export const fetchDashboardInfo = (id) => ({
  type: FETCH_DASHBOARD_INFO_REQUEST,
  id,
});

export const fetchDashboardInfoSuccess = (data) => ({
  type: FETCH_DASHBOARD_INFO_SUCCESS,
  data,
});

export const fetchDashboardInfoFail = (error) => ({
  type: FETCH_DASHBOARD_INFO_FAIL,
  error,
});

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import Selectors from 'selectors';
import { Loading } from 'components/UI';
import isEmpty from 'lodash/isEmpty';

import ViewProfile from './View';
import CompleteProfile from './Complete';

const ProfilePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.fetchProfile());
  }, [dispatch]);

  const isLoading = useSelector(
    Selectors.createLoadingSelector([
      Actions.FETCH_PROFILE_REQUEST,
      Actions.UPDATE_USER_REQUEST,
    ])
  );

  const userProfile = useSelector(Selectors.userProfile);

  const {
    image_url: avatarUrl,
    first_name: firstName,
    last_name: lastName,
    email,
    country,
    city,
    biography,
    languages,
    rep_artists: repArtist,
    rep_events: repEvents,
    rep_venues: repVenues,
    facebook,
    linked_in: linkedIn,
    instagram,
    member_types: memberTypes,
    industry_since: industrySince,
    users_experiences: workExperiences,
    users_services: userServices,
    spotify_playlists: spotify,
    youtube_playlists: youtube,
    completed_profile: hasCompletedProfile = true,
    chat_notification: chatNotification,
    email_visibility: emailVisibility,
  } = userProfile;

  const arrayStringInitValue = (array) => array?.join(', ') || '';
  const arrayInitialValues = (array) => (isEmpty(array) ? [''] : array);
  const workExpInitialValues = (array) => {
    if (isEmpty(array)) return [''];

    // allow to display dynamic year_end value, refer to MR 305
    return array.map((obj) => (obj.year_end ? obj : { ...obj, year_end: ' ' }));
  };

  const initialValues = {
    avatarUrl: avatarUrl || '',
    first_name: firstName || '',
    last_name: lastName || '',
    email: email || '',
    country: country?.name || '',
    city: city?.name || '',
    biography: biography || '',
    member_types:
      memberTypes?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) || [],
    languages:
      languages?.map(({ name }) => ({
        label: name,
        value: name,
      })) || [],
    rep_artists: arrayStringInitValue(repArtist),
    rep_events: arrayStringInitValue(repEvents),
    rep_venues: arrayStringInitValue(repVenues),
    facebook: facebook || '',
    linked_in: linkedIn || '',
    instagram: instagram || '',
    industry_since: industrySince || '',
    users_experiences: workExpInitialValues(workExperiences),
    users_services: arrayInitialValues(userServices),
    spotify_playlists: arrayInitialValues(spotify),
    youtube_playlists: arrayInitialValues(youtube),
    chat_notification: chatNotification,
    email_visibility: emailVisibility,
  };

  const content = () => {
    if (hasCompletedProfile)
      return <ViewProfile initialValues={initialValues} />;
    return (
      <CompleteProfile
        hasCompletedProfile={hasCompletedProfile}
        initialValues={initialValues}
      />
    );
  };

  return (
    <>
      {isLoading && isEmpty(userProfile) && (
        <Loading className="fixed left-0 top-0 bottom-0 right-0" />
      )}
      {content()}
    </>
  );
};

export default ProfilePage;

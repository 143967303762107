import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Actions from 'actions';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { iconClock, iconStar, iconStarActive } from 'assets';
import { Badge, Card, Picture } from 'components/UI';
import { displayDateFormat } from 'utils/datetime';
import { urlHandler } from 'utils/helper';
import { AuthContext } from 'utils/context';
import qs from 'query-string';
import { startCase } from 'lodash';
import { BackgroundSection, labelBg } from '../LandingPage/common';
import ArticleFilter from './Filter';

export const ArticleCard = ({ article, updateFavourites, articlesPath }) => {
  const {
    id,
    image_url: imageUrl,
    article_category: category,
    title,
    location,
    current_user_favourited: favourited,
    publish_at: postedTime,
    read_time: timeToRead,
  } = article;
  const dispatch = useDispatch();
  const { hasAccess } = useContext(AuthContext);
  const [favourite, setFavourite] = useState(favourited || false);
  const cardLabel = category?.name;
  const cardLabelUrl = `${articlesPath}?${qs.stringify({
    category: cardLabel,
  })}`;

  useEffect(() => {
    setFavourite(favourited);
  }, [favourited]);

  const handleFavourite = (e) => {
    e.preventDefault();

    if (favourite) dispatch(Actions.unfavouriteArticleInfo(id));
    else dispatch(Actions.favouriteArticleInfo(id));

    updateFavourites(id);
    setFavourite(!favourite);
  };

  return (
    <div className="relative z-0 h-full">
      {cardLabel && (
        <Link to={cardLabelUrl}>
          <Badge bg={labelBg(cardLabel)} className="ml-4 mt-4 absolute z-10">
            {cardLabel}
          </Badge>
        </Link>
      )}
      <Link to={`${articlesPath}/${id}`} className="relative">
        <Card className="h-full">
          <BackgroundSection
            backgroundImage={`url(${urlHandler(imageUrl)})`}
            className="relative bg-no-repeat bg-cover bg-center h-56 rounded-t-lg"
          >
            <div className="flex justify-between">
              {hasAccess && (
                <div
                  className={classnames(
                    'flex items-center justify-center cursor-pointer ml-auto',
                    'bg-gray-500 bg-opacity-50 h-10 w-10 rounded-bl-lg rounded-tr-lg'
                  )}
                  onClick={handleFavourite}
                  role="presentation"
                >
                  <Picture
                    src={favourite ? iconStarActive : iconStar}
                    className="w-5 h-5"
                  />
                </div>
              )}
            </div>
          </BackgroundSection>
          <div className="p-4 space-y-1">
            <h3>{title}</h3>
            <p className="text-boulder">{startCase(location)}</p>
            <div className="flex text-boulder text-sm">
              <span className="flex-1 border-r border-boulder pr-2">
                {displayDateFormat(postedTime)}
              </span>
              <span className="flex items-center flex-1 pl-2">
                <Picture src={iconClock} className="w-4 mr-2" />
                {timeToRead || '0'} min read
              </span>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};

ArticleCard.propTypes = {
  article: PropTypes.object.isRequired,
  updateFavourites: PropTypes.func,
  articlesPath: PropTypes.string,
};

ArticleCard.defaultProps = {
  updateFavourites: () => {},
  articlesPath: '/articles',
};

const List = ({ articles, updateFavourites, articlesPath }) => (
  <div className="container mx-auto px-6 mb-10">
    <ArticleFilter />
    {articles.length ? (
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {articles.map((item) => (
          <ArticleCard
            article={item}
            key={item.id}
            articlesPath={articlesPath}
            updateFavourites={updateFavourites}
          />
        ))}
      </div>
    ) : (
      <div className="text-center mx-auto my-24">No articles found</div>
    )}
  </div>
);

List.propTypes = {
  articles: PropTypes.array.isRequired,
  articlesPath: PropTypes.string,
  updateFavourites: PropTypes.func,
};

List.defaultProps = {
  articlesPath: '/articles',
  updateFavourites: () => {},
};

export default List;

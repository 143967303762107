import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/UI';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'utils/context';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';

const LockOverlay = ({
  showLockOverlay,
  className,
  isPaidContent,
  isAllAccessContent,
}) => {
  const { authenticated } = useContext(AuthContext);
  const message = () => {
    if (isAllAccessContent) return `Exclusive for ${ALL_ACCESS} only`;
    return isPaidContent
      ? `Exclusive for ${PREMIUM} & ${ALL_ACCESS} only`
      : 'Become a member to view more…';
  };
  const buttonLabel =
    (isPaidContent || isAllAccessContent) && authenticated
      ? 'Upgrade Membership'
      : 'Apply to be a member';
  const navigatePath = authenticated ? '/subscription' : '/signup';

  useEffect(() => {
    // NOTES: This will reload the page whenever the overlay div is deleted using DevTools
    const observer = new MutationObserver((records) => {
      records.forEach((record) => {
        record.removedNodes.forEach((node) => {
          if (node.id === 'overlay' && showLockOverlay)
            window.location.reload();
        });
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      childList: true,
      subtree: true,
      attributeOldValue: true,
      characterData: true,
    });
  }, [showLockOverlay]);

  const navigate = useNavigate();

  if (showLockOverlay) {
    return (
      <div
        id="overlay"
        className={classnames(
          'flex flex-col items-center justify-center text-center p-8',
          'absolute bottom-0 w-full bg-gradient-white-hide',
          className
        )}
      >
        <h5>{message()}</h5>
        <div className="mt-6">
          <Button label={buttonLabel} onClick={() => navigate(navigatePath)} />
        </div>
      </div>
    );
  }

  return null;
};

LockOverlay.propTypes = {
  showLockOverlay: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isPaidContent: PropTypes.bool,
  isAllAccessContent: PropTypes.bool,
};

LockOverlay.defaultProps = {
  className: 'h-2/5',
  isPaidContent: false,
  isAllAccessContent: false,
};

export default LockOverlay;

const NAME = 'EVENTS/REMOVE_EVENT_';

export const REMOVE_EVENT_REQUEST = `${NAME}REQUEST`;
export const REMOVE_EVENT_SUCCESS = `${NAME}SUCCESS`;
export const REMOVE_EVENT_FAIL = `${NAME}FAIL`;

export const removeEvent = (id, callbacks) => ({
  type: REMOVE_EVENT_REQUEST,
  id,
  callbacks,
});

export const removeEventSuccess = () => ({
  type: REMOVE_EVENT_SUCCESS,
});

export const removeEventFail = () => ({
  type: REMOVE_EVENT_FAIL,
});

const NAME = 'COUNTRIES';

export const FETCH_CITIES_OF_COUNTRY_REQUEST = `${NAME}/FETCH_CITIES_OF_COUNTRY_REQUEST`;
export const FETCH_CITIES_OF_COUNTRY_SUCCESS = `${NAME}/FETCH_CITIES_OF_COUNTRY_SUCCESS`;
export const FETCH_CITIES_OF_COUNTRY_FAIL = `${NAME}/FETCH_CITIES_OF_COUNTRY_FAIL`;

export const fetchCitiesOfCountry = (data) => ({
  type: FETCH_CITIES_OF_COUNTRY_REQUEST,
  data,
});

export const fetchCitiesOfCountrySuccess = (data) => ({
  type: FETCH_CITIES_OF_COUNTRY_SUCCESS,
  data,
});

export const fetchCitiesOfCountryFail = (error) => ({
  type: FETCH_CITIES_OF_COUNTRY_FAIL,
  error,
});

import { all, fork } from 'redux-saga/effects';
import auth from './auth';
import events from './events';
import cities from './cities';
import user from './user';
import articles from './articles';
import newsLetterSignUp from './newsLetterSignUp';
import countries from './countries';
import membership from './membership';
import sendbird from './sendbird';
import sendgrid from './sendgrid';
import stripe from './stripe';
import common from './common';
import dashboard from './dashboard';
import subscription from './subscription';
import consultation from './consultation';
import timezones from './timezones';
import videos from './videos';
import perks from './perks';
import consultationService from './consultationService';

export default function* root() {
  yield all([
    fork(auth),
    fork(events),
    fork(cities),
    fork(user),
    fork(articles),
    fork(newsLetterSignUp),
    fork(countries),
    fork(sendbird),
    fork(sendgrid),
    fork(stripe),
    fork(membership),
    fork(common),
    fork(dashboard),
    fork(subscription),
    fork(consultation),
    fork(timezones),
    fork(videos),
    fork(perks),
    fork(consultationService),
  ]);
}

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Selectors from 'selectors';
import classnames from 'classnames';
import useQuery from 'hooks/useQuery';
import { iconStar, iconStarActive } from 'assets';
import { CityFilterInput, Select, Picture } from 'components/UI';
import { Field, Formik, Form } from 'formik';
import TextInput from 'components/Form/TextInput';
import { debounce } from 'lodash';

import { AuthContext } from 'utils/context';

const Filter = () => {
  const [, setSearchParams] = useSearchParams();
  const { authenticated } = useContext(AuthContext);
  const params = useQuery();
  const hasParams = Object.values(params).some((obj) => obj !== '');
  const { city, category, favourite, search, country } = params;
  const isFavourite = favourite === 'true';

  const cities = useSelector(Selectors.cities).map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const countries = useSelector(Selectors.countries).map((item) => ({
    label: item.name,
    value: item.name,
  }));
  const categories = useSelector(Selectors.articleCategories);
  const categoryOptions = [
    { label: 'All Categories', value: null },
    ...categories.map(({ name, id }) => ({
      label: name,
      value: id,
    })),
  ];

  const updateQuery = (query) => {
    const queryParams = { ...params, ...query };
    const result = {};
    Object.entries(queryParams).forEach(([k, v] = arr) => {
      if (v) result[k] = v;
    });

    setSearchParams(result);
  };

  return (
    <div className="flex my-8">
      <Formik
        initialValues={{ category, city, favourite, search, country }}
        onSubmit={updateQuery}
      >
        {({ submitForm, setFieldValue }) => (
          <Form
            onChange={debounce(submitForm, 500)}
            className="flex flex-col md:flex-row md:flex-wrap gap-3 w-full justify-between"
          >
            <div className="flex flex-col lg:flex-row gap-3">
              <Field
                name="search"
                className="h-12 rounded-full pl-8 -mt-1 lg:w-64"
                component={TextInput}
                basic
                placeholder="Search an article. . ."
              />
              <CityFilterInput
                className="mb-4 sm:mr-4 sm:w-56"
                cities={cities}
                defaultValue={city}
                onChange={({ value }) => {
                  setFieldValue('city', value);
                  submitForm();
                }}
                valueString
              />
              <Select
                key={country}
                className="mb-4 sm:mr-4 sm:w-56"
                onChange={({ value }) => {
                  setFieldValue('country', value);
                  submitForm();
                }}
                options={[
                  { label: 'All Countries', value: null },
                  ...countries,
                ]}
                placeholder="All Countries"
                value={countries.find(({ value }) => value === country)}
              />
              <Select
                key={category}
                className="sm:w-56"
                onChange={({ value }) => {
                  setFieldValue('category', value);
                  submitForm();
                }}
                options={categoryOptions}
                placeholder="All Categories"
                defaultValue={categoryOptions.find(
                  ({ value }) => value === parseInt(category, 10)
                )}
              />
              {hasParams && (
                <button
                  className="border border-silver rounded shadow text-silver h-12 focus:outline-none px-4 w-full sm:w-32 sm:mb-0"
                  onClick={() => {
                    setSearchParams();
                    setFieldValue('category', null);
                    setFieldValue('city', null);
                    setFieldValue('country', null);
                  }}
                  type="button"
                >
                  Clear Filter
                </button>
              )}
            </div>
            <div>
              {authenticated && (
                <div className="flex items-center h-12 mt-4 lg:mt-0">
                  <div
                    className={classnames(
                      isFavourite ? 'bg-sigmaBlue' : 'bg-white',
                      'rounded-l-full flex flex-wrap cursor-pointer items-center justify-center shadow-card'
                    )}
                    onClick={() => {
                      setFieldValue('favourite', isFavourite ? '' : 'true');
                      submitForm();
                    }}
                    role="presentation"
                  >
                    <div className="h-12 px-6">
                      <Picture
                        src={isFavourite ? iconStarActive : iconStar}
                        className="h-6 mt-3"
                      />
                    </div>
                  </div>
                  <div
                    className={classnames(
                      isFavourite ? 'bg-sigmaBlue-dark' : 'bg-white',
                      'rounded-r-full flex flex-wrap items-center justify-center shadow-card'
                    )}
                  >
                    <div
                      className={classnames(
                        isFavourite ? 'text-white' : 'text-dusty',
                        'h-12 pt-3 px-8 sm:px-5'
                      )}
                    >
                      Favourite Articles
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Filter;

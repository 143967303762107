const NAME = 'EVENTS/ATTEND_EVENT';

export const ATTEND_EVENT_REQUEST = `${NAME}_REQUEST`;
export const ATTEND_EVENT_SUCCESS = `${NAME}_SUCCESS`;
export const ATTEND_EVENT_FAIL = `${NAME}_FAIL`;

export const attendEvent = (id, data, callbacks) => ({
  type: ATTEND_EVENT_REQUEST,
  id,
  data,
  callbacks,
});

export const attendEventSuccess = (id, data) => ({
  type: ATTEND_EVENT_SUCCESS,
  id,
  data,
});

export const attendEventFail = (error) => ({
  type: ATTEND_EVENT_FAIL,
  error,
});

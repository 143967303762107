import startCase from 'lodash/startCase';
import { imgPlaceholder } from 'assets';
import { yearRange } from './format';
import { CURRENT_YEAR } from './datetime';

export const arrayToOptions = (array) =>
  array.map((item) => ({
    label: startCase(item),
    value: item,
  }));

export const YEAR_OPTIONS = arrayToOptions(yearRange());

export const selectOptionsHelper = (array, key, firstItem) => {
  const optionsArray = [
    ...new Set(
      array.filter((item) => item[key] !== null).map((item) => item[key].name)
    ),
  ];

  return [
    { label: `All ${firstItem}`, value: null },
    ...arrayToOptions(optionsArray),
  ];
};

export const urlHandler = (url = '') => {
  if (url === null) return imgPlaceholder.default;
  return `"${url}"`;
};

export const paginationHelper = (headers) => ({
  currentPage: Number(headers['current-page']),
  pageItems: Number(headers['page-items']),
  totalCount: Number(headers['total-count']),
  totalPages: Number(headers['total-pages']),
});

export const latestWorkExp = (workExp) =>
  workExp.sort((exp1, exp2) => {
    const ongoingYear = CURRENT_YEAR + 1;
    const yearEnd1 = exp1.year_end || ongoingYear;
    const yearEnd2 = exp2.year_end || ongoingYear;
    if (yearEnd1 !== yearEnd2) return yearEnd2 - yearEnd1;
    if (exp2.year_start === exp1.year_start) return exp2.id - exp1.id;
    return exp2.year_start - exp1.year_start;
  })[0] || {};

import auth from './auth';
import persist from './persist';
import common from './common';
import events from './events';
import cities from './cities';
import user from './user';
import articles from './articles';
import newsLetterSignUp from './newsLetterSignUp';
import countries from './countries';
import sendbird from './sendbird';
import sendgrid from './sendgrid';
import stripe from './stripe';
import membership from './membership';
import dashboard from './dashboard';
import subscription from './subscription';
import ui from './ui';
import consultation from './consultation';
import timezones from './timezones';
import videos from './videos';
import perks from './perks';
import consultationService from './consultationService';

export default {
  AUTH: auth,
  PERSIST: persist,
  COMMON: common,
  EVENTS: events,
  CITIES: cities,
  USER: user,
  ARTICLES: articles,
  NEWSLETTER_SIGNUP: newsLetterSignUp,
  COUNTRIES: countries,
  SENDBIRD: sendbird,
  SENDGRID: sendgrid,
  STRIPE: stripe,
  DASHBOARD: dashboard,
  MEMBERSHIP: membership,
  SUBSCRIPTION: subscription,
  UI: ui,
  CONSULTATION: consultation,
  TIMEZONE: timezones,
  VIDEO: videos,
  PERKS: perks,
  CONSULTATION_SERVICE: consultationService,
};

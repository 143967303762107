import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, Picture } from 'components/UI';
import { displayTimeFormat, displayDayDateFormat } from 'utils/datetime';
import { iconClockTeal, iconAddEventGrey } from 'assets';
import { Link } from 'react-router-dom';
import { urlHandler } from 'utils/helper';
import { BackgroundSection, labelBg } from '../../LandingPage/common';

const AddedEventCard = ({ eventInfo, eventsPath, handleRemoveEvent }) => {
  const {
    id,
    city,
    name,
    image_url: imageUrl,
    start_at: startAt,
    end_at: endAt,
    event_category: eventCategory,
    time_zone: timeZone,
  } = eventInfo;
  const eventType = eventCategory?.name || 'Festival';

  return (
    <div className="w-full min-w-80 mr-4 mb-10 sm:mr-8">
      <Card className="w-full sm:flex">
        <Link className="w-full sm:w-1/2 lg:w-1/3" to={`${eventsPath}/${id}`}>
          <BackgroundSection
            backgroundImage={`url(${urlHandler(imageUrl)})`}
            className="bg-no-repeat bg-cover bg-center h-full rounded-l-lg"
          />
        </Link>
        <div className="w-full px-6 pb-6 sm:grid lg:flex">
          <Link to={`${eventsPath}/${id}`} className="w-full lg:w-2/3">
            <Badge bg={labelBg(eventType)} className="pb-2 pt-6">
              {eventType}
            </Badge>
            <div className="mt-2 text-2lg font-bold truncate max-w-lg">
              {name}
            </div>
            <p className="text-boulder font-semibold">
              {city?.name || 'Global'}
            </p>
            <div className="mt-5 flex text-boulder text-sm">
              <span className="border-r border-easter pr-4 text-easter w-1/2 lg:w-auto">
                {displayDayDateFormat(startAt, timeZone)}
              </span>
              <span className="flex items-center flex-1 pl-4 text-easter">
                <Picture src={iconClockTeal} className="w-4 mr-2 text-easter" />
                {`${displayTimeFormat(startAt)} - ${displayTimeFormat(endAt)}`}
              </span>
            </div>
          </Link>
          <div className="grid w-full lg:w-1/3">
            <div
              className="flex items-center justify-end cursor-pointer h-10 sm:h-12 pt-6"
              onClick={() => handleRemoveEvent(id)}
              role="presentation"
            >
              <Picture
                src={iconAddEventGrey}
                className="w-5 h-5 sm:w-6 sm:h-6"
              />
              <div className="uppercase text-dusty ml-3 text-sm sm:text-base">
                remove event
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

AddedEventCard.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  eventsPath: PropTypes.string,
  handleRemoveEvent: PropTypes.func,
};

AddedEventCard.defaultProps = {
  eventsPath: '/events',
  handleRemoveEvent: null,
};

export default AddedEventCard;

import Actions from 'actions';

const initialState = Object.freeze({});

function list(state = initialState, action) {
  switch (action.type) {
    case Actions.LIST_MESSAGES_REQUEST:
      return {
        ...initialState,
        data: state.data,
      };

    case Actions.LIST_MESSAGES_SUCCESS:
      return {
        ...initialState,
        data: { ...state.data, [action.id]: action.data },
      };

    case Actions.LIST_MESSAGES_FAIL:
      return initialState;
    case Actions.SIGN_OUT_SUCCESS:
    default:
      return state;
  }
}

export default list;

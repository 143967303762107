/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import Selectors from 'selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'components/UI/Loading';
import { Card, Picture, Options } from 'components/UI';
import { nameFormat } from 'utils/format';
import { displaySubscriptionDateFormat } from 'utils/datetime';
import { isEmpty, startCase } from 'lodash';
import {
  bgMemberCardPro,
  bgMemberCardLite,
  bgMemberCardFree,
  gfxViewSubscription,
} from 'assets';
import notify from 'utils/notification';
import { AuthContext } from 'utils/context';
import { ALL_ACCESS, PREMIUM, FREE } from 'utils/constants';
import { BackgroundSection } from '../LandingPage/common';
import { PRO_DIALOG, liteDialog } from './dialogs';
import MemberDifference from './MemberDifference';
import TierCard from './TierCard';
import TerminateForm from './TerminateForm';

const MembershipDetails = ({ isLoading, subscription, tiers, isMobile }) => {
  const {
    price,
    status,
    next_price: nextPrice,
    expires_at: expiry,
    pro_eligible: isProEligible,
    monthly_upgraded: isMonthlyUpgraded,
    reward_period: activeReward,
    cancel_at: cancelAt,
  } = subscription;
  const {
    first_name: firstName,
    last_name: lastName,
    member_tier: tier,
    approved_at: approved,
  } = useSelector(Selectors.getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isPro, isFreeTier, hasAccess } = useContext(AuthContext);
  const tierName = tier?.name;
  const isAnnual = price?.interval === 'year';
  const cardBg = {
    [FREE]: bgMemberCardFree,
    [PREMIUM]: bgMemberCardLite,
    [ALL_ACCESS]: bgMemberCardPro,
  }[tierName];
  const nextPricePlan = isAnnual ? 'monthly' : 'annual';
  const validity = isFreeTier
    ? 'Indefinitely'
    : displaySubscriptionDateFormat(expiry);
  const freeMemberTier = tiers.find(({ name }) => name === FREE);
  const availableTiers = isProEligible
    ? tiers.filter(({ name }) => name !== FREE)
    : tiers.filter(({ name }) => name === PREMIUM);
  const cancelUpdate = () => {
    dispatch(
      Actions.cancelUpdateSubscription({
        success: () => notify('Cancelled subscription update'),
        failure: (error) =>
          notify(
            error || 'Failed to cancel, please try again later',
            null,
            'danger'
          ),
      })
    );
  };

  const optionList = () => {
    if (isFreeTier) {
      return [
        {
          label: 'Delete Account',
          className: 'text-valencia',
          onClick: () =>
            dispatch(
              Actions.showDialog({
                title: 'Permanently Delete Account?',
                content:
                  'Are you sure you want to delete your account permanently?',
                subContent: `Once the deletion process begins, you won't be able to reactivate your account or retrieve any of the content or information you have added.`,
                acceptLabel: 'YES, DELETE ACCOUNT',
                acceptBtnTheme: 'valencia',
                dismissLabel: `NO, DON'T DELETE`,
                onAccept: () => {
                  dispatch(
                    Actions.showDialog({
                      title: 'Permanently Delete Account?',
                      content: <TerminateForm />,
                      acceptLabel: 'YES, DELETE ACCOUNT',
                      acceptBtnTheme: 'valencia',
                      dismissLabel: `NO, DON'T DELETE`,
                      hideButtons: true,
                    })
                  );
                },
              })
            ),
        },
      ];
    }

    const updateTier = () =>
      dispatch(
        Actions.showDialog({
          ...(isPro ? PRO_DIALOG : liteDialog(price?.interval === 'year')),
          onAccept: () => {
            navigate('/subscription/update', {
              state: { tierToUpdate: isPro ? PREMIUM : ALL_ACCESS },
            });
            dispatch(Actions.hideDialog());
          },
        })
      );

    const downgradeToFreeTier = {
      label: `Downgrade to ${FREE}`,
      onClick: () =>
        dispatch(
          Actions.showDialog({
            title: `DOWNGRADE TO ${FREE} MEMBERSHIP?`,
            content: `Are you sure you want to downgrade to ${FREE} membership?`,
            subContent: `You will not be charged any monthly fee but you will lose all the benefits tied to ${tierName} membership.`,
            acceptLabel: `YES, DOWNGRADE TO ${FREE}`,
            dismissLabel: "NO, DON'T DOWNGRADE",
            onAccept: () => {
              if (freeMemberTier) {
                dispatch(
                  Actions.updateSubscription(
                    { price_id: freeMemberTier.prices[0].id },
                    {
                      success: () => {
                        dispatch(Actions.hideDialog());
                        notify('Successfully updated subscription!');
                        navigate('/subscription');
                      },
                      failure: () =>
                        notify(
                          'Failed to update subscription, please try again later',
                          null,
                          'danger'
                        ),
                    }
                  )
                );
              }
            },
          })
        ),
    };

    return hasAccess && (isPro || isProEligible)
      ? [
          {
            label: isPro
              ? `Downgrade to ${PREMIUM}`
              : `Upgrade to ${ALL_ACCESS}`,
            onClick: updateTier,
          },
          downgradeToFreeTier,
        ]
      : [downgradeToFreeTier];
  };

  const updateMessage = () => {
    if (isMonthlyUpgraded) {
      return (
        <div>
          {`You have upgraded to ${ALL_ACCESS} membership!`}
          <br />
          {`Your new billing cycle will take effect on `}
          <span className="text-mulled font-semibold">
            {`${displaySubscriptionDateFormat(expiry, true)} `}
          </span>
          <p>
            {`Click `}
            <span
              className="text-easter font-semibold cursor-pointer"
              onClick={cancelUpdate}
              role="presentation"
            >
              here
            </span>
            {` if you want to cancel`}
          </p>
        </div>
      );
    }

    const nextTierName = nextPrice?.member_tier?.name;
    const nextPricePlanName =
      tierName === nextTierName ? nextPricePlan : nextTierName;

    return (
      <>
        <p>
          {`Your new ${nextPricePlanName} subscription will take effect on `}
          <span className="text-mulled font-semibold">
            {`${displaySubscriptionDateFormat(expiry, true)}. `}
          </span>
        </p>
        {!activeReward && (
          <p>
            {`Click `}
            <span
              className="text-easter font-semibold cursor-pointer"
              onClick={cancelUpdate}
              role="presentation"
            >
              here
            </span>
            {` to cancel`}
          </p>
        )}
      </>
    );
  };

  const renderMembership = () => {
    const statusTextColor =
      status === 'active' || status === 'trialing'
        ? 'text-green-400'
        : 'text-dusty';

    const formatStatus = status === 'incomplete' ? 'pending_payment' : status;

    const PaymentDetails = () => {
      return isMobile ? (
        <div className="p-10 my-auto lg:border-l lg:min-h-56">
          <p className="text-xs text-dusty">RECURRING PAYMENT</p>
          <p className="text-lg uppercase my-2">monthly</p>
        </div>
      ) : (
        <>
          <div className="sm:min-h-56 flex justify-center flex-col sm:border-l">
            {activeReward ? (
              <>
                <p className="text-sm text-dusty uppercase">You are on</p>
                <p className="text-md my-2">1 Month {tier?.name} Reward</p>
              </>
            ) : (
              <>
                <p className="text-xs text-dusty">RECURRING PAYMENT</p>
                <p className="text-lg uppercase my-2">
                  {isAnnual ? 'annual' : 'monthly'}
                </p>
              </>
            )}
            {!cancelAt && !activeReward && (
              <div className="italic text-xs text-dusty font-light">
                {`Next payment: ${displaySubscriptionDateFormat(expiry)}`}
              </div>
            )}
            {!activeReward && (
              <>
                <Picture
                  src={gfxViewSubscription}
                  className="h-10 mt-3 mb-5 w-full"
                />
                <div className="flex justify-center my-auto">
                  <span className="text-easter font-semibold">
                    {price?.currency}
                  </span>
                  <span className="text-3xl text-easter leading-none font-semibold">
                    {Number(price?.amount)}
                  </span>
                  <span className="text-sm self-end text-ebony">
                    {`/${isAnnual ? 'yr' : 'mo'}`}
                  </span>
                </div>
              </>
            )}
          </div>
          {nextPrice ? (
            <div className="rounded-lg bg-yellow-200 text-gray-500 mt-5 text-sm h-16 flex justify-center items-center px-1">
              {updateMessage()}
            </div>
          ) : (
            <>
              {hasAccess && !activeReward && (
                <Link to="/subscription/update">
                  <div className="flex rounded-lg bg-teal-100 justify-center items-center py-4 text-gray-700 mt-5 h-16">
                    {`Switch to ${nextPricePlan} payment?`}
                  </div>
                </Link>
              )}
            </>
          )}
        </>
      );
    };

    return (
      <div className="w-full py-5">
        <div className="p-5 pt-0">
          <div className="flex items-center border-b pb-4 w-full">
            <div className="text-lg uppercase font-medium">{`${tierName} MEMBERSHIP`}</div>
            <div className={`ml-auto text-sm uppercase ${statusTextColor}`}>
              {formatStatus === 'trialing'
                ? startCase('active')
                : startCase(formatStatus)}
            </div>
            {!nextPrice && !isMobile && <Options list={optionList()} />}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 px-6 sm:px-0 sm:pl-4">
          <div className="flex flex-col w-full sm:min-w-80">
            <Card>
              <BackgroundSection
                backgroundImage={`url(${cardBg.default})`}
                className="relative bg-no-repeat bg-cover bg-center rounded-lg"
              >
                <div className="flex flex-col text-white text-shadow justify-between min-h-48 sm:min-h-56">
                  <div className="self-end text-center mt-4 mr-4">
                    <div className="text-sm">
                      <span className="uppercase">{tierName}</span> Membership
                    </div>
                  </div>
                  <div className="flex flex-col mx-2 mb-2 sm:mx-4 sm:mb-4">
                    <div className="text-sm sm:text-base sm:font-hairline">
                      {nameFormat({ firstName, lastName })}
                    </div>
                    <div className="flex flex-row justify-between text-xxs font-hairline sm:text-xs">
                      <div>
                        {`Joined: ${displaySubscriptionDateFormat(approved)}`}
                      </div>
                      <div>{`Valid till: ${validity}`}</div>
                    </div>
                  </div>
                </div>
              </BackgroundSection>
            </Card>
            <MemberDifference />
          </div>
          <div className="flex flex-col text-center w-full px-4">
            {isFreeTier ? (
              <div className="sm:px-4 sm:border-l h-full flex flex-col gap-4">
                {availableTiers.map((memberTier) => (
                  <TierCard tier={memberTier} key={memberTier.name} />
                ))}
              </div>
            ) : (
              <PaymentDetails />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <h3 className="uppercase mb-4 font-semibold">My Subscription</h3>
      <Card className="min-w-80 sm:min-w-100 max-w-200">
        {isLoading && isEmpty(subscription) ? (
          <div className="flex justify-center items-center py-4 min-h-56">
            <Spinner className="h-20" color="#47bad4" />
          </div>
        ) : (
          !isEmpty(subscription) && renderMembership()
        )}
      </Card>
    </>
  );
};

MembershipDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  subscription: PropTypes.object.isRequired,
  tiers: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MembershipDetails;

import { combineReducers } from 'redux';
import consultants from './consultants';
import slots from './slots';
import bookings from './bookings';
import create from './create';

export default combineReducers({
  consultants,
  slots,
  bookings,
  create,
});

import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import useResize from 'hooks/useResize';
import notify from 'utils/notification';
import { AuthContext } from 'utils/context';
import Channels from './Channels';
import ChatBox from './ChatBox';

const MD_SCREEN = 768;

const Chat = () => {
  const dispatch = useDispatch();
  const { width } = useResize();
  const { state } = useLocation();
  const recipientId = state?.sendbirdId;
  const channels = useSelector(Selectors.getChannels);
  const { isFreeTier } = useContext(AuthContext);

  const [chatListIsOpen, showChatList] = useState(true);
  const [currentFocus, setCurrentFocus] = useState(null);

  useEffect(() => {
    // Notes: Enable chat list visibility trigger on a smaller screen
    if (width >= MD_SCREEN) showChatList(true);
  }, [width]);

  useEffect(() => {
    // Notes: Load channels when mounted
    dispatch(Actions.listChannels());
  }, [dispatch]);

  useEffect(() => {
    if (currentFocus) {
      const { channel } = currentFocus;
      dispatch(Actions.listMessages(channel));
    }
  }, [dispatch, currentFocus]);

  useEffect(() => {
    dispatch(Actions.fetchSendbirdUsers());
  }, [dispatch]);

  const handleSelectChat = (channel) => {
    channel.channel.markAsRead();
    setCurrentFocus(channel);
    showChatList(false);
  };

  const leaveChannel = (channel) => {
    const callbacks = {
      success: () => setCurrentFocus() && showChatList(true),
      failure: () =>
        notify('Failed to leave group, try again later', null, 'danger'),
    };
    dispatch(
      Actions.showDialog({
        title: 'LEAVE GROUP',
        content: 'Are you sure you want to LEAVE this group?',
        subContent: 'You won’t be able to receive new messages from this group',
        acceptLabel: 'YES, LEAVE GROUP',
        dismissLabel: "NO, DON'T LEAVE",
        onAccept: () => dispatch(Actions.leaveChannel(channel, callbacks)),
      })
    );
  };

  useEffect(() => {
    if (recipientId && channels.length && !currentFocus) {
      const channel = channels.find(
        ({ recipients }) =>
          !Array.isArray(recipients) && recipients.userId === recipientId
      );
      return channel && handleSelectChat(channel);
    }
  }, [recipientId, channels, currentFocus]);

  useEffect(() => {
    if (isFreeTier && channels.length > 0 && !currentFocus) {
      setCurrentFocus(channels[0]);
    }
  }, [channels, isFreeTier, currentFocus]);

  return (
    <div className="flex overflow-x-hidden">
      <Channels
        isOpen={chatListIsOpen}
        selectChat={handleSelectChat}
        channels={channels}
        openedChannel={currentFocus !== null}
      />
      <ChatBox
        channel={currentFocus}
        isOpen={!chatListIsOpen}
        selectChat={handleSelectChat}
        showChatList={() => showChatList(true)}
        recipient={recipientId}
        leaveChannel={leaveChannel}
      />
    </div>
  );
};

export default Chat;

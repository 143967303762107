import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from 'components/UI';
import FeaturedLayout from '../../LandingPage/common/FeaturedLayout';
import MemberAccessBadge from '../MemberAccessBadge';

const Featured = ({ perk }) => {
  const {
    id,
    image_url: img,
    name,
    summary,
    member_access: memberAccess,
  } = perk;

  return (
    <FeaturedLayout image={img}>
      <div className="flex items-center justify-center relative m-auto text-white">
        <div className="flex flex-col">
          <MemberAccessBadge memberAccess={memberAccess} md />
          <h1 className="font-semibold leading-none mt-4 mb-2 sm:text-6xl text-4xl max-w-6xl">
            {name}
          </h1>
          <p className="sm:text-lg max-w-xl">{summary}</p>
          <Link to={id} className="mt-6">
            <Button
              label="PERK DETAILS"
              bold={false}
              theme="easter"
              className="bg-opacity-50 flex flex-row-reverse"
              icon={<ChevronRightIcon className="ml-2" />}
            />
          </Link>
        </div>
      </div>
    </FeaturedLayout>
  );
};

Featured.propTypes = {
  perk: PropTypes.object.isRequired,
};

export default Featured;

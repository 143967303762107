const NAME = 'CITIES';

export const FETCH_CITY_INFO_REQUEST = `${NAME}/FETCH_CITY_INFO_REQUEST`;
export const FETCH_CITY_INFO_SUCCESS = `${NAME}/FETCH_CITY_INFO_SUCCESS`;
export const FETCH_CITY_INFO_FAIL = `${NAME}/FETCH_CITY_INFO_FAIL`;

export const fetchCityInfo = (id) => ({
  type: FETCH_CITY_INFO_REQUEST,
  id,
});

export const fetchCityInfoSuccess = (data) => ({
  type: FETCH_CITY_INFO_SUCCESS,
  data,
});

export const fetchCityInfoFail = (error) => ({
  type: FETCH_CITY_INFO_FAIL,
  error,
});

const NAME = 'PERKS/FETCH_PERKS_';

export const FETCH_PERKS_REQUEST = `${NAME}REQUEST`;
export const FETCH_PERKS_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_PERKS_FAIL = `${NAME}FAIL`;

export const fetchPerks = (params) => ({
  type: FETCH_PERKS_REQUEST,
  params,
});

export const fetchPerksSuccess = (data, exp, page) => ({
  type: FETCH_PERKS_SUCCESS,
  data,
  exp,
  page,
});

export const fetchPerksFail = (error) => ({
  type: FETCH_PERKS_FAIL,
  error,
});

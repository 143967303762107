import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from 'actions';
import classnames from 'classnames';
import {
  CURRENT_TIMEZONE,
  DAY_OPTIONS,
  displayWeekday,
  HOURS_OPTIONS,
} from 'utils/datetime';
import { DateInput, SelectInput } from 'components/Form';
import { Formik, Form, Field } from 'formik';
import { add, startOfDay } from 'date-fns';

import notify from 'utils/notification';
import { Button } from 'components/UI';

const AddSlot = ({
  addSlotIsOpen,
  service,
  addRecurringSlot,
  addRecurringSlotIsOpen,
  editSlotIsOpen,
  closeSlotForms,
  currentSlot,
  currentSlotId,
}) => {
  const formIsOpen = addRecurringSlotIsOpen || addSlotIsOpen || editSlotIsOpen;
  const isEditForm = editSlotIsOpen;
  const DEFAULT_VALUES = isEditForm
    ? currentSlot
    : { date: '', startHour: '', endHour: '' };
  const dispatch = useDispatch();
  const formattedSlots = service?.slots.reduce(
    (currentResult, slot) => {
      let date = new Date(slot.start_at);
      const startHour = `${date.getHours()}`;
      const endHour = `${new Date(slot.end_at).getHours()}`;
      if (!slot.recurring)
        return {
          ...currentResult,
          slots: [
            ...currentResult.slots,
            { ...slot, date, startHour, endHour },
          ],
        };

      date = DAY_OPTIONS.find(
        ({ label }) => label === displayWeekday(slot.start_at)
      ).value;
      const formatted = { ...slot, date, startHour, endHour };

      return {
        ...currentResult,
        recurringSlots: [...currentResult.recurringSlots, formatted],
      };
    },
    { slots: [], recurringSlots: [] }
  );
  const { slots, recurringSlots } = { ...formattedSlots };

  const addNewSlot = (values) => {
    const allSlots = [...slots, ...recurringSlots].map((slot) => {
      const date = startOfDay(slot.date);
      const startDateTime = add(date, { hours: slot.startHour });
      const endDateTime = add(date, { hours: slot.endHour });
      return {
        start_at: startDateTime,
        end_at: endDateTime,
        timezone: CURRENT_TIMEZONE,
        recurring: slot.recurring,
        id: slot.id,
        _destroy: slot.destroy,
      };
    });
    const newSlotDate = startOfDay(values.date);
    const formatNewSlot = {
      start_at: add(newSlotDate, { hours: values.startHour }),
      end_at: add(newSlotDate, { hours: values.endHour }),
      timezone: CURRENT_TIMEZONE,
      recurring: addRecurringSlot,
    };
    const data = {
      ...service,
      consultation_slots_attributes: [...allSlots, formatNewSlot],
    };
    const callbacks = {
      success: () => {
        notify('Consultation slots updated');
      },
      fail: (e) => notify(e, null, 'danger'),
    };

    return dispatch(Actions.updateConsultationService(data, callbacks));
  };

  const editSlot = (values) => {
    const edittedSlotDate = startOfDay(values.date);
    const formatEdittedSlot = {
      id: currentSlotId,
      start_at: add(edittedSlotDate, { hours: values.startHour }),
      end_at: add(edittedSlotDate, { hours: values.endHour }),
      timezone: CURRENT_TIMEZONE,
      recurring: addRecurringSlot,
    };
    const data = {
      ...service,
      consultation_slots_attributes: [formatEdittedSlot],
    };
    const callbacks = {
      success: () => {
        notify('Consultation slot updated');
      },
      fail: (e) => notify(e, null, 'danger'),
    };

    return dispatch(Actions.updateConsultationService(data, callbacks));
  };

  const removeSlot = () => {
    const data = {
      ...service,
      consultation_slots_attributes: [{ id: currentSlotId, _destroy: true }],
    };
    const callbacks = {
      success: () => {
        notify('Consultation slot deleted');
      },
      fail: (e) => notify(e, null, 'danger'),
    };
    return dispatch(Actions.updateConsultationService(data, callbacks));
  };

  return (
    <div
      className={classnames(
        { hidden: !formIsOpen },
        'bg-gray-100 mt-2 rounded-lg p-3 mb-3 xl:w-11/12'
      )}
    >
      <Formik
        initialValues={formIsOpen ? DEFAULT_VALUES : {}}
        onSubmit={isEditForm ? editSlot : addNewSlot}
        enableReinitialize
      >
        {({ handleSubmit, values }) => (
          <Form>
            <div className="w-full">
              <p className="text-dusty mb-2">
                {addRecurringSlot ? 'Day' : 'Consultation Date'}
              </p>
              <Field
                name="date"
                component={addRecurringSlot ? SelectInput : DateInput}
                options={DAY_OPTIONS}
                placeholder={`Select ${addRecurringSlot ? 'Day' : 'Date'}`}
                minDate={new Date()}
              />
            </div>
            <div className="flex flex-col lg:flex-row justify-around lg:justify-start lg:space-x-2 border-b">
              <div>
                <p className="text-dusty mb-2">Consultation start time</p>
                <Field
                  name="startHour"
                  component={SelectInput}
                  placeholder="Start Time"
                  options={HOURS_OPTIONS.slice(0, -1)}
                  isDisabled={!values.date}
                />
              </div>
              <div>
                <p className="text-dusty mb-2">Consultation end time</p>
                <Field
                  name="endHour"
                  component={SelectInput}
                  placeholder="End Time"
                  options={HOURS_OPTIONS.filter(
                    ({ value }) => Number(value) > Number(values.startHour)
                  )}
                  isDisabled={!values.startHour}
                />
              </div>
            </div>
            <div className="pt-3 flex justify-between space-x-5 lg:px-5 text-dusty items-center">
              {isEditForm ? (
                <button
                  type="button"
                  className="text-xs text-valencia"
                  onClick={removeSlot}
                >
                  REMOVE SLOT
                </button>
              ) : (
                <button type="button" onClick={closeSlotForms}>
                  Cancel
                </button>
              )}
              <Button
                onClick={handleSubmit}
                label={isEditForm ? 'Edit Slot' : 'Add Slot'}
                className="text-xs w-1/2"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

AddSlot.propTypes = {
  addSlotIsOpen: PropTypes.bool,
  service: PropTypes.object.isRequired,
  addRecurringSlot: PropTypes.bool.isRequired,
  addRecurringSlotIsOpen: PropTypes.bool,
  closeSlotForms: PropTypes.func.isRequired,
  editSlotIsOpen: PropTypes.bool,
  currentSlot: PropTypes.object,
  currentSlotId: PropTypes.number,
};

AddSlot.defaultProps = {
  addSlotIsOpen: false,
  addRecurringSlotIsOpen: false,
  editSlotIsOpen: false,
  currentSlot: undefined,
  currentSlotId: null,
};

export default AddSlot;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { iconClockTeal } from 'assets';
import { Badge, Card, Picture } from 'components/UI';
import { displayTimeFormat, displayDayDateFormat } from 'utils/datetime';
import { urlHandler } from 'utils/helper';
import qs from 'query-string';
import { BackgroundSection, labelBg } from '../LandingPage/common';
import MemberAccessBadge from './MemberAccessBadge';

const EventCard = ({ events, eventsPath }) => {
  const {
    id,
    city,
    name,
    image_url: imageUrl,
    start_at: startAt,
    end_at: endAt,
    current_user_rsvped: rsvped,
    event_category: eventCategory,
    time_zone: timeZone,
    member_access: memberAccess,
  } = events;
  const eventType = eventCategory?.name || 'Festival';
  const eventTypeUrl = `${eventsPath}?${qs.stringify({ category: eventType })}`;
  const startDate = displayDayDateFormat(startAt, timeZone);
  const endDate = displayDayDateFormat(endAt, timeZone);
  const time = () => {
    const start = displayTimeFormat(startAt);
    return endAt > startAt && startDate === endDate
      ? `${start} - ${displayTimeFormat(endAt)}`
      : start;
  };

  return (
    <div className="relative z-0">
      <Link to={`${eventsPath}/${id}`}>
        <Card className="w-full mb-10 sm:mb-0 h-full">
          <BackgroundSection
            backgroundImage={`url(${urlHandler(imageUrl)})`}
            className="relative bg-no-repeat bg-cover bg-center h-56 rounded-t-lg"
          >
            <div className="flex justify-between">
              <Link to={eventTypeUrl}>
                <Badge
                  bg={labelBg(eventType)}
                  className="absolute z-10 ml-4 mt-4"
                >
                  {eventType}
                </Badge>
              </Link>
              {rsvped && (
                <div className="flex items-center justify-center mr-4 mt-4 bg-brilliantGreen text-white h-8 w-20 rounded-full">
                  Booked
                </div>
              )}
            </div>
          </BackgroundSection>
          <div className="p-4">
            <div className="flex text-boulder text-sm mb-3">
              <span className="flex-1 border-r border-easter pr-2 text-easter">
                {startDate}
              </span>
              <span className="flex items-center flex-1 pl-2 text-easter">
                <Picture src={iconClockTeal} className="w-4 mr-2 text-easter" />
                {time()}
              </span>
            </div>
            <h3 className="h-12 truncate font-lynstone">{name}</h3>
            <div className="flex justify-between mb-3 mt-1">
              <p className="text-boulder font-semibold">
                {city?.name || 'Global'}
              </p>
              {!!memberAccess && (
                <MemberAccessBadge memberAccess={memberAccess} />
              )}
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};

EventCard.propTypes = {
  events: PropTypes.object.isRequired,
  eventsPath: PropTypes.string,
};

EventCard.defaultProps = {
  eventsPath: '/events',
};

export default EventCard;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchCount() {
  try {
    const response = yield call(api.fetchCount);
    yield put(Actions.fetchCountSuccess(response.data));
  } catch (error) {
    yield put(Actions.fetchCountFail(error));
  }
}

function* watchFetchCount() {
  yield takeLatest(Actions.FETCH_COUNT_REQUEST, fetchCount);
}

export default function* getCount() {
  yield all([fork(watchFetchCount)]);
}

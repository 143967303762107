import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from 'components/UI';
import { displaySubscriptionDateFormat } from 'utils/datetime';
import { urlHandler } from 'utils/helper';
import { BackgroundSection } from '../../LandingPage/common';
import MemberAccessBadge from '../MemberAccessBadge';

const PerkCard = ({ perk }) => {
  const {
    id,
    name,
    summary,
    member_access: memberAccess,
    image_url: img,
    end_date: expiry,
    promo_code: promo,
  } = perk;

  return (
    <Link to={id}>
      <Card className="w-full flex overflow-hidden h-48">
        <div className="w-1/3 relative">
          <BackgroundSection
            backgroundImage={`url(${urlHandler(img)})`}
            className="bg-no-repeat bg-cover bg-center h-full relative"
          />
          <div className="m-4 absolute top-0 left-0">
            <MemberAccessBadge memberAccess={memberAccess} />
          </div>
        </div>
        <div className="w-full p-4 pl-6 flex">
          <div className="w-full">
            <h3 className="font-semibold mb-2 break-normal font-lynstone">
              {name}
            </h3>
            <div className="text-dusty overflow-y-scroll">{summary}</div>
          </div>
          <div className="w-2/5 flex justify-between flex-col items-end text-right text-dusty">
            <>
              <p className="text-sm">Promo Code</p>
              <p className="text-easter text-md">{promo}</p>
            </>
            <div className="text-xs mt-auto">
              {`Expires ${displaySubscriptionDateFormat(expiry)}`}
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
};

PerkCard.propTypes = {
  perk: PropTypes.object.isRequired,
};

export default PerkCard;

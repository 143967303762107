import React, { useEffect, useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import SendBird from 'sendbird';
import { ChatList, MessageList, Input } from 'react-chat-elements';
import { Button } from 'components/UI';
import 'react-chat-elements/dist/main.css';

const Chat = () => {
  const ref = useRef();
  const [currentFocus, setCurrentFocus] = useState(null);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const channels = useSelector(Selectors.getChannels);
  const unread = useSelector(Selectors.getUnreadCount);
  const messages = useSelector(Selectors.getMessages) || [];
  const sb = SendBird.getInstance();

  useEffect(() => {
    dispatch(Actions.listChannels());
    if (sb) {
      const ChannelHandler = new sb.ChannelHandler();
      ChannelHandler.onMessageReceived = (channel) => {
        dispatch(Actions.listChannels());
        dispatch(Actions.listMessages(channel));
      };
      sb.addChannelHandler('ROOM_MESSAGE_HOOK', ChannelHandler);
    }
    return () => {
      if (sb) {
        sb.removeChannelHandler('ROOM_MESSAGE_HOOK');
      }
    };
  }, [dispatch, sb]);

  useEffect(() => {
    if (channels[0]) {
      const { channel, recipient } = channels[0];
      setCurrentFocus({ channel, recipient });
      channel.markAsRead();
    }
  }, [channels]);

  useEffect(() => {
    if (currentFocus !== null) {
      const { channel } = currentFocus;
      dispatch(Actions.listMessages(channel));
    }
  }, [dispatch, currentFocus]);

  const onSubmit = () => {
    const { channel } = currentFocus;
    ref.current.clear();
    dispatch(Actions.sendMessage(channel, message));
    setMessage('');
  };

  return (
    <div className="flex max-h-screen">
      <div className="flex flex-col bg-violet text-white min-h-screen p-4 w-64">
        <h1>Chat</h1>
        <span className="test-xs">{unread} unread messages</span>
        <ChatList
          onClick={({ channel, recipient }) => {
            setCurrentFocus({ channel, recipient });
            channel.markAsRead();
          }}
          className="chat-list"
          dataSource={typeof channels === 'undefined' ? [] : channels}
        />
      </div>
      <div className="flex relative flex-1 overflow-y-scroll p-12">
        <MessageList
          className="w-full"
          lockable
          toBottomHeight="100%"
          dataSource={messages}
        />
        <Input
          ref={ref}
          name="message"
          placeholder="Type a message..."
          multiline
          className="border border-gray-200 absolute bottom-0 left-0 right-0"
          onChange={(event) => setMessage(event.target.value)}
          onKeyUp={(event) => {
            if (event.ctrlKey && event.key === 'Enter') onSubmit();
          }}
          rightButtons={
            <Button label="Send" ml={10} type="submit" onClick={onSubmit} />
          }
        />
      </div>
    </div>
  );
};

Chat.propTypes = {};

export default Chat;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchConsultationSlots({ id, date }) {
  try {
    const response = yield call(api.fetchConsultationSlots, id, { date });
    yield put(Actions.fetchConsultationSlotsSuccess(response.data));
  } catch (error) {
    yield put(Actions.fetchConsultationSlotsFail(error));
  }
}

function* watchFetchConsultationSlots() {
  yield takeLatest(Actions.FETCH_SLOTS_REQUEST, fetchConsultationSlots);
}

export default function* dates() {
  yield all([fork(watchFetchConsultationSlots)]);
}

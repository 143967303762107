const NAME = 'PERK/REDEEM_PERK_';

export const REDEEM_PERK_REQUEST = `${NAME}REQUEST`;
export const REDEEM_PERK_SUCCESS = `${NAME}SUCCESS`;
export const REDEEM_PERK_FAIL = `${NAME}FAIL`;

export const redeemPerk = (id, callbacks) => ({
  type: REDEEM_PERK_REQUEST,
  id,
  callbacks,
});

export const redeemPerkSuccess = (data) => ({
  type: REDEEM_PERK_SUCCESS,
  data,
});

export const redeemPerkFail = (error) => ({
  type: REDEEM_PERK_FAIL,
  error,
});

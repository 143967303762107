const map = require('lodash/map');
const fromPairs = require('lodash/fromPairs');
const plugin = require('tailwindcss/plugin');
const { spacing } = require('tailwindcss/defaultTheme');

const basePlugin = plugin(({ addBase, config }) => {
  const boldChap = {
    fontWeight: 700,
    fontFamily: 'Chap',
  };

  addBase({
    h1: {
      fontSize: config('theme.fontSize.2xl'),
      ...boldChap,
    },
    h2: {
      fontSize: config('theme.fontSize.xl'),
      ...boldChap,
    },
    h3: {
      fontSize: config('theme.fontSize.lg'),
      ...boldChap,
    },
    h4: {
      fontSize: config('theme.fontSize.md'),
      ...boldChap,
    },
    h5: {
      fontSize: config('theme.fontSize.md'),
      ...boldChap,
      fontWeight: 600,
    },
    h6: {
      fontSize: config('theme.fontSize.base'),
      ...boldChap,
      fontWeight: 600,
    },
  });
});

const gradientsPlugin = plugin(
  ({ addUtilities, e, theme, variants }) => {
    const gradients = theme('gradients', {});
    const gradientVariants = variants('gradients', []);

    const utilities = map(gradients, ([start, end, deg = 0], name) => ({
      [`.bg-gradient-${e(name)}`]: {
        backgroundImage: `linear-gradient(${deg}deg, ${start}, ${end})`,
      },
    }));

    addUtilities(utilities, gradientVariants);
  },
  {
    theme: {
      gradients: (theme) => ({
        'turqoise-violet': [
          theme('colors.turqoise'),
          theme('colors.violet.default'),
          163.89,
        ],
        'easter-bostonBlue': [
          theme('colors.easter'),
          theme('colors.bostonBlue'),
          149.62,
        ],
        'cyan-violet': [
          theme('colors.cyan'),
          theme('colors.violet.bright'),
          163.89,
        ],
        'violet-cyan': [
          theme('colors.violet.bright'),
          theme('colors.cyan'),
          163.89,
        ],
        'tory-cyan': [theme('colors.tory'), theme('colors.cyan'), 163.89],
        violet: [
          theme('colors.violet.dark'),
          theme('colors.violet.electric'),
          330.83,
        ],
        ebony: [theme('colors.ebony'), 'rgba(48, 45, 68, 0)', 180],
        'white-hide': [
          'rgba(251,254,255,0.52)',
          `${theme('colors.white')} 25%`,
          180,
        ],
        'turqoise-violet-light': [
          theme('colors.violet.light'),
          theme('colors.bostonBlue'),
          154.71,
        ],
        'violet-valencia': [
          theme('colors.violet.light'),
          theme('colors.valencia'),
          163.89,
        ],
        'dusty-silver': [theme('colors.dusty'), theme('colors.silver'), 163.89],
      }),
    },
    variants: {
      gradients: ['responsive', 'hover'],
    },
  }
);

const borderGradientsPlugin = plugin(
  ({ addUtilities, e, theme, variants }) => {
    const gradients = theme('gradients', {});
    const gradientVariants = variants('gradients', []);

    const utilities = map(gradients, ([start, end, deg], name) => ({
      [`.border-gradient-${e(name)}`]: {
        borderImage: `linear-gradient(${deg}deg, ${start}, ${end})`,
        borderImageSlice: '1',
      },
    }));

    addUtilities(utilities, gradientVariants);
  },
  {
    variants: {
      borderGradients: ['responsive', 'hover'],
    },
  }
);

const textShadowPlugin = plugin(({ addUtilities, e, theme, variants }) => {
  const utilities = fromPairs(
    map(theme('textShadow'), (value, modifier) => {
      const className =
        modifier === 'default'
          ? 'text-shadow'
          : `${e(`text-shadow-${modifier}`)}`;
      return [
        `.${className}`,
        {
          'text-shadow': value,
        },
      ];
    })
  );
  addUtilities(utilities, variants('textShadow'));
});

module.exports = {
  purge: ['./src/**/*.jsx', './src/**/*.js', './src/**/*.html'],
  theme: {
    extend: {
      colors: {
        black: '#1d1f1f',
        pureWhite: '#ffffff',
        white: '#fbfeff',
        offwhite: '#f2f2f2',
        sigmaBlue: {
          default: '#7fcfe0',
          dark: '#46bbd4',
        },
        turqoise: '#28ebd5',
        cyan: '#40fffe',
        bostonBlue: '#399bb1',
        brilliantGreen: '#47d47d',
        violet: {
          default: '#695cb6',
          light: '#8372e6',
          bright: '#8628eb',
          dark: '#6a3093',
          electric: '#a044ff',
        },
        // shades of blue
        rock: '#120335',
        tory: '#0e5ca4',
        boston: '#3896ab',
        easter: '#47bad4',
        twilight: '#f4fdff',
        lily: '#e9fbff',
        squeeze: '#eaf1f7',
        // shades of gray
        ebony: '#302d44',
        martini: '#37344f',
        mulled: '#4d4966',
        boulder: '#7a7a7a',
        gull: '#99a7b7',
        dusty: '#9e9d9d',
        alto: '#dedede',
        silver: '#c6c6c6',
        concrete: '#f3f3f3',
        // shades of red
        pippin: '#ffdddd',
        valencia: '#d44747',
        'black-cover': 'rgba(48,45,68,0.9)',
      },
      backgroundSize: {
        full: '100% 100%',
      },
      fontFamily: {
        lynstone: 'Lynstone',
        chap: 'Chap',
      },
      fontSize: {
        xxs: '12px',
        xs: '13px',
        sm: '14px',
        base: '16px',
        md: '18px',
        lg: '21px',
        '2lg': '24px',
        xl: '36px',
        '2xl': '48px',
        '3xl': '60px',
      },
      spacing: {
        '100vw': '100vw',
        '30': '7.5rem',
      },
      minHeight: {
        ...spacing,
      },
      maxHeight: {
        '80': '20rem',
        '104': '26rem',
        '140': '35rem',
      },
      margin: {
        '30': '7.5rem',
      },
      height: {
        '2/3': '66%',
        '1/2': '50%',
        '2/5': '40%',
        '17/20': '85%',
        '9/10': '90%',
        '30': '7.5rem',
        '104': '26rem',
        '140': '35rem',
      },
      minWidth: {
        '100': '25rem',
        '80': '20rem',
      },
      maxWidth: {
        '60': '15rem',
        '100': '25rem',
        '120': '30rem',
        '140': '35rem',
        '200': '50rem',
      },
      width: {
        '17/20': '85%',
        '60': '15rem',
      },
      boxShadow: {
        button: '0 2px 4px 0 rgba(14,92,164,0.2)',
        card: '0 5px 15px 0 rgba(14,92,164,0.2)',
      },
      textShadow: {
        default: '0 1px 5px rgba(18,3,53,0.5)',
        none: 'none',
      },
      borderWidth: {
        '6': '6px',
      },
      transitionDuration: {
        '400': '400ms',
      },
      inset: {
        '1/2': '50%',
      },
    },
  },
  variants: {
    borderStyle: ['responsive', 'last', 'first'],
    padding: ['responsive', 'first'],
    textShadow: ['responsive'],
  },
  plugins: [
    basePlugin,
    gradientsPlugin,
    borderGradientsPlugin,
    textShadowPlugin,
  ],
};

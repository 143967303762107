import React from 'react';
import PropTypes from 'prop-types';
import { iconNext, iconBack } from 'assets';
import Actions from 'actions';
import TextInput from 'components/Form/TextInput';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import notify from 'utils/notification';

const RequestForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const RequestForgotPasswordForm = ({ nextStep, setEmail }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="md:px-8">
      <h5 className="mt-8">Forgot Password?</h5>
      <p className="text-dusty mt-2 mb-8 text-sm font-light">
        Don’t worry, enter the email address associated with your account
        <br />
        and we will send a code to reset your password
      </p>
      <Formik
        onSubmit={({ email }) => {
          dispatch(
            Actions.forgotPassword(
              { email },
              {
                success: () => {
                  setEmail(email);
                  nextStep();
                },
                failure: ({ data: { error } }) => {
                  notify(
                    error?.response?.data?.error ||
                      'Email not found or something went wrong',
                    null,
                    'danger'
                  );
                },
              }
            )
          );
        }}
        validationSchema={RequestForgotPasswordSchema}
        initialValues={{
          email: '',
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              label="Email"
              name="email"
              component={TextInput}
              type="email"
              placeholder="Email"
            />
            <div className="flex justify-between mt-32 mb-8">
              <button
                type="button"
                className="uppercase text-dusty flex focus:outline-none"
                onClick={() => navigate('/login')}
              >
                <img
                  alt="check"
                  src={iconBack.default}
                  className="h-4 mt-1 mr-4"
                />
                Back
              </button>
              <button
                type="submit"
                className="uppercase text-easter flex focus:outline-none"
              >
                Continue
                <img
                  alt="check"
                  src={iconNext.default}
                  className="h-4 mt-1 ml-4"
                />
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

RequestForgotPasswordForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
};

export default RequestForgotPasswordForm;

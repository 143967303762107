import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components/UI/Loading';
import { currencyFormat } from 'utils/format';
import { CardElement } from '@stripe/react-stripe-js';
import classnames from 'classnames';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'formik';
import { SingleCheckboxInput } from 'components/Form';

const CARD_ELEMENT_STYLES = {
  style: {
    base: {
      color: '#718196',
      fontWeight: 600,
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0afbf',
      },
    },
    invalid: {
      color: '#fc8181',
      iconColor: '#fc8181',
    },
  },
};

const Review = ({
  setError,
  back,
  processing,
  pricePlan: { amount, interval },
  promoDetails,
  tier,
  isDowngraded,
  error,
}) => {
  const [disabled, setDisabled] = useState(true);
  const disabledSubmitState =
    processing || error || (disabled && !isDowngraded);
  const { amount_off: amountOff, percent_off: percentOff } = promoDetails;
  const subscriptionInterval = interval === 'year' ? 'Annual' : 'Monthly';
  const PRICE = Number(amount);
  let PROMO = 0;
  if (!isEmpty(promoDetails)) {
    if (!isNull(amountOff)) PROMO = amountOff / 100;
    if (!isNull(percentOff)) PROMO = PRICE * (percentOff / 100);
  }
  const TOTAL = PRICE - PROMO;
  const handleCardOnChange = (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  return (
    <div className="flex flex-col text-center items-center justify-center max-w-120">
      <h5 className="text-2lg text-ebony">Almost there...</h5>
      {isDowngraded ? (
        <p className="text-sm text-dusty mb-6 mt-2">
          Please confirm your payment below. Don’t worry, your transaction is
          secured through <b className="text-easter font-semibold">Stripe</b>
        </p>
      ) : (
        <p className="text-sm text-dusty mb-6 mt-2">
          {`Don't worry, your credit card will only be charged upon Approval of
          your Membership. Your transaction is secured through `}
          <b className="text-easter font-semibold">Stripe</b>
        </p>
      )}
      <div className="flex flex-col mb-4">
        <h5 className="mb-4 font-lynstone">{`${tier?.name} Membership Fee (${subscriptionInterval})`}</h5>
        <div className="flex justify-between mb-3">
          <p className="text-boulder">Amount</p>
          <p className="text-rock">{currencyFormat(PRICE)}</p>
        </div>
        <div className="flex justify-between">
          <p className="text-boulder">Discount</p>
          <p className="text-rock">
            -{' '}
            {isNull(percentOff) || isEmpty(promoDetails)
              ? currencyFormat(PROMO)
              : `${percentOff}% (${currencyFormat(PROMO)})`}
          </p>
        </div>
        <div className="flex justify-between items-center border-t-2 border-alto pt-2 mt-3">
          <p className="text-boulder">Total</p>
          <p className="font-semibold text-2lg text-easter">
            {currencyFormat(TOTAL)}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full p-4 bg-squeeze rounded">
        {!isDowngraded && (
          <>
            <p className="text-gull text-sm">Pay with credit/debit card</p>
            <CardElement
              options={CARD_ELEMENT_STYLES}
              onChange={handleCardOnChange}
              className="w-full bg-white px-4 py-3 my-2 rounded"
            />
          </>
        )}
        <button
          className={classnames(
            'flex justify-center w-full rounded bg-easter text-white text-lg py-2',
            {
              'bg-boulder opacity-100 cursor-not-allowed': disabledSubmitState,
            }
          )}
          type="submit"
          disabled={disabledSubmitState}
        >
          {processing ? (
            <Spinner color="#fff" height={20} />
          ) : (
            `Pay ${currencyFormat(TOTAL)}`
          )}
        </button>
      </div>
      <Field
        name="permission"
        component={SingleCheckboxInput}
        label={
          <div className="max-w-sm text-left">
            I authorise Gig Life Pro to send instructions to my financial
            institution to take payments from my card account for my Gig Life
            Pro membership, under the terms of my agreement with Gig Life Pro.
          </div>
        }
        className="mt-8"
      />
      <button
        className="uppercase text-dusty mt-4 hover:opacity-75"
        type="button"
        onClick={back}
      >
        Back to Previous Page
      </button>
    </div>
  );
};

Review.propTypes = {
  setError: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  isDowngraded: PropTypes.bool,
  pricePlan: PropTypes.object.isRequired,
  tier: PropTypes.object.isRequired,
  promoDetails: PropTypes.object,
  error: PropTypes.string,
};

Review.defaultProps = {
  promoDetails: {},
  isDowngraded: null,
  error: null,
};

export default Review;

import { all, fork } from 'redux-saga/effects';
import connect from './connect';
import channel from './channel';
import user from './user';
import users from './users';
import message from './message';

export default function* sendbird() {
  yield all([
    fork(connect),
    fork(channel),
    fork(user),
    fork(message),
    fork(users),
  ]);
}

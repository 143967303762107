import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* addEvent({ id, callbacks }) {
  try {
    yield call(api.addEvent, id);
    yield put(Actions.addEventSuccess());
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.addEventFail(error));
    if (typeof callbacks?.fail === 'function') {
      yield call(callbacks.fail);
    }
  }
}

function* watchAddEvent() {
  yield takeLatest(Actions.ADD_EVENT_REQUEST, addEvent);
}

export default function* add() {
  yield all([fork(watchAddEvent)]);
}

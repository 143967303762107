import Actions from 'actions';

function user(state = null, action) {
  switch (action.type) {
    case Actions.SIGN_IN_SUCCESS:
    case Actions.SET_USER:
    case Actions.FETCH_PROFILE_SUCCESS:
    case Actions.UPDATE_USER_SUCCESS:
      return action.data;
    case Actions.SIGN_OUT:
    case Actions.CLEAR_USER:
      return null;
    default:
      return state;
  }
}

export default user;

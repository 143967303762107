import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import styled from '@emotion/styled';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import ConsultantCard from './ConsultantCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BG_HEIGHT = 900;

const Background = styled.div`
  height: ${BG_HEIGHT}px;
`;

const SectionContainer = styled.section`
  margin-top: -${BG_HEIGHT - 55}px;
`;

const SliderArrow = (props) => {
  // eslint-disable-next-line react/prop-types
  const { currentSlide, icon, onClick, changeIndex, slideCount } = props;
  let slideCycle = currentSlide + changeIndex;
  if (slideCycle < 0) slideCycle = slideCount - 1;
  if (slideCycle === slideCount) slideCycle = 0;

  return (
    <div onClick={onClick} role="button" tabIndex={0} className="m-auto">
      {icon}
    </div>
  );
};

const Consultants = ({ consultants, scroll, consultantIndex }) => {
  const ConsultantsSlider = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      initialSlide: consultantIndex,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: (
        <SliderArrow
          icon={<ChevronRightIcon fontSize="large" className="text-mulled" />}
          changeIndex={1}
        />
      ),
      prevArrow: (
        <SliderArrow
          icon={<ChevronLeftIcon fontSize="large" className="text-mulled" />}
          changeIndex={-1}
        />
      ),
      className: 'flex',
      beforeChange: (_, next) => setActiveIndex(next),
      customPaging: (i) => (
        <a>
          <div
            className={classnames('rounded-full h-3 w-3', {
              'bg-sigmaBlue-dark': i === activeIndex,
              'bg-silver': i !== activeIndex,
            })}
          />
        </a>
      ),
    };

    return (
      <div id="consultant" className="container mx-auto">
        {consultants.length > 1 ? (
          <Slider {...settings}>
            {consultants.map((consultant) => (
              <ConsultantCard
                consultant={consultant}
                key={consultant.id}
                scroll={scroll}
              />
            ))}
          </Slider>
        ) : (
          <ConsultantCard
            consultant={consultants[0]}
            key={consultants[0].id}
            scroll={scroll}
          />
        )}
      </div>
    );
  };

  const memoizedSlider = useMemo(() => <ConsultantsSlider />, []);

  return (
    <>
      <Background />
      <SectionContainer className="mb-12 sm:mb-24 relative">
        <h2 className="text-center mb-2 sm:mb-6 font-semibold">
          Our Consultants
        </h2>
        <div className="container mx-auto sm:px-6">{memoizedSlider}</div>
      </SectionContainer>
    </>
  );
};

Consultants.propTypes = {
  consultants: PropTypes.array.isRequired,
  scroll: PropTypes.func.isRequired,
  consultantIndex: PropTypes.number.isRequired,
};

export default Consultants;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ReactCodeInput from 'react-code-input';

const StyledCodeInput = styled(ReactCodeInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    width: 60px;
    height: 80px;
    outline: none;
    margin: 4px;
    text-align: center;
    font-size: 32px;
    box-sizing: border-box;
    color: black;
    transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 99%,
      #dedede 1%
    );
    &:focus {
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 99%,
        #47bad4 1%
      );
    }
  }
`;

const CodeInput = (props) => {
  const {
    field: { name, value },
    form: { setFieldValue, submitForm },
    type,
    className,
    fieldLength,
    isValid,
  } = props;

  const onValueChange = (value) => {
    setFieldValue(name, value);
    if (value && value.length === fieldLength) {
      submitForm();
    }
  };

  const inputStyleInvalid = {
    borderBottom: '2px solid #d44747',
  };

  const inputProps = {
    ...props,
    className,
    id: name,
    name,
    onChange: onValueChange,
    type,
    value,
    isValid,
    fields: fieldLength,
    inputStyleInvalid,
  };

  return <StyledCodeInput {...inputProps} />;
};

CodeInput.propTypes = {
  form: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  fieldLength: PropTypes.number,
  isValid: PropTypes.bool,
};

CodeInput.defaultProps = {
  type: 'number',
  className: null,
  fieldLength: 6,
  isValid: true,
};

export default CodeInput;

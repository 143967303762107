import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* approveBooking({ id, callbacks }) {
  try {
    yield call(api.approveBooking, id);
    yield put(Actions.approveBookingSuccess());
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.approveBookingFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure);
    }
  }
}

function* watchApproveBooking() {
  yield takeLatest(Actions.APPROVE_BOOKING_REQUEST, approveBooking);
}

export default function* approve() {
  yield all([fork(watchApproveBooking)]);
}

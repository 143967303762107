import React from 'react';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs, Card, Button } from 'components/UI';
import { Form, Formik, Field } from 'formik';
import TextInput from 'components/Form/TextInput';
import * as Yup from 'yup';
import notify from 'utils/notification';
import FeaturedLayout from './common/FeaturedLayout';
import Apply from './common/Apply';

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  content: Yup.string().required('Required'),
});

const Contact = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.SEND_ENQUIRY_EMAIL_REQUEST])
  );
  return (
    <div>
      <FeaturedLayout>
        <div className="container sm:px-4 mx-auto z-0">
          <Breadcrumbs
            trails={[{ label: 'Contact Us', path: '/contacts' }]}
            publicRoute
          />
        </div>
        <div className="flex flex-col text-center text-white relative m-auto">
          <h1 className="text-3xl font-semibold">Contact Us</h1>
          <p className="text-lg">Need a hand? Just shoot us a message</p>
        </div>
      </FeaturedLayout>
      <div className="container mx-auto px-4 max-w-200 mb-8">
        <Card className="p-8 md:p-16">
          <Formik
            initialValues={{
              name: '',
              email: '',
              content: '',
            }}
            validationSchema={ContactSchema}
            onSubmit={(fields, { resetForm }) => {
              dispatch(
                Actions.sendEnquiryEmail(fields, {
                  success: () => {
                    notify('Message Sent', null);
                    resetForm();
                  },
                  failure: () => notify('Something went wrong', null, 'danger'),
                })
              );
            }}
          >
            <Form>
              <Field name="name" component={TextInput} placeholder="Name" />
              <Field name="email" component={TextInput} placeholder="Email" />
              <Field
                name="content"
                component={TextInput}
                placeholder="Message"
                type="textarea"
                showRemaining
                maxLength={500}
              />
              <div className="mt-12">
                <Button
                  className="block mx-auto sm:px-16 py-4"
                  label="Send Message"
                  type="submit"
                  isLoading={isLoading}
                />
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
      <Apply />
    </div>
  );
};

export default Contact;

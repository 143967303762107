import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchBookings() {
  try {
    const response = yield call(api.fetchBookings);
    yield put(Actions.fetchBookingsSuccess(response.data));
  } catch (error) {
    yield put(Actions.fetchBookingsFail(error));
  }
}

function* watchFetchBookings() {
  yield takeLatest(Actions.FETCH_BOOKINGS_REQUEST, fetchBookings);
}

export default function* bookings() {
  yield all([fork(watchFetchBookings)]);
}

import React from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import { Picture } from 'components/UI';
import {
  iconLeftArrow,
  iconRightArrow,
  imgReviewAyudita,
  imgReviewJeff,
  imgReviewStephen,
  imgReviewJustin,
  imgReviewTej,
  imgReviewClaire,
  imgReviewSarun,
  imgReviewNjTaylor,
} from 'assets';
import styled from '@emotion/styled';
import { BackgroundSection } from '../common';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ReviewPicture = styled(Picture)`
  max-height: 360px;
`;

const CONTENT = [
  {
    id: 'member-7',
    name: 'Sarun Pinyarat',
    position: 'CEO at Fungjai / Co-founder at Maho Rasop Festival',
    image: imgReviewSarun,
    quote:
      "As a member of GLP's AA program, I've cultivated numerous new connections through its platform and on-site engagements. Beyond these invaluable connections, GLP has also provided a wealth of industry insights and perks to participate in various partner events.",
  },
  {
    id: 'member-6',
    name: 'Claire Collins',
    position: 'Head of Music SXSW Sydney',
    image: imgReviewClaire,
    quote:
      'SXSW Sydney engaged Priya and her team as our Asian Advisory for our inaugural event and they provided us with valuable insights into the region, as well as significant connections with leading industry that resulted in a widely-praised networking event. They are effective, a pleasure to work with and lovely people as well! I look forward to our continuing relationship with GLP.',
  },
  {
    id: 'member-7',
    name: 'NJ Taylor',
    position: 'Pop/R&B Singer-Songwriter, Artist Coach',
    image: imgReviewNjTaylor,
    quote:
      'For a very long time I’ve been looking to connect with music industry professionals to help me explore the Asian market but had a really hard time finding any information about it. I magically discovered Gig Life Pro few months ago and it’s everything I’ve been looking for and more. It’s a great platform that facilitates connecting with music industry pros. Sarah and Priya are absolutely amazing; they are both a wealth of knowledge and not only they are super sweet but they are truly passionate about the industry, and incredibly helpful. Everything about Gig Life Pro is amazing – from one-on-one consultations, to super informative articles and videos. Furthermore, the articles and videos cover topics that are not usually discussed on most music blogs. Gig Life Pro is truly refreshing!',
  },
  {
    id: 'member-1',
    name: 'Ayudita Hariadi',
    position: 'Booking Manager, Ismaya Group Indonesia',
    image: imgReviewAyudita,
    quote:
      'I always enjoy Gig Life Pro virtual Happy Hours! Especially during this pandemic time, I have exciting catch up time with the community. Lots of sharing and learning. Community support is one of the things we need to get through this.',
  },
  {
    id: 'member-2',
    name: 'Jeff Bell',
    position: 'GM, International, Partisan Records, UK',
    image: imgReviewJeff,
    quote:
      "From a big picture perspective down to localised campaign execution, and everything in between, GLP has been an invaluable resource as we've developed key relationships, systems and infrastructure across the region to better serve the global footprint we strive for all of our artists.",
  },
  {
    id: 'member-3',
    name: 'Stephen Dowler',
    position: 'Brand Manager Asia Pacific, Monstercat',
    image: imgReviewStephen,
    quote:
      'Giglife has been instrumental in getting to know people from all over Asia in the music industry. Whether offline at a mixer surrounding a conference or online at a happy hour, Giglife has provided a relaxed, natural environment to forge relationships with players all over the region in a meaningful way.',
  },
  {
    id: 'member-4',
    name: 'Justin Sweeting',
    position: 'Magnetic Asia',
    image: imgReviewJustin,
    quote:
      'We’ve worked with Gig Life Pro on projects during both Clockenflap and Sonar Hong Kong.  Throughout, we have very much appreciated how well they have complemented as well as added value towards an aligned vision of bringing the industry together in meaningful ways.  They expertly tow that fine line of being able to professionally manage and create environments which are fun, friendly and highly effective in encouraging industry connections both within the region and beyond.',
  },
  {
    id: 'member-5',
    name: 'Tej Brar',
    position: 'Founder & Managing Director // Third Culture Entertainment',
    image: imgReviewTej,
    quote:
      "Gig Life Pro has been an incredible community to be a part of! Whether it's attending various festivals/events across Asia and building my network on-ground or through their virtual happy hours, i've been able to meet a lot of like minded individuals throughout the APAC region. Through one of the virtual happy hours I connected with Def Jam SEA which led to us signing Yung Raja for India, an incredible partnership made possible by GLP!",
  },
];

function SlideArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { currentSlide, icon, onClick, changeIndex, slideCount } = props;
  let slideCycle = currentSlide + changeIndex;
  if (slideCycle < 0) slideCycle = slideCount - 1;
  if (slideCycle === slideCount) slideCycle = 0;

  return (
    <BackgroundSection
      backgroundImage={`url(${CONTENT[slideCycle].image.default})`}
      className="flex bg-cover bg-center lg:mx-16 rounded mb-1 cursor-pointer"
      onClick={() => {
        onClick();
      }}
      role="presentation"
    >
      <div
        className={classnames(
          'flex items-center',
          'rounded lg:px-6',
          'bg-gradient-turqoise-violet opacity-75'
        )}
      >
        <Picture src={icon} className="w-12" />
      </div>
    </BackgroundSection>
  );
}

const SaySlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlideArrow icon={iconRightArrow} changeIndex={1} />,
    prevArrow: <SlideArrow icon={iconLeftArrow} changeIndex={-1} />,
    className: 'flex',
  };

  return (
    <div className="hidden sm:block container mx-auto px-6 mt-20">
      <h2 className="text-center mb-20">What Our Members Say</h2>

      <Slider {...settings}>
        {CONTENT.map(({ id, name, position, image, quote }) => (
          <div key={id}>
            <div className="grid md:grid-cols-5 items-center">
              <ReviewPicture
                src={image}
                className="p-5 sm:p-8 md:p-0 md:col-span-2"
              />
              <div className="p-4 md:p-8 md:col-span-3">
                <i className="font-light">{quote}</i>
                <div className="text-lg mt-4">{name}</div>
                <div>{position}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SaySlider;

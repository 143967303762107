import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* createEvent({ data, callbacks }) {
  try {
    const response = yield call(api.createEvent, data);
    yield put(Actions.createEventSuccess(response.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success);
    }
  } catch (error) {
    yield put(Actions.createEventFail(error));
    if (typeof callbacks?.failure === 'function') {
      const err = error?.response?.data?.error;
      yield call(
        callbacks.failure,
        err ? err[0] : 'Something went wrong, please try again later'
      );
    }
  }
}

function* watchCreateEvent() {
  yield takeLatest(Actions.CREATE_EVENT_REQUEST, createEvent);
}

export default function* create() {
  yield all([fork(watchCreateEvent)]);
}

const NAME = 'NEWSLETTER_SIGNUP';

export const NEWSLETTER_SIGNUP_REQUEST = `${NAME}/NEWSLETTER_SIGNUP_REQUEST`;
export const NEWSLETTER_SIGNUP_SUCCESS = `${NAME}/NEWSLETTER_SIGNUP_SUCCESS`;
export const NEWSLETTER_SIGNUP_FAIL = `${NAME}/NEWSLETTER_SIGNUP_FAIL`;

export const newsLetterSignUp = (data, successCallback, failureCallback) => ({
  type: NEWSLETTER_SIGNUP_REQUEST,
  data,
  successCallback,
  failureCallback,
});

export const newsLetterSignUpSuccess = () => ({
  type: NEWSLETTER_SIGNUP_SUCCESS,
});

export const newsLetterSignUpFail = (error) => ({
  type: NEWSLETTER_SIGNUP_FAIL,
  error,
});

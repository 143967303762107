import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'actions';
import PropTypes from 'prop-types';
import Selectors from 'selectors';
import CreatedList from './CreatedList';
import AttendingList from './AttendingList';
import AddedList from './AddedList';
import EventForm from './EventForm';

const MyEvents = ({ eventsPath, handleTab }) => {
  const dispatch = useDispatch();
  const attendingEvents = useSelector(Selectors.eventAttendList);
  const addedEventsList = useSelector(Selectors.addedEvents);
  const createdEvents = useSelector(Selectors.createdEvents);
  const [items, setItems] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [addedEvents, setAddedEvents] = useState(addedEventsList);
  const isLoadingAttend = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_ATTENDING_EVENTS_REQUEST])
  );
  const isLoadingAdded = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_ADDED_EVENTS_REQUEST])
  );
  const isLoadingCreated = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_CREATED_LIST_REQUEST])
  );

  useEffect(() => {
    dispatch(Actions.fetchAttendingEvents());
    dispatch(Actions.fetchAddedEvents());
    dispatch(Actions.fetchCreatedEvents());
  }, [dispatch]);

  useEffect(() => {
    setItems(attendingEvents);
  }, [attendingEvents]);

  useEffect(() => {
    setAddedEvents(addedEventsList);
  }, [addedEventsList]);

  const showForm = (id) => {
    setUpdateId(id);
    setIsFormOpen(true);
  };
  const hideForm = () => {
    setUpdateId(null);
    setIsFormOpen(false);
  };

  const updateAttending = (eventId) => {
    dispatch(
      Actions.fetchAttendingEventsSuccess(
        items.filter(({ id }) => id !== eventId)
      )
    );
  };

  const removeEvent = (eventId) =>
    dispatch(
      Actions.removeEvent(eventId, {
        success: setAddedEvents(addedEvents.filter(({ id }) => id !== eventId)),
      })
    );

  return (
    <div className="pt-10 sm:pt-20 sm:mr-5">
      {isFormOpen ? (
        <EventForm
          hideForm={hideForm}
          updateId={updateId}
          isFormOpen={isFormOpen}
        />
      ) : (
        <>
          <AttendingList
            events={items}
            eventsPath={eventsPath}
            handleTab={handleTab}
            handleCancelBooking={updateAttending}
            isLoading={isLoadingAttend}
          />
          <AddedList
            events={addedEvents}
            eventsPath={eventsPath}
            isLoading={isLoadingAdded}
            handleRemoveEvent={removeEvent}
          />
          <CreatedList
            events={createdEvents}
            showForm={showForm}
            isLoading={isLoadingCreated}
            eventsPath={eventsPath}
          />
        </>
      )}
    </div>
  );
};

MyEvents.propTypes = {
  eventsPath: PropTypes.string.isRequired,
  handleTab: PropTypes.func.isRequired,
};

export default MyEvents;

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* createPerk({ params, callbacks }) {
  try {
    const { data } = yield call(api.createPerk, params);
    yield put(Actions.createPerkSuccess(data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, data);
    }
  } catch (error) {
    yield put(Actions.createPerkFail(error));
    if (typeof callbacks?.fail === 'function') {
      const err = error?.response?.data?.error;
      yield call(
        callbacks.fail,
        err ? err[0] : 'Something went wrong, please try again later'
      );
    }
  }
}

function* watchCreatePerk() {
  yield takeLatest(Actions.CREATE_PERK_REQUEST, createPerk);
}

export default function* create() {
  yield all([fork(watchCreatePerk)]);
}

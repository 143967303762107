import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/UI';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

const Panel = ({ parent, child, openPanel, isOpen }) => (
  <Card className="mb-4">
    <header
      className={classnames('cursor-pointer p-6 text-md', {
        'text-easter': isOpen,
      })}
      onClick={openPanel}
      role="presentation"
    >
      {parent}
    </header>
    <article
      className={classnames('py-6 mx-6 border-t border-alto', {
        hidden: !isOpen,
      })}
    >
      {typeof child === 'function' ? child() : ReactHtmlParser(child)}
    </article>
  </Card>
);

Panel.propTypes = {
  parent: PropTypes.string.isRequired,
  child: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  openPanel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const Accordion = ({
  className,
  content,
  parentKey,
  childKey,
  onChange,
  currentPanel,
}) => {
  const [activePanel, setPanel] = useState(0);

  useEffect(() => {
    if (currentPanel) setPanel(currentPanel);
  }, [currentPanel]);

  return (
    <div className={className}>
      {content.map((item, idx) => (
        <Panel
          openPanel={() => {
            if (onChange) onChange(idx);
            setPanel(idx);
          }}
          isOpen={activePanel === idx}
          parent={item[parentKey]}
          child={item[childKey]}
          key={item.id}
        />
      ))}
    </div>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array.isRequired,
  parentKey: PropTypes.string.isRequired,
  childKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  currentPanel: PropTypes.number,
};
Accordion.defaultProps = {
  className: '',
  onChange: null,
  currentPanel: null,
};

export default Accordion;

export const PREMIUM = 'Premium';
export const ALL_ACCESS = 'All Access';
export const FREE = 'Free';
export const GUEST = 'Guest';

export const ALL_MEMBERS = 'all_members';
export const PREMIUM_ALL_ACCESS = 'premium_and_all_access';
export const ALL_ACCESS_ONLY = 'all_access_only';

// ——————— Reward Types ————————————————

export const ALL_ACCESS_MEMBERSHIP = 'all_access_membership';
export const PREMIUM_MEMBERSHIP = 'premium_membership';

export const membershipCriteria = {
  [ALL_ACCESS]: [
    'Must be Management / C-Level / Owner',
    'Musician who owns and runs a business (label, management, etc.) for over 6 years.',
    'Minimum 6 years working in the music industry',
  ],
  [PREMIUM]: [
    'Music Business Student',
    'Musician (Producer, DJ, Songwriter)',
    'Working in the music industry',
  ],
  [FREE]: [
    'Music Business Student',
    'Musician (Producer, DJ, Songwriter)',
    'Working in the music industry',
  ],
};

export const membershipBenefits = {
  [ALL_ACCESS]: [
    'All the benefits of a PREMIUM membership',
    'Opportunity to speak on virtual and in-person panels, workshops, or conferences',
    'Opportunity to offer paid Consulting Services through the platform. Eligibility requirements apply',
    'Priority, and FREE access to Trade Missions',
    'Access to ALL ACCESS offline and online events with ALL ACCESS event perks',
    'Ability to invite guests to online and offline events',
    'Search and connect with ALL Gig Life Pro members',
  ],
  [PREMIUM]: [
    'All the benefits of a FREE membership',
    'Access to Location guides of local services and platforms',
    'Access to monthly networking Happy Hours with other PREMIUM members',
    'Limited acccess to events with ALL ACCESS members',
    'Invitations to offline Industry Mixers with PREMIUM event perks',
    'Get connected with Senior Level industry professionals (ALL ACCESS)',
    'Acccess to upload your own perks and promote your business to other members',
    '50% discount on consulting services',
    'Invitation to private Facebook community',
  ],
  [FREE]: [
    'Setup a profile and get noticed by music industry professionals',
    'Search for and connect with FREE Members via the chat feature',
    'Access to monthly webinars with service providers and industry experts',
    'Full access to articles',
    'Upload your own events and promote on our events platform',
  ],
};

export const experienceOptions = [
  'Student',
  'Musician',
  'Less than 6 years',
  'Industry ( 6+ years )',
];

export const partnershipServices = [
  {
    heading: 'Curation Consultant',
    content: [
      'Drive event strategy',
      'Curate panel topics, speakers and delegates',
      'Manage speaker and delegate communication and preparation',
      'On-site event support',
      'Partnered Events: MU:CON (Korea) and Music Matters (Singapore)',
    ],
  },
  {
    heading: 'Advisory Consultant / Trade Missions',
    content: [
      'Assist with an overall strategy',
      'Make connections with local professionals',
      'Additional: workshops, itinerary, on-ground management',
      'Partnered Events: SXSW Sydney (Australia)',
    ],
  },
  {
    heading: 'Gig Life Pro Explores',
    content: [
      'GLP will bring international industry professionals and creatives to your event',
      'GLP will host a Member event in your market around the event and include you as a partner',
      'Partnered Events: Wanderland (Philippines), Busan Rock Festival (Korea), Maho Rasop (Thailand)',
    ],
  },
  {
    heading: 'Event Sponsorships',
    content: ['Mixers, Afterparties, Panels, Showcases, Festivals'],
  },
];

export const generalEmails = [
  {
    department: 'Growth',
    email: 'growth@giglifepro.com',
  },
  {
    department: 'HR',
    email: 'hr@giglifepro.com',
  },
  {
    department: 'Members Experience',
    email: 'community@giglifepro.com',
  },
  {
    department: 'Partnerships',
    email: 'partnerships@giglifepro.com',
  },
  {
    department: 'Events',
    email: 'events@giglifepro.com',
  },
  {
    department: 'General Information',
    email: 'general@giglifepro.com',
  },
  {
    department: 'Tech',
    email: 'tech@giglifepro.com',
  },
  {
    department: 'Editorial',
    email: 'editorial@giglifepro.com',
  },
  {
    department: 'Media/PR',
    email: 'press@giglifepro.com',
  },
  {
    department: 'Finance',
    email: 'finance@giglifepro.com',
  },
];

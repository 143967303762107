import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, Button, Modal } from 'components/UI';
import { allAccessRewardsCard, premiumRewardsCard, pngLogoWhite } from 'assets';
import { BackgroundSection } from '../../LandingPage/common';
import RedeemModal from './RedeemModal';
import { ALL_ACCESS_MEMBERSHIP } from '../../../utils/constants';

const SubscriptionRewardCard = ({ subscriptionRedeemable, currentPoints }) => {
  const {
    redeemable_type: redeemableType,
    title,
    subtitle,
    points,
  } = subscriptionRedeemable;
  const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false);
  const closeRedeemModal = () => setIsRedeemModalOpen(false);
  const openRedeemModal = () => {
    setIsRedeemModalOpen(true);
  };
  const bgImage =
    redeemableType === ALL_ACCESS_MEMBERSHIP
      ? allAccessRewardsCard
      : premiumRewardsCard;

  const titleMap =
    redeemableType === ALL_ACCESS_MEMBERSHIP ? 'All Access' : 'Premium';
  return (
    <>
      <Card className="md:w-3/5 xl:w-2/5">
        <BackgroundSection
          backgroundImage={`url(${bgImage.default})`}
          className="relative bg-no-repeat bg-cover bg-center rounded-lg flex flex-col"
        >
          <section className="flex justify-between text-white text-shadow p-4">
            <section className="self-start flex space-x-3 items-center">
              <img
                src={pngLogoWhite.default}
                alt="glpLogo"
                className="h-10 xl:h-12"
              />
              <article className="flex flex-col">
                <p className="text-sm xl:text-lg uppercase">{titleMap}</p>
                <p className="text-sm font-hairline xl:text-lg">Membership</p>
              </article>
            </section>
            <article className="self-end text-center -mt-1 lg:-mt-2 flex flex-col">
              <p className="text-md xl:text-xl font-bold">{points}</p>
              <p className="text-sm xl:text-md self-end">Points</p>
            </article>
          </section>

          <p className="text-white text-sm xl:text-lg px-5 font-semibold uppercase">
            {title}
          </p>
          <p className="text-white text-xs xl:text-md px-5 pt-3">{subtitle}</p>
          <Button
            label="Redeem"
            className="flex items-center text-xs xl:text-md mb-3 h-8 xl:h-10 border-none xl:mt-1 self-end mr-4 mt-1 md:mt-3"
            outline
            onClick={openRedeemModal}
          />
        </BackgroundSection>
      </Card>
      <Modal
        isOpen={isRedeemModalOpen}
        content={
          <RedeemModal
            onCancel={() => setIsRedeemModalOpen(false)}
            currentPoints={currentPoints}
            redeemableImg={bgImage.default}
            closeRedeemModal={() => setIsRedeemModalOpen(false)}
            redeemable={subscriptionRedeemable}
          />
        }
        onDismiss={closeRedeemModal}
      >
        <div />
      </Modal>
    </>
  );
};

SubscriptionRewardCard.propTypes = {
  points: PropTypes.number,
  currentPoints: PropTypes.number,
  subscriptionRedeemable: PropTypes.object.isRequired,
};

SubscriptionRewardCard.defaultProps = {
  currentPoints: 0,
  points: 0,
};

const SubscriptionRewards = ({ subscriptionRedeemables, currentPoints }) => {
  return (
    <>
      <section className="flex flex-col md:ml-6 px-4 xl:px-0 xl:ml-10 lg:mt-10">
        <h3 className="font-semibold xl:mt-5">SUBSCRIPTION REWARDS</h3>
        <article className="flex flex-col mt-5 space-y-10 lg:flex-row lg:space-y-0 lg:space-x-5">
          {subscriptionRedeemables?.map((subscriptionRedeemable) => {
            return (
              <SubscriptionRewardCard
                subscriptionRedeemable={subscriptionRedeemable}
                currentPoints={currentPoints}
                key={subscriptionRedeemable.id}
              />
            );
          })}
        </article>
      </section>
    </>
  );
};

SubscriptionRewards.propTypes = {
  currentPoints: PropTypes.number,
  subscriptionRedeemables: PropTypes.array.isRequired,
};

SubscriptionRewards.defaultProps = {
  currentPoints: 0,
};

export default SubscriptionRewards;

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/UI';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { bgVisa } from 'assets';
import ReactHtmlParser from 'react-html-parser';
import { urlHandler } from 'utils/helper';
import { BackgroundSection, LockOverlay } from '../LandingPage/common';

const VenueColumn = ({ venues }) => {
  const { venueType, venue } = venues;
  return (
    <div className="flex flex-col">
      <div className="text-gray-600 text-md uppercase">{venueType}</div>
      {venue.map((item) => {
        const { name, location, link } = item;
        const imageUrl = item.image_url;
        return (
          <a className="my-5" href={link}>
            <Card className="flex items-center">
              <BackgroundSection
                className="w-40 h-30 bg-no-repeat bg-cover bg-center rounded-l-lg"
                backgroundImage={`url(${urlHandler(imageUrl)})`}
              />
              <div className="flex flex-col justify-center w-1/2 ml-5">
                <div className="font-semibold">{name}</div>
                <div className="text-gray-700 text-sm mt-1">{location}</div>
              </div>
              <ChevronRight
                style={{ fontSize: 50 }}
                className="m-auto text-gray-400"
              />
            </Card>
          </a>
        );
      })}
    </div>
  );
};

VenueColumn.propTypes = {
  venues: PropTypes.object.isRequired,
};

const VisaDetails = ({ visaInfo, showLockOverlay }) => {
  const { visaType, visaTimeline, visaConsulate, visaDetails } = visaInfo;

  const renderInfo = (title, content) => (
    <>
      <div className="text-dusty font-semibold uppercase mt-4">{title}</div>
      <div className="text-sm">{ReactHtmlParser(content)}</div>
    </>
  );

  return (
    <div className="flex flex-col container px-6 mx-auto py-10">
      <div className="font-chap text-xl mb-5">Visa Process</div>
      <Card className="flex sm:flex-row flex-col w-full sm:mx-auto px-5 md:px-0">
        <BackgroundSection
          className="bg-no-repeat bg-cover bg-center rounded-l-lg sm:w-1/2 hidden sm:block"
          backgroundImage={`url(${bgVisa.default})`}
        />
        <div className="flex flex-col sm:w-1/2 w-full justify-center my-10 relative sm:px-4 overflow-x-hidden">
          <LockOverlay
            showLockOverlay={showLockOverlay}
            className="h-9/10 px-5 left-0"
            isPaidContent
          />
          <div className="text-dusty font-semibold uppercase">Visa Type</div>
          <div className="flex flex-col">
            <h4 className="font-lynstone">
              Work Permit
              <ChevronRight
                style={{ fontSize: 40 }}
                className="mr-10 text-gray-400"
                fontSize="large"
              />
            </h4>
            <div className="text-sm">{ReactHtmlParser(visaType)}</div>
          </div>
          {renderInfo('Timeline', visaTimeline)}
          {renderInfo('Consulate Visit', visaConsulate)}
          {renderInfo('Details', visaDetails)}
        </div>
      </Card>
    </div>
  );
};

VisaDetails.propTypes = {
  visaInfo: PropTypes.object.isRequired,
  showLockOverlay: PropTypes.bool.isRequired,
};

export default VisaDetails;

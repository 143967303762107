import React, { useState } from 'react';
import Actions from 'actions';
import useQuery from 'hooks/useQuery';
import { Formik, Field } from 'formik';
import TextInput from 'components/Form/TextInput';
import { Button, ErrorContainer } from 'components/UI';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import AuthLayout from './common/AuthLayout';
import SuccessScreen from './Activate/SuccessScreen';

const PasswordSchema = Yup.object({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password must match')
    .required('Confirm password is required'),
});

const Activate = () => {
  const { email, inviteCode } = useQuery();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  if (success)
    return (
      <AuthLayout showBg disableScroll>
        <SuccessScreen />
      </AuthLayout>
    );

  return (
    <AuthLayout>
      <div className="flex flex-col my-8">
        <h5 className="text-2lg">Create Your Password</h5>
        <p className="text-sm text-dusty mb-16 max-w-120 mt-4">
          Welcome to Gig Life Pro <b>{email}</b>, Please create your password to
          continue
        </p>
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          onSubmit={({ password }) => {
            const params = { email, password, invitation_code: inviteCode };

            dispatch(
              Actions.createPassword(params, {
                success: () => {
                  dispatch(
                    Actions.signIn(params, {
                      success: () => setSuccess(true),
                    })
                  );
                },
                failure: (error) =>
                  setError(error.response.data.error || 'Something Went Wrong'),
              })
            );
          }}
          validationSchema={PasswordSchema}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="password"
                component={TextInput}
                placeholder="Password"
                type="password"
              />
              <Field
                name="confirmPassword"
                component={TextInput}
                placeholder="Confirm Password"
                type="password"
              />
              <ErrorContainer error={error} />
              <div className="mt-12">
                <Button
                  className="block mx-auto"
                  label="Confirm Password"
                  type="submit"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

Activate.propTypes = {};

export default Activate;

import React, { useState } from 'react';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'react-query';
import api from 'api';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, Picture, Modal, Button } from 'components/UI';
import AddToCalendar from 'components/UI/AddToCalendar';
import { Spinner } from 'components/UI/Loading';
import { modalHeader, createdEventsEmpty } from 'assets';
import { Field, Formik, Form } from 'formik';
import { TextInput } from 'components/Form';
import notify from 'utils/notification';
import { BackgroundSection } from '../../LandingPage/common';
import CancelModal from './CancelModal';
import BookingCard from './BookingCard';

const CustomerBookings = ({ bookings, isLoading }) => {
  const dispatch = useDispatch();

  const [calendarModal, setCalendarModal] = useState(false);
  const closeCalendarModal = () => setCalendarModal(false);

  const [modalStatus, showModal] = useState(false);
  const closeModal = () => showModal(false);

  const [cancelModalStatus, showCancelModal] = useState(false);
  const closeCancelModal = () => showCancelModal(false);

  const [currentBooking, setBooking] = useState({});

  const updateBookingsData = () =>
    setTimeout(() => dispatch(Actions.fetchServiceBookings(), 300));

  const { data: completedServicesData } = useQuery(
    'completedServicesData',
    () => api.fetchCompletedServices()
  );
  const completedServicesDataLength = completedServicesData?.data?.length || 0;

  const setCalendarBooking = (booking) => {
    setBooking(booking);
    setCalendarModal(true);
  };

  const acceptBooking = (booking) => {
    dispatch(
      Actions.approveBooking(booking.id, {
        success: () => {
          dispatch(Actions.fetchServiceBookings());
          notify('Booking has been accepted!');
        },
        failure: () => {
          notify('An error occured. Unable to accept booking.');
        },
      })
    );
  };

  const declineBooking = (booking, reason) => {
    dispatch(
      Actions.declineBooking(
        booking.id,
        { decline_reason: reason },
        {
          success: () => {
            dispatch(Actions.fetchServiceBookings());
            showModal(false);
            notify('Booking has been declined!');
          },
          failure: () => {
            notify('An error occured. Unable to decline booking.');
            showModal(false);
          },
        }
      )
    );
  };

  const { mutate: cancelServiceBookingMutate } = useMutation(
    (booking) => api.cancelConsultationServiceBooking(booking.id),
    {
      onSuccess: () => {
        showCancelModal(false);
        updateBookingsData();
        notify('Successfully cancelled');
      },
      onError: (e) => {
        showCancelModal(false);
        notify(
          e?.response?.data?.error ||
            'Something went wrong, please try again later',
          null,
          'danger'
        );
      },
    }
  );

  const showDeclineModal = (booking) => {
    setBooking(booking);
    showModal(true);
  };

  const showCancelBookingModal = (booking) => {
    setBooking(booking);
    showCancelModal(true);
  };

  if (isLoading && bookings.length === 0) {
    return <Spinner />;
  }

  return (
    <>
      {bookings.length > 0 ? (
        <div className="mx-4 sm:mx-0">
          {bookings.map((booking) => (
            <BookingCard
              key={booking.id}
              booking={booking}
              cancelServiceBookingMutate={() => showCancelBookingModal(booking)}
              acceptBooking={() => acceptBooking(booking)}
              declineBooking={() => showDeclineModal(booking)}
              onClickCalendar={() => setCalendarBooking(booking)}
              declineRescheduleMutate={() => declineBooking(booking)}
              isConsultantTab
            />
          ))}
        </div>
      ) : (
        <Card className="mx-auto border-t-2 p-10 text-center mt-5">
          <div className="m-auto text-center">
            <Picture
              src={createdEventsEmpty}
              className="py-8 w-2/5 sm:w-1/6 m-auto"
            />
            <h3 className="font-semibold">No upcoming appointments</h3>
            <p className="text-dusty">
              Your appointment with your customers will appear here
            </p>
          </div>
        </Card>
      )}

      {completedServicesDataLength > 0 && (
        <>
          <h3 className="font-semibold sm:pb-0 mt-10 mx-4 sm:mx-0">
            PAST APPOINTMENTS
          </h3>
          {completedServicesData?.data?.map((data) => (
            <div
              className="flex-grow-0 flex-shrink-0 my-4 mx-4 sm:mx-0"
              key={data.id}
            >
              <BookingCard booking={data} key={data.id} isConsultantTab />
            </div>
          ))}
        </>
      )}
      <Modal
        isOpen={cancelModalStatus}
        content={
          <CancelModal
            cancelBooking={() => cancelServiceBookingMutate(currentBooking)}
            onCancel={() => showCancelModal(false)}
          />
        }
        onDismiss={closeCancelModal}
      >
        <div />
      </Modal>
      <Modal
        isOpen={modalStatus}
        content={
          <DeclineModal
            onDecline={(values) =>
              declineBooking(currentBooking, values.reason)
            }
            onCancel={() => showModal(false)}
          />
        }
        onDismiss={closeModal}
      >
        <div />
      </Modal>
      <Modal
        isOpen={calendarModal}
        content={
          <div className="w-full p-4 rounded bg-white">
            <AddToCalendar
              start={currentBooking.start_at || ''}
              end={currentBooking.end_at || ''}
              title={`Appointment with ${currentBooking.consultant?.name}`}
              guestEmail={currentBooking.customer_email || ''}
              link={currentBooking.consultant?.link}
              showDescription
            />
          </div>
        }
        onDismiss={closeCalendarModal}
      >
        <div />
      </Modal>
    </>
  );
};

const DeclineModal = ({ onDecline, onCancel }) => {
  return (
    <div className="w-full px-2 md:p-0">
      <Card>
        <BackgroundSection
          backgroundImage={`url(${modalHeader.default})`}
          className={classnames(
            'relative bg-no-repeat bg-cover bg-center h-18 rounded-t-lg'
          )}
        >
          <h3 className="p-3 pt-4 md:p-4 md:pt-5 text-white font-hairline text-center">
            DECLINE BOOKING
          </h3>
        </BackgroundSection>
        <div className="p-4 sm:p-10 text-center flex flex-col justify-center items-center mb-4 sm:mb-8">
          <p>Are you sure you want to decline the consultation booking?</p>
          <p className="text-dusty mt-5">
            Please state your reason for declining and we will notify the
            customer.
          </p>
          <Formik onSubmit={onDecline} initialValues={{ reason: '' }}>
            {() => (
              <Form className="w-full mt-5">
                <Field
                  name="reason"
                  component={TextInput}
                  placeholder="Reason (eg. Sorry, my expertise only covers the Asian Market...)"
                  className="w-full"
                />
                <div className="mt-5">
                  <Button
                    className="mt-2 py-3 w-full max-w-xs"
                    label="YES, DECLINE BOOKING"
                    type="submit"
                    isPill
                  />
                  <Button
                    className="mt-2 py-3 w-full max-w-xs text-ebony border border-gray-100"
                    label="NO, DON'T DECLINE"
                    theme="plain"
                    onClick={onCancel}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </div>
  );
};

CustomerBookings.propTypes = {
  bookings: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

BookingCard.propTypes = {
  booking: PropTypes.object.isRequired,
  acceptBooking: PropTypes.func,
  declineBooking: PropTypes.func,
  onClickCalendar: PropTypes.func,
  cancelServiceBookingMutate: PropTypes.func,
};

BookingCard.defaultProps = {
  acceptBooking: null,
  declineBooking: null,
  onClickCalendar: null,
  cancelServiceBookingMutate: null,
};

DeclineModal.propTypes = {
  onDecline: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CustomerBookings;

import Actions from 'actions';

const initialState = Object.freeze({});

function create(state = initialState, action) {
  switch (action.type) {
    case Actions.CREATE_BOOKING_SUCCESS:
      return action.data;
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default create;

const NAME = 'USER/UPLOAD_USER_IMAGE';

export const UPLOAD_USER_IMAGE_REQUEST = `${NAME}_REQUEST`;
export const UPLOAD_USER_IMAGE_SUCCESS = `${NAME}_SUCCESS`;
export const UPLOAD_USER_IMAGE_FAIL = `${NAME}_FAIL`;

export const uploadUserImage = (data, callbacks) => ({
  type: UPLOAD_USER_IMAGE_REQUEST,
  data,
  callbacks,
});

export const uploadUserImageSuccess = () => ({
  type: UPLOAD_USER_IMAGE_SUCCESS,
});

export const uploadUserImageFail = (error) => ({
  type: UPLOAD_USER_IMAGE_FAIL,
  error,
});

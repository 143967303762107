import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, ErrorContainer, Footer } from 'components/UI';
import { Formik, Form } from 'formik';
import classnames from 'classnames';
import { iconNext, iconBack } from 'assets';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Actions from 'actions';

import Personal from './Personal';
import Playlist from './Playlist';
import Services from './Services';
import Work from './Work';

const CompletionProgress = ({ show }) => {
  if (show)
    return (
      <div className="bg-twilight shadow-button">
        <div className="flex flex-col container mx-auto px-6 py-2">
          <p className="text-md font-semibold">Complete your profile</p>
          <p className="text-sm text-boulder">
            Let’s complete your profile for other members to know you better and
            gain more credibility.
          </p>
        </div>
      </div>
    );
  return null;
};

CompletionProgress.propTypes = {
  show: PropTypes.bool.isRequired,
};

const FORM_STEPS = {
  personal: <Personal />,
  work: <Work />,
  services: <Services />,
  playlist: <Playlist />,
};

const CompleteProfile = ({ hasCompletedProfile, initialValues }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const currentSection =
    searchParams.get('section') || Object.keys(FORM_STEPS)[0];
  const initialStep = Object.keys(FORM_STEPS).indexOf(currentSection);
  const [activeStep, setActiveStep] = useState(initialStep);

  useEffect(() => {
    if (!hasCompletedProfile)
      setSearchParams({ section: Object.keys(FORM_STEPS)[activeStep] });
  }, [activeStep, setSearchParams, hasCompletedProfile]);

  const [error, setError] = useState(null);
  const isLastStep = activeStep === Object.keys(FORM_STEPS).length - 1;

  function handleOnSubmit(formValues, actions) {
    const {
      member_types: memberTypes,
      languages,
      rep_artists: repArtists,
      rep_events: repEvents,
      rep_venues: repVenues,
      users_services: userServices,
      users_experiences: userExperiences,
    } = formValues;

    const parsedFormValue = {
      ...formValues,
      member_type_ids: memberTypes.map((item) => item.value),
      languages: languages.map((item) => item.value),
      rep_artists: repArtists.split(', '),
      rep_events: repEvents.split(', '),
      rep_venues: repVenues.split(', '),
      users_services_attributes: userServices,
      users_experiences_attributes: userExperiences,
      completed_profile: true,
    };

    if (isLastStep) {
      dispatch(
        Actions.updateUser(parsedFormValue, {
          failure: (error) => {
            setActiveStep(0);
            const errorMsg = error?.response?.data?.error;
            setError(errorMsg ? errorMsg[0] : 'Failed to update profile');
          },
        })
      );
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      setError('');
    }
  }

  const FormButtons = () => (
    <Footer>
      <button
        type="button"
        className={classnames(
          'flex flex-1',
          'uppercase text-dusty hover:text-easter focus:outline-none',
          { invisible: activeStep < 1 }
        )}
        onClick={() => setActiveStep(activeStep - 1)}
      >
        <img alt="check" src={iconBack.default} className="h-4 mt-1 mr-4" />
        Back
      </button>
      <Button
        type="submit"
        className="text-xs md:text-base"
        label={
          <div className="flex">
            {isLastStep ? (
              'Complete Your Profile'
            ) : (
              <>
                Continue
                <img
                  alt="check"
                  src={iconNext.default}
                  className="h-4 mt-1 ml-4"
                />
              </>
            )}
          </div>
        }
      />
    </Footer>
  );

  return (
    <>
      <CompletionProgress show={!hasCompletedProfile} />
      <div className="container mx-auto px-6 mt-16 mb-24">
        <Card className="border-t-8 border-easter p-8">
          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            enableReinitialize
          >
            <Form>
              {Object.values(FORM_STEPS)[activeStep]}
              <ErrorContainer error={error} />
              <FormButtons />
            </Form>
          </Formik>
        </Card>
      </div>
    </>
  );
};

CompleteProfile.propTypes = {
  hasCompletedProfile: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
};

CompleteProfile.defaultProps = {
  hasCompletedProfile: true,
};

export default CompleteProfile;

/* eslint-disable camelcase */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { Select } from 'components/UI';
import { AuthContext } from 'utils/context';
import { nameFormat } from 'utils/format';
import { TextInput } from 'components/Form';
import { isEmpty } from 'lodash';
import { ALL_ACCESS, PREMIUM, FREE } from 'utils/constants';
import ChatInput from './ChatInput';

const MEMBER_GROUP_OPTIONS = Object.freeze([
  { label: 'All Premium and All Access members', id: 'All' },
  { label: `Only ${ALL_ACCESS} members`, id: ALL_ACCESS },
  { label: `Only ${PREMIUM} members`, id: PREMIUM },
  { label: `Only ${FREE} members`, id: FREE },
]);

const CreateForm = ({ selectChat, isGroup, recipient, closeModal }) => {
  const { isPro: userIsPro } = useContext(AuthContext);
  const [recipients, setRecipients] = useState([]);
  const [recipientGroup, setRecipientGroup] = useState();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_USERS_REQUEST])
  );
  let availableUsers = useSelector(Selectors.getSendbirdUsers);
  const defaultUser =
    recipient && availableUsers.find(({ sendbird_id: id }) => id === recipient);
  const payingUsers = availableUsers.filter(
    (user) => !user?.member_tier?.is_free_tier
  );

  if (!userIsPro && isGroup) {
    availableUsers = availableUsers.filter(
      (user) => user?.member_tier?.name === PREMIUM
    );
  }

  const groupOptions = () =>
    userIsPro ? MEMBER_GROUP_OPTIONS : MEMBER_GROUP_OPTIONS.slice(2);

  const submitForm = (name, recipients, msg, callback) => {
    if (
      isEmpty(msg) ||
      isEmpty(recipients) ||
      (isGroup && isEmpty(name)) ||
      (isGroup && recipients.length < 2 && !recipientGroup)
    )
      return;

    const groupRecipients = () => {
      const type = recipientGroup.id;
      if (type === 'All') return payingUsers;

      return availableUsers.filter((user) => user?.member_tier?.name === type);
    };
    const finalRecipients =
      isGroup && recipientGroup ? groupRecipients() : recipients;

    dispatch(
      Actions.createChannel(finalRecipients, msg, name, {
        success: (channel) => {
          callback();
          selectChat(channel);
          closeModal();
        },
      })
    );
  };

  useEffect(() => {
    if (defaultUser) setRecipients(defaultUser);
  }, [defaultUser]);

  useEffect(() => {
    if (isGroup && Array.isArray(recipients)) {
      if (!recipients.length) return setRecipientGroup();
      const selectedGroup = recipients.find(({ label }) => !!label);
      if (selectedGroup)
        setRecipientGroup(recipients.reverse().find(({ label }) => label));
    }
  }, [recipients, isGroup]);

  return (
    <div className="bg-white rounded p-4">
      <h3 className="font-lynstone">
        {isGroup ? 'Create group chat' : 'Send Direct Message'}
      </h3>
      <Formik initialValues={{ groupName: '' }}>
        {({ values, setErrors, setTouched }) => (
          <form>
            {isGroup && (
              <Field
                name="groupName"
                component={TextInput}
                placeholder="Group Name"
                className="mt-4"
              />
            )}
            <Select
              isMulti={isGroup}
              isLoading={isLoading}
              isDisabled={isLoading}
              options={
                isGroup ? [...groupOptions(), ...payingUsers] : availableUsers
              }
              getOptionLabel={({
                first_name: firstName,
                last_name: lastName,
                label,
              }) => label || nameFormat({ firstName, lastName })}
              getOptionValue={({ id }) => id}
              onChange={setRecipients}
              className="my-4"
              placeholder={
                isGroup ? 'Select group members' : 'Choose or search recipient'
              }
              defaultValue={defaultUser}
              value={recipientGroup}
            />
            <ChatInput
              onSubmit={(msg, callback) => {
                if (isGroup && !values.groupName) {
                  setTouched({ groupName: true }, false);
                  setErrors({ groupName: 'required' });
                } else {
                  submitForm(values.groupName, recipients, msg, callback);
                }
              }}
              recipient={!isGroup ? recipients : null}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

CreateForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectChat: PropTypes.func.isRequired,
  isGroup: PropTypes.bool.isRequired,
  recipient: PropTypes.string,
};

CreateForm.defaultProps = {
  recipient: null,
};

export default CreateForm;

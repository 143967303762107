import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { SelectInput } from 'components/Form';
import api from 'api';
import classnames from 'classnames';
import { Card, Button } from 'components/UI';
import { modalHeader } from 'assets';
import { useQuery, useMutation } from 'react-query';
import {
  displayConsultationDateFormat,
  displayTimeFormat,
} from 'utils/datetime';
import { BackgroundSection } from '../../LandingPage/common';

const RescheduleModal = ({ onCancel, consultantServiceId, bookingId }) => {
  const { data: consultantSlots } = useQuery('consultantSlots', () =>
    api.fetchConsultationSlots(consultantServiceId)
  );
  const [startAtError, setStartAtError] = useState(false);
  const [date, setDate] = useState();
  const [startAt, setStartAt] = useState(null);
  const dates = [
    ...new Set(
      consultantSlots?.data?.map((slot) => displayConsultationDateFormat(slot))
    ),
  ];
  const dateOptions = dates.map((item) => ({
    label: item,
    value: item,
  }));
  const dispatch = useDispatch();

  const updateBookingsData = () =>
    setTimeout(() => dispatch(Actions.fetchBookings(), 300));

  const { mutate: rescheduleBookingMutate } = useMutation(
    (bookingData) => api.rescheduleBooking(bookingId, bookingData),
    {
      onSuccess: () => {
        onCancel();
        updateBookingsData();
        notify('Successfully requested a reschedule');
      },
      onError: (e) => {
        showCancelModal(false);
        notify(
          e?.response?.data?.error ||
            'Something went wrong, please try again later',
          null,
          'danger'
        );
      },
    }
  );

  const rescheduleBooking = () => {
    if (!startAt) return setStartAtError(true);
    rescheduleBookingMutate({ start_at: startAt });
  };

  const StartAtOptions = () => {
    const slotBtn = (slot) => (
      <div
        className={classnames(
          'text-sm sm:text-base mr-4 rounded-full p-2 w-30 flex justify-center items-center mb-4',
          {
            'bg-gray-200 text-gray-500 cursor-pointer': startAt !== slot,
            'bg-easter text-white': startAt === slot,
          }
        )}
        onClick={() => setStartAt(slot)}
        role="presentation"
        key={slot}
      >
        {displayTimeFormat(slot)}
      </div>
    );

    return (
      <div className="mb-4">
        <div className="flex flex-row items-center flex-wrap">
          {consultantSlots?.data
            ?.filter((slot) => displayConsultationDateFormat(slot) === date)
            .map((slot) => slotBtn(slot))}
        </div>
        {!startAt && startAtError && (
          <div className="text-valencia h-4 mt-1 text-xs ml-3 font-light text-left">
            Required
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full px-2 md:p-0">
      <Card>
        <BackgroundSection
          backgroundImage={`url(${modalHeader.default})`}
          className={classnames(
            'relative bg-no-repeat bg-cover bg-center h-18 rounded-t-lg'
          )}
        >
          <h3 className="p-3 pt-4 md:p-4 md:pt-5 text-white font-hairline text-center">
            RESCHEDULE BOOKING
          </h3>
        </BackgroundSection>
        <div className="p-4 sm:p-10 text-center flex flex-col justify-center items-center mb-4 sm:mb-8">
          <p>Are you sure you want to reschedule the consultation booking?</p>
          <p className="text-dusty mt-5 md:w-3/4 mb-3">
            Please choose one of the available slots below
          </p>
          <div className="px-10">
            <Formik
              initialValues={{ date: '' }}
              onSubmit={rescheduleBooking}
              enableReinitialize
            >
              {({ setFieldValue, setFieldTouched, handleSubmit }) => (
                <>
                  <Form>
                    {dateOptions.length ? (
                      <>
                        <div className="mt-5 text-sm text-dusty text-left mb-2">
                          Choose available dates
                        </div>
                        <Field
                          name="date"
                          component={SelectInput}
                          placeholder="Choose Date"
                          options={dateOptions}
                          className="w-full"
                          onChange={({ value }) => {
                            setDate(value);
                            setStartAt();
                            setFieldValue('start_at', '');
                            setFieldTouched('start_at', false);
                          }}
                        />
                      </>
                    ) : (
                      !dateOptions && (
                        <div className="mb-6 text-red-600">
                          All dates are fully booked, please choose another
                          consultant to proceed
                        </div>
                      )
                    )}
                    {date && (
                      <>
                        <div className="mb-2 text-sm text-dusty text-left">
                          Choose start time (Slots below are shown in your local
                          time zone)
                        </div>
                        <StartAtOptions />
                      </>
                    )}
                  </Form>
                  <div className="mt-5">
                    <Button
                      className="mt-2 py-3 w-full max-w-xs"
                      label="RESCHEDULE BOOKING"
                      type="submit"
                      onClick={handleSubmit}
                      isPill
                    />
                    <Button
                      className="mt-2 py-3 w-full max-w-xs text-ebony border border-gray-100"
                      label="NO, DON'T RESCHEDULE"
                      theme="plain"
                      onClick={onCancel}
                    />
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </Card>
    </div>
  );
};

RescheduleModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  consultantServiceId: PropTypes.number.isRequired,
  bookingId: PropTypes.number.isRequired,
};

export default RescheduleModal;

const AUTH = 'AUTH/RESET_PASSWORD';

export const RESET_PASSWORD_REQUEST = `${AUTH}_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${AUTH}_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${AUTH}_FAIL`;

export const resetPassword = (data, callbacks = {}) => ({
  type: RESET_PASSWORD_REQUEST,
  data,
  callbacks,
});

export const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  data,
});

export const resetPasswordFail = (errors) => ({
  type: RESET_PASSWORD_FAIL,
  errors,
});

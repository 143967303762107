import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Close from '@material-ui/icons/Close';

const Modal = ({
  children,
  className,
  content,
  contentClassName,
  lightDismiss,
  onChange,
  isOpen,
  onDismiss,
  onChildClick,
}) => {
  const ref = useRef(null);
  const opened = useRef(false);
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, setOpen]);

  useEffect(() => {
    if (open && !opened.current) {
      opened.current = true;
    }
  }, [open]);

  useEffect(() => {
    const modal = ref.current;
    const ready = opened.current && modal;

    if (ready) document.body.appendChild(modal);

    return () => {
      if (ready) document.body.removeChild(modal);
    };
  });

  useEffect(() => {
    onChange(open);
    if (open) {
      document.body.classList.add('overflow-hidden');
    }

    return () => document.body.classList.remove('overflow-hidden');
  }, [open, onChange]);

  const dismissModal = () => {
    if (onDismiss) onDismiss();
    setOpen(false);
  };

  const renderContent = () => {
    if (typeof content === 'function') return content(setOpen);
    return content;
  };

  return [
    {
      ...children,
      props: {
        ...children.props,
        onClick: onChildClick || (() => setOpen(true)),
      },
    },
    <div
      key="modal"
      ref={ref}
      className={classnames(
        className,
        'flex top-0 bottom-0 right-0 left-0 modal fixed w-screen py-20 overflow-auto z-40',
        'duration-500 transition-all ease-in',
        { 'flex opacity-100': open },
        { 'hidden opacity-0': !open }
      )}
    >
      <div
        onClick={lightDismiss ? dismissModal : undefined}
        role="presentation"
        className="absolute top-0 right-0 bottom-0 left-0"
        style={{ zIndex: -1 }}
      />
      <div className="max-w-2xl flex flex-col m-auto transition-slow w-full transition-slow">
        <div className={contentClassName}>{open && renderContent()}</div>
      </div>

      <div className="absolute cursor-pointer mr-3 text-gray-500 right-0 top-0 text-xl">
        <Close onClick={dismissModal} role="presentation" fontSize="inherit" />
      </div>
    </div>,
  ];
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  content: PropTypes.any,
  contentClassName: PropTypes.string,
  lightDismiss: PropTypes.bool,
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
  onChildClick: PropTypes.func,
};

Modal.defaultProps = {
  className: 'bg-black-cover',
  content: null,
  contentClassName: '',
  lightDismiss: true,
  onChange: (v) => v,
  isOpen: false,
  onDismiss: null,
  onChildClick: null,
};

export default Modal;

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'utils/context';
import HeroSection from './Hero';
import SliderSection from './SaySlider';
import CommunitySection from './Community';
import FeaturesSection from './Features';
import PastPartnershipSection from './PastPartnerships';
import ApplySection from '../common/Apply';

const Home = () => {
  const { authenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) navigate('/dashboard');
  }, [authenticated, navigate]);

  return (
    <>
      <HeroSection />
      <CommunitySection />
      <SliderSection />
      <FeaturesSection />
      <PastPartnershipSection />
      <ApplySection />
    </>
  );
};

export default Home;

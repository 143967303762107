const NAME = 'ARTICLES';

export const FETCH_ARTICLES_REQUEST = `${NAME}/FETCH_ARTICLES_REQUEST`;
export const FETCH_ARTICLES_SUCCESS = `${NAME}/FETCH_ARTICLES_SUCCESS`;
export const FETCH_ARTICLES_FAIL = `${NAME}/FETCH_ARTICLES_FAIL`;

export const fetchArticles = (params) => ({
  type: FETCH_ARTICLES_REQUEST,
  params,
});

export const fetchArticlesSuccess = (data, page, isDefault) => ({
  type: FETCH_ARTICLES_SUCCESS,
  data,
  page,
  isDefault,
});

export const fetchArticlesFail = (error) => ({
  type: FETCH_ARTICLES_FAIL,
  error,
});

export const NAME = 'SENDBIRD';

export const SEND_MESSAGE_REQUEST = `${NAME}/SEND_MESSAGE_REQUEST`;
export const SEND_MESSAGE_SUCCESS = `${NAME}/SEND_MESSAGE_SUCCESS`;
export const SEND_MESSAGE_FAIL = `${NAME}/SEND_MESSAGE_FAIL`;

export const sendMessage = (channel, msg, recipientId, callbacks) => ({
  type: SEND_MESSAGE_REQUEST,
  channel,
  msg,
  recipientId,
  callbacks,
});

export const sendMessageSuccess = (data, recipientId) => ({
  type: SEND_MESSAGE_SUCCESS,
  data,
  recipientId,
});

export const sendMessageFail = (error) => ({
  type: SEND_MESSAGE_FAIL,
  error,
});

import Actions from 'actions';
import uniqBy from 'lodash/uniqBy';

const initialState = Object.freeze({ data: [], page: {}, default: [] });

function list(
  state = initialState,
  { type, data: articles, page, error, isDefault }
) {
  switch (type) {
    case Actions.FETCH_ARTICLES_REQUEST:
      return {
        ...initialState,
        data: state.data,
        default: state.default,
      };
    case Actions.FETCH_ARTICLES_SUCCESS:
      return {
        ...initialState,
        default:
          isDefault && page?.currentPage === 1 ? articles : state.default,
        page,
        data:
          page?.currentPage === 1
            ? articles
            : uniqBy([...state.data, ...articles], 'id'),
      };
    case Actions.FETCH_ARTICLES_FAIL:
      return {
        ...initialState,
        error,
      };
    default:
      return state;
  }
}

export default list;

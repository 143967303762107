import { takeLatest, all, fork, put, call, select } from 'redux-saga/effects';
import Actions from 'actions';
import Selectors from 'selectors';
import { setCacheExp } from 'utils/dataCache';
import api from 'api';

function* fetchConsultationService() {
  try {
    const service = yield select(Selectors.getConsultationService);
    if (new Date().getTime() < service.cacheExp) {
      yield put(Actions.fetchConsultationServiceSuccess(service));
    } else {
      const { data } = yield call(api.fetchConsultationService);
      yield put(
        Actions.fetchConsultationServiceSuccess({
          data: data || null,
          cacheExp: setCacheExp(10),
        })
      );
    }
  } catch (error) {
    yield put(Actions.fetchConsultationServiceFail(error));
  }
}

function* watchFetchConsultationService() {
  yield takeLatest(
    Actions.FETCH_CONSULTATION_SERVICE_REQUEST,
    fetchConsultationService
  );
}

export default function* fetch() {
  yield all([fork(watchFetchConsultationService)]);
}

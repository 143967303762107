import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import styled from '@emotion/styled';
import { width } from 'styled-system';
import { AuthContext } from 'utils/context';
import { ArticleCard } from './List';

const CardContainer = styled.div`
  ${width}
`;

const RelatedArticles = ({ articles, fromDashboardPage, articlesPath }) => {
  const { authenticated } = useContext(AuthContext);

  if (isEmpty(articles)) return null;
  return (
    <div
      className={classnames('container mx-auto pr-6 sm:pr-0 pl-6 py-16', {
        'pr-6': !authenticated,
      })}
    >
      {fromDashboardPage ? (
        <p className="font-semibold text-lg ml-2">Latest articles</p>
      ) : (
        <h3 className="font-semibold">Related Articles</h3>
      )}
      <div className="flex flex-no-wrap overflow-x-auto w-full pl-3">
        {articles.map((item) => (
          <CardContainer
            width="300px"
            className="flex-grow-0 flex-shrink-0 mr-10 my-4"
            key={item.id}
          >
            <ArticleCard article={item} articlesPath={articlesPath} />
          </CardContainer>
        ))}
      </div>
    </div>
  );
};

RelatedArticles.propTypes = {
  articles: PropTypes.array.isRequired,
  fromDashboardPage: PropTypes.bool,
  articlesPath: PropTypes.string,
};

RelatedArticles.defaultProps = {
  fromDashboardPage: false,
  articlesPath: '/articles',
};
export default RelatedArticles;

import React from 'react';
import PropTypes from 'prop-types';

import { referralIcon } from 'assets';
import notify from 'utils/notification';
import { Card, Button } from 'components/UI';
import { Spinner } from 'components/UI/Loading';

import HowAndWhatInfo from './HowAndWhatInfo';

const SucessfulReferralsInfo = ({ allAccessReferrals, premiumReferrals }) => {
  return (
    <section className="flex flex-col lg:w-1/2 pt-5 lg:pt-0">
      <p className="text-md text-gray-700 font-bold self-center md:text-lg">
        Successful Referrals
      </p>
      <article className="flex justify-around mt-2">
        <section className="flex flex-col items-center">
          <p className="text-3xl font-bold text-easter">{premiumReferrals}</p>
          <p className="text-md text-easter">Premium</p>
        </section>
        <section className="flex flex-col items-center">
          <p className="text-3xl font-bold text-violet">{allAccessReferrals}</p>
          <p className="text-md text-violet">All Access</p>
        </section>
      </article>
    </section>
  );
};

const ShareReferralInfo = ({ referralCode }) => {
  const referralLink = `${window.location.origin}/signup?referralCode=${referralCode}`;
  return (
    <section className="flex flex-col items-center lg:w-1/2 lg:border-r-2 lg:pr-5 lg:pl-5">
      <p className="text-md text-gray-700 font-bold md:text-lg xl:self-start xl:pl-24 xl:-mb-4">
        Share Referral Code
      </p>
      <article className="flex items-center justify-between ml-3 xl:ml-0 space-x-5 xl:space-x-5 mt-5 md:w-17/20 lg:w-full">
        <img
          src={referralIcon.default}
          alt="referral"
          className="h-12 xl:h-16 xl:-mt-10"
        />
        <p className="text-sm xl:text-md text-gray-700">
          Share your personalised referral code below and claim rewards.
        </p>
      </article>
      <section className="flex mt-5 py-1 md:py-2 px-2  rounded-lg xl:self-center justify-center items-center w-11/12 border-2 border-dashed bg-white">
        <span className="text-dusty text-xs overflow-x-scroll xl:w-3/4 whitespace-no-wrap">
          {referralLink}
        </span>
        <Button
          className="flex h-8 md:h-8 items-center justify-center w-5 xl:w-1/4 text-xs xl:text-sm"
          label="Copy"
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(referralLink);
            notify('Referral link copied!');
          }}
        />
      </section>
    </section>
  );
};

const ReferralInfoCard = ({
  referralCode,
  allAccessReferrals,
  premiumReferrals,
  isLoadingUserPoints,
}) => {
  if (isLoadingUserPoints) {
    return (
      <div className="flex my-20">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div className="container mx-auto flex justify-center xl:-mt-64">
        <Card className="flex flex-col p-5 w-11/12 mb-10 -mt-10 xl:-mt-5">
          <div className="flex flex-col max-w-full justify-between lg:flex-row lg:items-start">
            <ShareReferralInfo referralCode={referralCode} />
            <SucessfulReferralsInfo
              allAccessReferrals={allAccessReferrals}
              premiumReferrals={premiumReferrals}
            />
          </div>
          <div className="lg:flex lg:space-x-4">
            <HowAndWhatInfo />
          </div>
        </Card>
      </div>
    </>
  );
};

ReferralInfoCard.propTypes = {
  referralCode: PropTypes.string.isRequired,
  allAccessReferrals: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  premiumReferrals: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoadingUserPoints: PropTypes.bool.isRequired,
};

ShareReferralInfo.propTypes = {
  referralCode: PropTypes.string.isRequired,
};

SucessfulReferralsInfo.propTypes = {
  allAccessReferrals: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  premiumReferrals: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ReferralInfoCard.defaultProps = {
  allAccessReferrals: '-',
  premiumReferrals: '-',
};

SucessfulReferralsInfo.defaultProps = {
  allAccessReferrals: '-',
  premiumReferrals: '-',
};

export default ReferralInfoCard;

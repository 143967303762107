import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const ref = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView({ block: 'center' });
  }, [pathname]);

  return <div ref={ref} />;
}

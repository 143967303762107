import Actions from 'actions';

const initialState = Object.freeze({});

function uploadUserImage(state = initialState, action) {
  switch (action.type) {
    case Actions.UPLOAD_USER_IMAGE_REQUEST:
    case Actions.UPLOAD_USER_IMAGE_SUCCESS:
      return initialState;
    case Actions.UPLOAD_USER_IMAGE_FAIL:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
}

export default uploadUserImage;

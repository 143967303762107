import React, { useContext } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SidebarContext } from 'utils/context';

const Container = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: calc(100% - 100px);
    ${({ sidebarExpanded }) => sidebarExpanded && 'width: calc(100% - 300px);'}
  }
`;

const Footer = ({ children }) => {
  const { sidebarExpanded } = useContext(SidebarContext);
  return (
    <Container
      className={classnames(
        'flex items-center justify-between px-6',
        'fixed bottom-0 right-0 h-20 w-full',
        'shadow-card  bg-white',
        'transition-all duration-500 ease-in-out'
      )}
      sidebarExpanded={sidebarExpanded}
    >
      {children}
    </Container>
  );
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Footer;

import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Actions from 'actions';
import Selectors from 'selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import {
  TextInput,
  AvatarInput,
  SelectInput,
  SocialMediaLinkInput,
  SingleCheckboxInput,
  RadioButtonInput,
} from 'components/Form';
import { arrayToOptions } from 'utils/helper';
import isEmpty from 'lodash/isEmpty';
import { iconIGLink, iconFBLink, iconLinkedInLink } from 'assets';
import Save from '@material-ui/icons/Save';
import classnames from 'classnames';
import { AuthContext } from 'utils/context';
import { ALL_ACCESS } from 'utils/constants';

const EMAIL_VISIBILITY_OPTIONS = [
  { label: 'Hide my email', value: 'email_hidden' },
  { label: `Show to ${ALL_ACCESS} only`, value: 'email_visible_to_pro' },
  { label: 'Show to all', value: 'email_visible' },
];

const PersonalForm = ({ isView, toggleForm }) => {
  const dispatch = useDispatch();
  const { isPro } = useContext(AuthContext);
  const {
    values: { country },
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    errors,
  } = useFormikContext();
  const emailOptions = isPro
    ? EMAIL_VISIBILITY_OPTIONS
    : EMAIL_VISIBILITY_OPTIONS.filter((_, i) => i !== 1);

  useEffect(() => {
    dispatch(Actions.fetchCountryOptions());
    dispatch(Actions.fetchMemberTypes());
    dispatch(Actions.fetchLanguages());
    if (!isEmpty(country)) {
      dispatch(Actions.fetchCitiesOfCountry(country));
    }
  }, [dispatch, country]);

  const countries = useSelector(Selectors.countryOptions);
  const cities = useSelector(Selectors.citiesOfCountry);
  const fetchedMemberTypes = useSelector(Selectors.memberTypesList);
  const languagesOptions = useSelector(Selectors.languages);

  const isLoadingCountryList = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_COUNTRY_OPTIONS_REQUEST])
  );
  const isLoadingCityList = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_CITIES_OF_COUNTRY_REQUEST])
  );
  const isLoadingMemberOptions = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_MEMBER_TYPES_REQUEST])
  );
  const isLoadingLanguages = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_LANGUAGES_REQUEST])
  );

  const memberTypesOptions = fetchedMemberTypes?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return (
    <div className={classnames({ 'p-8': isView })}>
      <div className="sm:flex justify-between mb-8 sm:mb-0">
        <h5 className="text-lg mb-4">Personal Information</h5>
        {isView && (
          <div
            className="flex text-dusty cursor-pointer hover:text-easter justify-end"
            onClick={() => {
              handleSubmit();
              if (isEmpty(errors)) toggleForm(true);
            }}
            role="presentation"
          >
            <Save />
            <div className="ml-2">Save Changes</div>
          </div>
        )}
      </div>
      <div className="flex flex-col xl:flex-row">
        <AvatarInput name="avatar" fieldClassname="mr-0 lg:mr-4" />
        <div className="flex flex-col flex-1">
          <div className="flex flex-col lg:flex-row">
            <Field
              name="first_name"
              component={TextInput}
              placeholder="First Name"
              fieldClassName="flex-1 lg:mr-4"
            />
            <Field
              name="last_name"
              component={TextInput}
              placeholder="Last Name"
              fieldClassName="flex-1 lg:ml-4"
            />
          </div>
          <Field name="email" component={TextInput} placeholder="Email" />
          <div className="my-2">
            <p className="text-dusty mb-2">Email Visibility</p>
            <Field
              name="email_visibility"
              component={RadioButtonInput}
              options={emailOptions}
            />
          </div>
          <p className="text-dusty mb-2">Location</p>
          <div className="flex flex-col lg:flex-row items-end">
            <Field
              className="flex-1 w-full lg:w-auto lg:mr-4"
              component={SelectInput}
              isLoading={isLoadingCountryList}
              name="country"
              onChange={({ value }) => {
                dispatch(Actions.fetchCitiesOfCountry(value));
                setFieldValue('city', '');
                setFieldTouched('city', false);
              }}
              options={arrayToOptions(Object.values(countries))}
              placeholder="Countries"
            />
            <Field
              className="flex-1 w-full lg:w-auto lg:ml-4"
              component={SelectInput}
              isDisabled={isEmpty(cities)}
              isLoading={isLoadingCityList}
              name="city"
              options={arrayToOptions(cities)}
              placeholder="Cities"
            />
          </div>
          <Field
            name="biography"
            component={TextInput}
            placeholder="Add a short biography"
            label="Biography"
            type="textarea"
            showRemaining
            maxLength={500}
          />
          <Field
            name="member_types"
            component={SelectInput}
            isLoading={isLoadingMemberOptions}
            isMulti
            creatable
            listHeight={150}
            options={memberTypesOptions}
            placeholder="Select member type(s)"
            label="Member Type(s)"
          />
          <Field
            name="languages"
            component={SelectInput}
            isLoading={isLoadingLanguages}
            isMulti
            placeholder="Languages Spoken"
            options={arrayToOptions(languagesOptions)}
            creatable
          />
          <Field
            name="rep_artists"
            component={TextInput}
            placeholder="Artists represented"
          />
          <Field
            name="rep_events"
            component={TextInput}
            placeholder="Events represented"
          />
          <Field
            name="rep_venues"
            component={TextInput}
            placeholder="Venue represented"
          />
          <Field
            name="chat_notification"
            component={SingleCheckboxInput}
            className="mt-2"
            label={
              <div className="max-w-sm">
                Email notifications for new chat messages
              </div>
            }
          />
        </div>
      </div>
      <div className="border-t border-alto border-dashed mt-8 pt-8">
        <h5 className="text-lg mb-4">Social Links</h5>
        <div className="flex flex-col">
          <SocialMediaLinkInput
            className="w-full"
            name="facebook"
            img={iconFBLink.default}
            placeholder="Paste Facebook link here…"
          />
          <SocialMediaLinkInput
            className="w-full"
            name="linked_in"
            img={iconLinkedInLink.default}
            placeholder="Paste LinkedIn link here…"
          />
          <SocialMediaLinkInput
            className="w-full"
            name="instagram"
            img={iconIGLink.default}
            placeholder="Paste Instagram link here…"
          />
        </div>
      </div>
    </div>
  );
};

PersonalForm.propTypes = {
  isView: PropTypes.bool,
  toggleForm: PropTypes.func.isRequired,
};

PersonalForm.defaultProps = {
  isView: false,
};

export default PersonalForm;

import Actions from 'actions';

const initialState = Object.freeze([]);

function getCategories(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_CATEGORIES_SUCCESS:
      return action.data;
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default getCategories;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'components/UI';
import styled from '@emotion/styled';
import { BackgroundSection } from '../LandingPage/common';

const GradientBackground = styled.div`
  background: rgb(48, 45, 68);
  background: linear-gradient(
    0deg,
    rgba(48, 45, 68, 1) 0%,
    rgba(48, 45, 68, 0.7357317927170868) 18%,
    rgba(0, 212, 255, 0) 100%
  );
`;

export const LocationCard = ({ location, locationsPath }) => {
  const {
    id,
    image_url: image,
    name,
    events_count: events,
    users_count: members,
    articles_count: articles,
    country,
  } = location;

  const NoOfEvents = `${events} Events`;
  const NoOfMembers = `${members} Members`;
  const NoOfArtices = `${articles} Articles`;

  return (
    <Link to={`${locationsPath}/${id}`}>
      <Card className="h-full">
        <BackgroundSection
          backgroundImage={`url(${image})`}
          className="relative bg-no-repeat bg-cover bg-center h-56 rounded-lg"
        >
          <GradientBackground className="absolute top-0 bottom-0 rounded-lg right-0 left-0  flex flex-col text-white justify-end p-5">
            <p className="font-semibold text-2lg ml-3 break-words">{name}</p>
            <p className="text-sm ml-3 break-words">{country?.name}</p>
            <div className="flex mt-3 justify-center text-center">
              <div>{NoOfEvents}</div>
              <div className="border-l border-r px-2 mx-2 xl:px-6 xl:mx-6">
                {NoOfMembers}
              </div>
              <div>{NoOfArtices}</div>
            </div>
          </GradientBackground>
        </BackgroundSection>
      </Card>
    </Link>
  );
};

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
  locationsPath: PropTypes.string.isRequired,
};

const LocationList = ({ locations, locationsPath }) => {
  return (
    <div className="container mx-auto px-6 my-20">
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {locations.map((item) => (
          <LocationCard
            location={item}
            key={item.id}
            locationsPath={locationsPath}
          />
        ))}
      </div>
    </div>
  );
};

LocationList.propTypes = {
  locations: PropTypes.array.isRequired,
  locationsPath: PropTypes.string.isRequired,
};

export default LocationList;

import Actions from 'actions';

export const initialState = Object.freeze({});

function fetch(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_SUBSCRIPTION_SUCCESS:
    case Actions.CANCEL_UPDATE_SUBSCRIPTION_SUCCESS:
      return action.data;
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default fetch;

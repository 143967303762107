import Actions from 'actions';

const initialState = Object.freeze({});

function info(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_CITIES_OF_COUNTRY_REQUEST:
      return initialState;
    case Actions.FETCH_CITIES_OF_COUNTRY_SUCCESS:
      return {
        ...initialState,
        data: action.data,
      };
    case Actions.FETCH_CITIES_OF_COUNTRY_FAIL:
      return {
        error: action.error,
        ...initialState,
      };
    default:
      return state;
  }
}

export default info;

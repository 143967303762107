import React from 'react';
import PropTypes from 'prop-types';

const Picture = ({ src, className, disableParentClassName, fallbackImage }) => (
  <picture className={!disableParentClassName ? className : ''}>
    <source type="image/svg+xml" srcSet={src.default} />
    <img
      className={className}
      src={src}
      alt=""
      onError={(ev) => {
        ev.target.src = fallbackImage;
      }}
    />
  </picture>
);

Picture.propTypes = {
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  fallbackImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  disableParentClassName: PropTypes.bool,
};

Picture.defaultProps = {
  className: null,
  disableParentClassName: false,
  fallbackImage: null,
};

export default Picture;

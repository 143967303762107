import Actions from 'actions';

const initialState = Object.freeze({});

function list(state = initialState, action) {
  switch (action.type) {
    case Actions.LIST_CHANNELS_SUCCESS:
      return {
        ...initialState,
        data: action.data,
      };
    case Actions.LIST_CHANNELS_FAIL:
      return initialState;
    case Actions.LEAVE_CHANNEL_SUCCESS:
      return {
        data: state.data.filter(
          ({ channel }) => channel.url !== action.channelUrl
        ),
      };
    case Actions.LIST_CHANNELS_REQUEST:
    case Actions.SIGN_OUT_SUCCESS:
    default:
      return state;
  }
}

export default list;

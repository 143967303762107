import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'components/UI';
import {
  ALL_ACCESS,
  PREMIUM,
  PREMIUM_ALL_ACCESS,
  ALL_ACCESS_ONLY,
} from 'utils/constants';

export const memberAccessMap = {
  [PREMIUM_ALL_ACCESS]: `${ALL_ACCESS} & ${PREMIUM}`,
  [ALL_ACCESS_ONLY]: ALL_ACCESS,
};

const MemberAccessBadge = ({ memberAccess, md }) => {
  if ([PREMIUM_ALL_ACCESS, ALL_ACCESS_ONLY].includes(memberAccess)) {
    return (
      <Badge bg="bg-gradient-violet-cyan" md={md}>
        <div className="capitalize">{memberAccessMap[memberAccess]}</div>
      </Badge>
    );
  }

  return null;
};

MemberAccessBadge.propTypes = {
  memberAccess: PropTypes.string.isRequired,
  md: PropTypes.bool,
};

MemberAccessBadge.defaultProps = {
  md: false,
};

export default MemberAccessBadge;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import Actions from 'actions';
import Selectors from 'selectors';
import {
  Breadcrumbs,
  PageMeta,
  Loading,
  NavigationTab,
  Picture,
} from 'components/UI';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'components/UI/Loading';
import { gfxEmptyPerks } from 'assets';
import Featured from './Featured';
import PerksList from './List';

const PATH = '/members/perks/explore';
const TABS = Object.freeze([
  { name: 'Explore Perks', path: PATH },
  { name: 'My Perks', path: '/members/perks/my-perks' },
]);

const Explore = () => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const params = useQuery();
  const perks = useSelector(Selectors.getPerks);
  const featured = useSelector(Selectors.getFeaturedPerk);
  const { currentPage, totalPages } = useSelector(Selectors.perksPageMeta);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_PERKS_REQUEST])
  );
  const fetchMorePerks = () =>
    dispatch(Actions.fetchPerks({ page: currentPage + 1, ...params }));

  useEffect(() => {
    if (!featured && !isEmpty(params)) setSearchParams();
  }, [featured, params, setSearchParams]);

  useEffect(() => {
    dispatch(Actions.fetchPerks(params));
  }, [dispatch, params]);

  return (
    <>
      <PageMeta title="Perks" />
      <Breadcrumbs
        trails={[
          { label: 'Perks', path: PATH, icon: 'perks' },
          'Explore Perks',
        ]}
      />
      <NavigationTab tabs={TABS} />
      {featured ? (
        <>
          <Featured perk={featured} />
          <InfiniteScroll
            dataLength={perks.length}
            next={fetchMorePerks}
            hasMore={currentPage < totalPages}
            scrollableTarget="scrollableContent"
            loader={
              <div className="flex pb-8">
                <Spinner />
              </div>
            }
          >
            <PerksList perks={perks} featuredId={featured.id} />
          </InfiniteScroll>
        </>
      ) : (
        <>
          {isLoading ? (
            <Loading
              className="bg-white h-screen flex justify-center items-center"
              opaque={false}
            />
          ) : (
            <div className="h-screen flex justify-center items-center">
              <p className="text-dusty">
                <Picture src={gfxEmptyPerks} className="py-8 w-3/5 m-auto" />
                No available perks at the moment
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Explore;

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useSearchParams } from 'react-router-dom';
import { Select } from 'components/UI';
import useQuery from 'hooks/useQuery';
import { PREMIUM_ALL_ACCESS, ALL_ACCESS_ONLY } from 'utils/constants';
import PerkCard from './PerkCard';
import PerkTileCard from '../PerkTileCard';
import { memberAccessMap } from '../MemberAccessBadge';

const FILTER_OPTIONS = Object.freeze([
  { label: 'All Perks', value: {} },
  {
    label: memberAccessMap[PREMIUM_ALL_ACCESS],
    value: { member_access: [ALL_ACCESS_ONLY, PREMIUM_ALL_ACCESS] },
  },
  {
    label: memberAccessMap[ALL_ACCESS_ONLY],
    value: { member_access: ALL_ACCESS_ONLY },
  },
]);
const FEATURED_INDEX = 2;

const List = ({ perks, featuredId }) => {
  const [, setSearchParams] = useSearchParams();
  const hasMultiple = perks.length > 1;
  const perkList = hasMultiple
    ? perks.filter(({ id }) => id !== featuredId)
    : perks;
  const filterPerks = ({ value }) => setSearchParams(value);
  const params = useQuery();

  return (
    <div className="lg:container mx-auto px-6 pt-3 sm:mb-10 max-w-full">
      <Select
        onChange={filterPerks}
        options={FILTER_OPTIONS}
        placeholder="All Perks"
        className="sm:w-60"
        value={FILTER_OPTIONS.find(
          ({ value }) => value.member_access === params.member_access
        )}
      />
      {isEmpty(perks) ? (
        <div className="p-10 text-center my-24">No perks found</div>
      ) : (
        <>
          <div className="hidden sm:grid sm:grid-cols-2 gap-10 py-6">
            {perkList.map((item, index) => (
              <PerkCard
                perk={item}
                featured={FEATURED_INDEX === index}
                key={item.id}
              />
            ))}
          </div>
          <div className="grid gap-10 py-6 sm:hidden">
            {perkList.map((item) => (
              <PerkTileCard perk={item} key={item.id} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

List.propTypes = {
  perks: PropTypes.array.isRequired,
  featuredId: PropTypes.string.isRequired,
};

export default List;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Picture, Button } from 'components/UI';
import { iconRegistrationSuccess } from 'assets';

const SuccessScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col mx-5 mb-16 mt-10 max-w-120">
      <Picture src={iconRegistrationSuccess} className=" w-24 mx-auto" />
      <div className="font-chap text-gray-800 mb-1 text-center text-2lg">
        Membership Application Sent!
      </div>
      <div className="text-gray-500 mb-2 text-xs text-center">
        Thank you for your interest. We have sent your application for approval
        from our team. You will receive an email notification from us once your
        application has been approved.
      </div>

      <Button
        className="mt-8 max-w-80 mx-auto"
        label="BACK TO HOMEPAGE"
        onClick={() => navigate('/')}
      />
    </div>
  );
};

export default SuccessScreen;

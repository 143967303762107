import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, Button } from 'components/UI';
import { modalHeader } from 'assets';
import { BackgroundSection } from '../../LandingPage/common';

const CancelModal = ({ cancelBooking, onCancel }) => {
  return (
    <div className="w-full px-2 md:p-0">
      <Card>
        <BackgroundSection
          backgroundImage={`url(${modalHeader.default})`}
          className={classnames(
            'relative bg-no-repeat bg-cover bg-center h-18 rounded-t-lg'
          )}
        >
          <h3 className="p-3 pt-4 md:p-4 md:pt-5 text-white font-hairline text-center">
            CANCEL BOOKING
          </h3>
        </BackgroundSection>
        <div className="p-4 sm:p-10 text-center flex flex-col justify-center items-center mb-4 sm:mb-8">
          <p>Are you sure you want to cancel the consultation booking?</p>
          <p className="text-dusty mt-5">
            Your customer will be refunded automatically.
          </p>
          <div className="mt-5">
            <Button
              className="mt-2 py-3 w-full max-w-xs"
              label="YES, CANCEL BOOKING"
              type="submit"
              isPill
              onClick={cancelBooking}
            />
            <Button
              className="mt-2 py-3 w-full max-w-xs text-ebony border border-gray-100"
              label="NO, DON'T CANCEL"
              theme="plain"
              onClick={onCancel}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

CancelModal.propTypes = {
  cancelBooking: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CancelModal;

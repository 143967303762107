const NAME = 'COMMON/FETCH_LANGUAGES';

export const FETCH_LANGUAGES_REQUEST = `${NAME}_REQUEST`;
export const FETCH_LANGUAGES_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_LANGUAGES_FAIL = `${NAME}_FAIL`;

export const fetchLanguages = () => ({
  type: FETCH_LANGUAGES_REQUEST,
});

export const fetchLanguagesSuccess = (data) => ({
  type: FETCH_LANGUAGES_SUCCESS,
  data,
});

export const fetchLanguagesFail = (error) => ({
  type: FETCH_LANGUAGES_FAIL,
  error,
});

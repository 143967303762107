const NAME = 'STRIPE';

export const GET_STRIPE_CUSTOMER_REQUEST = `${NAME}/GET_STRIPE_CUSTOMER_REQUEST`;
export const GET_STRIPE_CUSTOMER_SUCCESS = `${NAME}/GET_STRIPE_CUSTOMER_SUCCESS`;
export const GET_STRIPE_CUSTOMER_FAIL = `${NAME}/GET_STRIPE_CUSTOMER_FAIL`;

export const getStripeCustomer = (callbacks) => ({
  type: GET_STRIPE_CUSTOMER_REQUEST,
  callbacks,
});

export const getStripeCustomerSuccess = (data) => ({
  type: GET_STRIPE_CUSTOMER_SUCCESS,
  data,
});

export const getStripeCustomerFail = (error) => ({
  type: GET_STRIPE_CUSTOMER_FAIL,
  error,
});

import React from 'react';
import { Breadcrumbs, PageMeta, NavigationTab } from 'components/UI';
import { useQuery } from 'react-query';
import api from 'api';

import { bgRewards } from 'assets';
import { ALL_ACCESS_MEMBERSHIP, PREMIUM_MEMBERSHIP } from 'utils/constants';
import Featured from './Featured';
import SubscriptionRewards from './SubscriptionRewards';
import { BackgroundSection } from '../../LandingPage/common';
import EarnPointsDropdown from './EarnPointsDropDown';

const PATH = '/members/rewards/explore';
const TABS = Object.freeze([
  { name: 'Explore Rewards', path: PATH },
  { name: 'My Rewards', path: '/members/rewards/my-rewards' },
]);

const Explore = () => {
  const { data: userPointsData } = useQuery(
    'userPointsData',
    api.fetchUserPoints
  );
  const { data: redeemablesData } = useQuery(
    'redeemablesData',
    api.fetchRedeemables
  );
  const subscriptionRedeemables = redeemablesData?.data.filter(
    ({ redeemable_type: redeemableType }) =>
      [PREMIUM_MEMBERSHIP, ALL_ACCESS_MEMBERSHIP].includes(redeemableType)
  );
  const redeemables = redeemablesData?.data.filter(
    ({ redeemable_type: redeemableType }) =>
      ![PREMIUM_MEMBERSHIP, ALL_ACCESS_MEMBERSHIP].includes(redeemableType)
  );
  const { current_points: currentPoints } = userPointsData?.data || {};

  return (
    <>
      <PageMeta title="Rewards" />
      <Breadcrumbs
        trails={[{ label: 'Rewards', path: PATH }, 'Explore Rewards']}
      />
      <NavigationTab tabs={TABS} />
      <BackgroundSection
        backgroundImage={`url(${bgRewards.default})`}
        minHeight="400px"
        backgroundPosition="50%"
        className="relative max-w-full bg-cover bg-no-repeat pt-20 px-4 mb-1 flex flex-col xl:h-140 xl:px-0"
      >
        <article className="mt-10 pl-4 xl:pl-0 xl:mt-20 md:w-2/3 lg:w-2/5 xl:w-1/2">
          <h2 className="text-white text-wrap text-shadow xl:text-2xl xl:ml-20">
            Gain points and claim these amazing rewards!
          </h2>
        </article>
      </BackgroundSection>
      <div className="py-1 sm:mb-10 container mx-auto">
        <EarnPointsDropdown />
        {subscriptionRedeemables?.length > 0 && (
          <SubscriptionRewards
            currentPoints={currentPoints}
            subscriptionRedeemables={subscriptionRedeemables || []}
          />
        )}
        {redeemables?.length > 0 && (
          <Featured
            redeemables={redeemables || []}
            currentPoints={currentPoints}
          />
        )}
      </div>
    </>
  );
};

export default Explore;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const RadioButton = ({ onClick, selected, label, downsize }) => (
  <button
    type="button"
    className={classnames(
      'px-4 py-3 border-2 rounded-lg mr-3 focus:outline-none mb-2',
      {
        'border-easter text-easter bg-pureWhite': selected,
        'border-gray-200 text-dusty bg-gray-100': !selected,
        'px-2 py-2': downsize,
      }
    )}
    onClick={onClick}
  >
    <div
      className={classnames('flex items-center', {
        'text-sm': downsize,
      })}
    >
      {selected ? (
        <RadioButtonCheckedIcon fontSize={downsize ? 'small' : 'medium'} />
      ) : (
        <RadioButtonUncheckedIcon fontSize={downsize ? 'small' : 'medium'} />
      )}
      <p className="ml-3">{label}</p>
    </div>
  </button>
);

RadioButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  downsize: PropTypes.bool,
};

RadioButton.defaultProps = {
  downsize: false,
};

export default RadioButton;

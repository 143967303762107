import React from 'react';
import PropTypes from 'prop-types';
import {
  iconIGLink,
  iconFBLink,
  iconLinkedInLink,
  iconLinkCheckmark,
} from 'assets';
import { ErrorContainer } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import SocialMediaLinkInput from 'components/Form/SocialMediaLinkInput';
import Tooltip from '@material-ui/core/Tooltip';

const SocialMediaDetails = ({ hasTierError, isLoading, signupCode }) => {
  return (
    <div className="flex flex-col mx-5 mt-6 mb-8">
      <div className="font-chap text-gray-800 mb-1 text-2lg">Social Media</div>
      <div className="text-gray-500 mb-8 text-xs">
        Share with us your social media links for us to know you better.
      </div>
      <SocialMediaLinkInput
        className="w-full"
        name="facebook"
        img={iconFBLink.default}
        placeholder="Paste Facebook link here…"
      />
      <SocialMediaLinkInput
        className="w-full"
        name="linked_in"
        img={iconLinkedInLink.default}
        placeholder="Paste LinkedIn link here…"
      />
      <SocialMediaLinkInput
        className="w-full"
        name="instagram"
        img={iconIGLink.default}
        placeholder="Paste Instagram link here…"
      />
      {signupCode && (
        <Tooltip
          placement="bottom"
          interactive
          title={
            <p className="text-xs">
              Reward points will be given for you to redeem subscription
              discounts
            </p>
          }
        >
          <div className="items-center rounded-lg bg-green-200 flex p-3">
            <div className="text-gray-800 text-sm">
              Signup code <span className="font-semibold">{signupCode}</span>{' '}
              has been applied
            </div>
            <img
              src={iconLinkCheckmark.default}
              alt=""
              className="ml-auto h-4 w-4"
            />
          </div>
        </Tooltip>
      )}
      {hasTierError && (
        <ErrorContainer error="Please select a tier" className="mt-3" />
      )}
      {isLoading && <Spinner height="40" color="#47bad4" />}
    </div>
  );
};

SocialMediaDetails.propTypes = {
  hasTierError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signupCode: PropTypes.string,
};

SocialMediaDetails.defaultProps = {
  signupCode: null,
};

export default SocialMediaDetails;

import { utcToZonedTime } from 'date-fns-tz';
import {
  format,
  startOfDay,
  endOfDay,
  add,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
} from 'date-fns';
import isNil from 'lodash/isNil';

const getCurrentDate = () => new Date();

export const CURRENT_YEAR = getCurrentDate().getUTCFullYear();

export const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions()
  .timeZone;

const formatHelper = (value, dateFormat) => {
  if (isNil(value)) return 'N/A';
  return format(utcToZonedTime(value, CURRENT_TIMEZONE), dateFormat);
};

export const displayDateFormat = (date, timeZone) => {
  const DISPLAY_FORMAT = 'do LLL yyyy'; // * eg: 12th Jun 2020
  return formatHelper(date, DISPLAY_FORMAT, timeZone);
};

export const displaySubscriptionDateFormat = (date, simplified = false) => {
  const DISPLAY_FORMAT = simplified ? 'd/L/yy' : 'do LLL yyyy';
  return formatHelper(date, DISPLAY_FORMAT);
};

export const displayTimeFormat = (time = '2012-12-12T12:12:12Z', timeZone) => {
  const DISPLAY_FORMAT = 'h:mm a'; // * eg: 12:00 PM

  if (timeZone) {
    try {
      const options = {
        timeZone,
        hour12: true,
        hour: 'numeric',
        minute: '2-digit',
      };
      return new Date(time).toLocaleTimeString('en-US', options);
    } catch (error) {
      console.error('Error in timezone', error);
      return formatHelper(time, DISPLAY_FORMAT);
    }
  }
  return formatHelper(time, DISPLAY_FORMAT);
};

export const displayDayDateFormat = (date, timeZone) => {
  const DISPLAY_FORMAT = 'EEE, do LLL yyyy'; // * eg: Tue, 12th Jun 2020
  return formatHelper(date, DISPLAY_FORMAT, timeZone);
};

export const displayConsultationDateFormat = (date, timeZone) => {
  const DISPLAY_FORMAT = 'EEEE, do LLL yyyy'; // * eg: Tuesday, 12th Jun 2020
  return formatHelper(date, DISPLAY_FORMAT, timeZone);
};

export const displayConsultationDateTime = (date, timeZone) => {
  const DISPLAY_FORMAT = 'do LLL yyyy, h:mm a'; // * eg: 26th Jan 2021, 6:00 PM
  return formatHelper(date, DISPLAY_FORMAT, timeZone);
};

export const yearsTillNow = (year) => getCurrentDate().getFullYear() - year;

export const displayTime = (time) => formatHelper(time, 'h:mm a'); // 11:00 PM

export const displayWeekday = (date) => formatHelper(date, 'EEEE'); // Monday

const everyHourOfTheDay = () => {
  const firstHour = startOfDay(getCurrentDate());
  const lastHour = endOfDay(getCurrentDate());
  let result = [];
  let start = firstHour;

  while (start < lastHour) {
    result = [...result, start];
    start = add(start, { hours: 1 });
  }
  return result;
};

const everyDayOfTheWeek = () => {
  const weekOptions = { weekStartsOn: 1 };

  return eachDayOfInterval({
    start: startOfWeek(getCurrentDate(), weekOptions),
    end: endOfWeek(getCurrentDate(), weekOptions),
  });
};

export const HOURS_OPTIONS = everyHourOfTheDay().map((time) => ({
  label: displayTime(time),
  value: formatHelper(time, 'H'),
}));

export const DAY_OPTIONS = everyDayOfTheWeek().map((date) => ({
  label: displayWeekday(date),
  value: date,
}));

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CONFIG from 'utils/config';
import Actions from 'actions';
import Selectors from 'selectors';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import notify from 'utils/notification';
import { Spinner } from 'components/UI/Loading';

const stripePromise = loadStripe(CONFIG.STRIPE_PUB_KEY);
const genericError = 'Something went wrong, please try again later';

const CARD_ELEMENT_STYLES = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#718196',
      fontWeight: 600,
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0afbf',
      },
    },
    invalid: {
      color: '#fc8181',
      iconColor: '#fc8181',
    },
  },
};

const StripeCreditCardForm = ({ amount, promoId, priceId }) => {
  const dispatch = useDispatch();
  const { email } = useSelector(Selectors.getUser);

  const CreditCardForm = () => {
    const elements = useElements();
    const stripe = useStripe();
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [isDisabled, setDisabled] = useState(true);
    const [isProcessing, setProcessing] = useState(false);
    const isBtnDisabled = isProcessing || isDisabled || !!error;
    const setupIntentData = useSelector(Selectors.getSetupIntent);

    const handleCardOnChange = (event) => {
      setDisabled(!event.complete);
      setError(event?.error?.message || '');
    };

    const saveCreditCard = () => {
      if (!stripe || !elements) return;

      setProcessing(true);
      if (!isEmpty(setupIntentData))
        return handleStripeSetupIntent(setupIntentData);
      dispatch(Actions.setupIntent({ email }, handleStripeSetupIntent));
    };

    const handleStripeSetupIntent = ({ secret, customer }) =>
      stripe
        .confirmCardSetup(secret, {
          payment_method: { card: elements.getElement(CardElement) },
        })
        .then((result) => {
          if (result.error) {
            setError(result.error.message || genericError);
            setProcessing(false);
          } else {
            dispatch(
              Actions.updateSubscription(
                {
                  price_id: priceId,
                  promo_id: promoId,
                  stripe_customer_id: customer,
                  // eslint-disable-next-line camelcase
                  payment_method: result.setupIntent?.payment_method,
                },
                {
                  success: () => {
                    notify('Successfully updated subscription!');
                    navigate('/subscription');
                  },
                  failure: () =>
                    notify(
                      'Failed to update subscription, please try again later',
                      null,
                      'danger'
                    ),
                }
              )
            );
          }
        });

    return (
      <>
        <CardElement
          options={CARD_ELEMENT_STYLES}
          onChange={handleCardOnChange}
          className="w-full bg-white px-4 py-3 my-2 rounded h-10 sm:h-full"
        />
        <button
          className={classNames(
            'flex justify-center w-full rounded bg-easter text-white text-lg py-2',
            { 'bg-boulder opacity-100 cursor-not-allowed': isBtnDisabled }
          )}
          type="submit"
          disabled={isBtnDisabled}
          onClick={saveCreditCard}
        >
          {isProcessing ? (
            <Spinner color="#fff" height={20} />
          ) : (
            `Pay USD ${amount.toFixed(2)}`
          )}
        </button>
        {!!error && <p className="mt-2 font-light text-valencia">{error}</p>}
      </>
    );
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="flex flex-col items-center justify-center w-full p-4 rounded bg-squeeze">
        <p className="text-gull text-sm">Pay with credit/debit card</p>
        <CreditCardForm />
      </div>
    </Elements>
  );
};

StripeCreditCardForm.propTypes = {
  amount: PropTypes.number.isRequired,
  priceId: PropTypes.number,
  promoId: PropTypes.string,
};

StripeCreditCardForm.defaultProps = {
  priceId: null,
  promoId: null,
};

export default StripeCreditCardForm;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getIn } from 'formik';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import styled from '@emotion/styled';

const Container = styled.div`
  box-shadow: 0 5px 15px 0 rgba(14, 92, 164, 0.3);
  background-color: white;
`;

const DateRangeInput = (props) => {
  const {
    form: { touched, errors, setFieldTouched },
    field: { name },
    onChange,
    className,
    initialValues,
  } = props;
  const error = getIn(touched, name) && getIn(errors, name);
  const [dates, setDates] = useState(initialValues);
  const hasInitialValues = initialValues[0] && initialValues[1];
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (dates) => {
    setDates(dates);
    if (onChange) onChange(dates);
  };

  useEffect(() => setDates(initialValues), [initialValues]);

  return (
    <div>
      <Container
        className={classnames(
          'px-4 py-3 rounded-md items-center inline-flex text-boulder',
          className
        )}
      >
        <DateRangeIcon className="text-easter mr-3" />
        {hasInitialValues || isOpen ? (
          <DateRangePicker
            format="dMMMy"
            rangeDivider=" - "
            onChange={handleChange}
            value={dates}
            calendarClassName="border-0"
            clearIcon={null}
            calendarIcon={null}
            isOpen={isOpen}
            dayPlaceholder="day "
            monthPlaceholder="month"
            yearPlaceholder=" year"
          />
        ) : (
          <div
            className="cursor-pointer"
            role="presentation"
            onClick={() => {
              setIsOpen(true);
              setFieldTouched(name);
            }}
          >
            Start Date - End Date
          </div>
        )}
      </Container>
      <div className="text-valencia h-4 mt-2 text-xs font-light">{error}</div>
    </div>
  );
};

DateRangeInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  initialValues: PropTypes.array.isRequired,
};

DateRangeInput.defaultProps = {
  onChange: null,
  className: null,
};

export default DateRangeInput;

const NAME = 'STRIPE/CREATE_PORTAL_SESSION';

export const CREATE_PORTAL_SESSION_REQUEST = `${NAME}_REQUEST`;
export const CREATE_PORTAL_SESSION_SUCCESS = `${NAME}_SUCCESS`;
export const CREATE_PORTAL_SESSION_FAIL = `${NAME}_FAIL`;

export const createPortalSession = (callbacks) => ({
  type: CREATE_PORTAL_SESSION_REQUEST,
  callbacks,
});

export const createPortalSessionSuccess = (data) => ({
  type: CREATE_PORTAL_SESSION_SUCCESS,
  data,
});

export const createPortalSessionFail = (error) => ({
  type: CREATE_PORTAL_SESSION_FAIL,
  error,
});

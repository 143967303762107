const AUTH = 'AUTH/SIGN_IN';

export const SIGN_IN_REQUEST = `${AUTH}_REQUEST`;
export const SIGN_IN_SUCCESS = `${AUTH}_SUCCESS`;
export const SIGN_IN_FAIL = `${AUTH}_FAIL`;

export const signIn = (credentials, callbacks = {}) => ({
  type: SIGN_IN_REQUEST,
  credentials,
  callbacks,
});

export const signInSuccess = (data) => ({
  type: SIGN_IN_SUCCESS,
  data,
});

export const signInFail = (errors) => ({
  type: SIGN_IN_FAIL,
  errors,
});

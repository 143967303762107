import React, { useContext, useEffect } from 'react';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, PageMeta } from 'components/UI';
import { nameFormat } from 'utils/format';
import { AuthContext } from 'utils/context';
import ViewProfile from '../Profile/View';

const UserProfile = () => {
  const { authenticated } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { id: userId } = useParams();

  useEffect(() => {
    dispatch(Actions.fetchUser(userId));
  }, [dispatch, userId]);

  const userData = useSelector(Selectors.userData);

  const {
    image_url: avatarUrl,
    first_name: firstName,
    last_name: lastName,
    email,
    country,
    city,
    biography,
    languages,
    rep_artists: repArtist,
    rep_events: repEvents,
    rep_venues: repVenues,
    facebook,
    linked_in: linkedIn,
    instagram,
    member_types: memberTypes,
    industry_since: industrySince,
    users_experiences: workExperiences,
    users_services: userServices,
    spotify_playlists: spotify,
    youtube_playlists: youtube,
    sendbird_id: sendbirdId,
  } = userData;

  const arrayStringInitValue = (array) => array?.join(', ') || '';

  // TODO: Probably need refactoring on not rendering into form for viewing other user
  const initialValues = {
    avatarUrl: avatarUrl || '',
    first_name: firstName || '',
    last_name: lastName || '',
    email: email || '',
    country: country?.name || '',
    city: city?.name || '',
    biography: biography || '',
    member_types:
      memberTypes?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) || [],
    languages:
      languages?.map(({ name }) => ({
        label: name,
        value: name,
      })) || [],
    rep_artists: arrayStringInitValue(repArtist),
    rep_events: arrayStringInitValue(repEvents),
    rep_venues: arrayStringInitValue(repVenues),
    facebook: facebook || '',
    linked_in: linkedIn || '',
    instagram: instagram || '',
    industry_since: industrySince || '',
    users_experiences: workExperiences || [],
    users_services: userServices || [],
    spotify_playlists: spotify || [''],
    youtube_playlists: youtube || [''],
    sendbirdId,
  };

  const userName = nameFormat({ firstName, lastName });

  return (
    <>
      <PageMeta title={userName} />
      <Breadcrumbs
        isMember={authenticated}
        trails={[{ label: 'Network', path: '/network', icon: null }, userName]}
      />
      <div className="pt-16">
        <ViewProfile initialValues={initialValues} readOnly />
      </div>
    </>
  );
};

UserProfile.propTypes = {};

export default UserProfile;

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* forgotPassword({ data, callbacks }) {
  try {
    const response = yield call(api.forgotPassword, data);
    yield put(Actions.forgotPasswordSuccess(response.data));
    if (typeof callbacks.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.forgotPasswordFail(error));
    if (typeof callbacks.failure === 'function') {
      yield call(callbacks.failure, error?.response);
    }
  }
}

function* watchforgotPassword() {
  yield takeLatest(Actions.FORGOT_PASSWORD_REQUEST, forgotPassword);
}

export default function* forgot() {
  yield all([fork(watchforgotPassword)]);
}

const NAME = 'AUTH/ACTIVATE_ACCOUNT';

export const ACTIVATE_ACCOUNT_REQUEST = `${NAME}_REQUEST`;
export const ACTIVATE_ACCOUNT_SUCCESS = `${NAME}_SUCCESS`;
export const ACTIVATE_ACCOUNT_FAIL = `${NAME}_FAIL`;

export const activateAccount = (data, callbacks) => ({
  type: ACTIVATE_ACCOUNT_REQUEST,
  data,
  callbacks,
});

export const activateAccountSuccess = () => ({
  type: ACTIVATE_ACCOUNT_SUCCESS,
});

export const activateAccountFail = (error) => ({
  type: ACTIVATE_ACCOUNT_FAIL,
  error,
});

const NAME = 'PERK/FETCH_PERK_';

export const FETCH_PERK_REQUEST = `${NAME}REQUEST`;
export const FETCH_PERK_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_PERK_FAIL = `${NAME}FAIL`;

export const fetchPerk = (id, callbacks) => ({
  type: FETCH_PERK_REQUEST,
  id,
  callbacks,
});

export const fetchPerkSuccess = (data) => ({
  type: FETCH_PERK_SUCCESS,
  data,
});

export const fetchPerkFail = (error) => ({
  type: FETCH_PERK_FAIL,
  error,
});

const NAME = 'EVENTS/FETCH_ADDED_EVENTS_';

export const FETCH_ADDED_EVENTS_REQUEST = `${NAME}REQUEST`;
export const FETCH_ADDED_EVENTS_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_ADDED_EVENTS_FAIL = `${NAME}FAIL`;

export const fetchAddedEvents = () => ({
  type: FETCH_ADDED_EVENTS_REQUEST,
});

export const fetchAddedEventsSuccess = (data) => ({
  type: FETCH_ADDED_EVENTS_SUCCESS,
  data,
});

export const fetchAddedEventsFail = () => ({
  type: FETCH_ADDED_EVENTS_FAIL,
});

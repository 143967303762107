import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isPast } from 'date-fns';
import Actions from 'actions';
import PropTypes from 'prop-types';

import { Spinner } from 'components/UI/Loading';
import { Button } from 'components/UI';
import { AuthContext } from 'utils/context';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';
import { ALL_ACCESS_ONLY } from 'utils/constants';
import ConfirmBookingModal from './ConfirmBookingModal';
import BookingPaymentModal from './BookingPaymentModal';

const EventModal = ({
  eventInfo,
  attending,
  isLoading,
  cancelOnly,
  updateAttending,
}) => {
  const {
    id,
    name,
    max_rsvp_limit: maxRSVPLimit,
    current_user_opted_in: userOptIn,
    slots_by_tier: slotsByTier,
    rsvp_deadline_date: rsvpDeadline,
    allow_guests: guestsAllowed,
    member_access: memberAccess,
    ticket_price: ticketPrice,
    ticket_price_currency: ticketPriceCurrency,
  } = eventInfo;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authenticated, tier, allAccessEventEligible } = useContext(
    AuthContext
  );
  const isRSVPDeadlinePassed = rsvpDeadline && isPast(new Date(rsvpDeadline));

  // Context: if there is no ticket price, the event is open to book for free.
  const showAllAccessPaywall =
    !allAccessEventEligible &&
    memberAccess === ALL_ACCESS_ONLY &&
    ticketPrice > 0;

  const disableBooking = !maxRSVPLimit || isRSVPDeadlinePassed;
  const disableBookingLabel = !maxRSVPLimit ? 'opening soon' : 'RSVP Closed';

  const userSlot =
    slotsByTier?.find(
      ({ member_tier: memberTier }) => memberTier === tier.name
    ) || {};
  const openSlot = userSlot?.slots;

  const [optIn, setOptIn] = useState(userOptIn || false);
  const [buttonTheme, setButtonTheme] = useState(true);
  const [hasSlots, setHasSlots] = useState(openSlot > 0);

  useEffect(() => {
    setOptIn(userOptIn);
  }, [eventInfo, userOptIn]);

  useEffect(() => {
    setHasSlots(openSlot > 0);
  }, [openSlot]);

  useEffect(() => {
    setButtonTheme(
      modalButtonLabel() === 'Remove from waitlist' ||
        modalButtonLabel() === 'Cancel Booking'
    );
  }, [hasSlots, attending, optIn, modalButtonLabel]);

  const displayError = (error) => {
    dispatch(
      Actions.showDialog({
        ...initModalContent(),
        error: error?.response?.data?.error || 'Something Went Wrong',
      })
    );
  };

  const handleConfirmBooking = (id, values) => {
    dispatch(
      Actions.attendEvent(id, values, {
        success: () => {
          dispatch(
            Actions.showDialog({
              content: 'Your booking has been confirmed',
              acceptLabel: 'OK',
              isSuccess: true,
              showAddToCalendar: true,
            })
          );
        },
        failure: (error) => {
          const errorResponse = error.response.data.error;
          if (errorResponse.includes('Event RSVP limit is reached')) {
            dispatch(
              Actions.showDialog({
                title: 'Caution',
                content:
                  'Unfortunately, all the slots for this event have been booked.',
                subContent:
                  "Don't worry, you can still join the waitlist and you'll be notified by email if any slots become available.",
                acceptLabel: 'YES, JOIN WAITLIST',
                dismissLabel: "NO, DON'T JOIN WAITLIST",
                onAccept: handleOptIn,
                error: error?.response?.data?.error,
              })
            );
            setHasSlots(false);
          } else {
            displayError(error);
          }
        },
      })
    );
  };

  const handleCancelBooking = () => {
    dispatch(
      Actions.unattendEvent(id, {
        success: () => {
          if (typeof updateAttending === 'function') updateAttending(id);
          setHasSlots(true);
          dispatch(
            Actions.showDialog({
              content: 'Your booking has been cancelled',
              acceptLabel: 'OK',
              isSuccess: true,
            })
          );
        },
        failure: (error) => displayError(error),
      })
    );
  };

  const handleOptIn = () => {
    dispatch(
      Actions.optInEvent(id, {
        success: () => {
          setOptIn(true);
          dispatch(
            Actions.showDialog({
              content:
                'You have successfully joined the waitlist for this events',
              acceptLabel: 'OK',
              isSuccess: true,
            })
          );
        },
        failure: (error) => displayError(error),
      })
    );
  };

  const handleOptOut = () => {
    dispatch(
      Actions.optOutEvent(id, {
        success: () => {
          setOptIn(false);
          dispatch(
            Actions.showDialog({
              content:
                'You have successfully removed youself from the waitlist for this events',
              acceptLabel: 'OK',
              isSuccess: true,
            })
          );
        },
        failure: (error) => displayError(error),
      })
    );
  };

  const initModalContent = () => {
    if (hasSlots !== false || attending) {
      if (attending) {
        return {
          title: 'Cancel booking',
          content:
            'Are you sure you want to CANCEL your booking for this event?',
          subContent:
            'You will have to book a slot again to join this event, depending on the availability',
          acceptLabel: 'YES, CANCEL BOOKING',
          dismissLabel: "NO, DON'T CANCEL",
          onAccept: handleCancelBooking,
        };
      }

      return {
        title: 'Confirm your booking',
        content: showAllAccessPaywall ? (
          <BookingPaymentModal
            eventName={name}
            eventId={id}
            eventPrice={ticketPrice}
            eventPriceCurrency={ticketPriceCurrency}
            handleConfirmBooking={handleConfirmBooking}
          />
        ) : (
          <ConfirmBookingModal
            eventName={name}
            handleConfirmBooking={handleConfirmBooking}
            tier={tier}
            id={id}
            openSlot={openSlot}
            guestsAllowed={guestsAllowed}
          />
        ),
        acceptLabel: 'YES, BOOK SLOT',
        dismissLabel: "NO, DON'T BOOK",
        hideButtons: true,
      };
    }

    if (!attending) {
      if (optIn) {
        return {
          title: 'Remove from waiting list for event',
          content:
            'Are you sure you want remove yourself from the waiting list?',
          subContent:
            'You will have to join the waitlist again to receive notification if there are available slots for this events',
          acceptLabel: 'YES, REMOVE FROM WAITLIST',
          dismissLabel: 'NO, REMAIN IN WAITLIST',
          onAccept: handleOptOut,
        };
      }

      return {
        title: 'Join waitlist for event',
        content: `By joining the waitlist for "${name}", we will notify you through email if there are available slots for this events`,
        acceptLabel: 'YES, JOIN WAITLIST',
        dismissLabel: "NO, DONT'T JOIN WAITLIST",
        onAccept: handleOptIn,
      };
    }
  };

  const modalButtonLabel = () => {
    if (hasSlots === false && !attending) {
      return optIn ? 'EXIT WAITLIST' : 'JOIN WAITLIST';
    }
    return attending ? 'Cancel Booking' : 'Book a Slot';
  };

  const openDialog = (e = null) => {
    if (e) e.preventDefault();
    dispatch(Actions.showDialog(initModalContent()));
  };

  if (isLoading)
    return (
      <div className="flex pb-8">
        <Spinner />
      </div>
    );

  if (authenticated) {
    if (cancelOnly) {
      return (
        <div
          className="uppercase text-dusty pb-5 sm:pb-0 ml-0 sm:ml-auto h-16 pt-6 cursor-pointer"
          onClick={openDialog}
          role="presentation"
        >
          Cancel Booking
        </div>
      );
    }
    return (
      <div className="flex flex-row items-center gap-3">
        <Button
          label={disableBooking ? disableBookingLabel : modalButtonLabel()}
          className="max-w-60 lg:w-64 h-12 flex items-center justify-center"
          theme={buttonTheme ? 'plain' : 'default'}
          outline={buttonTheme}
          onClick={openDialog}
          disabled={disableBooking}
        />
        {showAllAccessPaywall && (
          <Tooltip
            title={
              <p className="text-xs">
                Minimum of 3 months All Access membership required to book a
                slot for free
              </p>
            }
            interactive
            placement="right"
            className=""
          >
            <InfoIcon className="text-dusty hover:text-easter" />
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <Button
      label="BECOME A MEMBER"
      className="w-64 h-12"
      onClick={navigate('/signup')}
    />
  );
};

EventModal.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  attending: PropTypes.bool,
  isLoading: PropTypes.bool,
  cancelOnly: PropTypes.bool,
  updateAttending: PropTypes.func,
};

EventModal.defaultProps = {
  attending: false,
  isLoading: false,
  cancelOnly: false,
  updateAttending: null,
};

export default EventModal;

import React from 'react';
import PropTypes from 'prop-types';

import RewardCard from './RewardCard';

const Featured = ({ redeemables, currentPoints }) => {
  return (
    <>
      <div className="flex flex-col px-4 xl:px-0 md:ml-6 max-w-full lg:pt-5 xl:ml-10 xl:w-17/20">
        <h3 className="font-semibold self-start">FEATURED REWARDS</h3>
        {redeemables?.length > 0 &&
          redeemables.map((redeemable) => {
            return (
              <RewardCard
                redeemable={redeemable}
                currentPoints={currentPoints || 0}
                key={redeemable.id}
              />
            );
          })}
      </div>
    </>
  );
};

Featured.propTypes = {
  redeemables: PropTypes.array.isRequired,
  currentPoints: PropTypes.number,
};

Featured.defaultProps = {
  currentPoints: 0,
};

export default Featured;

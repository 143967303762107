import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import useQuery from 'hooks/useQuery';
import isEmpty from 'lodash/isEmpty';
import { Breadcrumbs, PageMeta } from 'components/UI';
import { Spinner } from 'components/UI/Loading';
import { AuthContext } from 'utils/context';
import Header from './Header';
import LocationList from './List';
import Filter from './Filter';

const Locations = () => {
  const params = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();
  const { authenticated, hasAccess } = useContext(AuthContext);
  const memberPage = '/members/locations';
  const locationsPath = authenticated ? memberPage : '/locations';
  const allLocations = useSelector(Selectors.getPaginatedCities);
  const locationsPageMeta = useSelector(Selectors.citiesPageMeta);
  const { currentPage = 1, totalCount = 0, totalPages = 0 } = locationsPageMeta;
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.FETCH_CITIES_REQUEST])
  );

  useEffect(() => {
    if (authenticated && hasAccess && !currentPath.includes(memberPage)) {
      navigate(memberPage);
    }
  }, [authenticated, navigate, hasAccess]);

  useEffect(() => {
    dispatch(Actions.fetchCities(params));
  }, [dispatch, params]);

  function fetchMoreLocations() {
    dispatch(Actions.fetchCities({ ...params, page: currentPage + 1 }));
  }

  const content = () => {
    if (isLoading && isEmpty(allLocations)) {
      return (
        <div className="flex pb-8">
          <Spinner />
        </div>
      );
    }
    if (isEmpty(allLocations) && params) {
      return (
        <div className="text-center px-8">
          No result found for &quot;{params.search}&quot;
        </div>
      );
    }
    if (isEmpty(allLocations)) {
      return (
        <div className="text-center px-8">
          More locations will be coming soon...
        </div>
      );
    }

    return (
      <InfiniteScroll
        dataLength={totalCount}
        next={fetchMoreLocations}
        hasMore={currentPage < totalPages}
        scrollableTarget="scrollableContent"
        loader={
          <div className="flex pb-8">
            <Spinner />
          </div>
        }
      >
        <Filter />
        <LocationList locations={allLocations} locationsPath={locationsPath} />
      </InfiniteScroll>
    );
  };

  return (
    <div>
      <PageMeta title="Locations" />
      {hasAccess && (
        <Breadcrumbs
          trails={[
            {
              label: 'Global Directory',
              path: locationsPath,
              icon: 'locations',
            },
          ]}
        />
      )}
      <Header />
      {content()}
    </div>
  );
};

export default Locations;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchAttendances({ id }) {
  try {
    const response = yield call(api.fetchAttendances, id);
    yield put(Actions.fetchAttendancesSuccess(id, response?.data));
  } catch (error) {
    yield put(Actions.fetchAttendancesFail(error));
  }
}

function* watchFetchAttendances() {
  yield takeLatest(Actions.FETCH_ATTENDANCES_REQUEST, fetchAttendances);
}

export default function* attendances() {
  yield all([fork(watchFetchAttendances)]);
}

import React, { useState } from 'react';
import Actions from 'actions';
import Selectors from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'components/UI/Loading';
import { Field, Formik, Form } from 'formik';
import { Button, ErrorContainer } from 'components/UI';
import TextInput from 'components/Form/TextInput';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import AuthLayout from './common/AuthLayout';

const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const isLoading = useSelector(
    Selectors.createLoadingSelector([Actions.SIGN_IN_REQUEST])
  );
  const { redirectPath = '/dashboard', completedPayment } = useQuery();
  const isCompletedPayment = completedPayment === 'true';

  return (
    <>
      <AuthLayout>
        <div className="md:px-82">
          <h5 className="mt-8 md:min-w-100">
            {isCompletedPayment ? 'Welcome to Gig Life Pro!' : 'Login'}
          </h5>
          <p className="text-dusty mt-2 mb-8 text-sm font-light">
            {isCompletedPayment
              ? 'Please login to your new account'
              : 'Welcome back to Gig Life Pro, please login to continue.'}
          </p>
          <Formik
            onSubmit={({ email, password }) => {
              setError(null);
              dispatch(
                Actions.signIn(
                  { email, password },
                  {
                    success: () => navigate(redirectPath),
                    failure: (error) => {
                      const errMsg = [401, 404, 400].includes(error?.status)
                        ? error?.data?.error
                        : 'Something went wrong, please try again later';
                      setError(errMsg);
                    },
                  }
                )
              );
            }}
            validationSchema={LoginSchema}
            initialValues={{
              email: '',
              password: '',
            }}
          >
            <Form>
              <Field
                label="Email"
                name="email"
                component={TextInput}
                type="email"
                placeholder="Email"
              />
              <Field
                label="Password"
                name="password"
                component={TextInput}
                type="password"
                placeholder="Password"
              />
              <Link
                className="block text-easter text-right"
                to="/forgot-password"
              >
                Forgot Password?
              </Link>
              <div className="flex items-center justify-center mt-16 mb-8">
                {isLoading ? (
                  <Spinner height="40" color="#47bad4" />
                ) : (
                  <Button className="px-16" label="Login" type="submit" />
                )}
              </div>
              <ErrorContainer error={error} />
            </Form>
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};

export default Login;

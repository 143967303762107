import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchVideoCategories() {
  try {
    const { data } = yield call(api.fetchVideoCategories);
    yield put(Actions.fetchVideoCategoriesSuccess(data));
  } catch (error) {
    yield put(Actions.fetchVideoCategoriesFail(error));
  }
}

function* watchFetchVideoCategories() {
  yield takeLatest(
    Actions.FETCH_VIDEO_CATEGORIES_REQUEST,
    fetchVideoCategories
  );
}

export default function* categories() {
  yield all([fork(watchFetchVideoCategories)]);
}

import React from 'react';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';

export const PRO_DIALOG = Object.freeze({
  title: `DOWNGRADE TO ${PREMIUM}?`,
  content: `Are you sure you want to downgrade to ${PREMIUM} membership?`,
  subContent: `You will lose all the benefits tied to ${ALL_ACCESS} membership. Your updated fee of USD 15/month or USD 150/year will take effect in your next billing cycle`,
  acceptLabel: `YES, DOWNGRADE TO ${PREMIUM}`,
  dismissLabel: "NO, DON'T DOWNGRADE",
});

export const liteDialog = (isAnnual) => {
  const paymentInfo = isAnnual ? (
    <span>
      Your <b>prorated charges</b> for an annual membership will be processed at
      the time of your upgrade.
    </span>
  ) : (
    'Your updated fee from $15/month to $50/month will take effect in your next billing cycle.'
  );

  return {
    title: `LET'S UPGRADE TO ${ALL_ACCESS}!`,
    content: `Awesome! You are eligible to upgrade to ${ALL_ACCESS} Membership`,
    acceptLabel: `YES, UPGRADE TO ${ALL_ACCESS}`,
    dismissLabel: `NO, DON'T UPGRADE`,
    subContent: (
      <span>
        {`You will gain all the benefits tied to ${ALL_ACCESS} membership. `}
        <span>{paymentInfo}</span>
      </span>
    ),
  };
};

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import { setCacheExp } from 'utils/dataCache';
import api from 'api';

function* updateConsultationService({ params, callbacks = {} }) {
  try {
    const { data } = yield call(api.updateConsultationService, params);
    const cacheExp = setCacheExp(10);
    yield put(Actions.updateConsultationServiceSuccess({ data, cacheExp }));
    if (typeof callbacks.success === 'function') yield call(callbacks.success);
  } catch (error) {
    yield put(Actions.updateConsultationServiceFail(error));
    if (typeof callbacks.fail === 'function') {
      const err = error?.response?.data?.error;
      const msg = Array.isArray(err)
        ? err.join(', ')
        : err || 'Failed to update';
      yield call(callbacks.fail, msg);
    }
  }
}

function* watchUpdateConsultationService() {
  yield takeLatest(
    Actions.UPDATE_CONSULTATION_SERVICE_REQUEST,
    updateConsultationService
  );
}

export default function* update() {
  yield all([fork(watchUpdateConsultationService)]);
}

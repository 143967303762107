import Actions from 'actions';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import api from 'api';

function* getStripeCustomer({ callbacks }) {
  try {
    const response = yield call(api.getStripeCustomer);
    if (response) {
      yield put(Actions.getStripeCustomerSuccess(response.data));
      if (callbacks.success) {
        yield call(callbacks.success, response.data);
      }
    } else {
      yield put(Actions.getStripeCustomerFail(response.error));
      if (callbacks.failure) {
        yield call(callbacks.failure, response.error);
      }
    }
  } catch (error) {
    yield put(Actions.getStripeCustomerFail(error));
  }
}

function* watchgetStripeCustomer() {
  yield takeLatest(Actions.GET_STRIPE_CUSTOMER_REQUEST, getStripeCustomer);
}

export default function* create() {
  yield all([fork(watchgetStripeCustomer)]);
}

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from '@emotion/styled';
import { width } from 'styled-system';
import EventCard from './EventCard';

const CardContainer = styled.div`
  ${width}
`;

const RelatedEvents = ({ events, fromLocationPage, eventsPath }) => {
  if (isEmpty(events)) return null;

  return (
    <div className="container mx-auto px-6 py-10">
      {fromLocationPage ? (
        <h2 className="font-semibold mb-6">Upcoming Events</h2>
      ) : (
        <h3 className="font-semibold">Related Events</h3>
      )}
      <div className="flex flex-no-wrap overflow-x-auto w-full pl-3">
        {events.map((item) => (
          <CardContainer
            width="350px"
            className="flex-grow-0 flex-shrink-0 mr-10 my-4"
            key={item.id}
          >
            <EventCard events={item} eventsPath={eventsPath} />
          </CardContainer>
        ))}
      </div>
    </div>
  );
};

RelatedEvents.propTypes = {
  events: PropTypes.array.isRequired,
  fromLocationPage: PropTypes.bool,
  eventsPath: PropTypes.string,
};

RelatedEvents.defaultProps = {
  fromLocationPage: false,
  eventsPath: '/events',
};

export default RelatedEvents;

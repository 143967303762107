const NAME = 'MEMBER/FETCH_TIERS';

export const FETCH_MEMBER_TIERS_REQUEST = `${NAME}_REQUEST`;
export const FETCH_MEMBER_TIERS_SUCCESS = `${NAME}_SUCCESS`;
export const FETCH_MEMBER_TIERS_FAIL = `${NAME}_FAIL`;

export const fetchMemberTiers = () => ({
  type: FETCH_MEMBER_TIERS_REQUEST,
});

export const fetchMemberTiersSuccess = (data) => ({
  type: FETCH_MEMBER_TIERS_SUCCESS,
  data,
});

export const fetchMemberTiersFail = (error) => ({
  type: FETCH_MEMBER_TIERS_FAIL,
  error,
});

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchRedeemedPerks() {
  try {
    const { data } = yield call(api.redeemedPerks);
    yield put(Actions.fetchRedeemedPerksSuccess(data));
  } catch (error) {
    yield put(Actions.fetchRedeemedPerksFail(error));
  }
}

function* watchFetchRedeemedPerks() {
  yield takeLatest(Actions.FETCH_REDEEMED_REQUEST, fetchRedeemedPerks);
}

export default function* redeemed() {
  yield all([fork(watchFetchRedeemedPerks)]);
}

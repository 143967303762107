import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Badge = ({ children, md, bg, className }) => {
  let size = 'text-base py-1 px-3';
  if (md) size = 'text-md py-2 px-4';

  return (
    <div className={`flex items-center ${className}`}>
      <div
        className={classnames(
          size,
          bg,
          'text-white uppercase rounded leading-none'
        )}
      >
        {children}
      </div>
    </div>
  );
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  md: PropTypes.bool,
  bg: PropTypes.string,
  className: PropTypes.string,
};

Badge.defaultProps = {
  md: false,
  bg: 'bg-gray-700',
  className: null,
};

export default Badge;

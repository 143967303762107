import Actions from 'actions';

const initialState = Object.freeze([]);

function slots(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_SLOTS_SUCCESS:
      return action.data;
    case Actions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export default slots;

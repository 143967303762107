const NAME = 'PERK/FETCH_CREATED_';

export const FETCH_CREATED_REQUEST = `${NAME}REQUEST`;
export const FETCH_CREATED_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_CREATED_FAIL = `${NAME}FAIL`;

export const fetchCreatedPerks = () => ({
  type: FETCH_CREATED_REQUEST,
});

export const fetchCreatedPerksSuccess = (data) => ({
  type: FETCH_CREATED_SUCCESS,
  data,
});

export const fetchCreatedPerksFail = (error) => ({
  type: FETCH_CREATED_FAIL,
  error,
});

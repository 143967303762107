import Actions from 'actions';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import api from 'api';

function* createSubscription({ data }) {
  try {
    const response = yield call(api.createSubscription, data);
    yield put(Actions.createSubscriptionSuccess(response?.data));
  } catch (error) {
    yield put(Actions.createSubscriptionFail(error));
  }
}

function* watchcreateSubscription() {
  yield takeLatest(Actions.CREATE_SUBSCRIPTION_REQUEST, createSubscription);
}

export default function* create() {
  yield all([fork(watchcreateSubscription)]);
}

import {
  takeLatest,
  all,
  fork,
  put,
  call,
  take,
  select,
} from 'redux-saga/effects';
import Actions from 'actions';
import Selectors from 'selectors';
import { differenceInDays } from 'date-fns';
import { sendSbMessage, sendSbFileMessage } from 'utils/sendbird';
import api from 'api';
import imageCompression from 'browser-image-compression';

function* sendMessage({ channel, msg, recipientId, callbacks }) {
  let message;
  try {
    if (msg instanceof Blob) {
      const compressed = yield call(imageCompression, msg, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 600,
      });
      message = yield call(sendSbFileMessage, channel, compressed);
    } else {
      message = yield call(sendSbMessage, channel, msg);
    }

    if (message) {
      yield put(Actions.sendMessageSuccess());
      yield put(Actions.listMessages(channel));
      yield take([Actions.LIST_MESSAGES_SUCCESS, Actions.LIST_MESSAGES_FAIL]);
      yield put(Actions.listChannels());
      const msgs = yield select(Selectors.getMessages(channel.url));
      const lastMsg = msgs[msgs.length - 2];
      if (
        recipientId &&
        (!lastMsg || differenceInDays(lastMsg.createdAt, Date.now()))
      ) {
        yield call(api.sendChatEmailNotification, { sendbird_id: recipientId });
      }
      if (channel.unreadMessageCount) {
        yield call(channel.markAsRead());
      }
      if (typeof callbacks?.success === 'function') {
        yield call(callbacks.success);
      }
    }
  } catch (error) {
    yield put(Actions.sendMessageFail(error));
  }
}

function* watchSendMessage() {
  yield takeLatest(Actions.SEND_MESSAGE_REQUEST, sendMessage);
}

export default function* send() {
  yield all([fork(watchSendMessage)]);
}

import * as list from './list';
import * as info from './info';
import * as redeem from './redeem';
import * as redeemed from './redeemed';
import * as create from './create';
import * as created from './created';
import * as destroy from './destroy';
import * as update from './update';

export default {
  ...list,
  ...info,
  ...redeem,
  ...redeemed,
  ...create,
  ...created,
  ...destroy,
  ...update,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Button, Modal } from 'components/UI';
import { bgGroup } from 'assets';
import { BackgroundSection } from '../../LandingPage/common';
import RedeemModal from './RedeemModal';

const RewardCard = ({ redeemable, currentPoints }) => {
  const { title, subtitle, points } = redeemable;
  const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false);
  const closeRedeemModal = () => setIsRedeemModalOpen(false);
  const openRedeemModal = () => {
    setIsRedeemModalOpen(true);
  };
  return (
    <>
      <Link to="">
        <Card className="relative my-5 pb-4 lg:w-full lg:pb-0 flex flex-col lg:flex-row">
          <BackgroundSection
            backgroundImage={`url(${bgGroup.default})`}
            className="relative bg-no-repeat bg-cover bg-center rounded-lg h-40 xl:h-48 lg:w-4/5 xl:w-3/5 lg:rounded-r-none"
          />
          <section className="self-start w-full flex p-4 lg:pr-0 space-x-3 items-center">
            <article className="flex flex-col">
              <p className="text-sm sm:text-md xl:text-lg">{title}</p>
              <p className="text-xs sm:text-sm xl:text-md text-boulder pt-2">
                {subtitle}
              </p>
            </article>
          </section>

          <section className="px-4 flex justify-between items-center lg:py-4 lg:flex-col-reverse">
            <Button
              label="Redeem"
              className="text-sm flex items-center h-8 xl:text-md xl:h-10"
              onClick={openRedeemModal}
            />
            <article className="text-center mr-5 flex flex-col self-end xl:-mt-2">
              <p className="text-md md:text-lg xl:text-xl font-bold">
                {points}
              </p>
              <p className="text-xs self-end md:text-sm xl:text-md">Points</p>
            </article>
          </section>
        </Card>
      </Link>
      <Modal
        isOpen={isRedeemModalOpen}
        content={
          <RedeemModal
            onCancel={() => setIsRedeemModalOpen(false)}
            currentPoints={currentPoints}
            redeemableImg={bgGroup.default}
            redeemable={redeemable}
            isMembership={false}
            closeRedeemModal={() => setIsRedeemModalOpen(false)}
          />
        }
        onDismiss={closeRedeemModal}
      >
        <div />
      </Modal>
    </>
  );
};

RewardCard.propTypes = {
  redeemable: PropTypes.object.isRequired,
  currentPoints: PropTypes.number.isRequired,
};

export default RewardCard;

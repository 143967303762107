import React from 'react';
// import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CONFIG from 'utils/config';
import CheckoutForm from './StripeForm';

const stripePromise = loadStripe(CONFIG.STRIPE_PUB_KEY);

const Stripe = () => {
  return (
    <div className="flex h-screen">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

Stripe.propTypes = {};

export default Stripe;

import * as list from './list';
import * as info from './info';
import * as attend from './attend';
import * as unattend from './unattend';
import * as attendList from './attendList';
import * as optIn from './optIn';
import * as optOut from './optOut';
import * as getCategories from './getCategories';
import * as getCount from './getCount';
import * as attendances from './attendances';
import * as add from './add';
import * as remove from './remove';
import * as addedList from './addedList';
import * as create from './create';
import * as createdList from './createdList';
import * as destroy from './destroy';
import * as update from './update';
import * as fetchCreated from './fetchCreated';

export default {
  ...list,
  ...info,
  ...attend,
  ...unattend,
  ...attendList,
  ...optIn,
  ...optOut,
  ...getCategories,
  ...getCount,
  ...attendances,
  ...add,
  ...remove,
  ...addedList,
  ...create,
  ...createdList,
  ...destroy,
  ...update,
  ...fetchCreated,
};

const AUTH = 'AUTH/FORGOT_PASSWORD';

export const FORGOT_PASSWORD_REQUEST = `${AUTH}_REQUEST`;
export const FORGOT_PASSWORD_SUCCESS = `${AUTH}_SUCCESS`;
export const FORGOT_PASSWORD_FAIL = `${AUTH}_FAIL`;

export const forgotPassword = (data, callbacks = {}) => ({
  type: FORGOT_PASSWORD_REQUEST,
  data,
  callbacks,
});

export const forgotPasswordSuccess = (data) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  data,
});

export const forgotPasswordFail = (errors) => ({
  type: FORGOT_PASSWORD_FAIL,
  errors,
});

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchMemberTypes({ params }) {
  try {
    const response = yield call(api.fetchMemberTypes, params);
    yield put(Actions.fetchMemberTypesSuccess(response.data));
  } catch (error) {
    yield put(Actions.fetchMemberTypesFail(error));
  }
}

function* watchFetchMemberTypes() {
  yield takeLatest(Actions.FETCH_MEMBER_TYPES_REQUEST, fetchMemberTypes);
}

export default function* list() {
  yield all([fork(watchFetchMemberTypes)]);
}

export const yearRange = () => {
  const now = new Date().getUTCFullYear();

  return Array(now - (now - 71))
    .fill()
    .map((_, idx) => now - idx);
};

export const currencyFormat = (number, currency = 'USD') =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(number);

export const nameFormat = ({ firstName, lastName }) => {
  if (!firstName) return '';
  return `${firstName} ${lastName}`;
};

export const ccExpiry = ({ expMonth, expYear }) => {
  let month;
  if (expMonth < 10) {
    month = `0${expMonth}`;
  } else {
    month = expMonth.toString();
  }

  return `Expires ${month}/${expYear.toString().substring(2, 4)}`;
};

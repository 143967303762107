import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { background, layout } from 'styled-system';
import { Picture, Card, Loading, Modal } from 'components/UI';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  bgAuth,
  iconCheckmarkWhite,
  bgAuthContainer,
  pngLogoWhite,
  bgGroup,
  imgTableBenefits,
} from 'assets';

import { BackgroundSection } from '../../LandingPage/common';

const LOGO_SRC = pngLogoWhite;

const Filter = styled.div`
  z-index: 9;
  ${background}
`;

const PolyColumnContainer = styled.div`
  @media (min-width: 768px) {
    clip-path: polygon(0 0%, 100% 0%, 75% 100%, 0% 100%);
  }
  ${background}
  ${layout}
`;
const ColumnContainer = styled.div`
  @media (min-width: 768px) {
    max-height: 780px !important;
  }
  ${background}
  ${layout}
`;

const Benefits = () => {
  const benefit = (label) => (
    <li className="flex mb-2 text-shadow text-white">
      <img
        alt="check"
        src={iconCheckmarkWhite.default}
        className="h-4 mt-1 mr-4"
      />
      {label}
    </li>
  );

  return (
    <div className="flex-col text-sm max-w-sm flex my-4 md:mt-8 mx-8 md:mx-0 gap-3 text-white text-shadow">
      <div>
        Be a part of the Gig Life Pro Community and enjoy some of these
        benefits:
      </div>
      <ul className="list-outside">
        {benefit('Connect and chat with music industry professionals')}
        {benefit(
          'Learn about various music markets across Asia Pacific through Articles and City Guides'
        )}
        {benefit(
          'Participate in exclusive music industry events curated by Gig Life Pro'
        )}
        {benefit('Promote your own events to music industry professionals')}
      </ul>
      <Modal content={<Picture src={imgTableBenefits} />}>
        <span className="cursor-pointer ml-8 pl-1 underline">
          Click here to explore different membership benefits
        </span>
      </Modal>
    </div>
  );
};

const AuthLayout = ({
  children,
  showInfo,
  progress,
  isLoading,
  showBg,
  disableScroll,
}) => {
  const ProgressBar = () =>
    progress !== null && (
      <div className="absolute top-0 z-20 h-2 flex w-full">
        <div
          className={classnames('bg-easter rounded-tl-lg', {
            'w-1/4': progress === 0,
            'w-2/4': progress === 1,
            'w-3/4': progress === 2,
            'w-full rounded-t-lg': progress > 2,
          })}
        />
        <div className={classnames('bg-alto rounded-tr-lg flex-1')} />
      </div>
    );

  if (isLoading) return <Loading />;

  return (
    <BackgroundSection
      backgroundImage={`url(${bgAuth.default})`}
      className="relative flex-1 bg-no-repeat bg-cover flex pt-24 pb-20 px-2"
    >
      <Card className="m-auto flex flex-col md:flex-row relative">
        <ProgressBar />
        {showBg && (
          <Filter
            background="linear-gradient(180deg, rgba(18,3,53,0) 0%, #120335 70%, #fff 0%);"
            className="bg-opacity-75 absolute top-0 bottom-0 right-0 left-0 rounded-lg"
          />
        )}
        <BackgroundSection
          backgroundImage={showBg ? `url(${bgGroup.default})` : null}
          className="relative flex flex-col md:flex-row bg-cover bg-no-repeat bg-center rounded-lg"
        >
          <PolyColumnContainer
            backgroundImage={`url(${bgAuthContainer.default})`}
            className="flex rounded-t-lg md:rounded-t-none md:rounded-l-lg bg-full z-10"
          >
            <div
              className={classnames('flex flex-col w-full md:w-3/4', {
                'md:pl-8 justify-center': showInfo,
                'md:pl-4 md:pt-8': !showInfo,
              })}
            >
              <Link to="/" className="self-center my-4 md:my-0">
                {showInfo ? (
                  <span className="h-12 text-2xl text-white mr-4 icon-expand-logo" />
                ) : (
                  <Picture className="w-32 md:w-48" src={LOGO_SRC} />
                )}
              </Link>
              {showInfo && <Benefits />}
            </div>
          </PolyColumnContainer>
          <ColumnContainer
            maxHeight={!disableScroll && [null, null, 600]}
            className={classnames('p-8 z-10', {
              'overflow-y-auto': !disableScroll,
            })}
          >
            {children}
          </ColumnContainer>
        </BackgroundSection>
      </Card>
    </BackgroundSection>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showInfo: PropTypes.bool,
  isLoading: PropTypes.bool,
  showBg: PropTypes.bool,
  progress: PropTypes.number,
  disableScroll: PropTypes.bool,
};

AuthLayout.defaultProps = {
  showInfo: false,
  isLoading: false,
  showBg: false,
  progress: null,
  disableScroll: false,
};

export default AuthLayout;

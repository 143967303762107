const NAME = 'VIDEO/FETCH_VIDEO_';

export const FETCH_VIDEO_REQUEST = `${NAME}REQUEST`;
export const FETCH_VIDEO_SUCCESS = `${NAME}SUCCESS`;
export const FETCH_VIDEO_FAIL = `${NAME}FAIL`;

export const fetchVideo = (id, callbacks) => ({
  type: FETCH_VIDEO_REQUEST,
  id,
  callbacks,
});

export const fetchVideoSuccess = (data) => ({
  type: FETCH_VIDEO_SUCCESS,
  data,
});

export const fetchVideoFail = (error) => ({
  type: FETCH_VIDEO_FAIL,
  error,
});

import { all, fork } from 'redux-saga/effects';
import list from './list';
import info from './info';
import favouriteList from './favouriteList';
import favourite from './favourite';
import unfavourite from './unfavourite';
import categories from './categories';

export default function* events() {
  yield all([
    fork(list),
    fork(info),
    fork(favouriteList),
    fork(favourite),
    fork(unfavourite),
    fork(categories),
  ]);
}

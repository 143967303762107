const AUTH = 'AUTH/VALIDATE_RESET_PASSWORD_CODE';

export const VALIDATE_RESET_PASSWORD_CODE_REQUEST = `${AUTH}_REQUEST`;
export const VALIDATE_RESET_PASSWORD_CODE_SUCCESS = `${AUTH}_SUCCESS`;
export const VALIDATE_RESET_PASSWORD_CODE_FAIL = `${AUTH}_FAIL`;

export const validateResetPasswordCode = (data, callbacks = {}) => ({
  type: VALIDATE_RESET_PASSWORD_CODE_REQUEST,
  data,
  callbacks,
});

export const validateResetPasswordCodeSuccess = (data) => ({
  type: VALIDATE_RESET_PASSWORD_CODE_SUCCESS,
  data,
});

export const validateResetPasswordCodeFail = (errors) => ({
  type: VALIDATE_RESET_PASSWORD_CODE_FAIL,
  errors,
});

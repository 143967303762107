import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';
import { paginationHelper } from 'utils/helper';

function* fetchEvents({ params, callbacks }) {
  try {
    const response = yield call(api.fetchEvents, params);
    const data = response?.data;
    const pagination = paginationHelper(response?.headers);

    if (data.length === 0) {
      const response = yield call(api.fetchEvents);
      const data = response?.data;
      const featured = data[0];

      yield put(Actions.fetchEventsSuccess([featured], pagination));
      yield put(Actions.fetchEventsSuccess([], pagination));
    } else {
      yield put(Actions.fetchEventsSuccess(data, pagination));
    }

    if (typeof callbacks?.success === 'function') {
      yield call(callbacks.success, response.data);
    }
  } catch (error) {
    yield put(Actions.fetchEventsFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks.failure, error);
    }
  }
}

function* watchFetchEvents() {
  yield takeLatest(Actions.FETCH_EVENTS_REQUEST, fetchEvents);
}

export default function* list() {
  yield all([fork(watchFetchEvents)]);
}

import React from 'react';
import PropTypes from 'prop-types';

const Tags = ({ data }) => (
  <div className="text-boulder mt-5">
    <p>TAGS</p>
    <div className="flex flex-wrap mt-3">
      {data.map((name) => (
        <div
          className="mr-2 flex items-center justify-center p-1 px-2 rounded-lg bg-boston text-white uppercase text-sm mb-2"
          key={name}
        >
          {name}
        </div>
      ))}
    </div>
  </div>
);

Tags.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Tags;

import { takeLatest, all, fork, put, call } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchCreatedEvents() {
  try {
    const response = yield call(api.fetchCreatedEvents);
    yield put(Actions.fetchCreatedEventsSuccess(response.data));
  } catch (error) {
    yield put(Actions.fetchCreatedEventsFail(error));
  }
}

function* watchFetchCreatedEvents() {
  yield takeLatest(Actions.FETCH_CREATED_LIST_REQUEST, fetchCreatedEvents);
}

export default function* createdList() {
  yield all([fork(watchFetchCreatedEvents)]);
}

import { takeLatest, all, fork, call, put } from 'redux-saga/effects';
import Actions from 'actions';
import api from 'api';

function* fetchProfile({ callbacks }) {
  try {
    const response = yield call(api.fetchUserProfile);
    yield put(Actions.fetchProfileSuccess(response?.data));
    if (typeof callbacks?.success === 'function') {
      yield call(callbacks?.success, response?.data);
    }
  } catch (error) {
    yield put(Actions.fetchProfileFail(error));
    if (typeof callbacks?.failure === 'function') {
      yield call(callbacks?.failure, error);
    }
  }
}

function* watchFetchProfile() {
  yield takeLatest(Actions.FETCH_PROFILE_REQUEST, fetchProfile);
}

export default function* profile() {
  yield all([fork(watchFetchProfile)]);
}

const NAME = 'EVENTS/OPT_OUT_EVENT';

export const OPT_OUT_EVENT_REQUEST = `${NAME}_REQUEST`;
export const OPT_OUT_EVENT_SUCCESS = `${NAME}_SUCCESS`;
export const OPT_OUT_EVENT_FAIL = `${NAME}_FAIL`;

export const optOutEvent = (id, callbacks) => ({
  type: OPT_OUT_EVENT_REQUEST,
  id,
  callbacks,
});

export const optOutEventSuccess = () => ({
  type: OPT_OUT_EVENT_SUCCESS,
});

export const optOutEventFail = (error) => ({
  type: OPT_OUT_EVENT_FAIL,
  error,
});
